import React, {Component} from 'react'
import PartnerForm from "./PartnerForm"
import HeaderTools from '../../PageComponents/Header/HeaderTools';
import { ToastProvider } from 'react-toast-notifications';

class Partner extends Component {
    constructor(props) {
        super(props);
    }

    componentDidmount(){

    }

    render() {
        return (
            <> 
                <div className="container-fluid px-2 mt-3">
                    <div className="card border-left-primary shadow h-100 py-0">
                        <div className="card-body py-0">
                            <div className="row no-gutters align-items-center">
                                <div className="col mr-2 row">
                                    <div className="col-lg-12 form-group my-0 d-flex align-items-center">
                                        <div className="d-table-cell no-shadow">
                                            {window.location.hash === "#/partner" ? 
                                            <HeaderTools newHandle={() => this.newItem()} save={() => this.save()}  back="/#/partners#active" />:
                                            <HeaderTools newHandle={() => this.newItem()} save={() => this.save()}  />
                                            
                                        }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <PartnerForm newHandle={click => this.newItem = click} save={click => this.save = click} props={this.props} /> 
            </div>
            </>
        )
    }
} 

export default Partner