import React, { useState, useEffect , useRef } from "react";
import Select from "react-select";
import { MDBInput } from "mdb-react-ui-kit";
import apiService from "../../../Services/apiServices";
import { useToasts } from "react-toast-notifications";
import Search from "../../../PageComponents/Search/Search_Old";
import globals from "../../../Services/globals";
import { confirmAlert } from "react-confirm-alert";
import helper from "../../../Services/helper";
import calculate from "../../../Services/calculate";
import Pagination from "../../../PageComponents/UI/Pagination/Pagination";
import SelectByGroup from './../../../PageComponents/UI/Inputs/SelectByGroup'
import ExcelExport from "../../../PageComponents/Excel/ExcelExport";
import Print from "../../../PageComponents/Print/Print";



function ShareHolderEquity(props) {


    return (
        <div className="container-fluid px-2 mt-3">
            <Search  back="#/reports"/>
         <div className="card shadow">
            <div className="card-header border-left-primary py-2"><h6 className="m-0 font-weight-bold text-primary text-center">{helper().translate("SHAREHOLDER_EQUITY")}</h6></div>
         </div>
        <div className="card border-left-primary shadow h-100 mt-1 pt-1 pb-3 text-dark">
          <div className="container">
            <div className="row font-weight-bold my-4" >
              <div className="col-12  d-flex justify-content-center justify-content-md-start text-center ">
              <h6 className="m-0 w-100 font-weight-bold text-primary text-center ">Pasqyra e ndryshimeve në ekuitet për vitin që përfundon me:</h6>
              </div>
            </div>

            <div className="row mt-2 font-weight-bold inputMinWitdth" >
              <div className=" col-12 col-md-4 d-flex justify-content-center justify-content-md-start ">
              </div> 
              <div className="col-3 col-md-2  d-flex justify-content-center  ">
                <label><p className="font-weight-bold text-dark"><u>{`Kapitali aksionar`}</u></p></label>
              </div>
              <div className="col-3 col-md-2  d-flex justify-content-center   ">
              <label><p className="font-weight-bold text-dark"><u>{`Fitimet e mbajtura`}</u></p></label>
              </div>
              <div className="col-3 col-md-2  d-flex justify-content-center  ">
                <label><p className="font-weight-bold text-dark"><u>{`Rezervat e tjera`}</u></p></label>
              </div>
              <div className="col-3 col-md-2  d-flex justify-content-center   ">
              <label><p className="font-weight-bold text-dark"><u>{`Gjithsej`}</u></p></label>
              </div>
            </div>

            <div className="row mt-4 font-weight-bold inputMinWitdth" >
                <div className="col-12 col-md-4 d-flex justify-content-center justify-content-md-start">
                  <div  className="text-left">
                    <p className="font-weight-bold text-dark"><u>Gjendja më 1 janar 2018</u> </p>
                  </div>
                </div>
                <div className="col-3 col-md-2  d-flex justify-content-center justify-content-md-start ">
                    <input type="number" className="form-control form-control-sm font-weight-bold text-right  border-dark" disabled ></input>
                </div>
                <div className="col-3 col-md-2  d-flex justify-content-center justify-content-md-start ">
                    <input type="number" className="form-control form-control-sm font-weight-bold text-right border-dark " disabled ></input>
                </div>
                <div className="col-3 col-md-2  d-flex justify-content-center justify-content-md-start ">
                    <input type="number" className="form-control form-control-sm font-weight-bold text-right border-dark " disabled ></input>
                </div>
                <div className="col-3 col-md-2  d-flex justify-content-center justify-content-md-start ">
                    <input type="number" className="form-control form-control-sm font-weight-bold text-right border-dark " disabled ></input>
                </div>
            </div>

            <div className="row mt-1 inputMinWitdth" >
                <div className="col-12 col-md-4 d-flex justify-content-center justify-content-md-start">
                  <div  className="text-left">
                    <p>Fitimi / (humbja) i / e vitit</p>
                  </div>
                </div>
                <div className="col-3 col-md-2  d-flex justify-content-center justify-content-md-start ">
                    <input type="number" className="form-control form-control-sm font-weight-bold text-right " disabled ></input>
                </div>
                <div className="col-3 col-md-2  d-flex justify-content-center justify-content-md-start ">
                    <input type="number" className="form-control form-control-sm font-weight-bold text-right " disabled ></input>
                </div>
                <div className="col-3 col-md-2  d-flex justify-content-center justify-content-md-start ">
                    <input type="number" className="form-control form-control-sm font-weight-bold text-right " disabled ></input>
                </div>
                <div className="col-3 col-md-2  d-flex justify-content-center justify-content-md-start ">
                    <input type="number" className="form-control form-control-sm font-weight-bold text-right " disabled ></input>
                </div>
            </div>

            <div className="row mt-1 inputMinWitdth" >
                <div className="col-12 col-md-4 d-flex justify-content-center justify-content-md-start">
                  <div  className="text-left">
                    <p>Diferencat nga përkthimet valutore</p>
                  </div>
                </div>
                <div className="col-3 col-md-2  d-flex justify-content-center justify-content-md-start ">
                    <input type="number" className="form-control form-control-sm font-weight-bold text-right " disabled ></input>
                </div>
                <div className="col-3 col-md-2  d-flex justify-content-center justify-content-md-start ">
                    <input type="number" className="form-control form-control-sm font-weight-bold text-right " disabled ></input>
                </div>
                <div className="col-3 col-md-2  d-flex justify-content-center justify-content-md-start ">
                    <input type="number" className="form-control form-control-sm font-weight-bold text-right " disabled ></input>
                </div>
                <div className="col-3 col-md-2  d-flex justify-content-center justify-content-md-start ">
                    <input type="number" className="form-control form-control-sm font-weight-bold text-right " disabled ></input>
                </div>
            </div>
            <div className="row mt-1 inputMinWitdth" >
                <div className="col-12 col-md-4 d-flex justify-content-center justify-content-md-start">
                  <div  className="text-left">
                    <p>Dividendat</p>
                  </div>
                </div>
                <div className="col-3 col-md-2  d-flex justify-content-center justify-content-md-start ">
                    <input type="number" className="form-control form-control-sm font-weight-bold text-right " disabled ></input>
                </div>
                <div className="col-3 col-md-2  d-flex justify-content-center justify-content-md-start ">
                    <input type="number" className="form-control form-control-sm font-weight-bold text-right " disabled ></input>
                </div>
                <div className="col-3 col-md-2  d-flex justify-content-center justify-content-md-start ">
                    <input type="number" className="form-control form-control-sm font-weight-bold text-right " disabled ></input>
                </div>
                <div className="col-3 col-md-2  d-flex justify-content-center justify-content-md-start ">
                    <input type="number" className="form-control form-control-sm font-weight-bold text-right " disabled ></input>
                </div>
            </div>
            <div className="row mt-1 inputMinWitdth" >
                <div className="col-12 col-md-4 d-flex justify-content-center justify-content-md-start">
                  <div  className="text-left">
                    <p>Deponimet e kapitalit shtesë</p>
                  </div>
                </div>
                <div className="col-3 col-md-2  d-flex justify-content-center justify-content-md-start ">
                    <input type="number" className="form-control form-control-sm font-weight-bold text-right " disabled ></input>
                </div>
                <div className="col-3 col-md-2  d-flex justify-content-center justify-content-md-start ">
                    <input type="number" className="form-control form-control-sm font-weight-bold text-right " disabled ></input>
                </div>
                <div className="col-3 col-md-2  d-flex justify-content-center justify-content-md-start ">
                    <input type="number" className="form-control form-control-sm font-weight-bold text-right " disabled ></input>
                </div>
                <div className="col-3 col-md-2  d-flex justify-content-center justify-content-md-start ">
                    <input type="number" className="form-control form-control-sm font-weight-bold text-right " disabled ></input>
                </div>
            </div>
            <div className="row mt-1 inputMinWitdth" >
                <div className="col-12 col-md-4 d-flex justify-content-center justify-content-md-start">
                  <div  className="text-left">
                    <p>Ndarja e rezervave</p>
                  </div>
                </div>
                <div className="col-3 col-md-2  d-flex justify-content-center justify-content-md-start ">
                    <input type="number" className="form-control form-control-sm font-weight-bold text-right " disabled ></input>
                </div>
                <div className="col-3 col-md-2  d-flex justify-content-center justify-content-md-start ">
                    <input type="number" className="form-control form-control-sm font-weight-bold text-right " disabled ></input>
                </div>
                <div className="col-3 col-md-2  d-flex justify-content-center justify-content-md-start ">
                    <input type="number" className="form-control form-control-sm font-weight-bold text-right " disabled ></input>
                </div>
                <div className="col-3 col-md-2  d-flex justify-content-center justify-content-md-start ">
                    <input type="number" className="form-control form-control-sm font-weight-bold text-right " disabled ></input>
                </div>
            </div>
            <div className="row mt-4 font-weight-bold inputMinWitdth" >
                <div className="col-12 col-md-4 d-flex justify-content-center justify-content-md-start">
                  <div  className="text-left">
                    <p className="font-weight-bold text-dark"><u>Gjendja më 1 janar 2019</u> </p>
                  </div>
                </div>
                <div className="col-3 col-md-2  d-flex justify-content-center justify-content-md-start ">
                    <input type="number" className="form-control form-control-sm font-weight-bold text-right  border-dark" disabled ></input>
                </div>
                <div className="col-3 col-md-2  d-flex justify-content-center justify-content-md-start ">
                    <input type="number" className="form-control form-control-sm font-weight-bold text-right border-dark " disabled ></input>
                </div>
                <div className="col-3 col-md-2  d-flex justify-content-center justify-content-md-start ">
                    <input type="number" className="form-control form-control-sm font-weight-bold text-right border-dark " disabled ></input>
                </div>
                <div className="col-3 col-md-2  d-flex justify-content-center justify-content-md-start ">
                    <input type="number" className="form-control form-control-sm font-weight-bold text-right border-dark " disabled ></input>
                </div>
            </div>

            <div className="row mt-1 inputMinWitdth" >
                <div className="col-12 col-md-4 d-flex justify-content-center justify-content-md-start">
                  <div  className="text-left">
                    <p>Fitimi / (humbja) i / e vitit</p>
                  </div>
                </div>
                <div className="col-3 col-md-2  d-flex justify-content-center justify-content-md-start ">
                    <input type="number" className="form-control form-control-sm font-weight-bold text-right " disabled ></input>
                </div>
                <div className="col-3 col-md-2  d-flex justify-content-center justify-content-md-start ">
                    <input type="number" className="form-control form-control-sm font-weight-bold text-right " disabled ></input>
                </div>
                <div className="col-3 col-md-2  d-flex justify-content-center justify-content-md-start ">
                    <input type="number" className="form-control form-control-sm font-weight-bold text-right " disabled ></input>
                </div>
                <div className="col-3 col-md-2  d-flex justify-content-center justify-content-md-start ">
                    <input type="number" className="form-control form-control-sm font-weight-bold text-right " disabled ></input>
                </div>
            </div>

            <div className="row mt-1 inputMinWitdth" >
                <div className="col-12 col-md-4 d-flex justify-content-center justify-content-md-start">
                  <div  className="text-left">
                    <p>Diferencat nga përkthimet valutore</p>
                  </div>
                </div>
                <div className="col-3 col-md-2  d-flex justify-content-center justify-content-md-start ">
                    <input type="number" className="form-control form-control-sm font-weight-bold text-right " disabled ></input>
                </div>
                <div className="col-3 col-md-2  d-flex justify-content-center justify-content-md-start ">
                    <input type="number" className="form-control form-control-sm font-weight-bold text-right " disabled ></input>
                </div>
                <div className="col-3 col-md-2  d-flex justify-content-center justify-content-md-start ">
                    <input type="number" className="form-control form-control-sm font-weight-bold text-right " disabled ></input>
                </div>
                <div className="col-3 col-md-2  d-flex justify-content-center justify-content-md-start ">
                    <input type="number" className="form-control form-control-sm font-weight-bold text-right " disabled ></input>
                </div>
            </div>
            <div className="row mt-1 inputMinWitdth" >
                <div className="col-12 col-md-4 d-flex justify-content-center justify-content-md-start">
                  <div  className="text-left">
                    <p>Dividendat</p>
                  </div>
                </div>
                <div className="col-3 col-md-2  d-flex justify-content-center justify-content-md-start ">
                    <input type="number" className="form-control form-control-sm font-weight-bold text-right " disabled ></input>
                </div>
                <div className="col-3 col-md-2  d-flex justify-content-center justify-content-md-start ">
                    <input type="number" className="form-control form-control-sm font-weight-bold text-right " disabled ></input>
                </div>
                <div className="col-3 col-md-2  d-flex justify-content-center justify-content-md-start ">
                    <input type="number" className="form-control form-control-sm font-weight-bold text-right " disabled ></input>
                </div>
                <div className="col-3 col-md-2  d-flex justify-content-center justify-content-md-start ">
                    <input type="number" className="form-control form-control-sm font-weight-bold text-right " disabled ></input>
                </div>
            </div>
            <div className="row mt-1 inputMinWitdth" >
                <div className="col-12 col-md-4 d-flex justify-content-center justify-content-md-start">
                  <div  className="text-left">
                    <p>Deponimet e kapitalit shtesë</p>
                  </div>
                </div>
                <div className="col-3 col-md-2  d-flex justify-content-center justify-content-md-start ">
                    <input type="number" className="form-control form-control-sm font-weight-bold text-right " disabled ></input>
                </div>
                <div className="col-3 col-md-2  d-flex justify-content-center justify-content-md-start ">
                    <input type="number" className="form-control form-control-sm font-weight-bold text-right " disabled ></input>
                </div>
                <div className="col-3 col-md-2  d-flex justify-content-center justify-content-md-start ">
                    <input type="number" className="form-control form-control-sm font-weight-bold text-right " disabled ></input>
                </div>
                <div className="col-3 col-md-2  d-flex justify-content-center justify-content-md-start ">
                    <input type="number" className="form-control form-control-sm font-weight-bold text-right " disabled ></input>
                </div>
            </div>
            <div className="row mt-1 inputMinWitdth" >
                <div className="col-12 col-md-4 d-flex justify-content-center justify-content-md-start">
                  <div  className="text-left">
                    <p>Ndarja e rezervave</p>
                  </div>
                </div>
                <div className="col-3 col-md-2  d-flex justify-content-center justify-content-md-start ">
                    <input type="number" className="form-control form-control-sm font-weight-bold text-right " disabled ></input>
                </div>
                <div className="col-3 col-md-2  d-flex justify-content-center justify-content-md-start ">
                    <input type="number" className="form-control form-control-sm font-weight-bold text-right " disabled ></input>
                </div>
                <div className="col-3 col-md-2  d-flex justify-content-center justify-content-md-start ">
                    <input type="number" className="form-control form-control-sm font-weight-bold text-right " disabled ></input>
                </div>
                <div className="col-3 col-md-2  d-flex justify-content-center justify-content-md-start ">
                    <input type="number" className="form-control form-control-sm font-weight-bold text-right " disabled ></input>
                </div>
            </div>
            <div className="row mt-4 font-weight-bold inputMinWitdth" >
                <div className="col-12 col-md-4 d-flex justify-content-center justify-content-md-start">
                  <div  className="text-left">
                    <p className="font-weight-bold text-dark"><u>Gjendja më 1 janar 2020</u> </p>
                  </div>
                </div>
                <div className="col-3 col-md-2  d-flex justify-content-center justify-content-md-start ">
                    <input type="number" className="form-control form-control-sm font-weight-bold text-right  border-dark" disabled ></input>
                </div>
                <div className="col-3 col-md-2  d-flex justify-content-center justify-content-md-start ">
                    <input type="number" className="form-control form-control-sm font-weight-bold text-right border-dark " disabled ></input>
                </div>
                <div className="col-3 col-md-2  d-flex justify-content-center justify-content-md-start ">
                    <input type="number" className="form-control form-control-sm font-weight-bold text-right border-dark " disabled ></input>
                </div>
                <div className="col-3 col-md-2  d-flex justify-content-center justify-content-md-start ">
                    <input type="number" className="form-control form-control-sm font-weight-bold text-right border-dark " disabled ></input>
                </div>
            </div>

            <div className="row mt-1 inputMinWitdth" >
                <div className="col-12 col-md-4 d-flex justify-content-center justify-content-md-start">
                  <div  className="text-left">
                    <p>Fitimi / (humbja) i / e vitit</p>
                  </div>
                </div>
                <div className="col-3 col-md-2  d-flex justify-content-center justify-content-md-start ">
                    <input type="number" className="form-control form-control-sm font-weight-bold text-right " disabled ></input>
                </div>
                <div className="col-3 col-md-2  d-flex justify-content-center justify-content-md-start ">
                    <input type="number" className="form-control form-control-sm font-weight-bold text-right " disabled ></input>
                </div>
                <div className="col-3 col-md-2  d-flex justify-content-center justify-content-md-start ">
                    <input type="number" className="form-control form-control-sm font-weight-bold text-right " disabled ></input>
                </div>
                <div className="col-3 col-md-2  d-flex justify-content-center justify-content-md-start ">
                    <input type="number" className="form-control form-control-sm font-weight-bold text-right " disabled ></input>
                </div>
            </div>

            <div className="row mt-1 inputMinWitdth" >
                <div className="col-12 col-md-4 d-flex justify-content-center justify-content-md-start">
                  <div  className="text-left">
                    <p>Diferencat nga përkthimet valutore</p>
                  </div>
                </div>
                <div className="col-3 col-md-2  d-flex justify-content-center justify-content-md-start ">
                    <input type="number" className="form-control form-control-sm font-weight-bold text-right " disabled ></input>
                </div>
                <div className="col-3 col-md-2  d-flex justify-content-center justify-content-md-start ">
                    <input type="number" className="form-control form-control-sm font-weight-bold text-right " disabled ></input>
                </div>
                <div className="col-3 col-md-2  d-flex justify-content-center justify-content-md-start ">
                    <input type="number" className="form-control form-control-sm font-weight-bold text-right " disabled ></input>
                </div>
                <div className="col-3 col-md-2  d-flex justify-content-center justify-content-md-start ">
                    <input type="number" className="form-control form-control-sm font-weight-bold text-right " disabled ></input>
                </div>
            </div>
            <div className="row mt-1 inputMinWitdth" >
                <div className="col-12 col-md-4 d-flex justify-content-center justify-content-md-start">
                  <div  className="text-left">
                    <p>Dividendat</p>
                  </div>
                </div>
                <div className="col-3 col-md-2  d-flex justify-content-center justify-content-md-start ">
                    <input type="number" className="form-control form-control-sm font-weight-bold text-right " disabled ></input>
                </div>
                <div className="col-3 col-md-2  d-flex justify-content-center justify-content-md-start ">
                    <input type="number" className="form-control form-control-sm font-weight-bold text-right " disabled ></input>
                </div>
                <div className="col-3 col-md-2  d-flex justify-content-center justify-content-md-start ">
                    <input type="number" className="form-control form-control-sm font-weight-bold text-right " disabled ></input>
                </div>
                <div className="col-3 col-md-2  d-flex justify-content-center justify-content-md-start ">
                    <input type="number" className="form-control form-control-sm font-weight-bold text-right " disabled ></input>
                </div>
            </div>
            <div className="row mt-1 inputMinWitdth" >
                <div className="col-12 col-md-4 d-flex justify-content-center justify-content-md-start">
                  <div  className="text-left">
                    <p>Deponimet e kapitalit shtesë</p>
                  </div>
                </div>
                <div className="col-3 col-md-2  d-flex justify-content-center justify-content-md-start ">
                    <input type="number" className="form-control form-control-sm font-weight-bold text-right " disabled ></input>
                </div>
                <div className="col-3 col-md-2  d-flex justify-content-center justify-content-md-start ">
                    <input type="number" className="form-control form-control-sm font-weight-bold text-right " disabled ></input>
                </div>
                <div className="col-3 col-md-2  d-flex justify-content-center justify-content-md-start ">
                    <input type="number" className="form-control form-control-sm font-weight-bold text-right " disabled ></input>
                </div>
                <div className="col-3 col-md-2  d-flex justify-content-center justify-content-md-start ">
                    <input type="number" className="form-control form-control-sm font-weight-bold text-right " disabled ></input>
                </div>
            </div>
            <div className="row mt-1 inputMinWitdth" >
                <div className="col-12 col-md-4 d-flex justify-content-center justify-content-md-start">
                  <div  className="text-left">
                    <p>Ndarja e rezervave</p>
                  </div>
                </div>
                <div className="col-3 col-md-2  d-flex justify-content-center justify-content-md-start ">
                    <input type="number" className="form-control form-control-sm font-weight-bold text-right " disabled ></input>
                </div>
                <div className="col-3 col-md-2  d-flex justify-content-center justify-content-md-start ">
                    <input type="number" className="form-control form-control-sm font-weight-bold text-right " disabled ></input>
                </div>
                <div className="col-3 col-md-2  d-flex justify-content-center justify-content-md-start ">
                    <input type="number" className="form-control form-control-sm font-weight-bold text-right " disabled ></input>
                </div>
                <div className="col-3 col-md-2  d-flex justify-content-center justify-content-md-start ">
                    <input type="number" className="form-control form-control-sm font-weight-bold text-right " disabled ></input>
                </div>
            </div>
            
          </div>
        </div>
      </div>

    )
}

export default ShareHolderEquity;
