import React, { useEffect, useState, useRef } from 'react';
import apiService from '../../Services/apiServices';
import helper from '../../Services/helper';
import HeaderTools from '../Header/HeaderTools';
import { MDBInput } from 'mdb-react-ui-kit';
import { useToasts } from 'react-toast-notifications';
import globals from '../../Services/globals';

const DocumentNumber = (params) => {
    const { addToast } = useToasts();
    const [settings, setSettings] = useState()
    const invoiceMethods = [
        {id: 1, name: 'daily', form: "yy-mm-dd"},
        {id: 2, name: 'monthly', form: "yy-mm"},
        {id: 3, name: 'annual', form: "yy"}
    ]
    const [invoiceMethod, setInvoiceMethod] = useState()
    const docNumObj = {
        id: 0, 
        invoice: 'F-',//+invoiceMethods.find(i => i.id === invoiceMethod)?.form+'-DOCID', 
        cashbox: 'A-',//+invoiceMethods.find(i => i.id === invoiceMethod)?.form+'-DOCID', 
        order: 'T-',//+invoiceMethods.find(i => i.id === invoiceMethod)?.form+'-DOCID', 
        calculate: 'K-',//+invoiceMethods.find(i => i.id === invoiceMethod)?.form+'-DOCID', 
        bank: 'B-',//+invoiceMethods.find(i => i.id === invoiceMethod)?.form+'-DOCID', 
        saldo: 'S-',//+invoiceMethods.find(i => i.id === invoiceMethod)?.form+'-DOCID' 
    }
    const [docNum, setDocNum] = useState(docNumObj);
    

    const t = (key) => {
        return helper().translate(key);
    }

    const getDocumentNumber = () => {
        apiService().get('documentsPrefix')
            .then((response) => {
                if(response.success && response.data.length > 0){
                    let prefixes = response.data;
                    let obj = {};
                    prefixes.forEach(p => {
                        obj[p.documentType] = p.prefix
                    })
                    setDocNum(obj)
                }
                else {
                    getSettings()
                }
            })
    }

    const prepareContextObj = () => {
        let ojbs = []
        
        for (const property in docNum) {
            if(property !== 'id') {
                let obj = {
                    entity: {
                        documentType: property,
                        prefix: docNum[property]
                    },
                    status: 2,
                    type: 'documentsPrefix'
                }
                ojbs.push(obj)
            }
        }

        return ojbs
    }

    const save = () => {
        apiService().put('settings', settings)
            .then((response)=>{
                if(response.success){
                    //responseMessage(response, 'Save Successfully')
                }
            });

        apiService().saveChanges(prepareContextObj())
            .then((response) => {
                console.log('resp: ', response)
                if (response.success) {
                    responseMessage(response, 'Save Successfully')
                }
                else {
                    console.log('Error:' + response);
                }
            });

        // if(docNum?.id) {
        //     apiService().put('documentNumber', docNum)
        //         .then((response)=>{
        //             if(response.success){
        //                 responseMessage(response, 'Save Successfully')
        //             }
        //         });
        // }
        // else {
        //     apiService().post('documentNumber', docNum)
        //         .then((response)=>{
        //             if(response.success){
        //                 responseMessage(response, 'Save Successfully')
        //             }
        //         });
        // }
    }

    const getSettings = () => {
        globals().getSettings().then((response) => {
            if (response) {
                let settings = response;
                setSettings(settings)
                setInvoiceMethod(settings?.invoiceIDGenerationMethod)
                setDocNumObj(settings?.invoiceIDGenerationMethod)
            } else {
                console.log('Error:' + response.errorMessage);
            }
        })
    }

    const setDocNumObj = (method) => {
        let docNumObj = {
            id: 0, 
            invoice: 'F-',//+invoiceMethods.find(i => i.id === invoiceMethod)?.form+'-DOCID', 
            cashbox: 'A-',//+invoiceMethods.find(i => i.id === invoiceMethod)?.form+'-DOCID', 
            order: 'T-',//+invoiceMethods.find(i => i.id === invoiceMethod)?.form+'-DOCID', 
            calculate: 'K-',//+invoiceMethods.find(i => i.id === invoiceMethod)?.form+'-DOCID', 
            bank: 'B-',//+invoiceMethods.find(i => i.id === invoiceMethod)?.form+'-DOCID', 
            saldo: 'S-',//+invoiceMethods.find(i => i.id === invoiceMethod)?.form+'-DOCID'  
        }

        setDocNum(docNumObj)
    }

    const responseMessage = (response, message) => {
        if(response.success){
            addToast( message, { appearance: 'success', autoDismiss: true, });
        }
        else{
            addToast(response.errorMessage, { appearance: 'error', autoDismiss: true, });
        }
    }

    useEffect(() => {
      getDocumentNumber();
      getSettings()
    }, [])
    
    return (
        <>
            <div className="card o-hidden border-0 shadow-lg">
                <div className="card-body p-0">
                    <div className="row">
                        <div className="no-shadow px-4">
                            <HeaderTools save={() => save()} />
                        </div>
                        <div className="no-shadow px-5">
                                <p><b>yy</b> -{helper().translate("YEAR")}, <b>mm</b> -{helper().translate("MONTH")}, <b>dd</b> - {helper().translate("DAY")}, <b>DOCID</b> -{helper().translate("SERIAL NUMBER")}</p>
                        </div>
                        <div>
                            <div className="col-md-6 px-0 px-md-3 py-3 py-md-3 overflow-hidden">
                                <div className="col-md-12 col-lg-12 col-xl-12 mb-0 form-floating">
                                    <MDBInput type="text" name="invoiceIDFormat" id="invoiceIDFormat" value={settings?.invoiceIDFormat ? settings?.invoiceIDFormat : '' } style={{height: 'auto'}} rows="5" className="form-control-sm text-black px-3" onChange={(e) => setSettings({...settings, invoiceIDFormat: e.target.value})} label={t('FORMAT')}  />
                                </div>
                            </div>
                        </div>
                        <br></br>
                        <div className="col-md-6 col-lg-6 col-xl-6 mb-3 d-md-flex d-lg-flex d-xl-flex px-4">
                            <div className="col-md-6 px-0 px-md-0 py-3 py-md-3 mx-auto overflow-hidden">
                                <div className="col-md-12 col-lg-12 col-xl-12 mb-3 form-floating">
                                    <MDBInput type="text" name="invoice" id="nrSaleInvoice" value={docNum?.invoice ? docNum?.invoice : ''} className="form-control-sm text-black px-3" onChange={(e) => setDocNum({...docNum, invoice: e.target.value})} label={t('NO_SALE_INVOICE')}  />
                                    <small className='font-70'>{t('E.G.')} {new Date().getFullYear()}-0000001</small>
                                </div>
                                <div className="col-md-12 col-lg-12 col-xl-12 mb-3 form-floating">
                                    <MDBInput type="text" name="cashbox" id="nrCashboxDocument" value={docNum?.cashbox ? docNum?.cashbox : ''} className="form-control-sm text-black px-3" onChange={(e) => setDocNum({...docNum, cashbox: e.target.value})} label={t('NO_CASHBOX_DOCUMENT')}  />
                                    <small className='font-70'>{t('E.G.')} {t('BRANCH')}-{t('WAREHOUSE')}-{t('CASHBOX')}-0000001</small>
                                </div>
                                <div className="col-md-12 col-lg-12 col-xl-12 mb-3 form-floating">
                                    <MDBInput type="text" name="order" id="nrOrderDocument" value={docNum?.order ? docNum?.order : ''} className="form-control-sm text-black px-3" onChange={(e) => setDocNum({...docNum, order: e.target.value})} label={t('NO_ORDER_DOCUMENT')}  />
                                    <small className='font-70'>{t('E.G.')} {t('ORD')}-{new Date().getFullYear()}-0000001</small>
                                </div>
                            </div>
                            <div className="col-md-6 px-0 px-md-0 py-3 py-md-3 mx-auto overflow-hidden">
                                <div className="col-md-12 col-lg-12 col-xl-12 mb-3 form-floating">
                                    <MDBInput type="text" name="calculate" id="nrCalculate" value={docNum?.calculate ? docNum?.calculate : ''} className="form-control-sm text-black px-3" onChange={(e) => setDocNum({...docNum, calculate: e.target.value})} label={t('NO_CALCULATE')}  />
                                    <small className='font-70'>{t('E.G.')} {t('Kal')}-{new Date().getFullYear()}-0000001</small>
                                </div>
                                <div className="col-md-12 col-lg-12 col-xl-12 mb-3 form-floating">
                                    <MDBInput type="text" name="bank" id="nrBankDocument" value={docNum?.bank ? docNum?.bank : ''} className="form-control-sm text-black px-3" onChange={(e) => setDocNum({...docNum, bank: e.target.value})} label={t('NO_BANK_DOCUMENT')}  />
                                    <small className='font-70'>{t('E.G.')} {t('BANK')}-{new Date().getFullYear()}-0000001</small>
                                </div>
                                <div className="col-md-12 col-lg-12 col-xl-12 mb-3 form-floating">
                                    <MDBInput type="text" name="saldo" id="nrSaldoDocument" value={docNum?.saldo ? docNum?.saldo : ''} className="form-control-sm text-black px-3" onChange={(e) => setDocNum({...docNum, saldo: e.target.value})} label={t('NO_SALDO_DOCUMENT')}  />
                                    <small className='font-70'>{t('E.G.')} {t('SALDO')}-{new Date().getFullYear()}-0000001</small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DocumentNumber