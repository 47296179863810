import React, { useState, useEffect, useRef } from 'react';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import SubNav from '../../PageComponents/Nav/SubNav'
import Transactions from './Transactions';
import AccountingPlans from './AccountingPlans';
import TransactionDetails from './TransactionDetails';
import RegistrationOfGoods from './RegistrationOfGoods';
import PurchasesRegistrationOfGoods from './PurchasesRegistrationOfGoods';
import helper from '../../Services/helper';

const links = [
    {path:"finances/transactions", name:helper().translate("FINANCIAL_ORDER"), icon:"fas fa-file-invoice-dollar mr-2", component: Transactions, type:"link"},
    {path:"finances/transactions?type=1", name:helper().translate("FINANCIAL_ORDER"), icon:"fas fa-file-invoice-dollar mr-2", component: Transactions, type:"menu"},
    {path:"finances/transactionDetails/:id", name:"Urdheresa financiare id", icon:"fas fa-money-bill mr-2", component: TransactionDetails, type:"link"},
    {path:"finances/transactions?type=2", name:helper().translate("OPEN_BALANCE"), icon:"fas fa-money-bill mr-2", component: Transactions, type:"menu"},
    {path:"finances/accounting", name:helper().translate("ACCOUNTING_PLAN"), icon:"fas fa-money-bill mr-2", component: AccountingPlans, type:"menu"},
    {path:"finances/registration-goods/:id", name:helper().translate("REGISTRATION_OF_GOODS"), icon:"fas fa-money-bill mr-2", component: RegistrationOfGoods, type:"link"},
    {path:"finances/registration-goods-list", name:helper().translate("REGISTRATION_OF_GOODS"), icon:"fas fa-fw fa-layer-group mr-2", component: PurchasesRegistrationOfGoods, type:"menu"},
    {path:"finances/registration-goods", name:helper().translate("REGISTRATION_OF_GOODS"), icon:"fas fa-fw fa-layer-group mr-2", component: RegistrationOfGoods, type:"link"},
    {path:"finances/transactions?type=3", name:helper().translate("BALANCE_ACCOUNTS_PAYABLE"), icon:"fas fa-money-bill mr-2", component: Transactions, type:"menu"},
    {path:"finances/transactions?type=4", name:helper().translate("BALANCE_ACCOUNTS_RECEIVABLE"), icon:"fas fa-money-bill mr-2", component: Transactions, type:"menu"},
]

const Finances = (props) => {
    return (
        <div className="d-flex">
            <div className="p-0 position-relative sub-menu">
                <SubNav links={links} />
            </div>
            <div className="w-100 overflow-x-hidden pr-0">
                {props.location.pathname === '/finances' && <Transactions />}
                <Router>
                    <Switch>
                        {links.map((link)=>{
                            return <Route path={`/${link.path}`} component={link.component} />
                        })}
                    </Switch>
                </Router>
            </div>
        </div>
    )
}

export default Finances
