import React, { useState, useEffect , useRef } from "react";
import Search from "../../PageComponents/Search/Search_Old";
import { MDBInput  } from "mdb-react-ui-kit";
import Select , {components} from "react-select";
import apiService from "../../Services/apiServices";
import { confirmAlert } from "react-confirm-alert";
import { useToasts } from "react-toast-notifications";
import globals from "../../Services/globals";
import "../../App";
import helper from "../../Services/helper";
import { Checkbox } from "@material-ui/core";
import { blue } from "@material-ui/core/colors";


function AddTransactionBanks(props) {
  const [paymentTypes , setPaymentTypes] = useState([]);
  const [banks , setBanks] = useState([]);
  const [accountingGroup , setAccountingGroup] = useState([]);
  const [accountingSale , setAccountingSale] = useState([]);
  const [accountingBuy , setAccountingBuy] = useState([]);
  const [partners , setPartners] = useState([]);
  const [allPayments , setAllPayments] = useState([]);
  const [editPaymnets , setEditPayments] = useState([]);
  const [rowNum , setRowNum] = useState(0);
  const [bankId , setBankId] = useState(null);
  const [totalDebit , setTotalDebit] = useState(0);
  const [totalCredit , setTotalCredit] = useState(0);
  const [firstRowEditModeValue , setFirstRowEditModeValue] = useState(0);
  const [partnerStringLength , setPartnerStringLength] = useState(0);
  const [accountingStringLength , setAccountingStringLength] = useState(0);
  const [search , setSearch] = useState('');
  var [start , setStart] = useState();
  const { addToast } = useToasts();
  const [columnNum,setColumnNum]=useState(0);
  const ref3 = useRef();
  const ref4 = useRef();
  const ref5 = useRef();
  const ref6 = useRef();
  
  const [projects , setProjects] = useState([]);
  const newTrasaction = {
    no: 1,
    id: 0,
    date: new Date(),
    description: "",
    reference: "",
    partnerId: null,
    partnerName: '',
    paymentType: null,
    warehouseId: null,
    userId: null,
    cashBoxId: 0,
    bankId: typeof(bankId) == 'undefined' ? null : bankId,
    projectId: null,
    accountId: null,
    debit: 0,
    credit: 0,
    saldo: null,
    createdAt: null,
    createdBy: null,
    changedAt: null,
    changedBy: null,
    addButton: true,
    editMode: false,
    validate: true,
    D: false,
    H: false,
    unChangedId: 1,
    status: 0,
  };
  const [transaction, setTransaction] = useState([{...newTrasaction , editMode: true}]);
  const [transactionCopy, setTransactionCopy] = useState([{...newTrasaction , editMode: true}]);

  const getPaymentItem = (paymentTypeData) => {
    var id = props.match.params.id;
    if(id> 0){
      apiService()
      .query("getPayment" , "id="+id)
      .then((response) => {
        if (response.success) {
          var data = response.data;
          var count = 1;
          if(data[0].paymentType === 1 || data[0].paymentType === 3 ){
            getEditPayments(data[0].partnerId,data[0].paymentType,0,data[0].id);
            getRowPayments(data[0].partnerId,data[0].paymentType,0,data[0].date);
          }
          data.forEach(i=> {i.no = count; count++});
          for(var i= 0 ; i< data.length ; i++){
            data[i].addButton = false;
            data[i].editMode = false;
            setTotalDebit( parseFloat(data[i].debit));
            setTotalCredit(parseFloat(data[i].credit));
            data[i].date = new Date(data[i].date);
            if(paymentTypeData.find(p => p.value === data[i].paymentType).type === "D"){
              data[i].D = true;
              setFirstRowEditModeValue(data[i].credit);
            }else if (paymentTypeData.find(p => p.value === data[i].paymentType).type === "H"){
              data[i].H = true;
              setFirstRowEditModeValue(data[i].debit);
            }
            if(i === (data.length - 1)) {
            data[i].addButton = true;
            // data[i].editMode = true;
            }
            }
            setTransaction(data);
        }
         else {
          alert("Error: getting paymentItem" + response.errorMessage);
        }
      });
    }
  
  };
  const getPaymentTypes = () => {
    apiService()
      .query("getPaymentTypes")
      .then((response) => {
        if (response.success) {
         let options = response.data.filter((i) => i.id != 2 && i.id != 4 && i.id != 5 )
         let filterOption = options.map((item, index) => ({
            value: item.id,
            label: item.name,
            type: item.type
          }));
          setPaymentTypes(filterOption);
          getPaymentItem(filterOption);
        }
         else {
          alert("Error: getting Pagesat" + response.errorMessage);
        }
      });
  };
  const getBanks = () => {
    let options;
    apiService()
      .query("getBanks")
      .then((response) => {
        if (response.success) {
          options = response.data.map((item, index) => ({
            value: item.id,
            label: item.name
            
          }));
          setBanks(options);
        } else {
          alert("Error: getting Banks" + response.errorMessage);
        }
      });
  };
  const getProjects = () => {
    let options;
    apiService()
      .query("getProjects")
      .then((response) => {
        if (response.success) {
          let data = response.data;
          let firstItem = {id: null , name: helper().translate("NO_PROJECT")};
          data.unshift(firstItem);
          options = data.map((item, index) => ({
            value: item.id,
            label: item.name
          }));
          setProjects(options);
        } else {
          alert("Error: getting Projects" + response.errorMessage);
        }
      });
  };
  const getAccountingPlan = () => {
    let options;
    apiService()
      .query("getAccountingPlan")
      .then((response) => {
        if (response.success) {
          options = response.data.map((item, index) => ({
            value: item.id,
            label: item.name,
            accountNumber: item.accountNumber
          }));
          setAccountingGroup(options);
        } else {
          alert("Error: getting PlaniKontabel" + response.errorMessage);
        }
      });
  };

  const getAccountsTypeSale = () => {
    let options;
    apiService()
      .query("getAccountsTypeSale")
      .then((response) => {
        if (response.success) {
          options = response.data.map((item, index) => ({
            value: item.id,
            label: item.name,
            accountNumber: item.accountNumber
          }));
          setAccountingSale(options);
        } else {
          alert("Error: getting PlaniKontabel" + response.errorMessage);
        }
      });
  };
  const getAccountsTypePurchase = () => {
    let options;
    apiService()
      .query("getAccountsTypePurchase")
      .then((response) => {
        if (response.success) {
          options = response.data.map((item, index) => ({
            value: item.id,
            label: item.name,
            accountNumber: item.accountNumber
          }));
          setAccountingBuy(options);
        } else {
          alert("Error: getting PlaniKontabel" + response.errorMessage);
        }
      });
  };
  const getPartners = () => {
    apiService().get('partners').then((response) => {
      let options
      if (response.success) {
        let data = response.data;
        let firstItem = {id: 0 , companyName: "" , fiscalNo: "" , cityName: ""};
        data.unshift(firstItem);
          options = data.map((item, index) => ({
            value: item.id,
            label: item.companyName,
            fiscalNo: item.fiscalNo,
            cityName: item.cityName
          }));
        
        setPartners(options);
      } else {
        console.log('Error:' + response.errorMessage);
      }
    });
  }

  const saveOnlyOnce = () => {
    var element = document.getElementById("saveOnce");
    element.classList.toggle("disabled");
  }

  useEffect(()=>{    
    let filtered = filterTransactions(search);
    if(search != ""){
      setTransactionCopy(filtered); 
    }else {
      setTransactionCopy(transaction); 
    }
        
  },
    [transaction])

 useEffect(()=>{
   getPaymentTypes();
   getBanks();
   getProjects();
   getAccountingPlan();
   getPartners();
   getAccountsTypeSale();
   getAccountsTypePurchase();
  //  setStart(document.getElementById('start1'))
  //  getPaymentItem();
 },[])

 const selectStyles = {
   container: provided => ({
     ...provided,
     minWidth: '190px'
   }),
   control: (provided, state) => ({
     ...provided,
     background: "#fff",
     borderColor: "#9e9e9e",
     minHeight: "28.59px",
     height: "28.59px",
     boxShadow: state.isFocused ? null : null,
   }),

   valueContainer: (provided, state) => ({...provided,height: "28.59px",padding: "0 6px",}),
   input: (provided, state) => ({ ...provided, margin: "0px",}), indicatorSeparator: (state) => ({ display: "none", }),
   indicatorsContainer: (provided, state) => ({...provided,height: "28.59px",}),
 };

 const multipleItemStyle = {
  container: provided => ({
    ...provided,
    minWidth: '190px'
  }),
  control: (provided, state) => ({
    ...provided,
    background: "#fff",
    borderColor: "#9e9e9e",
    minHeight: "28.59px",
    height: "28.59px",
    boxShadow: state.isFocused ? null : null,
  }),
  menu: (provided, state) => ({
   ...provided,
   width: "auto"
  //  marginTop: "30px"
 }),
  menuList: (provided, state) => ({
   ...provided,
   width: "auto"
 }),

  valueContainer: (provided, state) => ({...provided,height: "28.59px",padding: "0 6px",}),
  input: (provided, state) => ({ ...provided, margin: "0px",}), indicatorSeparator: (state) => ({ display: "none", }),
  indicatorsContainer: (provided, state) => ({...provided,height: "28.59px",}),
};


 const addButtonLastRow =  (arrTransaction) => {   
  for(var i= 0 ; i< arrTransaction.length ; i++){
    arrTransaction[i].addButton = false;
    //arrTransaction[i].editMode = false;
    if(i === (arrTransaction.length -1 )) {
      arrTransaction[i].addButton = true;
    }
  }
 }

 
 const filterTransactions = (searchString) => { 

  if (searchString.length > 0) {   
      let searchTransaction = transaction.filter((item) => {
      return (       
           //item?.partnerName?.toString().toLowerCase().match(searchString)
           item.partnerName === '' || item.editMode ||  item?.partnerName?.toString().toLowerCase().match(searchString)
        )
      });
   
      addButtonLastRow(searchTransaction);
      // var count = 1;
      // searchTransaction.forEach(i=> {i.no = count; count++});
      // setTransactionFilter(searchTransaction);
      return searchTransaction;
    } 
    else {
      let allTransaction = [...transaction];
      addButtonLastRow(allTransaction);
      return allTransaction;
    }
 
  };


 const findChange = (e) => { 
    setSearch(e.target.value);   
    let searchString = e.target.value.trim().toLowerCase();

    let searchTransaction = filterTransactions(searchString);
    setTransactionCopy(searchTransaction);
 
  };

  const fieldChange = (e,no) => {
    if(e.target.name === "date"){
      let newArr = [...transaction];
      var today = new Date();
      // time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
      newArr[no-1][e.target.name] = new Date(e.target.value) ;
      setTransaction(newArr);
    } else if(e.target.name === "description"){
      let newArr = [...transaction];
      newArr[no-1][e.target.name] = e.target.value;
      setTransaction(newArr);
    }else if(e.target.name === "reference"){
      let newArr = [...transaction];
      newArr[no-1][e.target.name] = e.target.value;
      setTransaction(newArr);
    }
    else{
      let allPay = [...allPayments];
      let totaliDebit = 0;
      let totaliCredit = 0;
      let newArr = [...transaction];
      if(  (parseFloat(e.target.value) >= 0 )|| e.target.value === ''){
        newArr[no-1][e.target.name] = e.target.value;
        newArr.forEach(e => {
          totaliDebit = totaliDebit + parseFloat(e.debit);
          totaliCredit = totaliCredit + parseFloat(e.credit);
        });
        // console.log(allPay[no-1])
        let leftValue= e.target.value;
        if(no === 1 && newArr[no-1].id > 0){
            leftValue = parseFloat(leftValue) - firstRowEditModeValue;
        }
        if(leftValue >= 0 || isNaN(leftValue)){
          allPay[no-1]?.forEach(e => {
            if(parseFloat(leftValue) >= e.amountLeft && leftValue !== ''){
              e.check = true;
              leftValue = parseFloat(leftValue) - e.amountLeft;
              e.toPay = e.amountLeft;
            }else
            if(parseFloat(leftValue) < e.amountLeft || leftValue === '' ){
              if(parseFloat(leftValue) !== 0 && leftValue !== ''){
                e.check = true;
                e.toPay = parseFloat(leftValue);
                leftValue = 0;
              }else if(parseFloat(leftValue) === 0 || leftValue === ''){ // me shiqu ehde njere kete loop
                e.toPay = 0;
                e.check = false;
              }
            }
          });
        }
      }
      setAllPayments(allPay);
      setTotalCredit( parseFloat(totaliCredit));
      setTotalDebit( parseFloat(totaliDebit));
      setTransaction(newArr);
    }
  };

  const selectChange = (e,obj,no) => {
    if(obj.name === "bankId"){
      let newArr = [...transaction];
      for(var i= 0 ; i< newArr.length ; i++){
        newArr[i][obj.name] = e.value;
        }
      newArr[0].isChanged = true
      setTransaction(newArr);
      setBankId(e.value);
    }else if(obj.name === "paymentType"){
      let newArr = [...transaction];
      newArr[no-1][obj.name] = e.value;
      newArr[no-1]['accountId'] = null;
      newArr[no-1]['partnerId'] = null;
      newArr[no-1]['debit'] = 0;
      newArr[no-1]['credit'] = 0;
      deletePaymentArr(no)
    if(paymentTypes.find(p => p.value == e.value).type === "D"){
      newArr[no-1]["D"] = true;
      newArr[no-1]["H"] = false;
    }else if(paymentTypes.find(p => p.value == e.value).type === "H"){
      newArr[no-1]["D"] = false;
      newArr[no-1]["H"] = true;
    }
    setTransaction(newArr);
    } else if(obj.name === "partnerId"){
      let newArr = [...transaction];
      if(newArr[no-1].partnerId != e.value){
        let checkduplicate = newArr.filter((f) => (f.paymentType === newArr[no-1].paymentType) && ((f.paymentType === 1) || (f.paymentType === 3)));
        let checkFinal = checkduplicate.filter((f) =>f.partnerId === e.value );
        if(newArr[no-1]['paymentType'] != null){
          if(checkFinal.length === 0){
            newArr[no-1][obj.name] = e.value;
            newArr[no-1]['partnerName'] = partners.find((p) => p.value == e.value).label;
            if(newArr[no-1]['paymentType'] != null ){
              getRowPayments(e.value,newArr[no-1]['paymentType'],no-1,newArr[no-1]['date'])
            }
            setTransaction(newArr);
          }else{
            addToast(helper().translate("DUPLICATE_OF_PAYMENTTYPE_AND_PARTNER_ISNT_ALLOWED"), {
              appearance: "error",
              autoDismiss: true,
            });
          }
      } else{
        addToast(helper().translate("PLEASE_CHOOSE_PAYMENT_FRIST"), {
          appearance: "error",
          autoDismiss: true,
        });
      }
      }
    }
    else{
    let newArr = [...transaction];
    newArr[no-1][obj.name] = e.value;
    setTransaction(newArr);
    }
  };

  const payFieldChange = (e,no,countNum) =>{
    let newArr = [...transaction];
    let total = 0;
    // newArr[no].d
    let allPaymentsCopy = [...allPayments];
    if(e.target.name === "toPay"){
      let input = parseFloat(e.target.value)
      if(input >0 && input <= allPaymentsCopy[no][countNum-1].amountLeft) {
        allPaymentsCopy[no][countNum-1].toPay = input;
        allPaymentsCopy[no][countNum-1].check = true;
      }else{
        allPaymentsCopy[no][countNum-1].check = false;
        allPaymentsCopy[no][countNum-1].toPay = input;
      }
     
    }
    allPaymentsCopy[no].forEach(e => {
      if(e.check){
        total = total + parseFloat(e.toPay) 
      }
    });
    if(newArr[no].H){
      if(no === 0 && newArr[no].id > 0){
        newArr[no].debit = total + firstRowEditModeValue;
        setTotalDebit(parseFloat((total) + firstRowEditModeValue).toFixed(2));
      }else{
        newArr[no].debit = total;
        setTotalDebit(parseFloat(total).toFixed(2));
      }
      
    }else if(newArr[no].D){
      if(no === 0 && newArr[no].id > 0){
        newArr[no].credit = total + firstRowEditModeValue;
        setTotalCredit(parseFloat((total) + firstRowEditModeValue).toFixed(2));
      }else{
        
        newArr[no].credit = total;
        setTotalCredit(parseFloat(total).toFixed(2));
      }
    };
    setAllPayments(allPaymentsCopy);
    setTransaction(newArr);
  }

  const checkChange=(e,no, countNum) => {
    let transactionCopy = [...transaction];
    // console.log(transactionCopy , no , countNum)
    let calculateTotal = 0;
    let allPaymentsCopy = [...allPayments];
    if(allPaymentsCopy[no][countNum-1].check === false){
      allPaymentsCopy[no][countNum-1].check = true
      allPaymentsCopy[no][countNum-1].toPay = (allPaymentsCopy[no][countNum-1].total - allPaymentsCopy[no][countNum-1].amounPaid).toFixed(2);
    }else{
      allPaymentsCopy[no][countNum-1].check = false
      allPaymentsCopy[no][countNum-1].toPay = 0;
    }
    
    //calculating total for each row
    let allpay = allPaymentsCopy[no].filter( (p) => p.check === true);
    // console.log(allpay);
    allpay.forEach(i => {
      calculateTotal =  calculateTotal + parseFloat(i.toPay);
    });
    // console.log(calculateTotal)
    if(transactionCopy[no].H){
      if(no === 0 && transactionCopy[0].id > 0){
        transactionCopy[no].debit = calculateTotal + firstRowEditModeValue;
      setTotalDebit(parseFloat(calculateTotal) + firstRowEditModeValue);
      }else{
        transactionCopy[no].debit = calculateTotal;
        setTotalDebit(parseFloat(calculateTotal));
      }
      
    }else
    if(transactionCopy[no].D){
      if(no === 0 && transactionCopy[0].id > 0){
        transactionCopy[no].credit = calculateTotal+ firstRowEditModeValue;
        setTotalCredit(parseFloat(calculateTotal) + firstRowEditModeValue);
      }else{

        transactionCopy[no].credit = calculateTotal;
        setTotalCredit(parseFloat(calculateTotal));
      }
    }
    setTransaction(transactionCopy)
    setAllPayments(allPaymentsCopy)
  }

  const getRowPayments = (parnterId , paymentType,no,date) => {
    let dateFromat = helper().formatDate(date,'MM-dd-yyyy')+ " 23:59:59";
    if(parnterId){
      var params = `&partnerNum=${parnterId}&paymentType=${paymentType}&date=${dateFromat}`;
      apiService()
      .query("getSaleAndPurchasePayments" , params)
      .then((response) => {
        if (response.success) {
          let data = response.data;
          let count = 1;
         if(data != null || data?.length > 0){
           data.forEach(e => {
            e.check = false;
            e.countNumber = count++;
            e.toPay = 0;
           });
           let AddPay = [...allPayments];
           AddPay[no] = data;
           setAllPayments(AddPay);
         }
        }
         else {
          alert("Error: getting getRowPayments" + response.errorMessage);
        }
      });
    }else{
        let AddPay = [...allPayments];
         AddPay[no] = [];
         setAllPayments(AddPay);
       }
  };
  const getEditPayments = (parnterId , paymentType,no,id) => {
    if(parnterId){

      var params = `&id=${id}&partnerNum=${parnterId}&paymentType=${paymentType}`;
      apiService()
      .query("getSalePurchasePayments" , params)
      .then((response) => {
        if (response.success) {
          let data = response.data;
          let count = 1;
         if(data != null || data?.length > 0){
           data.forEach(e => {
            e.check = false;
            e.countNumber = count++;
           });
           let AddPay = [...editPaymnets];
           AddPay[no] = data;
           setEditPayments(AddPay);
         }
        }
         else {
          alert("Error: getting getRowPayments" + response.errorMessage);
        }
      });
    }
  };

  const addNewRow = () => {
    var id = props.match.params.id
    let newItem = newTrasaction;
    newItem.no = Math.max(...transaction.map(o => o.no), 0) + 1;
    setRowNum(newItem.no-1);
    
    //newItem.unChangedId = Math.max(...transaction.map(o => o.no), 0) + 1;
    newItem.status = 1;
    if(id> 0){
      newItem.bankId = transaction[0]?.bankId;
    }

    const newState = [...transaction, newItem];

    for(var i= 0 ; i< newState.length ; i++){
      newState[i].addButton = false;
      newState[i].editMode = false;
      if(i === (newState.length - 1)) {
        newState[i].addButton = true;
        newState[i].editMode = true;
        }
      }
      setTransaction(newState);  
  };

  const addRowEnter = () => {
    if(event.key === 'Enter'){
      addNewRow();
    }
  }


  const rowClick = (no) => {
    //enter edit mode
    let newItem = [...transaction];
    let editPay = [...editPaymnets]
    newItem.forEach(e => e.editMode = false);
    newItem[no-1].editMode = true;
    if(newItem[no-1].id > 0){
      newItem[no-1].status = 2;
    }else{
      newItem[no-1].status = 1;
    }
    setTransaction(newItem);
    setColumnNum(0);
    setRowNum(no-1);

    if(newItem[no-1].id > 0 && editPay[no-1]?.length > 0 && no == 1 ){
      addToast(helper().translate("CHANGE_PAYMENTTYPE_PARTNERID"), {
        appearance: "warning",
        autoDismiss: true,
      });
    }
  }

  function onClear(){
    let newReset = {...newTrasaction};
    newReset.bankId = null;
    setTransaction([newReset]);
    setBankId(null);
    setAllPayments([]);
    setEditPayments([]);
    setFirstRowEditModeValue(0);
    setTotalCredit(0);
    setTotalDebit(0);
  }

  const newHandle = () => {
    let trans = [...transaction];
    let obj = trans.filter(e => (e.status === 2) || (e.status === 1));
    trans.forEach(e => {
      if(e.date != ''  && e.paymentType != null && e.userId != null && e.bankId != null  && e.accountId != null ){
        e.validate = true;
        e.editMode = false;
       }else{
         e.validate = false;
         e.editMode = true;
       }
    })
    // setTransaction(trans);
    

    if(obj.length === 0 && typeof(transaction[0].isChanged) === 'undefined'){
      onClear();
    }else{
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="custom-ui" >
              <h1>{helper().translate("ARE_YOU_SURE")}</h1>
              <p>{helper().translate("WANT_TO_SAVE_ACTUAL_CHANGES")}</p>
              <button
                onClick={() => {
                  save();
                  onClose();
                }}
              >
                {helper().translate("YES")}
              </button>
              <button onClick={() =>{
                onClear();
                onClose();
              }} className="ms-3">{helper().translate("NO")}</button>
            </div>
          );
        }
      })
    }
  }

 
  const handleDelete = (id , no)=> {
    if(id>0){
      apiService()
      .query("deletePayment", "id=" + id)
      .then((response) => {
        if (response.success) {
          let allPaymentsCopy = [...allPayments]
          allPaymentsCopy.splice(no-1, 1);
          let newItem = [...transaction];
          let arr = newItem.filter((row) => row.no !== no);
          var num = 1;
          arr.forEach(i=> {i.no = num; num++});
          for(var i= 0; i< arr.length; i++  ){
            if(i === (arr.length - 1)) {
              arr[i].addButton = true;
              }}
          addToast(helper().translate("SUCCESSFULLY_DELETED"), {
            appearance: "success",
            autoDismiss: true,
          });
          if(arr.length == 0){
            setTransaction([newTrasaction]);
          }else 
          if(arr.length >0){
            setTransaction(arr);
          }
          setSearch('');
          setEditPayments([]);
          setAllPayments(allPaymentsCopy);
          setRowNum(0);
        } else {
          alert("Error:" + response.errorMessage);
        }
      });
      
    }else {
          let allPaymentsCopy = [...allPayments]
          allPaymentsCopy.splice(no-1, 1);
          let newItem = [...transaction];
          let arr = newItem.filter((row) => row.no !== no);
          var num = 1;
          arr.forEach(i=> {i.no = num; num++});
           for(var i= 0; i< arr.length; i++  ){
            if(i === (arr.length - 1)) {
              arr[i].addButton = true;
              }}
              setSearch('');
          if(arr.length == 0){
            setTransaction([newTrasaction]);
          }else 
          if(arr.length >0){
            setTransaction(arr);
          }
          setAllPayments(allPaymentsCopy);
    }
    }

  const alertnOnDelete = (id , no)=> {

    if(id > 0 ){
      confirmAlert({
          customUI: ({ onClose }) => {
            return (
              <div className="custom-ui">
                <h1>{helper().translate("ARE_YOU_SURE")}</h1>
                <p>{helper().translate("WANT_TO_DELETE_THIS_TRANSACTION")}</p>
                <button
                  onClick={() => {
                    handleDelete(id, no);
                    onClose();
                  }}
                >
                  {helper().translate("YES")} , {helper().translate("DELETE")}!
                </button>
                <button onClick={onClose}>{helper().translate("NO")}</button>
              </div>
            );
          },
        });
    }else if(id == 0){
      handleDelete(id, no);
    }
  }

 
  const DeleteInvoicePayment = (saleId, purchaseId, paymentId , no) => {
    if(paymentId>0){
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <h1>{helper().translate('ARE_YOU_SURE')}</h1>
            <p>{helper().translate('WANT_TO_DELETE_THIS_PAYMENT')}</p>
            <button
              onClick={() => {
                if(saleId){
                  var params = `&id=${paymentId}&saleId=${saleId}&purchaseId=${0}`;
                }else if(purchaseId){
                  var params = `&id=${paymentId}&saleId=${0}&purchaseId=${purchaseId}`;
                }
                  apiService()
                  .query("deletePaymentSales_deletePaymentPurchases", params)
                  .then((response) => {
                    // console.log(response)
                    if (response.success) {
                      let transactionCopy = [...transaction]
                      let editPaymnetsCopy = [...editPaymnets]
                      var num = 1;
                      let total = 0;
                      if(saleId){
                        var arr = editPaymnetsCopy[0].filter((row) => row.id !== saleId);
                      }else if(purchaseId){
                        var arr = editPaymnetsCopy[0].filter((row) => row.id !== purchaseId);
                      }
                      arr.forEach(i=> {
                        i.countNumber = num; 
                        num++;
                        total = total + i.amounPaid;
                      });
                    
                      editPaymnetsCopy[0] = arr;
                      addToast("Fshirja u be me sukses", {
                        appearance: "success",
                        autoDismiss: true,
                      });

                      // on delete calculate debit and credit 
                      if(transactionCopy[0].H){
                        transactionCopy[0].debit = total;
                        transactionCopy[0].saldo = transactionCopy[0].debit - transactionCopy[0].credit;
                        setFirstRowEditModeValue(total);
                        setTotalDebit(total);
                      }else 
                      if(transactionCopy[0].D){
                        transactionCopy[0].credit = total;
                        transactionCopy[0].saldo = transactionCopy[0].debit - transactionCopy[0].credit;
                        setFirstRowEditModeValue(total);
                        setTotalCredit(total);
                      }
                      setTransaction(transactionCopy);
                      let fromDelete ;
                      save(fromDelete = true);
                      setEditPayments(editPaymnetsCopy);
                      getRowPayments(transactionCopy[0].partnerId, transactionCopy[0].paymentType,0,transactionCopy[0].date);
                    } else {
                      alert("Error:" + response.errorMessage);
                    }
                  });
                
                onClose();
              }}
            >
              {helper().translate('YES')}
            </button>
            <button onClick={onClose}>{helper().translate('NO')}</button>
          </div>
        );
      },
    });
  }
}

  const deletePaymentArr = (no) => {
    let allPaymentsCopy = [...allPayments]
    allPaymentsCopy.splice(no-1, 1);
    setAllPayments(allPaymentsCopy);
  }

  const save = (fromDelete) => {
    var id = props.match.params.id;

    if(id == 0) {
      saveOnlyOnce();
      let obj = [...transaction];
      let insertPayments = [];
      obj.forEach( e => {
        e.saldo = e.debit - e.credit;
        e.userId = globals().getLogedInUser().data.id;
        e.createdBy= globals().getLogedInUser().data.username;

         if(e.date != ''   && e.paymentType != null && e.userId != null && e.bankId != null  && e.accountId != null ){
          e.validate = true;
          e.editMode = false;
         }else{
           e.validate = false;
           e.editMode = true;
         }
          let contextObj = {
            entity: e,
            status: 1,
            type: 'insertPayments'
          }
          insertPayments.push(contextObj);
      });

      let arr = obj.filter((p) => p.validate == false);
      if(arr.length === 0){
        apiService().saveChanges(insertPayments).then((response) => {
          let resData = response.data;
          resData?.sort(function(a, b) {
          return a?.no - b?.no;
          } );
          let allpay = [];
          let allpaymentCopy = [...allPayments]
          if(allPayments.length > 0){
            resData?.forEach(e => {
              allpaymentCopy[e.no-1]?.forEach(i => {
                if(i.check ===  true){
                  i.paymentId = e.id;
                  i.salePurchaseId = i.id;
                }
                if(e.paymentType === 3){
                  i.isSale = true;
                  i.isPurchase = false;
                }else
                if(e.paymentType === 1){
                  i.isPurchase = true;
                  i.isSale = false;
                }
              });
            });

            let insertAllPaymetns = [];
            for (let i = 0; i < allPayments.length; i++) {
              allpay[i] =  allPayments[i]?.filter((e) => e.check === true && e.toPay !== 0);
              allpay[i]?.forEach(p => {
                p.createdBy = globals().getLogedInUser().data.username;
                let contextObj = {
                  entity: p,
                  status: 1,
                  type: 'insertSalePurchasePayments'
                }
                insertAllPaymetns.push(contextObj);
              });
              apiService().saveChanges(insertAllPaymetns).then((response) => {
                saveOnlyOnce();
                addToast(helper().translate("SUCCESSFULLY_ADDED"), {
                  appearance: "success",
                  autoDismiss: true,
                });
                onClear();
              })
            }

          }else if(response.success){
            saveOnlyOnce();
            addToast(helper().translate("SUCCESSFULLY_ADDED"), {
              appearance: "success",
              autoDismiss: true,
            });
            onClear();
          }
       }) 
      }else{
        saveOnlyOnce();
        addToast(helper().translate("FILL_REQUIRED_FIELDS"), {
          appearance: "error",
          autoDismiss: true,
        });
      }
      setTransaction(obj);

    }else if(id> parseInt(0)){
      let obj = [...transaction];
      let editAllPay = [...editPaymnets]
      var totalFirstRowPayment = 0;
      editAllPay[0]?.forEach(i => {
        totalFirstRowPayment = totalFirstRowPayment + parseFloat(i.amounPaid);
      });
      let totalFirstRow = 0;
      if(obj[0].D ){
        totalFirstRow = obj[0].credit;
      }else if(obj[0].H){
        totalFirstRow = obj[0].debit;
      }

      if((obj[0].id > 0 && parseFloat(totalFirstRow) >=  parseFloat(totalFirstRowPayment) ) || obj[0].id === 0 || fromDelete === true){
        let updatePayments = [];
        obj.forEach( e => {
          e.saldo = e.debit - e.credit;
          e.userId = globals().getLogedInUser().data.id;
  
           if(e.id > 0 ){
            e.changedBy= globals().getLogedInUser().data.username;
            e.bankId = transaction[0].bankId;
            let contextObj = {
              entity: e,
              status: 2,
              type: 'updatePayments'
            }
            updatePayments.push(contextObj);
           } else if(e.id === 0){
            e.createdBy= globals().getLogedInUser().data.username;
            let contextObj = {
              entity: e,
              status: 1,
              type: 'insertPayments'
            }
            updatePayments.push(contextObj);
           }
           if(e.date != '' &&  e.paymentType != null && e.userId != null && e.bankId != null  && e.accountId != null ){
            e.validate = true;
            e.editMode = false;
           }else{
             e.validate = false;
             e.editMode = true;
           }
          });
          let arr = obj.filter((p) => p.validate == false);
        if(arr.length === 0){
          apiService().saveChanges(updatePayments).then((response) => {
            if(response.success){
              
              let resData = response.data
              resData.sort(function(a, b) {
              return a?.no - b?.no;
              } );
  
              let allpay = [];
              let allpaymentCopy = [...allPayments]
  
              if(allPayments.length > 0){
                resData.forEach(e => {
                  if(e?.no > 0){
                    allpaymentCopy[e.no-1]?.forEach(i => {
                      if(i.check ===  true){
                        i.paymentId = e.id;
                        i.salePurchaseId = i.id;
                      }
                      if(e.paymentType === 3){
                        i.isSale = true;
                        i.isPurchase = false;
                      }else
                      if(e.paymentType === 1){
                        i.isPurchase = true;
                        i.isSale = false;
                      }
                    });
                  }else{
                    let payId = parseInt(props.match.params.id) ;
                    let transCopy = [...transaction]
                    allpaymentCopy[0]?.forEach(i => {
                      if(i.check ===  true){
                        i.paymentId = payId;
                        i.salePurchaseId = i.id;
                      }
                      if(transCopy[0].paymentType === 3){
                        i.isSale = true;
                        i.isPurchase = false;
                      }else
                      if(transCopy[0].paymentType === 1){
                        i.isPurchase = true;
                        i.isSale = false;
                      }
                    });
                  }
                });
    
                let insertAllPaymetns = [];
                for (let i = 0; i < allPayments.length; i++) {
                  allpay[i] =  allPayments[i]?.filter((e) => e.check === true && e.toPay !== 0);
                  allpay[i]?.forEach(p => {
                    p.createdBy = globals().getLogedInUser().data.username;
                    let contextObj = {
                      entity: p,
                      status: 1,
                      type: 'insertSalePurchasePayments'
                    }
                    insertAllPaymetns.push(contextObj);
                  });
                  apiService().saveChanges(insertAllPaymetns).then((response) => {
                    // console.log(response);
                    if(response.success){
  
                      saveOnlyOnce();
                      addToast(helper().translate("SUCCESSFULLY_ADDED"), {
                        appearance: "success",
                        autoDismiss: true,
                      });
                      onClear();
                    }
                  })
                }
    
              } else{
                addToast(helper().translate("SUCCESSFULLY_MODIFIED"), {
                  appearance: "success",
                  autoDismiss: true,
                });
                props.history.push("/edit_bank/"+ transaction[0].id);
              } 
            }else {
              addToast("error update", {
                appearance: "error",
                autoDismiss: true,
              });
            }
         }) 
        }else{
          addToast(helper().translate("FILL_REQUIRED_FIELDS"), {
            appearance: "error",
            autoDismiss: true,
          });
        }
      }else {
        addToast(helper().translate("ROW_VALUE_CANT_BE_LOWER_THAN_AMOUNT_PAID"), {
          appearance: "error",
          autoDismiss: true,
        });
      }
  
      // setTransaction(obj);
      
    }
  };

  const partnerOption = props => {
    if(props.data.label.length > partnerStringLength){
      setPartnerStringLength(props.data.label.length);
    }
    // let stringMaxLength = 0;
    // let data = props.data.label;
    // if(data.length > stringMaxLength){
    //   stringMaxLength = data.length
    // }
    // console.log(data.length);
    return (
      <components.Option {...props}>
        <div className="d-flex text-truncate"><p style={{width: `${partnerStringLength * 8}px`}} className="m-0 text-left ">{props.data.label}</p>|<p style={{width: "120px"}} className="m-0 text-center ">{props.data.fiscalNo}</p>|<p style={{width: "120px"}} className="m-0 text-center ">{props.data.cityName}</p></div>
      </components.Option>
    );
  };

  const accountingOption = props => {
    if(props.data.label.length > accountingStringLength){
      setAccountingStringLength(props.data.label.length);
    }
    // let stringMaxLength = 0;
    // let data = props.data.label;
    // if(data.length > stringMaxLength){
    //   stringMaxLength = data.length
    // }
    // console.log(data.length);
    return ( 
      <components.Option {...props}>
        <div className="d-flex text-truncate"><p style={{width: `${accountingStringLength * 8}px`}} className="m-0 text-left ">{props.data.label}</p>|<p style={{width: "120px"}} className="m-0 text-center ">{props.data.accountNumber}</p></div>
      </components.Option>
    );
  };



  //document.onkeydown = checkKey; 


  function checkKey(e) {
    
  let arrFull = [...transaction]
  let arr = arrFull.filter(e => e.editMode === true);
  let getNum = arr[0].no;
    e = e || window.event;
    
    if (e.keyCode === 38) {
        if(getNum != 1){
        // up arrow
      arrFull[getNum-2].editMode = true;
      arrFull[getNum-1].editMode = false;
      setTransactionCopy(arrFull);
      setStart(document.getElementById('start1'))
      setColumnNum(0);
      }
    }else if (e.keyCode === 40){
        if(getNum != arrFull.length){
        //down
        arrFull[getNum].editMode = true;
        arrFull[getNum-1].editMode = false;
      setTransactionCopy(arrFull);
      setStart(document.getElementById('start1'))
      setColumnNum(0);
      }
    }else if (e.keyCode === 39 || e.keyCode === 37){
       //right arrow
      if(e.keyCode === 39){
        let nextColNum = columnNum+1;
        if(nextColNum <=8){
          setColumnNum(nextColNum);
          focusMethod(nextColNum);
        }
      }//left arrow
       else {
        let previousColNum = columnNum -1;
        if(previousColNum >= 1){
          setColumnNum(previousColNum);
          focusMethod(previousColNum);
        }
      }
      
  }
}
const focusMethod = columnNum => {
 
  if(columnNum === 1|| columnNum === 2 || columnNum === 7 ||columnNum === 8 ){
    var start = document.getElementById("start"+columnNum);
    start.focus();
  }else {
    switch (columnNum) {
      case 3:
        ref3.current.focus();
      break;
      case 4:
        ref4.current.focus();
      break;
      case 5:
        ref5.current.focus();
      break;
      case 6:
        ref6.current.focus();
      break;
      default:
        alert("ka gabim levizje mes kolonave")
        break;
    }
  }
}
  return (
    <div id="addTransaction">
      <div className="container-fluid px-2 mt-3">
        <Search headersFrom={"PAYMENTS"}  back="#/payments/banks" new="#/paymentbank/0" newHandle={newHandle} save={save}  change={(e) => findChange(e)} value={search}  />
        <div className="card border-left-primary shadow h-100 mt-1">
          <div className="card-body pt-1 pb-1">
            <div className="row">
              <div className="col-lg-6 col-12">
              <Select
                  name="bankId"
                  openMenuOnFocus={true}
                  placeholder={helper().translate("CHOOSE_BANK")}
                  styles={selectStyles}
                  className={transaction[0]?.validate == false ? transaction[0]?.bankId === null ? " is-invalid-s ": ''   : ''}
                  options={banks}
                  onChange={(e, obj) => selectChange(e, obj)}
                  value={banks.find(b => b.value == transaction[0]?.bankId) || ''}
                  />
              </div>
            </div>
          </div>
        </div>
        <div className="card shadow h-100 mt-1">
      <div className="card-header py-2"><h6 className="m-0 font-weight-bold text-primary">{helper().translate("TRANSACTION_LIST")}</h6></div>
        <div className="card-body table-responsive px-2">
          <table onKeyPress={addRowEnter}  className="table table-hover text-center ">
            <thead>
                <tr>
                    <th>{helper().translate("NR")}</th>
                    <th>{helper().translate("DATE")}</th>
                    <th>{helper().translate("REFERENCE")}</th>
                    <th>{helper().translate("DESCRIPTION")}</th>
                    <th>{helper().translate("PAYMENT_TYPE")}</th>
                    <th>{helper().translate("PROJECT")}</th>
                    <th>{helper().translate("PARTNER")}</th>
                    <th>{helper().translate("ACCOUNT")}</th>
                    <th>{helper().translate("DEBIT_IN")}</th>
                    <th>{helper().translate("CREDIT_OUT")}</th>
                    <th>{helper().translate("ADD")}</th>
                    <th>{helper().translate("DELETE")}</th>
                </tr>
            </thead>
            <tbody>
            {/* search ? transactionFilter :  */}
                { transactionCopy.map((item,key)=> (
                    <tr key={key}>
                      {/*  */}
                        <td   >{item.no}</td>
                        { item.editMode ? <td  >
                            <MDBInput
                            name="date"
                            onKeyDown={(e) => e.keyCode === 39 ? e.preventDefault() : ''}
                            id={item.editMode === true ? "start1" : null}
                            className={transaction[item.no-1]?.validate == false ? transaction[item.no-1]?.date === '' ? "form-control-sm mb-0 is-invalid": 'form-control-sm'   : 'form-control-sm'}
                            onChange={(e) => fieldChange(e , item.no)}
                            type="date"
                            value={helper().isValidDate(transaction[item.no-1]?.date)  ?  transaction[item.no-1]?.date.toISOString().substring(0,10): ''}
                            />
                        </td> : <td onClick={() => rowClick(item.no)}><span className="text-center text-dark small">{item.date ? item?.date.toISOString().substring(0,10) : helper().translate("CHOOSE_DATE")}</span> </td> }
                        
                        {item.editMode ? <td >
                            <MDBInput
                            name="reference"
                            title={transaction[item.no-1]?.reference}
                            className="form-control-sm "
                            onChange={(e) => fieldChange(e , item.no)}
                            type="text"
                            value={transaction[item.no-1]?.reference || ''}
                            />
                        </td> : <td onClick={() => rowClick(item.no)}>{item?.reference?.substring(0,20)}</td>}

                        {item.editMode ? <td style={{minWidth: "150px"}} >
                            <MDBInput
                            name="description"
                            id={item.editMode === true ? "start2" : null}
                            title={transaction[item.no-1]?.description}
                            className="form-control-sm "
                            onChange={(e) => fieldChange(e , item.no)}
                            type="text"
                            value={transaction[item.no-1]?.description || ''}
                            />
                        </td> : <td onClick={() => rowClick(item.no)}>{item?.description.substring(0,20)}</td>}

                        {item.editMode ?  <td >
                            <Select
                            name="paymentType"
                            id={item.editMode === true ? "start3" : null}
                            ref={item.editMode === true ? ref3 : null}
                            isDisabled={item.id > 0 ? editPaymnets[item.no-1]?.length > 0 ? true : false : false}
                            // onMenuOpen={(e,obj) =>test12(e,obj))}
                            placeholder=""
                            className={transaction[item.no-1]?.validate == false ? transaction[item.no-1]?.paymentType === null ? " is-invalid-s ": ''   : ''}
                            menuPosition={"fixed"}
                            styles={selectStyles}
                            options={paymentTypes}
                            onChange={(e, obj) => selectChange(e, obj , item.no)}
                            value={paymentTypes.find(p => p.value == transaction[item.no-1]?.paymentType) || ''}
                            />
                        </td> : <td onClick={() => rowClick(item.no)}>
                        <span className="text-center text-dark small">{item.paymentType > 0 ? paymentTypes.find(p => p.value == transaction[item.no-1]?.paymentType)?.label : helper().translate("PAYMENT_TYPE")}</span>
                          </td> }

                       { item.editMode ?   <td >
                            <Select
                            name="projectId"
                            id={item.editMode === true ? "start4" : null}
                            ref={item.editMode === true ? ref4 : null}
                            // openMenuOnFocus={true}
                            placeholder=""
                            // className={transaction[item.no-1]?.validate == false ? transaction[item.no-1]?.projectId === null ? " is-invalid-s ": ''   : ''}
                            menuPosition={"fixed"}
                            options={projects}
                            styles={selectStyles}
                            onChange={(e, obj) => selectChange(e, obj , item.no)}
                            value={projects.find((pro) => pro.value == transaction[item.no-1]?.projectId) || ''}
                            />
                        </td> : <td onClick={() => rowClick(item.no)}>
                        <span className="text-center text-dark small">{item.projectId > 0 ? projects.find((pro) => pro.value == transaction[item.no-1]?.projectId)?.label : helper().translate("CHOOSE_PROJECT")}</span>
                        </td>}
                      
                      {item.editMode ? <td >
                            <Select
                            name="partnerId"
                            menuPosition={"fixed"}
                            id={item.editMode === true ? "start5" : null}
                            ref={item.editMode === true ? ref5 : null}
                            isDisabled={item.id > 0 ? editPaymnets[item.no-1]?.length > 0 ? true : false : false}
                            // openMenuOnFocus={true}
                            components={{ Option: partnerOption }}
                            placeholder=""
                            // className={transaction[item.no-1]?.validate == false ? transaction[item.no-1]?.partnerId === null ? " is-invalid-s ": '': ''}
                            options={partners}
                            styles={multipleItemStyle}
                            onChange={(e, obj) => selectChange(e, obj , item.no)}
                            value={partners.find((p) => p.value == transaction[item.no-1]?.partnerId) || ''}
                            />
                        </td> : <td onClick={() => rowClick(item.no)}><span className="text-center text-dark small">{item.partnerId > 0 ? partners.find((p) => p.value == transaction[item.no-1]?.partnerId)?.label: helper().translate("CHOOSE_PARTNER")}</span></td>}
                        {item.editMode ?  <td >
                            <Select
                            name="accountId"
                            // openMenuOnFocus={true}
                            id={item.editMode === true ? "start6" : null}
                            ref={item.editMode === true ? ref6 : null}
                            menuPosition={"fixed"}
                            components={{ Option: accountingOption }}
                            placeholder=""
                            className={transaction[item.no-1]?.validate == false ? transaction[item.no-1]?.accountId === null ? " is-invalid-s ": ''   : ''}
                            options={
                              paymentTypes.find(p => p.value == transaction[item.no-1.]?.paymentType)?.label === "Pagesa Shitjes" ? accountingSale :
                              paymentTypes.find(p => p.value == transaction[item.no-1.]?.paymentType)?.label === "Pagesa Blerjes" ? accountingBuy :
                               accountingGroup
                            }
                            styles={multipleItemStyle}
                            onChange={(e, obj) => selectChange(e, obj , item.no)}
                            value={accountingGroup.find((a) => a.value == transaction[item.no-1]?.accountId) || ''}
                            />
                        </td> : <td  onClick={() => rowClick(item.no)}><span className="text-center text-dark small">{item.accountId > 0 ? accountingGroup.find((a) => a.value == transaction[item.no-1]?.accountId)?.label: helper().translate("CHOOSE_ACCOUNT")}</span>
                          </td>}
                      
                      { item.editMode ? <td >
                            <MDBInput
                            name="debit"
                            id={item.editMode === true ? "start7" : null}
                            className="form-control-sm text-center"
                            onChange={(e) => fieldChange(e , item.no)}
                            type="number"
                            disabled={transaction[item.no-1]?.D}
                            style={{border: !transaction[item.no-1]?.D ? "green solid 3px" : ''} }
                            value={helper().toFixed2("debit",transaction[item.no-1]?.debit)}
                            />
                        </td> : <td onClick={() => rowClick(item.no)}>{ transaction[item.no-1]?.debit != null ? (transaction[item.no-1]?.debit * 1).toFixed(2) : transaction[item.no-1]?.debit } </td>}

                        {item.editMode ?  <td >
                            <MDBInput
                            name="credit"
                            id={item.editMode === true ? "start8" : null}
                            className="form-control-sm text-center"
                            onChange={(e) => fieldChange(e , item.no)}
                            type="number"
                            disabled={transaction[item.no-1]?.H}
                            style={{border: !transaction[item.no-1]?.H ? "green solid 3px" : ''} }
                            value={helper().toFixed2("credit",transaction[item.no-1]?.credit)}
                            />
                        </td> : <td onClick={() => rowClick(item.no)}>{ transaction[item.no-1]?.credit != null ? (transaction[item.no-1]?.credit * 1).toFixed(2) : transaction[item.no-1]?.credit } </td> }
                       
                        {/* add btn problem itemn.no-1 */}
                        {transaction[item.no-1]?.addButton ? <td  ><button tabIndex="-1" className="btn-edit pt-1" onClick={addNewRow}><span className="icon text-white-40"><i className="fas fa-plus-circle fa-lg"></i></span></button></td> : <td></td> }
                        {/* edit btn */} 
                        <td ><button tabIndex="-1" className="btn-delete pt-1" onClick={() => alertnOnDelete(item.id, item.no)}><span className="icon text-white-40">
                        <i className="fas fa-minus-circle fa-lg"></i>
                        </span></button></td> 
                        
                    </tr>
                ))}
            </tbody>
            <tfoot className="mb-2 mt-2 table-borderless no-colors">
              <tr>
                <td colSpan="7"></td>
                <td className="font-weight-bold align-middle">{helper().translate("TOTAL")}:</td>
                <td ><input style={{width: '99%'}} type="text" disabled defaultValue="0" className="form-control text-center pb-2" value={helper().formatNumber(totalDebit) } ></input></td>
                <td><input style={{width: '99%'}} type="text" disabled defaultValue="0" className="form-control text-center pb-2" value={helper().formatNumber(totalCredit) } ></input></td>
              </tr>
            </tfoot>
          </table>
        </div>
        {/* faturat e paguara */}
        {editPaymnets[rowNum]? 
        <>
        <div className="card-header py-2"><h6 className="m-0 font-weight-bold text-light "><span className="bg-success px-1 rounded">{helper().translate("PAID_INVOICES")}</span> </h6></div>
        <div className="card-body table-responsive px-2">
          <table  className="table table-hover text-center ">
          <thead>
                <tr>
                    <th>{helper().translate("NR")}</th>
                    <th>{helper().translate("DATE")}</th>
                    <th>{helper().translate("INVOICE_NO")}</th>
                    <th>{helper().translate("PARTNER")}</th>
                    <th>{helper().translate("TOTAL")}</th>
                    <th>{helper().translate("PAID_AMOUNT")}</th>
                    <th>{helper().translate("DELETE")}</th>
                </tr>
            </thead>
            <tbody>
            { editPaymnets[rowNum]?.map((item,key)=> (
              <tr key={key}>
                <td className="text-center ">{item.countNumber}</td>
                <td className="text-center  ">{helper().formatDate(item.date) }</td>
                <td className="text-center  ">{item.invoiceNo}</td>
                <td className="text-left ps-4 ">{item.partnerName}</td>
                <td className="text-right font-weight-bold pe-4 ">{ helper().formatNumber(item.total) }</td>
                <td className="text-right font-weight-bold pe-4 ">{ helper().formatNumber(item.amounPaid) }</td>
                <td >
                  <button tabIndex="-1" className="btn-delete pt-1" onClick={() => DeleteInvoicePayment(item.saleId, item.purchaseId, transaction[0].id , item.id)} >
                    <span className="icon text-white-40">
                      <i className="fas fa-minus-circle fa-lg"></i>
                    </span>
                  </button>
                </td> 
              </tr>
              ))}
            </tbody>
          </table>
        </div>
        </>
        : ""}


        {/* faturat e papaguara */}
        {allPayments[rowNum]? 
        <>
        <div className="card-header py-2"><h6 className="m-0 font-weight-bold text-light "><span className="bg-danger px-1 rounded">{helper().translate("UNPAID_INVOICES")}</span> </h6></div>
        <div className="card-body table-responsive px-2">
          <table  className="table table-hover text-center ">
          <thead>
                <tr>
                    <th>{helper().translate("NR")}</th>
                    <th>{helper().translate("DATE")}</th>
                    <th>{helper().translate("INVOICE_NO")}</th>
                    <th>{helper().translate("PARTNER")}</th>
                    <th>{helper().translate("TOTAL")}</th>
                    <th>{helper().translate("PAID_AMOUNT")}</th>
                    <th>{helper().translate("LEFT_AMOUNT")}</th>
                    <th>{helper().translate("PAY")}</th>
                </tr>
            </thead>
            <tbody>
            { allPayments[rowNum].map((item,key)=> (
              <tr key={key}>
                <td className="text-center ">{item.countNumber}</td>
                <td className="text-center  ">{helper().formatDate(item.date) }</td>
                <td className="text-center  ">{item.invoiceNo}</td>
                <td className="text-left ps-4 ">{item.partnerName}</td>
                <td className="text-right font-weight-bold pe-4 ">{ helper().formatNumber(item.total) }</td>
                <td className="text-right font-weight-bold pe-4 ">{helper().formatNumber(item.amounPaid)}</td>
                <td className="text-right font-weight-bold pe-4 ">{helper().formatNumber(item.amountLeft)}</td>
                <td style={{width: '200px'}} >
                  <div className="d-flex justify-content-center align-items-center">
                  <div className="w-75">
                  <MDBInput
                    name="toPay"
                    // id={item.editMode === true ? "start8" : null}
                    className="form-control-sm text-center "
                    defaultValue="0"
                    onChange={(e) => payFieldChange(e , rowNum , item.countNumber)}
                    type="number"
                    // disabled={transaction[item.no-1]?.H}
                    // style={{border: !transaction[item.no-1]?.H ? "green solid 3px" : ''} }
                    value={helper().toFixed2("toPay",item?.toPay)}
                  />
                  </div>
                  <div className="flex-fill">
                  <input onChange={(e) => checkChange(e,rowNum,item.countNumber)} value={item?.check} checked={item?.check}  type="checkbox" className=" bigCheckBox align-middle "></input>
                  </div>
                  </div>
                </td>
              </tr>
              ))}
            </tbody>
          </table>
        </div>
        </>
        : ""}
        </div>
      </div>
    </div>
  );
}

export default AddTransactionBanks;
