import React, { useState, useEffect, useRef } from "react";
import Search from "../../PageComponents/Search/Search_Old";
import { MDBInput, MDB } from "mdb-react-ui-kit";
import Select, { components } from "react-select";
import apiService from "../../Services/apiServices";
import { confirmAlert } from "react-confirm-alert";
import { useToasts } from "react-toast-notifications";
import globals from "../../Services/globals";
import "../../App";
import { Alert } from "bootstrap";
import helper from "../../Services/helper";
import ImportData from "../Tools/ImportData";
import LoaderComponent from "../../PageComponents/UI/Components/LoaderComponent";


function TransactionDetails(props) {


  const [transactionType, setTransactionType] = useState([]);
  const [partners, setPartners] = useState([]);
  const [projects, setProjects] = useState([]);
  const [userInfo, setUserInfo] = useState();
  const [accountingGroup, setAccountingGroup] = useState([]);
  const [totals, setTotals] = useState({ totalDebit: 0, totalCredit: 0, totalSaldo: 0 });
  const [partnerStringLength, setPartnerStringLength] = useState(0);
  const [accountingStringLength, setAccountingStringLength] = useState(0);
  const [search, setSearch] = useState('');
  const [branches, setBranches] = useState([]);
  const [warehouses, setWarehouses] = useState([]);
  const [isUpload, setIsUpload] = useState(false);
  const { addToast } = useToasts();
  const [showImportData, setShowImportData] = useState(false);
  const pageRef = useRef();

  const newItem = {
    id: 0,
    comment: '',
    date: (new Date()).toISOString().substring(0, 10) + ' ' + new Date().getHours() + ':' + new Date().getMinutes() + ':' + new Date().getSeconds(),
    createdAt: null,
    createdBy: globals().getLogedInUser().data.username,
    changedAt: null,
    changedBy: null,
    transactionType: null,
    branchId: null,
    warehouseId: null,
    isChanged: false,
    validate: true
  }
  const [item, setItem] = useState(newItem);

  const newItemDetails = {
    id: 0,
    no: 1,
    date: item?.date, //(new Date()).toISOString().substring(0, 10) + ' ' + new Date().getHours() + ':' + new Date().getMinutes() + ':' + new Date().getSeconds(),
    documentId: null,
    description: '',
    partnerId: 0,
    partnerName: '',
    warehouseId: null,
    branchId: null,
    transactionId: 0,
    //userId: globals().getLogedInUser().data.id,
    userId: null,
    projectId: 0,
    accountNumber: null,
    debit: 0,
    credit: 0,
    saldo: 0,
    createdAt: null,
    createdBy: null,
    changedAt: null,
    changedBy: null,
    addButton: true,
    validate: true
  };
  
  const [itemDetails, setItemDetails] = useState([{ ...newItemDetails, editMode: false }]);
  const [itemDetailsCopy, setItemDetailsCopy] = useState([{ ...newItemDetails, editMode: false }]);
  const [disable, setDisable] = useState(false)
  const [loader , setLoader] = useState(false);

  useEffect(() => {
    let id = props.match.params.id;
    getTransactionType();
    getPartners();
    getProjects();
    getAccountingGroup();
    getUser();
    getBranches();
    if (id > 0) {
      getItem(id);
      getItemDetails(id);
    }

    let str = props.location.search
    if (str) {
      setItem({ ...item, transactionType: parseInt(str.charAt(str.length - 1)) })
    }
  }, [])

  useEffect(() => {
    let str = props.location.search
    if (str) {
      setItem({ ...item, transactionType: parseInt(str.charAt(str.length - 1)) })
      setDisable(true)
    }
  }, [props.location.search])


  useEffect(() => {
    if (item.transactionType === 3 || item.transactionType === 4) {
      setIsUpload(true);
    } else {
      setIsUpload(false);
    }
  }, [item.transactionType])

  useEffect(() => {
    let filtered = filterItemDetails(search);
    if (search != "") {
      setItemDetailsCopy(filtered);
    } else {
      setItemDetailsCopy(itemDetails);
    }

  }, [itemDetails]);

  useEffect(() => {
    let id = props.match.params.id;
    getItemDetails(id)
  }, [accountingGroup])
  

  const getItem = (id) => {
    apiService().query('getTransaction', 'id=' + id)
      .then((response) => {
        if (response.success) {
          let getItem = response.data[0];
          getItem.isChanged = false;
          getItem.branchId = null;
          getItem.warehouseId = null;
          setItem(getItem);
        } else {
          console.log('Error:' + response.errorMessage);
        }
      });

  }
  const getItemDetails = (id) => {
    apiService().query('getTransactionDetails', 'transactionId=' + id)
      .then((response) => {
        if (response.success) {
          let totalC = 0;
          let totalD = 0;
          let getItemDetails = response.data;
          var count = 1;
          getItemDetails.forEach(i => {
            i.no = count; count++;
            i.accountNumber = accountingGroup.find(acc => acc.value === i.accountId)?.accountNumber;
            totalC = totalC + parseFloat(i.credit);
            totalD = totalD + parseFloat(i.debit);
          });
          for (var i = 0; i < getItemDetails.length; i++) {
            if (i === (getItemDetails.length - 1)) {
              getItemDetails[i].addButton = true;
            }
          }

          if (getItemDetails.length === 0) {
            setItemDetails([newItemDetails]);
          } else {
            setItemDetails(getItemDetails);
          }
          setTotals({ ...totals, totalDebit: totalD, totalCredit: totalC, totalSaldo: 0 });
        } else {
          console.log('Error:' + response.errorMessage);
        }
      });
  }

  const getUser = () => {
    apiService().query('user', 'userName=' + globals().getLogedInUser().data.username)
      .then((response) => {
        if (response.success) {
          let u = response.data[0];
          if (props.match.params.id == 0) {
            let iDetails = [...itemDetails];
            iDetails.forEach(i => {
              i.warehouseId = u.warehouseId;
            });
            setItemDetails(iDetails)
          }
          setUserInfo(u);
        }
        else alert('errr')
      })
  }

  const getWarehousesByBranch = (e) => {
    apiService().query('WarehousesByBranch', `branchId=${e}`)
      .then((response) => {
        if (response.success && response.data.length) {
          let records = response.data
          records = records.map(record => ({ value: record.id, label: record.name }))
          setWarehouses(records)
        }
      })
  }

  const getBranches = () => {
    apiService()
      .get("branches")
      .then((response) => {
        if (response.success) {
          let data = response.data;
          let options = data.map((item, index) => ({
            value: item.id,
            label: item.name
          }));
          setBranches(options);
        } else {
          alert("Error: getting branches" + response.errorMessage);
        }
      });
  }



  const selectStyles = {
    container: provided => ({
      ...provided,
      minWidth: '190px'
    }),
    control: (provided, state) => ({
      ...provided,
      background: "#fff",
      borderColor: "#9e9e9e",
      minHeight: "28.59px",
      height: "28.59px",
      boxShadow: state.isFocused ? null : null,
    }),

    valueContainer: (provided, state) => ({ ...provided, height: "28.59px", padding: "0 6px", }),
    input: (provided, state) => ({ ...provided, margin: "0px", }), indicatorSeparator: (state) => ({ display: "none", }),
    indicatorsContainer: (provided, state) => ({ ...provided, height: "28.59px", }),
  };
  const multipleItemStyle = {
    container: provided => ({
      ...provided,
      minWidth: '190px'
    }),
    control: (provided, state) => ({
      ...provided,
      background: "#fff",
      borderColor: "#9e9e9e",
      minHeight: "28.59px",
      height: "28.59px",
      boxShadow: state.isFocused ? null : null,
    }),
    menu: (provided, state) => ({
      ...provided,
      width: "auto"
    }),
    menuList: (provided, state) => ({
      ...provided,
      width: "auto"
    }),

    valueContainer: (provided, state) => ({ ...provided, height: "28.59px", padding: "0 6px", }),
    input: (provided, state) => ({ ...provided, margin: "0px", }), indicatorSeparator: (state) => ({ display: "none", }),
    indicatorsContainer: (provided, state) => ({ ...provided, height: "28.59px", }),
  };
  const getPartners = () => {
    apiService().get('partners').then((response) => {
      let options
      if (response.success) {
        if (response.success) {
          let data = response.data;
          let firstItem = { id: 0, companyName: "", fiscalNo: "", cityName: "" };
          data.unshift(firstItem);
          options = data.map((item, index) => ({
            value: item.id,
            label: item.companyName,
            ficalNo: item.fiscalNo,
            cityName: item.cityName
          }));
        }
        setPartners(options);
      } else {
        alert('Error:' + response.errorMessage);
      }
    });
  }
  const getProjects = () => {
    let options;
    apiService()
      .query("getProjects")
      .then((response) => {
        if (response.success) {
          let data = response.data;
          let firstItem = { id: 0, name: helper().translate("NO_PROJECT") };
          data.unshift(firstItem);
          options = data.map((item, index) => ({
            value: item.id,
            label: item.name
          }));
          setProjects(options);
        } else {
          alert("Error: getting Projects" + response.errorMessage);
        }
      });
  };
  const getTransactionType = () => {
    let options;
    apiService()
      .query("getTransactionType")
      .then((response) => {
        if (response.success) {
          options = response.data.map((item, index) => ({
            value: item.id,
            label: helper().translate(`${item.TransactionType}`)
          }));
          setTransactionType(options);
        } else {
          alert("Error: getting TransactionType" + response.errorMessage);
        }
      });
  };
  const getAccountingGroup = () => {
    let options;
    apiService()
      .query("getAccountingPlan")
      .then((response) => {
        if (response.success) {
          options = response.data.map((item, index) => ({
            value: item.id,
            label: item.name,
            accountNumber: item.accountNumber
          }));
          setAccountingGroup(options);
        } else {
          alert("Error: getting PlaniKontabel" + response.errorMessage);
        }
      });
  };

  const fieldChange = (e, no) => {
    //item
    if (e.target.name === "itemDate") {
      let newArr = item;
      setItem({ ...newArr, date: e.target.value, isChanged: true });
      itemDetails.forEach(item => {
        item.date = e.target.value
      })
    } else if (e.target.name === "comment") {
      let newArr = item;
      setItem({ ...newArr, comment: e.target.value, isChanged: true });
    }
    //itemdetails
    else if (e.target.name === "date") {
      let newArr = [...itemDetails];
      newArr[no - 1][e.target.name] = e.target.value;
      setItemDetails(newArr);
    }
    else if (e.target.name === "description") {
      let newArr = [...itemDetails];
      newArr[no - 1][e.target.name] = e.target.value;
      setItemDetails(newArr);
    } else if (e.target.name === "debit") {
      let newArr = [...itemDetails];
      newArr[no - 1][e.target.name] = e.target.value;
      newArr[no - 1]["saldo"] = e.target.value - newArr[no - 1].credit;
      let totaliCredit = 0;
      let totaliDebit = 0;
      newArr.forEach(e => {
        totaliCredit = totaliCredit + parseFloat(e.credit);
        totaliDebit = totaliDebit + parseFloat(e.debit);
      });
      setTotals({ ...totals, totalDebit: totaliDebit, totalSaldo: totaliDebit - totaliCredit });
      setItemDetails(newArr);
    } else if (e.target.name === "credit") {
      let newArr = [...itemDetails];
      newArr[no - 1][e.target.name] = e.target.value;
      newArr[no - 1]["saldo"] = newArr[no - 1].debit - e.target.value;
      let totaliCredit = 0
      let totaliDebit = 0;
      newArr.forEach(e => {
        totaliCredit = totaliCredit + parseFloat(e.credit);
        totaliDebit = totaliDebit + parseFloat(e.debit);
      });
      setTotals({ ...totals, totalCredit: totaliCredit, totalSaldo: totaliDebit - totaliCredit });
      setItemDetails(newArr);
    }
  }

  const selectChange = (e, obj, no) => {
    //item
    if (obj.name === "transactionType") {
      let newArr = item;
      setItem({ ...newArr, transactionType: e.value, validate: true, isChanged: true })
    }
    //itemdetails
    else if (obj.name === "partnerId") {
      let newArr = [...itemDetails];
      newArr[no - 1][obj.name] = e.value;
      newArr[no - 1]['partnerName'] = partners.find((p) => p.value == e.value).label;
      setItemDetails(newArr);
    } else if (obj.name === "projectId") {
      let newArr = [...itemDetails];
      newArr[no - 1][obj.name] = e.value;
      setItemDetails(newArr);
    } else if (obj.name === "accountNumber") {
      let newArr = [...itemDetails];
      newArr[no - 1][obj.name] = e.accountNumber;
      setItemDetails(newArr);
    }
    else if (obj.name === "branchId") {
      let newArr = item;
      setItem({ ...newArr, branchId: e.value, warehouseId: null });
      getWarehousesByBranch(e.value);
    }
    else if (obj.name === "warehouseId") {
      let newArr = [...itemDetails];
      let mArr = item;
      newArr.forEach(i => {
        i.branchId = item.branchId;
        i.warehouseId = e.value
      });
      setItemDetails(newArr);
      setItem({ ...mArr, warehouseId: e.value, validate: true });
    }
  }
  const findChange = (e) => {
    setSearch(e.target.value);
    let searchString = e.target.value.trim().toLowerCase();

    let searchItemDetails = filterItemDetails(searchString);
    setItemDetailsCopy(searchItemDetails);
  }
  const filterItemDetails = (searchString) => {

    if (searchString.length > 0) {
      let searchItemDetails = itemDetails.filter((item) => {
        return (
          //item?.partnerName?.toString().toLowerCase().match(searchString)
          item.partnerName === '' || item.editMode || item?.partnerName?.toString().toLowerCase().match(searchString)
        )
      });

      addButtonLastRow(searchItemDetails);
      // var count = 1;
      // searchTransaction.forEach(i=> {i.no = count; count++});
      // setTransactionFilter(searchTransaction);
      return searchItemDetails;
    }
    else {
      let allItemDetails = [...itemDetails];
      addButtonLastRow(allItemDetails);
      return allItemDetails;
    }

  }

  const addButtonLastRow = (arr) => {
    for (var i = 0; i < arr.length; i++) {
      arr[i].addButton = false;
      //arr[i].editMode = false;
      if (i === (arr.length - 1)) {
        arr[i].addButton = true;
      }
    }
  }

  const addRow = () => {
    var id = props.match.params.id;
    let newItem = newItemDetails;
    newItemDetails.no = Math.max(...itemDetails.map(o => o.no), 0) + 1;
    newItem.status = 1;
    newItem.warehouseId = userInfo?.warehouseId;
    if (parseInt(id) > 0) {
      newItem.transactionId = id;
    }

    const newState = [...itemDetails, newItem];
    for (var i = 0; i < newState.length; i++) {
      newState[i].addButton = false;
      newState[i].editMode = false;
      if (i === (newState.length - 1)) {
        newState[i].addButton = true;
        newState[i].editMode = true;
      }
    }
    setItemDetails(newState);
  }

  const rowClick = (no) => {
    //enter edit mode
    let newItem = [...itemDetails];
    newItem.forEach(e => e.editMode = false);
    newItem[no - 1].editMode = true;
    if (newItem[no - 1].id > 0) {
      newItem[no - 1].status = 2;
    } else {
      newItem[no - 1].status = 1;
    }
    setItemDetails(newItem);
  }
  const addRowEnter = () => {
    if (event.key === 'Enter') {
      addRow();
    }
  }

  const alertnOnDelete = (id, no) => {
    if (id > 0) {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="custom-ui">
              <h1>{helper().translate("ARE_YOU_SURE")}</h1>
              <p>{helper().translate("WANT_TO_DELETE_THIS_TRANSACTION")}</p>
              <button
                onClick={() => {
                  deleteItem(id, no);
                  onClose();
                }}
              >
                {helper().translate("YES")}, {helper().translate("DELETE")}!
              </button>
              <button onClick={onClose}>{helper().translate("NO")}</button>
            </div>
          );
        },
      });
    } else if (id == 0) {
      deleteItem(id, no);
    }
  }

  const deleteItem = (id, no) => {
    if (id > 0) {
      apiService()
        .query("deleteTransactionDetail", "id=" + id)
        .then((response) => {
          if (response.success) {
            let newItem = [...itemDetails];
            let arr = newItem.filter((row) => row.no !== no);
            var num = 1;
            arr.forEach(i => { i.no = num; num++ });
            for (var i = 0; i < arr.length; i++) {
              if (i === (arr.length - 1)) {
                arr[i].addButton = true;
                arr[i].editMode = true;
              }
            }
            addToast(helper().translate("SUCCESSFULLY_DELETED"), {
              appearance: "success",
              autoDismiss: true,
            });
            if (arr.length == 0) {
              setItemDetails([{ ...newItemDetails, transactionId: props.match.params.id }]);
            } else
              if (arr.length > 0) {
                setItemDetails(arr);
              }
            setSearch('');
          } else {
            alert("Error:" + response.errorMessage);
          }
        });
    } else {
      let newItem = [...itemDetails];
      let arr = newItem.filter((row) => row.no !== no);
      var num = 1;
      arr.forEach(i => { i.no = num; num++ });
      for (var i = 0; i < arr.length; i++) {
        if (i === (arr.length - 1)) {
          arr[i].addButton = true;
          arr[i].editMode = true;
        }
      }
      setSearch('');
      if (arr.length == 0) {
        setItemDetails([newItemDetails]);
      } else
        if (arr.length > 0) {
          setItemDetails(arr);
        }
    }
  }

  const newHandle = () => {
    let itemCopy = item;
    let iDetailsCopy = [...itemDetails];
    var id = props.match.params.id;
    let yesSave = true;
    let obj = iDetailsCopy.filter(e => (e.status === 2) || (e.status === 1));

    if (item?.isChanged === false && obj.length === 0) {
      props.history.push('/finances/edit_transactionDetails/0');
    } else {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="custom-ui" >
              <h1>{helper().translate("ARE_YOU_SURE")}</h1>
              <p>{helper().translate("WANT_TO_SAVE_ACTUAL_CHANGES")}</p>
              <button
                onClick={() => {
                  itemSave(id, yesSave);
                  onClose();
                }}
              >
                {helper().translate("YES")}
              </button>
              <button onClick={() => {
                props.history.push('/finances/edit_transactionDetails/0');
                onClose();
              }} className="ms-3">{helper().translate("NO")}</button>
            </div>
          );
        }
      })
    }
  }

  const getUploadItemDetails = (params) => {
    let itemId = item.id;
    apiService().query('openBalance', params)
      .then((response) => {
        if (response.success) {
          let getItemDetails = response.data;
          let totalC = 0;
          let totalD = 0;
          let totalS = 0;
          var count = 1;
          getItemDetails.forEach(i => {
            i.no = count;
            count++;
            i.id = 0;
            i.status = 1;
            i.transactionId = itemId;
            totalC = totalC + parseFloat(i.credit);
            totalD = totalD + parseFloat(i.debit);
            i.date = (new Date()).toISOString().substring(0, 10) + ' ' + new Date().getHours() + ':' + new Date().getMinutes() + ':' + new Date().getSeconds();
            //i.userId = globals().getLogedInUser().data.id;
            i.userId = null;
          }
          );
          totalS = Math.abs(totalC - totalD);
          for (var i = 0; i < getItemDetails.length; i++) {
            if (i === (getItemDetails.length - 1)) {
              getItemDetails[i].addButton = true;
            }
          }
          setItemDetails(getItemDetails);
          setTotals({ ...totals, totalDebit: totalD, totalCredit: totalC, totalSaldo: totalS });
        } else {
          console.log(response)
        }
      })
  }

  const uploadClick = () => {
    let id = parseInt(props.match.params.id)
    let iDetails = itemDetails.filter(f => f.id > 0);
    if (id > 0 && iDetails.length > 0) {
      addToast(helper().translate("PLEASE_DELETE_ALL_TRANSACTION_FIELD"), {
        appearance: "error",
        autoDismiss: true,
      });
    } else {

      if (item.branchId !== null && item.warehouseId !== null) {
        // var params = `fromDate=01/01/2020&toDate=10/10/2022&accountType=Accounts_Receivable&warehouseId=1`;
        let accType = "";
        if (item.transactionType === 3) {
          accType = "Accounts_Payable";
        } else if (item.transactionType === 4) {
          accType = "Accounts_Receivable";
        }
        let getYrs = (new Date().getFullYear() - 1);
        let fromDate = `12/31/${getYrs}`;
        // let toDate = (new Date()).toISOString().substring(0, 10) + ' ' + new Date().getHours() + ':' + new Date().getMinutes() + ':' + new Date().getSeconds();
        var params = `fromDate=${fromDate}&toDate=${item.date}&accountType=${accType}&warehouseId=${item.warehouseId}&branchId=${item.branchId}`;
        getUploadItemDetails(params)
      } else {
        addToast(helper().translate("CHOOSE_BRANCH_AND_WAREHOUSE"), {
          appearance: "error",
          autoDismiss: true,
        });
        //setLoader(false)
      }
    }

  }

  const validateForm = () => {

    let value = true;
    let itemCopy = item;
    let iDetailsCopy = [...itemDetails];
    if (isUpload) {
      if (item.transactionType === null || itemCopy.branchId === null || itemCopy.warehouseId === null) {
        setItem({ ...itemCopy, validate: false });
        value = false;
      }
    } else if (item.transactionType === null) {

      setItem({ ...itemCopy, validate: false });
      value = false;
    }
    iDetailsCopy.forEach(e => {
      if (e.partnerId != null && e.projectId != null && e.accountNumber != null) {

      } else {
        e.validate = false;
        e.editMode = true;
        value = false;
      }

    });
    setItemDetails(iDetailsCopy);
    return value;
  }

  const saveOnlyOnce = () => {
    var element = document.getElementById("saveOnce");
    element.classList.toggle("disabled");
  }

  const itemSave = (id, yesSave) => {
    if (id > 0) {
      // if(item.transactionType === 2 || item.transactionType === 3 ){
      //   id = 0;
      // }
      // console.log(id)
    } else {
      // console.log("ska id")
      var id = props.match.params.id;
    }


    
    if (parseInt(id) === 0) {

      saveOnlyOnce();
      if (validateForm()) {
        setLoader(true)
        if (Math.abs(totals.totalSaldo )>= 0 && Math.abs(totals.totalSaldo ) <= 0.05) {
          apiService().execute('insertTransaction', item, 'post')
            .then((response) => {
              if (response.success) {
                let obj = [...itemDetails];
                let insertItemDetails = [];
                var idTransaction = response.data[0].id;

                obj.forEach(e => {
                  e.createdBy = globals().getLogedInUser().data.username;
                  e.transactionId = idTransaction;
                  e.accountId = accountingGroup.find(ag => ag.accountNumber === e.accountNumber)?.value;
                  let contextObj = {
                    entity: e,
                    status: 1,
                    type: 'transactionDetails'
                  }
                  insertItemDetails.push(contextObj);
                })
                console.log(insertItemDetails)
                apiService().saveChanges(insertItemDetails).then((response) => {
                  if (response.success) {
                    if (yesSave === true) {
                      props.history.push('/finances/edit_transactionDetails/0');
                    } else {
                      props.history.push('/finances/edit_transactionDetails/' + idTransaction);
                    }
                    addToast(helper().translate("SUCCESSFULLY_ADDED"), {
                      appearance: "success",
                      autoDismiss: true,
                    });
                    setLoader(false)
                  } else {
                    saveOnlyOnce();
                    setLoader(false)
                  }
                })
              }
            })
        } else {
          addToast(helper().translate("SALDO_EQUAL_TO_ZERO"), {
            appearance: "error",
            autoDismiss: true,
          });
          setLoader(false)
          saveOnlyOnce();
        }
      } else {
        addToast(helper().translate("FILL_REQUIRED_FIELDS"), {
          appearance: "error",
          autoDismiss: true,
        });
        setLoader(false)
        saveOnlyOnce();
      }

    } else if (parseInt(id) > 0) {
      if (validateForm()) {
        if (totals.totalSaldo >= 0 && totals.totalSaldo <= 0.05) {
          let updateItem = item;
          updateItem.changedBy = globals().getLogedInUser().data.username;
          apiService().execute('updateTransaction', updateItem, 'post')
            .then((response) => {
              let updateItemDetails = [];
              let obj = itemDetails.filter(e => (e.status === 2) || (e.status === 1));
              obj.forEach(e => {
                e.accountId = accountingGroup.find(ag => ag.accountNumber === e.accountNumber)?.value;
                var contextObj = {
                  entity: e,
                  status: e.status,
                  type: "transactionDetails"
                }
                updateItemDetails.push(contextObj);
              });
              console.log(obj.length)
              if (obj.length > 0) {
                apiService().saveChanges(updateItemDetails).then((response) => {
                  console.log(response)
                  if (response.success) {
                    if (yesSave === true) {
                      props.history.push('/finances/edit_transactionDetails/0');
                    } else {
                      props.history.push('/finances/edit_transactionDetails/' + id);
                    }
                    addToast("update successfully", {
                      appearance: "success",
                      autoDismiss: true,
                    });
                    setLoader(false)
                  }
                })
              } else {
                if (yesSave === true) {
                  props.history.push('/finances/edit_transactionDetails/0');
                } else {
                  props.history.push('/finances/edit_transactionDetails/' + id);
                }
              }
            })
        }
      }
    }
  }

  const partnerOption = props => {
    if (props.data.label.length > partnerStringLength) {
      setPartnerStringLength(props.data.label.length);
    }
    // let stringMaxLength = 0;
    // let data = props.data.label;
    // if(data.length > stringMaxLength){
    //   stringMaxLength = data.length
    // }
    // console.log(data.length);
    return (
      <components.Option {...props}>
        <div className="d-flex text-truncate"><p style={{ width: `${partnerStringLength * 8}px` }} className="m-0 text-left ">{props.data.label}</p>|<p style={{ width: "200px" }} className="m-0 text-center ">{props.data.ficalNo}</p>|<p style={{ width: "200px" }} className="m-0 text-center ">{props.data.cityName}</p></div>
      </components.Option>
    );
  };

  const accountingOption = props => {
    if (props.data.label.length > accountingStringLength) {
      setAccountingStringLength(props.data.label.length);
    }
    // let stringMaxLength = 0;
    // let data = props.data.label;
    // if(data.length > stringMaxLength){
    //   stringMaxLength = data.length
    // }
    // console.log(data.length);
    return (
      <components.Option {...props}>
        <div className="d-flex text-truncate"><p style={{ width: `${accountingStringLength * 8}px` }} className="m-0 text-left ">{props.data.label}</p>|<p style={{ width: "200px" }} className="m-0 text-center ">{props.data.accountNumber}</p></div>
      </components.Option>
    );
  };

  const toggleImportData = () => {
    setShowImportData(!showImportData)
  }

  const prepareData = (headerKeys, items) => {
    var objs = [];
    let model = newItemDetails;// new ModelPurchaseDetails();

    items?.forEach(itm => {
      let obj = { ...newItemDetails };
      obj.date = item.date;
      headerKeys?.forEach(header => {
        Object.keys(model).forEach(function (key) {
          if (header === key) {
            obj[key] = itm[header]
          }
        })
      })

      objs.push(obj)
    })

    return objs;
  }

  const getDataFromImport = (headers, data) => {
    let totalC = 0, totalD = 0, objs = prepareData(headers, data?.filter(d => d.no !== ''));
    objs.forEach(i => {
      totalC = totalC + parseFloat(i.credit);
      totalD = totalD + parseFloat(i.debit);
    });
    setTotals({ ...totals, totalDebit: totalD, totalCredit: totalC, totalSaldo: totalD - totalC });
    setItemDetails(objs)
    setShowImportData(false)
    // setItemDetailsCopy(objs)    
  }

  return (

    <div id="financialOrder">
      <div className="container-fluid px-2 mt-3">
        <Search headersFrom={"FINANCE"} newHandle={newHandle} change={(e) => findChange(e)} save={itemSave} importData={() => toggleImportData()} back="#/finances/transactions" />
        <div className="card border-left-primary shadow h-100 mt-1">
          <div className="card-body pt-1 pb-1">
            <div className="row">
              <div className="col-lg-4 col-12">
                <div className="row pt-2">
                  <div className="col mb-md-0 pe-0 ">
                    <div >
                      <MDBInput
                        name="itemDate"
                        value={item.date.substring(0, 10)}
                        className="form-control-sm active"
                        onChange={(e) => fieldChange(e)}
                        type="date"
                        label={helper().translate("DATE")}
                      />
                    </div>
                  </div>
                </div>
                <div className="row py-2">
                  <div className="col pe-0">
                    <Select
                      name="transactionType"
                      openMenuOnFocus={true}
                      placeholder={helper().translate("CHOOSE_FINANCIAL_ORDER_TYPE")}
                      styles={selectStyles}
                      className={item?.validate == false ? item?.transactionType === null ? " is-invalid-s " : '' : ''}
                      options={transactionType}
                      onChange={(e, obj) => selectChange(e, obj)}
                      value={transactionType.find(t => t.value == item?.transactionType) || ''}
                      isDisabled={disable}
                    />
                  </div>

                </div>
              </div>

              <div className="col-lg-3 col-12">
                <div className="pt-2" >
                  <MDBInput
                    textarea
                    rows={3}
                    name="comment"
                    className="form-control-sm active"
                    onChange={(e) => fieldChange(e)}
                    type="text"
                    label={helper().translate("COMMENT")}
                    value={item?.comment}
                  />
                </div>
              </div>
              {isUpload ?
                <div className="col-lg-4 col-12">
                  <div className="row pt-2">
                    <div className="col pe-0">
                      <Select
                        name="branchId"
                        openMenuOnFocus={true}
                        placeholder={helper().translate("SELECT_BRANCH")}
                        className={item?.validate == false ? item?.branchId === null ? " is-invalid-s " : '' : ''}
                        options={branches}
                        onChange={(e, obj) => selectChange(e, obj)}
                        value={branches.find(b => b.value == item?.branchId) || ''}
                      />
                    </div>
                  </div>
                  <div className="row py-2">
                    <div className="col pe-0">
                      <Select
                        name="warehouseId"
                        openMenuOnFocus={true}
                        placeholder={helper().translate("SELECT_WAREHOUSE")}
                        styles={selectStyles}
                        className={item?.validate == false ? item?.warehouseId === null ? " is-invalid-s " : '' : ''}
                        options={warehouses}
                        onChange={(e, obj) => selectChange(e, obj)}
                        value={warehouses.find(w => w.value == item?.warehouseId) || ''}
                      />

                    </div>
                  </div>
                </div> : ""}
              {isUpload ?
                <div className="col-lg-1 col-6 d-flex justify-content-center align-items-center">
                  <div >
                    <button className='btn btn-primary' onClick={() => uploadClick()} >{helper().translate('UPLOAD')}</button>
                  </div>
                </div> : ""}

            </div>
          </div>
        </div>
        
        <div className="card shadow h-100 mt-1">
          <div className="card-header py-2"><h6 className="m-0 font-weight-bold text-primary">Lista e Transaksioneve</h6></div>
          {loader && <LoaderComponent /> } 
          {!loader && <div className="card-body table-responsive pb-0">
            {showImportData && <ImportData ref={pageRef} queryName="transactionDetails" model={newItemDetails} getBackData={(headers, data) => getDataFromImport(headers, data)} transactionType={item?.transactionType} itemDate={item?.date} />}
            <table onKeyPress={addRowEnter} className="table table-hover text-center ">
              <thead>
                <tr>
                  <th>{helper().translate("NR")}</th>
                  <th>{helper().translate("DATE")}</th>
                  <th>{helper().translate("DESCRIPTION")}</th>
                  <th>{helper().translate("PARTNER")}</th>
                  <th>{helper().translate("PROJECT")}</th>
                  <th>{helper().translate("ACCOUNT")}</th>
                  <th>{helper().translate("DEBIT_IN")}</th>
                  <th>{helper().translate("CREDIT_OUT")}</th>
                  <th>{helper().translate("ADD")}</th>
                  <th>{helper().translate("DELETE")}</th>
                </tr>
              </thead>
              <tbody>
                {itemDetailsCopy.map((item, key) => (
                  <tr key={key}>
                    <td>{item.no}</td>
                    {item.editMode ? <td>
                      <MDBInput
                        name="date"
                        // className={transaction[item.no-1]?.validate == false ? transaction[item.no-1]?.date === '' ? "form-control-sm mb-0 is-invalid": 'form-control-sm'   : 'form-control-sm'}
                        className="form-control-sm"
                        onChange={(e) => fieldChange(e, item.no)}
                        type="date"
                        value={itemDetails[item.no - 1]?.date.toString().substring(0, 10) || ''}
                      />
                    </td> : <td onClick={() => rowClick(item.no)}><span className="text-center text-dark small">{item.date ? item?.date.substring(0, 10) : helper().translate("CHOOSE_DATE")}</span> </td>}

                    {item.editMode ? <td>
                      <MDBInput
                        name="description"
                        // title={transaction[item.no-1]?.description}
                        className="form-control-sm "
                        onChange={(e) => fieldChange(e, item.no)}
                        type="text"
                        value={itemDetails[item.no - 1]?.description || ''}
                      />
                    </td> : <td onClick={() => rowClick(item.no)}>{item?.description?.substring(0, 20)}</td>}

                    {
                      item.editMode ? <td> <Select
                        name="partnerId"
                        menuPosition={"fixed"}
                        openMenuOnFocus={true}
                        placeholder=""
                        className={itemDetails[item.no - 1]?.validate == false ? itemDetails[item.no - 1]?.partnerId === null ? " is-invalid-s " : '' : ''}
                        options={partners}
                        components={{ Option: partnerOption }}
                        styles={multipleItemStyle}
                        onChange={(e, obj) => selectChange(e, obj, item.no)}
                        value={partners.find((p) => p.value == itemDetails[item.no - 1]?.partnerId) || ''}
                      /></td> : <td onClick={() => rowClick(item.no)}><span className="text-center text-dark small">{item.partnerId > 0 ? partners.find((p) => p.value == itemDetails[item.no - 1]?.partnerId)?.label : helper().translate("CHOOSE_PARTNER")}</span></td>
                    }
                    {item.editMode ? <td><Select
                      name="projectId"
                      openMenuOnFocus={true}
                      placeholder=""
                      className={itemDetails[item.no - 1]?.validate == false ? itemDetails[item.no - 1]?.projectId === null ? " is-invalid-s " : '' : ''}
                      menuPosition={"fixed"}
                      options={projects}
                      styles={selectStyles}
                      onChange={(e, obj) => selectChange(e, obj, item.no)}
                      value={projects.find((pro) => pro.value == itemDetails[item.no - 1]?.projectId) || ''}
                    /></td> : <td onClick={() => rowClick(item.no)}>
                      <span className="text-center text-dark small">{projects.find((pro) => pro.value == itemDetails[item.no - 1]?.projectId)?.label}</span>
                    </td>}
                    {console.log(item)}
                    {
                      item.editMode ? <td><Select
                        name="accountNumber"
                        menuPosition={"fixed"}
                        openMenuOnFocus={true}
                        placeholder=""
                        className={itemDetails[item.no - 1]?.validate == false ? itemDetails[item.no - 1]?.accountNumber === null ? " is-invalid-s " : '' : ''}
                        options={accountingGroup}
                        components={{ Option: accountingOption }}
                        styles={multipleItemStyle}
                        onChange={(e, obj) => selectChange(e, obj, item.no)}
                        value={accountingGroup.find((a) => a.accountNumber == itemDetails[item.no - 1]?.accountNumber) || ''}
                      /></td> : <td onClick={() => rowClick(item.no)}><span className="text-center text-dark small">{item.accountNumber > 0 ? accountingGroup.find((a) => a.accountNumber == itemDetails[item.no - 1]?.accountNumber)?.label : helper().translate("CHOOSE_ACCOUNT")}</span>
                      </td>
                    }
                    {item.editMode ? <td><MDBInput
                      name="debit"
                      className="form-control-sm text-center"
                      onChange={(e) => fieldChange(e, item.no)}
                      type="number"
                      value={itemDetails[item.no - 1]?.debit}
                    /></td> : <td style={{ width: "250px" }} onClick={() => rowClick(item.no)}>{itemDetails[item.no - 1]?.debit != null ? (itemDetails[item.no - 1]?.debit * 1).toFixed(2) : itemDetails[item.no - 1]?.debit} </td>}

                    {item.editMode ? <td><MDBInput
                      name="credit"
                      className="form-control-sm text-center"
                      onChange={(e) => fieldChange(e, item.no)}
                      type="number"
                      // style={{border: !transaction[item.no-1]?.H ? "green solid 3px" : ''} }
                      value={itemDetails[item.no - 1]?.credit}
                    /></td> : <td style={{ width: "250px" }} onClick={() => rowClick(item.no)}>{itemDetails[item.no - 1]?.credit != null ? (itemDetails[item.no - 1]?.credit * 1).toFixed(2) : itemDetails[item.no - 1]?.credit} </td>}

                    {itemDetails[item.no - 1]?.addButton ?
                      <td style={{ width: "35px" }} ><button className="btn-edit pt-1" onClick={addRow}><span className="icon text-white-40"><i className="fas fa-plus-circle fa-lg"></i></span></button></td> :
                      <td></td>
                    }

                    <td style={{ width: "35px" }} ><button className="btn-delete pt-1" onClick={() => alertnOnDelete(item.id, item.no)}><span className="icon text-white-40">
                      <i className="fas fa-minus-circle fa-lg"></i>
                    </span></button></td>
                  </tr>
                ))}
              </tbody>
              <tfoot className="mb-2 mt-2 pt-2 table-borderless no-colors">
                <tr>
                  <td colSpan="6"></td>
                  <td className="font-weight-bold">{helper().translate("TOTAL_DEBIT")}:</td>
                  <td className="font-weight-bold">{helper().translate("TOTAL_CREDIT")}:</td>
                </tr>
                <tr>
                  <td colSpan="6"></td>
                  <td ><input style={{ width: '99%' }} type="text" disabled defaultValue="0"
                    value={helper().formatNumber(totals?.totalDebit)} className="form-control text-center pb-2 "  ></input></td>
                  <td><input style={{ width: '99%' }} type="text" disabled defaultValue="0" value={helper().formatNumber(totals?.totalCredit)} className="form-control text-center pb-2"  ></input></td>
                </tr>
                <tr>
                  <td colSpan="6"></td>
                  <td colSpan="2" className="font-weight-bold">{helper().translate("TOTAL_SALDO")}:</td>
                </tr>
                <tr>
                  <td colSpan="6"></td>
                  <td colSpan="2"><input style={{ width: '99%' }} type="text" disabled defaultValue="0" value={helper().formatNumber(totals?.totalSaldo)} className="form-control text-center pb-2"  ></input></td>
                </tr>
              </tfoot>
            </table>
          </div>}
        </div>
      </div>
    </div>
  )
}


export default TransactionDetails;