import React, { useState, useEffect, useRef } from 'react';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import SubNav from '../../PageComponents/Nav/SubNav'
import CashPayments from './CashPayments';
import BanksPayments from './BanksPayments';;
import AddBanksPayments from './AddBanksPayments';
import AddCashPayments from './AddCashPayments';
import helper from '../../Services/helper';

const PaymentsMenus = (props) => {
    const links = [
        {path:"payments/cash", name:helper().translate("CASH_PAYMENTS_LIST"), icon:"fas fa-money-check-alt mr-2", component: CashPayments, type:"menu"},
        {path:"paymentcash/:id", name:helper().translate("CASH_PAYMENT"), icon:"fas fa-money-bill mr-2", component: AddCashPayments, type:"link"},
        {path:"payments/banks", name:helper().translate("BANK_PAYMENTS_LIST"), icon:"fas fa-money-bill mr-2", component: BanksPayments, type:"menu"},
        {path:"paymentbank/:id", name:helper().translate("BANK_PAYMENT"), icon:"fas fa-money-bill mr-2", component: AddBanksPayments, type:"link"}
        // {path:"payment/bank/0", name:"F | Transaksionet", icon:"fas fa-money-bill mr-2", component: AddTransactionBanks, type:"menu"}
    ]
    return (
        <div className="d-flex">
            <div className="p-0 position-relative sub-menu">
                <SubNav links={links} />
            </div>
            <div className="w-100 overflow-auto overflow-auto pr-0">
                {props.location.pathname === '/administration' && <AddUser />}
                <Router>
                    <Switch>
                        {links.map((link , key)=>{
                            return <Route key={key} path={`/${link.path}`} component={link.component} />
                        })}
                    </Switch>
                </Router>
            </div>
        </div>
    )
}

export default PaymentsMenus
