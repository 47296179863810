import React, { Component } from 'react'
import { Page, Text, View, Document, StyleSheet, PDFViewer, PDFDownloadLink, Image,Font } from '@react-pdf/renderer';
import apiService from "../../Services/apiServices";
import globals from '../../Services/globals';
import { auto, right } from '@popperjs/core';
import calculate from '../../Services/calculate';
import helper from '../../Services/helper';
import LoaderComponent from '../UI/Components/LoaderComponent';
import config from '../../config/config';
import FontUbuntuRegular from './fonts/Ubuntu-Regular.ttf';
import FontUbuntuItalic from './fonts/Ubuntu-Italic.ttf';
import FontUbuntu700 from './fonts/Ubuntu-Bold.ttf';
import FontUbuntuLight from './fonts/Ubuntu-Light.ttf';

class Invoice6 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sale: {},
            saleDetails: [],
            partner: {},
            saleType: {},
            units: {},
            settings: {},
            saleId: props.match ? props.match.params.id : props.saleId,
            //vatGroup: []
            loader: true
        }

    }
    componentDidMount() {

        apiService().query('getSale', 'id=' + this.state.saleId)
            .then((response) => {
                if (response.success) {
                    var sale = response.data[0];
                    sale.totalTvsh = (parseFloat(sale.totalSum) + parseFloat(sale.vatSum)).toFixed(2);
                    this.setState({
                        sale: sale
                    });
                    this.getSaleDetails(sale.id, sale.partnerId, sale.salesTypeId)
                    //this.props.addToast('Success!')
                    //this.props.addToast('Saved Successfully', { appearance: 'success', autoDismiss: true, });
                }
                else {
                    console.log('ka gabim: ', response);
                    //this.props.addToast('Ka ndodhur nje gabim!', { appearance: 'error', autoDismiss: true, });
                }
            });
        apiService().get('units').then((response) => {
            if (response.success) {
                this.setState({ units: response.data });

            } else {
                console.log('Error:' + response.errorMessage);
            }
        });
            globals().getSettings().then((response) => {
                if (response) {
                let sett = response;
                this.setState({ settings: sett });
                }
            })
    }

    grupByVat = (items) => {
        let group = items.reduce((r, a) => {
            r[a.vat] = [...r[a.vat] || [], a];
            return r;
        }, {});
        this.vatGroup(group)
    }

    getSaleDetails(saleId, partnerId, salesTypeId) {
        apiService().query('getSaleDetails', 'saleId=' + saleId)
            .then((response) => {
                var data = response.data;
                if (response.success) {
                    for (var i = 0; i < data.length; i++) {
                        if (data[i].discount > 0) {

                            data[i].priceDiscount = calculate().calculateSale('priceDiscount')(data[i].price, data[i].discount);
                            data[i].priceTvsh = calculate().calculateSale('priceTvsh')(data[i].priceDiscount, data[i].vat);
                            data[i].totalPrice = calculate().calculateSale('totalPrice')(data[i].priceDiscount, data[i].quantity);
                            data[i].totalPriceTvsh = calculate().calculateSale('totalPriceTvsh')(data[i].totalPrice, data[i].vat);
                            // data[i].totalPriceItemTvsh= calculate().calculateSale('totalPriceItemTvsh')(data[i].totalPriceTvsh , data[i].quantity);

                        } else {
                            data[i].priceTvsh = calculate().calculateSale('priceTvsh')(data[i].price, data[i].vat);
                            data[i].totalPrice = calculate().calculateSale('totalPrice')(data[i].price, data[i].quantity);
                            data[i].totalPriceTvsh = calculate().calculateSale('totalPriceTvsh')(data[i].totalPrice, data[i].vat);
                            // data[i].totalPriceItemTvsh= calculate().calculateSale('totalPriceItemTvsh')(data[i].totalPriceTvsh , data[i].quantity);

                        }
                    }
                    this.grupByVat(data)
                    this.setState({ saleDetails: data });
                    this.setState({
                        loader: false
                    })
                }
                else {
                    console.log('ka gabim sd: ', response);
                    //this.props.addToast('Ka ndodhur nje gabim!', { appearance: 'error', autoDismiss: true, });
                }
            });
        apiService()
            .query('partner', 'id=' + partnerId)
            .then((response) => {
                if (response.success) {
                    this.setState({
                        partner: response.data[0]
                    });
                    //console.log('Partner: ', response.data);
                } else {
                    console.log('Error:' + response.errorMessage);
                }
            });
        apiService()
            .query('getSaleType', 'id=' + salesTypeId)
            .then((response) => {
                if (response.success) {
                    this.setState({
                        saleType: response.data[0]
                    });
                } else {
                    console.log('Error:' + response.errorMessage);
                }
            });
    }

    checkColumn = (property, items) => {
        // const isTrue = (currentValue) => currentValue[property];
        // return items?.every(isTrue)
        return true
    }

    vatGroup = (group) => {
        let arr = []
        Object.entries(group).forEach(([key, value]) => {
            arr.push({ key: key, value: this.calculateVat(value) })
        })

        this.setState({
            vatGroup: arr
        })
    }
    calculateVat = (items) => {
        return items?.reduce((accumulator, current) => parseFloat(accumulator) + current.vatSum, 0)
    }

    render() {
        Font.register({
            family: 'Ubuntu',
            fonts: [
              {
                src: FontUbuntuRegular,
              },
              {
                src: FontUbuntuItalic,
                fontWeight: 'italic',
              },
              {
                src: FontUbuntu700,
                fontWeight: 'bold',
              },
              {
                src: FontUbuntuLight,
                fontWeight: 'light',
              },
            ],
          });
          Font.register({ family: 'Times-Italic'});
        let styles = StyleSheet.create({
            page: {
                display: 'flex',
                //flexDirection: 'row',
                //alignItems:'baseline',
                backgroundColor: '#fff',
                width: '100%',
                flexDirection: 'row'
            },
            section: {
                //paddingHorizontal: 5,
                flexGrow: 1,
                width: '50%',
            },
            td: {
                marginLeft: 10,
                marginRight: 10
            },
            text: {
                // borderBottom: 1, 
                fontSize: 9,
                paddingTop: 2,
                paddingHorizontal: 2
            },
            textTblHeader: {
                paddingVertical: 2,fontFamily: 'Ubuntu' , fontSize: 10, fontWeight: 'bold'
            },
            textTotal: {
                paddingVertical: 3,fontFamily: 'Ubuntu' , fontSize: 10, fontWeight: 'bold'
            },
            text10: {
                paddingVertical: 2,fontFamily: 'Ubuntu' , fontSize: 10, fontWeight: 'light'
            },
            pageNumbers: {
                position: 'absolute',
                fontFamily: "Times-Italic",
                bottom: 8,
                left: 0,
                right: 0,
                textAlign: "center",
                paddingVertical: 2,fontFamily: 'Ubuntu' , fontSize: 7, fontWeight: 'light'
            },
        });
        let sale = this.state.sale;
        let saleDetails = this.state.saleDetails;
        let partner = this.state.partner;
        let vatGroup = this.state.vatGroup;
        let loader = this.state.loader;
        let settings = this.state.settings;
        return (

            <div className="pdf my-4">
                {loader && <LoaderComponent />}
                {!loader &&
                    <PDFViewer>
                        <Document style={{ justifyContent: 'center' }}>
                            <Page size="A4" style={{ paddingVertical: 20, paddingHorizontal: 30, margin: 0 }} wrap>
                                <View style={{display:'flex', flexDirection:'row',marginTop:'5px', marginBottom:'20px'}}>
                                <Image src={settings?.img ? settings?.img : config.noLogo} style={{ width: settings?.img ? '150px' : '150px' }} />
                                    <View style={{marginLeft:'auto'}}>
                                        <View style={{marginTop:'5px'}}>
                                            <Text style={[{ fontFamily: 'Ubuntu' , fontSize: 17, fontWeight: 'bold',marginLeft:'auto',textAlign:'left' }]}>{settings.companyName}</Text>
                                        </View>
                                        <View style={{marginTop:'5px'}}>
                                            <Text style={[{ fontFamily: 'Ubuntu' , fontSize: 10, fontWeight: 'light',marginLeft:'auto',textAlign:'left' }]}>
                                                {settings.address}, {settings.city}
                                            </Text>
                                        </View>
                                        <View style={{marginTop:'5px'}}>
                                            <Text style={[{ fontFamily: 'Ubuntu' , fontSize: 10, fontWeight: 'light',marginLeft:'auto',textAlign:'left' }]}>
                                                Tel: {settings.phoneNo}
                                            </Text>
                                        </View>
                                        <View style={{marginTop:'5px'}}>
                                            <Text style={[{ fontFamily: 'Ubuntu' , fontSize: 10, fontWeight: 'light',marginLeft:'auto',textAlign:'left' }]}>{helper().translate("BUSINESS_NUMBER")}: {settings.businessNumber}</Text>
                                        </View>
                                        <View style={{marginTop:'5px'}}>
                                            <Text style={[{ fontFamily: 'Ubuntu' , fontSize: 10, fontWeight: 'light',marginLeft:'auto',textAlign:'left' }]}>{helper().translate("VAT_NUMBER")}: {settings.vatNumber}</Text>
                                        </View>
                                    </View>
                                </View>
                                
                                <View>
                                    <Text style={{ fontFamily: 'Ubuntu' , fontSize: 24,width:'50%' }}>{sale.salesTypeId == 1 ? helper().translate("SALE_INVOICE") : (sale.salesTypeId == 2) ? 'Faturë ' + this.state.saleType.Name : this.state.saleType.Name}</Text>
                                </View>
                                <View style={{display:'flex', flexDirection:'row',marginTop:'5px', borderBottom:1}}>
                                    <Text style={{ fontFamily: 'Ubuntu' , fontSize: 14}}>Invoice No: 
                                        <Text style={{ fontFamily: 'Ubuntu',fontWeight: 'light' , fontSize: 14}}> {sale.invoiceNo}</Text>
                                    </Text>
                                    <Text style={{ fontFamily: 'Ubuntu', fontSize: 14,marginLeft:'auto' }}>{helper().translate("DATE")}: 
                                        <Text style={{ fontFamily: 'Ubuntu',fontWeight: 'light' , fontSize: 14}}> {helper().formatDate(sale.date)}</Text>
                                    </Text>
                                </View>
                                <View style={{marginTop:'15px'}}>
                                    <Text style={{ fontFamily: 'Ubuntu' , fontSize: 14}}>{helper().translate("BILL_TO")}: <Text style={{ fontFamily: 'Ubuntu',fontWeight: 'light' , fontSize: 14}}> {partner.partnerType !== 6 ? partner.companyName : partner.subjectName}</Text> </Text>
                                    <Text style={[{ fontFamily: 'Ubuntu' , fontSize: 11 }]}>{helper().translate("BUSINESS_NUMBER")}: &nbsp; 
                                        <Text style={[{ fontFamily: 'Ubuntu' , fontSize: 11, fontWeight: 'light'}]}> 
                                        {partner.businessNo}
                                        </Text> 
                                    </Text>
                                    <Text style={[{ fontFamily: 'Ubuntu' , fontSize: 11 }]}>{helper().translate("ADDRESS")}: &nbsp; 
                                        <Text style={[{ fontFamily: 'Ubuntu' , fontSize: 11, fontWeight: 'light'}]}> 
                                        {partner.address} {partner.city}
                                        </Text> 
                                    </Text>
                                    <Text style={[{ fontFamily: 'Ubuntu' , fontSize: 11 }]}>{helper().translate("PHONE")}: &nbsp; 
                                        <Text style={[{ fontFamily: 'Ubuntu' , fontSize: 11, fontWeight: 'light'}]}> 
                                        {partner.phone}
                                        </Text> 
                                    </Text>
                                </View>

                                <View style={{ marginTop:'15px' }}>
                                    {/* width 565px */}
                                    <View style={[styles.page, { borderBottom: '1px dotted #000',borderTop:'1px dotted #000',paddingVertical:10, paddingHorizontal: 0, minHeight: '12px' }]}>
                                        <Text style={[styles.textTblHeader, { width: '20px', textAlign: 'center' }]}>{helper().translate("NR")}</Text>
                                        <Text style={[styles.textTblHeader, { width: '70px', textAlign: 'center'}]}>{helper().translate("BARCODE")}</Text>
                                        <Text style={[styles.textTblHeader, { width: '105px', textAlign: 'center', flexGrow: 3 }]}>{helper().translate("DESIGNATION")}</Text>
                                        <Text style={[styles.textTblHeader, { width: '40px', textAlign: 'center' }]}>{helper().translate("UNIT")}</Text>
                                        {this.checkColumn('quantity', saleDetails) &&
                                            <Text style={[styles.textTblHeader, { width: '35px', textAlign: 'center'}]}>{helper().translate("QUANTITY")}</Text>
                                        }
                                         {this.checkColumn('priceDiscount', saleDetails) &&
                                            <Text style={[styles.textTblHeader, { width: '45px', textAlign: 'center'}]}>{helper().translate("PRICE")}</Text>
                                        }
                                        {this.checkColumn('discount', saleDetails) &&
                                            <Text style={[styles.textTblHeader, { width: '45px', textAlign: 'center' }]}>{helper().translate("DISCOUNT")} %</Text>
                                        }
                                        {this.checkColumn('totalPriceTvsh', saleDetails) &&
                                            <Text style={[styles.textTblHeader, { width: '50px', textAlign: 'center' }]}>{helper().translate("TOTAL")}</Text>
                                        }
                                    </View>
                                    {saleDetails?.map((item, index) => (
                                        <View key={index} style={[styles.page, { borderBottom: '1px dotted #000',paddingVertical:3, paddingHorizontal: 0, overflow: 'hidden', height: 'auto', backgroundColor: 'transparent' }]}>
                                            <Text style={[styles.text10, { width: '20px', textAlign: 'center' }]}>{item.no}</Text>
                                            <Text style={[styles.text10, { width: '70px', textAlign: 'center'}]}>{item.barcode}</Text>
                                            <Text style={[styles.text10, { width: '105px', textAlign: 'center', flexGrow: 2 }]}>{item.itemName}</Text>
                                            <Text style={[styles.text10, { width: '40px', textAlign: 'center'}]}>{(this.state.units.find(u => u.id === item.unitId))?.name}</Text>
                                            {this.checkColumn('quantity', saleDetails) &&
                                                <Text style={[styles.text10, { width: '35px', textAlign: 'center'}]}>{item.quantity}</Text>
                                            }
                                           {this.checkColumn('priceTvsh', saleDetails) &&
                                                <Text style={[styles.text10, { width: '45px', textAlign: 'center'}]}>{typeof (item.priceTvsh) == 'undefined' ?
                                                    item.priceTvsh : helper().formatNumber(item.priceTvsh,true)}</Text>
                                            }
                                           
                                            {this.checkColumn('discount', saleDetails) &&
                                                <Text style={[styles.text10, { width: '45px', textAlign: 'center'}]}>{item.discount}</Text>
                                            }
                                            
                                            {this.checkColumn('totalPriceTvsh', saleDetails) &&
                                                <Text style={[styles.text10, { width: '50px', textAlign: 'center' }]}>{typeof (item.totalPriceTvsh) == 'undefined' ?
                                                    item.totalPriceTvsh : helper().formatNumber(item.totalPriceTvsh,true)}</Text>
                                            }
                                        </View>
                                    ))}
                                </View>
                                <View style={[styles.page, { marginTop: 5, paddingVertical: 10, alignItems: "flex-start" }]}>
                                    <View style={[{ width: '55%', padding: 5 }]}>
                                        <Text style={styles.textTblHeader}>{helper().translate("BANK_NUMBER")}:</Text>
                                        <Text style={styles.text10}>{settings.accNo}</Text>
                                        <Text style={[styles.textTblHeader,{marginTop:'5px'}]}>{helper().translate("COMMENT")}:</Text>
                                        <Text style={[styles.text10, { paddingRight: 10 }]}>{sale.comment ? sale.comment : ""}</Text>
                                    </View>
                                    <View style={[{ width: '45%', paddingVertical: 0 }]}>
                                        <View style={[styles.page, { borderBottom: '1px dotted #000', marginTop: 0, paddingHorizontal: 5, alignItems: "flex-end"}]}>
                                            <View style={{ width: '50%'}}>
                                                <Text style={[styles.textTotal, { textAlign: 'left' }]}>{helper().translate("TOTAL")}: </Text>
                                            </View>
                                            <View style={{ width: '50%' }}>
                                                <Text style={[styles.textTotal, { textAlign: 'right' }]}>{
                                                    typeof ((sale.totalSum)) == 'undefined' ?
                                                        (sale.totalSum) : helper().formatNumber(sale.totalSum)
                                                }</Text>
                                            </View>
                                        </View>
                                        {
                                            vatGroup?.map((item) => (
                                                <View style={[styles.page, { borderBottom: '1px dotted #000', marginTop: 0, paddingHorizontal: 5, alignItems: "flex-end" }]}>
                                                    <View style={{ width: '50%'}}>
                                                        <Text style={[styles.textTotal, { textAlign: 'left' }]}>{helper().translate("VAT_VALUE")}({item.key}%): </Text>
                                                    </View>
                                                    <View style={{ width: '50%' }}>
                                                        <Text style={[styles.textTotal, { textAlign: 'right' }]}>{helper().formatNumber(item.value)}</Text>
                                                    </View>
                                                </View>
                                            ))
                                        }
                                        <View style={[styles.page, { borderBottom: '1px dotted #000', marginTop: 0, paddingHorizontal: 5, alignItems: "flex-end"}]}>
                                            <View style={{ width: '50%'}}>
                                                <Text style={[styles.textTotal, { textAlign: 'left' }]}>{helper().translate("TOTAL_WITH_VAT")}: </Text>
                                            </View>
                                            <View style={{ width: '50%' }}>
                                                <Text style={[styles.textTotal, { textAlign: 'right' }]}>{typeof (sale.totalTvsh) == 'undefined' ?
                                                    (sale.totalTvsh) : helper().formatNumber( parseFloat(sale.totalTvsh))
                                                     }</Text>
                                            </View>
                                        </View>
                                    </View>
                                </View>
                                <View style={[styles.page, { width: '100%', marginTop: 80 }]}>
                                    <View style={[{ textAlign: 'center', width: '50%' }]}>
                                        <Text style={[styles.text10, { width: '60%', borderTop: 1, margin: 'auto' }]}>{helper().translate("SUBMITTED")}</Text>
                                    </View>
                                    <View style={[{ textAlign: 'center', width: '50%' }]}>
                                        <Text style={[styles.text10, { width: '60%', borderTop: 1, margin: 'auto' }]}>{helper().translate("RECEIVED")}</Text>
                                    </View>
                                </View>
                                <Text style={styles.pageNumbers} fixed>{helper().translate("IMPLEMENTED_BY")} Nsum Solution</Text>
                            </Page>
                        </Document>
                    </PDFViewer>
                }
            </div>
        )
    }
}

export default Invoice6
