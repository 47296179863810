import React, { useEffect, useState, useRef } from 'react'
import apiService from '../../../Services/apiServices'
import DataTable from '../../../PageComponents/UI/Tables/DataTable';
import helper from '../../../Services/helper';
import Select from "react-select"
import { Switch } from '@material-ui/core';
import ExcelExport from '../../../PageComponents/Excel/ExcelExport';
import Search from '../../../PageComponents/Search/Search_Old';
import { MDBInput } from 'mdb-react-ui-kit';
import Print from '../../../PageComponents/Print/Print';
import globals from '../../../Services/globals';
import LoaderComponent from '../../../PageComponents/UI/Components/LoaderComponent';

const ReportSaleBook = () => {
    const [data, setData] = useState([])
    const d = new Date();
    const searchObj = { CompanyBranch: 0, PurchaseType: 0, StartDate: '', EndDate: (new Date()).toISOString().substring(0, 10), WarehouseId: 0, includeStartDate: true, includeEndDate: true }
    const [searchQuery, setSearchQuery] = useState(searchObj)
    const [saleType, setSaleType] = useState()
    const [partners, setPartners] = useState()
    const [salesTypes, setSalesTypes] = useState()
    const [warehouses, setWarehouses] = useState()
    const [open, setOpen] = useState(true)
    const logedInUser = globals().getLogedInUser()
    const [loader , setLoader] = useState(false);
    const [reference, setReference] = useState(false)

    const selectStyles = {
        control: (provided, state) => ({
            ...provided,
            background: '#fff',
            borderColor: '#9e9e9e',
            minHeight: '28.59px',
            height: '28.59px',
            boxShadow: state.isFocused ? null : null,
        }),

        valueContainer: (provided, state) => ({
            ...provided,
            height: '28.59px',
            padding: '0 6px'
        }),

        input: (provided, state) => ({
            ...provided,
            margin: '0px',
        }),
        indicatorSeparator: state => ({
            display: 'none',
        }),
        indicatorsContainer: (provided, state) => ({
            ...provided,
            height: '28.59px',
        }),
    };

    const t = (key) => {
        return helper().translate(key);
    }

    //d.setDate(d.getDate() - 30);
    //searchObj.StartDate = d.toISOString();
    //searchObj.StartDate = searchObj.StartDate.substring(0, 10);
    
    let date = new Date(), y = date.getFullYear(), m = date.getMonth();
    searchObj.StartDate = new Date(y, m, 2) //new Date(y, m, 2);
    searchObj.StartDate = searchObj.StartDate.toISOString();
    searchObj.StartDate = searchObj.StartDate.substring(0, 10);

    const excelExportRef = useRef();
    const printRef = useRef();
    const fileName = helper().translate("SALES_BOOK")

    const books = [
        {nr:1, name:'Libri Shitje Vendore'},
        {nr:2, name:'Libri Shitje EX'},
        {nr:3, name:'Libri Not Debiti'},
        {nr:4, name:'Libri Kthimi i Shitjeve'},
        {nr:5, name:'Libri Ngarkesa e Kundert'},
        {nr:6, name:'Libri Fletdergesat'},
        {nr:7, name:'Libri Oferta'},
        {nr:8, name:'Libri Shitje Investive Vendore'},
        {nr:9, name:'Libri Shitje Investive Export'},
        {nr:10, name:'Libri ProFatura'},
        {nr:11, name:'Libri ProFatura Ex'},
        {nr:12, name:'Libri Oferta Ex'}
    ]
    const reportName = books.find(b => b.nr === searchQuery?.PurchaseType) ? books.find(b => b.nr === searchQuery?.PurchaseType)?.name : helper().translate("SALES_BOOK")

    const tables = [
        { name: 'partners', method: setPartners },
        { name: 'salesType', method: setSalesTypes },
        { name: 'warehouses', method: setWarehouses },
    ];

    const months = [
        { value: 1, label: 'Janar' },
        { value: 2, label: 'Shkurt' },
        { value: 3, label: 'Mars' },
        { value: 4, label: 'Prill' },
        { value: 5, label: 'Maj' },
        { value: 6, label: 'Qershor' },
        { value: 7, label: 'Korrik' },
        { value: 8, label: 'Gusht' },
        { value: 9, label: 'Shtator' },
        { value: 10, label: 'Tetor' },
        { value: 11, label: 'Nëntor' },
        { value: 12, label: 'Dhjetor' },
    ]
    const [years, setYears] = useState()

    const checkColumn = (header, items) => {
        if(header && header.subHeader){
            const isTrue = (currentValue) => parseFloat(currentValue[header.key]) !== 0;
            if(!items?.every(isTrue)) {
                return header
            }
        }
        else{
            return header
        }
     }

    const headers = [
        { key: 'Nr', columnName: t('NU') , dontShowMenu: true},
        { key: 'Date', columnName: t('DATE') },
        !reference && { key: 'InvoiceNo', columnName: t('INVOICE_NO') },
        reference && { key: 'Reference', columnName: t('REFERENCE') },
        { key: 'CompanyName', columnName: t('COMPANY_NAME') },
        { key: 'FiscalNumber', columnName: t('FISCAL_NUMBER') },
        { key: 'VatNumber', columnName: t('VAT_NUMBER') },
        // [0, 1].includes(saleType) && { key: 'K9', columnName: t('K9'), description: 'Shitjet e liruara pa te drejte kreditimi', subHeader: true, calculate: true },
        { key: 'K9', columnName: t('K9'), description: 'Shitjet e liruara pa te drejte kreditimi', subHeader: true, calculate: true },
        saleType === 0 && { key: 'K10a', columnName: t('K10A'), subHeader: true, calculate: true },
        saleType === 0 && { key: 'K10b', columnName: t('K10B'), subHeader: true, calculate: true },
        saleType === 0 && { key: 'K10c', columnName: t('K10C'), subHeader: true, calculate: true },
        saleType === 0 && { key: 'K10', columnName: t('K10'), subHeader: true, calculate: true },
        saleType === 0 && { key: 'K11', columnName: t('K11'), description: 'Eksportet', subHeader: true, calculate: true },
        // [0, 1].includes(saleType) && { key: 'K12', columnName: t('K12'), description: 'Shitjet e tatueshme me 18%', subHeader: true, calculate: true },
        { key: 'K12', columnName: t('K12'), description: 'Shitjet e tatueshme me 18%', subHeader: true, calculate: true },
        // [0, 3].includes(saleType) && { key: 'K16', columnName: t('K16'), description: 'Nota debitore e leshuar, nota kreditore e pranuar', subHeader: true, calculate: true },
        { key: 'K16', columnName: t('K16'), description: 'Nota debitore e leshuar, nota kreditore e pranuar', subHeader: true, calculate: true },
        saleType === 0 && { key: 'K20', columnName: t('K20'), subHeader: true, calculate: true },
        saleType === 0 && { key: 'K24', columnName: t('K24'), subHeader: true, calculate: true },
        // [0, 5].includes(saleType) && { key: 'K28', columnName: t('K28'), description: 'Blerjet qe i nenshtrohen ngarkeses se kundert', subHeader: true, calculate: true },
        { key: 'K28', columnName: t('K28'), description: 'Blerjet qe i nenshtrohen ngarkeses se kundert', subHeader: true, calculate: true },
        // [1, 3, 5].includes(saleType) && { key: 'K1', columnName: t('K1'), description: 'Totali i TVSH-se se llogaritu me normen 18%', subHeader: true, calculate: true },
        { key: 'K1', columnName: t('K1'), description: 'Totali i TVSH-se se llogaritu me normen 18%', subHeader: true, calculate: true },
        // [0, 1].includes(saleType) && { key: 'K14', columnName: t('K14'), description: 'Shitjet e tatueshme me 8%', subHeader: true, calculate: true },
        { key: 'K14', columnName: t('K14'), description: 'Shitjet e tatueshme me 8%', subHeader: true, calculate: true },
        saleType === 0 && { key: 'K18', columnName: t('K18'), subHeader: true, calculate: true },
        saleType === 0 && { key: 'K22', columnName: t('K22'), subHeader: true, calculate: true },
        saleType === 0 && { key: 'K26', columnName: t('K26'), subHeader: true, calculate: true },
        // [0, 1].includes(saleType) && { key: 'K2', columnName: t('K2'), description: 'Totali i TVSh-se se llogaritur me normen 8%', subHeader: true, calculate: true },
        { key: 'K2', columnName: t('K2'), description: 'Totali i TVSh-se se llogaritur me normen 8%', subHeader: true, calculate: true },
        // [0, 1, 3, 5].includes(saleType) && { key: 'K30', columnName: t('K30'), noPrint: true },
        { key: 'K30', columnName: t('K30'), noPrint: true },
        {key: 'GjithesejShitja', columnName: 'Gjithesej Shitja', description: 'Total Shitja', subHeader: true, calculate: true}
    ];

    const fillYears = () => {
        let s = d.getFullYear() - 10
        let e = d.getFullYear()
        let years = []

        for (let index = s; index <= e; index++) {
            let obj = { value: index, label: index }
            years.push(obj)
        }
        setYears(years)
    }

    const getDefaults = (name, method) => {
        apiService().get(name).then((response) => {
            if (response.success) {
                let records = response.data
                if(name === "salesType"){
                    records = records.map(record => ({ value: record.id, label: name != 'partners' ? helper().translate(`${record.name}`)  : record.saveAs }))
                }else{
                    
                    records = records.map(record => ({ value: record.id, label: name != 'partners' ? record.name : record.saveAs }))
                }
                method(records)
            }
        })
    }

    const getData = () => {
        setLoader(true);
        var params = `CompanyBranch=${searchQuery.CompanyBranch}&PurchaseType=${searchQuery.PurchaseType}&StartDate=${searchQuery.includeStartDate ? searchQuery.StartDate : "1900-01-01"}&EndDate=${searchQuery.EndDate+" 23:59:59"}&WarehouseId=${searchQuery.WarehouseId}`;

        apiService().query('RptSaleBook', params)
        .then((response) => {
                if (response.success) {
                    setLoader(false)
                    let res = response.data
                    res = res.forEach(el => el.Date = helper().formatDate(el.Date));
                    response.data ? setData(response.data) : setData([])
                    setSaleType(searchQuery.PurchaseType)
                }
            })
    }

    const print = () => {
        printRef.current.printPreview()
    }

    const exportToExcel = () => {
        excelExportRef.current.exportToExcel(fileName)
    }

    const pdf = () => {
        var divContents = document.getElementById("dvContainer").outerHTML;
        var printWindow = window.open('', '', 'height=400,width=800');
        //printWindow.document.write('<html><head><title>DIV Contents</title>');
        //printWindow.document.write('</head><body >');
        printWindow.document.write(divContents);
        //printWindow.document.write('</body></html>');
        //printWindow.document.close();
        //printWindow.print();
    }

    useEffect(() => {
        //getData()
        fillYears()
        tables.forEach((table, index) => {
            getDefaults(table.name, table.method)
        })
        getData()
    }, [])

    return (
        <div>
            {open && <Print data={data.length ? data : []} headers={headers.filter(header => header !== false)} query={searchQuery} ref={printRef} reportName={reportName} formName="ReportSaleBook" />}
            <div className="container-fluid px-2 mt-3">
                <Search search={false} excel={() => exportToExcel()} printClick={print} back="#/reports"/>
                <div className="card border-left-primary shadow h-100 mt-1">
                    <div className="card-body pt-3 pb-1">
                        <div className="row">
                            <div className="col-md-11 col-12">
                                <div className="row no-gutters align-items-center">
                                    <div className="col mr-2 row">
                                        <div className='w-100 d-md-flex d-lg-flex' >
                                            <div className="col-lg-3 mb-3 mb-md-0 pe-0 d-flex align-items-center">
                                                <div style={{ width: '5%' }}>
                                                    <input type="checkbox" name="includeStartDate" checked={searchQuery.includeStartDate ? 'checked' : ''} onChange={(e) => {setSearchQuery({ ...searchQuery, includeStartDate: e.target.checked }), console.log(e.target.checked)}}></input>
                                                </div>
                                                <div style={{ width: '95%' }}>
                                                    <MDBInput
                                                        name="StartDate"
                                                        disabled={!searchQuery.includeStartDate ? 'disabled' : ''}
                                                        className="form-control-sm active"
                                                        onChange={(e) => setSearchQuery({ ...searchQuery, StartDate: e.target.value })}
                                                        type="date"
                                                        label={helper().translate("START_DATE")}
                                                        //value={searchQuery.StartDate ? helper().formatDate(searchQuery.StartDate, 'yyyy-MM-dd') : (new Date()).toISOString().substring(0, 10)}
                                                        value={searchQuery.StartDate}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-3 mb-3 mb-md-0 pe-0 d-flex align-items-center">
                                                <div style={{ width: '5%' }}>
                                                    <input type="checkbox" name="includeEndDate" checked={searchQuery.includeEndDate ? 'checked' : ''} onChange={(e) => setSearchQuery({ ...searchQuery, includeEndDate: e.target.checked })}></input>
                                                </div>
                                                <div style={{ width: '95%' }}>
                                                    <MDBInput
                                                        name="EndDate"
                                                        disabled={!searchQuery.includeEndDate ? 'disabled' : ''}
                                                        className="form-control-sm active"
                                                        onChange={(e) => setSearchQuery({ ...searchQuery, EndDate: e.target.value })}
                                                        type="date"
                                                        label={helper().translate("END_DATE")}
                                                        // value={searchQuery.EndDate ? helper().formatDate(searchQuery.EndDate, 'yyyy-MM-dd') : (new Date()).toISOString().substring(0, 10)}
                                                        value={searchQuery.EndDate}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-2 col-lg-2 mb-3 mb-md-0 pe-0">
                                                <Select styles={selectStyles} placeholder={helper().translate("SALE_TYPE")} value={months?.find(m => m.value === searchQuery.EndMonth)} options={salesTypes} onChange={(e) => { setSearchQuery({ ...searchQuery, PurchaseType: e.value }) }} />
                                            </div>
                                            <div className="col-md-2 col-lg-2 mb-3 mb-md-0 pe-0">
                                                <Select styles={selectStyles} placeholder={helper().translate("WAREHOUSE")} value={warehouses?.find(w => w.value === searchQuery.WarehouseId)} options={warehouses} onChange={(e) => { setSearchQuery({ ...searchQuery, WarehouseId: e.value }) }} />
                                            </div>
                                            <div className="col-md-2 col-lg-2">
                                                {/* <label className="text-xs font-weight-bold text-primary text-uppercase">
                                                    {t('REFERENCE')}
                                                </label> */}
                                            
                                                <Switch className="margin-7" color="primary" onChange={(e)=> setReference(e.target.checked)} checked={reference}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-auto">
                                        <a className="searchbutton" onClick={getData}>
                                            <i className="fas fa-search fa-2x text-dark" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card shadow mt-1">
                    <div className="card-header py-2">
                        <h6 className="m-0 font-weight-bold text-primary text-center">{helper().translate("SALES_BOOK")}</h6>
                    </div>
                    {loader && <LoaderComponent /> }
                    {!loader ? data.length ? <DataTable data={data.length ? data : []}  headers={headers?.filter(header => checkColumn(header, data))} formName="ReportSaleBook" /> : <div className="p-5 text-center">{helper().translate("NO_DATA")}</div>: "" }
                    <ExcelExport data={data.length ? data : []} headers={headers} type={'other'} ref={excelExportRef} hideContent={true} />
                </div>
            </div>
        </div>
    )
}

export default ReportSaleBook;
