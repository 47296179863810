import React from 'react'

const TabNav = ({id, title, active, setShow}) => {
    return (
        <>
            <button 
                className={`nav-link ${active ? 'active show' : ''}`} 
                id={'id_'+title.replace(/\s+/g, '')}
                data-bs-toggle="tab" 
                data-bs-target={'#tar_'+title.replace(/\s+/g, '')} 
                type="button" 
                role="tab" 
                aria-controls={'#tar_'+title.replace(/\s+/g, '')}
                aria-selected="true"
                onClick={() => setShow(id)}
            >
                {title}
            </button>
        </>
    )
}

export default TabNav
