import React, { useState, useEffect ,useContext , useRef} from "react";
import Select from "react-select";
import { MDBInput } from "mdb-react-ui-kit";
import apiService from "../../Services/apiServices";
import { useToasts } from "react-toast-notifications";
import Search from "../../PageComponents/Search/Search_Old";
import { confirmAlert } from "react-confirm-alert";
import helper from "../../Services/helper";
import globals from "../../Services/globals";
import LoaderComponent from "../../PageComponents/UI/Components/LoaderComponent";
import UserPermissionContext from "../Context/UserPermissionContext";
import ExcelExport from "../../PageComponents/Excel/ExcelExport";

function WarehouseTransfers() {
    const [item, setItem] = useState([]);
    const [find, setFind] = useState([]);
    const [findByDate , setFindByDate] = useState({warehouseFrom: null ,warehouseFromVal: false,  warehouseTo: null , warehouseToVal: false,  dateStart: null, dateEnd:null , dateStartDisabled: true , dateEndDisabled: true });
    const [warehouse, setWarehouse] = useState([]);
    const [dataShow, setDataShow] = useState(true);
    const [userColumns , setUserColumns] = useState([]);
    const [newColumns , setNewColumns] = useState(["DOCUMENT_NR",  "DATE" , "FROM_WAREHOUSE" , "TO_WAREHOUSE", "TOTAL_SUM", "COMMENT" , "REGISTRATION_DATE" , "REGISTERED_BY", "DATE_OF_MODIFICATION" ,"MODIFIED_BY" ]);
    const [showColMenu , setShowColMenu] = useState(false);
    const [loader , setLoader] = useState(false);
    const { addToast } = useToasts();
    const excelExportRef = useRef();
    const {userPermission} = useContext(UserPermissionContext);


    const exportToExcel = () => {
      // let table = document.getElementById('table-to-xls-1').innerHTML;
      let fileName = helper().translate("WAREHOUSE_TRANSFERS");
      // return helper().exportToExcel(table, fileName)
      excelExportRef.current.exportToExcel(fileName)
  }

  const headers = [
    { key: 'countNumber', columnName: helper().translate("NR") },
    { key: 'id', columnName: helper().translate("DOCUMENT_ID") },
    { key: 'date', columnName: helper().translate("DATE")  },
    { key: 'fromWarehouse', columnName: helper().translate("FROM_WAREHOUSE") , type:"text"  },
    { key: 'toWarehouse', columnName: helper().translate("TO_WAREHOUSE") , type:"text" },
    { key: 'comment', columnName: helper().translate("COMMENT") , type:"comment" },
    { key: 'createdAt', columnName: helper().translate("REGISTRATION_DATE") , type:"text"  },
    { key: 'createdBy', columnName: helper().translate("REGISTERED_BY") , type:"text" },
    { key: 'changedAt', columnName: helper().translate("DATE_OF_MODIFICATION") , type:"text" },
    { key: 'changedBy', columnName: helper().translate("MODIFIED_BY") , type:"text" }
  ];

    const printRow = (item) => {
      if(item) {
          //window.location.href = `#/printPurchase/${item.id}`
          window.open(`#/print-warehouse-transers/${item.id}`, 'popUpWindow','height=3000,width=32000,left=100,top=100,right=100,bottom=100,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes')
      }
      else {
          console.log("print isnt working right now please try again");
      }
  }


    const getWarehouses = () => {
      apiService()
        .get("warehouses")
        .then((response) => {
          if (response.success) {
            let data = response.data;
            let firstItem = {id: null , name: helper().translate("ALL_WAREHOUSES")};
            data.unshift(firstItem);
              let options = data.map((item, index) => ({
                value: item.id,
                label: item.name
              }));
            setWarehouse(options);
          } else {
            alert("Error: getting warehouses" + response.errorMessage);
          }
        });
  }
      const GetWarehouseTransers = () => {
        setLoader(true);
        let dateTimeNow = new Date();
        let dateTimeStart = new Date(dateTimeNow.getFullYear(),dateTimeNow.getMonth(),dateTimeNow.getDate()-30); 
        let strStartDate = helper().formatDate(dateTimeStart,'MM-dd-yyyy');
        let strEndDate = helper().formatDate(dateTimeNow,'MM-dd-yyyy') + " 23:59:59"

        var params = `&dateStart=${strStartDate}&dateEnd=${strEndDate}&warehouseFromId=${findByDate.warehouseFrom}&warehouseToId=${findByDate.warehouseTo}`;
        apiService()
        .query("getWarehouseTransfers", params)
        .then((response) => {
            if (response.success) {
              var count = 1;
              var data = response.data;
              data.forEach(e=> {
                e.countNumber = count; count++,
                e.date = helper().formatDate(e.date),
                e.createdAt = helper().formatDate(e.createdAt),
                e.changedAt = e.changedAt ? helper().formatDate(e.changedAt) :'' ,
                e.totalSum = e.totalSum.toFixed(2)
              });
            setFind(data);
            setItem(data);
            setLoader(false)
            setDataShow(response.data?.length > 0 ? true : false);
            }
        }
        )

        setFindByDate({...findByDate , dateStart: dateTimeStart , dateEnd: dateTimeNow});
      };


    useEffect(() => {
       setLoader(true);
        GetWarehouseTransers();
        getWarehouses();
        let user = globals().getLogedInUser().data.username;
        getUserColumns(user);
    },[])

    const selectStyles = {
        control: (provided, state) => ({
          ...provided,
          background: "#fff",
          borderColor: "#9e9e9e",
          minHeight: "28.59px",
          height: "28.59px",
          boxShadow: state.isFocused ? null : null,
        }),
    
        valueContainer: (provided, state) => ({
          ...provided,
          height: "28.59px",
          padding: "0 6px",
        }),
    
        input: (provided, state) => ({
          ...provided,
          margin: "0px",
        }),
        indicatorSeparator: (state) => ({
          display: "none",
        }),
        indicatorsContainer: (provided, state) => ({
          ...provided,
          height: "28.59px",
        }),
      };

      const getUserColumns = (user) => {
        apiService().query('userColumns', `username=${user}&formname=WarehouseTransfer`)
            .then(response => {
                if(response.success && response.data.length > 0) {
                    let results = response.data[0].hColumns.split(',')
                    setUserColumns( results);
                }
                else {
                setUserColumns(newColumns);
               let obj = {userName: user, formName: 'WarehouseTransfer', hColumns: newColumns.toString()};
               apiService().post('userColumns', obj)
               .then(response => {
                  //  console.log(response)
               })
                }
            })
    }
    
      const fieldChange = (e) => {
          if(e.target.name === "dateStart"){
              setFindByDate({...findByDate , dateStart: e.target.value + " 00:00:00" });
          }else
          if(e.target.name === "dateEnd"){
            setFindByDate({...findByDate , dateEnd: e.target.value + " 23:59:59" }); 
        }
      };
      const selectChange = (e,obj) => {
        if (obj.name === "warehouseFrom"){
            setFindByDate({...findByDate, warehouseFrom:e.value , warehouseFromVal: false})
        }else 
        if (obj.name === "warehouseTo"){
          setFindByDate({...findByDate, warehouseTo:e.value , warehouseToVal: false})
      }
      };

      const findChange = (e) => {
        if (e.target.value == "") {
          GetWarehouseTransers();
        }
        var searchPayment;
        let searchString = e.target.value.trim().toLowerCase();
    
        if (searchString.length > 0) {
          // We are searching. Filter the results.
          //searchItems = searchItems.filter((e) => e.ItemName.toLowerCase().match(searchString));
          searchPayment = item.filter((item) => {
            return (
              item?.comment?.toString().toLowerCase().match(searchString) ||
              item?.createdBy?.toString().toLowerCase().match(searchString) ||
              item?.totalSum?.toString().toLowerCase().match(searchString) 
            );
          });
          setFind(searchPayment);
        }
      };

      const filterRowMenu = () => {
        let menuColOpen =   showColMenu;
        if(menuColOpen){
          menuColOpen = false
        }else{
          
          menuColOpen = true
        }
        setShowColMenu(menuColOpen)
      }

      const filterRowOnEyeClick = (itemToRemove) => {
        let col = [...userColumns] ;
        let findCol = col.find(c => c == itemToRemove);
        if(findCol){
          var result = col.filter(c => c !== itemToRemove);
        }else{
          col.push(itemToRemove)
          var result = col;
        }
        let user = globals().getLogedInUser().data.username;
        if(user){
          let obj = {userName: user, formName: 'WarehouseTransfer', hColumns: result.toString()};
          apiService().put('userColumns', obj)
          .then(response => {
              if(response.success){
                setUserColumns(result);
              }
          })
        }
      
      }

      const checkRowIfVisible = (itemToCheck) => {
        filterRowOnEyeClick(itemToCheck);
        
      }

      const alertnOnDelete = (id)=> {
        confirmAlert({
            customUI: ({ onClose }) => {
              return (
                <div className="custom-ui">
                  <h1>{helper().translate("ARE_YOU_SURE")}</h1>
                  <p>{helper().translate("WANT_TO_DELETE_THIS_WAREHOUSE_TRANSFER")}</p>
                  <button
                    onClick={() => {
                      handleDelete(id);
                      onClose();
                    }}
                  >
                    {helper().translate("YES")} , {helper().translate("DELETE")}!
                  </button>
                  <button onClick={onClose}>{helper().translate("NO")}</button>
                </div>
              );
            },
          });
      }
      const searchBtn = () => {
        setLoader(true);
        let startDate = findByDate.dateStart, endDate = findByDate.dateEnd , warehouseFrom = findByDate.warehouseFrom , warehouseTo = findByDate.warehouseTo;
        

        let strStartDate = helper().formatDate(startDate,'MM-dd-yyyy');
        let strEndDate = helper().formatDate(endDate,'MM-dd-yyyy') + " 23:59:59"
        
        if(!findByDate.dateStartDisabled) strStartDate = `01-01-${new Date().getFullYear()}`;
        if(!findByDate.dateEndDisabled) strEndDate = "2222-01-01";


        var params = `&dateStart=${strStartDate}&dateEnd=${strEndDate}&warehouseFromId=${findByDate.warehouseFrom}&warehouseToId=${findByDate.warehouseTo}`;
        apiService().query('getWarehouseTransfers', params).then((response) => {
            if(response.success){               
              var count = 1;
              var data = response.data;
              data.forEach(e=> {
                e.countNumber = count; count++,
                e.date = helper().formatDate(e.date),
                e.createdAt = helper().formatDate(e.createdAt),
                e.changedAt = e.changedAt ? helper().formatDate(e.createdAt) :'' ,
                e.totalSum = e.totalSum.toFixed(2)
              });
              setLoader(false)
              setDataShow(response.data?.length > 0 ? true : false);
              setFind(data);
              setItem(data);
            }else {
              console.log("error" , response.errorMessage)
            }
        })
    };
      
      const handleDelete = (id)=> {
        apiService()
        .delete("WarehouseTransfers", "id=" + id)
        .then((response) => {
          if (response.success) {
            GetWarehouseTransers();
          } else {
            alert("Error:" + response.errorMessage);
          }
        });
      }
    
      return (
        <div className="container-fluid px-2 mt-3">
          <Search new="#/features/warehouse-transfer/0" change={(e) => findChange(e)} excel={() => exportToExcel()} back="#/"/>
          <ExcelExport data={find.length ? find : []} headers={headers} type={'other'} ref={excelExportRef} hideContent={true} />
          <div className="card border-left-primary shadow h-100 mt-1">
            <div className="card-body pt-3 pb-1">
              <div className="row">
                <div className="col-12">
                  <div className="row no-gutters align-items-center">
                    <div className="col row">
                      <div className="col-lg-3 mb-3 mb-md-0 pe-0 d-flex align-items-center">
                        {/* <label className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                              Data e fillimit
                            </label> */}
                          <div style={{width: '5%'}}>
                            <input type="checkbox" name="includeStartDate" checked={findByDate.dateStartDisabled} onChange={(e) => setFindByDate({...findByDate, dateStartDisabled: e.target.checked})} ></input>
                          </div>
                          <div style={{width: '95%'}}>
                            <MDBInput
                            name="dateStart"
                            disabled={!findByDate.dateStartDisabled}
                            className="form-control-sm active"
                            onChange={(e) =>  setFindByDate({...findByDate , dateStart: new Date(e.target.value)})}
                            type="date"
                            label={helper().translate("START_DATE")}
                            value={helper().isValidDate(findByDate.dateStart)  ?  findByDate.dateStart.toISOString().substring(0,10): ''}
                            />
                          </div>
                      </div>
                      <div className="col-lg-3 mb-3 mb-md-0 pe-0 d-flex align-items-center">
                        {/* <label className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                              Data e Mbarimit
                            </label> */}
                        <div style={{width: '5%'}}>
                            <input type="checkbox" name="includeStartDate" checked={findByDate.dateEndDisabled}  onChange={(e) => setFindByDate({...findByDate, dateEndDisabled: e.target.checked})} ></input>
                        </div>
                        <div style={{width: '95%'}}>
                          <MDBInput
                            name="dateEnd"
                            disabled={!findByDate.dateEndDisabled}
                            className="form-control-sm active"
                            onChange={(e) =>  setFindByDate({...findByDate , dateEnd: new Date(e.target.value)})}
                            type="date"
                            label={helper().translate("END_DATE")}
                            value={helper().isValidDate(findByDate.dateEnd)  ?  findByDate.dateEnd.toISOString().substring(0,10): ''}
                          />
                        </div>
                      </div>
                      <div style={{paddingTop: "2px"}} className="col-lg-2 mb-3 mb-md-0 pe-0 ">
                        {/* <label className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                          Depo
                          </label> */}
                        <Select
                          name="warehouseFrom"
                          placeholder={helper().translate("FROM_WAREHOUSE")}
                          styles={selectStyles}
                          className={findByDate.warehouseFromVal == true ? " is-invalid-s ": '' }
                          options={warehouse}
                          onChange={(e, obj) => selectChange(e, obj)}
                          value={warehouse?.find(b => b.value == findByDate.warehouseFrom)}
                        />
                      </div>
                      <div style={{paddingTop: "2px"}} className="col-lg-2 mb-3 mb-md-0 pe-0 ">
                        {/* <label className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                          Depo
                          </label> */}
                        <Select
                          name="warehouseTo"
                          placeholder={helper().translate("TO_WAREHOUSE")}
                          styles={selectStyles}
                          className={findByDate.warehouseToVal == true ? " is-invalid-s ": '' }
                          options={warehouse}
                          onChange={(e, obj) => selectChange(e, obj)}
                          value={warehouse?.find(b => b.value == findByDate.warehouseTo)}
                        />
                      </div>
                      <div className="col-md-2 col-12 d-flex align-items-center justify-content-center  justify-content-lg-end">
                        <div>
                          <a className="searchbutton" onClick={() => searchBtn()}>
                            <i className="fas fa-search fa-2x text-dark"  />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card shadow mt-1">
        <div className="card-header py-2"><h6 className="m-0 font-weight-bold text-primary text-center">{helper().translate("WAREHOUSE_TRANSFERS")}</h6></div>
         {loader && <LoaderComponent /> }
          {!loader ? dataShow ? <div className="card-body table-responsive" style={{minHeight: "500px"}}>
          {showColMenu ?   <div style={{width: 'auto' , height: 'auto' }} className = "position-relative bg-primary " >
                <div style={{position: "absolute" , top: `${document.getElementById('WarehouseTransfer').offsetHeight}px` , left: '20px' , zIndex: '222' }} className="bg-light border border-5 border-dark rounded-2  ">
                <table  className="table table-hover text-left my-1 ">
                  <tbody className="">
                    {newColumns.map((i, key) => (
                      <tr key={key} className="align-middle">
                        <td className="border-0 py-1 px-4 align-middle "><input onChange={() => checkRowIfVisible(i)} checked={userColumns.find(f => f == i) ? true : false}  type="checkbox" className=" bigCheckBox align-middle me-2"></input><span className="fw-bold">{helper().translate(i) }</span> </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                </div>
              </div> : ""}
            <table className="table table-hover text-center ">
              <thead>
                <tr id ="WarehouseTransfer">
                  <th className="align-middle ">{helper().translate("NR")} <i className="fas fa-ellipsis-v align-right mx-2  mt-1 cursor-pointer"  onClick={() => filterRowMenu()} ></i></th>
                  <th className="align-middle" >{helper().translate("EDIT")}</th>
                  { userColumns?.find( f => f == "DOCUMENT_NR") ? <th className="align-middle text-truncate">{helper().translate("DOCUMENT_NR")} <i className="fas fa-eye align-right ms-1  mt-1 cursor-pointer " onClick={() => filterRowOnEyeClick('DOCUMENT_NR')}></i></th> : "" }  
                  { userColumns?.find( f => f == "DATE") ? <th className="align-middle text-truncate">{helper().translate("DATE")} <i className="fas fa-eye align-right ms-1  mt-1 cursor-pointer " onClick={() => filterRowOnEyeClick('DATE')}></i></th> : "" }  
                  { userColumns?.find( f => f == "FROM_WAREHOUSE") ? <th className="align-middle text-truncate">{helper().translate("FROM_WAREHOUSE")} <i className="fas fa-eye align-right ms-1  mt-1 cursor-pointer " onClick={() => filterRowOnEyeClick('FROM_WAREHOUSE')}></i></th> : "" }  
                  { userColumns?.find( f => f == "TO_WAREHOUSE") ? <th className="align-middle text-truncate">{helper().translate("TO_WAREHOUSE")} <i className="fas fa-eye align-right ms-1  mt-1 cursor-pointer " onClick={() => filterRowOnEyeClick('TO_WAREHOUSE')}></i></th> : "" }  
                  { userColumns?.find( f => f == "TOTAL_SUM") ? <th className="align-middle text-truncate">{helper().translate("TOTAL_SUM")} <i className="fas fa-eye align-right ms-1  mt-1 cursor-pointer " onClick={() => filterRowOnEyeClick('TOTAL_SUM')}></i></th> : "" } 

                  { userColumns?.find( f => f == "COMMENT") ? <th className="align-middle text-truncate">{helper().translate("COMMENT")} <i className="fas fa-eye align-right ms-1  mt-1 cursor-pointer " onClick={() => filterRowOnEyeClick('COMMENT')}></i></th> : "" } 

                  { userColumns?.find( f => f == "REGISTRATION_DATE") ? <th className="align-middle text-truncate">{helper().translate("REGISTRATION_DATE")} <i className="fas fa-eye align-right ms-1  mt-1 cursor-pointer " onClick={() => filterRowOnEyeClick('REGISTRATION_DATE')}></i></th> : "" } 
                  { userColumns?.find( f => f == "REGISTERED_BY") ? <th className="align-middle text-truncate">{helper().translate("REGISTERED_BY")} <i className="fas fa-eye align-right ms-1  mt-1 cursor-pointer " onClick={() => filterRowOnEyeClick('REGISTERED_BY')}></i></th> : "" } 
                  { userColumns?.find( f => f == "DATE_OF_MODIFICATION") ? <th className="align-middle text-truncate">{helper().translate("DATE_OF_MODIFICATION")} <i className="fas fa-eye align-right ms-1  mt-1 cursor-pointer " onClick={() => filterRowOnEyeClick('DATE_OF_MODIFICATION')}></i></th> : "" } 

                  { userColumns?.find( f => f == "MODIFIED_BY") ? <th className="align-middle text-truncate">{helper().translate("MODIFIED_BY")} <i className="fas fa-eye align-right ms-1  mt-1 cursor-pointer " onClick={() => filterRowOnEyeClick('MODIFIED_BY')}></i></th> : "" } 
                  <th className="align-middle">{helper().translate("PRINT")}</th>
                  <th className="buttons">{helper().translate("DELETE")}</th>
                </tr>
              </thead>
              <tbody>
                {find.map((item, key) => (
                  <tr key={key}>
                    <td style={{width: "50px"}}>{item.countNumber}</td>
                    <td style={{width: "50px"}}>
                      <a className={`${userPermission?.find((f)=> f.moduleName === "WAREHOUSE_TRANSFER")?.allowEdit === 1 ? "" : "pe-none"}`} href={"#/features/warehouse-transfer/"+item.id}>
                      <button
                      disabled={userPermission?.find((f)=> f.moduleName === "WAREHOUSE_TRANSFER")?.allowEdit === 1 ? false : true}
                        className="btn-edit">
                    <span className="icon text-white-40"><i className="fas fa-edit"></i></span>
                    </button>
                      </a>
                    </td>
                    {userColumns?.find( f => f == "DOCUMENT_NR")? <td>  { item.id}</td> : "" } 
                    {userColumns?.find( f => f == "DATE")? <td>  {item.date }</td> : "" } 
                    {userColumns?.find( f => f == "FROM_WAREHOUSE")? <td>  {item.fromWarehouse}</td> : "" } 
                    {userColumns?.find( f => f == "TO_WAREHOUSE")? <td>  {item.toWarehouse}</td> : "" } 
                    {userColumns?.find( f => f == "TOTAL_SUM")? <td className="text-right pe-2 ">  { item.totalSum}</td> : "" } 
                    {userColumns?.find( f => f == "COMMENT")? <td >  { item.comment }</td> : "" } 
                    {userColumns?.find( f => f == "REGISTRATION_DATE")? <td >  { item.createdAt}</td> : "" } 
                    {userColumns?.find( f => f == "REGISTERED_BY")? <td >  { item?.createdBy }</td> : "" } 
                    {userColumns?.find( f => f == "DATE_OF_MODIFICATION")? <td >  { item.changedAt }</td> : "" } 
                    {userColumns?.find( f => f == "MODIFIED_BY")? <td className="text-left ps-4 " >  { item.changedBy}</td> : "" } 
                    <td>  
                        <button className="btn-print" onClick={() => printRow(item)}><span className="icon text-white-40"><i className="fas fa-print"></i></span> </button> 
                    </td>
                    <td>
                      <a className={`${userPermission?.find((f)=> f.moduleName === "INTERNAL_MOVEMENT")?.allowDelete === 1 ? "" : "pe-none is-disabled"}`} onClick={() => alertnOnDelete(item.id)}>
                        <button
                         className="btn-delete">
                          <span className="icon text-white-40">
                            <i className="fas fa-trash"></i>
                          </span>
                        </button>
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          : 
          <div style={{height: '300px'}} className=" d-flex align-items-center justify-content-center  "><div><p>{helper().translate("NO_DATA")}</p></div></div>: ""
          }
        </div>
        </div>
      );
}
export default WarehouseTransfers;