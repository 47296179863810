import globals from "../Services/globals";
class PurchasesDetails {
    constructor(obj){
        var user = globals().getLogedInUser().data
        
        if(obj){ 
            this.id = obj.id;
            this.sId = obj.sId;
            this.purchaseId = obj.purchaseId;
            this.no = obj.no
            this.itemId = obj.itemId
            this.projectId = obj.projectId
            this.invoiceId = obj.invoiceId
            this.quantity = parseFloat(obj.quantity)
            this.price = parseFloat(obj.price)
            this.discount = parseFloat(obj.discount)
            this.markup = parseFloat(obj.markup)
            this.duty = parseFloat(obj.duty)
            this.transport = parseFloat(obj.transport)
            this.overvalue = parseFloat(obj?.overvalue)
            this.excise = parseFloat(obj.excise)
            this.purchasePrice = parseFloat(obj.purchasePrice)
            this.vat = obj.vat
            this.vatSum = parseFloat(obj.vatSum)
            this.createdAt = obj.createdAt;
            this.createdBy = obj.createdBy;
            this.changedAt = obj.changedAt;
            this.changedBy = obj.changedBy;
            this.status = obj.status
         }
        else {
            this.id = 0
            this.sId = null
            this.purchaseId = ''
            this.no = 0 
            this.itemId = 0
            this.projectId = 0
            this.invoiceId = 0
            this.quantity = 0.0
            this.price =  0.0
            this.discount = 0.0
            this.markup = 0.0
            this.duty = 0.0
            this.transport = 0
            this.overvalue = 0
            this.excise = 0.0
            this.purchasePrice = 0
            this.vat = ''
            this.vatSum = ''
            this.createdAt = new Date()
            this.createdBy = null
            this.changedAt = null
            this.changedBy = null
            this.status = 0
        }
    }
}

export default PurchasesDetails