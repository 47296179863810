import React, { useState, useEffect, useRef, isValidElement } from 'react';
import Select from "react-select";
import Switch from "react-switch";
import SaleAddPartner from '../../PageComponents/UI/Modal/SaleAddPartner';
import Search from '../../PageComponents/Search/Search_Old';
import apiService from '../../Services/apiServices';
import ModelPurchases from '../../Models/Purchases';
import ModelPurchaseDetails from '../../Models/PurchaseDetails';
import calculate from '../../Services/calculate';
import helper from '../../Services/helper';
import globals from '../../Services/globals';
import { ToastProvider, useToasts } from 'react-toast-notifications';
import LoaderComponent from '../../PageComponents/UI/Components/LoaderComponent';
import Modal from "react-bootstrap/Modal";
import Item from '../Items/Item';
import { confirmAlert } from 'react-confirm-alert';
import ImportDetails from './ImportDetails';
import ModelTransportDetails from '../../Models/TransportDetail';
import PaymentModel from '../../Models/PaymentModel';
import PaymentPurchases from './PaymentPurchases';
import Purchases from './Purchases';

const ImportPurchase = (params) => {
    const { addToast } = useToasts();
    const selectRef = useRef(null);
    const logedInUser = globals().getLogedInUser();
    const [user, setUser] = useState();
    const [userSettings, setUserSettings] = useState();
    const [loader, setLoader] = useState(true)
    const [partners, setPartners] = useState()
    const [purchase, setPurchase] = useState(new ModelPurchases())

    const [purchaseTotals, setPurchaseTotals] = useState(new ModelPurchases())

    const [purchaseTypes, setPurchaseTypes] = useState()
    const [payMethods, setPayMethods] = useState()
    const [warehouses, setWarehouses] = useState()
    const [units, setUnits] = useState()
    const [items, setItems] = useState()
    const [config, setConfig] = useState()
    const [errors, setErrors] = useState({})
    const [showModal, setShowModal] = useState(false)
    const [vatLevels, setVatLevels] = useState()
    const [projects, setProjects] = useState()
    const [isImport, setIsImport] = useState(true)


    const [onTransportDetails, setOnTransportDetails] = useState(true)


    const [transportDetails, setTransportDetails] = useState()
    const [payments, setPayments] = useState()
    const status = { added: 1, deleted: -1, changed: 2, unchanged: 0 };
    const [showPayments, setShowPayments] = useState(false)
    const localPurchases = [1, 2, 3, 7];
    const otherPurchases = [4, 5, 6]
    const [pType, setpType] = useState()
    const formName = 'PurchaseDetails';
    const [showedHeaders, setShowedHeaders] = useState();
    const [showHiddeBox, setShowHiddeBox] = useState(false);
    const [userColumns, setUserColumns] = useState([])
    const [cashBox, setCashBox] = useState()
    const [headers, setHeaders] = useState([
        { key: 'no' },
        { key: 'item' },
        { key: 'project' },
        { key: 'unit' },
        { key: 'quantity' },
        { key: 'price' },
        { key: 'price_with_vat' },
        { key: 'discount' },
        { key: 'price_with_discount' },
        { key: 'markup' },
        { key: 'sale_price' },
        { key: 'sale_price_with_vat' },
        { key: 'total' },
        { key: 'transport' },
        { key: 'transport_pc' },
        { key: 'overvalue' },
        { key: 'overvalue_pc' },
        { key: 'duty_value' },
        { key: 'duty_pc' },
        { key: 'excise' },
        { key: 'excise_pc' },
        { key: 'purchase_price' },
        { key: 'vat' },
        { key: 'vat_value' },
        { key: 'total_with_vat' },
        { key: 'total_with_vat_and_overvalue' }
    ])
    const [disableSaveButton, setDisableSaveButton] = useState(true)


    const purchaseObj = {
        id: 0, sId: null, purchaseId: '', no: 1, itemId: 0, projectId: 0, invoiceId: 0, quantity: purchase.purchaseTypeId !== 5 ? 1 : -1, price: 0, priceWithVat: 0, discount: 0.0, markup: 0, duty: 0.0, dutyPercent: 0, transport: 0.0, overvalue: 0.0, overvaluePercent: 0, excise: 0.0, excisePercent: 0, purchasePrice: 0, vat: '', vatSum: 0.0, createdAt: '', createdBy: '', changedAt: '', changedBy: '', status: 0, discountPrice: 0.0, salePrice: 0.0, salePriceWithVat: 0.0, editMode: false, subTotal: 0.0, total: 0.0, transportPercent: 0, dudNo: 0, dudDate: null, totalWithTransportAndOvervalueAndDutyExcise: 0.0
    }

    const itemsByPurchaseType = {
        1: 'getNoexpenseItems', 2: 'getInventoryItems', 3: 'getExpenseItems'
    }

    const [purchaseDetails, setPurchaseDetails] = useState([purchaseObj])

    const tables = [
        { name: 'units', method: setUnits },
        { name: 'purchaseType', method: setPurchaseTypes },
        { name: 'paymentMethod', method: setPayMethods },
        { name: 'warehouses', method: setWarehouses },
        { name: 'vatLevel', method: setVatLevels },
        { name: 'projects', method: setProjects }
    ];

    const modalShow = () => {
        setShowModal(true)
    }

    const modalClose = () => {
        setShowModal(false)
    }

    const styles = {
        container: provided => ({
            ...provided,
            Width: '100%'
        }),
        control: (provided, state) => ({
            ...provided,
            background: '#fff',
            borderColor: '#9e9e9e',
            minHeight: '28.59px',
            height: '28.59px',
            boxShadow: state.isFocused ? null : null,
        }),
        menuPortal: base => ({ ...base, zIndex: 9999 }),
        valueContainer: (provided, state) => ({
            ...provided,
            height: '28.59px',
            padding: '0 6px'
        }),

        input: (provided, state) => ({
            ...provided,
            margin: '0px',
        }),
        indicatorSeparator: state => ({
            display: 'none',
        }),
        indicatorsContainer: (provided, state) => ({
            ...provided,
            height: '28.59px',
        }),
    };

    const getUser = async () => {
        apiService().query('user', 'userName=' + logedInUser.data.username)
            .then((response) => {
                if (response.success) {
                    let u = response.data[0];
                    getCashbox(u.cashBoxId)
                    setUser(u)
                }
                else alert('errr')
            })
    }

    const getCashbox = async (id) => {
        apiService().query('cashBox', 'id=' + id)
            .then((response) => {
                if (response.success) {
                    let cb = response.data[0];
                    setCashBox(cb)
                    // setPurchase({warehouseId: cb?.warehouseId})
                }
                else alert('errr')
            })
    }

    const getPartners = () => {
        apiService().get('partners')
            .then((response) => {
                if (response.success) {
                    let records = response.data;
                    records = records.filter(record => record?.supplier && record?.status)
                    records = records.map(record => ({ value: record.id, label: record.name ? record.name : record.saveAs }))
                    setPartners(records)
                }
            })
    }

    const getItems = (name) => {
        apiService().get(name ? name : 'items')
            .then((response) => {
                if (response.success) {
                    setItems(response.data)
                }
            })
    }

    const getSettings = () => {
        globals().getSettings().then((response) => {
            if (response) {
                setConfig(response)
                setLoader(false)
            }
        })
    }

    const getDefaults = (name, method) => {
        apiService().get(name)
            .then((response) => {
                if (response.success) {
                    let records = response.data
                    if (name === 'purchaseType') {
                        records = records.map(record => ({ value: record.id, label: name === 'vatLevel' ? record.value : helper().translate(`${record.name}`), warehouseId: name === 'projects' ? record.warehouseId : 0 }))
                    }
                    else if (name === 'units') {
                        records = response.data.map(record => ({ value: record.typeId, label: record.name }));
                    }
                    else {
                        records = records.map(record => ({ value: record.id, label: name === 'vatLevel' ? record.value : record.name, warehouseId: name === 'projects' ? record.warehouseId : 0 }))
                    }

                    method(records)
                }
            })
    }

    const getPurchase = (id) => {
        if (id) {
            apiService().query('getPurchase', `id=${id}`)
                .then((response) => {
                    if (response.success && response.data.length) {
                        let purchase = response.data[0]
                        purchase.date = new Date(purchase.date);
                        purchase.dudDate = new Date(purchase.dudDate);
                        getItems(itemsByPurchaseType[purchase.purchaseTypeId]);
                        setPurchase(purchase)
                        getPurchaseDetails(purchase)
                        getTransportDetails(purchase)
                        getPayments(purchase)
                        !purchase.import ? setpType(purchase.purchaseTypeId) : setpType('im_' + purchase.purchaseTypeId)
                        //setLoader(false)
                    }
                    else {
                        let obj = new ModelPurchases()
                        obj.withVat = config?.withVat
                        obj.purchaseTypeId = parseInt(params.location.search.replace('?type=', ''))
                        //obj.warehouseId = warehouses?.filter(w => w.value === user?.warehouseId).length > 0 ? user?.warehouseId : 0; //user?.warehouseId
                        obj.warehouseId = cashBox?.warehouseId;
                        obj.date = new Date(); //(new Date()).toISOString().substring(0, 10)
                        obj.dudDate = new Date();
                        obj.import = true
                        setPurchase(obj)
                        setPurchaseDetails([purchaseObj]);
                    }
                })
        }
        else {
            let obj = new ModelPurchases()
            obj.withVat = config?.withVat
            obj.purchaseTypeId = parseInt(params.location.search.replace('?type=', ''))
            //obj.warehouseId = warehouses?.filter(w => w.value === user?.warehouseId).length > 0 ? user?.warehouseId : 0; //user?.warehouseId
            obj.warehouseId = cashBox?.warehouseId;
            obj.date = new Date() //(new Date()).toISOString().substring(0, 10)
            obj.dudDate = new Date();
            obj.import = true
            setPurchase(obj)
            setPurchaseDetails([purchaseObj])
            //setLoader(false)
        }
    }

    const getTransportDetails = (purchase) => {
        apiService().query('getTransportDetails', `purchaseId=${purchase.id}`)
            .then((response) => {
                if (response.success) {
                    let records = response.data
                    setTransportDetails(records)
                }
            })
    }

    const getPurchaseDetails = (purchase) => {
        apiService().query('getPurchaseDetails', `purchaseId=${purchase.id}`)
            .then((response) => {
                if (response.success && response.data && response.data.length) {
                    let records = response.data
                    records.sort(function (a, b) {
                        return a.no - b.no;
                    });
                    records.forEach(record => {
                        let item = items?.find(i => i.id === record.itemId)
                        record['unitId'] = item?.unitId;
                        calculate().calculateImportPercents(record);
                    });

                    calculateTotals(records, purchase)
                    setPurchaseDetails(records)
                }
                else {
                    setPurchaseDetails([purchaseObj]);
                }
            })
    }

    const getPayments = (obj) => {
        apiService().query('getPaymentPurchases', `purchaseId=${obj.id}`)
            .then((response) => {
                if (response.success) {
                    //purchase.pay = true;
                    //setPurchase({...purchase, pay: true})
                    //setShowPayments(true)
                    let records = response.data
                    setPayments(records)
                }
            })
    }

    const getUserColumns = () => {
        // apiService().query('userColumns', `username=${logedInUser.data.username}&formname=${formName}`)
        //     .then(response => {
        //         if(response.success && response.data.length > 0) {
        //             let results = response.data[0].hColumns.split(',')
        //             setUserColumns(results)
        //             setColumns(results)
        //         }
        //         else {
        //             setColumns()
        //         }
        //     })
    }

    const setColumns = (userColumns) => {
        var hdrs = []
        headers.forEach(h => {
            if (userColumns) {
                userColumns.includes(h?.key) ? h['showHidde'] = true : h['showHidde'] = false
            }
            else {
                h ? h['showHidde'] = true : 0;
            }
            hdrs.push(h)
        })
        setShowedHeaders(hdrs)
    }

    const removeColumn = (key) => {
        let arr = []
        showedHeaders.forEach(h => {
            if (h.key === key) h.showHidde = !h.showHidde
            arr.push(h)
        })
        // this.renderHeader(arr)
        setShowedHeaders(arr)
        savePrepare(arr)
    }

    const savePrepare = (hs) => {
        if (formName) {
            let hc = []
            let headers = hs ? hs : showedHeaders
            headers?.forEach(h => {
                h.showHidde ? hc.push(h.key) : ''
            })
            let obj = { userName: logedInUser.data.username, formName: formName, hColumns: hc.toString() }
            saveUserColumns(obj, hc.to)
        }
    }

    const saveUserColumns = (obj) => {
        if (!userColumns.length && !allAreTrue(showedHeaders)) {
            apiService().post('userColumns', obj)
                .then(response => {
                    getUserColumns()
                })
        }
        else if (userColumns.length && obj.hColumns !== userColumns.toString()) {
            apiService().put('userColumns', obj)
                .then(response => {
                    if (response.success) {
                        //getUserColumns()
                    }
                })
        }
    }

    const allAreTrue = (arr) => {
        return arr.every(element => element.showHidde === true);
    }

    const checkHeader = (key) => {
        var test = showedHeaders;
        if (test) {
            //console.log('nalu');
        }
        var find = showedHeaders?.find(s => s.key === key);
        if (find) {
            return find['showHidde'];
        }
        else {
            //console.log('nalu qetu');
            return true;
        }
    }

    const renderColumns = () => {
        var headersMap = showedHeaders ? showedHeaders : headers;
        return headersMap.map((header, index) => {
            return <div className='cursor-pointer' onClick={() => removeColumn(header.key)}><input className='me-2' type="checkbox" checked={header.showHidde ? 'checked' : ''} name={header.key}></input><label>{header.key}</label></div>
        });
    }

    const setUnit = (unitId) => {
        return units?.find(u => u.value === unitId)?.label
    }

    const setProject = (projectId) => {
        return projects?.find(p => p.value === projectId)?.label
    }

    const calculateTotals = (details, _purchase) => {
        //return;
        let purchaseObj = _purchase ? { ..._purchase } : { ...purchase }
        let _details = details.filter((row) => row.status !== status.deleted);
        let vatSum = 0.0, subTotal = 0.0, totalWithTransportAndOvervalueAndDutyExcise = 0.0;
        let duty = 0.0, transport = 0.0, overvalue = 0.0, excise = 0.0;
        for (var i = 0; i < _details.length; i++) {
            vatSum += parseFloat(_details[i].vatSum);
            subTotal += parseFloat(_details[i].subTotal);
            duty += parseFloat(_details[i].duty);
            transport += parseFloat(_details[i].transport);
            overvalue += parseFloat(_details[i].overvalue);
            excise += parseFloat(_details[i].excise);
            totalWithTransportAndOvervalueAndDutyExcise += parseFloat(_details[i].totalWithTransportAndOvervalueAndDutyExcise);
        }

        //test

        purchaseObj.transport = transport;
        purchaseObj.duty = duty;
        purchaseObj.excise = excise;
        purchaseObj.overvalue = overvalue;
        purchaseObj.vatSum = vatSum;
        purchaseObj.subTotal = subTotal;
        purchaseObj.totalWithTransportAndOvervalueAndDutyExcise = parseFloat(totalWithTransportAndOvervalueAndDutyExcise);
        //purchaseObj.totalWithTransportAndOvervalueAndDutyExcise = totalWithTransportAndOvervalueAndDutyExcise;

        // purchaseObj.vatSum = _details.reduce((accumulator, current) => parseFloat(accumulator) + parseFloat(current.vatSum), 0)
        // purchaseObj.subTotal = _details.reduce((accumulator, current) => parseFloat(accumulator) + parseFloat(current.subTotal), 0)
        // // purchaseObj.totalSum = _details.reduce((accumulator, current) => parseFloat(accumulator) + current.total, 0)
        // purchaseObj.totalSum = !isImport ? _details.reduce((accumulator, current) => parseFloat(accumulator) + parseFloat(current.subTotal), 0) : _details.reduce((accumulator, current) => parseFloat(accumulator) + (parseFloat(current.subTotal) - parseFloat(current.transport)), 0)
        // purchaseObj.totalWithOvervalue = _details.reduce((accumulator, current) => parseFloat(accumulator) + current.totalWithOvervalue, 0)
        // purchaseObj.transport = _details.reduce((accumulator, current) => parseFloat(accumulator) + parseFloat(current.transport), 0)
        // purchaseObj.overvalue = _details.reduce((accumulator, current) => parseFloat(accumulator) + parseFloat(current.overvalue), 0)
        // purchaseObj.duty = _details.reduce((accumulator, current) => parseFloat(accumulator) + parseFloat(current.duty), 0)
        // purchaseObj.excise = _details.reduce((accumulator, current) => parseFloat(accumulator) + parseFloat(current.excise), 0)
        // purchaseObj.amount = parseFloat(purchaseObj.totalSum) + parseFloat(purchaseObj.vatSum)

        //setPurchase(purchaseObj)
        setPurchaseTotals(purchaseObj);
        
    }

    const onRowClick = (no) => {
        let rowItem = [...purchaseDetails]
        rowItem.forEach(i => i.editMode = false);
        rowItem[no - 1].editMode = !rowItem[no - 1].editMode;
        setPurchaseDetails(rowItem);
    }

    const addRow = () => {
        let newItem = purchaseObj;
        newItem.editMode = true;
        newItem.no = Math.max(...purchaseDetails.map(o => o.no), 0) + 1
        //isImport ? newItem.transportPercent = parseFloat(purchase.transport) * 100 / (parseFloat(purchase.totalSum) ) : 0;
        //newItem.transportPercent = parseFloat(purchase.transport) * 100 / (parseFloat(purchase.totalSum))
        const newState = [...purchaseDetails, newItem];
        newState.forEach(item => {
            item.no !== newItem.no ? item.editMode = false : '';
        })
        setPurchaseDetails(newState);
        setTimeout(
            function () {
                selectRef.current.focus()
            }, 500);
    }

    const getFocus = (no) => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        // document.getElementById("item_"+no)?.getElementsByTagName("input")[0]?.focus();
        let elem = document.getElementById("item_" + no)?.getElementsByTagName("input")[0];
        elem.style.color = 'red';
    }

    const deleteRow = (rowId) => {
        let arrayCopy = [...purchaseDetails]
        let item = arrayCopy.find(i => i.no === rowId);
        item.status = status.deleted;
        //arrayCopy = arrayCopy.filter((row) => row.no !== rowId);
        let count = 1;
        arrayCopy.forEach(i => { i.no = count; count++ });
        //setPurchaseDetails([])
        const newArr = [arrayCopy]
        setPurchaseDetails(arrayCopy)
        calculateTotals(arrayCopy)
    }

    const reOrderItems = (items) => {
        let count = 1;
        items.forEach(i => {
            if (i.status !== -1) {
                i.no = count;
                count++
            }
            else {
                i.no = 0;
            }
        });

        return items;
    }

    const setEditMode = () => {
        let rowItem = [...purchaseDetails]
        rowItem.forEach(i => i.editMode = false);
        setPurchaseDetails(rowItem);
    }

    const onFieldChange = (index, itemId) => (e, a) => {
        let newArr = [...purchaseDetails];
        let purchDetails = newArr[index - 1];
        purchDetails.status = status.changed;
        if (a) {
            let prop = a.name
            newArr[index - 1][prop] = e.value;
            if (prop !== 'projectId') {
                let itemId1 = (prop === 'itemId') ? e.value : itemId
                let item = items?.find(i => i.id === itemId1)
                if (prop === 'vat') item.vat = e.value
                purchase.withVat ? purchDetails.vat = item.vat : purchDetails.vat = 0;
                purchDetails.unitId = item.unitId;
                calculate().calculateSalePrice(purchDetails, purchDetails.salePrice);
                calculate().onColumnChangeImport(prop, purchDetails, purchase);
            }
            setPurchaseDetails(newArr)
            calculateTotals(newArr);
            errors?.['pd_item_' + index] ? setErrors({ ...errors, ['pd_item_' + index]: "" }) : ''
        }
        else {
            let prop = e.target.name
            let value = e.target.value
            if (prop === 'quantity' && purchase.purchaseTypeId === 5) {
                if (value <= -1 || value === "")
                    newArr[index - 1][prop] = e.target.value;
            }
            else {
                newArr[index - 1][prop] = e.target.value;
            }
            // newArr[index - 1][prop] = e.target.value;
            errors?.['pd_price_' + index] ? setErrors({ ...errors, ['pd_price_' + index]: "" }) : ''

            //calculate().pourchaseCalcualte(newArr[index - 1], isImport, prop)
            calculate().onColumnChangeImport(prop, newArr[index - 1], purchase);
            calculateTotals(newArr);

            //setPurchaseDetails(newArr); 

        }
    }

    const changeField = (e) => {
        let pur = { ...purchase };
        pur[e.target.name] = new Date(e.target.value);
        console.log(e)
        setPurchase(pur)
    }

    const newPurchase = () => {

        if (onTransportDetails) {

            let type = parseInt(params.location.search.replace('?type=', ''));

            purchaseDetails?.some(function (el) {
                el.editMode === true ?
                    confirmAlert({
                        customUI: ({ onClose }) => {
                            return (
                                <div className="custom-ui" >
                                    <h1>A jeni te sigurt?</h1>
                                    <p>Dëshironi të hapni blerje të re pa i ruajtur ndryshimet paraprake?</p>
                                    <button
                                        onClick={() => {
                                            // !type || type === purchase.purchaseTypeId ?  getPurchase(0) : window.location.href = `#/purchase?type=${purchase.purchaseTypeId}`;
                                            continueToNew()
                                            onClose();
                                        }}
                                    >
                                        Vazhdo!
                                    </button>
                                    <button onClick={onClose}>Jo</button>
                                </div>
                            );
                        }
                    })
                    :
                    continueToNew()
            });
        }
        else {
            responseMessage('error', 'Se pari ruani detajet e transportit!')
        }

    }

    const continueToNew = () => {
        location.href = `#/import?type=${purchase.purchaseTypeId}`;
        setPayments([])
        setPurchase({ ...purchase, partnerId: 0 })
    }

    const escFunction = (event) => {
        if (event.keyCode === 27) {
            modalClose()
        }
    }

    const onCalculatorChange = (transportPercent) => {
        let pdCopy = [...purchaseDetails];
        pdCopy?.forEach((obj, inx) => {
            obj.transportPercent = parseFloat(transportPercent) ? parseFloat(transportPercent) : 0
            calculate().importCalculate(obj);

            if (inx + 1 === pdCopy.length) {
                setPurchaseDetails(pdCopy)
            }

        });
        calculateTotals(pdCopy);
    }

    const responseMessage = (appearance, message) => {
        appearance === 'error' ? toggleSaveButtonState(false) : '';
        addToast(message, { appearance: appearance, autoDismiss: true, });
    }

    const afterNewItem = (c) => {
        if (c) {
            modalClose();
            getItems(itemsByPurchaseType[purchase.purchaseTypeId])
        }
    }

    const toFixed2 = (name, value) => {
        let parent = document.getElementById("purchaseDetails");
        let elem = parent?.querySelector('[name='+name+']');

        if (elem === document.activeElement) {
            return value;
        } else {
            return parseFloat(value).toFixed(2)
        }
    }

    const handleBlur = (e) => {
        let elem = document.getElementsByName(e.target.name);
        elem[0].value = parseFloat(elem[0].value).toFixed(2);
    }

    const usePrevious = (value) => {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    // TO DO 
    const prevPurchase = usePrevious(purchase);
    const prevPurchaseDetails1 = usePrevious(purchaseDetails);
    const prevTransportDetails = usePrevious(transportDetails);

    const compareState = (prevState, state) => {
        //if(JSON.stringify(prevState) !== JSON.stringify(state)) {
        if (prevState !== state) {
            return true
        }
        else {
            return false
        }
    }

    // useEffect(() => {
    //     // if(JSON.stringify(purchaseDetails) !== JSON.stringify(prevPurchaseDetails1)){
    //     //     console.log('pooooo')
    //     // }
    //     // else {
    //     //     console.log('jooooo')
    //     // }
    //     console.log('1: ', purchaseDetails)
    // }, [purchaseDetails])

    // const save = () => {
    //     const pdWithoutEditMode = purchaseDetails//.map(({ editMode, ...item }) => item);
    //     const prevPdWithoutEditMode = prevPurchaseDetails//.map(({ editMode, ...item }) => item);

    //     //console.log('2: ', JSON.stringify(pdWithoutEditMode), JSON.stringify(prevPdWithoutEditMode))

    //     if(JSON.stringify(purchaseDetails) !== JSON.stringify(prevPurchaseDetails)){
    //         console.log('pooooo')
    //     }
    //     else {
    //         console.log('jooooo')
    //     }
    //     console.log('1: ', purchaseDetails, prevPurchaseDetails)
    //     let purchaseChange = compareState(prevPurchase, purchase),
    //         purchaseDetailsChange = compareState(prevPurchaseDetails, purchaseDetails),
    //         transportDetailsChange = compareState(prevTransportDetails, transportDetails)

    //     console.log(purchaseChange, purchaseDetailsChange, transportDetailsChange)
    //     purchaseChange ? savePurchase() : !purchaseDetailsChange && !transportDetailsChange ? responseMessage('info', 'Nuk ka ndonje ndryshim!') : '';
    //     purchaseDetailsChange && !purchaseChange ? savePurchaseDetails(purchase) : 0
    //     transportDetailsChange && !purchaseChange ? saveTransportDetails(purchase) : 0
    // }

    // END TO DO 

    // const save = () => {
    //     console.log('pooooooooooooooo: ', purchaseDetails)
    // }

    const toggleSaveButtonState = (val) => {
        if (val) {
            setDisableSaveButton(val)
        }
        else {
            setTimeout(
                function () {
                    setDisableSaveButton(false)
                }, 5000);
        }

    }

    const checkInvoiceNo = () => {
        toggleSaveButtonState(true)
        if (purchase.id !== 0) {
            save();
        }
        else {
            apiService().query('checkPurchaseInvoiceNo', 'invoiceNo=' + purchase?.invoiceNo)
                .then((response) => {
                    if (response.success && response.data.length) {
                        setErrors({ ...errors, invoiceNo: 'Ky numer i fatures ekziston!' })
                        responseMessage('error', 'Ky numer i fatures ekziston!')
                    }
                    else {
                        // setErrors({...errors, invoiceNo: ''})
                        save();
                    }
                })
        }
    }

    const save = () => {

        //if(onTransportDetails) {

        let errorsArr = {}, requiredFields = ['partnerId', 'warehouseId', 'paymentMethodId', 'invoiceNo'];
        requiredFields.forEach(field => {
            field != '' ? !purchase?.[field] ? errorsArr[field] = 'Zgjidhe' : '' : 0
        })

        purchaseDetails?.forEach(detail => {
            !detail.itemId ? errorsArr['pd_item_' + detail.no] = 'Zgjidhe' : '';
            !detail.price ? errorsArr['pd_price_' + detail.no] = 'Zgjidhe' : '';
        });

        const isEmpty = !Object.values(errorsArr).some(x => x !== null && x !== '');
        setErrors(errorsArr)

        if (purchase.id !== 0) {
            if (isEmpty && purchaseDetails.length && purchaseDetails[0].itemId) {
                purchase.changedBy =  globals().getLogedInUser().data.username;
                purchase.vatSum =  purchaseTotals.vatSum;
                purchase.totalSum =  purchaseTotals.subTotal;
                apiService().put('purchases', purchase)
                    .then((response) => {
                        if (response.success) {
                            //responseMessage(response.success ? 'success' : '', 'U ruajt me sukses')
                            //setPurchase(response.data)
                            if (purchase.paymentMethodId === 1) {
                                savePayment(purchase)
                                getPurchase(purchase.id)
                            }
                            savePurchaseDetails(purchase)
                            // isImport ? saveTransportDetails(purchase) : 0
                            transportDetails.length && saveTransportDetails(purchase)
                        }
                        else {
                            console.error(response)
                            responseMessage('error', response.errorMessage ? response.errorMessage : 'Ka ndodhur nje gabim!')
                        }
                    })
            }
            else {
                toggleSaveButtonState(false);
            }
        }
        else {
            purchase.dateRegistered = new Date();
            purchase.createdBy =  globals().getLogedInUser().data.username;
            purchase.vatSum =  purchaseTotals.vatSum;
            purchase.totalSum =  purchaseTotals.subTotal;
            if (isEmpty && purchaseDetails.length && purchaseDetails[0].itemId) {
                apiService().post('purchases', purchase)
                    .then((response) => {
                        if (response.success) {
                            let resp = response.data
                            resp.date = new Date(resp.date);
                            resp.dudDate = new Date(resp.dudDate);
                            if (resp.paymentMethodId === 1) {
                                savePayment(response.data)
                            }
                            setPurchase(resp)
                            savePurchaseDetails(response.data)
                            //isImport ? saveTransportDetails(response.data) : 0
                            transportDetails ? saveTransportDetails(response.data) : ""
                        }
                        else {
                            responseMessage('error', response.errorMessage ? response.errorMessage : 'Ka ndodhur nje gabim!')
                        }
                    })
            }
            else {
                toggleSaveButtonState(false);
            }
        }
        // }
        // else {
        //     responseMessage('error', 'Ruani se pari detajet e transportit!')
        // }
    }

    const savePurchaseDetails = async (purchase) => {
        let objs = [], items = reOrderItems([...purchaseDetails]), count = 1;

        items ? await items.forEach(item => {
            if(purchase.withVat === false){
                item.vat = 0;
                item.vatSum = 0;
            }
            item.purchaseId = purchase.id;
            if (item.id === 0) {
                let obj = {
                    id: 0,
                    entity: new ModelPurchaseDetails(item),
                    status: status.added,
                    type: 'purchaseDetails'
                }
                obj.entity.itemId && obj.entity.price && item.no !== 0 && item.status !== -1 ? objs.push(obj) : 0
            }
            else {
                let obj = {
                    entity: new ModelPurchaseDetails(item),
                    status: item.status ? item.status : status.unchanged,
                    type: 'purchaseDetails'
                }
                if (obj.status !== status.unchanged) {
                    obj.entity.itemId && obj.entity.price ? objs.push(obj) : 0
                }
            }
        }) :
            0

        objs ? apiService().saveChanges(objs)
            .then((response) => {
                if (response.success) {
                    responseMessage(response.success ? 'success' : '', 'U ruajt me sukses!')
                    window.location.href = '#/import/' + purchase.id;
                    setEditMode()
                    //!isImport ? toggleSaveButtonState(false) : ''
                    !transportDetails.length && toggleSaveButtonState(false)
                }
                else {
                    responseMessage('error', response.errorMessage ? response.errorMessage : 'Ka ndodhur nje gabim!')
                }
            }) :
            0
    }

    const saveTransportDetails = async (purchase) => {
        let objs = [], items = transportDetails ? reOrderItems([...transportDetails]) : []

        items ? await items.forEach(item => {
            item.purchaseId = purchase.id;
            if (item.id === 0) {
                let obj = {
                    id: 0,
                    entity: new ModelTransportDetails(item),
                    status: status.added,
                    type: 'transportDetails'
                }
                obj.entity.date && obj.entity.partnerId && obj.entity.invoiceSum && item.no !== 0 && item.status !== -1 ? objs.push(obj) : 0
            }
            else {
                let obj = {
                    entity: new ModelTransportDetails(item),
                    status: item.status ? item.status : status.unchanged,
                    type: 'transportDetails'
                }
                if (obj.status !== status.unchanged) {
                    obj.entity.date && obj.entity.partnerId && obj.entity.invoiceSum ? objs.push(obj) : 0
                }
            }
        }) :
            0

        objs ? apiService().saveChanges(objs)
            .then((response) => {
                if (response.success) {
                    responseMessage(response.success ? 'success' : '', 'U ruajt me sukses!')
                    toggleSaveButtonState(false)
                }
                else {
                    responseMessage('error', response.errorMessage ? response.errorMessage : 'Ka ndodhur nje gabim!')
                }
            }) :
            0
    }

    const savePayment = (purchase) => {
        let payment = new PaymentModel();
        payment.credit = purchase.amount;
        payment.saldo = payment.debit - payment.credit;
        payment.partnerId = purchase.partnerId;
        payment.paymentType = purchase.paymentMethodId;
        payment.warehouseId = purchase.warehouseId;
        payment.cashBoxId = user.cashBoxId;
        payment.reference = purchase.reference;
        payment.date = purchase.date;
        apiService().execute('insertPaymentFromPurchase', payment, 'post')
            .then((response) => {
                if (response.success) {
                    savePaymentPurchase(response.data[0].id, payment, purchase)
                }
                else {
                    responseMessage('error', response.errorMessage ? response.errorMessage : 'Ka ndodhur nje gabim gjate regjistrimit te pageses!')
                }
            })
    }

    const savePaymentPurchase = (id, payment, purchase) => {
        let paymentPurchases = {};
        paymentPurchases.paymentId = id;
        paymentPurchases.purchaseId = purchase.id;
        paymentPurchases.amountPaid = payment.credit;
        paymentPurchases.createdAt = new Date();
        paymentPurchases.createdBy = user.name + " " + user.surname;
        apiService().post('insertPaymentPurchases', paymentPurchases)
            .then((response) => {
                if (!response.success) {
                    responseMessage('error', response.errorMessage ? response.errorMessage : 'Ka ndodhur nje gabim gjate regjistrimit te detajeve te pageses!')
                }
            })
    }

    const print = () => {
        window.open(`#/print-purchase/${purchase.id}`, 'popUpWindow', 'height=1300,width="100%",left=100,top=100,right=100,bottom=100,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes')
    }

    const getUserSettings = () => {
        globals().getUserSettings(logedInUser).then((response) => {
            setUserSettings(response)
        });
    }

    useEffect(() => {
        getUserColumns()
        document.addEventListener("keydown", escFunction, false);
        return () => {
            document.removeEventListener("keydown", escFunction, false);
        }
    }, [])

    useEffect(() => {
        let id = params.match.params.id
        let type = params.location.search.replace('?type=', '')

        getUser()
        getPartners();
        !id ? getItems(itemsByPurchaseType[type]) : getItems()

        tables.forEach((table, index) => {
            getDefaults(table.name, table.method)
            index === tables.length - 1 ? getSettings() : ""
        })

        if (params.location.pathname.includes("import")) {
            //setIsImport(true)
            let pt = 'im_' + type;
            !id ? setpType(pt) : 0
        }
        else {
            !id ? setpType(parseInt(type)) : 0
        }
        getUserSettings()
        setTimeout(
            function () {
                setDisableSaveButton(false)
            }, 5000);
    }, [])

    useEffect(() => {
        getPurchase(params.match.params.id ? params.match.params.id : 0)
    }, [loader])

    useEffect(() => {
        getPurchase(params.match.params.id ? params.match.params.id : 0)
        // if(params.location.pathname.includes("import")){
        //     setIsImport(true)
        // }
    }, [params.location])

    useEffect(() => {
        setPurchase({ ...purchase, warehouseId: cashBox?.warehouseId })
    }, [cashBox])

    useEffect(() => {
        calculateTotals(purchaseDetails, purchase)
    }, [purchase])

    return (
        <div>
            <div style={{ transition: 'all 2s' }} className="container-fluid px-2 mt-3">
                <Search back={`#/imports?type=${purchase.purchaseTypeId}`} search={false} save={checkInvoiceNo} newHandle={newPurchase} printClick={!otherPurchases.includes(pType) ? print : 0} disableSaveButton={disableSaveButton} />
                <div className="card border-left-primary shadow h-100 mt-1 pt-1 pb-3">
                    {loader && <LoaderComponent type="ThreeDots" className="text-center w-100" color="#2BAD60" height="100" width="100" />}
                    {/* <PurchaseData data={partners}></PurchaseData> */}
                    {!loader && <>

                        {/* Vetoni{ onTransportDetails && !showPayments && */}
                        {onTransportDetails && !showPayments &&
                            <div className={`form col-md-12 px-4`} >
                                <div className="row">
                                    <div className={`col-md-9 col-12`}>
                                        <div className="row">
                                            <div className={`col-md-4 col-12 pl-0`}>
                                                <div className="row align-items-center">
                                                    <div className="col">
                                                        <label className="text-xs font-weight-bold text-primary mb-0">{helper().translate('PARTNER')}</label>
                                                        <Select
                                                            name="partnerId"
                                                            placeholder={helper().translate("CHOOSE_PARTNER")}
                                                            value={partners ? partners.find(v => v.value == purchase?.partnerId) : ''}
                                                            onChange={(e) => { setPurchase({ ...purchase, partnerId: e.value }); errors?.partnerId ? setErrors({ ...errors, partnerId: '' }) : '' }}
                                                            options={partners}
                                                            styles={styles}
                                                            className={errors?.partnerId ? 'is-invalid-s' : ''}
                                                        />
                                                    </div>
                                                </div>
                                                <SaleAddPartner onClose={getPartners} props={params} />
                                            </div>
                                            <div className={` col-md-4 col-12 pl-0`}>
                                                <div className="">
                                                    <label className="text-xs font-weight-bold text-primary mb-0">{helper().translate('DUD_NO')}</label>
                                                    <input name="dudNo" value={purchase?.dudNo} className={`form-control form-control-sm ${errors?.dudNo ? 'is-invalid' : ''}`} onChange={(e) => { setPurchase({ ...purchase, dudNo: e.target.value }); errors?.purchaseTypeId ? setErrors({ ...errors, dudNo: '' }) : '' }}></input>
                                                </div>
                                            </div>

                                            <div className={`col-md-4 col-12 l-straight pl-0 d-md-flex`}>
                                                <div className="col-md-6 pl-0 pr-1">
                                                    <label className="text-xs font-weight-bold text-primary mb-0">{helper().translate('INVOICE_NO')}</label>
                                                    <input className={`form-control form-control-sm ${errors?.invoiceNo ? 'is-invalid' : ''}`} name="invoiceNo" value={purchase?.invoiceNo} onChange={(e) => { setPurchase({ ...purchase, invoiceNo: e.target.value }); errors?.purchaseTypeId ? setErrors({ ...errors, invoiceNo: '' }) : '' }}></input>
                                                </div>
                                                <div className="col-md-6 pr-0 pl-1">
                                                    <label className="text-xs font-weight-bold text-primary mb-0">{helper().translate('REFERENCE_NO')}</label>
                                                    <input className="form-control form-control-sm" name="reference" value={purchase?.reference} onChange={(e) => setPurchase({ ...purchase, reference: e.target.value })}></input>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            {localPurchases.includes(pType) &&
                                                <div className={`col-md-4 col-12 pl-0 ${purchase.paymentMethodId === 1 ? 'd-flex' : ''}`}>
                                                    {!payments?.length &&
                                                        <div className="col-3 px-0">
                                                            <label className="text-xs font-weight-bold text-primary mb-0">{helper().translate('PAYMENT')}</label>
                                                            <div className="text-light">

                                                                <Switch
                                                                    name="pay"
                                                                    onColor="#86d3ff"
                                                                    onHandleColor="#2693e6"
                                                                    handleDiameter={25}
                                                                    width={55}
                                                                    height={20}
                                                                    uncheckedIcon={<span style={{ display: 'flex', margin: '-4px 0 0 -5px' }}>{helper().translate('NO')}</span>}
                                                                    checkedIcon={<span style={{ display: 'flex', margin: '-4px 0 0 -5px' }}>{helper().translate('YES')}</span>}
                                                                    padding="7px 0px"
                                                                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                                                    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                                                    checked={purchase.pay}
                                                                    onChange={(e) => { setPurchase({ ...purchase, pay: e, paymentMethodId: e ? 1 : 3 }) }}
                                                                />
                                                            </div>
                                                        </div>
                                                    }
                                                    {!payments?.length && purchase.pay && <>
                                                        <div className="col-9 pr-0">
                                                            <label className="text-xs font-weight-bold text-primary mb-0">{helper().translate('SUM')}</label>
                                                            <input name="amount" value={purchase.amount} onChange={(e) => setPurchase({ ...purchase, amount: e.target.value })} className="form-control form-control-sm" ></input>
                                                        </div>
                                                    </>
                                                    }
                                                    {payments?.length > 0 && <div className="row mx-0 w-100">
                                                        <div className="col-6 px-0">
                                                            <label className="text-xs font-weight-bold text-primary mb-0">{helper().translate('PAID_SUM')}</label>
                                                            <br /><span>{payments?.reduce((accumulator, current) => parseFloat(accumulator) + parseFloat(current.amountPaid), 0)}</span>
                                                        </div>
                                                        <div className="col-6 px-0 mt-3">
                                                            <button className="btn btn-link" onClick={() => setShowPayments(true)}>{helper().translate('PAYMENTS')}</button>
                                                        </div>
                                                    </div>
                                                    }

                                                </div>
                                            }
                                            {!localPurchases.includes(pType) &&
                                                <div className={`col-md-4 col-12 pl-0`}>
                                                    <div className="">
                                                        <label className="text-xs font-weight-bold text-primary mb-0">{helper().translate('PAYMENT_METHOD')}
                                                        </label  >
                                                        <Select
                                                            name="paymentMethodId"
                                                            value={payMethods?.find(v => v.value == purchase?.paymentMethodId)}
                                                            onChange={(e) => { setPurchase({ ...purchase, paymentMethodId: e.value }); errors?.paymentMethodId ? setErrors({ ...errors, paymentMethodId: '' }) : '' }}
                                                            options={!localPurchases.includes(pType) ? payMethods?.filter(record => record.value != 1) : payMethods}
                                                            styles={styles}
                                                            className={errors?.paymentMethodId ? 'is-invalid-s' : ''}
                                                        />
                                                    </div>
                                                </div>
                                            }
                                            <div className={` col-md-4 col-12 pl-0`}>
                                                <div className="d-flex">
                                                    <div className="col-md-6 col-12 pl-0">
                                                        <label className="text-xs font-weight-bold text-primary mb-0">{helper().translate('DUD_DATE')}</label>
                                                        <input
                                                            name="dudDate"
                                                            className="form-control form-control-sm"
                                                            type="date"
                                                            //value={purchase?.dudDate ? helper().formatDate(purchase.dudDate, 'yyyy-MM-dd') : (new Date()).toISOString().substring(0, 10)}
                                                            value={helper().isValidDate(purchase?.dudDate) ? purchase?.dudDate.toISOString().substring(0, 10) : ""}
                                                            // onChange={(e) => setPurchase({ ...purchase, dudDate: e.target.value })}
                                                            onChange={(e) => changeField(e)}
                                                        />
                                                    </div>
                                                    <div className="col-md-6 col-12 pr-0 pl-0">
                                                        <label className="text-xs font-weight-bold text-primary mb-0">{helper().translate('INVOICE_DATE')}</label>
                                                        <input
                                                            name="date"
                                                            className="form-control form-control-sm"
                                                            type="date"
                                                            value={helper().isValidDate(purchase?.date) ? purchase?.date.toISOString().substring(0, 10) : ""}
                                                            //max="9999-06-14T00:00"
                                                            onChange={(e) => changeField(e)}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={`col-md-4 col-12 pl-0`}>
                                                <div className='row'>
                                                    <div className="col-md-4 col-12 pl-0">
                                                        <div className=" d-flex flex-column align-items-center">
                                                            <label className="text-xs font-weight-bold text-primary mt-2 mb-0">{helper().translate('WITH_VAT')}</label>
                                                            {config?.withVat}
                                                            <div className="text-light">
                                                                <Switch
                                                                    name="withVat"
                                                                    onColor="#86d3ff"
                                                                    onHandleColor="#2693e6"
                                                                    handleDiameter={25}
                                                                    width={55}
                                                                    height={20}
                                                                    uncheckedIcon={<span style={{ display: 'flex', margin: '-4px 0 0 -5px' }}>{helper().translate('NO')}</span>}
                                                                    checkedIcon={<span style={{ display: 'flex', margin: '-4px 0 0 -5px' }}>{helper().translate('YES')}</span>}
                                                                    padding="7px 0px"
                                                                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                                                    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                                                    onChange={(e) => setPurchase({ ...purchase, withVat: e })}
                                                                    checked={purchase?.withVat ? true : false}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-8 col-12 pl-0">
                                                        <label className="text-xs font-weight-bold text-primary mb-0">{helper().translate('WAREHOUSE')}
                                                        </label  >
                                                        <Select
                                                            name="warehouseId"
                                                            placeholder={helper().translate("CHOOSE_WAREHOUSE")}
                                                            value={warehouses?.filter(v => v.value === purchase?.warehouseId)}
                                                            onChange={(e) => { setPurchase({ ...purchase, warehouseId: e.value }); errors?.warehouseId ? setErrors({ ...errors, warehouseId: '' }) : '' }}
                                                            options={warehouses}
                                                            styles={styles}
                                                            className={errors?.warehouseId ? 'is-invalid-s' : ''}
                                                            isDisabled={!userSettings?.allowToChangeWarehouse}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`text-box d-sm-none d-none d-md-block col-3 pl-0`}>
                                        <label className="text-xs font-weight-bold text-primary mb-0">{helper().translate('COMMENT')}</label>
                                        <textarea className="textareas form-control h-81" name="comment" value={purchase.comment} onChange={(e) => setPurchase({ ...purchase, comment: e.target.value })}
                                        />
                                    </div>
                                </div>
                            </div>}

                        <div className={`col-md-12 col-lg-12 col-xl-12`}>
                            <label className="text-xs font-weight-bold text-primary mb-3">{helper().translate('IMPORT_DETAILS')}</label>
                            <ImportDetails
                                purchaseData={purchase}
                                setTransportPercente={(transportPercenteValue) => { setPurchase({ ...purchase, transportPercente: transportPercenteValue }); onCalculatorChange(transportPercenteValue) }}
                                transportDetails={transportDetails}
                                setTransportDetails={(items) => setTransportDetails(items)}
                                onPurchaseTotalsChange={(a) => setPurchase(a)}
                                onTransportDetails={() => setOnTransportDetails(!onTransportDetails)}
                                pdLength={purchaseDetails.length}>
                            </ImportDetails>
                        </div>

                        {/* {showPayments && !isImport &&
                        <div className={`col-md-12 col-lg-12 col-xl-12`}>
                            <label className="text-xs font-weight-bold text-primary mb-3">{helper().translate('PAYMENTS')}</label>
                            <PaymentPurchases payments={payments} showPayments={()=> setShowPayments(!showPayments)} purchase={purchase} partners={partners} getPurchase={getPurchase} user={user}/>
                        </div>
                    } */}
                    </>}
                </div>
                <Modal
                    show={showModal}
                    size='lg'
                    scrollable={true}
                    onHide={modalClose}
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header closeButton>
                        <Modal.Title className="ms-3" >Shto Artikull</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Item props={params} fromPurchase={true} articleType={purchase.purchaseTypeId === 1 ? 1 : 4} pc={afterNewItem}></Item>
                    </Modal.Body>
                </Modal >
                <div className="card shadow mt-1">
                    {/* <div className="card-header py-2">
                        <h6 className="m-0 font-weight-bold text-primary">Artikujt</h6>
                    </div> */}
                    {
                        showHiddeBox && <div className='datatable-columns' style={{ margin: '50px' }}>
                            {renderColumns()}
                        </div>
                    }
                    {/* Veton {onTransportDetails && */}
                    {onTransportDetails &&
                        <section>
                            <div id="purchaseDetails" className="card-body table-responsive pb-0 pt-1 px-2 mt-1 purchase-tab">
                                {loader && <LoaderComponent />}
                                {!loader && <table style={{ minWidth: '1500px', overflowX: 'scroll', tableLayout: 'fixed' }} className="table table-hover text-center sales-table purchase-table">
                                    <thead>
                                        <tr>
                                            <th style={{ width: '45px' }}>{helper().translate('NU')}. <i className="fas fa-ellipsis-v float-right mx-2 mt-1 cursor-pointer" onClick={() => setShowHiddeBox(!showHiddeBox)}></i></th>
                                            {checkHeader('item') && <th style={{ width: '250px' }}>{helper().translate('ITEM')}</th>}
                                            {checkHeader('project') && <th style={{ width: '120px' }}>{helper().translate('PROJECT')}</th>}
                                            {checkHeader('unit') && <th style={{ width: '60px' }}>{helper().translate('UNIT')}</th>}
                                            {checkHeader('quantity') && <th style={{ width: '75px' }}>{helper().translate('QUANTITY')}</th>}
                                            {checkHeader('price') && <th style={{ width: '75px' }}>{helper().translate('PRICE')}<br></br> {purchase.withVat ? helper().translate('WITHOUT_VAT') : ''}</th>}
                                            {/* {checkHeader('price_with_vat') && <th style={{width:'75px'}}>{helper().translate('PRICE_WITH_VAT')}</th>} */}
                                            {checkHeader('discount') && <th style={{ width: '75px' }}>{helper().translate('DISCOUNT')} %</th>}
                                            {checkHeader('price_with_discount') && <th style={{ width: '75px' }}>{helper().translate('PRICE_WITH_DISCOUNT')}</th>}
                                            {/* {checkHeader('markup') && <th style={{width:'75px'}}>{helper().translate('MARKUP')}</th>}
                                    {checkHeader('sale_price') && <th style={{width:'75px'}}>{helper().translate('SALE_PRICE')} <br></br> {purchase.withVat ? helper().translate('WITHOUT_VAT') : ''}</th>}
                                    {checkHeader('sale_price_with_vat') && purchase.withVat ? <th style={{width:'75px'}}>{helper().translate('SALE_PRICE')} <br></br> {helper().translate('WITH_VAT')}</th> : ''} */}
                                            {checkHeader('total') && <th style={{ width: '75px' }}>{purchase.withVat ? helper().translate('TOTAL') + " " + helper().translate('WITHOUT_VAT') : helper().translate('TOTAL')}</th>}
                                            {/* <th>Çmimi blerjes</th> */}
                                            {checkHeader('transport') ? <th style={{ width: '75px' }}>{helper().translate('TRANSPORT')}</th> : ''}
                                            {checkHeader('transport_pc') ? <th style={{ width: '75px' }}>{helper().translate('TRANSPORT')} %</th> : ''}
                                            {checkHeader('overvalue') && <th style={{ width: '75px' }}>{helper().translate('OVERVALUE')}</th>}
                                            {checkHeader('overvalue_pc') && <th style={{ width: '75px' }}>{helper().translate('OVERVALUE')} %</th>}
                                            {checkHeader('duty_value') && <th style={{ width: '75px' }}>{helper().translate('DUTY_VALUE')}</th>}
                                            {checkHeader('duty_pc') && <th style={{ width: '75px' }}>{helper().translate('DUTY')} %</th>}
                                            {checkHeader('excise') && <th style={{ width: '75px' }}>{helper().translate('EXCISE')}</th>}
                                            {checkHeader('excise_pc') && <th style={{ width: '75px' }}>{helper().translate('EXCISE')} %</th>}
                                            {checkHeader('purchase_price') && <th style={{ width: '75px' }}>{helper().translate('PURCHASE_PRICE')}</th>}
                                            {checkHeader('vat') && purchase.withVat ? <th style={{ width: '65px' }}>{helper().translate('VAT')} %</th> : ''}
                                            {checkHeader('vat_value') && purchase.withVat ? <th style={{ width: '75px' }}>{helper().translate('VAT_VALUE')}</th> : ''}
                                            {checkHeader('total') && <th style={{ width: '75px' }}>{helper().translate('TOTAL')}</th>}
                                            {checkHeader('total_with_vat') && purchase.withVat ? <th style={{ width: '75px' }}>{helper().translate('TOTAL_WITH_VAT')}</th> : ""}
                                            <th style={{ width: '25px' }}>+</th>
                                            <th style={{ width: '25px' }}>-</th>
                                        </tr>
                                    </thead>
                                    <tbody className='pb-5'>
                                        {[...purchaseDetails]?.map((item, key) => (item.status !== status.deleted &&
                                            <tr key={item.no} >
                                                <td>{item.no}</td>
                                                {/* Barcode */}
                                                {
                                                    item.editMode ?
                                                        checkHeader('item') && <td className="position-relative"><Select className={errors?.['pd_item_' + item.no] ? 'is-invalid-s' : ''} styles={styles} menuPortalTarget={document.body} value={items?.map(record => ({ value: record.id, label: record.itemName })).find(i => i.value === item.itemId)} onChange={onFieldChange(item.no)} options={items?.map(record => ({ value: record.id, label: record.barcode + ' - ' + record.itemName }))} ref={item.editMode ? selectRef : ''} id={`item_${item.no}`} name="itemId" ></Select>
                                                            {(purchase.purchaseTypeId == 1 || purchase.purchaseTypeId == 2) ? <button className="btn-add-item-from-purchase" onClick={modalShow} title="Shto artikull të ri">+</button> : ''}

                                                        </td>
                                                        :
                                                        checkHeader('item') && <td onClick={function () { onRowClick(item.no) }}><span className={`text-center text-${errors?.['pd_item_' + item.no] ? 'danger' : 'dark'} small`}>{item.itemId > 0 ? items?.map(record => ({ value: record.id, label: record.itemName })).find(i => i.value === item.itemId)?.label : 'Zgjidh arttikullin'}</span></td>
                                                }
                                                {/* Projekti */}
                                                {
                                                    item.editMode && item.itemId ?
                                                        checkHeader('project') && <td><Select styles={styles} menuPortalTarget={document.body} value={projects.find(i => item.projectId ? i.id === item.projectId : i.warehouseId === purchase?.warehouseId)} onChange={onFieldChange(item.no, item.itemId)} options={projects} name="projectId"></Select></td>
                                                        :
                                                        checkHeader('project') && <td onClick={function () { onRowClick(item.no) }}><span className="text-center text-dark small">{item.projectId ? setProject(item.projectId) : 'Zgjidh projektin'}</span></td>
                                                }
                                                {/* unit */}
                                                {
                                                    checkHeader('unit') && <td onClick={function () { onRowClick(item.no) }}><span className="text-center text-dark small">{setUnit(item.unitId)}</span></td>
                                                }
                                                {/* sasia */}
                                                {
                                                    item.editMode && item.itemId ?
                                                        checkHeader('quantity') && <td><input type="number" min={purchase.purchaseTypeId !== 5 ? "1" : ''} max={purchase.purchaseTypeId === 5 ? "-1" : ''} value={item.quantity} pattern="[+-]?\d+(?:[.,]\d+)?" onChange={onFieldChange(item.no)} name="quantity" className="form-control form-control-sm text-center" /></td>
                                                        :
                                                        checkHeader('quantity') && <td onClick={function () { onRowClick(item.no) }}><span className="text-center text-dark small">{item.quantity ? item.quantity : '0'}</span></td>
                                                }
                                                {/* cmimi pa tvsh */}
                                                {
                                                    item.editMode && item.itemId ?
                                                        checkHeader('price') && <td><input type="number" min="0" step="any" value={toFixed2('price', item.price)} onChange={onFieldChange(item.no)} name="price" className={`form-control form-control-sm text-center ${errors?.['pd_price_' + item.no] ? 'is-invalid mb-0' : ''}`} /></td>
                                                        :
                                                        checkHeader('price') && <td onClick={function () { onRowClick(item.no) }}><span className={`text-center text-${errors?.['pd_price_' + item.no] ? 'danger' : 'dark'} small`}>{errors?.['pd_price_' + item.no] ? 'Çmimi nuk mund të jetë 0!' : item.price ? toFixed2('price', item.price) : '0'}</span></td>
                                                }

                                                {/* zbritja */}
                                                {
                                                    item.editMode && item.itemId ?
                                                        checkHeader('discount') && <td><input type="number" min="0" value={toFixed2('discount', item.discount)} onChange={onFieldChange(item.no)} name="discount" className="form-control form-control-sm text-center" /></td>
                                                        :
                                                        checkHeader('discount') && <td onClick={function () { onRowClick(item.no) }}><span className="text-center text-dark small">{item.discount ? toFixed2('discount', item.discount) : '0'}</span></td>
                                                }
                                                {/* cmimi me zbritje */}
                                                {
                                                    item.editMode && item.itemId ?
                                                        checkHeader('discountPrice') && <td><input type="number" min="0" value={toFixed2('discountPrice', item.discountPrice)} onChange={onFieldChange(item.no)} name="discountPrice" className="form-control form-control-sm text-center" /></td>
                                                        :
                                                        checkHeader('discountPrice') && <td onClick={function () { onRowClick(item.no) }}><span className="text-center text-dark small">{item.discountPrice ? toFixed2('discountPrice', item.discountPrice) : 0}</span></td>
                                                }
                                                {/* marzha */}
                                                {/* {
                                            item.editMode && item.itemId ?
                                            checkHeader('markup') &&<td><input type="number" min="0" value={toFixed2('markup', item.markup)} onChange={onFieldChange(item.no)} name="markup" className="form-control form-control-sm text-center"/></td>
                                                :
                                                checkHeader('markup') &&<td onClick={function () { onRowClick(item.no) }}> <span className="text-center text-dark small">{item.markup ? toFixed2('markup', item.markup): '0'}</span></td>
                                        }
                                       
                                        {
                                            item.editMode && item.itemId ?
                                            checkHeader('retailPrice') &&<td><input type="number" min="0" value={toFixed2('retailPrice', item.salePrice)} onChange={onFieldChange(item.no)} name="retailPrice" className="form-control form-control-sm text-center"/></td>
                                                :
                                                checkHeader('retailPrice') &&<td onClick={function () { onRowClick(item.no) }}> <span className="text-center text-dark small">{item.salePrice ? toFixed2('retailPrice', item.salePrice) : '0'}</span></td>
                                        }
                                       
                                        {
                                            item.editMode && item.itemId &&
                                            purchase.withVat ? checkHeader('retailPriceWithVat') && <td><input type="number" min="0" value={toFixed2('retailPriceWithVat', item.salePriceWithVat)} onChange={onFieldChange(item.no)} name="retailPriceWithVat" className="form-control form-control-sm text-center"/></td>
                                                :
                                                purchase.withVat ? checkHeader('retailPriceWithVat') && <td onClick={function () { onRowClick(item.no) }}> <span className="text-center text-dark small">{item.salePriceWithVat ? toFixed2('retailPriceWithVat', item.salePriceWithVat) : '0'}</span></td> : ''
                                        } */}
                                                {/* sub total */}
                                                {
                                                    checkHeader('total') && <td onClick={function () { onRowClick(item.no) }}><span className="text-center text-dark small">{parseFloat(item.subTotal).toFixed(2)}</span></td>
                                                }
                                                {/* Transporti */}
                                                {
                                                    item.editMode && item.itemId ?
                                                        checkHeader('transport') && <td><input type="number" min="0" value={toFixed2('transport', item.transport)} onChange={onFieldChange(item.no)} name="transport" className="form-control form-control-sm text-center" /></td> :
                                                        checkHeader('transport') && <td onClick={function () { onRowClick(item.no) }}><span className="text-center text-dark small">{parseFloat(item.transport).toFixed(2)}</span></td>
                                                }
                                                {/* % e Transportit */}
                                                {
                                                    checkHeader('transportPercent') && <td onClick={function () { onRowClick(item.no) }}><span className="text-center text-dark small">{parseFloat(item.transportPercent).toFixed(purchaseDetails.length > 1 ? 2 : 0)}</span></td>
                                                }
                                                {/* Shtesa doganore % */}
                                                {
                                                    item.editMode && item.itemId ?
                                                        checkHeader('overvalue') && <td><input type="number" min="0" value={toFixed2('overvalue', item.overvalue)} onChange={onFieldChange(item.no)} name="overvalue" className="form-control form-control-sm text-center" /></td> :
                                                        checkHeader('overvalue') && <td onClick={function () { onRowClick(item.no) }}><span className="text-center text-dark small">{parseFloat(item.overvalue).toFixed(2)}</span></td>
                                                }
                                                {/* % e shteses doganore */}
                                                {
                                                    checkHeader('overvaluePercent') && <td onClick={function () { onRowClick(item.no) }}><span className="text-center text-dark small">{parseFloat(item.overvaluePercent).toFixed(2)}</span></td>
                                                }

                                                {/* Vlera doganës */}
                                                {
                                                    item.editMode && item.itemId ?
                                                        checkHeader('duty') && <td><input type="number" min="0" value={toFixed2('duty', item.duty)} onChange={onFieldChange(item.no)} name="duty" className="form-control form-control-sm text-center" /></td> :
                                                        checkHeader('duty') && <td onClick={function () { onRowClick(item.no) }}><span className="text-center text-dark small">{parseFloat(item.duty).toFixed(2)}</span></td>
                                                }
                                                {/* Dogana % */}
                                                {
                                                    checkHeader('dutyPercent') && <td onClick={function () { onRowClick(item.no) }}><span className="text-center text-dark small">{parseFloat(item.dutyPercent).toFixed(2)}</span></td>
                                                }
                                                {/* Akciza */}
                                                {
                                                    item.editMode && item.itemId ?
                                                        checkHeader('excise') && <td><input type="number" min="0" value={toFixed2('excise', item.excise)} onChange={onFieldChange(item.no)} name="excise" className="form-control form-control-sm text-center" /></td> :
                                                        checkHeader('excise') && <td onClick={function () { onRowClick(item.no) }}><span className="text-center text-dark small">{parseFloat(item.excise).toFixed(2)}</span></td>
                                                }
                                                {/* % e akcizës */}
                                                {
                                                    checkHeader('excisePercent') && <td onClick={function () { onRowClick(item.no) }}><span className="text-center text-dark small">{parseFloat(item.excisePercent).toFixed(2)}</span></td>
                                                }

                                                {
                                                    checkHeader('purchasePrice') && <td onClick={function () { onRowClick(item.no) }}><span className="text-center text-dark small">{parseFloat(item.purchasePrice).toFixed(2)}</span></td>
                                                }
                                                {/* tvsh */}
                                                {
                                                    purchase.withVat && item.editMode && item.itemId ?
                                                        checkHeader('vat') && <td><Select styles={styles} menuPortalTarget={document.body} value={vatLevels.find(i => i.value === item.vat)} onChange={onFieldChange(item.no, item.itemId)} options={vatLevels} name="vat" ></Select></td>
                                                        :
                                                        purchase.withVat ? checkHeader('vat') && <td onClick={function () { onRowClick(item.no) }}><span className="text-center text-dark small">{item.vat ? item.vat : '0'}</span></td> : ''
                                                }
                                                {/* shuma tvsh-se */}
                                                {
                                                    purchase.withVat ? checkHeader('vatSum') && <td onClick={function () { onRowClick(item.no) }}><span className="text-center text-dark small">{parseFloat(item.vatSum).toFixed(2)}</span>
                                                        <input type="hidden" name="vatSum" value={toFixed2('vatSum', item.vatSum)} />
                                                    </td> : checkHeader('vatSum') && ''
                                                }
                                                {/* totali me tvsh */}
                                                {
                                                    item.editMode && item.itemId &&
                                                        checkHeader('subTotal') ? <td><input type="number" min="0" value={toFixed2('subTotal', item.totalWithTransportAndOvervalueAndDutyExcise)} onChange={onFieldChange(item.no)} onBlur={(e) => handleBlur(e)} name="subTotal" className="form-control form-control-sm text-center" /></td>
                                                        :
                                                        checkHeader('subTotal') && <td onClick={function () { onRowClick(item.no) }}><span className="text-center text-dark small">{parseFloat(item.totalWithTransportAndOvervalueAndDutyExcise).toFixed(2)}</span></td>
                                                }
                                                {/* totali me tvsh dhe shtese doganore */}
                                                {
                                                    purchase.withVat ? checkHeader('totalSum') && <td onClick={function () { onRowClick(item.no) }}><span className="text-center text-dark small">{(parseFloat(item.totalWithTransportAndOvervalueAndDutyExcise) + parseFloat(item.vatSum)).toFixed(2)}</span></td> : ''
                                                }
                                                {
                                                    purchaseDetails.filter(p => p.status !== status.deleted).length === key + 1 && item.itemId ? <td><button className="btn-edit" onClick={() => addRow()}><span className="icon text-white-40"><i className="fas fa-plus-circle"></i></span></button></td> : <td></td>
                                                }
                                                {
                                                    purchaseDetails.length > 1 ? <td><button onClick={() => deleteRow(item.no)} className="btn-delete"><span className="icon text-white-40"><i className="fas fa-minus-circle"></i></span></button></td> : <td></td>
                                                }
                                            </tr>
                                        ))}
                                        <tr style={{ fontSize: '14px' }}>
                                            <td colspan={purchase.withVat ? 8 : 8} className="border-bottom-0"></td>
                                            <td style={{ background: '#f8f9fc', fontSize: '14px' }} className="font-weight-bold"><small>{helper().translate('TOTAL')} {purchase.withVat ? helper().translate('WITHOUT_VAT') : ''}</small><br></br>{parseFloat(purchaseTotals.subTotal).toFixed(2)}</td>
                                            {<td style={{ background: '#f8f9fc', fontSize: '14px' }} className="font-weight-bold"><small>{helper().translate('TRANSPORT')}</small><br></br>{parseFloat(purchaseTotals.transport).toFixed(2)}</td>}
                                            {<td style={{ background: '#f8f9fc', fontSize: '14px' }}></td>}
                                            {<td style={{ background: '#f8f9fc', fontSize: '14px' }} className="font-weight-bold"><small>{helper().translate('OVERVALUE')}</small><br></br>{parseFloat(purchaseTotals.overvalue).toFixed(2)}</td>}

                                            {<td style={{ background: '#f8f9fc', fontSize: '14px' }} colspan="1"></td>}
                                            {<td style={{ background: '#f8f9fc', fontSize: '14px' }} className="font-weight-bold"><small>{helper().translate('DUTY')}</small><br></br>{parseFloat(purchaseTotals.duty).toFixed(2)}</td>}
                                            {<td style={{ background: '#f8f9fc', fontSize: '14px' }} colspan="1"></td>}
                                            {<td style={{ background: '#f8f9fc', fontSize: '14px' }} className="font-weight-bold"><small>{helper().translate('EXCISE')}</small><br></br>{parseFloat(purchaseTotals.excise).toFixed(2)}</td>}
                                            {<td style={{ background: '#f8f9fc', fontSize: '14px' }} colspan={!purchaseTotals.withVat ? 2 : 3}></td>}
                                            {purchaseTotals.withVat ? <td style={{ background: '#f8f9fc', fontSize: '14px' }} className="font-weight-bold"><small>{helper().translate('VAT')}</small><br></br>{parseFloat(purchaseTotals.vatSum).toFixed(2)}</td> : ''}
                                            {<td style={{ background: '#f8f9fc', fontSize: '14px' }} className="font-weight-bold"><small>{helper().translate('TOTAL')}</small><br></br>{parseFloat(purchaseTotals.totalWithTransportAndOvervalueAndDutyExcise).toFixed(2)}</td>}
                                            {purchaseTotals.withVat ? <td style={{ background: '#f8f9fc', fontSize: '14px' }} className="font-weight-bold"><small>{helper().translate('TOTAL_WITH_VAT')}</small><br></br>{(parseFloat(purchaseTotals.totalWithTransportAndOvervalueAndDutyExcise + purchaseTotals.vatSum)).toFixed(2)}</td> : ''}
                                        </tr>
                                    </tbody>
                                </table>
                                }
                                {/* <div>
                            <table style={{overflowX: 'scroll', tableLayout: 'fixed', float:'right'}} className="table table-hover text-center sales-table purchase-table">
                                <tr style={{fontSize: '14px'}}>
                                    <td colspan={purchase.withVat ? 7 : 8} className="border-bottom-0"></td>
                                    <td style={{background: '#f8f9fc', fontSize: '14px'}} className="font-weight-bold"><small>{helper().translate('TOTAL')} {purchase.withVat ? helper().translate('WITHOUT_VAT') : ''}</small><br></br>{parseFloat(purchaseTotals.subTotal).toFixed(2)}</td>
                                    {<td style={{background: '#f8f9fc', fontSize: '14px'}} className="font-weight-bold"><small>{helper().translate('TRANSPORT')}</small><br></br>{parseFloat(purchaseTotals.transport).toFixed(2)}</td> : ''}
                                    {<td style={{background: '#f8f9fc', fontSize: '14px'}}></td> : ''}
                                    {<td style={{background: '#f8f9fc', fontSize: '14px'}} className="font-weight-bold"><small>{helper().translate('OVERVALUE')}</small><br></br>{parseFloat(purchaseTotals.overvalue).toFixed(2)}</td> : ''}
                                    {<td style={{background: '#f8f9fc', fontSize: '14px'}} colspan="2"></td> : ''}
                                    {<td style={{background: '#f8f9fc', fontSize: '14px'}} className="font-weight-bold"><small>{helper().translate('DUTY')}</small><br></br>{parseFloat(purchaseTotals.duty).toFixed(2)}</td> : ''}
                                    {<td style={{background: '#f8f9fc', fontSize: '14px'}} className="font-weight-bold"><small>{helper().translate('EXCISE')}</small><br></br>{parseFloat(purchaseTotals.excise).toFixed(2)}</td> : ''}
                                    {purchaseTotals.withVat ? <td style={{background: '#f8f9fc', fontSize: '14px'}} colspan={3}></td> : ''}
                                    {purchaseTotals.withVat ? <td style={{background: '#f8f9fc', fontSize: '14px'}} className="font-weight-bold"><small>{helper().translate('VAT')}</small><br></br>{parseFloat(purchaseTotals.vatSum).toFixed(2)}</td> : ''}
                                    {purchaseTotals.withVat ? <td style={{background: '#f8f9fc', fontSize: '14px'}} className="font-weight-bold"><small>{helper().translate('TOTAL')}</small><br></br>{purchaseTotals.totalWithTransportAndOvervalueAndDutyExcise.toFixed(2)}</td> : ''} 
                                    {(purchaseTotals.withVat) ? <td style={{background: '#f8f9fc', fontSize: '14px'}} className="font-weight-bold"><small>{helper().translate('TOTAL_WITH_VAT')}</small><br></br>{(parseFloat(purchaseTotals.totalWithTransportAndOvervalueAndDutyExcise) + parseFloat(purchaseTotals.vatSum)).toFixed(2)}</td> : ''} 
                                </tr>
                            </table>
                        </div> */}
                            </div>
                        </section>
                    }
                </div>
            </div>
        </div>
    )
}

export default ImportPurchase
