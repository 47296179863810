import React, { useState, useEffect, forwardRef, useRef, useImperativeHandle } from 'react'
import apiService from '../../Services/apiServices';
import mdlSettings from '../../Models/Settings';
import HeaderTools from '../Header/HeaderTools';
import invOne from '../../img/invoice_1.PNG';
import invTwo from '../../img/invoice_2.PNG';
import invThree from '../../img/invoice_3.PNG'
import invFour from '../../img/invoice_4.jpg';
import invFive from '../../img/invoice_5.png';
import invSix from '../../img/invoice_6.PNG'
import { MDBRadio } from 'mdb-react-ui-kit';
import helper from '../../Services/helper';
import { confirmAlert } from 'react-confirm-alert'; 
import { ToastProvider, useToasts } from 'react-toast-notifications';

const Invoice = params => {
    const { addToast } = useToasts();
    const [settings, setSettings] = useState(new mdlSettings())
    const [costumInvoices, setCostumInvoices] = useState()

    const onInvoiceChange = (e) => {
        // console.log('e', e.target.value)
        setSettings({ ...settings, invoiceForm: parseInt(e.target.value) })
        params.setSettings({ ...settings, invoiceForm: parseInt(e.target.value) })
    }

    const t = (key) => {
        return helper().translate(key);
    }

    const getCostumInvoices = () => {
        apiService().get('invoicesTemplates').then(response => {
            if (response.success) {
                setCostumInvoices(response.data)
            }
        })
    }

    const responseMessage = (appearance, message) => {
        addToast(message, { appearance: appearance, autoDismiss: true, });
    }

    const deleteCostumInvoice = (id) => {
        confirmAlert({
            customUI: ({ onClose }) => {
              return (
                <div className="custom-ui" >
                  <h1>A jeni te sigurt?</h1>
                  <p>Dëshironi të fshini këtë artikull?</p>
                  <button
                    onClick={() => {
                        apiService().delete('invoicesTemplates', id)
                            .then((response)=>{
                                if(response.success) {
                                    responseMessage(response.success ? 'success' : '', 'U fshi me sukses!');
                                    getCostumInvoices()
                                }    
                            });
                        onClose();
                    }}
                  >
                    Po , Fshije!
                  </button>
                  <button onClick={onClose}>Jo</button>
                </div>
              );
            }
          }) 
    }

    useEffect(() => {
        // console.log('params in invoice: ', settings)
        setSettings(params.sett)
        getCostumInvoices()
    }, [params]);

    return (
        <>
            <div className="card o-hidden border-0 shadow-lg">
                <div className="card-body p-0">
                    <div className="row">
                        <div className="no-shadow px-4">
                            <HeaderTools save={params.save} new="#/add-invoice" />
                        </div>
                        <div className='container'>
                            <div className="row mb-3 ">
                                <div className="col-12 col-xl-6 mt-3 ">
                                    <div className="">
                                        <div className="d-flex justify-content-center align-items-center">
                                            <MDBRadio className="position-relative" name="invoiceForm" value={1} onChange={(e) => onInvoiceChange(e)} checked={settings && settings.invoiceForm == 1} id="inlineRadio1" />
                                            <label className="form-check-label" htmlFor="inlineRadio1">{t('INVOICE')} 1</label>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-center align-items-center ">
                                        <img width={500} height={580} src={invOne} className='border border-dark px-5 ' style={{ cursor: 'pointer' }} onClick={() => params.setSettings({ ...settings, invoiceForm: parseInt(1) })} />
                                    </div>
                                </div>
                                <div className="col-12 col-xl-6 mt-3 ">
                                    <div className="">
                                        <div className="d-flex justify-content-center align-items-center">
                                            <MDBRadio className="position-relative" name="invoiceForm" value={2} onChange={(e) => onInvoiceChange(e)} checked={settings && settings.invoiceForm == 2} id="inlineRadio2" />
                                            <label className="form-check-label" htmlFor="inlineRadio2">{t('INVOICE')} 2</label>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-center align-items-center">
                                        <img width={500} height={580} src={invTwo} className='border border-dark px-5' style={{ cursor: 'pointer' }} onClick={() => params.setSettings({ ...settings, invoiceForm: parseInt(2) })} />
                                    </div>
                                </div>
                                <div className="col-12 col-xl-6 mt-3">
                                    <div className="">
                                        <div className="d-flex justify-content-center align-items-center">
                                            <MDBRadio className="position-relative" name="invoiceForm" value={3} onChange={(e) => onInvoiceChange(e)} checked={settings && settings.invoiceForm == 3} id="inlineRadio3" />
                                            <label className="form-check-label" htmlFor="inlineRadio3">{t('INVOICE')} 3</label>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-center align-items-center">
                                        <img width="100%" height={580} src={invThree} className='border border-dark px-5' style={{ cursor: 'pointer' }} onClick={() => params.setSettings({ ...settings, invoiceForm: parseInt(3) })} />
                                    </div>
                                </div>
                                <div className="col-12 col-xl-6 mt-3">
                                    <div className="">
                                        <div className="d-flex justify-content-center align-items-center">
                                            <MDBRadio className="position-relative" name="invoiceForm" value={4} onChange={(e) => onInvoiceChange(e)} checked={settings && settings.invoiceForm == 4} id="inlineRadio4" />
                                            <label className="form-check-label" htmlFor="inlineRadio4">{t('INVOICE')} 4</label>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-center align-items-center">
                                        <img width={500} height={580} src={invFour} className='border border-dark px-5' style={{ cursor: 'pointer' }} onClick={() => params.setSettings({ ...settings, invoiceForm: parseInt(4) })} />
                                    </div>
                                </div>
                                <div className="col-12 col-xl-6 mt-3">
                                    <div className="">
                                        <div className="d-flex justify-content-center align-items-center">
                                            <MDBRadio className="position-relative" name="invoiceForm" value={5} onChange={(e) => onInvoiceChange(e)} checked={settings && settings.invoiceForm == 5} id="inlineRadio5" />
                                            <label className="form-check-label" htmlFor="inlineRadio5">{t('INVOICE')} 5</label>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-center align-items-center">
                                        <img width={500} height={580} src={invFive} className='border border-dark px-5' style={{ cursor: 'pointer' }} onClick={() => params.setSettings({ ...settings, invoiceForm: parseInt(5) })} />
                                    </div>
                                </div>
                                {/* <div className="col-12 col-xl-6 mt-3">
                                    <div className="">
                                        <div className="d-flex justify-content-center align-items-center">
                                            <MDBRadio className="position-relative" name="invoiceForm" value={7} onChange={(e) => onInvoiceChange(e)} checked={settings && settings.invoiceForm == 7} id="inlineRadio7" />
                                            <label className="form-check-label" htmlFor="inlineRadio7">{t('INVOICE')} 6</label>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-center align-items-center">
                                        <img width={500} height={580} src={invSeven} className='border border-dark px-5' style={{ cursor: 'pointer' }} onClick={() => params.setSettings({ ...settings, invoiceForm: parseInt(7) })} />
                                    </div>
                                </div> */}
                                {
                                    costumInvoices?.map(ci => {
                                        return <div className="col-12 col-xl-6 mt-3 ">
                                            <div className="">
                                                <div className="d-flex justify-content-center align-items-center">
                                                    <MDBRadio className="position-relative" name="invoiceForm" value={ci.id + 7} onChange={(e) => onInvoiceChange(e)} checked={settings && settings.invoiceForm == ci.id + 7} id={`inlineRadio${ci.id + 7}`} />
                                                    <label className="form-check-label" htmlFor={`inlineRadio${ci.id + 7}`}>{t('INVOICE')} {ci.name}</label>
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-center align-items-center">
                                                <button className='costum-invoice-delete-button' onClick={()=> deleteCostumInvoice(ci.id)}>delete</button>
                                                <button className='costum-invoice-button border border-dark px-5' style={{ cursor: 'pointer' }} onClick={() => params.setSettings({ ...settings, invoiceForm: parseInt(ci.id + 7) })}>{ci.name}</button>
                                            </div>
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Invoice
