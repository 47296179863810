import React, { Component } from 'react'
import { render } from 'react-dom';
import Select, { components } from 'react-select'
import apiService from '../../Services/apiServices'
import { useToasts } from 'react-toast-notifications'

function withToast(Component) {
    return function WrappedComponent(props) {
        const toastFuncs = useToasts()
        return <Component {...props} {...toastFuncs} />;
    }
}

class ItemOtherDetails extends Component {
    constructor(props) {
        super(props);
        this.showState = this.showState.bind(this);
        this.state = {
            itemId: props ? props.props?.match?.params.id : 0,
            pako: {
                shumefishi: 1,
                piece: 0.0,
                price: 0.0,
                package_price: 0.0
            },
            pakot: [],
            production: [],
            items: [],
            units: [],
            status: { added: 1, deleted: -1, changed: 2, unchanged: 0 },
            isAssembled: false,
            isProduction: false,
            errors: [],
            mainItem: {}
        }

        this.status = {
            added: 1,
            deleted: -1,
            changed: 2,
            unchanged: 0
        };

        this.redColor = {
            control: (base, state) => ({
                ...base,
                color: "red",
            })
        };

        const tables = ['getNoexpenseItems', 'units'];
        tables.map(table => {
            apiService()
                .get(table)
                .then((response) => {
                    if (response.success) {
                        if (table != 'units') {
                            var items = response.data.map(item => ({ value: item.id, label: item.itemName, unit: item.unitId }));
                            this.setState({ items: items });
                        }
                        else {
                            var units = response.data;
                            this.setState({ units: units });
                        }
                        //this.showState();
                    }
                    else {
                        console.log('Error:', table + response.errorMessage);
                    }
                });
        });

        this.responseMessage = this.responseMessage.bind(this);
    }

    componentDidMount() {
        if (this.state.itemId) {
            apiService()
                .query('getAssemblies', 'itemId=' + this.state.itemId)
                .then((response) => {
                    if (response.success) {
                        var assemblies = response.data;
                        var production = this.state.production;
                        var items = this.state.items;
                        var units = this.state.units;
                        assemblies.forEach(ass => {
                            const item = items.find(i => i.value === ass.parentId)
                            const unit = item ? units.find(u => u.id === item.unit) : ''
                            var newItem = { no: Math.max(...production.map(o => o.no), 0) + 1, id: ass.id, item: ass.parentId, itemName: item ? item.label : '', quantity: ass.quantity, unit: unit != undefined ? unit.name : '', editMode: false };
                            production.push(newItem);
                            this.setState({ production: production });
                        });
                    }
                    else {
                        console.log('Error:' + response.errorMessage);
                    }
                });

            this.getItem('item', 'id', this.state.itemId)
        }
        else {
            let hash = this.props.props?.location?.hash;
            if (hash === "#production") this.setState({ isProduction: true });
            else if (hash === "#assembled") this.setState({ isAssembled: true });
        }

        this.props.save(() => this.save());
    }

    async getItem(queryName, paramName, paramValue) {
        await apiService()
            .query(queryName, paramName + '=' + paramValue)
            .then((response) => {
                if (response.success) {
                    this.setState({
                        mainItem: response.data[0],
                        isAssembled: response.data[0].assembled,
                        isProduction: response.data[0].production
                    });
                } else {
                    console.log('Error:' + response.errorMessage);
                }
            });
    }

    showState() {
        console.log('staaateee: ', this.state.production)
    }

    addItemsForm = (t) => {
        console.log(t)
        if (t == 'pakot') {
            var pakot = this.state.pakot;
            var newItem = { no: Math.max(...pakot.map(o => o.no), 0) + 1, id: 0, shumefishi: 1, piece: 0.0, package_price: 0.0 };
            pakot.push(newItem);
            this.setState({ pakot: pakot });
        }
        else if (t == 'production') {
            var production = this.state.production;
            var newItem = { no: Math.max(...production.map(o => o.no), 0) + 1, id: 0, item: 0, itemName: 'Select item', quantity: 1.00, unit: 0 };
            production.push(newItem);
            this.setState({ production: production });
        }

    }

    deleteItemForm = (t, rowId) => {
        if (t == 'pakot') {
            var arrayCopy = this.state.pakot.filter((row) => row.no !== rowId);
            var count = 1;
            arrayCopy.forEach(i => { i.no = count; count++ });
            this.setState({ pakot: arrayCopy });
        }
        else if (t == 'production') {
            //var arrayCopy = this.state.production.filter((row) => row.no !== rowId);
            var arrayCopy = this.state.production;
            var item = arrayCopy.find(i => i.no === rowId)
            item.status = this.status.deleted;
            var count = 1;
            arrayCopy.forEach(i => { i.no = count; count++ });
            this.setState({ production: arrayCopy });
        }
    };

    rowClick = (t, no) => {
        if (t == 'pakot') {
            var rowItem = this.state.pakot;
        }
        else if (t == 'production') {
            var rowItem = this.state.production;
        }
        rowItem.forEach(i => i.editMode = false);
        rowItem[no - 1].editMode = !rowItem[no - 1].editMode;
        this.setState(rowItem);
    }

    onFieldChange = (t, e, obj, no) => {
        console.log('t, e, obj, no: ', t, e, obj, no)
        if (t == 'pakot') {
            var rows = this.state.pakot;
        }
        else if (t == 'production') {
            var rows = this.state.production;
        }
        var row = rows.find(i => i.no === no);

        if (obj != undefined) {
            var rows = this.state.production;
            var row = rows.find(i => i.no === no);
            var item = this.state.items.find(i => i.value === e.value)
            var unit = this.state.units.find(u => u.id === item.unit)

            row.itemName = item.label;
            row.unit = unit.name;
            row.editMode = false;
            row.status = this.status.changed

            this.setState({ production: rows });

            row[obj.name] = e.value;
            this.setState({
                [obj.name]: e.value,
            });
        }
        else {
            row.status = this.status.changed
            row[e.target.name] = e.target.value;
            this.setState({
                [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value,
            });
        }
    };

    save() {
        //alert('other details: ', this.state.itemId)
        var objs = [],
            errors = [],
            items = this.state.pakot

        if (this.state.itemId) {
            items.forEach(item => {
                if (item.item != 0) {
                    if (item.id == 0) {
                        let obj = {
                            id: 0,
                            entity: {
                                id: 0,
                                itemId: parseInt(this.state.itemId),
                                parentId: item.item,
                                quantity: parseFloat(item.quantity),
                                status: null
                            },
                            status: this.status.added,
                            type: 'assemblies'
                        }
                        objs.push(obj)
                    }
                    else {
                        let obj = {
                            entity: {
                                id: item.id,
                                itemId: parseInt(this.state.itemId),
                                parentId: item.item,
                                quantity: parseFloat(item.quantity),
                                status: null
                            },
                            status: item.status ? item.status : this.status.unchanged,
                            type: 'assemblies'
                        }
                        if (obj.status != this.status.unchanged) {
                            objs.push(obj)
                        }
                    }
                }
                else {
                    errors.push(item.no)
                }
            });
        }
        else {
            errors.push({ itemIdError: 'Fillimisht plotësojeni formën me të dhanat bazike!' })
        }

        if (errors.length > 0) {
            this.setState({
                errors: errors,
            });
        }
        else {
            this.setState({
                errors: [],
            });
            apiService()
                .saveChanges(objs)
                .then((response) => {
                    console.log('resp: ', response)
                    if (response.success) {
                        this.responseMessage(response.success)
                        var rowItem = this.state.production;
                        rowItem.forEach(i => i.editMode = false);
                        this.setState(rowItem);
                    }
                    else {
                        console.log('Error:' + response);
                        this.responseMessage(false)
                    }
                });
        }
    }

    responseMessage(success) {
        let that = this;
        if (success) {
            that.props.addToast('Saved Successfully', { appearance: 'success', autoDismiss: true, });
        }
        else {
            that.props.addToast('Ka ndodhur nje gabim!', { appearance: 'error', autoDismiss: true, });
        }
    }

    render() {
        const { pakot, production1, items, isAssembled, isProduction, errors, mainItem } = this.state;
        const production = this.state.production.filter((item) => item.status !== this.status.deleted)
        let that = this;
        var price = mainItem.retailPrice * (1 + mainItem.vat / 100);
        return (
            <>
                {errors.length > 0 && errors[0]['itemIdError'] ?
                    <div className="col-md-12 col-lg-12 col-xl-12 p-4">
                        <div className="alert alert-danger text-center" role="alert">
                            {errors[0]['itemIdError']}
                        </div>
                    </div>
                    : ''}
                <div className="col-md-12 col-lg-12 col-xl-12 mb-3 d-md-flex d-lg-flex d-xl-flex">
                    {/* <button type="button" onClick={() => this.save()} className="btn btn-primary float-right">'Save'</button> */}
                    <div className="col-md-6 px-md-3 px-3 py-5 mx-auto overflow-hidden">
                        <div className="w-100 d-flex align-items-center">
                            <div className="col-md-3"><h5 className="m-0">Pako</h5></div>
                            <div className="col-md-9 text-right">
                                <button onClick={() => this.addItemsForm('pakot')} className="btn btn-primary btn-icon-split btn-sm mt-2 mb-2 mr-2">
                                    <span className="icon text-white-40">
                                        <i className="fas fa-plus" />
                                    </span>
                                    <span className="text ">Shto</span>
                                </button>
                            </div>
                        </div>
                        <table className="table table-hover">
                            <thead>
                                <tr>
                                    <th>Shumëfishi</th>
                                    <th>Copë</th>
                                    <th>Çmimi njësi</th>
                                    <th>Çmimi i pakos</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody className='pb-5'>
                                {pakot.map((item, key) =>

                                    <tr key={item.no}>
                                        {
                                            item.editMode ?
                                                <td onClick={function () { that.rowClick('pakot', item.no) }}><input className="form-control " type="number" value={item.shumefishi} name="shumefishi" onChange={(e, obj) => this.onFieldChange('pakot', e, obj, item.no)}></input></td>
                                                :
                                                <td onClick={function () { that.rowClick('pakot', item.no) }}>{item.shumefishi}</td>
                                        }
                                        {
                                            item.editMode ?
                                                <td onClick={function () { that.rowClick('pakot', item.no) }}><input className="form-control " type="number" value={item.piece} name="piece" onChange={(e, obj) => this.onFieldChange('pakot', e, obj, item.no)}></input></td>
                                                :
                                                <td onClick={function () { that.rowClick('pakot', item.no) }}>{item.piece}</td>
                                        }
                                        {
                                            item.editMode ? 
                                            <td onClick={function(){ that.rowClick('pakot', item.no)}}><input className="form-control " type="number" value={mainItem.retailPrice*(1+ mainItem.vat/100)} name="price" onChange={(e,obj) => this.onFieldChange('pakot', e, obj, item.no)}></input></td>
                                            :
                                            item.price = mainItem.retailPrice * (1 + mainItem.vat / 100)
                                            // <td className={item.editMode ? 'line-height-2' : ''} onClick={function () { that.rowClick('pakot', item.no) }}>{parseFloat(mainItem.retailPrice * (1 + mainItem.vat / 100)).toFixed(2)} €</td>
                                        }
                                        {
                                            // item.editMode ? 
                                            // <td onClick={function(){ that.rowClick('pakot', item.no)}}><input className="form-control " type="number" defaultValue={`${item.package_price} €`} name="package_price" onChange={(e,obj) => this.onFieldChange('pakot', e, obj, item.no)}></input></td>
                                            // :
                                            //item.package_price = item.price * item.shumefishi,
                                            <td className={item.editMode ? 'line-height-2' : ''} onClick={function () { that.rowClick('pakot', item.no) }}>{parseFloat((mainItem.retailPrice * (1 + mainItem.vat / 100)) * item.shumefishi).toFixed(2)} €</td>
                                        }
                                        <td>
                                            <button onClick={() => this.deleteItemForm('pakot', item.no)} className="btn btn-danger btn-icon-split btn-sm">
                                                <span className="icon text-dark-40">
                                                    <i className="fas fa-trash" />
                                                </span>
                                            </button>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                    <div className="col-md-6 px-md-3 px-3 py-5 mx-auto overflow-hidden">
                        {(isAssembled || isProduction) ?
                            <>
                                <div className="w-100 d-flex align-items-center">
                                    <div className="col-md-3"><h5 className="m-0">Prodhim</h5></div>
                                    <div className="col-md-9 text-right">
                                        <button onClick={() => this.addItemsForm('production')} className="btn btn-primary btn-icon-split btn-sm mt-2 mb-2 mr-2">
                                            <span className="icon text-white-40">
                                                <i className="fas fa-plus" />
                                            </span>
                                            <span className="text ">Shto</span>
                                        </button>
                                    </div>
                                </div>
                                <table className="table table-hover">
                                    <thead>
                                        <tr>
                                            <th>Artikulli</th>
                                            <th>Sasia</th>
                                            <th>Njësia</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody className='pb-5'>
                                        {production.map((item, key) =>
                                            <tr key={item.no}>
                                                {
                                                    item.editMode ?
                                                        // <td onClick={function(){ that.rowClick('production', item.no)}}><input className="form-control " type="number" value={item.item} name="item" onChange={(e,obj) => this.onFieldChange(e, obj, item.no)}></input></td>
                                                        <td><Select menuPortalTarget={document.body} className={errors.includes(item.no) ? 'text-red' : ''} value={items.find(i => i.value === item.item)} onChange={function (e, obj) { that.onFieldChange('production', e, obj, item.no) }} name="item" options={items} ></Select>  </td>
                                                        :
                                                        <td className={errors.includes(item.no) ? 'text-red' : ''} onClick={function () { that.rowClick('production', item.no) }}>{item.itemName}</td>
                                                }
                                                {
                                                    item.editMode ?
                                                        <td onClick={function () { that.rowClick('production', item.no) }}><input style={{ width: '50px' }} className="form-control " type="number" value={item.quantity} name="quantity" onChange={(e, obj) => this.onFieldChange('production', e, obj, item.no)}></input></td>
                                                        :
                                                        <td onClick={function () { that.rowClick('production', item.no) }}>{item.quantity}</td>
                                                }
                                                {
                                                    // item.editMode ? 
                                                    // <td onClick={function(){ that.rowClick('production', item.no)}}><input style={{width: '50px'}}  className="form-control " type="number" defaultValue={item.unit} name="unit" onChange={(e,obj) => this.onFieldChange('production', e, obj, item.no)}></input></td>
                                                    // :
                                                    <td onClick={function () { that.rowClick('production', item.no) }}>{item.unit}</td>
                                                }
                                                <td>
                                                    <button onClick={() => this.deleteItemForm('production', item.no)} className="btn btn-danger btn-icon-split btn-sm">
                                                        <span className="icon text-dark-40">
                                                            <i className="fas fa-trash" />
                                                        </span>
                                                    </button>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </>
                            : ''}
                    </div>
                    {/* <div className="col-md-6 px-md-3 px-3 py-5 mx-auto overflow-hidden">
                        <div className="col-md-12 col-lg-12 col-xl-12 mb-3 px-0">
                            <Select
                                menuPortalTarget={ !this.props.props.fromPurchase ? document.body : ''} 
                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                onChange="{this.onFieldChange}"
                                placeholder={articleType != 3 ? 'Malli' : 'Konto e shpenzimeve'}
                                name="accountA"
                                options={this.state.accounts}
                                value = {this.state.accounts.find(account => account.value == this.state.item.accountA)}
                                styles={this.customStyles}
                                className={errors.accountA != '' ? 'is-invalid-s': ''}
                            />
                            {errors.accountA != '' && <span className='error'>{errors.accountA}</span>}
                        </div>
                    </div> */}
                </div>
            </>
        )
    }
}

export default withToast(ItemOtherDetails)