import React, { useState, useEffect } from 'react'
import Search from '../../PageComponents/Search/Search_Old';
import InvoiceEditor from './InvoiceEditor'
import apiService from '../../Services/apiServices';
import { ToastProvider, useToasts } from 'react-toast-notifications';

const AddInvoice = (params) => {
    const { addToast } = useToasts();
    const [name, setName] = useState();
    const [text, setText] = useState();
    const id = params.match.params.id;

    const save = () => {
        if (id) {
            apiService().put('invoicesTemplates', { id: id ? id : 0, name: name, text: text })
                .then(response => {
                    if (response.success) {
                        responseMessage(response.success ? 'success' : '', 'U ruajt me sukses!');
                    }
                    else {
                        responseMessage('error', 'Ka ndodhur një gabim!')
                    }
                })
        }
        else {
            apiService().post('invoicesTemplates', { id: id ? id : 0, name: name, text: text })
                .then(response => {
                    if (response.success) {
                        responseMessage(response.success ? 'success' : '', 'U ruajt me sukses!');
                        window.location.href = '#/edit-invoice/' + response.data.id;
                    }
                    else {
                        responseMessage('error', 'Ka ndodhur një gabim!')
                    }
                })
        }
    }

    const responseMessage = (appearance, message) => {
        addToast(message, { appearance: appearance, autoDismiss: true, });
    }

    const getTemplate = (id) => {
        apiService().query('getInvoiceTemplate', 'id=' + id)
            .then(response => {
                if (response.success) {
                    console.log(response)
                    setName(response.data[0].name)
                    setText(response.data[0].text)
                }
            })
    }

    useEffect(() => {
        if (id) {
            getTemplate(id)
        }
    }, [])

    return (
        <div className='px-4 py-2'>
            <Search save={() => save()} />
            <div className='mt-2'>
                <input value={name} name='name' type='text' className='form-control mb-2' placeholder='Emertimi' onChange={(e) => setName(e.target.value)} />
                <InvoiceEditor name="Text" value={text} getBackData={(text) => setText(text)} />
            </div>
        </div>
    )
}

export default AddInvoice