import React, { useState, useEffect, useRef } from 'react';
import apiService from '../../Services/apiServices';
import helper from '../../Services/helper';
import Select , {components} from "react-select";
import { useToasts } from "react-toast-notifications";
import { MDBInput  } from "mdb-react-ui-kit";
import LoaderComponent from '../../PageComponents/UI/Components/LoaderComponent'
import { confirmAlert } from 'react-confirm-alert'; 
import globals from '../../Services/globals';


function Permissions(props)  { 
  
  const [items , setItems] = useState([]);
  const [roles, setRoles] = useState([]);
  const [allRoles, setAllRoles] = useState([]);
  const [modules, setModules] = useState([]);
  const [disableSaveBtn, setDisableSaveBtn] = useState(true);
  const [disableRolesBtn, setDisableRolesBtn] = useState(false);
  const [selectRoleId, setSelectRoleId] = useState(null);
  const [viewAll, setViewAll] = useState(false);
  const [loader , setLoader] = useState(false);
  const { addToast } = useToasts();
  const newItemObj  = {
    id: 0,
    countNumber:Math.max(...allRoles.map(o => o.countNumber), 0) + 1 ,
    editMode: false,
    roleName : null
  }

    useEffect(()=>{
      getRoles();
      getModules();
    },[])

    
//   const multipleItemStyle = {
//     container: provided => ({
//       ...provided,
//       minWidth: '190px'
//     }),
//     control: (provided, state) => ({
//       ...provided,
//       background: "#fff",
//       borderColor: "#9e9e9e",
//       minHeight: "28.59px",
//       height: "28.59px",
//       boxShadow: state.isFocused ? null : null,
//     }),
//     menu: (provided, state) => ({
//      ...provided,
//      width: "auto"
//     //  marginTop: "30px"
//    }),
//     menuList: (provided, state) => ({
//      ...provided,
//      width: "auto"
//    }),
  
//     valueContainer: (provided, state) => ({...provided,height: "28.59px",padding: "0 6px",}),
//     input: (provided, state) => ({ ...provided, margin: "0px",}), indicatorSeparator: (state) => ({ display: "none", }),
//     indicatorsContainer: (provided, state) => ({...provided,height: "28.59px",}),
//   };

const selectStyles = {
    control: (provided, state) => ({
      ...provided,
      background: "#fff",
      borderColor: "#9e9e9e",
      minHeight: "28.59px",
      height: "28.59px",
      boxShadow: state.isFocused ? null : null,
    }),

    valueContainer: (provided, state) => ({
      ...provided,
      height: "28.59px",
      padding: "0 6px",
    }),

    input: (provided, state) => ({
      ...provided,
      margin: "0px",
    }),
    indicatorSeparator: (state) => ({
      display: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "28.59px",
    }),
  };

    const getPermissions = (roleId) => {
      setLoader(true);
      setDisableSaveBtn(false);
      var params = `&roleId=${roleId}`;
      apiService().query('getPermissions',params).then((response) => {
        if(response.success){
          setLoader(false);
          var data = response.data;
          setItems(data);
        }
      })
    }

    const getRoles = () => {
      let options;
      apiService()
        .get("roles")
        .then((response) => {
          if (response.success) {
            console.log(response.data)
            options = response.data.filter(f => f.id !== 1)
            let optionsWithoutAdmin = options.map((item, index) => ({
              value: item.id,
              label: item.roleName
            }));

            setRoles(optionsWithoutAdmin);
            let count = 1;
            response.data.forEach(e => {
              e.countNumber = count; count++
            });
            let allRoles = response.data;
            allRoles.forEach(e => {
              e.editMode = false;
            });
            setAllRoles(allRoles);
          } else {
            alert("Error: getting CashBox" + response.errorMessage);
          }
        });
    };
    const getModules = () => {
      let options;
      apiService()
        .query("getModules")
        .then((response) => {
          if (response.success) {
            options = response.data.map((item, index) => ({
              value: item.id,
              label: helper().translate(item.type)
            }));
            setModules(options);
          } else {
            alert("Error: getting CashBox" + response.errorMessage);
          }
        });
    };


    const addRow = () => {
      setDisableRolesBtn(false);
        const newState = [...allRoles, newItemObj];
    
        for(var i= 0 ; i< newState.length ; i++){
          newState[i].editMode = false;
          if(i === (newState.length - 1)) {
            newState[i].editMode = true;
            }
          }
          setAllRoles(newState);  
      };

      const rowClick = (no) => {
        if(no !== 1){
          let allRolesArr = [...allRoles];
          allRolesArr.forEach(e => {
            e.editMode = false;
          });
          allRolesArr[no-1].editMode = true;
          allRolesArr[no-1].status = 2;
          setAllRoles(allRolesArr);

        }
      }

    const fieldChange = (e,no) => {
      if(e.target.name === "roleName"){
        let rolesArr = [...allRoles];
        rolesArr[no-1][e.target.name] = e.target.value.replace(/[^a-z]/gi, '')
        setAllRoles(rolesArr);
        }
    };

    const selectChange = (e, obj ,no) => {

      if(obj.name === "partnerId"){
        let newArr = [...items];
        newArr[no-1][obj.name] = e.value;
        setItems(newArr);
      }else
      if(obj.name === "roleId"){
        setSelectRoleId(e.value);
        getPermissions(e.value);
      }
    };


    const checkChange = (e,obj,countNumber) => {
      let itCopy = [...items];
      if(e.target.name == "allowViewAll"){
        if(viewAll){
          setViewAll(false);
          itCopy.forEach(e => {
            e.allowView = 0;
            e.allowEdit = 0;
            e.allowDelete = 0;
          });
        }else{
          setViewAll(true);
          itCopy.forEach(e => {
            e.allowView = 1;
          });
        }
      }else{
        if(itCopy[countNumber-1][e.target.name] === 1){
          itCopy[countNumber-1][e.target.name] = 0;
          itCopy[countNumber-1].changed = true;
          if(e.target.name === "allowView"){
            itCopy[countNumber-1].allowEdit = 0;
            itCopy[countNumber-1].allowDelete = 0;
          }
        }else
        if(itCopy[countNumber-1][e.target.name] === 0){
          itCopy[countNumber-1][e.target.name] = 1;
          itCopy[countNumber-1].changed = true;
          if(e.target.name === "allowEdit"){
            itCopy[countNumber-1].allowView = 1;
          }else 
          if(e.target.name === "allowDelete"){
            itCopy[countNumber-1].allowView = 1;
            itCopy[countNumber-1].allowEdit = 1;
          }
        }
      }

      setItems(itCopy);
    }


    const itemSave = () => {
      setDisableSaveBtn(true);
      let updateItems = [];
      items.forEach(e => {
        var contextObj = {
          entity: e,
          status: 2,
          type: "updatePermissions"
        }
        updateItems.push(contextObj);
      });
      apiService().saveChanges(updateItems).then((response) => {
        if (response.success) {
          setDisableSaveBtn(false);
        }else{
          setDisableSaveBtn(false);
        }
      })
    }

    const itemRolesSave = () => { 

      setDisableRolesBtn(true);
      let rolesArr = [...allRoles];
      let insertRole = rolesArr.find((f) => f.id === 0);
      if(insertRole){
        let findSameRoleName = rolesArr.find((f) => f.roleName === insertRole?.roleName )
        if(findSameRoleName.id === 0 && findSameRoleName.roleName !== '' && findSameRoleName.roleName != null){
          apiService().execute('insertRoles', insertRole , 'post')
            .then((response) => {
              if (response.success) {
                let insertedId = response.data[0].id;
  
                let insertPermissions = [];
                modules.forEach(e => {
                  e.roleId = insertedId;
                  e.moduleId = e.value;
                  e.allowView = 1;
                  e.allowEdit=1;
                  e.allowDelete=1;
                  var contextObj = {
                    entity: e,
                    status: 1,
                    type: "insertPermissions"
                  }
                  insertPermissions.push(contextObj);
                });
                apiService().saveChanges(insertPermissions).then((response) => {
                  if (response.success) {
                    setDisableRolesBtn(false);
                    getRoles();
                  }else{
                    setDisableRolesBtn(false);
                  }
                })
              }
        })
  
        }else{
          setDisableRolesBtn(false);
          addToast(helper().translate("DUPLICATE_OF_ROLES_ISNT_ALLOWED"), {
            appearance: "error",
            autoDismiss: true,
          });
        }
      }


      let findDuplicates = Object.values(
        allRoles.reduce((groups, current) => {
          if (!groups[current.roleName]) {
            groups[current.roleName] = [];
          }
          groups[current.roleName].push(current);
          return groups;
        }, {})
      )
    
      let resultDuplicate = findDuplicates.find(f => f.length > 1);
      if(!resultDuplicate){
      let updateRoles = [];
      rolesArr.forEach(e => {
        var contextObj = {
          entity: e,
          status: 2,
          type: "updateRoles"
        }
        updateRoles.push(contextObj);
      });
        apiService().saveChanges(updateRoles).then((response) => {
        if (response.success) {
          setDisableRolesBtn(false);
          getRoles();
        }else{
          setDisableRolesBtn(false);
        }
      })
      }else{
        setDisableRolesBtn(false);
        addToast(helper().translate("DUPLICATE_OF_ROLES_ISNT_ALLOWED"), {
          appearance: "error",
          autoDismiss: true,
        });
      }

    }



    const deleteRow = (id, no) => {
      if(id>0){
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="custom-ui">
              <h1>{helper().translate('ARE_YOU_SURE')}</h1>
              <p>{helper().translate('WANT_TO_DELETE_THIS_ROLE')}</p>
              <button
                onClick={() => {
                  var params = `&roleId=${id}`;
                    apiService()
                    .query("deletePermisions", params)
                    .then((response) => {
                      if(response.success){
                    var params2 = `&id=${id}`;
                    apiService()
                    .query("deleteRoles", params2)
                    .then((response) => {
                      getRoles();
                    });
                      }
                    });
                  
                  onClose();
                }}
              >
                {helper().translate('YES')}
              </button>
              <button onClick={onClose}>{helper().translate('NO')}</button>
            </div>
          );
        },
      });
    }else {
     getRoles();
    }
    }
   
    return (
      <div className="col-lg-8">

        {/* permissions */}
        <div className="container-fluid px-2 mb-2" style={{minHeight: "325px"}}>
          
          <div className="card border-left-primary shadow h-100 " >
              <div className="card-header py-2"><label className="text-xs font-weight-bold text-primary mb-3">{helper().translate("PERMISSIONS")}</label>
                <div className='row'>
                  <div className='col-lg-6 col-6'>
                    <Select
                      name="roleId"
                      placeholder={helper().translate("CHOOSE_ROLE")}
                      value={roles?.find(p => p.value == selectRoleId)}
                      onChange={(e, obj ) => selectChange(e, obj)}
                      options={roles}
                      styles={selectStyles}
                      //className={errors?.partnerId ? 'is-invalid-s': ''}
                    />
                  </div>
                    <div className="col-lg-2 col-2 text-center ps-0">
                  <input 
                  name='allowViewAll'
                  title={helper().translate("SELECT_ALL")}
                  onChange={(e,obj) => checkChange(e,obj)} 
                  value={viewAll?.allowView} 
                  // checked={item?.allowView}  
                  type="checkbox" 
                  className={`bigCheckBox align-middle  ${selectRoleId !== null ? "" : "d-none"}`}>
                  </input>
                  </div>
                </div>
                <div className='d-flex align-items-center justify-content-end'>
                  {/* <button className="btn btn-sm btn-dark btn-transport-save  p-0" style={{width: "75px"}} onClick={() => props.hidePayments()} >{helper().translate('BACK')}</button> */}
                  {/* <button  
                //   disabled={items.filter((f) => f.id == 0)?.length >= 1 ? true : false} 
                  className="btn btn-sm btn-primary btn-transport-save ms-2 p-0" style={{width: "75px"}} onClick={() => addRow()}>{helper().translate('NEW')}</button> */}
                  <button disabled={disableSaveBtn} className="btn btn-sm btn-success btn-transport-save ms-2 p-0" style={{width: "75px"}} onClick={() => itemSave()} >{helper().translate('SAVE')}</button>
                </div>
              </div>
              {loader && <LoaderComponent /> } 
              {!loader ?               <div className="card-body pt-2 pb-0" style={{minHeight: "215px"}}>
              <table  className="table table-hover text-center " >
            <thead>
                <tr>
                  <th scope="col">{helper().translate('NR')}</th>
                  <th scope="col" className='text-left'>{helper().translate('MODULE')}</th>
                  <th scope="col">{helper().translate('ALLOW_VIEW')}</th>
                  <th scope="col">{helper().translate('ALLOW_EDIT')}</th>
                  <th scope="col">{helper().translate('ALLOW_DELETE')}</th>
                </tr>
            </thead>
            <tbody>
            {[...items].map((item, key) => 
              <tr key={key}  >
                <td  className="font-weight-bold col-2 " >{item.countNumber}</td>
     
                  <td   className="font-weight-bold text-left col-4"> <span >{modules?.find(p => p.value == item.moduleId)?.label}</span></td>
                
                  <td className='col-2 '>
                   <div className="flex-fill">
                  <input 
                  name='allowView'
                  onChange={(e,obj) => checkChange(e,obj,item.countNumber)} 
                  value={item?.allowView} 
                  checked={item?.allowView}  
                  type="checkbox" 
                  className=" bigCheckBox align-middle ">
                  </input>
                  </div>
                  </td>

                  <td className='col-2 '>
                   <div className="flex-fill">
                  <input 
                  name='allowEdit'
                  onChange={(e,obj) => checkChange(e,obj,item.countNumber)} 
                  value={item?.allowEdit} 
                  checked={item?.allowEdit}  
                  type="checkbox" 
                  className=" bigCheckBox align-middle ">
                  </input>
                  </div>
                  </td>

                  <td className='col-2 '>
                   <div className="flex-fill">
                  <input 
                  name='allowDelete'
                  onChange={(e,obj) => checkChange(e,obj,item.countNumber)} 
                  value={item?.allowDelete} 
                  checked={item?.allowDelete}  
                  type="checkbox" 
                  className=" bigCheckBox align-middle ">
                  </input>
                  </div>
                  </td>
              </tr>
              )}

              
            </tbody>
            </table>
              </div> : ""}
          </div>

          {/* roles */}
          
        </div>
        <div className="container-fluid px-2 ">
        <div className="card border-left-primary shadow h-100 ">
              <div className="card-header py-2"><label className="text-xs font-weight-bold text-primary mb-3">{helper().translate("ROLES")}</label>
                <div className='d-flex align-items-center justify-content-end'>
                  {/* <button className="btn btn-sm btn-dark btn-transport-save  p-0" style={{width: "75px"}} onClick={() => props.hidePayments()} >{helper().translate('BACK')}</button> */}
                  <button  
                  disabled={allRoles.filter((f) => f.id == 0)?.length >= 1 ? true : false} 
                  className="btn btn-sm btn-primary btn-transport-save ms-2 p-0" style={{width: "75px"}} onClick={() => addRow()}>{helper().translate('NEW')}</button>
                  <button disabled={disableRolesBtn} className="btn btn-sm btn-success btn-transport-save ms-2 p-0" style={{width: "75px"}} onClick={() => itemRolesSave()} >{helper().translate('SAVE')}</button>
                </div>
              </div>
              <div className="card-body pt-2 pb-0">
              <table  className="table table-hover text-center ">
            <thead>
                <tr>
                  <th scope="col">{helper().translate('NR')}</th>
                  <th scope="col" className='text-left'>{helper().translate('ROLE')}</th>
                  <th scope="col" style={{width:'28px'}}>-</th>
                </tr>
            </thead>
            <tbody>
            {[...allRoles].map((item, key) => 
              <tr key={key} onClick={() => rowClick(item.countNumber)} >
                <td  className="font-weight-bold align-middle col-2">{item.countNumber}</td>
                {
                  item.editMode ?
                  <td className='inputDiv font-weight-bold text-left' >    
                  <MDBInput
                  name="roleName"
                  // disabled={!findByDate.dateStartDisabled}
                  className="form-control-sm"
                  onChange={(e) => fieldChange(e,item.countNumber)}
                  type="text"
                  // label={helper().translate("START_DATE")}
                  value={allRoles[item.countNumber-1]?.roleName || ""}
                  /></td>
                  :<td className='text-left font-weight-bold'>{item.roleName}</td>
                }
                 {
                  <td><button disabled={item.id != 1 ? false : true}   onClick={() => deleteRow(item.id , item.countNumber)} className="btn-delete"><span className="icon text-white-40"><i className={`${item.id != 1 ? "fas fa-minus-circle"  : "" }`} ></i></span></button></td>
                }
              </tr>
              )}

              
            </tbody>
            </table>
              </div>
          </div>
          </div>
      </div>
    )
}
export default Permissions