import React , {useContext} from 'react'
import { MDBInput } from 'mdb-react-ui-kit'
import HeaderTools from '../Header/HeaderTools'
import helper from '../../Services/helper'
import UserPermissionContext from '../../components/Context/UserPermissionContext'

const Search = (props) => {
    const {userPermission} = useContext(UserPermissionContext);
    const t = (key) => {
        return helper().translate(key);
    }

    return (
        <>
            <div className={`card border-left-primary shadow h-100 ${ (props.new || props.newHandle ||props.save || props.print || props.excel || props.excelHandle || props.back) ? 'py-0' : 'py-1'}`} style={{minHeight: '0'}}>
                <div className="card-body py-0">
                    <div className="row no-gutters align-items-center">
                        <div className="col mr-2 row">
                            <div className="col-lg-12 form-group my-0 d-flex align-items-center px-0">
                                <div className="d-table-cell no-shadow">
                                <HeaderTools new={props.new ? props.new : null} newHandle={props.newHandle ? props.newHandle : null} save={props.save ? props.save : null} print={props.print ? props.print : null} printClick={props.printClick ? props.printClick : null} excel={props.excel ? props.excel : null} back={props.back ? props.back : null} upload={props.upload ? props.upload : null} importData={props.importData ? props.importData : null} schema={props.schema ? props.schema : null} disableSaveButton={props.disableSaveButton === true || userPermission?.find((f)=> f.moduleName === props.headersFrom )?.allowEdit === 0  ? true : false} />   
                            </div>
                                {(props.search || props.change) &&
                                    <div className="d-table-cell" style={{flex: 1}}>
                                        <MDBInput className="form-control-sm" label={t('SEARCH')+'...'} onChange={props.change} value={props.value} />
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Search
