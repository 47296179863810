import React, { Component } from "react";
import apiService from "../../Services/apiServices";
import Select from "react-select";
import moment from "moment";
import helper from "../../Services/helper";
moment().format();

class RecalculateAvgPrice extends Component {
  constructor(props) {
    super(props);

    this.state = {
      date: moment().locale("en").format("YYYY-MM-DD"),
      items: [],
      itemId: 0,
    };

    this.recalculate = this.recalculate.bind(this);
  }

  componentDidMount() {
    apiService()
      .query("getItems")
      .then((data) => {
        if (data.success) {
          this.setState({ items: data.data });
        } else {
          console.log("Error:" + data.errorMessage);
        }
      });
  }

  handleChange = (itemId) => {
    this.setState({ itemId });
  };
  onFieldChange = (e) => {
    this.setState({
      [e.target.name]:
        e.target.type === "checkbox" ? e.target.checked : e.target.value,
    });
  };

  recalculate = () => {
    var date = this.state.date;
    var itemId =
      this.state.itemId.value === undefined ? 0 : this.state.itemId.value;
    var params = `date=${date}&itemId=${itemId}`;

    if (confirm(helper().translate('ARE_YOU_SURE_YOU_WANT_TO_RECALCULATE_ALL_ITEMS'))) {
      // Save it!
      apiService()
        .query("Recalculate", params)
        .then((response) => {
          if (response.success) {
            alert("THE_RECALCULATION_WAS_DONE_SUCCESSFULLY");
          } else {
            alert("AN_ERROR_OCCURRED" + "Error: " + response.error);
          }
        });
    }
  };

  render() {
    const { items, date } = this.state;
    const stateOptions = items.map((item, index) => ({
      value: item.id,
      label: item.itemName,
    }));
    return (
      <div className="container-fluid px-2 mt-3">
        <div className="card border-left-primary shadow h-100 py-2 ">
          <div className="card-body">
            <h3>{helper().translate("RECALCULATION_OF_THE_COST_PRICE_OF_THE_ITEMS")}</h3>
            <p>
            {helper().translate("SELECT_THE_DATE_AND_CLICK_ON_THE_RECALCULATE_BUTTON_TO_START_THE_RECALCULATION_OF_THE_COST_PRICE_FOR_THE_SOLD_ITEMS")}
            </p>
            <div className="row no-gutters align-items-center">
              <div className="col mr-2 row">
                <div className="col-lg-3 form-group">
                  <label className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                  {helper().translate("DATE")}
                  </label>
                  <input
                    name="date"
                    value={date}
                    className="form-control"
                    onChange={this.onFieldChange}
                    type="date"
                  />
                </div>
                <div className="col-lg-6 form-group">
                  <label className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                  {helper().translate("ARTICLES")}
                  </label>
                  <Select
                    name="itemId"
                    placeholder={helper().translate("CHOOSE")}
                    value={this.state.itemId}
                    onChange={this.handleChange.bind(this)}
                    options={stateOptions}
                  />
                </div>
              </div>
              <div className="col-auto">
                <button className="btn btn-primary" onClick={this.recalculate}>
                 {helper().translate("RECALCULATE")}
                  {/* <i className="fas fa-calculator fa-2x " /> */}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default RecalculateAvgPrice;
