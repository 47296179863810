import React, { useState, useEffect, useRef } from 'react';
import helper from '../../Services/helper';
import apiService from '../../Services/apiServices';
import Select from "react-select";
import PaymentModel from '../../Models/PaymentModel';
import { ToastProvider, useToasts } from 'react-toast-notifications';
import { confirmAlert } from 'react-confirm-alert'; 

const PaymentPurchases = (params) => {
    const { addToast } = useToasts();
    const obj = {
        "paymentId": 0,
        "purchaseId": 0,
        "amountPaid": 0,
        "createdAt": "2021-12-10T23:59:37.690Z",
        "createdBy": "Admin Sejdiu",
        "changedAt": null,
        "changedBy": null,
        "status": 0
    }
    const status = {added: 1, deleted: -1, changed: 2, unchanged: 0};
    const [items, setItems] = useState(params.payments ? params.payments : []);
    const user = params.user
    const [leftAmount, setLeftAmount] = useState((params.purchase.totalSum + params.purchase.vatSum)  - items?.reduce((accumulator, current) => parseFloat(accumulator) + parseFloat(current.amountPaid), 0)); 
    const [payment, setPayment] = useState({amountPaid: leftAmount, partnerId: params.purchase.partnerId})
    const [cashBox, setCashBox] = useState()

    const styles = {
        container: provided => ({
            ...provided,
            Width: '100%'
        }),
        control: (provided, state) => ({
            ...provided,
            background: '#fff',
            borderColor: '#9e9e9e',
            minHeight: '28.59px',
            height: '28.59px',
            boxShadow: state.isFocused ? null : null,
        }),
        menuPortal: base => ({ ...base, zIndex: 9999 }),
        valueContainer: (provided, state) => ({
            ...provided,
            height: '28.59px',
            padding: '0 6px'
        }),

        input: (provided, state) => ({
            ...provided,
            margin: '0px',
        }),
        indicatorSeparator: state => ({
            display: 'none',
        }),
        indicatorsContainer: (provided, state) => ({
            ...provided,
            height: '28.59px',
        }),
    };

    const onRowClick = (no) => {
        let rowItem = [...items]
        rowItem.forEach(i => i.editMode = false);
        rowItem[no-1].editMode = !rowItem[no-1].editMode;
        setItems(rowItem);
    }

    const calculateLeftAmount = (purchase, payments) => {
        let value = (purchase.totalSum + purchase.vatSum)  - payments?.reduce((accumulator, current) => parseFloat(accumulator) + parseFloat(current.amountPaid), 0);
        setLeftAmount(value)
        setPayment({...payment, amountPaid: value})
    }

    const getCashBox = () => {
        apiService().query('cashBox', 'id='+user.cashBoxId)
            .then((response) => {
                if(response.success) {
                    setCashBox(response.data[0])
                }
                else alert('errr')
            })
    }

    const addRow = () => {
        const isTrue = (item) => item.editMode != true;

        if(items.every(isTrue)) {
            let newItem = obj
            newItem.editMode = true
            newItem.no = Math.max(...items.map(o => o.no), 0) + 1
            newItem.cashBoxName = cashBox.name
            newItem.bankName = null
            newItem.amountPaid = leftAmount
            const newState = [...items, newItem];
            setItems(newState)
        }
    }

    const deleteRow = (item) => {
        let rowNo = item.no
        let id = item.paymentId
        let arrayCopy = [...items]

        if(id && item.bankName === null) {
            confirmAlert({
                customUI: ({ onClose }) => {
                return (
                    <div className="custom-ui" >
                    <h1>A jeni te sigurt që doni ta fshini këtë pagesë?</h1>
                    <button
                        onClick={() => {
                            var par = `&id=${item.paymentId}&pId=${item.purchaseId}`;
                            apiService().query("deletePaymentPurchasesFrom_Cash", par)
                                .then((response) => { 
                                    if(response.success) {
                                        responseMessage('info', 'Pagesa u fshi me sukses!') 
                                        arrayCopy = arrayCopy.filter((row) => row.no !== rowNo);
                                        getPayments(params.purchase)
                                    }
                                    else {
                                        responseMessage('error', response.errorMessage ? response.errorMessage : 'Ka ndodhur nje gabim gjate fshirjes pageses!')
                                    }
                                });
                            onClose();
                        }}
                    >
                        {helper().translate('YES')}
                    </button>
                    <button onClick={onClose}>{helper().translate('NO')}</button>
                    </div>
                );
                }
            })
        }
        else if(item.bankName) {
            responseMessage('warning', 'Kjo pagese nuk mund te fshhet!') 
        }
        else if(item.editMode) {
            arrayCopy = arrayCopy.filter((row) => row.no !== rowNo);
        }

        let count = 1;
        arrayCopy.forEach(i => { i.no = count; count++ });
        const newArr = [arrayCopy]
        setItems(arrayCopy)
    }

    const setAmount = (value, obj) => {     
        if (value <= leftAmount && value > 0) {
            setPayment({...payment, amountPaid: value})
            let item = items.find(i => i.no === obj.no);
            item.amountPaid = value
        }
    }

    const getPayments = (obj) => {
        apiService().query('getPaymentPurchases', `purchaseId=${obj.id}`)
            .then((response) => {
                if(response.success) {
                    let records = response.data
                    setItems(records)
                    calculateLeftAmount(obj, records)
                }
            })
    }

    const savePayment = (purchase) => {
        let paymentObj = new PaymentModel();
        paymentObj.credit = payment.amountPaid;
        paymentObj.saldo = paymentObj.debit - paymentObj.credit;
        paymentObj.partnerId = purchase.partnerId;
        paymentObj.paymentType = purchase.paymentMethodId;
        paymentObj.warehouseId = purchase.warehouseId;
        paymentObj.cashBoxId = user.cashBoxId;
        paymentObj.reference = purchase.reference;
        payment.date = new Date();
        apiService().execute('insertPaymentFromPurchase', paymentObj , 'post')
            .then((response) => {
                if(response.success) {
                    savePaymentPurchase(response.data[0].id, paymentObj, purchase)
                }
                else {
                    responseMessage('error', response.errorMessage ? response.errorMessage : 'Ka ndodhur nje gabim gjate regjistrimit te pageses!')
                }
            })
    }

    const savePaymentPurchase = (id,payment, purchase) => {
        let paymentPurchases = {};
        paymentPurchases.paymentId = id;
        paymentPurchases.purchaseId = purchase.id;
        paymentPurchases.amountPaid = payment.credit;
        paymentPurchases.createdAt = new Date();
        paymentPurchases.createdBy = user.name + " " + user.surname;
        apiService().post('insertPaymentPurchases', paymentPurchases)
            .then((response) => {
                console.log(response)
                if(!response.success) {
                    responseMessage('error', response.errorMessage ? response.errorMessage : 'Ka ndodhur nje gabim gjate regjistrimit te detajeve te pageses!')
                }
                else {
                    responseMessage('success', 'Pagesa u ruajt me sukses!')
                    getPayments(purchase)
                }
            })    
    }

    const responseMessage = (appearance, message) => {
        addToast(message, { appearance: appearance, autoDismiss: true, });
    }

    useEffect(() => {
        items.map((item, key) => {
            item.no = key + 1
        })
        //calculateLeftAmount(params.purchase)
        getCashBox()
    }, [])
    return (
        <div>
            <div className='d-flex align-items-center float-right'>
                <button className="btn btn-sm btn-dark btn-transport-save position-relative mr-2 py-0" style={{width: "75px"}} onClick={() => {params.showPayments(), params.getPurchase(params?.purchase.id)}}>{helper().translate('BACK')}</button>
                <button disabled={!leftAmount ? 'disabled' : ''} className="btn btn-sm btn-primary btn-transport-save position-relative mr-2 py-0" style={{width: "75px"}} onClick={() => addRow()}>{helper().translate('NEW')}</button>
                <button className="btn btn-sm btn-success btn-transport-save position-relative mr-2 py-0" style={{width: "75px"}} onClick={() => {savePayment(params.purchase)}}>{helper().translate('SAVE')}</button>
            </div>
            <table style={{ overflowX: 'scroll', tableLayout: 'fixed'}} className="table table-hover text-center sales-table purchase-table transpot-table">
                <thead>
                    <tr>
                        <th>{helper().translate('DATE')}</th>
                        <th>{helper().translate('PAYMENT')}</th>
                        <th>{helper().translate('SUM')}</th>
                        <th>{helper().translate('PARTNER')}</th>
                        <th>{helper().translate('BANK')}</th>
                        <th>{helper().translate('CASHBOX')}</th>
                        {/* <th style={{width:'25px'}}>+</th> */}
                        <th style={{width:'25px'}}>-</th>
                    </tr>
                </thead>
                <tbody>
                    {[...items]?.map((item, key) => (
                            <tr>
                                {   item.editMode ?
                                    // <td><input type="date" value={payment.date ? helper().formatDate(payment.date) : (new Date()).toISOString().substring(0, 10)} onChange={(e) => setPayment({...payment, date: e.target.value})} name="date" className={`form-control form-control-sm'}`}/></td>
                                    <td><span className={`text-center text-dark'} small`}>{helper().formatDate(new Date)}</span></td>
                                    :
                                    <td> <span className={`text-center text-dark'} small`}>{helper().formatDate(item.date)}</span></td>
                                }
                                <td>{item.paymentId}</td>
                                {   item.editMode ?
                                    <td><input type="number" value={payment.amountPaid} onChange={(e) => setAmount(e.target.value, item)}  name="amountPaid" className={`form-control form-control-sm text-center`}/></td>
                                    :
                                    <td> <span className={`text-center text-dark'} small`}>{item.amountPaid}</span></td>
                                }
                                {   item.editMode ?
                                    <td>
                                        <Select
                                            name="partnerId"
                                            placeholder="Zgjidh Partnerin"
                                            value={params.partners ? params.partners.find(v => v.value == payment?.partnerId) : ''}
                                            onChange={(e) => setPayment({ ...payment, partnerId: e.value })}
                                            options={params.partners}
                                            styles={styles}
                                            //className={errors?.partnerId ? 'is-invalid-s': ''}
                                        />
                                    </td>
                                    :
                                    <td> <span className={`text-center text-dark'} small`}>{item.partner}</span></td>
                                }

                                <td>{item.bankName ? item.bankName : '-'}</td>
                                <td>{item.cashBoxName ? item.cashBoxName : '-'}</td>

                                {/* <td><button className="btn-edit" onClick={() => addRow()}><span className="icon text-white-40"><i className="fas fa-plus-circle"></i></span></button></td> */}
                                
                                {
                                    item.bankName === null ? <td><button onClick={() => deleteRow(item)} className="btn-delete"><span className="icon text-white-40"><i className="fas fa-minus-circle"></i></span></button></td> : <td></td>
                                }
                            </tr>
                        ))
                    }    
                    <tr>
                        <td colspan="2" className="border-bottom-0"></td>    
                        <td className="font-weight-bold"><small>{helper().translate('PAID_SUM')}</small><br></br>{items?.reduce((accumulator, current) => parseFloat(accumulator) + parseFloat(current.amountPaid), 0)}</td> 
                    </tr>   
                </tbody>
            </table>
        </div>
    )
}

export default PaymentPurchases
