import React, { useEffect,useState , useContext } from 'react';
import helper from '../Services/helper';
import apiService from '../Services/apiServices';
import globals from '../Services/globals';
import ChartSales from '../PageComponents/UI/Charts/ChartSales';
import ChartPurchasesSales from '../PageComponents/UI/Charts/ChartPurchasesSales';
import ChartPayments from '../PageComponents/UI/Charts/ChartPayments';
import ChartExpenses from '../PageComponents/UI/Charts/ChartExpenses';
import { MDBInput } from 'mdb-react-ui-kit';
import "./Dashboard.css"
import { set } from 'react-hook-form';
import UserPermissionContext from '../components/Context/UserPermissionContext';

const Dashboard = (props) => {
  const [sales, setSales] = useState();
  const [purchases, setPurchases] = useState();
  const [payments, setPayments] = useState();
  const [expensesChart, setExpensesChart] = useState(0.0);
  const {userPermission} = useContext(UserPermissionContext);
  const [note, setNote] = useState('');
  const [notes, setNotes] = useState();
  const d = new Date();
  let month = d.getMonth();
  var user = globals().getLogedInUser();


  const getNotes = () => {
    apiService().get('notes')
    .then((response) => {
      setNotes(response.data);
    })
  }
const saveNote = () => {
if(note != ''){
  var insertNotes = {notes:note,createdAt:d,createdBy:user.data.username}
  apiService().post('notes',insertNotes).then((response)=>
  {
    if(response.success)
    {
      getNotes();
      setNote('');
    }
    else
    {
      console.log(response.error)
    }
  });
}
}
  const getPurchases = () => {
    apiService().get('purchaseChart')
        .then((response) => {
          const actualMonth = response.data?.find(p => p.monthNumber === month + 1);
          const lastMonth = response.data?.find(p => p.monthNumber === month);
          const percentDifference = ((actualMonth?.total - lastMonth?.total) / lastMonth?.total) * 100.00;
          //console.log({total:actualMonth,percent:percentDifference,isDown:percentDifference.toString().includes("-")});
          setPurchases({total:actualMonth?.total,percent:percentDifference,isDown:percentDifference.toString().includes("-")});
        })
}
const getSales = () => {
    apiService().get('saleChart')
        .then((response) => {
          const actualMonth = response.data?.find(p => p.monthNumber === month + 1);
          const lastMonth = response.data?.find(p => p.monthNumber === month);
          const percentDifference = ((actualMonth?.total - lastMonth?.total) / lastMonth?.total) * 100.00;
          //console.log({total:actualMonth,percent:percentDifference,isDown:percentDifference.toString().includes("-")});
          setSales({total:actualMonth?.total,percent:percentDifference,isDown:percentDifference.toString().includes("-")});
        })
}

const getPayments = () => {
  apiService().get('paymentSaleChart')
      .then((response) => {
        const actualMonth = response.data?.find(p => p.monthNumber === month + 1);
        const lastMonth = response.data?.find(p => p.monthNumber === month);
        const percentDifference = ((actualMonth?.total - lastMonth?.total) / lastMonth?.total) * 100.00;
        //console.log({total:actualMonth,percent:percentDifference,isDown:percentDifference.toString().includes("-")});
        setPayments({total:actualMonth?.total,percent:percentDifference,isDown:percentDifference.toString().includes("-")});
      })
}

const getExpensesChart = () => {
  apiService().get('getExpensesChart')
      .then((response) => {
        const actualMonth = response.data?.find(p => p.monthNumber === month + 1);
        const lastMonth = response.data?.find(p => p.monthNumber === month);
        const percentDifference = ((actualMonth?.total - lastMonth?.total) / lastMonth?.total) * 100.00;
        console.log({total:actualMonth,percent:percentDifference,isDown:percentDifference.toString().includes("-")});
        setExpensesChart({total:actualMonth?.total,percent:percentDifference,isDown:percentDifference.toString().includes("-")});
      })
}

useEffect(() => {
  document.getElementById('navi')?.classList.add("nav-mobile")

  // returned function will be called on component unmount 
  return () => {
    document.getElementById('navi')?.classList.remove("nav-mobile")
  }
}, [])
useEffect(() => {
  getSales();
  getPurchases();
  getPayments();
  getExpensesChart();
  //getNotes();
  // getPermissions();
}, [])
  return (
    <>
    <div className="container-fluid " style={{ "marginTop": 80 }}>
      <div className="header-body">
        <div className="row">
          <div className="col-xl-3 col-lg-6">
            <div className="card card-stats mb-4 mb-xl-0">
              <a href={userPermission?.find((f)=> f.moduleName === 'PURCHASES')?.allowView ? "#/purchases" : "#/"}  className='text-decoration-none text-dark'>
                <div className="card-body">
                  <div className="row">
                    <div className="col">
                      <h5 className="card-title text-uppercase text-muted mb-0">
                        {helper().translate('PURCHASES')}
                      </h5>
                      <span className="h3 font-weight-bold mb-0">{helper().formatNumber(parseFloat(purchases?.total) )}</span>
                    </div>
                    <div className="col-auto">
                      <div className="icondash icondash-shape bg-danger text-white rounded-circle">
                      <i className="fas fa-shopping-basket fa-2x" />
                    </div>
                    </div>
                  </div>
                  <p className="mt-3 mb-0 text-muted text-sm">
                    <span className={`text-${purchases?.isDown ? "danger" : "success"} mr-2`}>
                      <i className={`fa fa-arrow-${purchases?.isDown ? "down" : "up"}`} /> {helper().formatNumber(parseFloat(purchases?.percent))}%
                    </span>
                    <span className="text-nowrap">{helper().translate('SINCELAST_MONTH')}</span>
                  </p>
                </div>
              </a>
            </div>
          </div>
          <div className="col-xl-3 col-lg-6">
            <div className="card card-stats mb-4 mb-xl-0 " >
              <a  href={userPermission?.find((f)=> f.moduleName === 'SALES')?.allowView ? "#/sales" : "#/"} className='text-decoration-none text-dark ' >
                <div className="card-body">
                  <div className="row">
                    <div className="col">
                      <h5 className="card-title text-uppercase text-muted mb-0">
                        {helper().translate('SALES')}
                      </h5>
                      <span className="h3 font-weight-bold mb-0">{helper().formatNumber(parseFloat(sales?.total) )}</span>
                    </div>
                    <div className="col-auto">
                      <div className="icondash icondash-shape bg-primary text-white rounded-circle shadow">
                      <i className="fas fa-shopping-cart fa-2x" />
                    </div>
                    </div>
                  </div>
                  <p className="mt-3 mb-0 text-muted text-sm">
                  <span className={`text-${sales?.isDown ? "danger" : "success"} mr-2`}>
                      <i className={`fa fa-arrow-${sales?.isDown ? "down" : "up"}`} /> {helper().formatNumber(parseFloat(sales?.percent))}%
                    </span>
                    <span className="text-nowrap">{helper().translate('SINCELAST_MONTH')}</span>
                  </p>
                </div>
              </a>
            </div>
          </div>
          <div className="col-xl-3 col-lg-6">
            <div className="card card-stats mb-4 mb-xl-0">
              <a href={userPermission?.find((f)=> f.moduleName === 'PAYMENTS')?.allowView ? "#/payments/cash" : "#/"}  className='text-decoration-none text-dark'>
                <div className="card-body">
                  <div className="row">
                    <div className="col">
                      <h5 className="card-title text-uppercase text-muted mb-0">
                        {helper().translate('PAYMENTS')}
                      </h5>
                      <span className="h3 font-weight-bold mb-0">{helper().formatNumber(parseFloat(payments?.total) )}</span>
                    </div>
                    <div className="col-auto">
                      <div className="icondash icondash-shape bg-warning text-white rounded-circle shadow">
                      <i className="fas fa-money-bill fa-2x" />
                    </div>
                    </div>
                  </div>
                  <p className="mt-3 mb-0 text-muted text-sm">
                  <span className={`text-${payments?.isDown ? "danger" : "success"} mr-2`}>
                      <i className={`fa fa-arrow-${payments?.isDown ? "down" : "up"}`} /> {helper().formatNumber(parseFloat(payments?.percent))}%
                    </span>
                    <span className="text-nowrap">{helper().translate('SINCELAST_MONTH')}</span>
                  </p>
                </div>
              </a>
            </div>
          </div>
          <div className="col-xl-3 col-lg-6">
            <div className="card card-stats mb-4 mb-xl-0 shadow">
              <a href="#/expenses" className='text-decoration-none text-dark'>
              <div className="card-body">
                  <div className="row">
                    <div className="col">
                      <h5 className="card-title text-uppercase text-muted mb-0">
                        {helper().translate('EXPENSES')}
                      </h5>
                      <span className="h3 font-weight-bold mb-0">{helper().formatNumber(parseFloat(expensesChart?.total) )}</span>
                    </div>
                    <div className="col-auto">
                     <div className="icondash icondash-shape bg-success text-white rounded-circle shadow">
                      <i className="fas fa-chart-bar fa-2x " />
                    </div>
                    </div>
                  </div>
                  <p className="mt-3 mb-0 text-muted text-sm">
                  <span className={`text-${payments?.isDown ? "danger" : "success"} mr-2`}>
                      <i className={`fa fa-arrow-${payments?.isDown ? "down" : "up"}`} />{helper().formatNumber(parseFloat(payments?.percent))}%
                    </span>
                    <span className="text-nowrap">{helper().translate('SINCELAST_MONTH')}</span>
                  </p>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="container-fluid " style={{ "marginTop": 40 }}>
      <div className="header-body">
        <div className="row">
          <div className="col-xl-6 col-lg-6">
            <div className="card card-stats mb-4 mb-xl-0">
              <div className="card-body">
                <div className="row">
                  <div className="col">
                    <h5 className="card-title text-uppercase text-muted mb-0">
                      {helper().translate('PURCHASES_SALES')}
                    </h5>
                  </div>
                  <div className="col-auto">
                  </div>
                </div>
                <ChartPurchasesSales />
              </div>
            </div>
          </div>
          <div className="col-xl-6 col-lg-6">
            <div className="card card-stats mb-4 mb-xl-0 ">
              <div className="card-body">
                <div className="row">
                  <div className="col">
                    <h5 className="card-title text-muted mb-0">
                      {helper().translate('PAYMENTS')}
                    </h5>
                  </div>
                </div>
                <ChartPayments />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
  )
}

export default Dashboard
