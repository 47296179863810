class Bank {
    constructor(obj){
        if(obj){
            this.id = obj.id;
            this.name = obj.name; 
            this.sId = obj.sId; 
            this.account = obj.account; 
            this.type = obj.type; 
            this.orderNo = obj.orderNo; 
            this.accountId = obj.accountId; 
            this.createdBy = obj.createdBy; 
            this.changedAt = obj.changedAt; 
            this.changedBy = obj.changedBy; 
            this.status = obj.status;
        }
        else {
            this.id = 0;
            this.name = ""; 
            this.sId = null; 
            this.account = ""; 
            this.type = ""; 
            this.orderNo = null; 
            this.accountId = 0; 
            this.createdBy = ""; 
            this.changedAt =""; 
            this.changedBy =""; 
            this.status = 0
        }
    }
}


export default Bank;