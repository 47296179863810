
export default function calculate() {

  function calculateSale(type) {

    // me 3 barazime
    if (type === 'totalPrice') {
      return (
        (price, quantity) => {
          var tPrice = price * quantity;
          return (tPrice)
        }
      )
    } else if (type === 'vatSum') {
      return (
        (totalprice, vat) => {
          var vatSum = totalprice * (vat / 100);
          return (vatSum)
        }
      )
    } else if (type === 'vatSumTvshDiscount') {
      return (
        (totalpriceTvsh, totalPrice) => {
          var vatSum = totalpriceTvsh - totalPrice;
          return (vatSum)
        }
      )
    }
    else if (type === 'totalPriceTvsh') {
      return (
        (totalprice, vat) => {
          let vatPercentage = (vat / 100) + 1;
          let totalpricetvsh = (totalprice) * (vatPercentage);
          return (totalpricetvsh)
        }
      )
    } else if (type === 'priceTvsh') {
      return (
        (price, vat) => {
          var priceTvsh = price * ((vat / 100) + 1);
          return (priceTvsh)
        }
      )
    } else if (type === 'price') {
      return (
        (priceTvsh, vat) => {
          var price = priceTvsh / (1 + (vat / 100));
          return (price)
        }
      )
    } else if (type === 'priceFromTotal') {
      return (
        (total, quantity) => {
          var price = total / quantity;
          return (price)
        }
      )
    }
    else if (type === 'priceDiscount') {
      return (
        (price, vat) => {
          if (vat > 0) {
            var priceDiscount = price * (1 - (vat / 100));
          } else {
            var priceDiscount = 0;
          }
          return (priceDiscount)
        }
      )
    } else if (type === 'priceFromDiscount') {
      return (
        (priceDiscount, discount) => {
          if (discount > 0) {
            var price = priceDiscount / (1 - (discount / 100));
          } else {
            var price = 0;
          }
          return (price)
        }
      )
    }
    else if (type === 'priceDiscountTvsh') {
      return (
        (priceTvsh, discount, vat) => {
          var price = (priceTvsh / (1 - (discount / 100))) / (1 + (vat / 100));
          return (price)
        }
      )
    } else if (type === 'totalPriceItemTvsh') {
      return (
        (totalPriceTvsh, quantity) => {
          var totalPriceItemTvsh = totalPriceTvsh / quantity;
          return (totalPriceItemTvsh)
        }
      )
    }

  }

  const calculateSalePrice = (obj, value) => {
    obj.salePriceWithVat = value ? parseFloat(parseFloat(value) + parseFloat((obj.vat / 100 * value))).toFixed(6) : 0;

    obj.markup = value ? (parseFloat(obj.salePrice) - parseFloat(obj.price_discount)) * 100 / obj.price_discount : 0
    obj.vatSum = parseFloat(obj.salePriceWithVat) - parseFloat(obj.salePrice);

    return obj
  }

  const calculateSalePriceWithVat = (obj, value) => {
    obj.salePrice = parseFloat(parseFloat(value) - parseFloat((obj.vat / 100 * obj.salePrice))).toFixed(6);
    obj.markup = (parseFloat(obj.salePrice) - parseFloat(obj.price_discount)) * 100 / obj.price_discount

    return obj
  }

  const calculatePrice = (obj, value) => {
    obj.price_discount = parseFloat(obj.price ? parseFloat(value) - parseFloat((obj.discount / 100 * obj.price)) : 0).toFixed(6);
    obj.salePrice = parseFloat(parseFloat(obj.price_discount) + parseFloat((obj.price_discount * obj.markup / 100))).toFixed(6)
    obj.salePriceWithVat = parseFloat(parseFloat(obj.salePrice) + parseFloat((obj.vat / 100 * obj.salePrice))).toFixed(6);
    // obj.vatSum = parseFloat(obj.salePriceWithVat) - parseFloat(obj.salePrice);

    obj.priceWithVat = parseFloat(parseFloat(obj.price) * (1 + parseFloat(obj.vat) / 100)).toFixed(6);
    //obj.vatSum = parseFloat(obj.quantity) * (parseFloat(obj.priceWithVat) - parseFloat(obj.price));
    return obj
  }

  const calculatePriceDiscount = (obj, value) => {
    obj.price = parseFloat(value) + parseFloat((obj.discount / 100 * value))

    obj.salePrice = parseFloat(parseFloat(value) + parseFloat((value * obj.markup / 100))).toFixed(6)
    obj.salePriceWithVat = parseFloat(parseFloat(obj.salePrice) + parseFloat((obj.vat / 100 * obj.salePrice))).toFixed(6);

    obj.vatSum = parseFloat(obj.salePriceWithVat) - parseFloat(obj.salePrice);

    return obj
  }

  const calculateDiscount = (obj, value) => {
    obj.price_discount = parseFloat(parseFloat(obj.price) - parseFloat((obj.price * value / 100))).toFixed(6)
    obj.salePrice = parseFloat(parseFloat(obj.price_discount) + parseFloat((obj.price_discount * obj.markup / 100))).toFixed(6)
    obj.salePriceWithVat = parseFloat(parseFloat(obj.salePrice) + parseFloat((obj.vat / 100 * obj.salePrice))).toFixed(6);

    obj.vatSum = parseFloat(obj.salePriceWithVat) - parseFloat(obj.salePrice);

    return obj
  }

  const calculateMarkup = (obj, value) => {
    obj.salePrice = parseFloat(parseFloat(obj.price_discount) + parseFloat((obj.price_discount * value / 100))).toFixed(6)
    obj.salePriceWithVat = parseFloat(parseFloat(obj.salePrice) + parseFloat((obj.vat / 100 * obj.salePrice))).toFixed(6);

    obj.vatSum = parseFloat(obj.salePriceWithVat) - parseFloat(obj.salePrice);

    return obj
  }


  const onColumnChange = (columnName, activeRow) =>{

    if (activeRow) {

      //switch (columnName.toLowerCase()) {
      switch (columnName) {
        case "discount":
          activeRow.discountPrice = activeRow.price * (1 - activeRow.discount / 100); 
          activeRow.priceWithVat = activeRow.discountPrice  * ((activeRow.vat / 100) + 1) ;  
                
          break;        
        case "price":
          if(activeRow.discount>0){
            activeRow.discountPrice = calculate().calculateSale('priceDiscount')(activeRow.price, activeRow.discount);
            activeRow.priceWithVat = calculate().calculateSale('priceTvsh')(activeRow.discountPrice ,activeRow.vat);
        
            }else{
              activeRow.priceWithVat = calculate().calculateSale('priceTvsh')(activeRow.price ,activeRow.vat);
              activeRow.discountPrice = calculate().calculateSale('priceDiscount')(activeRow.price, activeRow.discount);
            }         
          break;
        case "priceWithVat":
          if(activeRow.discount>0){
            activeRow.price = calculate().calculateSale('priceDiscountTvsh')(activeRow.priceWithVat ,activeRow.discount ,activeRow.vat);
            activeRow.discountPrice = calculate().calculateSale('priceDiscount')(activeRow.price , activeRow.discount);
          }else{
            activeRow.price = calculate().calculateSale('price')(activeRow.priceWithVat ,activeRow.vat);
          }
          break;
        case "discountPrice":       
          if(activeRow.discount > 0){
            activeRow.priceWithVat = calculate().calculateSale('priceTvsh')(activeRow.discountPrice ,activeRow.vat);
            activeRow.price = calculate().calculateSale('priceDiscountTvsh')(activeRow.priceWithVat ,activeRow.discount ,activeRow.vat);
          }
          break;
        case "markup":
          activeRow.retailPrice = activeRow.discountPrice * (1 + activeRow.markup / 100);
          activeRow.retailPriceWithVat = activeRow.retailPrice * (1 + activeRow.vat / 100);      
          break;
        case "retailPriceWithVat":       
          activeRow.retailPrice = activeRow.retailPriceWithVat / (1 + vat / 100);         
          activeRow.purchasePriceWithVat = activeRow. discountPrice * (1 + vat / 100);
          break;
        case "retailPrice":
          activeRow.retailPriceWithVat = activeRow.retailPrice * (1 + activeRow.vat / 100);         
        break;
        case "subTotal":
          activeRow.purchasePriceWithVat = (activeRow.quantity!==0)?  activeRow.discountPrice / activeRow.quantity : 0.0;       
          break;
          case "vat":
          if(activeRow.discount>0){
            activeRow.discountPrice = calculate().calculateSale('priceDiscount')(activeRow.price, activeRow.discount);
             activeRow.priceWithVat = calculate().calculateSale('priceTvsh')(activeRow.discountPrice ,activeRow.vat);
            }else{
               activeRow.priceWithVat = calculate().calculateSale('priceTvsh')(activeRow.price ,activeRow.vat);
            }
                
          break; 

        default: break;
      }

      calculatePurchaseColumns(activeRow,columnName);

    }
  }

  const calculatePurchaseColumns = (activeRow,columnName) => {
    try {
      //readonly columns
      columnName != "priceWithVat" ? activeRow.priceWithVat = activeRow.price * (1 + activeRow.vat / 100.00): "";
      columnName != "discountPrice" ? activeRow.discountPrice = activeRow.price * (1 - activeRow.discount/100.00): "";
      activeRow.subTotal = activeRow.quantity * activeRow.discountPrice;
      activeRow.purchasePrice = activeRow.discountPrice;
      activeRow.vatSum = activeRow.subTotal * activeRow.vat / 100.00;
      activeRow.totalWithVat = activeRow.subTotal  + activeRow.vatSum;
    }
    catch (ex) {
      alert("Gabim:" + ex.message);
    }
   
  }

  const onColumnChangeImport = (columnName, activeRow) =>{

    if (activeRow) {

      //switch (columnName.toLowerCase()) {
      switch (columnName) {
        case "discount":
          activeRow.discountPrice = activeRow.price * (1 - activeRow.discount / 100);          
          break;        
        case "price":
          activeRow.discountPrice = activeRow.price * (1 - activeRow.discount / 100);          
          break;
        case "discountPrice":       
          activeRow.price = activeRow.discountPrice / (1 - activeRow.discount / 100);         
          break;
        case "markup":
          activeRow.retailPrice = activeRow.discountPrice * (1 + activeRow.markup / 100);
          activeRow.retailPriceWithVat = activeRow.retailPrice * (1 + activeRow.vat / 100);      
          break;
        case "retailPriceWithVat":       
          activeRow.retailPrice = activeRow.retailPriceWithVat / (1 + vat / 100);         
          activeRow.purchasePriceWithVat = activeRow. discountPrice * (1 + vat / 100);
          break;
        case "retailPrice":
          activeRow.retailPriceWithVat = activeRow.retailPrice * (1 + activeRow.vat / 100);         
         break;
        case "subTotal":
          activeRow.purchasePriceWithVat = (activeRow.quantity!==0)?  activeRow.discountPrice / activeRow.quantity : 0.0;       
          break;

        case "transport":
          activeRow.transportPercent = (activeRow.subTotal!==0)? activeRow.transport/activeRow.subTotal * 100 : 0.0;       
          break;

        case "overvalue":
          activeRow.overvaluePercent = (activeRow.subTotal!==0)? activeRow.overvalue/activeRow.subTotal * 100 : 0.0;       
          break;

        case "excise":
          activeRow.excisePercent = (activeRow.subTotal!==0)? activeRow.excise/activeRow.subTotal * 100 : 0.0;       
          break;

        case "duty":
          activeRow.dutyPercent = (activeRow.subTotal + activeRow.transport + activeRow.overvalue !== 0)? activeRow.duty/(activeRow.subTotal + activeRow.transport + activeRow.overvalue )  * 100: 0.0;
          break;

        default: break;
      }

      calculateImportColumns(activeRow, columnName);

    }
  }

  //it was deleted before so registration of goods couldnt calculate  
  const registrationOfGoods = (obj, isImport, prop) => {
    obj.price = prop === 'price_discount' ? parseFloat(parseFloat(obj.price_discount) + parseFloat((obj.discount/100 * obj.price_discount))) : obj.price; 
    obj.price = prop === 'priceWithVat' ? parseFloat(parseFloat(obj.priceWithVat) / (1 + parseFloat(obj.vat)/100)) : obj.price
    
    obj.price_discount = prop === 'total' ? parseFloat(parseFloat(obj.total/obj.quantity) / (1 + parseFloat(obj.vat)/100)) : obj.price_discount;
    obj.price = prop === 'total' ? parseFloat(parseFloat(obj.price_discount) + parseFloat((obj.discount/100 * obj.price_discount))) : obj.price; 
    
    obj.priceWithVat = prop !== 'priceWithVat' ? parseFloat(parseFloat(obj.price) * (1 + parseFloat(obj.vat)/100)) : obj.priceWithVat
    obj.price_discount = (prop !== 'total' && prop !== 'price_discount' && prop !== 'markup' && prop !== 'salePrice' && prop !== 'salePriceWithVat') ? parseFloat(obj.price ? parseFloat(obj.price) - parseFloat((obj.discount/100 *  obj.price)) : 0) : obj.price_discount;
    obj.salePrice = prop !== 'salePrice' ? prop !== 'salePriceWithVat' ? parseFloat(parseFloat(obj.price_discount) + parseFloat((obj.price_discount*obj.markup/100))) : parseFloat(parseFloat(obj.salePriceWithVat) - parseFloat((obj.vat/100 * obj.salePrice))) : obj.salePrice;
    obj.salePriceWithVat =  prop !== 'salePriceWithVat' ? parseFloat(parseFloat(obj.salePrice) + parseFloat((obj.vat/100 * obj.salePrice))) : obj.salePriceWithVat;
    obj.markup = prop !== 'markup' ? obj.price_discount ? parseFloat((parseFloat(obj.salePrice) - parseFloat(obj.price_discount)) * 100 / obj.price_discount) : 0 : obj.markup;
    // obj.vatSum = parseFloat(obj.quantity) * (parseFloat(obj.salePriceWithVat) - parseFloat(obj.salePrice));
    obj.vatSum = parseFloat(obj.quantity) * (parseFloat(obj.priceWithVat) - parseFloat(obj.price));
    obj.subTotal = parseFloat(obj.quantity) * parseFloat(obj.price_discount);
    obj.total = prop !== 'total' ? obj.subTotal + obj.vatSum : obj.total;   
    
    Object.entries(obj).forEach(([key, value]) => {
      if(value === 'NaN') {
        obj[key] = 0
      }
    });

    if(isImport) {
      importCalculate(obj, prop)
    }
    else {
      obj.price = prop !== 'price' ? parseFloat(obj.price).toFixed(6) : obj.price;
      obj.price_discount = prop !== 'price_discount' ? parseFloat(obj.price_discount).toFixed(6) : obj.price_discount;
      obj.priceWithVat = prop !== 'priceWithVat' ? parseFloat(obj.priceWithVat).toFixed(6) : obj.priceWithVat;
      obj.salePrice = prop !== 'salePrice' ? parseFloat(obj.salePrice).toFixed(6) : obj.salePrice;
      obj.salePriceWithVat = prop !== 'salePriceWithVat' ? parseFloat(obj.salePriceWithVat).toFixed(6) : obj.salePriceWithVat;
      obj.markup = prop !== 'markup' ? parseFloat(obj.markup).toFixed(6) : obj.markups;
      obj.vatSum = parseFloat(obj.vatSum).toFixed(2);
      obj.subTotal = parseFloat(obj.subTotal).toFixed(2);
      obj.total = prop !== 'total' ? parseFloat(obj.total).toFixed(2) : obj.total;
      return obj
    }
  }

  const calculateImportPercents = (activeRow) =>{
        
    activeRow.discountPrice = activeRow.price * (1 - activeRow.discount/100);
    activeRow.subTotal = activeRow.discountPrice * activeRow.quantity;
    
    //main columns:
    // id	quantity price	discount	markup	duty	transport	overValue	excise	purchasePrice	vat	vatSum	
  
    activeRow.transportPercent = activeRow.transport/ activeRow.subTotal * 100;
    activeRow.overvaluePercent = activeRow.overvalue/ activeRow.subTotal * 100;
    activeRow.dutyPercent = activeRow.duty/(activeRow.subTotal + activeRow.transport + activeRow.overvalue) * 100;
    activeRow.excisePercent = activeRow.excise/ activeRow.subTotal * 100;

    calculateImportColumns(activeRow);
  }

  const calculateImportColumns = (activeRow, columnName) => {
    
    try {
      //readonly columns
      activeRow.retailPriceWithVat = activeRow.retailPrice * (1 + activeRow.vat / 100.00);
      
      activeRow.subTotal = activeRow.quantity * activeRow.discountPrice;
      if(columnName !== "transport") activeRow.transport = activeRow.subTotal * activeRow.transportPercent / 100;
      activeRow.totalWithTransport = parseFloat(activeRow.subTotal) + parseFloat(activeRow.transport);

      if(columnName !== "overvalue") activeRow.overvalue = activeRow.subTotal * activeRow.overvaluePercent / 100;
      activeRow.totalWithTransportAndOvervalue = parseFloat(activeRow.subTotal) + parseFloat(activeRow.transport) + parseFloat(activeRow.overvalue);

      if(columnName !== "duty") activeRow.duty =  activeRow.totalWithTransportAndOvervalue * activeRow.dutyPercent / 100;
      if(columnName !== "excise") activeRow.excise = activeRow.subTotal * activeRow.excisePercent / 100;
      
      activeRow.totalWithTransportAndOvervalueAndDuty = parseFloat(activeRow.subTotal) + parseFloat(activeRow.transport) + parseFloat(activeRow.overvalue) + parseFloat(activeRow.duty);
      activeRow.totalWithTransportAndOvervalueAndDutyExcise = parseFloat(activeRow.subTotal) + parseFloat(activeRow.transport) + parseFloat(activeRow.overvalue) + parseFloat(activeRow.duty) + parseFloat(activeRow.excise);
      
      //purchase price = subtotal + transport + duty + excise/ quantity
      activeRow.purchasePrice = (activeRow.quantity && activeRow.quantity !==0) ?
      (parseFloat(activeRow.subTotal) + parseFloat(activeRow.transport) + parseFloat(activeRow.duty) + parseFloat(activeRow.excise))/parseFloat(activeRow.quantity) : 0.0;
      
      activeRow.vatSum = parseFloat(activeRow.totalWithTransportAndOvervalueAndDutyExcise * activeRow.vat/100);
      activeRow.totalWithVat = parseFloat(activeRow.totalWithTransportAndOvervalueAndDutyExcise) + parseFloat(activeRow.vatSum);

    }
    catch (ex) {
      alert("Gabim:" + ex.message);
    }
  }

  return {
    calculateSale: calculateSale,
    calculatePrice: calculatePrice,
    calculatePriceDiscount: calculatePriceDiscount,
    calculateDiscount: calculateDiscount,
    calculateMarkup: calculateMarkup,
    calculateSalePrice: calculateSalePrice,
    calculateSalePriceWithVat: calculateSalePriceWithVat,
    registrationOfGoods: registrationOfGoods,

    calculatePurchaseColumns: calculatePurchaseColumns,
    onColumnChange : onColumnChange,
    //importCalculate: importCalculate,
    
    calculateImportColumns : calculateImportColumns,
    onColumnChangeImport : onColumnChangeImport,
    calculateImportPercents: calculateImportPercents

  }
}