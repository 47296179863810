import axios from 'axios';
import config from '../config/config';
import helper from './helper';

export default function loginService() {

    const useAuthCode = (authCode) => {

        var baseUrl = config.baseUrl + 'mylogins/mapi';
        return axios.get(baseUrl + `/login/useauthcode/${authCode}`).then(response => {
            return response.data;
        });
    }
       
    async function queryMyLogins(queryName, params) {
    //addAuthorizationHeader();
    var mylogins = config?.baseUrl + "mylogins/mapi";
    return await axios
        .get(mylogins + `/query/${queryName}?${params}`)
        .then((response) => {
        //console.log(response.data);
        return response.data;
        });
    }

    async function putMyLogins(tableName, obj) {
        var mylogins = config?.baseUrl + "mylogins/mapi";
        return await axios.put(mylogins + `/${tableName}`, obj).then((response) => {
          //console.log(response.data);
          return response.data;
        });
    }

    const logout = () => {
        //delete cokie if any
        helper().deleteItem('logedInUser');        
        helper().deleteItem('settings');
        helper().deleteItem('userSettings');
        helper().deleteItem('rememberMe');
       
        window.location = config.homepage;
    }

    return {
        logout: logout,
        useAuthCode : useAuthCode,
        queryMyLogins: queryMyLogins,
        putMyLogins: putMyLogins
        
    }
}
