import React from 'react'

const TabContent = ({title, body, active, show}) => {
    return (
        <>
            <div className={`tab-pane fade ${active ? 'active show' : ''}`} 
                id={'tar_'+title.replace(/\s+/g, '')} 
                role="tabpanel" 
                aria-labelledby={'id_'+title.replace(/\s+/g, '')} 
            >
                <div className="card o-hidden border-0 shadow-lg">
                    <div className="card-body p-0">
                        {show ? body : ''}
                    </div>
                </div>
            </div>
        </>
    )
}

export default TabContent
