import React, { Component } from 'react'
import Select, { components } from 'react-select'
import CreatableSelect from 'react-select/creatable';
import apiService from '../../Services/apiServices'
import { ToastProvider, useToasts } from 'react-toast-notifications'
import mdlItem from '../../Models/Item'
import Switch from "react-switch";
import { MDBInput } from 'mdb-react-ui-kit';
import helper from '../../Services/helper';
 
function withToast(Component) {
    return function WrappedComponent(props) {
        const toastFuncs = useToasts() 
        return <Component {...props} {...toastFuncs} />;
    }
}

class ItemForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            articleType: '',
            units : [],
            categories: [],
            producers: [],
            itemsOrigin: [],
            accounts: [],
            vatLevel: [],
            item: new mdlItem(),
            itemId: props ? props.props?.match?.params.id : 0,
            errors: new mdlItem(),
            itemExist: false
        };

        this.customStyles = {
            control: (base, state) => ({
                ...base,
                borderColor: state.isFocused ? "#bac8f3 !important" : "#d1d3e2",
                boxShadow: state.isFocused ? "0 0 0 0.2rem rgb(78 115 223 / 25%)" : "",
            }),
            control: (provided, state) => ({
                ...provided,
                background: '#fff',
                borderColor: '#9e9e9e',
                minHeight: '28.59px',
                height: '28.59px',
                boxShadow: state.isFocused ? null : null,
              }),
          
              valueContainer: (provided, state) => ({
                ...provided,
                height: '28.59px',
                padding: '0 6px'
              }),
          
              input: (provided, state) => ({
                ...provided,
                margin: '0px',
              }),
              indicatorSeparator: state => ({
                display: 'none',
              }),
              indicatorsContainer: (provided, state) => ({
                ...provided,
                height: '28.59px',
              }),
              menuPortal: base => ({ ...base, zIndex: 9999 })
        };

        //this.handleChange = this.handleChange.bind(this);
        this.responseMessage = this.responseMessage.bind(this);
        this.save = this.save.bind(this);
        this.newItem = this.newItem.bind(this);

        this.articleTypes = [
            { value: 1, label: 'Artikull mall', name: "itemGoods" },
            { value: 2, label: 'Shërbim', name:"service" },
            { value: 3, label: 'Shpenzim', name:"expense" },
            { value: 4, label: 'Asete', name: "inventoryItem" },
            { value: 5, label: 'Lëndë e parë', name: "material" },
            { value: 6, label: 'Artikull i montuar', name: "assembled" },
            { value: 7, label: 'Prodhim', name: "production" }
          ];

        this.accountsGroup = [
            {id: 1, first: 110, second: 120} //stoqet
        ]
    }

    componentDidMount() {
        // alert('called....')
        //apiService endpoint i datav
        this.renderComponent()
        //window.addEventListener('popstate', alert('changed'));
    }

    componentDidUpdate(prevProps) {
        if(this.props != prevProps)
        {
            this.setArticleType()
        }
      } 

    renderComponent() {
        const tables = ['units', 'categories', 'producers', 'itemsOrigin', 'accounts', 'vatLevel'];
        tables.forEach(table => {
            this.getDefaults(table)
        })
          
        if(this.state.itemId && this.state.itemId != 0) {
            let itemFromParent = this.props.item
            if(Object.keys(itemFromParent).length !== 0) {
                this.setState({
                    item: itemFromParent,
                });
            }
            else {
                this.getItem('item', 'id', this.state.itemId) 
            }
              
        } 
 
        this.props.save(() => this.save(this.state.itemId ? false : true));
        this.props.newHandle(() => this.newItem())

        if(!this.state.itemId){
            this.setArticleType();
        }
        
    }

    setArticleType() {
        let itemFromParent = this.props.item
        let hash = this.props?.props?.location?.hash.substring(1);
        let at = this.props.props.articleType

        if(Object.keys(itemFromParent).length !== 0) {
            itemFromParent.vat = itemFromParent.vat !== '' ? itemFromParent.vat : 18
            itemFromParent.price = itemFromParent.price ? itemFromParent.price : itemFromParent.retailPrice*(1+ itemFromParent.vat/100)
            itemFromParent.retailPrice = itemFromParent.retailPrice ? itemFromParent.retailPrice : 0.00
            if(at){
                itemFromParent[this.articleTypes.find(art=> art.value === at).name] = true
                this.setState({
                    item: itemFromParent,
                });
               this.getAccountsByGroup(this.accountsGroup.find(item => item.id === at))
            }
            else if(hash){
                itemFromParent[hash] = true
                this.setState({
                    item: itemFromParent,
                });
                
                this.setState({
                    articleType: this.articleTypes.find(at=> at.name === hash).value,
                });
                this.getAccountsByGroup(this.accountsGroup.find(item => item.id === this.articleTypes.find(at=> at.name === hash).value))
            }
        }
        else {
            
            if(at){
                this.setState({
                    item: new mdlItem(),
                }); 
                this.setState({
                    item: {
                        ...new mdlItem(),
                        [this.articleTypes.find(art=> art.value === at).name]: true,
                    }
                });
                this.getAccountsByGroup(this.accountsGroup.find(item => item.id === at))
            }
            else {
                if(hash){
                    this.setState({
                        item: new mdlItem(),
                    }); 
                    this.setState({
                        item: {
                            ...new mdlItem(),
                            [hash]: true,
                        }
                    });
                    
                    
                    this.setState({
                        articleType: this.articleTypes?.find(at=> at.name === hash)?.value,
                    });
                    this.getAccountsByGroup(this.accountsGroup.find(item => item.id === this.articleTypes.find(at=> at.name === hash).value))
                }
            }
        }
    }

    getAccountsByGroup(group) {
        if(group) {
            var params = `firstNo=${group.first}&secondNo=${group.second}`;
            apiService().query('getAccountsByGroup', params)
                .then((response) => {
                    if (response.success) {
                        var records = response.data.map(record => ({ value: record.id, label: record.name  }));
                        this.setState({
                            accounts: records
                        });
                    } else {
                        console.log('Error:' + response.errorMessage);
                    }
                })
        }
        
    }

    getDefaults(table) {
        apiService()
            .get(table)
                .then((response) => {
                    if (response.success) {
                        var records = response.data;
                        if(table === "vatLevel") {
                            records = response.data.filter(vl => vl.active === true).map(record => ({value: record.value, label: record.value}))
                        }
                        else {
                            records = response.data.map(record => ({ value: (table != 'vatLevel') ? record.id : record.value, label: (table != 'vatLevel') ? record.name : record.value  }));
                        }
                        if(table === 'units' || table === 'categories' || table === 'producers' || table === 'itemsOrigin') records = response.data.map(record => ({ value: record.typeId, label:  record.name  }));
                        
                        this.setState({
                            [table]: records
                        });
                    } else {
                        console.log('Error:' + response.errorMessage);
                    }
                });
    }

    
    async getItem(queryName, paramName, paramValue) {
        await apiService()
            .query(queryName, paramName+'='+paramValue)
                .then((response) => {
                    if (response.success) {
                        this.setState({
                            item: new mdlItem(response.data[0]),
                        });
                        let item = response.data[0];
                        // let at = this.props.props.articleType
                        // if(at) {
                        //     item.
                        // }
                        item = this.setAccounts(item)
                        let articleType = this.articleTypes.find(type => item[type.name] == true)
                        this.props.parentCallback(articleType ? articleType.name : '', item);  
                    } else {
                        console.log('Error:' + response.errorMessage);
                    }
                });  
    }

    onFieldChange = (e, action) => {
        if(e) {
            let objItem = this.state.item;
            if(action != undefined) {          
                if(action.name == 'articleType') {
                    this.articleTypes.forEach(type => {
                        if(e.value == type.value) {
                            objItem[type.name] = true;
                            this.setState(objItem);
                        }
                        else {
                            objItem[type.name] = false;
                            this.setState(objItem);
                        }
                    });
                    this.setState({
                        articleType : e.value
                    })
                    this.props.parentCallback(e.name, objItem)
                }
                else {
                    if(action.action === "create-option"){
                        var referenceObj = {id: 0, name: action.option.value, type: action.name != 'origine' ? action.name.substring(0, action.name.length - 2) : action.name, duration: 0}
                            apiService().post('referenceData', referenceObj)
                                .then((response)=>{
                                    this.setState({
                                        item: {
                                            ...this.state.item,
                                            [action.name]: response.data.id,
                                        }
                                    });
                                    this.getDefaults(action.name == 'categoryId' ? 'categories' : 'units')
                                });
                    }
                    this.setState({
                        item: {
                            ...this.state.item,
                            //[action.name]: (action.name == 'vat') ? e.value+1 : e.value,
                            [action.name]: e.value
                        }
                    });
                    if(action.name == 'vat') {
                        this.calculatePrice(action.name, e.value, e.value, true)
                    }
                }
            }
            else {
                if(e.target.type === 'checkbox'){
                    objItem[e.target.name] = e.target.checked;
                    this.setState(objItem);
                }

                if(e.target.name == 'price' || e.target.name == 'retailPrice') {
                    this.calculatePrice(e.target.name, e.target.value, objItem.vat, false)
                }

                this.setState({
                    item: {
                        ...objItem,
                        [e.target.name]:
                        e.target.type === 'checkbox' ? e.target.checked : e.target.value,
                    }
                }); 
            }
        }
    };

    componentWillUpdate(nextProps, nextState){
        // console.log('will up: ', this.state.item, nextState)
        if(nextState.item !== this.state.item) {
            let objItem = nextState.item
            objItem = this.setAccounts(objItem)
            let articleType = this.articleTypes.find(type => objItem[type.name] == true)
            this.props.parentCallback(articleType ? articleType.name : '', objItem);
        }
        // else {
        //     let objItem = this.state.item
        //     this.props.parentCallback(this.articleTypes.find(type => objItem[type.name] == true), objItem);
        // }
        
    }
    // componentDidUpdate(prevProps, prevState){
    //     // console.log('did up: ', prevProps, prevState)
    //     if(prevState !== this.state.item) {
    //         let objItem = this.state.item
    //         this.props.parentCallback(this.articleTypes.find(type => objItem[type.name] == true), objItem);
    //     }
    // }

    setAccounts(objItem) {
        objItem.accountA = objItem.accountA ? this.props.item.accountA ? this.props.item.accountA : objItem.accountA : 0
        objItem.accountB = objItem.accountB ? this.props.item.accountB ? this.props.item.accountB : objItem.accountB : 0
        objItem.accountC = objItem.accountC ? this.props.item.accountC ? this.props.item.accountC : objItem.accountC : 0 
        
        return objItem;
    }

    calculatePrice(name, value, vat, vatChange) {
        let item = this.state.item;
        if(vatChange) {
            var retailPrice = this.state.item.price / (1 + vat/100);
            //this.state.item.retailPrice = retailPrice;
            item['retailPrice'] = retailPrice;
            item['vat'] = vat;
            this.setState({
                item: item
            });
        }
        else{
            if(name === 'price'){
                var retailPrice = value / (1 + vat/100);
                //this.state.item.retailPrice = retailPrice;
                item['retailPrice'] = retailPrice;
                item['price'] = value;
                this.setState({
                    item: item
                });
            }
            else if(name === 'retailPrice'){
                var price = value*(1+ vat/100);
                //this.state.item.price = price;
                item['price'] = price;
                item['retailPrice'] = value;
                this.setState({
                    item: item
                });
            }
        }
        
        // this.setState({
        //     item: {
        //         ...this.state.item,
        //         [name]: value,
        //     }
        // });
    }

    toFixed2 = (name, value) =>{
        let elem = document.getElementsByName(name);
        if (elem[0] === document.activeElement) {
            return value;
        } else {
            return parseFloat(value).toFixed(2)
        }
    }

    handleBlur = (e) => {
        let elem = document.getElementsByName(e.target.name);
        elem[0].value = parseFloat(elem[0].value).toFixed(2);
    }

    onSwitch = (value, name) => {
        this.setState({
            item: {
                ...this.state.item,
                'expired': value,
            }
        });
    }

    async validate(st) {
       // let item = this.state.item;
        let item = new mdlItem();
        item = Object.assign(item, this.state.item);

        let errors = {};
        let articleType = this.state.articleType;
        // let fieldsToValidate = ['itemName', 'productNo', 'barcode', 'unitId', 'categoryId', articleType !== 2 ? 'accountA' :'', articleType !== 3 ? 'accountB': '', articleType !== 3 ? 'accountC':'']
        let fieldsToValidate = ['itemName', 'productNo', 'barcode', 'unitId', 'categoryId']
        let fields = fieldsToValidate.filter(function (el) {
            return el != "";
          });
        // var inputs, i;
        // inputs = document.querySelectorAll('[name="categoryId"]');// document.getElementsByTagName('input');
        // console.log('inputs: ', inputs)
        // for (i = 0; i < inputs.length; ++i) {
        //     console.log('name: ', inputs[i].name)
        // }

        for await (const [key, val] of Object.entries(item)) {
            if(fields.includes(key)){
                if(key == 'barcode' || key == 'productNo'){
                    if(val != ''){
                        let propertyName = key.charAt(0).toUpperCase() + key.slice(1)
                        let checkItem = await apiService()
                            .query('getItemBy'+propertyName, key+'='+val)
                                .then((response) => {
                                    if (response.success && response.data.length > 0) {
                                        if(st === false) {
                                            if(response.data[0].Id != this.state.itemId){
                                                return propertyName+" exist";
                                            }
                                            else {
                                                return '';
                                            }
                                        }
                                        else {
                                            return propertyName+" exist";
                                        }
                                    }
                                    else {
                                        return '';
                                    }
                                });
                            errors[key] = checkItem;
                            this.setState({
                                errors: errors
                            });
                    }
                    else {
                        errors[key] = '';
                        this.setState({
                            errors: errors
                        });
                    } 
                }
                else {
                    if(!val) {
                        errors[key] = "Cannot be blank";
                        this.setState({
                            errors: errors
                        });
                    }
                    else {
                        errors[key] = '';
                        this.setState({
                            errors: errors
                        });
                    }
                }
                
            }
        }

        if(Object.values(this.state.errors).every(x => x === null || x === '')) { 
            return true
        }
        else {
            return false
        }
    }

    newItem = () => {
        let articleType = this.articleTypes.find(type => this.state.item[type.name] == true);
        this.setState({
            item: new mdlItem(),
        }); 
        window.location.href = `#/item#${articleType?.name ? articleType?.name : 'itemGoods'}` 
    }

    save = (st) => {        
        let item = new mdlItem();
        item = Object.assign(item, this.state.item);

        let that = this;
        this.validate(st).then(function(result) {
            if(result){
                if(!st){
                    //that.props.updateChanges(st, that.props.props.fromPurchase);
                    apiService().put('items', item)
                    .then((response)=>{
                        if(response.success){
                            that.props.addToast( 'Updated Successfully', { appearance: 'success', autoDismiss: true, });
                        }
                    });
                }
                else{
                    //that.props.saveChanges(st, that.props.props.fromPurchase)
                    apiService().post('items', item)
                    .then((response)=>{
                        that.responseMessage(response, st)
                    });
                }
            }
        });
    }

    responseMessage(response, st){
        let that = this;
        if(response.success){
            that.props.addToast( st ? 'Saved Successfully' : 'Updated Successfully', { appearance: 'success', autoDismiss: true, });
            // this.state.itemId = response.data.id;
            if(st) {
                this.setState({
                    itemId: response.data.id
                });
            }
            !that.props.props.fromPurchase ? window.location.href = '#/edit_item/'+response.data.id : ''
            that.props.props.fromPurchase ? that.props.parentCallback("", response.data) : ''
        }
        else{
            that.props.addToast('Ka ndodhur nje gabim!', { appearance: 'error', autoDismiss: true, });
        }
    }

    render() {
        let barcodeNotShowing = [2, 3];
        let articleType;
        if(this.state.itemId) {
            articleType = this.articleTypes.find(type => this.state.item[type.name] == true);
            articleType = articleType != undefined ? articleType.value : 1;
            
        }
        else { 
            articleType = this.state.articleType 
        }
        //this.state.item.price = this.state.item.retailPrice*(1+ this.state.item.vat/100);
        let errors = this.state.errors;
          
        return (
            <div className="card o-hidden border-0 shadow-lg">
                    <div className="card-body p-0">
                        <div className="row">
                            {/* <div className="col-lg-6 d-none d-lg-block bg-light"></div> */}
                            <form className="user">
                                <div className="col-md-12 col-lg-12 col-xl-12 mb-2 d-md-flex d-lg-flex d-xl-flex px-2 px-md-0">
                                    <div className="col-md-6 px-0 px-md-3 py-3 py-md-5 mx-auto overflow-hidden">
                                        <div className="col-md-12 col-lg-12 col-xl-12 mb-3 px-0">
                                            {/* <label htmlFor="inputProductNo" className="m-0 text-12 px-1">Tipi i artikullit</label> */}
                                            <Select
                                                className="react-select"
                                                onChange={this.onFieldChange}
                                                options={this.articleTypes}
                                                required
                                                placeholder="Tipi i artikullit"
                                                name="articleType"
                                                value={this.props.props.articleType ? this.articleTypes.find(type => type.value === this.props.props.articleType) : this.articleTypes.find(type => this.state.item[type.name] == true)}
                                                styles={this.customStyles}
                                            />
                                            {/* <label htmlFor="inputCipher" className="m-0 text-12 px-1">Tipi i artikullit</label> */}
                                        </div>
                                        <div className="col-md-12 col-lg-12 col-xl-12 mb-3 px-0">
                                            {/* <label htmlFor="inputProductNo" className="m-0 text-12 px-1">Shifra</label> */}
                                            <MDBInput type="text" required name="productNo" onChange={this.onFieldChange} className={`form-control-sm text-black px-3 ${errors.productNo ? 'is-invalid': ''}`} id="inputProductNo" label={helper().translate("PRODUCT_NUMBER")} value={this.state.item.productNo ? this.state.item.productNo : ''} />
                                            {errors.productNo && <span className='error'>{errors.productNo}</span>}
                                        </div>
                                        <div className="col-md-12 col-lg-12 col-xl-12 mb-3 px-0 d-flex align-items-center">
                                            {!barcodeNotShowing.includes(articleType) &&
                                                <div className="col-md-12 px-0">
                                                    {/* <label htmlFor="inputBarcode" className="m-0 text-12 px-1">Barkodi</label> */}
                                                    <MDBInput type="text" name="barcode" onChange={this.onFieldChange} className={`form-control-sm text-black px-3 ${errors.barcode ? 'is-invalid': ''}`} id="inputBarcode" label={helper().translate("BARCODE")} value={this.state.item.barcode ? this.state.item.barcode : ''}/>
                                                    {errors.barcode && <span className='error'>{errors.barcode}</span>}
                                                </div>
                                            }
                                            {articleType == 3 && 
                                                <div className="col-md-12 px-0">
                                                    {/* <label htmlFor="inputContoNr" className="m-0 text-12 px-1">Nr. Kontos</label> */}
                                                    <MDBInput type="text" name="contoNr" onChange={this.onFieldChange} className="form-control-sm text-black" id="inputContoNr" label={helper().translate("ACCOUNT_NUMBER")} />
                                                </div>
                                            }
                                            
                                            {/* <div className="col-md-2">
                                                <MDBInput type="checkbox" className="mt-4" id="barcodeCheck" />
                                            </div> */}
                                        </div>
                                        <div className="col-md-12 col-lg-12 col-xl-12 mb-3 px-0">
                                            {/* <label htmlFor="inputItemName" className="m-0 text-12 px-1">Emri i artikullit</label> */}
                                            <MDBInput type="text" name="itemName" onChange={this.onFieldChange} className={`form-control-sm text-black ${errors.itemName ? 'is-invalid': ''}`} id="inputItemName" label={helper().translate("ITEMNAME")} value={this.state.item.itemName ? this.state.item.itemName : ''} />
                                            {errors.itemName && <span className='error'>{errors.itemName}</span>}
                                        </div>                                      
                                        <div className="col-md-12 col-lg-12 col-xl-12 mb-3 px-0">
                                            {/* <label htmlFor="inputItemName" className="m-0 text-12 px-1">Kategoria</label> */}
                                            <CreatableSelect
                                                onChange={this.onFieldChange}
                                                isClearable
                                                options={this.state.categories}
                                                placeholder={helper().translate("CATEGORIES")}
                                                name="categoryId"
                                                value={this.state.categories.filter(category => category.value == this.state.item.categoryId)}
                                                styles={this.customStyles}
                                                className={errors.categoryId ? 'is-invalid-s': ''}
                                            />
                                            {errors.categoryId !== 0 && <span className='error'>{errors.categoryId}</span>}
                                        </div>
                                        <div className="col-md-12 col-lg-12 col-xl-12 mb-3 px-0">
                                            {/* <label htmlFor="inputItemName" className="m-0 text-12 px-1">Njësia matëse</label> */}
                                            <CreatableSelect
                                                onChange={this.onFieldChange}
                                                isClearable
                                                // defaultValue = {this.props.units[1]}
                                                options={this.state.units}
                                                placeholder={helper().translate("UNIT")}
                                                name="unitId"
                                                value={this.state.units.find(unit => unit.value == this.state.item.unitId)}
                                                //getOptionLabel={ x => x.label}
                                                //getOptionValue={ x => x.value}
                                                styles={this.customStyles}
                                                className={errors.unitId ? 'is-invalid-s': ''}
                                            />
                                            {errors.unitId !==0 && <span className='error'>{errors.unitId}</span>}
                                        </div>
                                        {articleType == 1 && 
                                            <div className="col-md-12 col-lg-12 col-xl-12 mb-3 px-0">
                                                {/* <label htmlFor="producerId" className="m-0 text-12 px-1">Prodhuesi</label> */}
                                                <CreatableSelect
                                                    onChange={this.onFieldChange}
                                                    isClearable
                                                    value = {this.state.producers.find(producer => producer.value == this.state.item.producerId)}
                                                    options={this.state.producers}
                                                    placeholder={helper().translate("MANUFACTURER")}
                                                    name="producerId"
                                                    styles={this.customStyles}
                                                />
                                            </div>
                                        }
                                        {articleType != 3 && 
                                            <div className="col-md-12 col-lg-12 col-xl-12 mb-3 px-0">
                                                {/* <label htmlFor="origine" className="m-0 text-12 px-1">Origjina</label> */}
                                                <CreatableSelect
                                                    onChange={this.onFieldChange}
                                                    isClearable
                                                    options={this.state.itemsOrigin}
                                                    placeholder={helper().translate("ORIGIN")}
                                                    name="origine"
                                                    styles={this.customStyles}
                                                />
                                            </div>
                                        }
                                        <div className="col-md-12 col-lg-12 col-xl-12 mb-3 px-0 mt-3"> 
                                            <label className="mr-3">
                                            {helper().translate("EXPIRED")}
                                            </label>
                                            <Switch  
                                                id="expired"  
                                                className="text-light"                       
                                                onColor="#86d3ff"
                                                onHandleColor="#2693e6"
                                                handleDiameter={25}
                                                width={65}
                                                height={25}
                                                uncheckedIcon={<span style={{display:'flex', margin: '-2px 0 0 -5px'}}>Jo</span>}
                                                checkedIcon={<span style={{display:'flex', margin: '-2px 0 0 -5px'}}>Po</span>}
                                                padding="7px 0px"
                                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                                onChange={this.onSwitch} 
                                                checked={this.state.item.expired}
                                            />
                                        </div>
                                        <div className="col-md-12 col-lg-12 col-xl-12 mb-3 px-0">
                                            {/* <label htmlFor="inputComent" className="m-0 text-12 px-1">Përshkrimi</label> */}
                                            <MDBInput textarea className="form-control-sm text-black" style={{height: 'auto'}} name="description" onChange={this.onFieldChange} id="inputComent" rows="3" label={helper().translate("DESCRIPTION")} value={this.state.item.description ? this.state.item.description : ''}/>
                                        </div>
                                    </div>
                                    <div className="col-md-6 px-md-3 px-0 pt-3 py-md-5 mx-auto">
                                        {/* {articleType !== 2 && 
                                            <div className="col-md-12 col-lg-12 col-xl-12 mb-3 px-0">
                                                <Select
                                                    menuPortalTarget={ !this.props.props.fromPurchase ? document.body : ''} 
                                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                    onChange={this.onFieldChange}
                                                    placeholder={articleType != 3 ? 'Malli' : 'Konto e shpenzimeve'}
                                                    name="accountA"
                                                    options={this.state.accounts}
                                                    value = {this.state.accounts.find(account => account.value == this.state.item.accountA)}
                                                    styles={this.customStyles}
                                                    className={errors.accountA != '' ? 'is-invalid-s': ''}
                                                />
                                                {errors.accountA != '' && <span className='error'>{errors.accountA}</span>}
                                            </div>
                                        }
                                        {articleType !== 3 &&
                                            <>
                                                <div className="col-md-12 col-lg-12 col-xl-12 mb-3 px-0">
                                                    <Select
                                                        menuPortalTarget={ !this.props.props.fromPurchase ? document.body : ''}  
                                                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                        onChange={this.onFieldChange}
                                                        options={this.state.accounts}
                                                        value = {this.state.accounts.find(account => account.value == this.state.item.accountB)}
                                                        placeholder={articleType != 3 ? 'Kosto e shitjes se mallrave' : ''}
                                                        name="accountB"
                                                        styles={this.customStyles}
                                                        className={errors.accountB != '' ? 'is-invalid-s': ''}
                                                    />
                                                    {errors.accountB != '' && <span className='error'>{errors.accountB}</span>}
                                                </div>
                                            
                                                <div className="col-md-12 col-lg-12 col-xl-12 mb-3 px-0">
                                                    <Select
                                                        menuPortalTarget={ !this.props.props.fromPurchase ? document.body : ''}  
                                                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                        onChange={this.onFieldChange}
                                                        options={this.state.accounts}
                                                        value = {this.state.accounts.find(account => account.value == this.state.item.accountC)}
                                                        placeholder="Te hyrat nga shitja e mallrave me TVSH"
                                                        name="accountC"
                                                        styles={this.customStyles}
                                                        className={errors.accountC != '' ? 'is-invalid-s': ''}
                                                    />
                                                    {errors.accountC != '' && <span className='error'>{errors.accountC}</span>}
                                                </div>
                                            </>
                                        } */}
                                        <div className="col-md-12 col-lg-12 col-xl-12 mb-3 px-0">
                                            {/* <label htmlFor="inputComent" className="m-0 text-12 px-1">TVSH (%)</label> */}
                                            <Select
                                                menuPortalTarget={ !this.props.props.fromPurchase ? document.body : ''} 
                                                onChange={this.onFieldChange}
                                                options={this.state.vatLevel}
                                                value = {this.state.vatLevel.find(vat => vat.value == this.state.item.vat)}
                                                placeholder={helper().translate("VAT") + " %"}
                                                name="vat"
                                                styles={this.customStyles}
                                            />
                                        </div>
                                        {articleType !== 3 && 
                                            <>
                                                <div className="col-md-12 col-lg-12 col-xl-12 mb-3 px-0">
                                                    {/* <label htmlFor="" className="m-0 text-12 px-1">Çmimi i shitjes me TVSH (€)</label> */}
                                                    <div className="input-group input-group-sm price-input">
                                                        <div className="input-group-prepend" style={{ width: "50px"}}>
                                                            <span className="input-group-text w-100 text-center d-block h-100">€</span>
                                                        </div>
                                                        <MDBInput type="number" className="form-control-sm text-black m-0" name="price" value={this.toFixed2('price', this.state.item.price ? this.state.item.price : 0)} onBlur={(e) => this.handleBlur(e)} onChange={this.onFieldChange} label={helper().translate("SALEPRICE_WITH_VAT")} aria-label="Amount (to the nearest dollar)" />
                                                        
                                                        {/* <div className="input-group-append">
                                                            <span className="input-group-text">.00</span>
                                                        </div> */}
                                                    </div>
                                                </div>
                                                <div className="col-md-12 col-lg-12 col-xl-12 mb-3 px-0">
                                                    {/* <label htmlFor="" className="m-0 text-12 px-1">Çmimi njësi i shitjes (€)</label> */}
                                                    <div className="input-group input-group-sm price-input">
                                                        <div className="input-group-prepend" style={{ width: "50px"}}>
                                                            <span className="input-group-text w-100 text-center d-block h-100">€</span>
                                                        </div>
                                                        <MDBInput type="number" className="form-control-sm text-black m-0" name="retailPrice" value={this.toFixed2('retailPrice', this.state.item.retailPrice ? this.state.item.retailPrice : 0)} onBlur={(e) => this.handleBlur(e)} onChange={this.onFieldChange} label={helper().translate("UNIT_SALES_PRICE")}aria-label="Amount (to the nearest dollar)" />
                                                        {/* <div className="input-group-append">
                                                            <span className="input-group-text">.00</span>
                                                        </div> */}
                                                    </div>
                                                </div>
                                            </>
                                        }
                                    </div>
                                    <div className="col-md-4"></div>
                                </div>
                                {/* <div className="col-md-12 col-lg-12 col-xl-12 mb-3 overflow-hidden px-3 px-md-4">
                                    <button type="button" onClick={() => this.save(this.state.itemId ? false : true)} className="btn btn-primary float-right">{this.state.itemId ? 'Update' : 'Save'}</button>
                                </div> */}
                            </form>
                            
                        </div>
                    </div>
                </div>
        )
    }
}

export default withToast(ItemForm)
// /export default ItemForm
