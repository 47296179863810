import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from "react-router-dom";
import apiService from '../../../Services/apiServices';
import helper from '../../../Services/helper';
import Select , {components} from "react-select";
import { ToastProvider, useToasts } from 'react-toast-notifications';
import { confirmAlert } from 'react-confirm-alert'; 
import globals from "../../../Services/globals";


function PaymentSales(props)  { 
  
  const [items , setItems] = useState([]);
  const [defaultAccount , setDefaultAccount] = useState(props.defaultAccount);
  const [partners, setPartners] = useState(props.partners);
  const [amountLeft, setAmountLeft] = useState();
  const [totalPayment, setTotalPayment] = useState();
  const [cashBox, setCashBox] = useState([]);
  const [disableSaveBtn, setDisableSaveBtn] = useState(false);
  const {addToast} = useToasts();
  const history = useHistory();

    useEffect(()=>{
      getPaymentSales(props.saleId);
      getCashBoxes();
    },[])

    
  const multipleItemStyle = {
    container: provided => ({
      ...provided,
      minWidth: '190px'
    }),
    control: (provided, state) => ({
      ...provided,
      background: "#fff",
      borderColor: "#9e9e9e",
      minHeight: "28.59px",
      height: "28.59px",
      boxShadow: state.isFocused ? null : null,
    }),
    menu: (provided, state) => ({
     ...provided,
     width: "auto"
    //  marginTop: "30px"
   }),
    menuList: (provided, state) => ({
     ...provided,
     width: "auto"
   }),
  
    valueContainer: (provided, state) => ({...provided,height: "28.59px",padding: "0 6px",}),
    input: (provided, state) => ({ ...provided, margin: "0px",}), indicatorSeparator: (state) => ({ display: "none", }),
    indicatorsContainer: (provided, state) => ({...provided,height: "28.59px",}),
  };

    const getPaymentSales = (saleId, saveYes) => {
      apiService().query('getPaymentSales', "saleId="+saleId).then((response) => {
        if(response.success){
          var data = response.data;
          var count = 1;
          data.forEach(i=> {i.no = count; count++});
          data.forEach(i=> {i.editMode = false});
          let totalPayment = 0;
          data.forEach(i=> {totalPayment = totalPayment +  parseFloat(i.amountPaid)});
          let amountLeft = (parseFloat(props.sale.totalSum) - parseFloat(totalPayment)).toFixed(2);
          // console.log(parseFloat(props.sale.totalSum));
          // console.log(parseFloat(totalPayment));
          // console.log(amountLeft);
          setTotalPayment(totalPayment);
          setAmountLeft(amountLeft);
          setItems(data);
          if(amountLeft <= 0.01 && saveYes === true){
            props.hidePayments();
          }
        }
      })
    }

    const getCashBoxes = () => {
      let options;
      apiService()
        .query("getCashBoxes")
        .then((response) => {
          if (response.success) {
            options = response.data.map((item, index) => ({
              value: item.id,
              label: item.name
            }));
            setCashBox(options);
          } else {
            alert("Error: getting CashBox" + response.errorMessage);
          }
        });
    };

    const addRow = () => {
      let itemCopy = [...items].filter( i => i.editMode === true);

      if(itemCopy.length === 0 ){
        
        let obj = {
          no:Math.max(...items.map(o => o.no), 0) + 1 ,
          paymentId : 0,
          amountPaid: amountLeft,
          createdAt: "",
          createdBy: globals().getLogedInUser().data.username,
          date: (new Date()).toISOString().substring(0, 10) + ' ' + new Date().getHours() + ':' + new Date().getMinutes() + ':' + new Date().getSeconds(),
          description: '',
          warehouseId: props.sale.warehouseId,
          userId:globals().getLogedInUser().data.id,
          bankId:0,
          reference: '',
          partnerId : props.sale.partnerId,
          paymentType: 3,
          cashBoxId: props.cashBoxId,
          projectId: 1,//me e rregullu qysh duhet
          accountId: defaultAccount.defaultCashAccount,
          debit: amountLeft,
          credit: 0,
          bankName: null,
          saldo:amountLeft,
          editMode: true,
          insertItem: true
        }
        const newState = [...items, obj];
        setItems(newState);
      }

      setDisableSaveBtn(false);
    };

    const fieldChange = (e,no) => {
      if(e.target.name === "amountPaid"){
        if(parseFloat(e.target.value)  <= parseFloat(amountLeft) ){
          
          let newArr = [...items]; 
          newArr[no-1][e.target.name] = parseFloat(e.target.value);
          newArr[no-1].debit = parseFloat(e.target.value);
          newArr[no-1].saldo = parseFloat(e.target.value);
          setItems(newArr);
        }else{
          addToast(helper().translate('PAYMENT_SUM_SHOULDNT_BE_BIGGER_THAN_PAYMENT_AMOUNT'), {
            appearance: "error",
            autoDismiss: true,
          });
        }
        }
      
    };

    const selectChange = (e, obj ,no) => {

      if(obj.name === "partnerId"){
        let newArr = [...items];
        newArr[no-1][obj.name] = e.value;
        setItems(newArr);
      }
    };

    const partnerOption = props => {
      return (
        <components.Option {...props}>
          <div className="d-flex text-truncate"><p style={{width: "200px"}} className="m-0 text-left ">{props.data.label}</p>|<p style={{width: "150px"}} className="m-0 text-center ">{props.data.fiscalNo}</p>|<p style={{width: "150px"}} className="m-0 text-center ">{props.data.cityName}</p></div>
        </components.Option>
      );
    };

    const itemSave = () => {

      setDisableSaveBtn(true);
      let length = items.length -1;
      let insertPayment = [...items][length];
      if(insertPayment.insertItem === true) {
        apiService().execute('insertPaymentFromSale', insertPayment , 'post')
        .then((response) => {
          if(response.success){
            let paymentId = response.data[0].id;
            let insertPaymentSales = {paymentId: paymentId , saleId: props.saleId , amountPaid: insertPayment.debit , createdBy: insertPayment.createdBy };
            apiService().execute('insertPaymentSales', insertPaymentSales , 'post')
            .then((response) => {
              if(response.success){
                getPaymentSales(props.saleId , true);
                setDisableSaveBtn(false);
                
              }else{
                setDisableSaveBtn(false);
              }
            })
          }
        })
      }
    }
    const deleteRow = (id, no) => {
      if(id>0){
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="custom-ui">
              <h1>{helper().translate('ARE_YOU_SURE')}</h1>
              <p>{helper().translate('WANT_TO_DELETE_THIS_PAYMENT')}</p>
              <button
                onClick={() => {
                  var params = `&id=${id}&sId=${props.sale.id}`;
                    apiService()
                    .query("deletePaymentSaleFrom_Cash-Bank", params)
                    .then((response) => {
                      if (response.success) {
                        let newItem = [...items];
                        let arr = newItem.filter((row) => row.no !== no);
                        var num = 1;
                        arr.forEach(i=> {i.no = num; num++});
                        addToast("Fshirja u be me sukses", {
                          appearance: "success",
                          autoDismiss: true,
                        });
                        setItems(arr);
                        getPaymentSales(props.saleId);
                      } else {
                        alert("Error:" + response.errorMessage);
                      }
                    });
                  
                  onClose();
                }}
              >
                {helper().translate('YES')}
              </button>
              <button onClick={onClose}>{helper().translate('NO')}</button>
            </div>
          );
        },
      });
    }else {
      let newItem = [...items];
      let arr = newItem.filter((row) => row.no !== no);
      var num = 1;
      arr.forEach(i=> {i.no = num; num++});
      setItems(arr);
};
    }
   
    return (
      <div>
        <div className="container-fluid px-2 mt-3">
          
          <div className="card border-left-primary shadow h-100 mt-1">
              <div className="card-header py-2"><label className="text-xs font-weight-bold text-primary mb-3">{helper().translate("PAYMENTS")}</label>
                <div className='d-flex align-items-center justify-content-end'>
                  <button className="btn btn-sm btn-dark btn-transport-save  p-0" style={{width: "75px"}} onClick={() => props.hidePayments()} >{helper().translate('BACK')}</button>
                  <button  disabled={amountLeft <= 0.01 ? true : false} className="btn btn-sm btn-primary btn-transport-save ms-2 p-0" style={{width: "75px"}} onClick={() => addRow()}>{helper().translate('NEW')}</button>
                  <button disabled={disableSaveBtn} className="btn btn-sm btn-success btn-transport-save ms-2 p-0" style={{width: "75px"}} onClick={() => itemSave()} >{helper().translate('SAVE')}</button>
                </div>
              </div>
              <div className="card-body px-2">
              <table  className="table table-hover text-center sales-table purchase-table transpot-table ">
            <thead>
                <tr>
                  <th scope="col">{helper().translate('NR')}</th>
                  <th scope="col">{helper().translate('DATE')}</th>
                  <th scope="col">{helper().translate('PAYMENT')}</th>
                  <th scope="col">{helper().translate('SUM')}</th>
                  <th scope="col">{helper().translate('PARTNER')}</th>
                  <th scope="col">{helper().translate('BANK')}</th>
                  <th scope="col">{helper().translate('CASHBOX')}</th>
                  {/* <th style={{width:'25px'}}>+</th> */}
                  <th scope="col" style={{width:'25px'}}>-</th>
                </tr>
            </thead>
            <tbody>
            {[...items].map((item, key) => 
              <tr key={key}>
                <td>{item.no}</td>
                {   item.editMode ?
                  // <td><input type="date" value={payment.date ? helper().formatDate(payment.date) : (new Date()).toISOString().substring(0, 10)} onChange={(e) => setPayment({...payment, date: e.target.value})} name="date" className={`form-control form-control-sm'}`}/></td>
                  <td><span className={`text-center text-dark'} small`}>{helper().formatDate(new Date)}</span></td>
                  :
                  <td> <span className={`text-center text-dark'} small`}>{helper().formatDate(item.date)}</span></td>
                }
                <td>{item.paymentId}</td>
                {   item.editMode ?
                  <td style={{width: "200px"}}><input type="number" value={item.amountPaid} onChange={(e) => fieldChange(e , item.no)}  name="amountPaid" className={`form-control form-control-sm text-center`}/></td>
                  :
                  <td> <span className={`text-center text-dark'} small`}>{item.amountPaid}</span></td>
                }
                {   item.editMode ?
                  <td style={{width: "300px"}}>
                      <Select
                          name="partnerId"
                          placeholder="Zgjidh Partnerin"
                          value={partners?.find(p => p.value == item.partnerId)}
                          onChange={(e, obj ) => selectChange(e, obj, item.no)}
                          options={partners}
                          components={{ Option: partnerOption }}
                          styles={multipleItemStyle}
                          //className={errors?.partnerId ? 'is-invalid-s': ''}
                      />
                  </td>
                  :
                  <td> <span className={`text-center text-dark'} small`}>{item.partner}</span></td>
                }

                  <td>{item.bankName ? item.bankName : '-'}</td>
                  <td>{item.cashBoxId ? cashBox.find(c => c.value === item.cashBoxId)?.label : "-" }</td>
                {
                  item.bankName === null ? <td><button onClick={() => deleteRow(item.paymentId , item.no)} className="btn-delete"><span className="icon text-white-40"><i className="fas fa-minus-circle"></i></span></button></td> : <td></td>
                }
              </tr>
              )}
            </tbody>
            <tfoot className="mb-2 mt-2 table-borderless no-colors">
              <tr>
              <td ></td>
              <td colSpan="2" className="font-weight-bold">{helper().translate('INVOICE_TOTAL')}:</td>
              <td colSpan="2" className="font-weight-bold">{helper().translate('PAYMENT_TOTAL')}:</td>
              <td colSpan="2" className="font-weight-bold">{helper().translate('REMAINING_DEBT')}:</td>
              <td ></td>
              </tr>

              <tr>
              <td ></td>
              <td colSpan="2" className="font-weight-bold">
                <input style={{width: '99%'}} type="text" disabled defaultValue="0" className="form-control text-center pb-1" value={helper().formatNumber(props.sale.totalSum)} ></input>
              </td>
              <td colSpan="2" className="font-weight-bold">
              <input style={{width: '99%'}} type="text" disabled defaultValue="0" className="form-control text-center pb-1" value={helper().formatNumber(totalPayment)} ></input>
              </td>
              <td colSpan="2" className="font-weight-bold ">
                <input style={{width: '99%'}} type="text" disabled defaultValue="0" className="form-control text-center  pb-1" value={helper().formatNumber(amountLeft)} ></input>
              </td>
              <td ></td>
              </tr>
            </tfoot>
            </table>
              </div>
          </div>
        </div>
      </div>
    )
}
export default PaymentSales