import React, { useEffect, useState } from 'react';
import { MDBInput } from 'mdb-react-ui-kit';
import Select from 'react-select';
import Switch from "react-switch";
import globals from '../../Services/globals.js';
import apiService from '../../Services/apiServices.js';
import helper from '../../Services/helper.js';
import HeaderTools from '../../PageComponents/Header/HeaderTools.js';
import { useToasts } from 'react-toast-notifications';
import User from '../../Models/User.js';
import Modal from "react-bootstrap/Modal";
import loginService from '../../Services/loginService.js';

const AddUser = () => {
    const { addToast } = useToasts();
    const logedInUser = globals().getLogedInUser();
    const [user, setUser] = useState();
    const [users, setUsers] = useState([]);
    const userObj = new User(); //{id:0, userName:"", password:"", email:"", name:"", surname:""}
    const [newUser, setNewUser] = useState(userObj);
    const [errors, setErrors] = useState();
    const [roles, setRoles] = useState();
    const [cashBoxes, setCashBoxes] = useState()
    const [logedUserRole, setLogedUserRole] = useState();
    const settingsObj = {
        id: 0,
        userId_1: 0,
        allowToChangeSalePrice: false,
        digitsOnDetails: 2,
        digits: 2,
        style: "#4e73df",
        searchStatus: false,
        allowToChangeWarehouse: false,
        allowToDelete: false,
        backupPath: "",
        language: "sq"
    }
    const [userSettings, setUserSettings] = useState(settingsObj)
    const [haveSettings, setHaveSettings] = useState(false)

    const [show, setShow] = useState(false);

    const modalClose = () => setShow(false);
    const modalShow = () => setShow(true);

    const getUser = async () => {
        apiService().query('user', 'userName=' + logedInUser.data.username)
            .then((response) => {
                if (response.success) {
                    let u = response.data[0];
                    u.password = "";
                    setUser(u)
                }
                else alert('errr')
            })
    }

    const getUsers = () => {
        apiService().get('users')
            .then((response) => {
                if (response.success) {
                    setUsers(response.data)
                }
                else alert('errr')
            })
    }

    const getDefaults = (table) => {
        apiService().get(table)
            .then((response) => {
                if (response.success) {
                    if (table === 'cashBoxes') {
                        response.data.sort((p1, p2) => (p1.branchId > p2.branchId) ? 1 : (p1.branchId < p2.branchId) ? -1 : 0);
                        // let records = response.data.map(record => ({ value: record.id, label: table === 'roles' ? record.roleName : record.name, warehouse: table === 'cashBoxes' ?  record.warehouseName : '', warehouseId: table === 'cashBoxes' ?  record.warehouseId : '' }));
                    }
                    let records = response.data.map(record => ({ value: record.id, label: table === 'roles' ? record.roleName : table === 'cashBoxes' ? record.branchName + " | " + record.warehouseName + " | " + record.name : record.name, warehouse: table === 'cashBoxes' ? record.warehouseName : '', warehouseId: table === 'cashBoxes' ? record.warehouseId : '' }));
                    if (table === 'roles') setRoles(records);
                    else if (table === 'cashBoxes') setCashBoxes(records);
                }
                else alert('error');
            })
    }

    const addNewReference = () => {
        setNewUser(userObj);
        setErrors('');
        setShow(true);
        setTimeout(() => getFocus(), 1000)
        //getFocus();
    }

    const editUser = (user) => {
        getUserRole(user)
        getUsertSettings(user)
        setShow(true);
        setTimeout(() => getFocus(), 1000)
    }

    const getFocus = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        document.getElementById("inputUserName")?.focus();
    }

    const getUserRole = async (user) => {
        apiService().query('userRoles', 'username=' + user.userName)
            .then((response) => {
                if (response.success) {
                    //todo: check this line
                    //setLogedUserRole(response.data[0]?.roleId)
                    user.roleId = response.data[0].roleId;
                    user.password = "";
                    setNewUser(user);
                }
            })
    }

    const getUsertSettings = (user) => {
        apiService().query('userSettings', `username=${user?.userName}`)
            .then((response) => {
                if (response.success && response.data?.length > 0) {
                    let u = response.data[0]
                    // setSettings(u)
                    setHaveSettings(true)
                    user.settings = response.data[0]
                    setUserSettings(response.data[0])
                    setNewUser(user);
                }
            })
    }

    const validate = async (isNewUser) => {
        let errorsArr = {};
        let fieldsToValidate = ['userName', isNewUser && 'password', 'name', 'email', 'roleId']
        for await (const [key, val] of Object.entries(newUser)) {
            if (fieldsToValidate.includes(key)) {
                if (val !== "") {
                    if (key === "userName") {
                        let checkUserName = await apiService()
                            .query('checkUserName', key + '=' + val)
                            .then((response) => {
                                if (response.success && response.data.length > 0) {
                                    if (isNewUser) {

                                        return "Username exist!"

                                    }
                                    else {
                                        let findedUser = response.data[0];
                                        if (findedUser.id !== newUser.id) return "Username exist!"
                                        else return "";
                                    }
                                }
                                else return ""
                            });
                        errorsArr[key] = checkUserName;
                    }
                    else {
                        errorsArr[key] = ""

                    }
                }
                else {
                    errorsArr[key] = "Cannot be empty"
                }
            }
        }
        setErrors(errorsArr)
        return returnValidate(errorsArr);
    }

    const returnValidate = (errors) => {
        if (Object.values(errors).every(x => x === null || x === '')) {
            return true
        }
        else {
            return false
        }
    }

    const save = (isNewUser) => {
        validate(isNewUser).then(function (result) {
            if (result) {
                if (newUser.id != 0) {
                    apiService().put('user', newUser)
                        .then((response) => {
                            if (response.success) {
                                saveUserRole(newUser.id, newUser.roleId, false)
                                saveSettings(newUser.id, haveSettings)
                            }
                            else {
                                responseMessage(response, '')
                            }
                        });
                }
                else {
                    newUser.dateAdded = new Date();
                    newUser.firstName = newUser.name;
                    newUser.lastName = newUser.surname;
                    loginService().putMyLogins('users', newUser)
                        .then((response) => {
                            if (response.success) {
                                addNewUserLocaly(response.data, false)
                            }
                            else {
                                addNewUserLocaly(newUser);                                
                            }
                        });
                }
            }
        });
    }

    const addNewUserLocaly = (userObj) => {
        apiService().post('user', userObj)
            .then(response => {
                if (response.success) {
                    setNewUser(response.data)
                    saveUserRole(response.data.id, newUser.roleId, true)
                    saveSettings(response.data.id, haveSettings)
                    addSubscription(response.data)
                }
                else {
                    responseMessage(response, '')
                }
            })
    }

    const addSubscription = (userObj) => {
        let sub = { subscriptionId: logedInUser?.subscription, username: userObj?.userName }
        apiService().post('subscriptionUsersDetails', sub, 't3services@t3')
            .then(response => {
                if (response.success) {
                    responseMessage(response, 'Subscription Added Successfully')
                }
            })
    }

    const saveUserRole = (userId, roleId, save) => {
        let role = { userId: userId, roleId: roleId }
        if (save) {
            apiService().post('userRole', role)
                .then((response) => {
                    responseMessage(response, 'Saved Successfully')
                });
        }
        else {
            apiService().put('userRole', role)
                .then((response) => {
                    responseMessage(response, 'Saved Successfully')
                    getUser()
                });
        }

    }

    const saveSettings = (userId, hs) => {
        userSettings.userId_1 = userId
        if (!hs) {
            apiService().post('userSettings', userSettings)
                .then((response) => {
                    responseMessage(response, 'Saved Successfully')
                })
        }
        else {
            apiService().put('userSettings', userSettings)
                .then((response) => {
                    responseMessage(response, 'Saved Successfully')
                })
        }
    }

    const responseMessage = (response, message) => {
        if (response.success) {
            setShow(false)
            getUsers();
            addToast(message, { appearance: 'success', autoDismiss: true, });
        }
        else {
            addToast('Ka ndodhur nje gabim!', { appearance: 'error', autoDismiss: true, });
        }
    }

    const t = (key) => {
        return helper().translate(key);
    }

    const selectStyles = {
        control: (provided, state) => ({
            ...provided,
            background: '#fff',
            borderColor: '#9e9e9e',
            minHeight: '28.59px',
            height: '28.59px',
            boxShadow: state.isFocused ? null : null,
        }),

        valueContainer: (provided, state) => ({
            ...provided,
            height: '28.59px',
            padding: '0 6px'
        }),

        input: (provided, state) => ({
            ...provided,
            margin: '0px',
        }),
        indicatorSeparator: state => ({
            display: 'none',
        }),
        indicatorsContainer: (provided, state) => ({
            ...provided,
            height: '28.59px',
        }),
    };


    useEffect(() => {
        //getUser();
        //getUserRole(logedInUser?.data);
        let tables = ['roles', 'cashBoxes'];
        tables.forEach(table => {
            getDefaults(table)
        })
        getUsers();
        setErrors({})
    }, []);

    return (
        <>

            <div className="col-lg-8">
                <Modal
                    show={show}
                    onHide={modalClose}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header className="no-shadow btn-header px-1 py-0 d-flex justify-content-end">
                        <HeaderTools className="position-absolute" save={() => save(newUser.id !== 0 ? false : true)} close={modalClose} />
                    </Modal.Header>
                    <Modal.Body className="no-shadow">
                        <div className="col-md-12 col-lg-12 col-xl-12 px-2 my-3">
                            <div className="row">
                                <div className="col">
                                    <MDBInput type="text" required name="userName" value={newUser.userName} onChange={(e) => setNewUser({ ...newUser, userName: e.target.value })} className="form-control-sm text-black" id="inputUserName" label="Shfrytëzuesi" />
                                    {errors?.userName != '' && <span className='error'>{errors?.userName}</span>}
                                </div>
                                {/* {newUser.id === 0 &&  */}
                                <div className="col">
                                    <MDBInput type="password" name="password" value={newUser.password} onChange={(e) => setNewUser({ ...newUser, password: e.target.value })} className="form-control-sm text-black" id="inputPassword" label="Fjalëkalimi" />
                                    {errors?.password != '' && <span className='error'>{errors?.password}</span>}
                                </div>
                                {/* } */}
                            </div>
                            <div className="row mt-3">
                                <div className="col">
                                    <MDBInput type="text" name="name" value={newUser.name} onChange={(e) => setNewUser({ ...newUser, name: e.target.value })} className="form-control-sm text-black" id="inputName" label="Emri" />
                                    {errors?.name != '' && <span className='error'>{errors?.name}</span>}
                                </div>
                                <div className="col">
                                    <MDBInput type="text" name="surname" value={newUser.surname} onChange={(e) => setNewUser({ ...newUser, surname: e.target.value })} className="form-control-sm text-black" id="inputLastName" label="Mbiemri" />
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col">
                                    <Select
                                        className=""
                                        placeholder="Zgjidh Arkën"
                                        options={cashBoxes}
                                        onChange={(e) => setNewUser({ ...newUser, cashboxId: e.value, warehouseId: e.warehouseId })}
                                        value={cashBoxes?.find(cb => cb.value == newUser.cashboxId)}
                                        styles={selectStyles}
                                    />
                                </div>
                                <div className="col">
                                    <MDBInput type="text" name="dateAdded" disabled value={newUser.dateAdded ? helper().formatDate(newUser.dateAdded) : helper().formatDate(new Date())} onChange={(e) => setNewUser({ ...newUser, dateAdded: e.target.value })} className="form-control-sm text-black" id="inputLastRole" label="Data e regjistrimit" />
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col">
                                    <Select
                                        className=""
                                        placeholder="Zgjidh rolin"
                                        name="roleId"
                                        options={roles}
                                        onChange={(e) => setNewUser({ ...newUser, roleId: e.value })}
                                        value={roles?.find(role => role.value == newUser.roleId)}
                                        styles={selectStyles}
                                    />
                                    {errors?.roleId != '' && <span className='error'>{errors?.roleId}</span>}
                                </div>
                                <div className="col">
                                    {/* <MDBInput textarea rows={3} name="roles" value="" onChange="" className="form-control-sm text-black mt-3" id="inputRoles" label="Rolet" /> */}
                                    <input type="hidden" name="warehouseId" value={cashBoxes?.find(cb => cb.value == newUser.cashboxId)?.warehouseId}></input>
                                    <MDBInput type="text" name="warehouse" value={cashBoxes?.find(cb => cb.value == newUser.cashboxId)?.warehouse} disabled className="form-control-sm text-black" id="inputWarehouse" label="Depo" />
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col">
                                    <MDBInput type="text" name="email" value={newUser.email} onChange={(e) => setNewUser({ ...newUser, email: e.target.value })} className="form-control-sm text-black" id="inputEmail" label="Emaili" />
                                    {errors?.email != '' && <span className='error'>{errors?.email}</span>}
                                </div>
                                <div className="col">
                                    <MDBInput type="text" name="phone" value={newUser.phone != null ? newUser.phone : ''} onChange={(e) => setNewUser({ ...newUser, phone: e.target.value })} className="form-control-sm text-black" id="inputPhone" label="Telefoni" />
                                </div>
                            </div>
                            <div className='row mt-3'>
                                <div className="col-md-6 col-lg-6 col-xl-6 mb-3">
                                    <label className="mr-3">{helper().translate('ALLOW_TO_CHANGE_SALE_PRICE')}</label>
                                    <Switch
                                        id="withVat"
                                        className="text-light float-right"
                                        onColor="#86d3ff"
                                        onHandleColor="#2693e6"
                                        handleDiameter={25}
                                        width={65}
                                        height={25}
                                        uncheckedIcon={helper().translate('NO')}
                                        checkedIcon={helper().translate('YES')}
                                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                        onChange={(e) => setUserSettings({ ...userSettings, allowToChangeSalePrice: e })}
                                        checked={userSettings?.allowToChangeSalePrice}
                                    />
                                </div>
                            </div>
                            <div className='row'>
                                <div className="col-md-6 col-lg-6 col-xl-6 mb-3">
                                    <label className="mr-3">{helper().translate('ALLOW_TO_CHANGE_WAREHOUSE')}</label>
                                    <Switch
                                        id="withVat"
                                        className="text-light float-right"
                                        onColor="#86d3ff"
                                        onHandleColor="#2693e6"
                                        handleDiameter={25}
                                        width={65}
                                        height={25}
                                        uncheckedIcon={helper().translate('NO')}
                                        checkedIcon={helper().translate('YES')}
                                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                        onChange={(e) => setUserSettings({ ...userSettings, allowToChangeWarehouse: e })}
                                        checked={userSettings?.allowToChangeWarehouse}
                                    />
                                </div>
                            </div>
                            {/* <div className='row'>
                                <div className="col-md-6 col-lg-6 col-xl-6 mb-3">
                                    <label className="mr-3">{helper().translate('ALLOW_TO_DELETE')}</label>
                                    <Switch
                                        id="withVat"
                                        className="text-light float-right"
                                        onColor="#86d3ff"
                                        onHandleColor="#2693e6"
                                        handleDiameter={25}
                                        width={65}
                                        height={25}
                                        uncheckedIcon={helper().translate('NO')}
                                        checkedIcon={helper().translate('YES')}
                                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                        onChange={(e) => setUserSettings({ ...userSettings, allowToDelete: e })}
                                        checked={userSettings?.allowToDelete}
                                    />
                                </div>
                            </div> */}

                        </div>
                    </Modal.Body>
                </Modal>

                <div className="card border-left-primary shadow ">

                    <div className="card-body py-0">
                        <div className="row no-gutters align-items-center">
                            <div className="col mr-2 row">
                                <div className="col-lg-12 form-group my-0 d-flex align-items-center">
                                    <div className="d-table-cell no-shadow">
                                        <HeaderTools newHandle={addNewReference} />
                                    </div>
                                    <div className="d-table-cell" style={{ flex: 1 }}>
                                        <MDBInput className="form-control-sm" label={t('SEARCH') + '...'} onChange={(e) => this.handleChange(e)} />
                                    </div>
                                </div>
                            </div>
                            {/* <div className="col-auto">
                            <a className="searchbutton">
                            <i className="fas fa-search fa-2x " />
                            </a>
                        </div> */}
                        </div>
                    </div>
                </div>

                <div className="card shadow w-100 mt-1">
                    <div className="card-header">
                        {/* <h6 className="m-0 font-weight-bold text-primary">{user?.name}</h6> */}
                    </div>
                    <div className="card-body table-responsive">
                        <table className="table table-hover">
                            <thead>
                                <tr>
                                    <th scope="col">Username</th>
                                    <th scope="col">Email</th>
                                    <th scope="col">Emri</th>
                                    <th scope="col">Mbiemri</th>
                                    <th scope="col">Data</th>
                                    <th scope="col">Telefoni</th>
                                    <th scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    users?.map((user, key) => {
                                        return <tr key={key}>
                                            <td>{user?.userName}</td>
                                            <td>{user?.email}</td>
                                            <td>{user?.name}</td>
                                            <td>{user?.surname}</td>
                                            <td>{helper().formatDate(user?.dateAdded)}</td>
                                            <td>{user?.phone}</td>
                                            <td>
                                                <button onClick={() => editUser(user)} className="btn-edit mr-2">
                                                    <span className="icon text-white-40">
                                                        <i className="fas fa-edit" />
                                                    </span>
                                                </button>
                                            </td>
                                        </tr>
                                    })
                                }

                                {/* <tr>
                                    <td>{user?.userName}</td>
                                    <td>{user?.email}</td>
                                    <td>{user?.name}</td>
                                    <td>{user?.surname}</td>
                                    <td>{helper().formatDate(user?.dateAdded)}</td>
                                    <td>{user?.phone}</td>
                                    <td>
                                    <button onClick={() => editUser(user)} className="btn-edit mr-2">
                                        <span className="icon text-white-40">
                                        <i className="fas fa-edit" />
                                        </span>
                                    </button>
                                    </td>
                                </tr> */}

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddUser
