import React, { useEffect, useState, forwardRef, useRef, useImperativeHandle } from 'react';
import DataTable from '../UI/Tables/DataTable';
import apiService from '../../Services/apiServices';
import helper from '../../Services/helper';
import globals from '../../Services/globals';
import { right } from '@popperjs/core';

const Print =  forwardRef((params, ref) => {
    const [company, setCompany] = useState()
    const [branch, setBranch] = useState()
    const [warehouse, setWarehouse] = useState()
    const [partners, setPartners] = useState([])
    const [userColumns, setUserColumns] = useState()
    const logedInUser = globals().getLogedInUser()

    const t = (key) => {
        return helper().translate(key);
    }

    const checkColumn = (header, items) => {
        if(header && header.subHeader){
            const isTrue = (currentValue) => !currentValue[header.key] || currentValue[header.key] === 0;
            if(!items?.every(isTrue)) {
                return header
            }
        }
        else{
            return header
        }
    }

    const allHeaders = params?.headers?.filter(header => checkColumn(header, params?.data))
    let colLength = 96/allHeaders.length;

    const renderHeader = (headers) => {
        return headers?.map((header, index) => {
            if(header.showHidde) {
                return header && !header.noPrint ?  <th key={index} style={{textAlign: 'center', fontSize: '9pt', fontFamily: 'Arial', backgroundColor: '#f0f0f0', border: '1pt solid #000'}}>{header.description ? header.description : header.columnName}</th> : ''
            }
        });
    }

    const renderSubHeader = (headers) => {
        return headers?.map((header, index) => {
            if(header.showHidde) {
                if(index === 0 && calculateColSpan(headers)) return <td style={{textAlign: 'center', fontSize: '9pt', fontFamily: 'Arial', backgroundColor: '#C0C0C0', color: '#000', border: '1pt solid #000'}} colSpan={calculateColSpan(headers)}>Numri i kutise ne deklaraten e TVSh-se</td> 
                if(header.subHeader)
                    return <td key={index} style={{textAlign: 'center', fontSize: '9pt', fontFamily: 'Arial', backgroundColor: '#C0C0C0', color: '#000', border: '1pt solid #000'}}>{header.columnName}</td> 
            }
        });
    }

    const checkHaveCalculate = (headers) => {
        if (headers.some(e => (e.calculate === true && e.showHidde === true))) {
            return true;
        }
        else {
            return false;
        }
    }

    const renderFooter = (headers) => {
        return headers.map((header, index) => {
            if(index === 0 && checkHaveCalculate(headers) ) return <td style={{textAlign: 'right', fontSize: '9pt', fontFamily: 'Arial', backgroundColor: '#C0C0C0', color: '#000', border: '1pt solid #000'}} colSpan={calculateColSpan(headers)}>Totali: </td> 
            if(!header.noPrint) return (header && header.calculate && header.showHidde) && <td key={index} style={{textAlign: 'center', fontSize: '9pt', fontWeight:'bold', fontFamily: 'Arial', backgroundColor: '#C0C0C0', border: header.calculate ? '1pt solid #000' : ''}}>{ header.calculate === true ? calculateTotal(header.key) : (calculateTotal(header.calculate[0]) - calculateTotal(header.calculate[1])).toFixed(2) }</td>
        });
    }

    const getUserColumns = () => {
        apiService().query('userColumns', `username=${logedInUser.data.username}&formname=${params.formName}`)
            .then(response => {
                if(response.success && response.data.length > 0) {
                    let results = response.data[0].hColumns.split(',')
                    setUserColumns(results)
                    setColumns(results)
                }
                else {
                    setColumns()
                }
            })
    }

    const setColumns = (userColumns) => {
        var hdrs = []
        allHeaders?.forEach(h => {
            if(userColumns) {
                userColumns.includes(h?.key) ? h['showHidde'] = true : h['showHidde'] = false
            }
            else {
                h ? h['showHidde'] = true : 0;
            }
            hdrs.push(h)
        })
        return hdrs
    }

    const uc = setColumns(userColumns)

    const calculateColSpan = (headers) => {
        let i1 = 0;
        for (let i = 0; i < headers.length; i++) {
            if(!headers[i].noPrint && headers[i].showHidde){
                if(headers[i] && !headers[i].subHeader) {
                    if(headers[i].calculate) break;
                    else i1++; 
                }
                else return i1 
            }
             
        }
        return i1;
    }

    const calculateTotal = (prop) => {
        return parseFloat(params?.data.reduce((accumulator, current) => parseFloat(accumulator) + parseFloat(current[prop]), 0)).toFixed(2)
    }
    const renderRow = (row) => {       
        var headers = uc;
        var colspan = 0;
        return headers?.map((header, index) => {
            let val = row[header.name] ? row[header.name] : row[header.key]
            val = !isNaN(val) && isFloat(val) || val === 0 ? parseFloat(val).toFixed(2) : val
            //return header && !header.noPrint && <td key={index} style={{border:'1.0pt solid #000'}}>{val}</td>
            if(header.showHidde) {
                if(!row['isCalculate']) {
                    // return header && !header.noPrint && <td key={index} colSpan={row.colSpan ? row.colSpan : 1 }  style={{border:'1.0pt solid #000'}}>{val}</td>
                    if(header.type === 'number' && !header.noPrint){
                        return <td key={index} style={{fontWeight: 'bold',border:'1.0pt solid #000' , textAlign:"right" , paddingRight: "10px"}} >{typeof(val)=== "number" ? helper().formatNumber(val) : val }</td>
                    }else if (header.type === 'text' && !header.noPrint){
                        return <td key={index} style={{fontWeight: 'bold',border:'1.0pt solid #000' , textAlign:"left" , paddingLeft: "10px"}}>{val}</td>
                    }else if (header.color === 'red' && !header.noPrint){
                        return <td key={index} style={{fontWeight: 'bold',border:'1.0pt solid #000' , textAlign:"center" , paddingLeft: "10px", color:"red"}}>{val}</td>
                    } else{
                        return header && !header.noPrint && <td key={index} colSpan={row.colSpan ? row.colSpan : 1 }  style={{border:'1.0pt solid #000'}}>{val}</td>
                    }
                }
                else {
                    if(row[header.key]){
                        let td = <>{ colspan ? <td colSpan={colspan} style={{backgroundColor: '#C0C0C0', textAlign: 'right', paddingRight: 3, border: '1pt solid #000'}}>{row[header.key+"_label"]}</td>: "" }<td style={{backgroundColor: '#C0C0C0', border: '1pt solid #000', fontWeight:'bold'}} key={index}>{row[header.name] ? row[header.name] : row[header.key]}</td></>
                        colspan = 0;
                        return td;
                    }
                    else {
                        colspan++;
                    } 
                }
            }
        });
    }

    const renderRows = (rows) => {   
        return rows?.map((row, index) => {
            return <tr key={index} style={{textAlign: 'center', fontSize: '9pt', fontFamily: 'Arial'}}>
                { renderRow(row)}
            </tr>
        })
    }

    function isFloat(n){
        return Number(n) == n && n % 1 !== 0;
    }

    const getCompany = () => {
        globals().getSettings().then((response) => {
            if(response) {
                setCompany(response)
            }
        });
    }


    const haveSubHeader = (header) => {
        return header?.find(x => x.subHeader === true)
    }

   const printStyle = "table{border-collapse: collapse;} .col-6{width: 50%} td{text-align: center, background-color: #C0C0C0} .headRow{background: #C0C0C0} @media print {.noPrint{display:none;} .headRow{background-color: #C0C0C0 !important; -webkit-print-color-adjust: exact; } th{background-color: #f0f0f0 !important; -webkit-print-color-adjust: exact; }} @print { @page :footer { display: none} @page :header { display: none} }"
   const pdfScript = '<script>function pdf() {window.print();}</script>'
   const btn = '<button onclick="pdf()" class="noPrint">Print</button>'

    useImperativeHandle(ref, () => ({
        printPreview() {
            if(params.formName) {
                getUserColumns()
            }
            else {
                setColumns()
            }

            var divContents = document.getElementById("print").innerHTML;
            var printWindow = window.open('', '', 'height="100vh",width="100%"');
            printWindow.document.write('<html><head><style>'+printStyle+'</style>');
            printWindow.document.write('</head><body>');
            printWindow.document.write(btn);
            printWindow.document.write(divContents);
            printWindow.document.write(pdfScript);
            printWindow.document.write('</body></html>');
            printWindow.document.close(); 
            //printWindow.print();
        },

    }));

    useEffect(() => {
        getCompany()
        if(params.formName) {
            getUserColumns()
        }
        else {
            setColumns()
        }
    }, [])

    return (
        <> 
            <div id="print"  style={{display: 'none'}}>
                <div id="pdf">
                    <div id="table-to-xls-1" style={{padding: '10px' , maxWidth: "100vw"}}>
                        <div style={{display: 'flex', alignItems: 'center', marginBottom: 15, width: '100%'}}>
                            <div style={{width: '25%'}}>
                                <strong style={{color: '#000'}}>{t('COMPANY')}: {company?.companyName}</strong><br></br>
                                <span style={{color: '#000'}}>{t('BUSINESS_NUMBER')}: {company?.businessNumber}</span><br></br>
                                <span style={{color: '#000'}}>{t('FISCAL_NUMBER')}: {company?.fiscalNumber}</span><br></br>
                                <span style={{color: '#000'}}>{t('ADDRESS')}: {company?.address}</span><br></br>
                                <span style={{color: '#000'}}>{t('BRANCH')}: {branch}</span><br></br>
                                <span style={{color: '#000'}}>{t('WAREHOUSE')}: {warehouse}</span><br></br>
                            </div>
                            <div style={{width: '50%', textAlign: 'center', alignSelf:'start'}}>
                                <h2 style={{color: '#000', padding: '0px' , margin:'0px'}}> {params?.reportName ? params?.reportName : ''} </h2>
                            </div>
                            <div style={{width: '25%',alignSelf: params.query?.partnerName?.partner !== undefined ? 'start' : 'end' }}>
                                {/* {console.log(params?.reportName === 'Kartela e Blerësit')} */}
                                <div style={{float:"right"}}>
                                {params.query?.partnerName?.partner !== undefined ? 
                                <>
                                <strong style={{color: '#000'}}>{t(params.query.partnerName.label)}: {params.query.partnerName.partner}</strong><br></br>
                                <span style={{color: '#000'}}>{t('FISCAL_NUMBER')}: {params.query.partnerName.fiscalNo}</span><br></br><br></br>
                                </>
                                : ""}
                                <small style={{color: '#000' }}><strong>{t("PERIOD")}: {params.query.StartDate} - {params.query.EndDate}</strong></small>
                                </div>
                            </div>
                        </div>
                        <table id="table-to-xls-13" style={{minWidth: "100vw"}} >
                            {renderHeader(uc)}
                            {haveSubHeader(uc) && <tr className='headRow'>{ renderSubHeader(uc)}</tr> }
                            {renderRows(params?.data)}
                            <tr className='headRow'>{params.data ? renderFooter(uc) : 0}</tr>
                        </table>
                        <div style={{display: 'flex', alignItems: 'center', marginTop: 50}}>
                            <div className='col-6'>
                                <small style={{borderTop: '1pt solid #000', color: '#000', paddingRight: 20, paddingLeft: 20}}>{t('USER')}: {logedInUser?.data.name} {logedInUser?.data.surname}</small><br></br>
                            </div>
                            <div className='col-6'>
                                <small style={{color: '#000'}}>{t("PRINTING_DATE")}: {helper().formatDate(new Date())}</small>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
        )
});

export default Print;
