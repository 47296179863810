import React, { useEffect, useState } from 'react'
import AdministrationForm from '../UI/AdministrationForm/AdministrationForm'
import apiService from '../../Services/apiServices'
import helper from '../../Services/helper'

const Warehouse = (props) => {
    const [branches, setBranches] = useState()
    const [modal, setModal] = useState()

    const t = (key) => {
        return helper().translate(key);
    }

    const headers = [
        { key: 'NAME', name: 'name',columnName: t('name'), type: 'input', required: true },
        { key: 'BRANCH', name: "branchId", columnName: t('branchId'), type: 'select', options: branches,  required: false },
        //{ key: 'ADDRESS',name: 'address', columnName: t('address'), type: 'input', required: false },
       // { key: 'CONTACT_PERSON', name: 'contactPerson',columnName: t('contactPerson'), type: 'input', required: false },
       // { key: 'EMAIL',name: 'email', columnName: t('email'), type: 'input', required: false },
       // { key: 'PHONE', name: 'phone', columnName: t('phone'), type: 'input', required: false },
    ]

    const model = {
        id: 0,
        name: "",
        unitNo: "", 
        address: "",
        contactPerson: "",
        email: "",
        phone: "",
        otherData: "",
        status: 0
    }

    const getBranches = () => {
        apiService().get('branches')
            .then((response)=> {
                if(response.success) {
                    let records = response.data.map(record => ({ value: record.id, label: record.name }));
                    setBranches(records)
                }
            })
    }

    const performRefresh = () => {
        alert('reffff')
    }

    useEffect(() => {
        getBranches();
    }, [])

    useEffect(() => {
        setModal(props.modal)
    }, [props.modal]);

    return (
        <div>
            <AdministrationForm new={modal} headers={headers} table="Warehouses" name="Depo" model={model} formType="modal" />
        </div>
    )
}

export default Warehouse
