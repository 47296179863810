import React, { Component } from 'react';
import globals from '../../Services/globals';
import helper from '../../Services/helper';
import { BrowserRouter as Router, Route, Link, NavLink } from "react-router-dom";

class Nav extends Component {

  state = {
    // isBoxVisible:false
    settings: {}
  };
  // toggleBox = () => {
  //   this.setState(prevState => ({ isBoxVisible: !prevState.isBoxVisible }));
  // };
  
  componentDidMount() {
    let settings = globals().getSettings(); //helper().getItemFromLocalStorage('settings')

    var promise = Promise.resolve(settings);
    let that = this
    promise.then(function(val) {
        // console.log(val);
        that.setState({
          ['settings']: val
        })
    });
  }

  
  render() {
    //const { isBoxVisible } = this.state;
      let company = globals().getLogedInUser().company;
     
      const backUrl = '/some/other/value'
      let {settings} = this.state 
      // console.log('setiii: ', settings)
    return (
      <div  >

      
      <ul style={{background: settings.style}} className={`navbar-nav bg-gradient-primary sidebar sidebar-dark accordion position-sticky top-0 left-0 bottom-0 ${this.props.toggle  ? "" : "toggled"}`} id="accordionSidebar">
      {/* Sidebar - Brand */}
      
      <a className="sidebar-brand d-flex align-items-center justify-content-center" href="#">
        {/* <div className="sidebar-brand-icon rotate-n-15">
          <i className="fas fa-laugh-wink" />
        </div> */}
                <div className="sidebar-brand-text mx-3">T3 WEB</div>
                
      </a>
      <div className="sidebar-company d-flex align-items-center justify-content-center">{ company }</div>

      {/* Divider */}
      <hr className="sidebar-divider my-0" />
     
      {/* Nav Item - Pages Collapse Menu */}
      <li className="nav-item">
        <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
          <i className="fas fa-fw fa-cog" />
          <span>Raportet</span>
        </a>
        <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
          <div className="bg-white py-2 collapse-inner rounded">
            <h6 className="collapse-header">Stoqet:</h6>
            <a className="collapse-item" href="#/reports/warehouse">Stoku</a>
            <a className="collapse-item" href="#/reports/itemshistory">Historia e Artikujve</a>
            <hr className="sidebar-divider bg-light" />
            <h6 className="collapse-header">Librat:</h6>
            <a className="collapse-item" href="#/reports/purchasebook">Libri i Blerjes</a>
            <a className="collapse-item" href="#/reports/salesbook">Libri i Shitjes</a>
           
          </div>
        </div>
      </li>
      
      {/* Divider */}
      <hr className="sidebar-divider" />
      {/* Heading */}
      <div className="sidebar-heading">
        Addons
      </div>
      {/* Nav Item - Pages Collapse Menu */}

      {/* Nav Item - Charts */}
      <li className="nav-item">
        <a className="nav-link" href="#/charts">
          <i className="fas fa-fw fa-chart-area" />
          <span>Charts</span></a>
      </li>
      {/* Nav Item - Tables */}
      <li className="nav-item">
        <a className="nav-link" href="#/tables">
          <i className="fas fa-fw fa-table" />
          <span>Tables</span></a>
      </li>
      {/* Divider */}
      <hr className="sidebar-divider d-none d-md-block" />
      {/* Sidebar Toggler (Sidebar) */}
      <div className="text-center d-none d-md-inline">
        <button onClick={this.props.clickbtn} className="rounded-circle border-0" id="sidebarToggle" />
      </div>
    </ul>
    </div>
    );
  }
}



export default Nav;
