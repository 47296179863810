import React, { useEffect, useState, useRef } from 'react'
import apiService from '../../../Services/apiServices'
import DataTable from '../../../PageComponents/UI/Tables/DataTable';
import helper from '../../../Services/helper';
import ExcelExport from '../../../PageComponents/Excel/ExcelExport';
import Search from '../../../PageComponents/Search/Search_Old';
import { MDBInput } from 'mdb-react-ui-kit';
import Print from '../../../PageComponents/Print/Print';
import globals from '../../../Services/globals';
import LoaderComponent from '../../../PageComponents/UI/Components/LoaderComponent';

const PurchasesInDetails = (params) => {
    const [data, setData] = useState([])
    const [loader , setLoader] = useState(false);

    const searchObj = { CompanyBranch: 0, PurchaseType: params.match.params.id ? parseInt(params.match.params.id) : 0, StartDate: '', EndDate: (new Date()).toISOString().substring(0, 10), WarehouseId: 0, includeStartDate: true, includeEndDate: true }
    const [searchQuery, setSearchQuery] = useState(searchObj)

    const [open, setOpen] = useState(true)
    const logedInUser = globals().getLogedInUser()

    let date = new Date(), y = date.getFullYear(), m = date.getMonth();
    searchObj.StartDate = new Date(y, m, 2) //new Date(y, m, 2);
    searchObj.StartDate = searchObj.StartDate.toISOString();
    searchObj.StartDate = searchObj.StartDate.substring(0, 10);

    const excelExportRef = useRef();
    const printRef = useRef();

    const reportName = helper().translate("PURCHASES_IN_DETAIL")

    const checkColumn = (header, items) => {
        if(header && header.subHeader){
            //const isTrue = (currentValue) => parseFloat(currentValue[header.key]) != 0;
            const isTrue = (currentValue) => !currentValue[header.key] || currentValue[header.key] == 0;
            if(!items?.every(isTrue)) {
                return header
            }
        }
        else{
            return header
        }
    }

    const headers = [
        { key: 'no', columnName: helper().translate("NR") , dontShowMenu: true },
        { key: 'date', columnName: helper().translate("DATE") },
        { key: 'invoiceNo', columnName: helper().translate("INVOICE_NO") },
        { key: 'dudNo', columnName: helper().translate("DUD_NO") },
        { key: 'purchaseType', columnName: helper().translate("PURCHASE_TYPE") },
        { key: 'partner', columnName: helper().translate("PARTNER") },
        { key: 'warehouse', columnName: helper().translate("WAREHOUSE") },
        { key: 'barcode', columnName: helper().translate("BARCODE") },
        { key: 'name', columnName: helper().translate("ITEM") },
        { key: 'unit', columnName: helper().translate("UNIT") },
        { key: 'quantity', columnName: helper().translate("QUANTITY") },
        { key: 'price', columnName: helper().translate("PRICE") },
        { key: 'vat', columnName: helper().translate("VAT") },
        { key: 'priceWithVat', columnName: helper().translate("PRICE_WITH_VAT") },
        { key: 'total', columnName: helper().translate("TOTAL") },
        { key: 'totalWithVat', columnName: helper().translate("TOTAL_WITH_VAT") }
    ];
    
    const listSumByItem = ( list ) => {
        if(list.length > 0) {
        return list.reduce(function(previousValue, currentValue, index, array){
            const results = array.reduce((results, item) => ({
                ...results,
                [item.name]: [...(results[item.name] || []), item]
            }), {});

            let all = [];
            for (var key in results) {
                reOrderItems(results[key])  

                let obj = {
                    quantity_label: helper().translate("TOTAL_QUANTITY")+':',
                    quantity: calculateTotal(results[key], 'quantity'),
                    total_label: helper().translate("TOTAL_SUM")+':',
                    total: calculateTotal(results[key], 'total'),
                    totalWithVat: calculateTotal(results[key], 'totalWithVat'),
                    isCalculate: true
                };
                results[key].push(obj);

                results[key].forEach(r => {
                    all.push(r);
                })  
            }

            setData(all)
        });
    }
}

    const calculateTotal = (arr, prop) => {
        return parseFloat(arr.reduce((accumulator, current) => parseFloat(accumulator) + parseFloat(current[prop]), 0)).toFixed(2)
    }

    const reOrderItems = (items) => {
        let count = 1;
        items.forEach(i => { 
            i.no = count; 
            count++ 
        });
        return items;
    }

    const getData = () => {
        setLoader(true);
        var params = `dateStart=${searchQuery.includeStartDate ? searchQuery.StartDate : "1900-01-01"}&dateEnd=${searchQuery.EndDate+" 23:59:59"}`;

        apiService().query('purchasesInDetail', params)
            .then((response) => {
                if (response.success) {
                    if(response.data.length) {
                        var no = 1;
                        let data = response.data;
                        data.forEach(e=> {
                            e.no=no++;
                            e.date=helper().formatDate(e.date);
                        });
                        listSumByItem(data)
                        setLoader(false);
                    }
                    else {
                        setData([]);
                        setLoader(false);
                    }
                }
            })
    }

  

    const print = () => {
        // window.open(`#/excel-print?CompanyBranch=${searchQuery.CompanyBranch}&YEAR=${searchQuery.YEAR}&StartMonth=${searchQuery.StartMonth}&EndMonth=${searchQuery.EndMonth}`, 'popUpWindow', 'height=1300,width="100%",left=100,top=100,right=100,bottom=100,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes')
        //setOpen(!open)
        printRef.current.printPreview()
    }

    const exportToExcel = () => {
        // let table = document.getElementById('table-to-xls-1').innerHTML;
        let fileName = helper().translate("PURCHASES_IN_DETAIL")
        // return helper().exportToExcel(table, fileName)
        excelExportRef.current.exportToExcel(fileName)
    }

    useEffect(() => {     
        setLoader(true);
        getData()
    }, [])

    return (
        <div>
            {/* <div id="dvContainer">
                This content needs to be printed.
            </div>
            <button onClick={() => pdf()}>pdf</button> */}
            {open && <Print data={data.length ? data : []} headers={headers.filter(header => header !== false)} query={searchQuery} ref={printRef} reportName={reportName} formName="PurchasesInDetails" />}
            <div className="container-fluid px-2 mt-3">
                <Search search={false} excel={() => exportToExcel()} printClick={print} back="#/reports"/>
                {/* <Search search={false} excel={exportToExcel} printClick={print}/> */}
                <div className="card border-left-primary shadow h-100 mt-1">
                    <div className="card-body pt-3 pb-1">
                        <div className="row">
                            <div className="col-md-11 col-12">
                                <div className="row no-gutters align-items-center">
                                    <div className="col mr-2 row">
                                        <div className='w-100 d-md-flex d-lg-flex' >
                                            <div className="col-lg-3 mb-3 mb-md-0 pe-0 d-flex align-items-center">
                                                <div style={{ width: '5%' }}>
                                                    <input type="checkbox" name="includeStartDate" checked={searchQuery.includeStartDate ? 'checked' : ''} onChange={(e) => setSearchQuery({ ...searchQuery, includeStartDate: e.target.checked })}></input>
                                                </div>
                                                <div style={{ width: '95%' }}>
                                                    <MDBInput
                                                        name="StartDate"
                                                        disabled={!searchQuery.includeStartDate ? 'disabled' : ''}
                                                        className="form-control-sm active"
                                                        onChange={(e) => setSearchQuery({ ...searchQuery, StartDate: e.target.value })}
                                                        type="date"
                                                        label={helper().translate("START_DATE")}
                                                        //value={searchQuery.StartDate ? helper().formatDate(searchQuery.StartDate, 'yyyy-MM-dd') : (new Date()).toISOString().substring(0, 10)}
                                                        value={searchQuery.StartDate}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-3 mb-3 mb-md-0 pe-0 d-flex align-items-center">
                                                <div style={{ width: '5%' }}>
                                                    <input type="checkbox" name="includeEndDate" checked={searchQuery.includeEndDate ? 'checked' : ''} onChange={(e) => setSearchQuery({ ...searchQuery, includeEndDate: e.target.checked })}></input>
                                                </div>
                                                <div style={{ width: '95%' }}>
                                                    <MDBInput
                                                        name="EndDate"
                                                        disabled={!searchQuery.includeEndDate ? 'disabled' : ''}
                                                        className="form-control-sm active"
                                                        onChange={(e) => setSearchQuery({ ...searchQuery, EndDate: e.target.value })}
                                                        type="date"
                                                        label={helper().translate("END_DATE")}
                                                        //value={searchQuery.EndDate ? helper().formatDate(searchQuery.EndDate, 'yyyy-MM-dd') : (new Date()).toISOString().substring(0, 10)}
                                                        value={searchQuery.EndDate}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-auto">
                                        <a className="searchbutton" onClick={() => getData()}>
                                            <i className="fas fa-search fa-2x text-dark"  />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card shadow mt-1">
                    <div className="card-header py-2">
                        <h6 className="m-0 font-weight-bold text-primary text-center">{helper().translate("PURCHASES_IN_DETAIL")}</h6>
                    </div>
                    {loader && <LoaderComponent /> } 
                    {!loader ? data.length ? <DataTable data={data.length ? data : []} headers={headers?.filter(header => checkColumn(header, data))} formName="PurchasesInDetails" /> : <div className="p-5 text-center">{helper().translate("NO_DATA")}</div>: "" }
                    <ExcelExport data={data.length ? data : []} headers={headers} type={'other'} ref={excelExportRef} hideContent={true} />
                </div>
            </div>
        </div>
    )
}

export default PurchasesInDetails