import React from 'react';

const AtkSaleBookHeader = () => {

    return (
        <tbody>
            <tr height="34" style={{textAlign: 'center', lineHeight: 1, fontSize: '10pt', fontFamily: 'Arial', fontWeight: 'bold'}}>
                <td height="34" width="39.20" style={{border:'1.0pt solid #000'}} rowSpan={2}>Nr</td>
                <td width="192" style={{border:'1.0pt solid #000'}} colspan="2" >Fatura</td>
                <td width="355.93" colspan="3" style={{border:'1.0pt solid #000'}}>Blerësi</td>
                <td width="860.40" colspan="6" style={{border:'1.0pt solid #000'}}>Shitjet e liruara nga TVSH</td>
                <td width="860.27" colspan="6" style={{border:'1.0pt solid #000'}}>Shitjet e tatueshme me norm&#xEB;n 18%</td>
                <td width="842.27" colspan="5" style={{border:'1.0pt solid #000'}}>Shitjet e tatueshme me norm&#xEB;n 8%</td>
                <td width="95.93" rowSpan={2} style={{border:'1.0pt solid #000', background: '#BFBFBF'}}>Totali i TVSH-se se zbritshme me 18% dhe 8%</td>
            </tr>
            <tr style={{height: '97px', textAlign: 'center', fontSize: '9pt', fontFamily: 'Arial'}}>
                <td style={{border:'1.0pt solid #000', padding: 0, marggin: 0}}>Data</td>
                <td style={{border:'1.0pt solid #000', padding: 0, marggin: 0}}>Numri i fatures</td>
                <td style={{border:'1.0pt solid #000', padding: 0, marggin: 0}}>Emri i bler&#xEB;sit</td>
                <td style={{border:'1.0pt solid #000', padding: 0, marggin: 0}}>Numri fiskal i bler&#xEB;sit</td>
                <td style={{border:'1.0pt solid #000', padding: 0, marggin: 0}}>Numri i TVSH-se se bler&#xEB;sit</td>
                <td style={{border:'1.0pt solid #000', padding: 0, marggin: 0}}>Shitjet e liruara pa t&#xEB; drejt&#xEB; kreditimi</td>
                <td style={{border:'1.0pt solid #000', padding: 0, marggin: 0}}>Shitjet e sh&#xEB;rbimeve jasht&#xEB; vendit</td>
                <td style={{border:'1.0pt solid #000', padding: 0, marggin: 0}}>Shitjet brenda vendit me ngarkes&#xEB; t&#xEB; kund&#xEB;rt  t&#xEB; TVSH-s&#xEB;</td>
                <td style={{border:'1.0pt solid #000', padding: 0, marggin: 0}}>Shitjet tjera t&#xEB; liruara me t&#xEB; drejt&#xEB; kreditimi</td>
                <td style={{border:'1.0pt solid #000', padding: 0, marggin: 0}}>Totali i shitjeve t&#xEB; liruara me t&#xEB; drejt&#xEB; kreditimi</td>
                <td style={{border:'1.0pt solid #000', padding: 0, marggin: 0}}>Eksportet</td>
                <td style={{border:'1.0pt solid #000', padding: 0, marggin: 0}}>Shitjet e tatueshme</td>
                <td style={{border:'1.0pt solid #000', padding: 0, marggin: 0}}>Nota debitore e l&#xEB;shuar, nota kreditore e pranuar </td>
                <td style={{border:'1.0pt solid #000', padding: 0, marggin: 0}}>Fatura e borxhit t&#xEB; keq e pranuar</td>
                <td style={{border:'1.0pt solid #000', padding: 0, marggin: 0}}>Rregullimet p&#xEB;r t&#xEB; rritur TVSH-n&#xEB; </td>
                <td style={{border:'1.0pt solid #000', padding: 0, marggin: 0}}>Blerjet q&#xEB; i n&#xEB;nshtrohen ngarkes&#xEB;s s&#xEB; kund&#xEB;rt</td>
                <td style={{border:'1.0pt solid #000', padding: 0, marggin: 0}}>Totali i TVSH-s&#xEB; s&#xEB; llogaritur me norm&#xEB;n 18%</td>
                <td style={{border:'1.0pt solid #000', padding: 0, marggin: 0}}>Shitjet e tatueshme</td>
                <td style={{border:'1.0pt solid #000', padding: 0, marggin: 0}}>Nota debitore e l&#xEB;shuar, nota kreditore e pranuar </td>
                <td style={{border:'1.0pt solid #000', padding: 0, marggin: 0}}>Fatura e borxhit t&#xEB; keq e pranuar</td>
                <td style={{border:'1.0pt solid #000', padding: 0, marggin: 0}}>Rregullimet p&#xEB;r t&#xEB; rritur TVSH-n&#xEB; </td>
                <td style={{border:'1.0pt solid #000', padding: 0, marggin: 0}}>Totali i TVSH-s&#xEB; s&#xEB; llogaritur me norm&#xEB;n 8%</td>
            </tr>
            <tr style={{color: 'black', textAlign: 'center', fontSize: '9pt', fontFamily: 'Arial'}}>
                <td style={{border:'1.0pt solid #000', background: '#BFBFBF', padding: 0, marggin: 0}} colSpan={6}>Numri i kutise ne deklaraten e TVSH-se</td>
                <td style={{border:'1.0pt solid #000', background: '#BFBFBF', padding: 0, marggin: 0}}>[9]</td>
                <td style={{border:'1.0pt solid #000', background: '#BFBFBF', padding: 0, marggin: 0}}>[10a]</td>
                <td style={{border:'1.0pt solid #000', background: '#BFBFBF', padding: 0, marggin: 0}}>[10b]</td>
                <td style={{border:'1.0pt solid #000', background: '#BFBFBF', padding: 0, marggin: 0}}>[10c]</td>
                <td style={{border:'1.0pt solid #000', background: '#BFBFBF', padding: 0, marggin: 0}}>[10] = [10a]+[10b]+[10c]</td>
                <td style={{border:'1.0pt solid #000', background: '#BFBFBF', padding: 0, marggin: 0}}>[11]</td>
                <td style={{border:'1.0pt solid #000', background: '#BFBFBF', padding: 0, marggin: 0}}>[12]</td>
                <td style={{border:'1.0pt solid #000', background: '#BFBFBF', padding: 0, marggin: 0}}>[16]</td>
                <td style={{border:'1.0pt solid #000', background: '#BFBFBF', padding: 0, marggin: 0}}>[20]</td>
                <td style={{border:'1.0pt solid #000', background: '#BFBFBF', padding: 0, marggin: 0}}>[24]</td>
                <td style={{border:'1.0pt solid #000', background: '#BFBFBF', padding: 0, marggin: 0}}>[28]</td>
                <td style={{border:'1.0pt solid #000', background: '#BFBFBF', padding: 0, marggin: 0}}>[K1]</td>
                <td style={{border:'1.0pt solid #000', background: '#BFBFBF', padding: 0, marggin: 0}}>[14]</td>
                <td style={{border:'1.0pt solid #000', background: '#BFBFBF', padding: 0, marggin: 0}}>[18]</td>
                <td style={{border:'1.0pt solid #000', background: '#BFBFBF', padding: 0, marggin: 0}}>[22]</td>
                <td style={{border:'1.0pt solid #000', background: '#BFBFBF', padding: 0, marggin: 0}}>[26]</td>
                <td style={{border:'1.0pt solid #000', background: '#BFBFBF', padding: 0, marggin: 0}}>[K2]</td>
                <td style={{border:'1.0pt solid #000', background: '#BFBFBF', padding: 0, marggin: 0}}>[30]</td>
            </tr>
        </tbody>
    )
};

export default AtkSaleBookHeader;
