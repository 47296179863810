import React, { useEffect, useState, useRef } from 'react'
import apiService from '../../../Services/apiServices'
import DataTable from '../../../PageComponents/UI/Tables/DataTable';
import helper from '../../../Services/helper';
import Select from "react-select"
import { Switch } from '@material-ui/core';
import ExcelExport from '../../../PageComponents/Excel/ExcelExport';
import Search from '../../../PageComponents/Search/Search_Old';
import { MDBInput } from 'mdb-react-ui-kit';
import Print from '../../../PageComponents/Print/Print';
import globals from '../../../Services/globals';
import LoaderComponent from '../../../PageComponents/UI/Components/LoaderComponent';
import { TRUE } from 'sass';

const ReportPurchaseBook = (params) => {
    const [data, setData] = useState([])
    const d = new Date();
    const searchObj = { CompanyBranch: 0, PurchaseType: params.match.params.id ? parseInt(params.match.params.id) : 0, StartDate: '', EndDate: (new Date()).toISOString().substring(0, 10), WarehouseId: 0, includeStartDate: true, includeEndDate: true }
    const [searchQuery, setSearchQuery] = useState(searchObj)
    const [purchaseType, setPurchaseType] = useState(3)
    const [partners, setPartners] = useState()
    const [purchaseTypes, setPurchaseTypes] = useState()
    const [warehouses, setWarehouses] = useState()
    const [loader , setLoader] = useState(false);
    const [open, setOpen] = useState(true)
    const logedInUser = globals().getLogedInUser()

    const selectStyles = {
        control: (provided, state) => ({
            ...provided,
            background: '#fff',
            borderColor: '#9e9e9e',
            minHeight: '28.59px',
            height: '28.59px',
            boxShadow: state.isFocused ? null : null,
        }),

        valueContainer: (provided, state) => ({
            ...provided,
            height: '28.59px',
            padding: '0 6px'
        }),

        input: (provided, state) => ({
            ...provided,
            margin: '0px',
        }),
        indicatorSeparator: state => ({
            display: 'none',
        }),
        indicatorsContainer: (provided, state) => ({
            ...provided,
            height: '28.59px',
        }),
    };

    const t = (key) => {
        return helper().translate(key);
    }

    // d.setDate(d.getDate() - 30);
    // searchObj.StartDate = d.toISOString();
    // searchObj.StartDate = searchObj.StartDate.substring(0, 10);

    let date = new Date(), y = date.getFullYear(), m = date.getMonth();
    searchObj.StartDate = new Date(y, m, 2) //new Date(y, m, 2);
    searchObj.StartDate = searchObj.StartDate.toISOString();
    searchObj.StartDate = searchObj.StartDate.substring(0, 10);

    const excelExportRef = useRef();
    const printRef = useRef();

    const tables = [
        { name: 'partners', method: setPartners },
        { name: 'purchaseType', method: setPurchaseTypes },
        { name: 'warehouses', method: setWarehouses },
    ];

    const months = [
        { value: 1, label: 'Janar' },
        { value: 2, label: 'Shkurt' },
        { value: 3, label: 'Mars' },
        { value: 4, label: 'Prill' },
        { value: 5, label: 'Maj' },
        { value: 6, label: 'Qershor' },
        { value: 7, label: 'Korrik' },
        { value: 8, label: 'Gusht' },
        { value: 9, label: 'Shtator' },
        { value: 10, label: 'Tetor' },
        { value: 11, label: 'Nëntor' },
        { value: 12, label: 'Dhjetor' },
    ]

    const books = [
        {nr: 1, name: 'Libri i blerjes mall', formName: 'BookOfGoodsPurchases'},
        {nr: 2, name: 'Libri i investimeve', formName: 'BookOfInvestmentsPurchases'},
        {nr: 3, name: 'Libri i shpenzimeve', formName: 'BookOfExpensessPurchases'},
        {nr: 4, name: 'Libri not debiti', formName: 'BookOfNotDebitPurchases'},
        {nr: 5, name: 'Libri kthimi i blerjeve', formName: 'BookOfReturnedPurchases'},
        {nr: 6, name: 'Libri ngarkesa e kundërt', formName: 'BookOfReverseChargePurchases'},
    ]
    const reportName = books.find(b => b.nr === searchQuery?.PurchaseType) ? books.find(b => b.nr === searchQuery?.PurchaseType)?.name : helper().translate("BUYERS_BOOK")
    const formName = books.find(b => b.nr === searchQuery?.PurchaseType) ? books.find(b => b.nr === searchQuery?.PurchaseType)?.formName : ''

    const [years, setYears] = useState()

    const checkColumn = (header, items) => {
        if(header && header.subHeader){
            //const isTrue = (currentValue) => parseFloat(currentValue[header.key]) != 0;
            const isTrue = (currentValue) => !currentValue[header.key] || currentValue[header.key] == 0;
            if(!items?.every(isTrue)) {
                return header
            }
        }
        else{
            return header
        }
    }

    const headers = [
        { key: 'Pos', columnName: t('NR'), description: 'Nr' , dontShowMenu: true },
        { key: 'ID', columnName: t('ID'), noPrint: true },
        { key: 'Date', columnName: t('DATE') },
        { key: 'InvoiceNo', columnName: t('INVOICE_NO') },
        { key: 'Reference', columnName: t('REFERENCE') , type:'comment'},
        { key: 'CompanyName', columnName: t('COMPANY_NAME') , type:"text" },
        { key: 'FiscalNumber', columnName: t('FISCAL_NUMBER') },
        { key: 'VatNumber', columnName: t('VAT_NUMBER') , type:"number"  },
        purchaseType === 0 && { key: 'K9', columnName: t('K9'), subHeader: true },
        purchaseType === 0 && { key: 'K10a', columnName: t('K10A'), subHeader: true },
        purchaseType === 0 && { key: 'K10b', columnName: t('K10B'), subHeader: true },
        purchaseType === 0 && { key: 'K10c', columnName: t('K10C'), subHeader: true },
        purchaseType === 0 && { key: 'K10', columnName: t('K10'), subHeader: true },
        purchaseType === 0 && { key: 'K11', columnName: t('K11'), subHeader: true },
        purchaseType === 0 && { key: 'K12', columnName: t('K12'), subHeader: true },
        purchaseType === 0 && { key: 'K16', columnName: t('K16'), subHeader: true },
        purchaseType === 0 && { key: 'K20', columnName: t('K20'), subHeader: true },
        purchaseType === 0 && { key: 'K24', columnName: t('K24'), subHeader: true },
        purchaseType === 0 && { key: 'K28', columnName: t('K28'), subHeader: true },
        
        purchaseType === 0 && { key: 'K14', columnName: t('K14'), subHeader: true },
        purchaseType === 0 && { key: 'K18', columnName: t('K18'), subHeader: true },
        purchaseType === 0 && { key: 'K22', columnName: t('K22'), subHeader: true },

        // [0, 1].includes(purchaseType) && { key: 'K31', columnName: t('K31'), description: 'Blerjet dhe importet pa TVSh', subHeader: true, calculate: true },
        // [0, 1].includes(purchaseType) && { key: 'K35', columnName: t('K35'), description: 'Importet me 18%', subHeader: true, calculate: true },
        // [0, 2].includes(purchaseType) && { key: 'K47', columnName: t('K47'), description: 'Blerjet investive vendore me 18%', subHeader: true, calculate: true },
        // [0, 1, 3].includes(purchaseType) && { key: 'K43', columnName: t('K43'), description: 'Blerjet vendore me 18%', subHeader: true, calculate: true },
        // [0, 4, 5].includes(purchaseType) && { key: 'K53', columnName: t('K53'), description: 'Nota debitore e pranuar, nota kreditore e leshuar me 18%', subHeader: true, calculate: true },
        // [0, 6].includes(purchaseType) && { key: 'K65', columnName: t('K65'), description: 'E drejta e kreditimit te TVSh-se ne lidhje me ngarkesen e kundert 18%', subHeader: true, calculate: true },
        // [0, 1, 2, 3, 4, 5, 6].includes(purchaseType) && { key: 'K1', columnName: t('K1'), description: 'Totali i Tvsh-së së zbritshme me 18%', subHeader: true, calculate: true, calculate: true },
        // [0, 1, 3].includes(purchaseType) && { key: 'K45', columnName: t('K45'), description: 'Blerjet vendore me 8%', subHeader: true, calculate: true },
        // [0, 1, 3].includes(purchaseType) && { key: 'K2', columnName: t('K2'), description: 'Totali i Tvsh-së së zbritshme me 8%', subHeader: true, calculate: true },
        // [0, 1, 2, 3, 4, 5, 6].includes(purchaseType) && { key: 'K67', columnName: t('K67'), noPrint: true },
        // {key: 'GjithesejBlerja', columnName: 'Gjithesej Blerja', subHeader: true, calculate: true}


        { key: 'K31', columnName: t('K31'), description: 'Blerjet dhe importet pa TVSh', subHeader: true, calculate: true , type:"number" },
        { key: 'K35', columnName: t('K35'), description: 'Importet me 18%', subHeader: true, calculate: true  , type:"number" },
        { key: 'K47', columnName: t('K47'), description: 'Blerjet investive vendore me 18%', subHeader: true, calculate: true  , type:"number"},
        { key: 'K43', columnName: t('K43'), description: 'Blerjet vendore me 18%', subHeader: true, calculate: true , type:"number" },
        { key: 'K53', columnName: t('K53'), description: 'Nota debitore e pranuar, nota kreditore e leshuar me 18%', subHeader: true, calculate: true  , type:"number"},
        { key: 'K65', columnName: t('K65'), description: 'E drejta e kreditimit te TVSh-se ne lidhje me ngarkesen e kundert 18%', subHeader: true, calculate: true  , type:"number"},
        { key: 'K39', columnName: t('K39'), description: 'Importet investive me 18%', subHeader: true, calculate: true , type:"number"},
        { key: 'K1', columnName: t('K1'), description: 'Totali i Tvsh-së së zbritshme me 18%', subHeader: true, calculate: true  , type:"number"},
        { key: 'K41', columnName: t('K41'), description: 'Importet investive me 8%', subHeader: true, calculate: true , type:"number"},
        { key: 'K45', columnName: t('K45'), description: 'Blerjet vendore me 8%', subHeader: true, calculate: true , type:"number"},
        { key: 'K2', columnName: t('K2'), description: 'Totali i Tvsh-së së zbritshme me 8%', subHeader: true, calculate: true , type:"number" },
        { key: 'K67', columnName: t('K67'), noPrint: true  , type:"number" },
        {key: 'GjithesejBlerja', columnName: 'Gjithesej Blerja', subHeader: true, calculate: true , type:"number"}
    ];

    

    const fillYears = () => {
        let s = d.getFullYear() - 10
        let e = d.getFullYear()
        let years = []

        for (let index = s; index <= e; index++) {
            let obj = { value: index, label: index }
            years.push(obj)
        }
        setYears(years)
    }

    const getDefaults = (name, method) => {
        apiService().get(name).then((response) => {
            if (response.success) {
                let records = response.data
                records = records.map(record => ({ value: record.id, label: name != 'partners' ? t(`${record.name}`)  : record.saveAs }))
                method(records)
            }
        })
    }

    const getData = () => {
        setLoader(true);
        var params = `CompanyBranch=${searchQuery.CompanyBranch}&PurchaseType=${searchQuery.PurchaseType}&StartDate=${searchQuery.includeStartDate ? searchQuery.StartDate : "1900-01-01"}&EndDate=${searchQuery.EndDate+" 23:59:59"}&WarehouseId=${searchQuery.WarehouseId}`;
        apiService().query('RptPurchaseBook', params)
            .then((response) => {
                if (response.success) {
                    setLoader(false);
                    let d = response.data;
                    d.forEach(d => {
                        d.Import ? d.InvoiceNo = d.DudNo : 0
                    })
                    d ? setData(d) : setData([])
                    setPurchaseType(searchQuery.PurchaseType)
                    
                }
            })
    }

    const print = () => {
        // window.open(`#/excel-print?CompanyBranch=${searchQuery.CompanyBranch}&YEAR=${searchQuery.YEAR}&StartMonth=${searchQuery.StartMonth}&EndMonth=${searchQuery.EndMonth}`, 'popUpWindow', 'height=1300,width="100%",left=100,top=100,right=100,bottom=100,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes')
        //setOpen(!open)
        printRef.current.printPreview()
    }

    const exportToExcel = () => {
        // let table = document.getElementById('table-to-xls-1').innerHTML;
        let fileName = helper().translate("BUYERS_BOOK")
        // return helper().exportToExcel(table, fileName)
        excelExportRef.current.exportToExcel(fileName)
    }

    const pdf = () => {
        var divContents = document.getElementById("dvContainer").outerHTML;
        var printWindow = window.open('', '', 'height=400,width=800');
        //printWindow.document.write('<html><head><title>DIV Contents</title>');
        //printWindow.document.write('</head><body >');
        printWindow.document.write(divContents);
        //printWindow.document.write('</body></html>');
        //printWindow.document.close();
        //printWindow.print();
    }

    useEffect(() => {
        //getData()        
        fillYears()
        tables.forEach((table, index) => {
            getDefaults(table.name, table.method)
        })
        getData()
    }, [])

    return (
        <div>
            {/* <div id="dvContainer">
                This content needs to be printed.
            </div>
            <button onClick={() => pdf()}>pdf</button> */}
            {open && <Print data={data.length ? data : []} headers={headers.filter(header => header !== false)} query={searchQuery} ref={printRef} reportName={reportName} formName={formName} />}
            <div className="container-fluid px-2 mt-3">
                <Search search={false} excel={() => exportToExcel()} printClick={print} back="#/reports"/>
                {/* <Search search={false} excel={exportToExcel} printClick={print}/> */}
                <div className="card border-left-primary shadow h-100 mt-1">
                    <div className="card-body pt-3 pb-1">
                        <div className="row">
                            <div className="col-md-11 col-12">
                                <div className="row no-gutters align-items-center">
                                    <div className="col mr-2 row">
                                        <div className='w-100 d-md-flex d-lg-flex' >
                                            <div className="col-lg-3 mb-3 mb-md-0 pe-0 d-flex align-items-center">
                                                <div style={{ width: '5%' }}>
                                                    <input type="checkbox" name="includeStartDate" checked={searchQuery.includeStartDate ? 'checked' : ''} onChange={(e) => setSearchQuery({ ...searchQuery, includeStartDate: e.target.checked })}></input>
                                                </div>
                                                <div style={{ width: '95%' }}>
                                                    <MDBInput
                                                        name="StartDate"
                                                        disabled={!searchQuery.includeStartDate ? 'disabled' : ''}
                                                        className="form-control-sm active"
                                                        onChange={(e) => setSearchQuery({ ...searchQuery, StartDate: e.target.value })}
                                                        type="date"
                                                        label={helper().translate("START_DATE")}
                                                        //value={searchQuery.StartDate ? helper().formatDate(searchQuery.StartDate, 'yyyy-MM-dd') : (new Date()).toISOString().substring(0, 10)}
                                                        value={searchQuery.StartDate}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-3 mb-3 mb-md-0 pe-0 d-flex align-items-center">
                                                <div style={{ width: '5%' }}>
                                                    <input type="checkbox" name="includeEndDate" checked={searchQuery.includeEndDate ? 'checked' : ''} onChange={(e) => setSearchQuery({ ...searchQuery, includeEndDate: e.target.checked })}></input>
                                                </div>
                                                <div style={{ width: '95%' }}>
                                                    <MDBInput
                                                        name="EndDate"
                                                        disabled={!searchQuery.includeEndDate ? 'disabled' : ''}
                                                        className="form-control-sm active"
                                                        onChange={(e) => {setSearchQuery({ ...searchQuery, EndDate: e.target.value }), console.log('e: ', e.target.value)}}
                                                        type="date"
                                                        label={helper().translate("END_DATE")}
                                                        //value={searchQuery.EndDate ? helper().formatDate(searchQuery.EndDate, 'yyyy-MM-dd') : (new Date()).toISOString().substring(0, 10)}
                                                        value={searchQuery.EndDate}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3 col-lg-3 mb-3 mb-md-0 pe-0">
                                                <Select styles={selectStyles} placeholder={helper().translate("PURCHASE_TYPE")} value={purchaseTypes?.find(p => p.value === searchQuery.PurchaseType)} options={purchaseTypes?.filter(pt => pt.value !== 0)} onChange={(e) => { setSearchQuery({ ...searchQuery, PurchaseType: e.value })}} />
                                            </div>
                                            <div className="col-md-3 col-lg-3 mb-3 mb-md-0 pe-0">
                                                <Select styles={selectStyles} placeholder={helper().translate("WAREHOUSE")} value={warehouses?.find(w => w.value === searchQuery.WarehouseId)} options={warehouses} onChange={(e) => { setSearchQuery({ ...searchQuery, WarehouseId: e.value }) }} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-auto">
                                        <a className="searchbutton" onClick={() => getData()}>
                                            <i className="fas fa-search fa-2x text-dark"  />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card shadow mt-1">
                    <div className="card-header py-2">
                        <h6 className="m-0 font-weight-bold text-primary text-center">{helper().translate("BUYERS_BOOK")}</h6>
                    </div>
                    {loader && <LoaderComponent /> }
                    {!loader? data.length ? <DataTable data={data.length ? data : []} headers={headers?.filter(header => checkColumn(header, data))} formName={formName} /> : <div className="p-5 text-center">{helper().translate("NO_DATA")}</div> : ""}
                    <ExcelExport data={data.length ? data : []} headers={headers} type={'other'} ref={excelExportRef} hideContent={true} />
                </div>
            </div>
        </div>
    )
}

export default ReportPurchaseBook;
