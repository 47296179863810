import globals from "../Services/globals";
class TransportDetail {
    constructor(obj){
        var user = globals().getLogedInUser().data

        if(obj){ 
            this.id = obj.id;
            this.no = obj.no;
            this.date = obj.date;
            this.partnerId = obj.partnerId
            this.purchaseId = obj.purchaseId
            this.invoiceNo = obj.invoiceNo
            this.invoiceSum = obj.invoiceSum
            this.comment = obj.comment
            this.createdAt = obj.createdAt ? obj.createdAt : new Date()
            this.createdBy = obj.createdBy ? obj.createdBy : user.name +" "+ user.surname
            this.changedAt = new Date() //obj.changedAt 
            this.changedBy = user.name +" "+ user.surname //obj.changedBy
            this.status = obj.status
            this.editMode = obj.editMode
         }
        else {
            this.id = 0
            this.no = 1
            this.date = ''
            this.partnerId = 0
            this.purchaseId = 0
            this.invoiceNo = ''
            this.invoiceSum = 0.0
            this.comment = ''
            this.registeredBy = ''
            this.createdAt = new Date
            this.createdBy = user.name +" "+ user.surname
            this.changedAt = new Date
            this.changedBy = user.name +" "+ user.surname
            this.status = 0
            this.editMode = false
        }
    }
}

export default TransportDetail