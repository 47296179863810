import React, { useEffect, useState } from 'react'
import GridView from './GridView';


const TestGrid = (props) => {

    const [data, setData] = useState();

    let ddata = [
        {id:1, name: 'Veton', email:'vsha@nsum.net', phone:'044174371', price: 5003.45234},
        {id:2, name: 'Muhamed', email:'mshala@nsum.net', phone:'044903142'},
        {id:3, name: 'Shuajb', email:'shshala@nsum.net', phone:'048174372'},
    ]

    let headers = [ 
        {name:'Nr', key:'id', hidden:false},
        {name:'Emri', key:'name'},
        {name:'Emaili', key:'email'},
        {name:'Tel', key:'phone'},

    ]

    useEffect(()=>{
        setData(ddata);
    },[]);

    return (
        <div className="card-body table-responsive">
          <GridView data={data} headers={headers}></GridView>
        </div>
    );

}

export default TestGrid;
