import { React, useEffect, useState } from "react";
import globals from "../Services/globals";
import "./Databases.scss";
import config from "../config/config";
import helper from "../Services/helper";
// import logo from "../img/t3logo.png";
import apiService from "../Services/apiServices";
import loginService from '../Services/loginService.js';
import LoaderComponent from "../PageComponents/UI/Components/LoaderComponent";
import { v4 as uuidv4 } from 'uuid';

export default function Databases() {
  const [databases, setDatabases] = useState([]);
  const [filterRes, setFilterRes] = useState([]);
  const [radioVal, setRadioVal] = useState("");
  const [subscription, setSubscription] = useState();
  const [company, setCompany] = useState("");
  const [loader, setLoader] = useState("");
  const [createBtn, setCreateBtn] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const setUserCompany = (user) => {

    var jsValue = JSON.stringify(user);
    let rememberMe = helper().getItem('rememberMe');
    let exdays = (rememberMe) ? 4 : 0;
    helper().setItem("logedInUser", jsValue, exdays);
    if (user.company) {
      helper().deleteItem('settings');
      helper().deleteItem('userSettings');
      globals().getSettings();
      globals().getUserSettings(user, true).then(() => {
        window.location = config.homepage;
      });
    }
  }

  const getDb = () => {
    var user = globals().getLogedInUser();
    setCompany(user.company);
    if (user) {
      apiService().getDatabases("getUserDatabases?username=" + user.data.username).then((response) => {
        if (response.success) {
          var databases = response.data;
          setDatabases(databases);
          setFilterRes(databases);
          setLoader(false);
        }
      });
    }
  }

  useEffect(() => {
    setLoader(true);

    var user = globals().getLogedInUser();
    setCompany(user.company);
    if (user) {
      apiService().getDatabases("getUserDatabases?username=" + user.data.username).then((response) => {
        if (response.success) {
          var databases = response.data;
          setDatabases(databases);
          setFilterRes(databases);
          setLoader(false);
        }
      });
    }
  }, []);

  const logout = (e) => {
    loginService().logout();
    //window.location = '#/login';
  }

  const searchItems = (searchValue) => {
    if (searchValue !== "") {
      const filteredData = databases.filter((item) => {
        return Object.values(item).join("").toLowerCase().includes(searchValue.toString().toLowerCase());
      });
      if (filteredData.length == 0) {
        setCreateBtn(true);
        setInputValue(searchValue);

      }
      else {
        setCreateBtn(false);
      }
      setFilterRes(filteredData);
    } else {
      setFilterRes(databases);
    }
  };

  const chooseDatabase = () => {
    var user = globals().getLogedInUser();
    user.company = radioVal;
    user.subscription = subscription;
    setUserCompany(user);
  };

  const createDatabase = () => {
    setLoader(true);
    var user = globals().getLogedInUser();
    let dbName = inputValue.replace(/[ .@]/g, '');
    //dbName = dbName + '_' + user.data.username.substring(0, 2) + uuidv4().substring(0, 5).toString();
    // console.log(dbName);
    // console.log(user.data.username);
    // console.log(user.subscription);
    var isDbExist = `dbname=${dbName}`;
    apiService()
      .queryT3Services('dbExist', isDbExist)
      .then((response) => {
        if (response.success) {
          var createDb = `dbname=${dbName}&version=${'1.0.6'}`;
          apiService()
            .queryT3Services('createAllDb', createDb)
            .then((response) => {
              if (response.success) {
                var addSubscription = `SubscriptionId=${user.subscription}&DbName=${dbName}&UserName=${user.data.username}`;
                apiService()
                  .queryT3Services('CreateSubscriptionUser', addSubscription)
                  .then((response) => {
                    if (response.success) {
                      //responseMessage(response, 'Created Successfully')
                      //window.location = '#/selectcompany';
                      setInputValue("")
                      getDb();
                      setCreateBtn(false);
                      setLoader(false);
                    }
                    else {
                      console.log({ err: response.errorMessage, txt: "Sub" })
                      //setalertProp({ open: true, message: response.errorMessage, color: "error" });
                    }
                  });
              }
              else {
                console.log({ err: response.errorMessage, txt: "DB" })

                //setLoader(false);
                //setalertProp({ open: true, message: response.errorMessage, color: "error" });
              }
            });
        }
        else {
          setLoader(false);
          alert(response.errorMessage);
        }
      });
  };
  const chooseCompany = (companyName, subscriptionId) => {
    setRadioVal(companyName);
    setSubscription(subscriptionId);
  };

  return (
    <div className="body">
      {loader && <div className="d-flex justify-content-center align-items-center min-vh-100">
        <LoaderComponent />
      </div>}
      {!loader && <div>
        {!company && <nav className="navbar navbar-light bg-light justify-content-between">
          {/* <img src={logo} id="logo" style={{ height: "50px" }} /> */}
          <button className="btn btn-outline-primary my-2 my-sm-0" onClick={logout}>Sign Out</button>
        </nav>}
        <div className="select-database">
          <div className="sticky-top">
            <div className="card p-3 shadow-sm">
              <input
                type="text"
                className="form-control form-control-lg mb-2"
                placeholder="Search Database"
                // name="search"
                // value={inputValue}
                onChange={(e) => searchItems(e.target.value)}
              />
              <div className="text-right">
                <button className="btn btn-primary" hidden={createBtn} disabled={!radioVal} onClick={chooseDatabase}>
                  Continue
                </button>
                {company && <button className="btn btn-primary" hidden={!createBtn} onClick={createDatabase}>
                  Create
                </button>}
              </div>
            </div>
          </div>
          {filterRes.map((c) => (
            <div className="inputGroup shadow-sm" key={c.Name}>
              <input
                id={c.Name}
                value={c.Name}
                name="radio"
                type="radio"
                onChange={() => chooseCompany(c.Name, c.Id)}
              />
              <label htmlFor={c.Name}>{c.Name}</label>
            </div>
          ))}
        </div>
      </div>}
    </div>

  );
}
