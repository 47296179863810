import React, { useState, useEffect, useRef } from 'react';
import globals from '../../Services/globals';
import helper from '../../Services/helper';

const SubNav = (params) => {
    const [settings, setSettings] = useState()
    const [toggle, setToggle] = useState(false)
    const links = params.links

    const renderForm = () => {
        return links?.map((link, index) => {
            if (link.type === "menu" && !link.subMenu) return <li  className={`nav-item ${link?.disabled === true ? "pe-none" : ""} `} key={index}>
                <a href={`#/${link.path}`} className={`nav-link py-2 `}>
                    <div className={!toggle ? "d-flex align-items-center" : ""} >
                        <div ><i className={link.icon} /></div>
                        <div ><span >{link.name}</span></div>
                    </div>
                </a>
                <hr className="sidebar-divider my-0" />
            </li>
            else if (link.type === "menu" && link.subMenu) return <li className={`nav-item ${link?.disabled === true ? "pe-none" : ""} `} key={index}>
                <a className="nav-link collapsed py-2" href="#" data-toggle="collapse" data-target={`#${link.path}`} aria-expanded="true" aria-controls="collapseOne">
                    <i className={link.icon} />
                    <span>{link.name}</span>
                </a>
                <div id={link.path} className="collapse" aria-labelledby="headingOne" data-parent="#accordionSidebar">
                    <div className="bg-white py-2 collapse-inner rounded">
                        {link.subMenu.map((sub, index) => {
                            return <a key={index} className="collapse-item" href={(sub.type === 'hash') ? `#/${link.path}#${sub.path}` : `#/${sub.path}`}>
                                <i className={sub.icon} />
                                <span>{sub.name}</span>
                            </a>
                        })}
                    </div>
                </div>
                <hr className="sidebar-divider my-0" />
            </li>
        });
    }

    const getUserSettings = () => {
        globals().getUserSettings().then((sett) => {
            if (sett) { setSettings(sett) }
        });
    }

    useEffect(() => {
        getUserSettings()
        const interval = setInterval(() => {
            getUserSettings();
        }, 1000);
        return () => clearInterval(interval);
    }, [])

    useEffect(() => {
        localStorage.setItem('toggle', toggle);
        //setToggle(localStorage.getItem('toggle'))
    }, [toggle])
    return (
        <div id="subNav" style={{ background: settings?.style ? settings.style : '#5a5c69' }} className={``}>
            <button onClick={() => setToggle(!toggle)} id="sidebarToggleTop" style={{ background: settings?.style ? settings.style : '#5a5c69' }} className="btn d-md-none mr-3 shadow">
                <i className="fa fa-bars" />
            </button>
            <ul className={`navbar-nav sidebar sidebar-dark accordion position-sticky top-0 left-0 bottom-0 ${toggle ? "toggled" : ""}`} id="accordionSidebar">
                {renderForm()}
                <div className="text-center d-none d-md-inline mt-4">
                    <button onClick={() => setToggle(!toggle)} className="rounded-circle border-0" id="sidebarToggle" />
                </div>
            </ul>

        </div>
    )
}

export default SubNav
