import React, { useState, useEffect , useRef } from "react";
import globals from "../../../Services/globals";
// import Loader from 'react-loader-spinner';
import PropagateLoader from "react-spinners/PropagateLoader";



function LoaderComponent (props) { 

    const [userSettings , setUserSettings] = useState();


useEffect(()=>{
    let user = globals().getLogedInUser();
    getUserSettings(user);
  },[])


const getUserSettings = (user) => {
    globals().getUserSettings(user).then((response) => {
     setUserSettings(response)
    });
  }
  
    return (
        // <Loader type="ThreeDots" className="text-center" color={userSettings?.style ?  userSettings?.style : "#2BAD60"} height="100" width="100" />
        // <BarLoader
        //   color={userSettings?.style ?  userSettings?.style : "#2BAD60"}
        //   cssOverride={{marginLeft: "auto" , marginRight: "auto", marginTop:"30px" , marginBottom: "50px"
        // }}
        //   height={5}
        //   loading
        //   speedMultiplier={1}
        //   width={1000}
        // />
        <PropagateLoader  color={userSettings?.style ?  userSettings?.style : "#2BAD60"} cssOverride={{position:"absolute" , left:"50%", top:"100px"
         }} />
    )
}
export default LoaderComponent;

