import React from 'react';
import ReactDOM from 'react-dom';
import 'mdb-react-ui-kit/dist/css/mdb.min.css'
import App from './App';
import './index.css';
import './css/bootstrap.min.css'
import './css/sb-admin-2.css';
import '../node_modules/@fortawesome/fontawesome-free/css/all.min.css';
import '../node_modules/jquery/dist/jquery.min.js';
import '../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js';
import "../node_modules/bootstrap/dist/js/bootstrap"
import '../node_modules/jquery.easing/jquery.easing.js';
import './App.css'

// import './js/main.js'
// import './js/jquery.min.js'

ReactDOM.render(
  <App />,
  document.getElementById('root')
);
