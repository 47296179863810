import React, { useEffect, useState } from 'react';
import { MDBInput } from 'mdb-react-ui-kit';
import HeaderTools from '../../PageComponents/Header/HeaderTools.js';
import helper from '../../Services/helper';
import apiService from '../../Services/apiServices.js';
import { useToasts } from 'react-toast-notifications';
import globals from '../../Services/globals.js';
import loginService from '../../Services/loginService.js';

const ResetPassword = () => {
    const { addToast } = useToasts();
    const [user, setUser] = useState({ oldPassword: '', newPassword: '', confirmNewPassword: '' });
    const logedInUser = globals().getLogedInUser();


    const save = async () => {
        if (user.oldPassword && user.newPassword && user.confirmNewPassword) {
            if (validate(user.newPassword, user.confirmNewPassword)) {
                await loginService().queryMyLogins('IsCorrect', 'userName=' + logedInUser.data.username + '&password=' + user.oldPassword)
                    .then((response) => {
                        if (response.success) {
                            if (response.data[0].IsCorrect) {
                                if (user.newPassword === user.confirmNewPassword) {
                                    user.id = logedInUser.data.id
                                    user.password = user.newPassword
                                    loginService().putMyLogins('resetPassword', user)
                                        .then((response) => {
                                            responseMessage(response, 'Saved Successfully')
                                        });
                                }
                            }
                            else {
                                addToast(helper().translate('OLD_PASSWORD_VALIDATE'), { appearance: 'error', autoDismiss: true, });
                            }
                        }
                        else responseMessage(response, response.errorMessage)
                    });
            }
        }
        else {
            addToast(helper().translate('FILL_FIELDS_TO_CONTINUE'), { appearance: 'error', autoDismiss: true, });
        }
    }
    const responseMessage = (response, message) => {
        if (response.success) {
            addToast(message, { appearance: 'success', autoDismiss: true, });
        }
        else {
            addToast(response.errorMessage, { appearance: 'error', autoDismiss: true, });
        }
    }

    const validate = (newPass, confirmPass) => {
        if (newPass !== confirmPass) {
            addToast(helper().translate('NEWPWD_AND_CONFIRMPWD_INCORRECT'), { appearance: 'error', autoDismiss: true, });
        }
        else {
            return true
        }
    }

    return (
        <div className="col-lg-8">

            <div className="card border-left-primary shadow py-0 mb-2">
                <div className="card-body py-0">
                    <div className="row no-gutters align-items-center">
                        <div className="col mr-2 row">
                            <div className="col-lg-12 form-group my-0 d-flex align-items-center">
                                <div className="d-table-cell no-shadow">
                                    <HeaderTools save={() => save()} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card mb-4" >
                <div className="card-body">
                    <div className="row">
                        <div className="col-sm-4">
                            <p className="mb-0">{helper().translate('OLD_PASSWORD')}</p>
                        </div>
                        <div className="col-sm-8">
                            <MDBInput type="password" name="oldPassword" value={user?.oldPassword ? user?.oldPassword : ""} onChange={(e) => setUser({ ...user, oldPassword: e.target.value })} className="form-control text-black mb-3" id="inputUserName" label={helper().translate('OLD_PASSWORD')} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-4">
                            <p className="mb-0">{helper().translate('NEW_PASSWORD')}</p>
                        </div>
                        <div className="col-sm-8">
                            <MDBInput type="password" name="newPassword" value={user?.newPassword ? user?.newPassword : ""} onChange={(e) => setUser({ ...user, newPassword: e.target.value })} className="form-control text-black mb-3" id="inputUserName" label={helper().translate('NEW_PASSWORD')} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-4">
                            <p className="mb-0">{helper().translate('CONFIRM_NEW_PASSWORD')}</p>
                        </div>
                        <div className="col-sm-8">
                            <MDBInput type="password" name="confirmNewPassword" value={user?.confirmNewPassword ? user?.confirmNewPassword : ""} onChange={(e) => { setUser({ ...user, confirmNewPassword: e.target.value }) }} className="form-control text-black mb-3" id="inputUserName" label={helper().translate('CONFIRM_NEW_PASSWORD')} />
                            {/* <span className='error'>{!validate(user?.newPassword, user?.confirmNewPassword) ? 'Duhet te jete i njejte' : ''}</span> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default ResetPassword