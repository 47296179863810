/***
 * Global Service
 * V.sh. 16.01.2021 17:17
 * Get set global configurations for application
 ***/

import helper from './helper';
import apiService from './apiServices';

export default function globals() {

    //returs objec of type user
    function getLogedInUser() {
        var strUser = helper().getItem('logedInUser');
       
        if (strUser) {
            var isValid = helper().isValidJsonString(strUser);
            var user = (isValid) ? JSON.parse(strUser) : null;
            return user;
        }
        else {
            return null;
        }
    }

    //returns promise: call globals().getSettings().then();
    function getSettings(haveChange) {        
        var strSettings = helper().getItem('settings');
        if(strSettings && !haveChange){
            var isValid = helper().isValidJsonString(strSettings);
            var settings = (isValid) ? JSON.parse(strSettings) : null;           
            return new Promise((resolve,reject) =>{            
                resolve(settings);            
            });
        }
        else{
            return apiService().get('settings')
            .then(response => {
                if (response.success && response.data.length > 0) {
                    var settings = response.data[0];
                    strSettings = JSON.stringify(settings);
                    //ruje si json string ne cookies ose sessionStorage
                    helper().setItem('settings', strSettings);
                    //kthe objekt
                    return settings;
                }
            })
        }
    }       

    //return promise: call globals().getUserSettings().then();
    function getUserSettings(user, haveChange) {
        var strUserSettings = helper().getItem('userSettings');
        if(strUserSettings && !haveChange){
            var isValid = helper().isValidJsonString(strUserSettings);
            var userSettings = (isValid) ? JSON.parse(strUserSettings) : null;           
            return new Promise((resolve,reject) =>{            
                resolve(userSettings);            
            });
    }
        else{
            user = getLogedInUser();
            return apiService().query('userSettings', `username=${user.data.username}`)
            .then(response => {
                var userSettings = null;
                if (response.success) {
                    if(response.data && response.data.length > 0){
                        userSettings = response.data[0];
                        
                    } 
                }

                if(!userSettings){
                    //if usersettings is null set default
                    userSettings = {allowToChangeSalePrice:false,digitsOnDetails:2,digits:2,style:"#4e73df",searchStatus:false,allowToChangeWarehouse:false,allowToDelete:false,language:"sq"};
                }

                strUserSettings = JSON.stringify(userSettings);
                //ruje si json string ne cookies ose sessionStorage
                helper().setItem('userSettings', strUserSettings);
                //kthe objekt
                return userSettings;
            });
        }
    }

    return {
        //returs objec of type user
        getLogedInUser: getLogedInUser,       
        getSettings: getSettings,
        getUserSettings: getUserSettings,
    }
}