//todo add service for translate\
import sqFile from '../resources/language/sq-AL.json';
import enFile from '../resources/language/en-US.json';
import globals from './globals';
import currencyServices from './currencyServices';

/***
 * Helper Services
 * V.sh. 29.04.2020
 * */
export default function helper() {
    const sq = sqFile;
    const en = enFile;
    var userSettings = JSON.parse(getItem('userSettings'));

    //get paramet from query url
    function getParameterByName(name, url) {
        if (!url) url = window.location.href;
        name = name.replace(/[[\]]/g, '\\$&');
        var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }

    function getRandomInt(max) {
        return Math.floor(Math.random() * Math.floor(max));
    }

    function getItemFromCookie(key) {
        var name = key + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) === ' ') c = c.substring(1);
            if (c.indexOf(name) === 0) return c.substring(name.length, c.length);
        }
        return "";
    }

    function deleteCookie(key) {
        document.cookie = key + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    }

    function setItemToCookie(key, value, exdays) {

        //remove previous cookie
        deleteCookie(key);
        var d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        var expires = "expires=" + d.toUTCString();
        document.cookie = key + "=" + value + "; " + expires;
    }

    function getItem(key){
        let item = getItemFromCookie(key);
        if(item){
            return item;
        }
        else{
            item = getItemFromSessionStorage(key);
            if (item){
                return item;
            }
            else{
                return null;
            }
        }
    }

    function setItem(key, value, exdays){
        //exdays = 4;
       if(exdays && exdays > 0){
           setItemToCookie(key, value, exdays);
       }
       else{
            //var strValue = JSON.stringify(value);
            setItemToSessionStorage(key, value);
       }
        
    }

    function deleteItem(key){
        deleteCookie(key);
        setItemToSessionStorage(key, '');
    }

    function isValidJsonString(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    function getItemFromSessionStorage(key) {       
        
        var storageValue = sessionStorage.getItem(key);
        return storageValue;      
    }

    function setItemToSessionStorage(key, value) {
        sessionStorage.setItem(key, value);
    }

    function formatDate(date, format) {
        if(!isValidDate(date)){
            return ''
        }

        if(!date ){
            return ''
        }
        if (!format)
            format = 'dd.MM.yyyy';

        var retvalue = '';

        var dateparts = format.split(' ');
        var datepartformat = dateparts[0];
        var timepartformat = dateparts[1];
        
        var date= new Date(Date.parse(date));  // date cannot be convert if u dont specify the incoming date to be type of Date
        var myYear = date.getFullYear();
        var myMonth = date.getMonth() + 1;
        var myDate = date.getDate();

        if (myMonth < 10)
            myMonth = '0' + myMonth;
        if (myDate < 10)
            myDate = '0' + myDate;

        if (datepartformat === 'dd.MM.yyyy')
            retvalue = myDate + "." + myMonth + "." + myYear;
        else if (datepartformat === 'dd-MM-yyyy')
            retvalue = myDate + "-" + myMonth + "-" + myYear;
        else if (datepartformat === 'MM-dd-yyyy')
            retvalue = myMonth + "-" + myDate + "-" + myYear;
        else if (datepartformat === 'dd/MM/yyyy')
            retvalue = myDate + "/" + myMonth + "/" + myYear;
        else if (datepartformat === 'MM/dd/yyyy')
            retvalue = myMonth + "/" + myDate + "/" + myYear;
        else if (datepartformat === 'yyyy-MM-dd')
            retvalue = myYear + "-" + myMonth + "-" + myDate;
        else
            retvalue = myMonth + "/" + myDate + "/" + myYear;

        if (timepartformat !== undefined) {

            var timeparts = timepartformat.split(':');

            //shto pjese per timepart
            var hours = this.getHours();
            var minutes = this.getMinutes();
            var seconds = this.getSeconds();

            if (hours < 10) hours = '0' + hours;
            if (minutes < 10) minutes = '0' + minutes;
            if (seconds < 10) seconds = '0' + seconds;

            var timepart = hours + ':' + minutes;
            if (timeparts[2] !== undefined) //nese eshte e definuar pjesa per sekonda
                timepart += + ':' + seconds;

            //bashkangjitja vleren per kohe
            retvalue += ' ' + timepart;
        }
        return retvalue;

    }

    function isValidDate(d) {
        return d instanceof Date && !isNaN(d) || typeof(d) === "string";
      }

    function formatNumber(num,isInvoiceDetail) {
        if(typeof(num) === 'number'){
            let digits = userSettings?.digits;
            let digitsOnDetail = userSettings?.digitsOnDetails
            if(isInvoiceDetail === true){
                return num.toLocaleString(undefined, {minimumFractionDigits: digitsOnDetail, maximumFractionDigits: digitsOnDetail});
            }else {
                return num.toLocaleString(undefined, {minimumFractionDigits: digits, maximumFractionDigits: digits});
            }
            
        }
       else return '0.00' ;
        // return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    }

    function toFixed2 (name, value, isInvoiceDetail) {
        let digits = userSettings?.digits
        let digitsOnDetail = userSettings?.digitsOnDetails
        let elem = document.getElementsByName(name);
        if (elem[0] === document.activeElement) {
            return value;
        } else {
            if(isInvoiceDetail === true){
                return parseFloat(value).toFixed(digitsOnDetail)
            }else {
                return parseFloat(value).toFixed(digits)
            }
        }
    }

    function translate(key) {
        //return translate or key if no translate for key
        let lang = userSettings?.language ? userSettings.language : 'sq';
        return eval(lang)[key] || key;
    }

    function exportCsv(data, click, fileName) {

        if (!data || data.length < 1) {
            alert('empty data');
            return;
        }

        let csvContent = "data:text/csv;charset=utf-8,";
        let header = '';//'No,Date,CaseNumberString\r\n';       

        var firstRow = data[0];
        for (var hprop in firstRow) {
            header += hprop + ',';
        }
        header += '\r\n';

        csvContent += header;

        for (var i = 0; i < data.length; i++) {
            let row = '';
            for (var prop in data[i]) {
                var value = data[i][prop];
                row += value + ',';
            }
            csvContent += row + "\r\n";
        };

        var encodedUri = encodeURI(csvContent);
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", fileName ? fileName+'.csv' : "export_data.csv");
        document.body.appendChild(link); // Required for FF

        click && link.click();
    }

    function exportToExcel(table, fileName){
        var htmls = "";

        var uri = 'data:application/vnd.ms-excel,'
        htmls = '<table>'+table+'</table>'
        var ctx = {
            worksheet : 'Worksheet',
            table : htmls
        }
        
        // template = "";
        
        var link = document.createElement("a");
        link.download = fileName+".xls";
        //link.href = uri + base64(format(template, ctx));
        link.href = uri + escape(htmls)
        link.click();
    }

    function formatCurrency(num){
        //todo: get symboll from settings
        let currencYSymbol =  currencyServices().symbol() ;
        let numbToFixed = formatNumber(num);
         return (numbToFixed + currencYSymbol);
    }

    return {
        test() { alert('test function') },
        getParameterByName: getParameterByName,
        getRandomInt: getRandomInt,
        //getItemFromCookie: getItemFromCookie,
        //setItemToCookie: setItemToCookie,
        //deleteCookie: deleteCookie,
        getItem: getItem,
        setItem: setItem,
        deleteItem: deleteItem,
        //getItemFromLocalStorage: getItemFromLocalStorage,
        //setItemToLocalStorage: setItemToLocalStorage,
        formatDate: formatDate,
        formatNumber: formatNumber,
        toFixed2:toFixed2,
        formatCurrency: formatCurrency,
        translate: translate,
        exportCsv: exportCsv,
        exportToExcel: exportToExcel,
        isValidJsonString: isValidJsonString,
        isValidDate: isValidDate
    }
}