import React, { Component , useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Partner from "../../../components/Partners/Partner";



  class SaleAddPartner extends Component {
    constructor(props) {
      super(props);
      this.state = {
      data: false
    };
  }

  handleShow = () => {
    this.setState({data: true})
  }
  handleClose = () => {
    this.setState({data: false})
    this.props.onClose();
  }

  


  render(){
    return (
      <div>

        <div className="position-relative"> 
          <a className="partner-btn" onClick={this.handleShow}   > <i className="fas fa-plus">
            </i></a>
        </div>
        <Modal
          
          show={this.state.data}
          size='lg'
          scrollable={true}
          onHide={this.handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title className="ms-3" >Shto Partner</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Partner props={this.props} closeModal={this.handleClose} noRedirect={true}  save={click => this.save = click} />
          </Modal.Body>
          <Modal.Footer>
          </Modal.Footer>
        </Modal >
        </div>
    );
  }
}

export default SaleAddPartner;