import React, { useState, useEffect , useRef } from "react";
import Select from "react-select";
import { MDBInput } from "mdb-react-ui-kit";
import apiService from "../../../Services/apiServices";
import { useToasts } from "react-toast-notifications";
import Search from "../../../PageComponents/Search/Search_Old";
import globals from "../../../Services/globals";
import { confirmAlert } from "react-confirm-alert";
import helper from "../../../Services/helper";
import Switch from "react-switch";
import calculate from "../../../Services/calculate";
import Pagination from "../../../PageComponents/UI/Pagination/Pagination";
import SelectByGroup from './../../../PageComponents/UI/Inputs/SelectByGroup'
import ExcelExport from "../../../PageComponents/Excel/ExcelExport";
import Print from "../../../PageComponents/Print/Print";
import LoaderComponent from "../../../PageComponents/UI/Components/LoaderComponent";
import { blue } from "@material-ui/core/colors";



function Stock(props) {
    const [findByDate , setFindByDate] = useState({itemId: null , warehouseId: null , dateStart: null, dateEnd:null , dateStartDisabled: true , dateEndDisabled: true });
    const [items, setItems] = useState([]);
    const [pageOfItems, setPageOfItems] = useState([]);
    const [find, setFind] = useState([]);
    const [branches , setBranches] = useState([]);
    const [warehouse ,setWarehouse] = useState([]);
    const [dataShow, setDataShow] = useState(true);
    const [itemsTotal, setItemsTotal] = useState(0);
    const [isPositive, setIsPositive] = useState(true);
    const [userSetStyle, setUserSetStyle] = useState("");
    const { addToast } = useToasts();
    const excelExportRef = useRef();
    const [loader , setLoader] = useState(false);
    const printRef = useRef();
    const searchObj = { CompanyBranch: 0, PurchaseType: -1, StartDate: findByDate.dateStart, EndDate: findByDate.dateEnd, WarehouseId: 0}


    useEffect(() => {
      getUserSettings();
      getDate();
      getWarehouses();
      getBranches();
      apiService()
      .get('settings')
          .then((response) => {
              if (response.success) {
              } else {
                  console.log('Error:' + response.errorMessage);
              }
          });   
  },[])

  const exportToExcel = () => {
    // let table = document.getElementById('table-to-xls-1').innerHTML;
    let fileName = helper().translate("STOCKS_IN_WAREHOUSES");
    // return helper().exportToExcel(table, fileName)
    excelExportRef.current.exportToExcel(fileName)
}

const print = () => {
  // window.open(`#/excel-print?CompanyBranch=${searchQuery.CompanyBranch}&YEAR=${searchQuery.YEAR}&StartMonth=${searchQuery.StartMonth}&EndMonth=${searchQuery.EndMonth}`, 'popUpWindow', 'height=1300,width="100%",left=100,top=100,right=100,bottom=100,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes')
  //setOpen(!open)
  printRef.current.printPreview()
}



const headers = [
  { key: 'no', columnName: helper().translate("NR") },
  { key: 'productNo', columnName: helper().translate("PRODUCT_NUMBER") },
  { key: 'barcode', columnName: helper().translate("BARCODE") },
  { key: 'itemName', columnName: helper().translate("ITEMNAME") , type:"text"  },
  { key: 'unit', columnName: helper().translate("UNIT") },
  { key: 'quantity', columnName: helper().translate("ACTUAL_QUANTITY") },
  { key: 'avgPrice', columnName: helper().translate("AVG_PRICE") , type:"number"  },
  { key: 'total', columnName: helper().translate("TOTAL") , calculate: true , type:"number" }
];

  const getItemsInStock =( dateEnd ,  warehouseId) =>{
    setLoader(true);
    let warehouse = warehouseId;
    if(warehouse == null){
      warehouse = 0;
    }
    var params = `&dateEnd=${dateEnd}&warehouseId=${warehouse}`;
    apiService()
    .query('RptGetItemsInStock', params )
      .then((response) => {
        if (response.success) {
          setLoader(false)
          let totalSum = 0;
          var items = response.data;
          var count = 1;
          // items.forEach(i => {
          //   i.salePrice = i.salePrice.toFixed(2);
          //   i.total = i.total.toFixed(2); 
          // });
          let filterItems  = items.filter(i => isPositive === true ? i.quantity > 0 : i.quantity < 0 );
          let filterFinal = filterItems.filter(i => i.inventoryItem === false );
          filterFinal.forEach(i=> {
            i.no = count; count++;
            totalSum = parseFloat(totalSum)  + parseFloat(i.total);
          });
          if(filterFinal.length > 0){
            setFind(filterFinal);
            setItems( filterFinal);
            setItemsTotal(parseFloat(totalSum));
            setDataShow(true);
          }else{
            setDataShow(false);
          }
        } else {
          console.log('Error:' + response.errorMessage);
        }
      });
  }

  const getDate = () => {
    var getDate = new Date();
        var dateNow = new Date().toISOString().substring(0,10);
        var time = getDate.getHours() + ':' + getDate.getMinutes() + ':' + getDate.getSeconds();
        var dateTimeNow = dateNow+" " + time;
        let day = `${getDate.getDate()}`.padStart(2, "0")
        let month = `${getDate.getMonth()}`.padStart(2, "0");
        var dateTimeStart = ((getDate.getFullYear()+"-"+"01"+"-"+ "01"))+ ' ' + "00:00:00";

        let currentUserId = globals().getLogedInUser().data.username;
        apiService().query('user',  'userName='+currentUserId )
        .then((response) => {
          if (response.success) {
            let warehouseId = response.data[0].warehouseId;
            setFindByDate({...findByDate , dateStart: dateTimeStart , dateEnd: dateTimeNow , warehouseId: warehouseId});
            getItemsInStock( dateTimeNow , warehouseId)
    
          } else {
            console.log('Error: getting user cashbox' + response.errorMessage);
          }
        });
  }




    const getWarehouses = () => {
      apiService()
        .get("warehouses")
        .then((response) => {
          if (response.success) {
            let data = response.data;
            setWarehouse(data);
          } else {
            alert("Error: getting warehouses" + response.errorMessage);
          }
        });
  }

  const getBranches = () => {
    apiService().get('branches')
        .then((response)=> {
            if(response.success) {
                let records = response.data;
                setBranches(records);
            }
        })
}

    const searchBtn = () => {

      getItemsInStock( findByDate.dateEnd , findByDate.warehouseId)
   
    }

    const getUserSettings = () => {
      globals().getUserSettings(globals().getLogedInUser()).then((response) => {
        setUserSetStyle(response.style)
      });
  }

    const selectStyles = {
        control: (provided, state) => ({
          ...provided,
          background: "#fff",
          borderColor: "#9e9e9e",
          minHeight: "28.59px",
          height: "28.59px",
          boxShadow: state.isFocused ? null : null,
        }),
    
        valueContainer: (provided, state) => ({
          ...provided,
          height: "28.59px",
          padding: "0 6px",
        }),
    
        input: (provided, state) => ({
          ...provided,
          margin: "0px",
        }),
        indicatorSeparator: (state) => ({
          display: "none",
        }),
        indicatorsContainer: (provided, state) => ({
          ...provided,
          height: "28.59px",
        }),
      };
    const fieldChange = (e) => {
        if(e.target.name === "dateStart"){
            setFindByDate({...findByDate , dateStart: e.target.value });
        }else
        if(e.target.name === "dateEnd"){ 
          setFindByDate({...findByDate , dateEnd: e.target.value });
         
      }
    };

    const selectChange = (e,obj) => {
        if (obj.name === "item"){
            setFindByDate({...findByDate, itemId:e.value})
        }else if (obj.name === "warehouseId"){
          setFindByDate({...findByDate, warehouseId:e.value})
        }
      };

      const findChange = (e) => {
        if (e.target.value == "") {
        setFind(items);
        }
        var searchItems;
        let searchString = e.target.value.trim().toLowerCase();
    
        if (searchString.length > 0) {
          // We are searching. Filter the results.
          //searchItems = searchItems.filter((e) => e.ItemName.toLowerCase().match(searchString));
          searchItems = items.filter((item) => {
            return (
              item?.itemName?.toString().toLowerCase().match(searchString) ||
              item?.barcode?.toString().toLowerCase().match(searchString) ||
              item?.productNo?.toString().toLowerCase().match(searchString) 
            );
          });
          if(searchItems.length > 0){
            setFind(searchItems);
          }else{
            setFind(items);
          }
        }
      };

    return (
        <div className="container-fluid px-2 mt-3">
          <Search  change={(e) => findChange(e)} excel={() => exportToExcel()} printClick={print} back="#/reports"/>
          <ExcelExport data={find.length ? find : []} headers={headers} type={'other'} ref={excelExportRef} hideContent={true} />
          <Print data={find.length ? find : []} headers={headers}  ref={printRef} query={searchObj} reportName={helper().translate("STOCKS_IN_WAREHOUSES")} />
          <div className="card border-left-primary shadow h-100 mt-1">
            <div className="card-body pt-3 pb-1">
            <div className="row">
                <div className="col-11">
                  <div className="row no-gutters align-items-center">
                    <div className="col row align-items-center">
                      <div className="col-lg-4 mb-3 mb-md-0 pe-0 align-items-center ">
                        {/* <label className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                              Data e Mbarimit
                            </label> */}
                        <div className="pt-1">
                          <MDBInput
                            name="dateEnd"
                            disabled={!findByDate.dateEndDisabled}
                            className="form-control-sm active "
                            onChange={(e) => fieldChange(e)}
                            type="date"
                            label={helper().translate("DATE")}
                            value={findByDate.dateEnd == null ? '' : findByDate.dateEnd.substring(0,10)}
                          />
                        </div>
                      </div>
                      <div style={{paddingTop: "2px"}} className="col-lg-4 mb-3 mb-md-0 pe-0 ">
                        {/* <label className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                          Depo
                          </label> */}
                        {/* <Select
                          name="warehouseId"
                          placeholder="Depo"
                          styles={selectStyles}
                          options={warehouse}
                          onChange={(e, obj) => selectChange(e, obj)}
                          value={warehouse?.find(w => w.value == findByDate.warehouseId)}
                          
                        /> */}
                          <SelectByGroup 
                          name="warehouseId"
                          parent={branches} 
                          child={warehouse} 
                          childParentId={"branchId"} 
                          firstItemLabel={helper().translate("ALL_WAREHOUSES")}
                          placeholder={"Depo"}
                          styles={selectStyles}
                          onChange={(e, obj) => selectChange(e, obj)}
                          value={findByDate.warehouseId}
                          />
                      </div>
                      <div style={{paddingTop: "2px"}} className="col-lg-4 mb-3 mb-md-0  d-flex  align-items-start justify-content-center justify-content-lg-start ps-lg-5">
                      
                      <Switch
                          name="withVat"                     
                          onColor="#86d3ff"
                          offColor="#000000"
                          onHandleColor="#000000"
                          className="text-light "
                          handleDiameter={25}
                          width={55}
                          height={20}
                          uncheckedIcon={<span style={{display:'flex', margin: '-4px 0 0 -4px'}}><i className="fas  fa-minus mt-1 text-white"  /></span>}
                          checkedIcon={<span style={{display:'flex', margin: '-4px 0 0 -4px'}}><i className="fas  fa-plus mt-1 text-white"  /></span>}
                          padding="0px 0px"
                          boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                          activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                          onChange={(e) => setIsPositive(e)}
                          checked={isPositive}
                      />
                      </div>
                    </div>
                  </div>
                </div>
                  <div className="col-md-1 col-12 d-flex align-items-center justify-content-center  justify-content-lg-end">
                        <div>
                          <a className="searchbutton" onClick={() => searchBtn()}>
                            <i className="fas fa-search fa-2x text-dark"  />
                          </a>
                        </div>
                  </div>
              </div>
            </div>
        </div>
        <div className="card shadow mt-1">
        <div className="card-header py-2"><h6 className="m-0 font-weight-bold text-primary text-center">{helper().translate("STOCK")}</h6></div>
        {loader && <LoaderComponent /> } 
        {!loader ? dataShow ? <div className="card-body table-responsive">
            <table className="table table-hover text-center ">
              <thead>
                <tr>
                  <th className="align-middle">{helper().translate("NR")}</th>
                  <th className="align-middle">{helper().translate("PRODUCT_NUMBER")}</th>
                  <th className="align-middle">{helper().translate("BARCODE")}</th>
                  <th className="align-middle">{helper().translate("ITEMNAME")}</th>
                  <th className="align-middle">{helper().translate("UNIT")}</th>
                  <th className="align-middle">{helper().translate("IN_QUANTITY")}</th>
                  <th className="align-middle">{helper().translate("OUT_QUANTITY")}</th>
                  <th className="align-middle">{helper().translate("ACTUAL_QUANTITY")}</th>
                  <th className="align-middle">{helper().translate("AVG_PRICE")}</th>
                  <th className="align-middle">{helper().translate("TOTAL")}</th>
                </tr>
              </thead>
              <tbody>
                {pageOfItems.map((item, key) => (
                  <tr key={key}>
                    <td style={{width: "50px"}}>{item.no}</td>
                    <td className="text-middle  ">{item.productNo}</td>
                    <td className="text-middle  ">{item.barcode}</td>
                    <td className="text-left ps-4  ">{item.itemName}</td>
                    <td className="text-left ps-4 ">{item.unit}</td>
                    <td className="text-right pe-3 ">{helper().formatNumber(item.purchases)}</td>
                    <td className="text-right pe-3 ">{helper().formatNumber(item.sales)}</td>
                    <td className="text-right pe-4 ">{helper().formatNumber(item.quantity)}</td>
                    <td className="text-right pe-5 ">{helper().formatNumber(item.avgPrice)  }</td>
                    <td className="text-right pe-5 ">{helper().formatNumber(item.total) }</td>
                  </tr>
                ))}
              </tbody>
              <tfoot className="mb-2 mt-2 table-borderless no-colors">
              <tr>
                <td colSpan="8"></td>
                <td className="font-weight-bold align-middle">{helper().translate("TOTAL")}:</td>
                <td><input style={{width: '99%'}} type="text" disabled defaultValue="0" className="form-control text-center pb-2" value={helper().formatNumber(itemsTotal)} ></input></td>
              </tr>
            </tfoot>
            </table>
            <Pagination items={find} onChangePage={(pageOfItems) => setPageOfItems(pageOfItems)} />
          </div>
          : 
          <div style={{height: '300px'}} className=" d-flex align-items-center justify-content-center  "><div><p>{helper().translate("NO_DATA")}</p></div></div>: ""
          }
        </div>
        </div>

    )
}

export default Stock;
