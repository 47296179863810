import React from 'react'

class Settings  {
    constructor(obj){
        if(obj){
            this.id = obj.id;
            this.clientId = obj.clientId;
            this.companyName = obj.companyName;
            this.businessNumber = obj.businessNumber;
            this.fiscalNumber = obj.fiscalNumber;
            this.vatNumber = obj.vatNumber;
            this.address = obj.address;
            this.city = obj.city;
            this.country = obj.country;
            this.phoneNo = obj.phoneNo;
            this.fiscalPrinterPath = obj.fiscalPrinterPath;
            this.withVat = obj.withVat;
            this.invoiceIDFormat = obj.invoiceIDFormat;
            this.invoiceIDGenerationMethod = obj.invoiceIDGenerationMethod;
            this.image = obj.image;
            this.hideOrShowLackItems = obj.hideOrShowLackItems;
            this.allowLackItems = obj.allowLackItems;
            this.companyBranchId = obj.companyBranchId;
            this.warehouseId = obj.warehouseId;
            this.accNo = obj.accNo;
            this.announcement = obj.announcement;
            this.invoiceForm = obj.invoiceForm;
            this.faxNo = obj.faxNo;
        }
        else{
            this.id = 0;
            this.clientId = 0;
            this.companyName = '';
            this.businessNumber = '';
            this.fiscalNumber = '';
            this.vatNumber = '';
            this.address = '';
            this.city = '';
            this.country = '';
            this.phoneNo = '';
            this.fiscalPrinterPath = '';
            this.withVat = true;
            this.invoiceIDFormat = '';
            this.invoiceIDGenerationMethod = 1;
            this.image = '';
            this.hideOrShowLackItems = true;
            this.allowLackItems = true;
            this.companyBranchId = 0;
            this.warehouseId = 0;
            this.accNo = '';
            this.announcement = '';
            this.invoiceForm = '';
            this.faxNo = '';
        }
    }    
}

export default Settings
