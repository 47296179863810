import React, { Component } from 'react'
import { Page, Text, View, Document, StyleSheet, PDFViewer, PDFDownloadLink, Image, Font } from '@react-pdf/renderer';
import apiService from "../../Services/apiServices";
import globals from '../../Services/globals';
import { right } from '@popperjs/core';
import calculate from '../../Services/calculate';
import helper from '../../Services/helper';
import LoaderComponent from '../UI/Components/LoaderComponent';
import config from '../../config/config';

class Invoice3 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sale: {},
            saleDetails: [],
            partner: {},
            saleType: {},
            units: {},
            settings: {},
            saleId: props.match ? props.match.params.id : props.saleId,
            //vatGroup: []
            loader: true
        }
        
    }

    componentDidMount() {
       
            apiService().query('getSale', 'id=' + this.state.saleId)
            .then((response) => {
                if (response.success) {
                    var sale = response.data[0];
                    apiService()
                    .query('getWarehouseWithBranchDetails', 'warehouseId=' + sale.warehouseId)
                    .then((response) => {
                        if (response.success) {
                          let warData = response.data[0];
                          sale.warehouseAddress = warData.address;
                          sale.unitNo = warData.unitNo;
                          sale.branchPhone = warData.phone;
                          sale.email = warData.email;
                          sale.totalTvsh = (parseFloat(sale.totalSum) + parseFloat(sale.vatSum));
                          this.setState({
                              sale: sale
                          });
                          this.getSaleDetails(sale.id, sale.partnerId, sale.salesTypeId)
                        } else {
                            console.log('Error:' + response.errorMessage);
                        }
                    });
                  


                    //this.props.addToast('Success!')
                    //this.props.addToast('Saved Successfully', { appearance: 'success', autoDismiss: true, });
                }
                else {
                    console.log('ka gabim: ', response);
                    //this.props.addToast('Ka ndodhur nje gabim!', { appearance: 'error', autoDismiss: true, });
                }
            });
            apiService().get('units').then((response) => {
                if (response.success) {
                  this.setState({ units: response.data });
                  
                } else {
                  console.log('Error:' + response.errorMessage);
                }
              });
                globals().getSettings().then((response) => {
                        if (response) {
                        let sett = response;
                        this.setState({ settings: sett });
                        }
                    })
            
    }

    grupByVat = (items) => {
        let group = items.reduce((r, a) => {
            r[a.vat] = [...r[a.vat] || [], a];
            return r;
        }, {});
        this.vatGroup(group)
    }

    getSaleDetails(saleId, partnerId, salesTypeId) {
        apiService().query('getSaleDetails', 'saleId='+saleId)
            .then((response) => {
                var data = response.data;
                if(response.success){
                    for(var i= 0; i< data.length; i++){ 
                        if(data[i].discount> 0){
                
                          data[i].priceDiscount = calculate().calculateSale('priceDiscount')(data[i].price, data[i].discount);
                          data[i].priceTvsh = calculate().calculateSale('priceTvsh')(data[i].priceDiscount ,data[i].vat);
                          data[i].totalPrice= calculate().calculateSale('totalPrice')(data[i].priceDiscount,data[i].quantity);
                          data[i].totalPriceTvsh= calculate().calculateSale('totalPriceTvsh')(data[i].totalPrice,data[i].vat);
                          // data[i].totalPriceItemTvsh= calculate().calculateSale('totalPriceItemTvsh')(data[i].totalPriceTvsh , data[i].quantity);
                
                        } else{
                          data[i].priceTvsh = calculate().calculateSale('priceTvsh')(data[i].price ,data[i].vat);
                          data[i].totalPrice= calculate().calculateSale('totalPrice')(data[i].price , data[i].quantity );
                          data[i].totalPriceTvsh= calculate().calculateSale('totalPriceTvsh')(data[i].totalPrice,data[i].vat);
                          // data[i].totalPriceItemTvsh= calculate().calculateSale('totalPriceItemTvsh')(data[i].totalPriceTvsh , data[i].quantity);
                
                        }
                      }
                      this.grupByVat(data)
                      this.setState({ saleDetails: data });
                      this.setState({
                        loader: false
                    })
                }
                else{
                    console.log('ka gabim sd: ', response);
                    //this.props.addToast('Ka ndodhur nje gabim!', { appearance: 'error', autoDismiss: true, });
                }
            });
        apiService()
            .query('partner', 'id='+partnerId)
                .then((response) => {
                    if (response.success) {
                        this.setState({
                            partner: response.data[0]
                        });
                        //console.log('Partner: ', response.data);
                    } else {
                        console.log('Error:' + response.errorMessage);
                    }
                });    
            apiService()
                .query('getSaleType', 'id='+salesTypeId)
                    .then((response) => {
                        if (response.success) {
                            this.setState({
                                saleType: response.data[0]
                            });
                        } else {
                            console.log('Error:' + response.errorMessage);
                        }
                    });
    }

    checkColumn = (property, items) => {
        // const isTrue = (currentValue) => currentValue[property];
        // return items?.every(isTrue)
        return true
    }

    vatGroup = (group) => {
        let arr = []
        Object.entries(group).forEach(([key, value]) => {
            arr.push({key: key, value: this.calculateVat(value)})
        })

        this.setState({
            vatGroup: arr
        })
    }
    calculateVat = (items) => {
        return items?.reduce((accumulator, current) => parseFloat(accumulator) + current.vatSum, 0)
    }

    render() {

        Font.register({ family: 'Times-Italic'});
        let styles = StyleSheet.create({
            italicFont: {
                fontFamily:"Times-Italic"
            },
            contentWrap: {
                border: "2px",
                margin: "2px"
            },
            page: {
                display: 'flex',
                //flexDirection: 'row',
                //alignItems:'baseline',
                backgroundColor: '#fff',
                flexDirection: 'row'
            },
            section: {
                //paddingHorizontal: 5,
                flexGrow: 1,
                width: '50%'
            },
            td: {
                marginLeft: 10,
                marginRight: 10
            },
            text: {
                // borderBottom: 1, 
                fontSize: 9,
                paddingTop: 2,
                paddingHorizontal: 2
            },
            text10: {
                fontSize: 8,
                paddingVertical: 2
            },
            pageNumbers: {
                position: 'absolute',
                bottom: 5,
                left: 0,
                right: 0,
                textAlign:'right', 
                fontSize:8,
                borderTop: 1,
                // top: '15%'
                marginLeft:5,
                marginRight:5,
                marginBottom: -5                
              },
            pageNumbers2: {
                position: 'absolute',
                bottom: 15,
                left: 0,
                right: 0,
                textAlign:'right', 
                fontSize:8,
                // top: '15%'
                marginLeft:5,
                marginRight:5,
                marginBottom: -5                
              },
            pageNumbersCenter: {
                position: 'absolute',
                bottom: 60,
                left: 0,
                right: 0,
                textAlign:'center', 
                paddingLeft: 15,
                fontSize:12,
                // top: '15%'
                margin:"auto"               
              },
          });
          let sale = this.state.sale;
          let saleDetails = this.state.saleDetails;
          let partner = this.state.partner
          let vatGroup = this.state.vatGroup
          let loader = this.state.loader
          let settings = this.state.settings;

        return (
            
            <div className="pdf my-4">
            {loader && <LoaderComponent type="ThreeDots" className="text-center w-100" color="#2BAD60" height="100" width="100" /> }
            {!loader &&
            <PDFViewer>
                <Document style={{justifyContent: 'center'}}>
                    <Page size="A4" style={[{paddingVertical: 10, paddingHorizontal: 15 }]} wrap>
                        <View style={[styles.page, {borderTop: "2px solid black"}]}>
                            <View style={styles.section}>
                                <Text style={[styles.text, {fontSize: 11, fontWeight: 'bold'}]}>{helper().translate("COMPANY")}: {settings.companyName}</Text>
                                <View style={styles.page}>
                                    <View style={styles.section}>
                                        { settings.businessNumber?.length > 2 &&  <Text style={styles.text}>{helper().translate("NUI")}: {settings.businessNumber}</Text>}
                                        { settings.fiscalNumber?.length > 2 &&  <Text style={styles.text}>{helper().translate("FISCAL_NUMBER")}: {settings.fiscalNumber}</Text>}
                                        {settings.vatNumber?.length > 2 && <Text style={styles.text}>{helper().translate("VAT_NUMBER")}: {settings.vatNumber}</Text> }
                                        { sale.unitNo?.length > 2 && <Text style={styles.text}>{helper().translate("UNIT_NUMBER")}: {sale.unitNo}</Text>}
                                        { sale.warehouseAddress?.length > 2 && <Text style={styles.text}>{helper().translate("ADDRESS")}: {sale.warehouseAddress}</Text>}
                                        {sale.email?.length > 2 && <Text style={[styles.text]}>{helper().translate("EMAIL")}: {sale.email}</Text> }
                                        { settings.phoneNo?.length > 2 && <Text style={styles.text}>Tel: {settings.phoneNo}</Text>}
                                        {sale.branchPhone?.length > 2 && <Text style={[styles.text]}>{helper().translate("BRANCHE_PHONE_NUMBER")}: {sale.branchPhone}</Text>} 
                                    </View>
                                </View> 
                                
                            </View>
                            <View style={[styles.section,{marginVertical: 'auto'}]}>
                                <Image src={settings?.img ? settings?.img : config.noLogo} style={{width: settings?.img ? '100px' : '100px'}} />
                            </View>
                            <View style={[styles.section, {width: '40%' , paddingVertical: 5, paddingHorizontal: 20, textAlign: 'left'}]}>
                                <Text style={{fontSize: 12, fontWeight: 'bold'}}>{sale.salesTypeId == 1 ? helper().translate("SALE_INVOICE") : (sale.salesTypeId == 2) ? 'Faturë '+this.state.saleType.Name : this.state.saleType.Name }</Text>
                                {/* <Text style={{fontSize: 18, fontWeight: 'bold', height: 30}}></Text> */}
                                <Text style={{fontSize: 12 , padding: 2, fontWeight: 'bold', borderBottom: '1px solid #000', marginTop: 5 , color: 'red'}}>{sale.invoiceNo}</Text>
                                <Text style={{ fontSize: 12, padding: 2, marginTop: 5  , borderBottom: '1px solid #000'}}>{helper().translate("DATE")}: {helper().formatDate(sale.date)}</Text>
                            </View>
                        </View>
                        <View style={[styles.page, {borderTop: "2px solid black", marginTop:2, paddingVertical: 5, alignItems: "flex-end"}]}>
                            <View style={[styles.section, {width: '60%', padding: 0 , marginBottom: 25, marginTop:5}]}>
                                
                                {partner?.partnerType === 6 && <Text style={[styles.text, {fontSize: 12, fontWeight: 'bold', width: '100%'}]}>{helper().translate("UNIT")}: {partner?.companyName }</Text>}
                                <View style={[styles.page, { width: '80%', borderLeft: 1, paddingLeft: 3}]}>
                                    <View style={styles.section}>
                                        <Text style={[styles.text10, { fontWeight: 'bold', fontSize: 11, borderBottom: '1px solid #000' , paddingLeft: 2 , paddingBottom: 2}]}>{helper().translate("BUYER")}: {partner.partnerType !== 6 ? partner.companyName : partner.subjectName}</Text>
                                        {partner.id && <Text  style={[styles.text10, { borderBottom: '1px solid #000', paddingLeft: 2 , paddingBottom: 2}]}>{helper().translate("PARTNER_ID")}: {partner.id}</Text>}
                                        {partner.businessNo?.length > 2 && <Text style={[styles.text10, { borderBottom: '1px solid #000', paddingLeft: 2 , paddingBottom: 2}]}>{helper().translate("NUI")}: {partner.businessNo}</Text> }
                                        {partner.fiscalNo?.length > 2 && <Text  style={[styles.text10, { borderBottom: '1px solid #000', paddingLeft: 2 , paddingBottom: 2}]}>{helper().translate("FISCAL_NUMBER")}: {partner.fiscalNo}</Text>}
                                        {partner.vatNo?.length > 2 && <Text style={[styles.text10, { borderBottom: '1px solid #000', paddingLeft: 2 , paddingBottom: 2}]}>{helper().translate("VAT_NUMBER")}: {partner.vatNo}</Text>}
                                        {partner.address?.length > 2 && <Text style={[styles.text10, { borderBottom: '1px solid #000', paddingLeft: 2 , paddingBottom: 2}]}>{helper().translate("ADDRESS")}: {partner.address}</Text>}
                                        {partner.city?.length > 2 && <Text style={[styles.text10, { borderBottom: '1px solid #000', paddingLeft: 2 , paddingBottom: 2}]}>{helper().translate("CITY")}: {partner.city}</Text>}
                                        {partner.phone?.length > 2 && <Text style={[styles.text10, { borderBottom: '1px solid #000', paddingLeft: 2 , paddingBottom: 2}]}>{helper().translate("PHONE")}: {partner.phone}</Text>}
                                        
                                    </View>
                                </View>
                            </View>
                            
                        </View>
                        <View style={{border: 1, marginVertical: 0}}>
                            {/* width 565px */}
                            <View style={[styles.page, { borderBottom: 1, paddingHorizontal: 0, minHeight: '12px' , backgroundColor: "#F1F1F1"}]}>
                                <Text style={[styles.text10, {width: '20px', textAlign:'center', borderRight: 0.5}]}>{helper().translate("NR")}</Text>
                                {/* <Text style={[styles.text10, {width: '80px', textAlign:'center', borderRight: 0.5}]}>{helper().translate("BARCODE")}</Text> */}
                                <Text style={[styles.text10, {width: '155px', textAlign:'center', borderRight: 0.5, flexGrow: 3}]}>{helper().translate("DESIGNATION")}</Text>
                                {/* <Text style={[styles.text10, {width: '35px', textAlign:'center', borderRight: 0.5}]}>{helper().translate("UNIT")}</Text> */}
                                {this.checkColumn('quantity', saleDetails) &&
                                    <Text style={[styles.text10, {width: '45px', textAlign:'center', borderRight: 0.5}]}>{helper().translate("QUANTITY")}</Text>
                                }
                                {this.checkColumn('price', saleDetails) &&
                                    <Text style={[styles.text10, {width: '55px', textAlign:'center', borderRight: 0.5}]}>{helper().translate("PRICE")}</Text>
                                }
                                {this.checkColumn('priceTvsh', saleDetails) && settings.withVat === true && 
                                    <Text style={[styles.text10, {width: '55px', textAlign:'center', borderRight: 0.5}]}>{helper().translate("PRICE_WITH_VAT")}</Text>
                                }
                                {this.checkColumn('discount', saleDetails) &&
                                    <Text style={[styles.text10, {width: '30px', textAlign:'center', borderRight: 0.5}]}>{helper().translate("DISCOUNT")} %</Text>
                                }
                                {this.checkColumn('priceDiscount', saleDetails) &&
                                    <Text style={[styles.text10, {width: '55px', textAlign:'center', borderRight: 0.5}]}>{helper().translate("PRICE_WITH_DISCOUNT")} %</Text>
                                }
                                {this.checkColumn('vat', saleDetails) && settings.withVat === true && 
                                    <Text style={[styles.text10, {width: '30px', textAlign:'center', borderRight: 0.5}]}>{helper().translate("VAT")} %</Text>
                                }
                                {this.checkColumn('vatSum', saleDetails) && settings.withVat === true && 
                                    <Text style={[styles.text10, {width: '55px', textAlign:'center', borderRight: 0.5}]}>{helper().translate("VAT_VALUE")}</Text>
                                }
                                {this.checkColumn('totalPriceTvsh', saleDetails) &&
                                    <Text style={[styles.text10, {width: '60px', textAlign:'center'}]}>{settings.withVat === true ? helper().translate("TOTAL_WITH_VAT") : helper().translate("TOTAL")}</Text>
                                }
                            </View>
                            {saleDetails?.map((item, index) => (
                                <View key={index} style={[styles.page, {borderTop: 1, paddingHorizontal: 0, overflow: 'hidden', height:'auto', backgroundColor: 'transparent'}]}>
                                    <Text style={[styles.text10, {width: '20px', textAlign:'center', borderRight: 0.5}]}>{item.no}</Text>
                                    {/* <Text style={[styles.text10, {width: '80px', textAlign:'center', borderRight: 0.5}]}>{item.barcode}</Text> */}
                                    <Text style={[styles.text10, {width: '155px', textAlign:'center', borderRight: 0.5, flexGrow: 2}]}>{item.itemName}</Text>
                                    {/* <Text style={[styles.text10, {width: '35px', textAlign:'center', borderRight: 0.5}]}>{(this.state.units.find(u => u.id === item.unitId))?.name}</Text> */}
                                    {this.checkColumn('quantity', saleDetails) &&
                                        <Text style={[styles.text10, {width: '45px', textAlign:'center', borderRight: 0.5}]}>{item.quantity}</Text>
                                    }
                                    {this.checkColumn('price', saleDetails) &&
                                        <Text style={[styles.text10, {width: '55px', textAlign:'center', borderRight: 0.5}]}>{typeof(item.price) == 'undefined' ? 
                                        item.price : helper().formatNumber(item.price,true)}</Text>
                                    }         
                                    {this.checkColumn('priceTvsh', saleDetails) && settings.withVat === true && 
                                        <Text style={[styles.text10, {width: '55px', textAlign:'center', borderRight: 0.5}]}>{typeof(item.priceTvsh) == 'undefined' ? 
                                        item.priceTvsh :helper().formatNumber(item.priceTvsh,true)  }</Text>
                                    }
                                    {this.checkColumn('discount', saleDetails) &&
                                        <Text style={[styles.text10, {width: '30px', textAlign:'center', borderRight: 0.5}]}>{item.discount}</Text>
                                    }
                                    {this.checkColumn('priceDiscount', saleDetails) &&
                                        <Text style={[styles.text10, {width: '55px', textAlign:'center', borderRight: 0.5}]}>{typeof(item.priceDiscount) == 'undefined' ? 
                                        item.priceDiscount : helper().formatNumber(item.priceDiscount,true)  }</Text>
                                    }
                                    {this.checkColumn('vat', saleDetails) && settings.withVat === true && 
                                        <Text style={[styles.text10, {width: '30px', textAlign:'center', borderRight: 0.5}]}>{item.vat}</Text>
                                    } 
                                    {this.checkColumn('vatSum', saleDetails) && settings.withVat === true && 
                                        <Text style={[styles.text10, {width: '55px', textAlign:'center', borderRight: 0.5}]}>{typeof(item.vatSum) == 'undefined' ? 
                                        item.vatSum : helper().formatNumber(item.vatSum,true) }</Text>
                                    }
                                    {this.checkColumn('totalPriceTvsh', saleDetails) &&
                                        <Text style={[styles.text10, {width: '60px', textAlign:'center'}]}>{typeof(item.totalPriceTvsh) == 'undefined' ? 
                                        item.totalPriceTvsh : helper().formatNumber(item.totalPriceTvsh,true)  }</Text>
                                    }
                                </View>
                            ))}
                        </View>
                        <View  wrap={false} style={[styles.page, {marginTop: 5, paddingVertical: 5, alignItems: "flex-start"}]}>
                            <View style={[ {width: '75%', padding: 5}]}>
                                {/* <Text style={[styles.text10, {paddingRight: 10}]}>{sale.comment ? sale.comment : ""}</Text> */}
                            </View>
                            <View style={[ {width: '25%' , paddingVertical: 0}]}>
                                <View style={[styles.page, {borderBottom: 1, marginTop: 0, paddingVertical: 0, paddingHorizontal:5, alignItems: "flex-end"}]}>
                                    <View style={{width: '100%'}}>
                                        <Text style={[styles.text10, {textAlign:'right',  fontSize: "11px", fontWeight: "bold"}]}>{helper().translate("TOTAL")}:{ 
                                        typeof((sale.totalSum )) == 'undefined' ? 
                                        (sale.totalSum) : helper().formatNumber(parseFloat(sale.totalTvsh)) 
                                        }  </Text>
                                    </View>
                                </View>
                            </View>
                        </View> 
                        <View  style={[ {width: '75%'}]}>
                                <Text style={[styles.text10, {paddingRight: 10}]}>{sale.comment ? sale.comment : ""}</Text>
                        </View>
                        <View   wrap={false}>
                        <View  style={[styles.page, { width: '100%', marginTop: 2 , alignItems: "flex-start"} ]}>
                            <View style={[ {textAlign: 'left', width: '36%', border: 1, display: "flex", paddingLeft: "20px"}]}>
                            </View>
                        </View>
                        <View  style={[styles.page, { width: '100%', marginTop: 2 , alignItems: "flex-start", marginVertical: 10} ]}>
                            <View  style={[ {textAlign: 'left', width: '40%', height: "60px" , border: 1, display: "flex", paddingLeft: "20px"}]}>
                                <Text style={[styles.text10, {width: '100%'}]}>{helper().translate("PRINT_PAYMENT_DETAILS")}</Text>
                                <Text style={[styles.text10, {width: '100%', margin: 'auto'}]}>O {helper().translate("CASH")}</Text>
                                <Text style={[styles.text10, {width: '100%', margin: 'auto'}]}>O {helper().translate("BANK_DEPOSIT")}</Text>
                                <Text style={[styles.text10, {width: '100%', margin: 'auto'}]}>O {helper().translate("CREDIT_CARD")}</Text>
                            </View>
                            <View  style={[{textAlign: 'center', width: '30%'}]}></View>
                            <View  style={[{textAlign: 'left', width: '40%'}]}>
                            <Text style={[styles.italicFont ,{fontSize: 8, marginTop: 0 , fontStyle: 'bold'}]}>{settings.announcement}</Text>
                            </View>
                            {/* <View style={[{textAlign: 'center', width: '50%'}]}>
                                <Text style={[styles.text10, {width: '60%', height: "60px", border: '1px dotted #000', margin:'auto', backgroundColor: "#F1F1F1"}]}>{helper().translate("STAMP")} & {helper().translate("SIGNATURE")}</Text>
                            </View> */}
                        </View>

                        </View>
                        <View  wrap={false} style={[styles.page, { width: '100%', marginTop: 20 , alignItems: "flex-start"}]}>
                            <View style={[{textAlign: 'center', width: '50%' , display:"flex", alignItems: "flex-start" }]}>
                            <Text style={[ {width: 'auto' , textAlign: "left" ,fontSize: 8 , padding: 2, marginTop: 5}]} >{helper().translate("PAYMENT_BANK_DETAILS")}:</Text>
                            <Text style={[ { width: 'auto' , textAlign: "left" ,fontSize: 8 , borderTop: '1px solid #000' , padding: 2, fontWeight: 'bold', marginTop:1}]}>{settings.accNo}</Text>
                            </View>
                            
                        </View>
                        <View style={[styles.page, { width: '100%', marginTop: 160}]}>
                            <View style={[{textAlign: 'center', width: '20%'}]}>
                                <Text style={[styles.text10, { borderTop: 1}]}>{helper().translate("SUBMITTED")}:</Text>
                            </View>
                            <View style={[{textAlign: 'center', width: '60%'}]}></View>
                            <View style={[{textAlign: 'center', width: '20%'}]}>
                                <Text style={[styles.text10, { borderTop: 1}]}>{helper().translate("RECEIVED")}:</Text>
                            </View>
                        </View>
                        {/* <View style={[styles.pageNumbersCenter ,{display:"flex", justifyContent:"flex-end"}]}>
                        <Text style={[styles.italicFont ,{fontWeight: "bold", fontSize:"12", marginTop: "30px", textAlign: "center", borderBottom:"1px solid black"}]} fixed>{helper().translate("THANK_YOU")}</Text>
                        <Text style={[styles.italicFont ,{ fontSize:"8", textAlign: "right"}]}  fixed>{helper().translate("IMPLEMENTED_BY")} T3 Solutions</Text>
                        </View> */}
                        <Text  style={[styles.italicFont,styles.pageNumbers2 ,{fontWeight: "bold", fontSize:"10", marginTop: "30px", textAlign: "center"}]} fixed>{helper().translate("THANK_YOU")}</Text>
                        <Text style={[styles.pageNumbers,styles.italicFont]} fixed>{helper().translate("IMPLEMENTED_BY")} Nsum Solution</Text>
                    </Page>
                </Document>
            </PDFViewer>
            }
            </div>
        )
    }
}

export default Invoice3
