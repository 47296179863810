import React, { useState, useEffect, forwardRef, useRef, useImperativeHandle } from 'react'
import globals from '../../Services/globals';
import Select, { components } from "react-select";
import HeaderTools from '../Header/HeaderTools';
import Image from './Image';
import Switch from "react-switch";
import CreatableSelect from 'react-select/creatable';
import mdlSettings from '../../Models/Settings';
import apiService from '../../Services/apiServices';
import currencyServices from '../../Services/currencyServices';
import helper from '../../Services/helper';
import { useToasts } from 'react-toast-notifications';
import { MDBInput } from 'mdb-react-ui-kit';
import Resizer from "react-image-file-resizer";
import { set } from 'react-hook-form';

const Company = params => {
    const [image, setImage] = useState();
    const [settings, setSettings] = useState(params.sett);
    const [generateInvNo, setGenerateInvoNo] = useState(true);
    const [partners, setPartners] = useState();
    const [imgSrc, setImgSrc] = useState()
    const [formData, setFormData] = useState()

    var currency_symbols = currencyServices().currencyList();
    const [currencySym, setCurrencySym] = useState(currency_symbols);

    const customStyles = {
        control: (base, state) => ({
            ...base,
            borderColor: state.isFocused ? "#bac8f3 !important" : "#d1d3e2",
            boxShadow: state.isFocused ? "0 0 0 0.2rem rgb(78 115 223 / 25%)" : "",
        }),
        control: (provided, state) => ({
            ...provided,
            background: '#fff',
            borderColor: '#9e9e9e',
            minHeight: '28.59px',
            height: '28.59px',
            boxShadow: state.isFocused ? null : null,
        }),

        valueContainer: (provided, state) => ({
            ...provided,
            height: '28.59px',
            padding: '0 6px'
        }),

        input: (provided, state) => ({
            ...provided,
            margin: '0px',
        }),
        indicatorSeparator: state => ({
            display: 'none',
        }),
        indicatorsContainer: (provided, state) => ({
            ...provided,
            height: '28.59px',
        }),
    };

    const showState = () => {
        setSettings({ ...settings, image: '' })
    }
    const t = (key) => {
        return helper().translate(key);
    }

    const uploadFile = (e) => {
        setImage(e)
        // var inputElement = e.target;
        // // console.log('inp: ', e.target.files);
        // var file = inputElement.files[0];
        // var reader = new FileReader();
        // //let that = this;
        // let blob = image
        // reader.onloadend = function() {
        //     console.log('Encoded Base 64 File String:', reader.result);
        //     var data=(reader.result).split(',')[1];
        //     blob = 'data:image/bmp;base64,'+data;
        //     setImage(blob)
        //     console.log('buuuu: ', data)  
        // }
        // let url = reader.readAsDataURL(file);    

        // console.log('ffgg', reader.result)
        // const formData = new FormData();

        // // Update the formData object
        // formData.append(
        //     "image",
        //     file,
        //     file.name
        // );

        // console.log(formData)
    }

    const getSettings = () => {
        globals().getSettings(true).then((sett) => {
            let settInvoice = sett?.autoGenerateInvoiceNumber === true ? true : false
            setGenerateInvoNo(settInvoice);
            Object.keys(sett).forEach(key => {
                sett[key] !== null ? sett[key] = sett[key] : sett[key] = ''
            })
            setSettings(sett);
            // setImage(sett?.img)
        });
    }

    const getPartners = async () => {
        apiService().get('partners').then((response) => {
            if (response.success) {
                var records = response.data.map(record => ({ value: record.id, label: record.saveAs }));
                setPartners(records);
            } else {
                console.log('Error:' + response.errorMessage);
            }
        });
    }

    // useImperativeHandle(
    //     ref,
    //     () => ({
    //         save() {
    //             apiService().put('settings', settings)
    //                 .then((response)=>{responseMessage(response, 'Updated Successfully')});
    //         }
    //     }),
    // )

    const setImg = (img) => {
        setSettings({ ...settings, img: img })
    }

    const selectChange = (e, obj) => {
        if (obj.name === "currency") {
            setSettings({ ...settings, currency: e.label })
        }
    }

    const resizeFile = (file) =>
        new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                500,
                500,
                "PNG",
                100,
                0,
                (uri) => {
                    resolve(uri);
                },
                "base64"
            );
        })


    const onImgChange = async (event) => {
        try {
            const file = event?.target?.files[0];
            const image = await resizeFile(file);
            setImgSrc(image)
            setImg(image)
        }
        catch (err) {
            console.log(err);
        }
    };

    // New with file sistem 
    // const onImgChange = async (event) => {
    //     try {
    //         const file = event?.target?.files[0];
    //         const image = await resizeFile(file);
    //         let formData = new FormData();
    //         formData.append('file', image);
    //         setFormData(image)

    //         setImgSrc(image)
    //         setImg(image)
    //     }
    //     catch (err) {
    //         console.log(err);
    //     }
    // };

    const uploadImage = () => {
        if (formData) {
            apiService().uploadFile(formData).then((response) => {
                console.log(response);
                if (response.status === 200) {
                    setImgSrc(response.data.url)
                    setSettings({ ...settings, img1: response.data.url })
                    settings['img1'] = response.data.url
                    params.save()
                }
            });
        }
        else {
            params.save()
        }

    }

    useEffect(() => {
        getSettings();
        getPartners();
    }, []);

    // useEffect(() => {
    //     let settInvoice = settings?.invoiceIDFormat != "" ? true : false
    //     setGenerateInvoNo(settInvoice)
    // },[settings?.invoiceIDFormat])

    useEffect(() => {
        params.setSettings(settings)
    }, [settings]);

    // useEffect(() => {
    //     console.log(params)
    //     image ? onImgChange(image) : 0
    // }, [image])

    return (
        <>
            <div className="card o-hidden border-0 shadow-lg">
                <div className="card-body p-0">
                    <div className="row">
                        <div className="no-shadow px-4">
                            <HeaderTools save={params.save} />
                            {/* <HeaderTools save={() => uploadImage()} /> */}
                        </div>
                        <div className="col-md-12 col-lg-12 col-xl-12 mb-3 d-md-flex d-lg-flex d-xl-flex px-2">
                            <div className="col-md-6 px-0 px-md-3 py-3 py-md-3 mx-auto overflow-hidden">
                                <div className="col-md-12 col-lg-12 col-xl-12 mb-3 form-floating">
                                    <MDBInput type="text" name="companyName" id="companyName" value={settings?.companyName} className="form-control-sm text-black px-3" onChange={(e) => setSettings({ ...settings, companyName: e.target.value })} label={t('COMPANY_NAME')} />
                                    {/* <label htmlFor="companyName" className="px-4">Emri i kompanisë</label> */}
                                </div>
                                <div className="col-md-12 col-lg-12 col-xl-12 mb-3 form-floating">
                                    <MDBInput type="text" name="businessNumber" id="businessNumber" value={settings?.businessNumber} className="form-control-sm text-black px-3" onChange={(e) => setSettings({ ...settings, businessNumber: e.target.value })} label="NUI" />
                                    {/* <label htmlFor="businessNumber" className="px-4">NRB</label> */}
                                </div>
                                <div className="col-md-12 col-lg-12 col-xl-12 mb-3 form-floating">
                                    <MDBInput type="text" name="fiscalNumber" id="fiscalNumber" value={settings?.fiscalNumber} className="form-control-sm text-black px-3" onChange={(e) => setSettings({ ...settings, fiscalNumber: e.target.value })} label={t('FISCAL_NUMBER')} />
                                    {/* <label htmlFor="fiscalNumber" className="px-4">NIT</label> */}
                                </div>
                                <div className="col-md-12 col-lg-12 col-xl-12 mb-3 form-floating">
                                    <MDBInput type="text" name="vatNumber" id="vatNumber" value={settings?.vatNumber} className="form-control-sm text-black px-3" onChange={(e) => setSettings({ ...settings, vatNumber: e.target.value })} label={t('VAT_NUMBER')} />
                                    {/* <label htmlFor="vatNumber" className="px-4">Nr. TVSH</label> */}
                                </div>
                                <div className="col-md-12 col-lg-12 col-xl-12 mb-3 form-floating">
                                    <MDBInput type="text" name="phoneNo" id="phoneNo" value={settings?.phoneNo} className="form-control-sm text-black px-3" onChange={(e) => setSettings({ ...settings, phoneNo: e.target.value })} label={t('PHONE')} />
                                    {/* <label htmlFor="phoneNo" className="px-4">Tel</label> */}
                                </div>
                                <div className="col-md-12 col-lg-12 col-xl-12 mb-3 form-floating">
                                    <MDBInput type="text" name="faxNo" id="faxNo" value={settings?.faxNo} className="form-control-sm text-black px-3" onChange={(e) => setSettings({ ...settings, faxNo: e.target.value })} label="Fax" />
                                    {/* <label htmlFor="faxNo" className="px-4">Fax</label> */}
                                </div>
                                <div className="col-md-12 col-lg-12 col-xl-12 mb-3 form-floating">
                                    <MDBInput type="text" name="email" id="email" value={settings?.email} className="form-control-sm text-black px-3" onChange={(e) => setSettings({ ...settings, email: e.target.value })} label="EMail" />
                                    {/* <label htmlFor="email" className="px-4">Fax</label> */}
                                </div>
                                <div className="col-md-12 col-lg-12 col-xl-12 mb-3 form-floating">
                                    <MDBInput type="text" name="address" id="address" value={settings?.address} className="form-control-sm text-black px-3" onChange={(e) => setSettings({ ...settings, address: e.target.value })} label={t('ADDRESS')} />
                                    {/* <label htmlFor="address" className="px-4">Adresa</label> */}
                                </div>
                                <div className="col-md-12 col-lg-12 col-xl-12 mb-3 form-floating">
                                    <MDBInput type="text" name="city" id="city" value={settings?.city} className="form-control-sm text-black px-3" onChange={(e) => setSettings({ ...settings, city: e.target.value })} label={t('CITY')} />
                                    {/* <label htmlFor="city" className="px-4">Qyteti</label> */}
                                </div>
                                <div className="col-md-12 col-lg-12 col-xl-12 mb-3 form-floating">
                                    <MDBInput type="text" name="country" id="country" value={settings?.country} className="form-control-sm text-black px-3" onChange={(e) => setSettings({ ...settings, country: e.target.value })} label={t('COUNTRY')} />
                                    {/* <label htmlFor="country" className="px-4">Vendi</label> */}
                                </div>
                                <div className="col-md-12 col-lg-12 col-xl-12 mb-3 form-floating">
                                    <MDBInput textarea type="text" name="accNo" id="accNo" value={settings?.accNo} style={{ height: 'auto' }} rows="2" className="form-control-sm text-black px-3" onChange={(e) => setSettings({ ...settings, accNo: e.target.value })} label={t('BANK_ACCOUNTS')} />
                                    {/* <label htmlFor="accNo" className="px-4">Llogaritë bankare</label> */}
                                </div>
                                <div className="col-md-12 col-lg-12 col-xl-12 mb-3 form-floating">
                                    <MDBInput textarea type="text" name="announcement" value={settings?.announcement} id="announcement" style={{ height: 'auto' }} rows="2" className="form-control-sm text-black px-3" onChange={(e) => setSettings({ ...settings, announcement: e.target.value })} label={t('INVOICE_COMMENT')} />
                                    {/* <label htmlFor="announcement" className="px-4">Koment në faturë</label> */}
                                </div>
                            </div>
                            <div className="col-md-6 px-0 px-md-3 py-3 py-md-3 mx-auto overflow-hidden">
                                <div className="col-md-12 col-lg-12 col-xl-12 mb-2">
                                    <label className="mr-3">{t('ITS_WITH_VAT')}</label>
                                    <Switch
                                        id="withVat"
                                        className="text-light float-right"
                                        onColor="#86d3ff"
                                        onHandleColor="#2693e6"
                                        handleDiameter={25}
                                        width={65}
                                        height={25}
                                        uncheckedIcon={t('NO')}
                                        checkedIcon={t('YES')}
                                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                        onChange={(e) => setSettings({ ...settings, withVat: e })}
                                        checked={settings?.withVat}
                                    />
                                </div>
                                <div className="col-md-12 col-lg-12 col-xl-12 mb-2">
                                    <label className="mr-3">{t('SHOW_OUT_OF_STOCK_ITEMS')}</label>
                                    <Switch
                                        id="hideOrShowLackItems"
                                        className="text-light float-right"
                                        onColor="#86d3ff"
                                        onHandleColor="#2693e6"
                                        handleDiameter={25}
                                        width={65}
                                        height={25}
                                        uncheckedIcon={t('NO')}
                                        checkedIcon={t('YES')}
                                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                        onChange={(e) => setSettings({ ...settings, hideOrShowLackItems: e })}
                                        checked={settings?.hideOrShowLackItems}
                                    />
                                </div>
                                <div className="col-md-12 col-lg-12 col-xl-12 mb-2">
                                    <label className="mr-3">{t('ALLOW_OUT_OF_STOCK_ITEMS')}</label>
                                    <Switch
                                        id="allowLackItems"
                                        className="text-light float-right"
                                        onColor="#86d3ff"
                                        onHandleColor="#2693e6"
                                        handleDiameter={25}
                                        width={65}
                                        height={25}
                                        uncheckedIcon={t('NO')}
                                        checkedIcon={t('YES')}
                                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                        onChange={(e) => setSettings({ ...settings, allowLackItems: e })}
                                        checked={settings?.allowLackItems}
                                    />
                                </div>
                                <div className="col-md-12 col-lg-12 col-xl-12 mb-2">
                                    <label className="mr-3">{t('AUTOMATICALLY_GENERATE_INVOICE_NUMBER')}</label>
                                    <Switch
                                        id="expired"
                                        className="text-light float-right"
                                        onColor="#86d3ff"
                                        onHandleColor="#2693e6"
                                        handleDiameter={25}
                                        width={65}
                                        height={25}
                                        uncheckedIcon={t('NO')}
                                        checkedIcon={t('YES')}
                                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                        onChange={(e) => (setSettings({ ...settings, autoGenerateInvoiceNumber: e === true ? true : false }), setGenerateInvoNo(e))}
                                        checked={generateInvNo}
                                    />
                                </div>
                                <div className="col-12  mb-2 ">
                                    <div className='row'>
                                        <div className='col-10 '>
                                            <Select
                                                onChange={(e, obj) => selectChange(e, obj)}
                                                options={currencySym}
                                                placeholder={t('CURRENCY')}
                                                name="currency"
                                                styles={customStyles}
                                                value={currencySym.find(c => c.label === settings?.currency) || ''}
                                            />
                                        </div>
                                        <div className='col-2 ps-0'>
                                            <MDBInput type="text" name="symbol" disabled value={currencySym.find(c => c.label === settings?.currency)?.value} className="form-control-sm fw-bold text-center px-3" label={t('SYMBOL')} />
                                        </div>
                                    </div>

                                </div>
                                {/* <div className="col-md-12 col-lg-12 col-xl-12 mb-2 form-floating">
                                    <MDBInput type="text" name="invoiceIDFormat" id="invoiceIDFormat" disabled={ !generateInvNo ? 'disabled' : ''} value={settings?.invoiceIDFormat} style={{height: 'auto'}} rows="5" className="form-control-sm text-black px-3" onChange={(e) => setSettings({...settings, invoiceIDFormat: e.target.value})} label={t('INVOICE_NO_FORMAT')}  />
                                </div> */}
                                <div className="col-md-12 col-lg-12 col-xl-12 mb-2 form-floating">
                                    <MDBInput type="text" name="invoiceIDGenerationMethod" id="invoiceIDGenerationMethod" value={settings?.invoiceIDGenerationMethod} style={{ height: 'auto' }} rows="5" className={`form-control-sm text-black px-3 ${settings?.invoiceIDGenerationMethod ? 'active' : ''}`} onChange={(e) => setSettings({ ...settings, invoiceIDGenerationMethod: e.target.value })} label={t('INVOICE_NUMBER_GENERATION_METHOD')} />
                                    {/* <label htmlFor="invoiceIDGenerationMethod" className="px-4">Metoda e gjenerimit të numrit të faturës</label> */}
                                </div>
                                <div className="col-md-12 col-lg-12 col-xl-12 mb-2 form-floating">
                                    <MDBInput type="text" name="fiscalPrinterPath" id="fiscalPrinterPath" value={settings?.fiscalPrinterPath} style={{ height: 'auto' }} rows="5" className="form-control-sm text-black px-3" onChange={(e) => setSettings({ ...settings, fiscalPrinterPath: e.target.value })} label={t('FISCAL_PRINTER_SOCKET')} />
                                    {/* <label htmlFor="fiscalPrinterPath" className="px-4">Shteku për printer fiskal</label> */}
                                </div>
                                <div className="col-md-12 col-lg-12 col-xl-12 mb-2">
                                    <CreatableSelect
                                        onChange=""
                                        isClearable
                                        options={partners}
                                        placeholder={t('PARTNER')}
                                        name="origine"
                                        styles={customStyles}
                                    />
                                </div>
                                <div className="col-md-12 col-lg-12 col-xl-12 mb-2">
                                    <div className="w-100 d-flex">
                                        <span className="col-7 p-0">{t('INVOICE_NO_FORMAT')}:</span>
                                        <span className="col-5 p-0 text-right">{t('EXAMPLE')}: F-yy-mm-dd-AA</span>
                                    </div>
                                    <div className="w-100 d-flex">
                                        <span className="col-7 p-0">{t('INVOICE_NUMBER_GENERATION_METHOD')}:</span>
                                        <span className="col-5 p-0 text-right">1. {t('DAILY')}, 2. {t('MONTHLY')}, 3. {t('ANNUAL')}</span>
                                    </div>
                                </div>
                                <div className="col-md-12 col-lg-12 col-xl-12 mb-2 p-5 z9">
                                    {/* <MDBInput type="file" name="image" onChange={(e) => setImage(e)}  /> */}
                                    <input className='form-control' type="file" name="image" onChange={onImgChange} />
                                    {/* <img className="col-md-12 mt-3 p-0" src={image}></img> */}
                                    {/* <Image data={settings} imgFile={image} setSettings = {setImg} /> */}
                                    <img id="imagePreview" style={{ width: '100%', marginTop: 10 }} src={imgSrc ? imgSrc : settings?.img} ></img>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Company
