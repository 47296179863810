import React, { Component } from 'react'
import { Page, Text, View, Document, StyleSheet, PDFViewer, PDFDownloadLink } from '@react-pdf/renderer';
import apiService from "../../Services/apiServices";
import mdlSale from '../../Models/Sale'
import { right } from '@popperjs/core';
import calculate from '../../Services/calculate';
import helper from '../../Services/helper';

class PDF extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sale: {},
            saleDetails: [],
            partner: {},
            saleType: {},
            units: {},
            saleId: props.match ? props.match.params.id : props.saleId
        }
        
    }

    componentDidMount() {
        apiService().query('getSale', 'id='+this.state.saleId)
            .then((response) => {
                if(response.success){
                    var sale = response.data[0];
                    this.setState({
                        sale: new mdlSale(sale)
                    });
                    this.getSaleDetails(sale.id, sale.partnerId, sale.salesTypeId)
                    //this.props.addToast('Success!')
                    //this.props.addToast('Saved Successfully', { appearance: 'success', autoDismiss: true, });
                }
                else{
                    console.log('ka gabim: ', response);
                    //this.props.addToast('Ka ndodhur nje gabim!', { appearance: 'error', autoDismiss: true, });
                }
            }); apiService().get('units').then((response) => {
                if (response.success) {
                  this.setState({ units: response.data });
                  
                } else {
                  console.log('Error:' + response.errorMessage);
                }
              });
            
    }

    getSaleDetails(saleId, partnerId, salesTypeId) {
        apiService().query('getSaleDetails', 'saleId='+saleId)
            .then((response) => {
                var data = response.data;
                if(response.success){
                    for(var i= 0; i< data.length; i++){ 
                        if(data[i].discount> 0){
                
                          data[i].priceDiscount = calculate().calculateSale('priceDiscount')(data[i].price, data[i].discount);
                          data[i].priceTvsh = calculate().calculateSale('priceTvsh')(data[i].priceDiscount ,data[i].vat);
                          data[i].totalPrice= calculate().calculateSale('totalPrice')(data[i].priceDiscount,data[i].quantity);
                          data[i].totalPriceTvsh= calculate().calculateSale('totalPriceTvsh')(data[i].totalPrice,data[i].vat);
                          // data[i].totalPriceItemTvsh= calculate().calculateSale('totalPriceItemTvsh')(data[i].totalPriceTvsh , data[i].quantity);
                
                        } else{
                          data[i].priceTvsh = calculate().calculateSale('priceTvsh')(data[i].price ,data[i].vat);
                          data[i].totalPrice= calculate().calculateSale('totalPrice')(data[i].price , data[i].quantity );
                          data[i].totalPriceTvsh= calculate().calculateSale('totalPriceTvsh')(data[i].totalPrice,data[i].vat);
                          // data[i].totalPriceItemTvsh= calculate().calculateSale('totalPriceItemTvsh')(data[i].totalPriceTvsh , data[i].quantity);
                
                        }
                      }
                      this.setState({ saleDetails: data });
                }
                else{
                    console.log('ka gabim sd: ', response);
                    //this.props.addToast('Ka ndodhur nje gabim!', { appearance: 'error', autoDismiss: true, });
                }
            });
        apiService()
            .query('partner', 'id='+partnerId)
                .then((response) => {
                    if (response.success) {
                        this.setState({
                            partner: response.data[0]
                        });
                        //console.log('Partner: ', response.data);
                    } else {
                        console.log('Error:' + response.errorMessage);
                    }
                });    
            apiService()
                .query('getSaleType', 'id='+salesTypeId)
                    .then((response) => {
                        if (response.success) {
                            this.setState({
                                saleType: response.data[0]
                            });
                        } else {
                            console.log('Error:' + response.errorMessage);
                        }
                    });
    }

    render() {
        let styles = StyleSheet.create({
            page: {
                display: 'flex',
                //flexDirection: 'row',
                //alignItems:'baseline',
                backgroundColor: '#fff',
                width: '100%',
                flexDirection: 'row'
            },
            section: {
                //paddingHorizontal: 5,
                flexGrow: 1,
                width: '50%'
            },
            td: {
                marginLeft: 10,
                marginRight: 10
            },
            text: {
                borderBottom: 1, 
                fontSize: 10,
                paddingTop: 5,
                paddingHorizontal: 2
            },
            text10: {
                fontSize: 10,
                paddingVertical: 5
            },
            pageNumbers: {
                position: 'absolute',
                bottom: 20,
                left: 0,
                right: 0,
                textAlign:'right', 
                fontSize:8,
                borderTop: 1,
                // top: '15%'
                marginLeft:5,
                marginRight:5,
                marginBottom: -5                
              },
          });
          let sale = this.state.sale;
          let saleDetails = this.state.saleDetails;
          let partner = this.state.partner

        return (
            
            <div className="pdf my-4">
            <PDFViewer>
                <Document style={{display: 'flex', justifyContent: 'center'}}>
                    <Page size="A4" style={{paddingVertical: 10, paddingHorizontal: 10}} wrap>
                        <View style={styles.page}>
                            <View style={styles.section}></View>
                            <View style={styles.section}>
                                <Text style={[styles.text, {fontSize: 14, fontWeight: 'bold'}]}>{partner.saveAs}</Text>
                                <Text style={styles.text}>Numri Fiskal:</Text>
                                <Text style={styles.text}>Numri i Biznesit:</Text>
                                <Text style={styles.text}>Numri i Tvsh:</Text>
                                <Text style={styles.text}>Adresa:</Text>
                                <Text style={styles.text}>Numri i Bankës:</Text>
                                <Text style={styles.text}>Pagesa e faturës të bëhet në afat prej 10 ditëve</Text>
                            </View>
                        </View>
                        <hr></hr>
                        <View style={[styles.page, {borderTop: 1, marginTop: 5, paddingVertical: 5, alignItems: "flex-end"}]}>
                            <View style={[styles.section, {width: '60%', padding: 5, borderLeft: 1}]}>
                                <Text style={[styles.text, {fontSize: 14, fontWeight: 'bold', width: '100%'}]}>Blerësi: {partner.saveAs}</Text>
                                <View style={[styles.page, { width: '100%'}]}>
                                    <View style={styles.section}>
                                        <Text style={styles.text10}>Numri Fiskal: {partner.fiscalNo}</Text>
                                        <Text style={styles.text10}>Numri i Biznesit: {partner.businessNo}</Text>
                                        <Text style={styles.text10}>Numri i Tvsh: {partner.vatNo}</Text>
                                    </View>
                                    <View style={[styles.section, {borderLeft: 1, paddingLeft: 5}]}>
                                        <Text style={styles.text10}>Adresa: {partner.address}</Text>
                                        <Text style={styles.text10}>Qyteti: {partner.city}</Text>
                                        <Text style={styles.text10}>Telefoni: {partner.phone}</Text>
                                    </View>
                                </View>
                                <Text style={{border: 1, fontSize: 10, padding: 5, width: '100%', marginTop: 5}}>Data: {helper().formatDate(sale.date)}</Text>
                            </View>
                            <View style={[styles.section, {width: '40%' , paddingVertical: 5, paddingHorizontal: 20}]}>
                                <Text style={{fontSize: 18, fontWeight: 'bold', textAlign: 'center'}}>{sale.salesTypeId == 1 ? 'Faturë Shitje' : (sale.salesTypeId == 2) ? 'Faturë '+this.state.saleType.Name : this.state.saleType.Name }</Text>
                                <Text style={{fontSize: 18, fontWeight: 'bold', textAlign: 'center', height: 30}}></Text>
                                <Text style={{fontSize: 18, fontWeight: 'bold', textAlign: 'center', borderBottom: '1px dotted #000'}}>{sale.invoiceNo}</Text>
                            </View>
                        </View>
                        <View style={{border: 1, marginVertical: 5,  alignItems: "flex-end"}}>
                            <View style={[styles.page, {flex: 1, borderBottom: 1, padding: 5}]}>
                                <Text style={[styles.text10, {width: '2%', textAlign:'center'}]}>Nr</Text>
                                <Text style={[styles.text10, {width: '10%', textAlign:'center'}]}>Barkodi</Text>
                                <Text style={[styles.text10, {width: '18%', textAlign:'center'}]}>Emërtimi</Text>
                                <Text style={[styles.text10, {width: '5%', textAlign:'center'}]}>Njësia</Text>
                                <Text style={[styles.text10, {width: '5%', textAlign:'center'}]}>Sasia</Text>
                                <Text style={[styles.text10, {width: '5%', textAlign:'center'}]}>Çmimi</Text>
                                <Text style={[styles.text10, {width: '10%', textAlign:'center'}]}>Çmimi me Tvsh</Text>
                                <Text style={[styles.text10, {width: '10%', textAlign:'center'}]}>Zbritja %</Text>
                                <Text style={[styles.text10, {width: '10%', textAlign:'center'}]}>Çmimi me Zbritje %</Text>
                                <Text style={[styles.text10, {width: '8%', textAlign:'center'}]}>Tvsh %</Text>
                                <Text style={[styles.text10, {width: '7%', textAlign:'center'}]}>Tvsh Vlera</Text>
                                <Text style={[styles.text10, {width: '10%', textAlign:'center'}]}>Totali me Tvsh</Text>
                            </View>
                            {saleDetails.map((item, index) => (
                                <View key={index} style={[styles.page, { borderTop: 1, paddingHorizontal: 5, overflow: 'hidden', height:'auto'}]}>
                                    <Text style={[styles.text10, {width: '2%', textAlign:'center'}]}>{item.no}</Text>
                                    <Text style={[styles.text10, {width: '10%', textAlign:'center'}]}>{item.barcode}</Text>
                                    <Text style={[styles.text10, {width: '18%', textAlign:'center'}]}>{item.itemName}</Text>
                                    <Text style={[styles.text10, {width: '5%', textAlign:'center'}]}>{(this.state.units.find(u => u.id === item.unitId)).name}</Text>
                                    <Text style={[styles.text10, {width: '5%', textAlign:'center'}]}>{item.quantity}</Text>
                                    <Text style={[styles.text10, {width: '5%', textAlign:'center'}]}>{typeof(item.price) == 'undefined' ? 
                                    item.price : item.price.toFixed(2) }</Text>
                                    <Text style={[styles.text10, {width: '10%', textAlign:'center'}]}>{typeof(item.priceTvsh) == 'undefined' ? 
                                    item.priceTvsh : item.priceTvsh.toFixed(2) }</Text>
                                    <Text style={[styles.text10, {width: '10%', textAlign:'center'}]}>{item.discount}</Text>
                                    <Text style={[styles.text10, {width: '10%', textAlign:'center'}]}>{typeof(item.priceDiscount) == 'undefined' ? 
                                    item.priceDiscount : item.priceDiscount.toFixed(2) }</Text>
                                    <Text style={[styles.text10, {width: '8%', textAlign:'center'}]}>{item.vat}</Text>
                                    <Text style={[styles.text10, {width: '7%', textAlign:'center'}]}>{typeof(item.vatSum) == 'undefined' ? 
                                    item.vatSum : item.vatSum.toFixed(2) }</Text>
                                    <Text style={[styles.text10, {width: '10%', textAlign:'center'}]}>{typeof(item.totalPriceTvsh) == 'undefined' ? 
                                    item.totalPriceTvsh : item.totalPriceTvsh.toFixed(2) }</Text>
                                </View>
                            ))}
                        </View>
                        <View style={[styles.page, {marginTop: 5, paddingVertical: 5, alignItems: "flex-end"}]}>
                            <View style={[ {width: '65%', padding: 5}]}></View>
                            <View style={[ {width: '35%' , paddingVertical: 5}]}>
                                <View style={[styles.page, {borderBottom: 1, marginTop: 5, paddingVertical: 3, paddingHorizontal:5, alignItems: "flex-end"}]}>
                                    <View style={{width: '50%'}}>
                                        <Text style={[styles.text10, {textAlign:'left'}]}>Totali: </Text>
                                    </View>
                                    <View style={{width: '50%'}}>
                                        <Text style={[styles.text10, {textAlign:'right'}]}>{ 
                                        typeof((sale.totalSum - sale.vatSum )) == 'undefined' ? 
                                        (sale.totalSum - sale.vatSum) : (sale.totalSum - sale.vatSum ).toFixed(2)
                                        }</Text>
                                    </View>
                                </View>
                                <View style={[styles.page, {borderBottom: 1, marginTop: 5, paddingVertical: 3, paddingHorizontal:5, alignItems: "flex-end"}]}>
                                    <View style={{width: '50%'}}>
                                        <Text style={[styles.text10, {textAlign:'left'}]}>Shuma e Tvsh: </Text>
                                    </View>
                                    <View style={{width: '50%'}}>
                                        <Text style={[styles.text10, {textAlign:'right'}]}>{typeof(sale.vatSum) == 'undefined' ? 
                                    sale.vatSum : sale.vatSum.toFixed(2)}</Text>
                                    </View>
                                </View>
                                <View style={[styles.page, {borderBottom: 1, marginTop: 5, paddingVertical: 3, paddingHorizontal:5, alignItems: "flex-end"}]}>
                                    <View style={{width: '50%'}}>
                                        <Text style={[styles.text10, {textAlign:'left'}]}>Totali me Tvsh: </Text>
                                    </View>
                                    <View style={{width: '50%'}}>
                                        <Text style={[styles.text10, {textAlign:'right'}]}>{typeof(sale.totalSum) == 'undefined' ? 
                                    sale.totalSum : sale.totalSum.toFixed(2)}</Text>
                                    </View>
                                </View>
                            </View>
                        </View> 
                        <View style={[styles.page, { width: '100%', marginTop: 80}]}>
                            <View style={[{textAlign: 'center', width: '50%'}]}>
                                <Text style={[styles.text10, {width: '60%', borderTop: 1, margin: 'auto'}]}>Dorëzoi</Text>
                            </View>
                            <View style={[{textAlign: 'center', width: '50%'}]}>
                                <Text style={[styles.text10, {width: '60%', borderTop: 1, margin:'auto'}]}>Pranoi</Text>
                            </View>
                        </View>
                        <Text style={styles.pageNumbers} fixed>Implementuar nga T3 Solutions</Text>
                    </Page>
                    {/* <Page>
                    <View style={styles.section}>
                            
                            </View>
                            <View style={styles.section}>
                                    {this.items.map((item, index) => (
                                        <Text key={item.id} style={styles.section}>
                                            <td style={styles.td}>{item.id}</td>
                                            <td style={styles.td}>{item.title +' '+ index}</td>
                                            <td style={styles.td}>{item.date}</td>
                                        </Text>
                                    ))}
                            </View>
                        <View style={styles.page}>
                            <View style={styles.section}>
                                <Text style={styles.div}>Section #3</Text>
                            </View>
                            <View style={styles.section}>
                                <Text>Section #4</Text>
                            </View>
                        </View>
                    </Page> */}
                </Document>
            </PDFViewer>
            </div>
        )
    }
}

export default PDF
