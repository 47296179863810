import React, { useState, useEffect, useRef } from 'react'
import { Page, Text, View, Document, StyleSheet, PDFViewer, PDFDownloadLink } from '@react-pdf/renderer';
import apiService from '../../../Services/apiServices';
import ModelPurchases from '../../../Models/Purchases';
import calculate from '../../../Services/calculate';
import helper from '../../../Services/helper';
import globals from '../../../Services/globals';

const WarehouseTransferPDF = (params) => {
    const [items, setItems] = useState();
    const [transaction, setTransaction] = useState();
    const [transactionDetails, setTransactionDetails] = useState();
    const [warehouse , setWarehouse] = useState();
    const [partner, setPartner] = useState();
    const [settings, setSettings] = useState();
    const [units, setUnits] = useState();
    const [total, setTotal] = useState(0);
   
    const styles = StyleSheet.create({
        page: {
            display: 'flex',
            //flexDirection: 'row',
            alignItems:'center',
            backgroundColor: '#fff',
            width: '100%',
            flexDirection: 'row'
        },
        section: {
            //paddingHorizontal: 5,
            flexGrow: 1,
            width: '50%',
            margin: 3,
            padding: 5
        },
        td: {
            marginLeft: 10,
            marginRight: 10
        },
        text: {
            // borderBottom: 1, 
            fontSize: 9,
            paddingTop: 2,
            paddingHorizontal: 2
        },
        text10: {
            fontSize: 8,
            paddingVertical: 2
        },
        pageNumbers: {
            position: 'absolute',
            bottom: 20,
            left: 0,
            right: 0,
            textAlign:'right', 
            fontSize:8,
            // borderTop: 1,
            // top: '15%'
            marginLeft:5,
            marginRight:5,
            marginBottom: -5                
          },
        more35: {
            marginTop: 60
        },
        less35: {
            position: 'absolute', 
            bottom:30
        }
      });


    
    const getItems = () => {
        apiService().get('items')
            .then((response) => {
                if (response.success) {
                    setItems(response.data);
                } else {
                    console.log('Error:' + response.errorMessage);
                }
            }); 
    }

    const getWarehouses = () => {
        apiService()
          .get("warehouses")
          .then((response) => {
            if (response.success) {
              let data = response.data;
              setWarehouse(data);
            } else {
              alert("Error: getting warehouses" + response.errorMessage);
            }
          });
    }

    const getSettings = async () => {
        
        globals().getSettings().then((response) => {
            if (response) {
                setSettings(response);
            } else {
                console.log('Error:' + response.errorMessage);
            }
        });
    }

    const getUnits = () => {
        apiService().get('units')
            .then((response) => {
                if (response.success) {
                    setUnits(response.data);
                } else {
                    console.log('Error:' + response.errorMessage);
                }
            });
    }
    

    const getTransferItem = (id) => {
        apiService().get("WarehouseTransfers" , "id="+id).then((response) => {
             if (response.success) {
               var data = response.data;
               setTransaction({...data[0]});
               apiService().query("WarehouseTransfersDetails" , "transferId="+ id).then((response) => {
                 if (response.success) {
                   var res = response.data;
                   var total= 0
                   res.forEach(e => {
                     e.total = (parseFloat(e.quantity) * parseFloat(e.avgPrice)).toFixed(2);
                     e.barcode = items?.find(i => i.id === e.itemId)?.barcode;
                     total=  parseFloat(e.total)  + total;
                   });
                   total = total.toFixed(2)
                   setTotal(total);
                   setTransactionDetails(res);
                   
                 } else {
                   console.log('Error:' + response.errorMessage);
                 }
               });
               
             } else {
               console.log('Error:' + response.errorMessage);
             }
           });
     }

    const translate = (key) => {
        return helper().translate(key);
    }

    const setUnit = (unitId) => {
        return units?.find(u => u.id === unitId)?.name
    }


    const checkColumn = (property, items) => {
        // const isTrue = (currentValue) => currentValue[property] == 0;
        // return !items?.every(isTrue)
        return true
    }

    useEffect(() => {
        // getPurchaseTypes()
        getItems();
        
    }, [])
    useEffect(() => {
        getTransferItem(params.match.params.id);
        getWarehouses();
        getSettings();
        getUnits();
        
    }, [items])
    return (
        
        <div className='pdf my-4'>
            {
                
            }
            <PDFViewer>
                <Document style={{justifyContent: 'center'}}>
                    <Page orientation='landscape' size="A4" style={{paddingVertical: 10, paddingHorizontal: 15, marginTop: 5}} wrap>
                        <View style={styles.page}><Text style={{marginHorizontal: 'auto', textDecoration: 'underline'}}>{translate("INTERNAL_MOVEMENT")}</Text></View>
                        <View style={[styles.page ,{marginTop:'20px'}]}>
                            <View style={[styles.section, {border: '1px'}]}>
                                <Text style={{fontSize: 12}}>{translate("FROM_WAREHOUSE")}: {warehouse?.find(w => w.id === transaction?.fromWarehouse)?.name}</Text>
                            </View>
                            <View style={styles.section}>
                            <Text style={{fontSize: 12}}>{translate("DOCUMENT_NR")}: {transaction?.id}</Text>
                            <Text style={{fontSize: 12}}>{translate("DATE")}: {helper().formatDate(transaction?.date)}</Text>
                            </View>
                            <View style={[styles.section, {border: '1px'}]}>
                                 <Text style={{fontSize: 12}}>{translate("TO_WAREHOUSE")}: {warehouse?.find(w => w.id === transaction?.toWarehouse)?.name}</Text>
                            </View>
                        </View>
                        <View style={{border: 1 , borderBottom: 0, marginVertical: 0, marginTop: 5, alignItems: "flex-end"}}>
                            <View style={[styles.page, { borderBottom: 1, paddingHorizontal: 0, minHeight: '12px'}]}>
                                <Text style={[styles.text10, {width: '5%', textAlign:'center', borderRight: 0.5}]}>{translate('NU')}</Text>
                                <Text style={[styles.text10, {width: '20%', textAlign:'center', borderRight: 0.5}]}>{translate('ITEM')}</Text>
                                <Text style={[styles.text10, {width: '20%', textAlign:'center', borderRight: 0.5}]}>{translate('BARCODE')}</Text>
                                <Text style={[styles.text10, {width: '15%', textAlign:'center', borderRight: 0.5}]}>{translate('QUANTITY')}</Text>
                                <Text style={[styles.text10, {width: '20%', textAlign:'center', borderRight: 0.5}]}>{translate('PURCHASE_PRICE')}</Text>
                                <Text style={[styles.text10, {width: '20%', textAlign:'center', borderRight: 0.5}]}>{translate('TOTAL')}</Text>
                            </View>
                            {transactionDetails?.map((item, index) => (
                                <View key={index} style={[styles.page, {borderBottom: 1, paddingHorizontal: 0, overflow: 'hidden', height:'auto', backgroundColor: 'transparent'}]}>
                                    <Text style={[styles.text10, {width: '5%', textAlign:'center', borderRight: 0.5}]}>{item.no}</Text>
                                    <Text style={[styles.text10, {width: '20%', textAlign:'center', borderRight: 0.5}]}>{items?.find(i => i.id === item.itemId)?.itemName }</Text>
                                    <Text style={[styles.text10, {width: '20%', textAlign:'center', borderRight: 0.5}]}>{item?.barcode}</Text>
                                    <Text style={[styles.text10, {width: '15%', textAlign:'center', borderRight: 0.5}]}>{item?.quantity}</Text>
                                    <Text style={[styles.text10, {width: '20%', textAlign:'center', borderRight: 0.5}]}>{parseFloat(item?.avgPrice).toFixed(2)}</Text>
                                    <Text style={[styles.text10, {width: '20%', textAlign:'center', borderRight: 0.5}]}>{parseFloat(item?.total).toFixed(2) }</Text>
                                </View>
                            ))}
                        </View>
                        <View style={{ marginVertical: 0, display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', marginTop: 13}}>
                        <View style={[styles.page, {flex: 1, paddingHorizontal: 0, minHeight: '12px', backgroundColor: '#fff' , justifyContent: 'flex-end'}]}>
                                <Text style={[styles.text10, {width: '4%', textAlign:'right', fontSize: '10px'}]}>{translate('TOTAL')}:</Text>
                                <Text style={[styles.text10, {width: '20.1%', textAlign:'center', border: 1, fontSize: '10px' , fontWeight: 'bold'}]}>{total}</Text>
                                
                            </View>
                           
                        </View>
                        <View style={[ { width: '100%', display: 'flex', flexDirection: 'row'}, styles.less35 ]}>
                            <View style={[{textAlign: 'left', marginLeft: 20, width: '50%'}]}>
                                <Text style={[styles.text10, {width: '100%', margin: 'auto'}]}>Kalkuloi: _____________________</Text>
                            </View>
                            <View style={[{textAlign: 'right', width: '50%'}]}>
                                <Text style={[styles.text10, {width: '100%', margin: 'auto'}]}>Data e shtypjes: {helper().formatDate(new Date)}</Text>
                            </View>
                        </View>
                    </Page>
                </Document>
            </PDFViewer>
        </div>
    )
}

export default WarehouseTransferPDF
