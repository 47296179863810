import React, { useState, useEffect } from 'react'
import TabNav from './TabNav'
import TabContent from './TabContent'
// import { useEffect } from 'react/cjs/react.development';

const Tabs = ({tabs}) => {
    const [show, setShow] = useState(1);

    useEffect(() => {
        // console.log('show: ', props)
    }, [show])

    return (
        <div>
            <div className="col-xl-12 col-lg-12 col-md-12 px-0 px-md-0 mt-1" >
                <div className="row justify-content-center">
                    <div className="col-xl-12 col-lg-12 col-md-12">
                        <nav>
                            <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                {tabs.map((tab, index) => (
                                    tab.show && <TabNav setShow={setShow} active={index == 0 ? true : false} id={tab.id} title={tab.title} />
                                ))}
                            </div>
                        </nav>
                        <div className="tab-content mb-5" id="nav-tabContent">
                            {tabs.map((tab, index) => (
                                tab.show && <TabContent show={show === tab.id ? true : false} active={index == 0 ? true : false} title={tab.title} body={tab.body} />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Tabs