
class SaleModel {
    constructor(obj){
        if(obj){
            this.id = obj.id
            this.date = obj.date;
            this.endDate = obj.endDate;
            this.saleId = obj.saleId;
            this.cashBoxId = obj.cashBoxId;
            this.warehouseId = obj.warehouseId;
            this.invoiceNo = obj.invoiceNo;
            this.reference = obj.reference;
            this.partnerId =  obj.partnerId;
            this.paymentMethod = obj.paymentMethod;
            this.salesTypeId = obj.salesTypeId;
            this.totalSum = obj.totalSum;
            this.withVat = obj.withVat;
            this.export = obj.export;
            this.vatSum = obj.vatSum;
            this.subTotal = obj.subTotal;
            this.currency = obj.currency;
            this.currencyRate = obj.currencyRate;
            this.comment = obj.comment;
            this.printed = obj.printed;
            this.createdAt = obj.createdAt;
            this.createdBy = obj.createdBy;
            this.changedAt = obj.changedAt;
            this.changedBy = obj.changedBy;
            this.status = obj.status;
            
        }
        else {
   
            this.date = new Date();
            this.endDate = '';
            this.saleId = 0;
            this.cashBoxId = 0;
            this.warehouseId = null;
            this.invoiceNo = '';
            this.reference = '';
            this.partnerId = null;
            this.paymentMethod = 3;
            this.salesTypeId = 1;
            this.totalSum = 0;
            this.withVat = 0;
            this.export = 0;
            this.vatSum = 0;
            this.cashToPay = 0;
            this.currency = 'euro';
            this.currencyRate = 0;
            this.comment = '';
            this.printed = 0;
            //this.createdAt = new Date();
            this.createdBy = '';
            this.totalSalePayment = 0;
            this.amountLeft = 0;
            this.allowLackItems = 0;
            //this.changedAt =
            //this.changedBy = 
            this.status = 0;
        }
    }
}

export default SaleModel;