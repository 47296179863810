import React, { useState, useEffect } from "react";
import Select from "react-select";
import { MDBInput } from "mdb-react-ui-kit";
import apiService from "../../Services/apiServices";
import { useToasts } from "react-toast-notifications";
import Search from "../../PageComponents/Search/Search_Old";
import { confirmAlert } from "react-confirm-alert";
import helper from "../../Services/helper";
import globals from "../../Services/globals";
import LoaderComponent from "../../PageComponents/UI/Components/LoaderComponent";
import DataTable from "../../PageComponents/UI/Tables/DataTable";

function Transactions(props) {
    const [items, setItems] = useState([]);
    const [find, setFind] = useState([]);
    const [findByDate, setFindByDate] = useState({ dateEnd: new Date(), dateEndDisabled: true, dateStart: new Date(new Date().getFullYear(),new Date().getMonth(),new Date().getDate()-30), dateStartDisabled: true });
    const [dataShow, setDataShow] = useState(true);
    const [userColumns, setUserColumns] = useState([]);
    const [newColumns, setNewColumns] = useState(["DATE", "COMMENT", "REGISTRATION_DATE", "REGISTERED_BY", "DATE_OF_MODIFICATION", "MODIFIED_BY", "TRANSACTION_TYPE"]);
    const [showColMenu, setShowColMenu] = useState(false);
    const [loader , setLoader] = useState(false);
    const { addToast } = useToasts();

    useEffect(() => {
      setLoader(true);
      getItemsByMonth();
    }, [])

    useEffect(() => {
      setLoader(true);
      getItemsByMonth();
    }, [props?.location?.search])

    const selectStyles = {
      control: (provided, state) => ({
        ...provided,
        background: "#fff",
        borderColor: "#9e9e9e",
        minHeight: "28.59px",
        height: "28.59px",
        boxShadow: state.isFocused ? null : null,
      }),

      valueContainer: (provided, state) => ({
        ...provided,
        height: "28.59px",
        padding: "0 6px",
      }),

      input: (provided, state) => ({
        ...provided,
        margin: "0px",
      }),
      indicatorSeparator: (state) => ({
        display: "none",
      }),
      indicatorsContainer: (provided, state) => ({
        ...provided,
        height: "28.59px",
      }),
    };
    
    const headers = [
      { key: 'countNumber', columnName: helper().translate('NR')},
      { key: 'date', columnName: helper().translate('DATE') },
      { key: 'comment', columnName: helper().translate('COMMENT')  , type:"comment" },
      { key: 'createdAt', columnName: helper().translate('REGISTRATION_DATE') , type:"text" },
      { key: 'createdBy', columnName: helper().translate('REGISTERED_BY') ,type:"text" } ,
      { key: 'changedAt', columnName: helper().translate('DATE_OF_MODIFICATION'), type:"text" },
      { key: 'changedBy', columnName: helper().translate('MODIFIED_BY'),  type:'text' },
      { key: 'transactionName', columnName: helper().translate('TRANSACTION_TYPE') , type:"text" }
    ]

    const edit = (item) => {
      window.location.href = `#/finances/transactionDetails/${item.id}` ;
    }
    const fieldChange = (e) => {
      if (e.target.name === "dateStart") {
        setFindByDate({ ...findByDate, dateStart: new Date(e.target.value) });
      } else
        if (e.target.name === "dateEnd") {
          setFindByDate({ ...findByDate, dateEnd: new Date(e.target.value)});
        }
    };


    const findChange = (e) => {
      if (e.target.value == "") {
        getItemsByMonth();
      }
      var searchItem;
      let searchString = e.target.value.trim().toLowerCase();

      if (searchString.length > 0) {
        // We are searching. Filter the results.
        //searchItems = searchItems.filter((e) => e.ItemName.toLowerCase().match(searchString));
        searchItem = items.filter((item) => {
          return (
            item.transactionName.toString().toLowerCase().match(searchString) ||
            item.comment.toString().toLowerCase().match(searchString) ||
            item.createdBy.toString().toLowerCase().match(searchString)
          );
        });
        setFind(searchItem);
      }
    };

    const alertnOnDelete = (id) => {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="custom-ui">
              <h1>{helper().translate("ARE_YOU_SURE")}</h1>
              <p>{helper().translate("WANT_TO_DELETE_THIS_TRANSACTION")}</p>
              <button
                onClick={() => {
                  handleDelete(id);
                  onClose();
                }}
              >
                {helper().translate("YES")}, {helper().translate("DELETE")}!
              </button>
              <button onClick={onClose}>{helper().translate("NO")}</button>
            </div>
          );
        },
      });
    }


    const getItemsByMonth = () => {
      // var getDate = new Date();
      // let day = `${getDate.getDate()}`.padStart(2, "0");
      // let month = `${getDate.getMonth()}`.padStart(2, "0");
      // if (getDate.getMonth() == 0) {
      //   var dateStart = (((getDate.getFullYear() - 1) + "-" + 12 + "-" + day)) + ' ' + "00:00:00";
      // } else {
      //   var dateStart = ((getDate.getFullYear() + "-" + month + "-" + day)) + ' ' + "00:00:00";
      // }
      // let dateEnd = new Date().toISOString().substring(0, 10) + ' ' + "23:59:59";
      let eDate = new Date();
      let sDate = new Date(eDate.getFullYear(),eDate.getMonth(),eDate.getDate()-30); 
      setFindByDate({ ...findByDate, dateEnd: eDate, dateStart: sDate });

      getItemsBySearch(sDate, eDate);
    }

    const getItemsBySearch = (dateStart, dateEnd) => {
      setLoader(true);
      
      let strStartDate = helper().formatDate(dateStart,'MM-dd-yyyy');
      let strEndDate = helper().formatDate(dateEnd,'MM-dd-yyyy') + " 23:59:59"
      var params = `dateStart=${strStartDate}&dateEnd=${strEndDate}`;
      apiService()
        .query('transactionFromTo', params)
        .then((response) => {
          if (response.success) {
            var count = 1;
            var data = response.data;
            data.forEach(e => { e.countNumber = count; count++; e.transactionName = helper().translate(e.transactionName) , e.date = helper().formatDate(e.date), e.createdAt = helper().formatDate(e.createdAt), e.changedAt = helper().formatDate(e.changedAt)})
            let str = props?.location?.search
            if(str) {
              data.filter(d => d.TransactionTypeId === parseInt(str.charAt(str.length - 1)))
              setFind(data.filter(d => d.TransactionTypeId === parseInt(str.charAt(str.length - 1))));
            }
            else {
              setFind(data);
            }
            
            setItems(data);
            setLoader(false)
            setDataShow(response.data?.length > 0 ? true : false);

          } else {
            console.log('Error:' + response.errorMessage);
          }
        });
    }

    const searchBtn = () => {

      let startDate = findByDate.dateStart, endDate = findByDate.dateEnd;

      let strStartDate = helper().formatDate(startDate,'MM-dd-yyyy');
      let strEndDate = helper().formatDate(endDate,'MM-dd-yyyy') + " 23:59:59"

      if(!findByDate.dateStartDisabled) strStartDate = `01-01-${new Date().getFullYear()}`;
      if(!findByDate.dateEndDisabled) strEndDate = "01-01-2222";

      getItemsBySearch(strStartDate, strEndDate);

    };

    const handleDelete = (id) => {
      apiService()
        .query("deleteTransaction", "id=" + id)
        .then((response) => {
          if (response.success) {
            getItemsByMonth();
          } else {
            alert("Error:" + response.errorMessage);
          }
        });
    }


  return (
    <div className="container-fluid px-2 mt-3">
      <Search new={`#finances/transactionDetails/0${props?.location?.search}`} change={(e) => findChange(e)} back="#/" />
      <div className="card border-left-primary shadow h-100 mt-1">
        <div className="card-body pt-3 pb-1">
          <div className="row">
            <div className="col-12">
              <div className="row no-gutters align-items-center">
                <div className="col row">
                  <div className="col-lg-3 mb-3 mb-md-0 pe-0 d-flex align-items-center">
                    {/* <label className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                              Data e fillimit
                            </label> */}
                    <div style={{ width: '5%' }}>
                      <input type="checkbox" name="includeStartDate" checked={findByDate.dateStartDisabled} onChange={(e) => setFindByDate({ ...findByDate, dateStartDisabled: e.target.checked })} ></input>
                    </div>
                    <div style={{ width: '95%' }}>
                      <MDBInput
                        name="dateStart"
                        disabled={!findByDate.dateStartDisabled}
                        className="form-control-sm active"
                        onChange={(e) => fieldChange(e)}
                        type="date"
                        label={helper().translate("START_DATE")}
                        value={helper().isValidDate(findByDate?.dateStart)  ?  findByDate?.dateStart.toISOString().substring(0,10): ''}
                      />
                    </div>
                  </div>
                  <div className="col-lg-3 mb-3 mb-md-0 pe-0 d-flex align-items-center">
                    {/* <label className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                              Data e Mbarimit
                            </label> */}
                    <div style={{ width: '5%' }}>
                      <input type="checkbox" name="includeStartDate" checked={findByDate.dateEndDisabled} onChange={(e) => setFindByDate({ ...findByDate, dateEndDisabled: e.target.checked })} ></input>
                    </div>
                    <div style={{ width: '95%' }}>
                      <MDBInput
                        name="dateEnd"
                        disabled={!findByDate.dateEndDisabled}
                        className="form-control-sm active"
                        onChange={(e) => fieldChange(e)}
                        type="date"
                        label={helper().translate("END_DATE")}
                        value={helper().isValidDate(findByDate?.dateEnd)  ?  findByDate?.dateEnd.toISOString().substring(0,10): ''}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-12 d-flex align-items-center justify-content-center  justify-content-lg-end">
                    <div>
                      <a className="searchbutton" onClick={() => searchBtn()}>
                        <i className="fas fa-search fa-2x text-dark"  />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="card shadow mt-1">
        <div className="card-header py-2"><h6 className="m-0 font-weight-bold text-primary">{helper().translate("TRANSACTION_LIST")}</h6></div>
        {loader && <LoaderComponent /> } 
        {!loader ? dataShow ? <DataTable data={items} headers={headers}  edit={edit} delete={alertnOnDelete} headersFrom={"FINANCE"} formName="financialOrder"/> : <div className="p-5 text-center">{helper().translate("NO_DATA")}</div> : ''}  
      </div>
    </div>
  );
}
export default Transactions;
