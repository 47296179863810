import React, { Component } from 'react';
import globals from '../../Services/globals.js';
import loginService from '../../Services/loginService.js';
// import smallImg from '../../../img/avataruser.png'
import avatar from '../../img/avatar.svg'
import helper from '../../Services/helper.js';
import apiService from '../../Services/apiServices.js';

class Header extends Component {

  constructor(props) {
    super(props);
    this.logout = this.logout.bind(this);

    this.globals = new globals();
    this.loginService = new loginService();

    this.state = {
      user: '',
      companyName: '',
      settings: {},
      roleId: 2,
      userPermissions: []
    };
  }

  componentDidMount() {
    var user = this.globals.getLogedInUser();
    if (!user) {
      return;
    }
    var company = user.company;
    user = user?.data;

    if (user) {
      this.setState({ user: user.firstname + ' ' + user.lastname })
      this.setState({ companyName: company });
    }

    this.getUserSettings();

    setInterval(() => {
      this.getUserSettings()
    }, 1000);

    this.getRole();
    this.getPermissions();
  }

  componentDidUpdate() {
    if (window.location.hash === "#/settingsTrue") {
      this.getSettings();
    }

  }

  getUserSettings = () => {
    globals().getUserSettings().then((sett) => {
      if (sett) { this.setState({ ['settings']: sett }) }
    });
  }

  // getSettings = async () => {
  //   apiService().get('settings').then((response) => {
  //     if (response.success) {
  //       let sett = response.data[0];
  //       this.setState({ companyName: sett.companyName });
  //     } else {
  //       console.log('Error:' + response.errorMessage);
  //     }
  //   });
  // }

  getRole = () => {
    let logedInUser = globals().getLogedInUser();
    apiService().query('userRoles', 'username=' + logedInUser?.data?.username)
      .then((response) => {
        if (response.success) {
          if (response.data && response.data.length > 0) {
            this.setState({
              roleId: response.data[0].roleId
            })
          }

        }
      })
  }

   getPermissions = () => {
    var params = `&username=${globals().getLogedInUser().data?.username}`;
    apiService().query('getUserPermissions',params)
        .then((response) => {
         if(response.success){
          this.setState({
            userPermissions: response.data
          })
         }
        })
  }

  logout = (e) => {
    loginService().logout();
  }

  render() {
    const { user, settings, companyName , roleId } = this.state;
    return (
      <nav id="navi" style={{ background: settings?.style ? settings.style : '#5a5c69' }} className="navbar navbar-expand navbar-light col-md-12 topbar static-top position-fixed px-1 px-md-3">
        {/* <a className='col-lg-2 mb-0 navbar-brand' style={{ textDecoration: "none", color: "#fff" }} href='#/selectcompany'>{companyName}</a> */}
        <div className='col-lg-2  '>
        <div className="btn-group companyNameDashBoard shadow-none" style={{ background: settings?.style ? settings.style : '#5a5c69' }}>
          <button type="button" className="btn shadow-sm  bg-body-tertiary rounded  "><a className=''  href='#/'>{companyName.substring(0,25)}</a></button>
          <button type="button" className="btn shadow-sm  bg-body-tertiary rounded  dropdown-toggle dropdown-toggle-split" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <span className="sr-only">Toggle Dropdown</span>
          </button>
          <div className="dropdown-menu" style={{ background: settings?.style ? settings.style : '#5a5c69' }}>
          <button type="button" className='px-0  border border-0 w-100' style={{ background: settings?.style ? settings.style : '#5a5c69' }} ><a   href='#/selectcompany'>{helper().translate("CHANGE_COMPANY")}</a></button>
          </div>
        </div>
        </div>
        <ul className="col-lg-8 top-menu d-md-flex justify-content-center text-center mb-0 overflow-auto overflow-md-auto">
          {/* <li className="px-2 px-md-3"><a href="#/">{helper().translate('DASHBOARD')}</a></li> */}
          <li className="px-2 px-md-3"><a href="#/purchases?type=1">{helper().translate('PURCHASES')}</a></li>
          <li className="px-2 px-md-3"><a href="#/sales">{helper().translate('SALES')}</a></li>
          <li className="px-2 px-md-3"><a href="#/payments/cash">{helper().translate('PAYMENTS')}</a></li>
          <li className="px-2 px-md-3"><a href="#/expenses">{helper().translate('EXPENSES')}</a></li>
          <li className="px-2 px-md-3"><a href="#/partners">{helper().translate('REGISTRATIONS')}</a></li>
          <li className="px-2 px-md-3"><a href={this.state.userPermissions.find((f)=> f.moduleName === 'FINANCE')?.allowView ? "#/finances" : "#/"} >{helper().translate('FINANCE')}</a></li>
          <li className="px-2 px-md-3"><a href="#/reports">{helper().translate('REPORTS')}</a></li>
          {/* {roleId === 1 && <li className="px-2 px-md-3"><a href="#/administration">{helper().translate('ADMINISTRATION')}</a></li>}
          {roleId === 1 && <li className="px-2 px-md-3"><a href="#/settings">{helper().translate('SETTINGS')}</a></li>} */}
          <li className="px-2 px-md-3"><a href="#/features/warehouse-transfers">{helper().translate('OTHER')}</a></li>
        </ul>
        <ul className="col-lg-2 navbar-nav d-flex flex-row-reverse">
          {/* Nav Item - User Information */}
          <li className="nav-item dropdown no-arrow">
            <span className="nav-link dropdown-toggle px-1 px-md-3" href="#" id="userDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <span className="mr-2 d-none d-lg-inline  small">{user}</span>
              <a href="#" >
                <img className="img-profile rounded-circle" src={avatar} />
                {/* {avatar} */}
              </a>
            </span>
            {/* Dropdown - User Information */}
            <div className="dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="userDropdown">
              <a className="dropdown-item" href={roleId !== 10 ? '#/user-profile' : ''}>
                <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400" />
                Profile
              </a>

              <a className="dropdown-item" href="#/settings">
                <i className="fas fa-cogs fa-sm fa-fw mr-2 text-gray-400" />
                Settings
              </a>
              <a className="dropdown-item" href="#">
                <i className="fas fa-list fa-sm fa-fw mr-2 text-gray-400" />
                Activity Log
              </a>
              <div className="dropdown-divider" />
              <button className="dropdown-item" href="#" onClick={this.logout} >
                <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400" />
                Logout
              </button>
            </div>
          </li>
        </ul>
      </nav>
    );
  }
}

export default Header;
