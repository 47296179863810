import React, { Component } from 'react';
import apiService from '../../Services/apiServices';
import DropDownInput from '../../PageComponents/UI/Inputs/DropDownInput';
import HeaderTools from '../../PageComponents/Header/HeaderTools';
import Pagination from '../../PageComponents/UI/Pagination/Pagination';
import moment from 'moment';
import MapSelectServices from '../../Services/mapSelectServices';
import Search from '../../PageComponents/Search/Search_Old' 
moment().format();

class PurchaseBook extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pageOfItems: [],
      purchaseBook: [],
      fromDate: moment().locale('en').format('YYYY-MM-DD'),
      toDate: moment().locale('en').format('YYYY-MM-DD'),
      companyId: 0,
      purchaseTypeId: 0,
      warehouseId: 0,
    };

    this.onChangePage = this.onChangePage.bind(this);
    this.search = this.search.bind(this);
    this.exportCsv = this.exportCsv.bind(this);
  }

  onChangePage(pageOfItems) {
    this.setState({ pageOfItems: pageOfItems });
  }

  onFieldChange = (e) => {
    this.setState({
      [e.target.name]:
        e.target.type === 'checkbox' ? e.target.checked : e.target.value,
    });
  };
  handleChange = (companyId, purchaseTypeId, warehouseId) => {
    this.setState({ companyId, purchaseTypeId, warehouseId });
  };
  search = () => {
    var fromDate = this.state.fromDate;
    var toDate = this.state.toDate;
    var companyId =
      this.state.companyId.value === undefined ? 0 : this.state.companyId.value;
    var purchaseTypeId =
      this.state.purchaseTypeId.value === undefined
        ? 0
        : this.state.purchaseTypeId.value;
    var warehouseId =
      this.state.warehouseId.value === undefined
        ? 0
        : this.state.warehouseId.value;
    var params = `companyBranch=${companyId}&purchaseType=${purchaseTypeId}&startdate=${fromDate}&enddate=${toDate}&warehouseId=${warehouseId}`;

    apiService()
      .execute('rptPurchaseBookNew', params)
      .then((response) => {
        if (response.success) {
          if(response.data.length > 0)
          this.setState({ purchaseBook: response.data });
        } else {
          console.log('Error:' + response.errorMessage);
        }
      });
  };

  formatNumber(num) {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  }

  formatDate(date) {
    var dateFrom = new Date(date);
    return (
      dateFrom.getDate() +
      '/' +
      (dateFrom.getMonth() + 1) +
      '/' +
      dateFrom.getFullYear()
    );
  }

  exportCsv() {
    let csvContent = 'data:text/csv;charset=utf-8,';
    var data = this.state.itemsHistory;
    let header = 'No,Date,Item Name,Unit,Quantity,Price,Saldo,Total\r\n';
    csvContent += header;
    for (var i = 0; i < data.length; i++) {
      let item = data[i];
      let row =
        item.no +
        ',' +
        this.formatDate(item.date) +
        ',' +
        item.itemName +
        ',' +
        item.unit +
        ',' +
        item.quantity.toFixed(2) +
        ',' +
        item.price.toFixed(4) +
        ',' +
        item.saldo.toFixed(2) +
        ',' +
        item.total.toFixed(2);
      csvContent += row + '\r\n';
    }

    //var encodedUri = encodeURI(csvContent);
    //window.open(encodedUri);

    var encodedUri = encodeURI(csvContent);
    var link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'my_data.csv');
    document.body.appendChild(link); // Required for FF

    link.click(); // This will download the data file named "my_data.csv".
  }

  render() {
    const { pageOfItems, fromDate, toDate, purchaseBook } = this.state;
    const sampleJSON = {
      pageOfItems: { pageOfItems },
    };

    return (
      <div>
        {/* <HeaderTools excel={this.exportCsv} /> */}
        <div className="container-fluid px-2 mt-3">
        <Search excel={this.exportCsv}/>
          <div className="card border-left-primary shadow h-100 py-2 mt-1">
            <div className="card-body py-0">
              <div className="row no-gutters align-items-center">
                <div className="col mr-2 row">
                  <div className="col-lg-2">
                    <label className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                      Prej
                    </label>
                    <input
                      name="fromDate"
                      value={fromDate}
                      className="form-control form-control-sm"
                      onChange={this.onFieldChange}
                      type="date"
                    />
                  </div>
                  <div className="col-lg-2">
                    <label className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                      Deri
                    </label>
                    <input
                      name="toDate"
                      value={toDate}
                      className="form-control form-control-sm"
                      onChange={this.onFieldChange}
                      type="date"
                    />
                  </div>

                  <MapSelectServices
                    queryName="getCompanies"
                    label="Kompania"
                    width="4"
                    onChange={this.handleChange.bind(this)}
                  />
                  <MapSelectServices
                    queryName="getPurchaseTypes"
                    label="Tipi i blerjes"
                    width="2"
                    onChange={this.handleChange.bind(this)}
                  />
                  <MapSelectServices
                    queryName="getWarehouses"
                    label="Depo"
                    width="2"
                    onChange={this.handleChange.bind(this)}
                  />
                </div>

                <div className="col-auto">
                  <a className="searchbutton" onClick={this.search}>
                    <i className="fas fa-search fa-2x text-dark" />
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="card shadow mt-1">
            <div className="card-header py-2">
              <h6 className="m-0 font-weight-bold text-primary">
                Libri i Blerjes
              </h6>
            </div>
            <div className="card-body table-responsive">
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th title="Field #1">Pos</th>
                    <th title="Field #3">InvoiceNo</th>
                    <th title="Field #5">Date</th>
                    <th title="Field #6">CompanyName</th>
                    <th title="Field #7">FiscalNumber</th>
                    <th title="Field #8">VatNumber</th>
                    <th title="Field #9">K31</th>
                    <th title="Field #10">K32</th>
                    <th title="Field #11">K33</th>
                    <th title="Field #12">K34</th>
                    <th title="Field #13">K35</th>
                    <th title="Field #14">K39</th>
                    <th title="Field #15">K43</th>
                    <th title="Field #16">K47</th>
                    <th title="Field #17">K53</th>
                    <th title="Field #18">K57</th>
                    <th title="Field #19">K61</th>
                    <th title="Field #20">K65</th>
                    <th title="Field #21">K1</th>
                    <th title="Field #22">K37</th>
                    <th title="Field #23">K41</th>
                    <th title="Field #24">K45</th>
                    <th title="Field #25">K49</th>
                    <th title="Field #26">K51</th>
                    <th title="Field #27">K55</th>
                    <th title="Field #28">K59</th>
                    <th title="Field #29">K63</th>
                    <th title="Field #30">K2</th>
                    <th title="Field #31">K67</th>
                    <th title="Field #32">GjithesejBlerja</th>
                  </tr>
                </thead>
                {pageOfItems.map((item, key) => (
                  <tbody>
                    <tr key={item.id}>
                      <td> {item.Pos}</td>
                      <td> {item.InvoiceNo}</td>
                      <td> {item.Date}</td>
                      <td> {item.CompanyName}</td>
                      <td> {item.FiscalNumber}</td>
                      <td> {item.VatNumber}</td>
                      <td> {item.K31}</td>
                      <td> {item.K32}</td>
                      <td> {item.K33}</td>
                      <td> {item.K34}</td>
                      <td> {item.K35}</td>
                      <td> {item.K39}</td>
                      <td> {item.K43}</td>
                      <td> {item.K47}</td>
                      <td> {item.K53}</td>
                      <td> {item.K57}</td>
                      <td> {item.K61}</td>
                      <td> {item.K65}</td>
                      <td> {item.K1}</td>
                      <td> {item.K37}</td>
                      <td> {item.K41}</td>
                      <td> {item.K45}</td>
                      <td> {item.K49}</td>
                      <td> {item.K51}</td>
                      <td> {item.K55}</td>
                      <td> {item.K59}</td>
                      <td> {item.K63}</td>
                      <td> {item.K2}</td>
                      <td> {item.K67}</td>
                      <td> {item.GjithesejBlerja}</td>
                    </tr>
                  </tbody>
                ))}
              </table>
              <hr />
              <Pagination
                items={purchaseBook}
                onChangePage={this.onChangePage}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PurchaseBook;
