import React, { Component } from "react";
import HeaderTools from "../../PageComponents/Header/HeaderTools";
import apiService from "../../Services/apiServices";
import helper from "../../Services/helper";
import Pagination from '../../PageComponents/UI/Pagination/Pagination';
import Select , {components} from "react-select";
import "./Sales.css";
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css'
// import Sale from '../../Models/Sale';
import { PDFDownloadLink } from '@react-pdf/renderer';
import MyDocument from '../../PageComponents/PDF/PDF'
import { useToasts } from 'react-toast-notifications';
import Search from "../../PageComponents/Search/Search_Old";
import { MDBInput } from "mdb-react-ui-kit";
import globals from "../../Services/globals";
import DataTable from "../../PageComponents/UI/Tables/DataTable";
import LoaderComponent from "../../PageComponents/UI/Components/LoaderComponent";
import ExcelExport from "../../PageComponents/Excel/ExcelExport";
import DropdownGrid from "../../mycomponents/Inputs/DropdownGrid";

function withToast(Component) {
  return function WrappedComponent(props) {
    const toastFuncs = useToasts()
    return <Component {...props} {...toastFuncs} />;
  }
}

class Sales extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageOfItems: [],
      items: [],
      warehouse: [],
      sales: [],
      salesType:[],
      dataShow: true,
      dateStart: null,
      dateEnd: null,
      dateStartCheck: true,
      dateEndCheck: true,
      warehouseId: 0,
      salesTypeId: props.match.isExact ? null : parseInt((this.props.location.search).substring(1,this.props.location.search.length)),
      find: [],
      userSettings: [],
      loader: false
  };

  this.search = this.search.bind(this);
  this.excelExportRef = React.createRef();
  this.onChangePage = this.onChangePage.bind(this);
  // var offer = new Sale();
  // offer.date = new Date();
  this.selectStyles = {
    control: (provided, state) => ({
      ...provided,
      background: '#fff',
      borderColor: '#9e9e9e',
      minHeight: '28.59px',
      height: '28.59px',
      boxShadow: state.isFocused ? null : null,
    }),

    valueContainer: (provided, state) => ({
      ...provided,
      height: '28.59px',
      padding: '0 6px'
    }),

    input: (provided, state) => ({
      ...provided,
      margin: '0px',
    }),
    indicatorSeparator: state => ({
      display: 'none',
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: '28.59px',
    }),
  };
  
}


 headers = [
  { key: 'id', columnName: helper().translate('ID') },
  { key: 'countNumber', columnName: helper().translate('NR') },
  { key: 'date', columnName: helper().translate('DATE') },
  { key: 'company', columnName: helper().translate('COMPANY')  , type:"text" },
  { key: 'invoiceNo', columnName: helper().translate('INVOICE_NO') },
  { key: 'partnerId', columnName: helper().translate('NR_NUI') } ,
  { key: 'paymentMethod', columnName: helper().translate('PAYMENT_METHOD'), type:"text" },
  { key: 'totalWithoutVat', columnName: helper().translate('TOTAL_WITHOUT_VAT'),  type:'number' },
  { key: 'vatSum', columnName: helper().translate('VAT') , type:"number" },
  { key: 'totalWithVat', columnName: helper().translate('TOTAL_WITH_VAT') , type:'number'  },
  { key: 'comment', columnName: helper().translate('COMMENT') , type:'comment' },
  { key: 'createdAt', columnName: helper().translate('REGISTRATION_DATE')   },
  { key: 'createdBy', columnName: helper().translate('REGISTERED_BY') ,type:"text"  },
  { key: 'changedAt', columnName: helper().translate('DATE_OF_MODIFICATION')   },
  { key: 'changedBy', columnName: helper().translate('MODIFIED_BY'), type:"text"  },
]

edit = (item) => {
  window.location.href = `#/sale/${item.id}` ;
}

componentDidMount() {
  this.setState({loader: true})
  apiService().get('warehouses').then((response) => {
      if (response.success) {
        let data = response.data;
        let firstItem = {id: 0 , name: helper().translate("ALL_WAREHOUSES")};
        data.unshift(firstItem);
        this.setState({ warehouse: data });
      } else {
        console.log('Error:' + response.errorMessage);
      }
    });
    this.getSales(null,this.state.salesTypeId);
    apiService().get('SalesType').then((response) => {
      if (response.success) {
        let data = response.data;
        data.forEach(e => {
          e.name = helper().translate(`${e.name}`)
        });
        let firstItem = {id: null , name: helper().translate("ALL_SALESTYPES") , type: ""};
        data.unshift(firstItem);
        this.setState({ salesType: data });
      } else {
        console.log('Error:' + response.errorMessage);
      }
    });
    this.getSettings();
    this.getUserSettings();
  }

  getSettings = () => {
    globals().getSettings().then((response) => {
      this.setState({
        invoiceForm: response.invoiceForm
      })
  });
  }

  exportToExcel = () => {
    // let table = document.getElementById('table-to-xls-1').innerHTML;
    let fileName = helper().translate("SALES");
    // return helper().exportToExcel(table, fileName)
    this.excelExportRef.current.exportToExcel(fileName)
  }
 
  getUserSettings = () => {
    globals().getUserSettings(this.state.logedInUser).then((response) => {
        this.setState({
            userSettings: response
        })
    });
  }

componentDidUpdate(prevProps) {
  // Typical usage (don't forget to compare props):
  if (parseInt((this.props.location.search).substring(1,this.props.location.search.length)) !== parseInt((prevProps.location.search).substring(1,prevProps.location.search.length))) {
      let saleId = parseInt((this.props.location.search).substring(1,this.props.location.search.length));
      if(this.props.match.isExact != true){
        this.setState({salesTypeId: saleId });
        this.getSales(null,saleId);
      }
  }
}

onChangePage(pageOfItems) {
  this.setState({ pageOfItems: pageOfItems });
}

returnData = () => {
  let find = this.state.find;
  let sales = this.state.sales
  if(typeof(find) == "undefined" || find.length == 0  ){
    return sales
  }else{
    return find
  }
}

handleDelete=(id , no)=>{
  var getRow = this.state.sales.filter((row) => row.countNumber == no);
  
  apiService().query('deleteSales', "id="+id).then((response) => {
    if (response.success) {
      if(getRow[0].paymentMethod.toLowerCase() == "cashbox"){
        apiService().query("getPaymentSales", "saleId="+id).then((response) => {
          if(response.success){
            if(response.data.length != 0){
              let paymentId = response.data[0].paymentId;
              let deletePaymentSales = {paymentId: paymentId , saleId: id}
              apiService().execute('deletePaymentSales', deletePaymentSales , 'post').then((response) => {
                if(response.success){
                console.log("fshirja u be me sukses");
                }})
            }
          }
        })
      }
     this.getSales(this.state.warehouseId,this.state.salesTypeId);
    } else {
      console.log('Error:' + response.errorMessage);
    }
  });
}

alertnOnDelete= (id , no , paymentMethod) => {

  confirmAlert({
    customUI: ({ onClose }) => {
      let stringText = helper().translate("ARE_YOU_SURE");
      let paragraphText = helper().translate("WANT_TO_DELETE_THIS_SALE");
      if(paymentMethod.toLowerCase() == "cashbox"){
        stringText = helper().translate("SALE_HAS_PAYMENT");
        paragraphText = helper().translate("DELETE_SALE_WITH_PAYMENT");
      }
      return (
        <div className="custom-ui" >
          <h1>{stringText}</h1>
          <p>{paragraphText}</p>
          <button
            onClick={() => {
              this.handleDelete(id , no);
              onClose();
            }}
          >
            Po , Fshije!
          </button>
          <button onClick={onClose}>Jo</button>
        </div>
      );
    }
  
  })
    
  };
  


getSales=(warehouseId , salesTypeId) =>{
      // var getDate = new Date();
      // let day = `${getDate.getDate()}`.padStart(2, "0");
      // let month = `${getDate.getMonth()}`.padStart(2, "0");
      // if(getDate.getMonth() == 0){
      //   var dateStart = (((getDate.getFullYear()-1)+"-"+12+"-"+ day))+ ' ' + "00:00:00";
      // }else{
      //   var dateStart;
      //   getDate.setDate(getDate.getDate() - 30);
      //   let dateSt = getDate.toISOString();
      //   dateStart = dateSt.substring(0, 10)+ ' ' + "00:00:00";
      // }
      // let dateEnd =  new Date().toISOString().substring(0,10) + ' ' + "23:59:59";

      let eDate = new Date();
      let sDate = new Date(eDate.getFullYear(),eDate.getMonth(),eDate.getDate()-30); 
      this.setState({ dateEnd : eDate , dateStart: sDate  });
      
      this.getSalesBySearch(sDate , eDate , warehouseId , salesTypeId);
}


onFieldChange = (e) => {
  if(e.target.name == 'dateStart'){
    this.setState({
      [e.target.name]: e.target.value + " 00:00:00"
    });
  }else if(e.target.name == 'dateEnd') {
    this.setState({
      [e.target.name]: e.target.value + " 23:59:59"
    });
  }else if(e.target.name == 'dateStartCheck' || e.target.name == 'dateEndCheck') {
    this.setState({
      [e.target.name]: e.target.checked
    });
  }
  
};

search = () => {
  var getDate = new Date();

  var dateStart = this.state.dateStart;
  var dateEnd = this.state.dateEnd;
  var warehouseId = this.state.warehouseId ;
  var salesTypeId =  this.state.salesTypeId;
  
  
  let strStartDate = helper().formatDate(dateStart,'MM-dd-yyyy');
  let strEndDate = helper().formatDate(dateEnd,'MM-dd-yyyy') + " 23:59:59"
  
  if(!this.state.dateStartCheck) strStartDate = `01-01-${getDate.getFullYear()}`;
  if(!this.state.dateEndCheck) strEndDate = null;
  if(this.state.warehouseId === '') warehouseId = null;
  if(this.state.salesTypeId === '') salesTypeId = null;  
  
  this.getSalesBySearch(strStartDate,strEndDate,warehouseId,salesTypeId);
   
};

getSalesBySearch =(dateStart , dateEnd , warehouseId , salesTypeId) =>{
  this.setState({loader: true});

  let strStartDate = helper().formatDate(dateStart,'MM-dd-yyyy');
  let strEndDate = helper().formatDate(dateEnd,'MM-dd-yyyy') + " 23:59:59"
  var params = `dateStart=${strStartDate}&dateEnd=${strEndDate}&warehouseId=${warehouseId}&salesTypeId=${salesTypeId}`;
  apiService()
  .query('searchSales', params )
    .then((response) => {
      if (response.success) {
        this.setState({loader: false})
        var count = 1;
        var data = response.data;
        data.forEach(e =>{
          e.countNumber = count;
          e.date = helper().formatDate(e.date);
          e.createdAt = helper().formatDate(e.createdAt);
          if(e.changedAt) {
            e.changedAt = helper().formatDate(e.changedAt);
          }
          e.totalWithoutVat = e.totalSum ;
          e.totalWithVat = e.totalSum + e.vatSum;
          e.paymentMethod = helper().translate(e.paymentMethod)
          count++;
        })
        if(data.length > 0){
          this.setState({ sales: data });
          this.setState({dataShow: true});
        }else{
          this.setState({dataShow: false});
        }
      } else {
        console.log('Error:' + response.errorMessage);
      }
    });
}

findChange = (e) => {
  let searchValue = e.target.value;
  if(e.target.value == ''){
    this.getSales(this.state.warehouseId,this.state.salesTypeId);
  }
  this.callFind(searchValue);
  
  
}

callFind = (value) => {
  let searchSales;
  let searchString = value.trim().toLowerCase();

      if (searchString.length > 0) {
          // We are searching. Filter the results.
          //searchItems = searchItems.filter((e) => e.ItemName.toLowerCase().match(searchString));
          searchSales = this.state.sales.filter(item => {
            return (
              item.id?.toString().toLowerCase().match(searchString) || 
              item.company?.toString().toLowerCase().match(searchString) || 
              item.cashBoxId?.toString().toLowerCase().match(searchString) ||
              item.warehouseId?.toString().toLowerCase().match(searchString) ||
              item.invoiceNo?.toString().toLowerCase().match(searchString) ||
              item.partnerId?.toString().toLowerCase().match(searchString) ||
              item.paymentMethod?.toString().toLowerCase().match(searchString) ||
              item.totalSum?.toString().toLowerCase().match(searchString) ||
              item.vatSum?.toString().toLowerCase().match(searchString) ||
              item.currency?.toString().toLowerCase().match(searchString) ||
              item.comment?.toString().toLowerCase().match(searchString) ||
              item.createdBy?.toString().toLowerCase().match(searchString) 
            )
          });
      }
      this.setState({find: searchSales})
}

onSelectChange = (e,obj) => { 
  this.setState({ [obj.name] : e.value} )
}

// selectWarehouse = function(selectdW){
//   console.log(selectdW);
// }

print = (item, invoiceForm) => {
  window.open(`#/print${invoiceForm}/${item.id}`, 'popUpWindow','height=4300,width=4000,left=100,top=100,right=100,bottom=100,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes')
}


  render() {
    const { items,warehouse , salesType , sales , find, invoiceForm , loader } = this.state;

    const warehouseOptions = warehouse.map((item, index) => ({
        index:index + 1,
        value: item.id,
        label: item.name,
        displayName: item.displayName
      }));

      var ddlGrid = {headers:[{name:'index',displayName: 'Nr.'},{name:'label', displayName:'Emri'}], 
      data : warehouseOptions };
   
    const salesOptions = salesType.map((item , index) => ({
      value: item.id,
      label: item.name
    }));

    //let mainForm = this;

    return (
      <div id="Sales" >
        {/* <HeaderTools  new="#/sale/0/Shitje Vendore"/> */}
        <div className="container-fluid px-2 mt-3">
          <Search back="#/" new={this.props.match.isExact ?  "#/sale/0/type?1" : "#/sale/0/type?"+parseInt((this.props.location.search).substring(1,this.props.location.search.length)) } 
          change={this.findChange} excel={() => this.exportToExcel()}/>
          <ExcelExport data={this.returnData()} headers={this.headers} type={'other'} ref={this.excelExportRef} hideContent={true} />
          <div className="card border-left-primary shadow h-100 mt-1">
            <div className="card-body pt-3 pb-1">
              <div className="row">
                <div className="col-md-11 col-12">
                  <div className="row no-gutters align-items-center">
                    <div className="col row">
                      <div className="col-lg-3 mb-3 mb-md-0 pe-0 d-flex align-items-center">
                        <div style={{width: '5%'}}>
                          <input type="checkbox" name="dateStartCheck" checked={this.state.dateStartCheck} onChange={this.onFieldChange} ></input>
                        </div>
                        <div style={{width: '95%'}}>
                          <MDBInput
                            name="dateStart"
                            disabled={!this.state.dateStartCheck}
                            className="form-control-sm active"
                            onChange={(e) =>  this.setState({ dateStart: new Date(e.target.value)})}
                            value={helper().isValidDate(this.state?.dateStart)  ?  this.state?.dateStart?.toISOString().substring(0,10): ''}
                            type="date"
                            label={helper().translate("START_DATE")}
                          />
                        </div>
                      </div>
                      <div className="col-lg-3 mb-3 mb-md-0 pe-0 d-flex align-items-center">
                        <div style={{width: '5%'}}>
                          <input type="checkbox" name="dateEndCheck" checked={this.state.dateEndCheck} onChange={this.onFieldChange} ></input>
                        </div>
                        <div style={{width: '95%'}}>
                          <MDBInput
                            name="dateEnd"
                            disabled={!this.state.dateEndCheck}
                            className="form-control-sm active"
                            onChange={(e) =>  this.setState({ dateEnd: new Date(e.target.value)})}
                            value={helper().isValidDate(this.state?.dateEnd)  ?  this.state.dateEnd?.toISOString().substring(0,10): ''}
                            type="date"
                            label={helper().translate("END_DATE")}
                          />
                        </div>
                      </div>
                      <div className="col-lg-3 mb-3 mb-md-0 pe-0">
                     <DropdownGrid name='warehouseId' placeholder={helper().translate("CHOOSE_WAREHOUSE")} value={this.state.warehouseId} headers={ddlGrid.headers} data={ddlGrid.data} onChange={this.onSelectChange}></DropdownGrid>
                      {/* <Select name="warehouseId" placeholder="Zgjedh Depon" value={warehouseOptions?.find(w => w.value == this.state.warehouseId)}  onChange={this.onSelectChange} options={warehouseOptions} styles={this.selectStyles}  /> */}
                      </div>
                      <div className="col-lg-3 mb-3 mb-md-0 pe-0">
                      <Select name="salesTypeId" placeholder={helper().translate("CHOOSE_SALE_TYPE")}  value={salesOptions?.find(s => s.value == this.state.salesTypeId)} onChange={this.onSelectChange} options={salesOptions} styles={this.selectStyles} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-1 col-12 d-flex align-items-center justify-content-center  justify-content-lg-end">
                  <div>
                    <a className="searchbutton" onClick={this.search}>
                      <i className="fas fa-search fa-2x text-dark"  />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card shadow mt-1">
            <div className="card-header py-2">
              <h6 className="m-0 font-weight-bold text-primary">{helper().translate("INVOICES")}</h6>
            </div>
            <div style={{minHeight: "500px"}} className="card-body table-responsive py-0 px-1">
            {loader && <LoaderComponent /> } 
            {!loader ? this.state.dataShow ? <DataTable data={this.returnData()} showEdit={true} headers={this.headers} headersFrom={"SALES"} print={this.print} edit={this.edit} delete={this.alertnOnDelete} formName="Sales"/> : <div className="p-5 text-center">{helper().translate("NO_DATA")}</div> : ''}      
            {/* {this.state.dataShow ? <DataTable data={this.returnData()} headers={this.headers} print={this.print} edit={this.edit} delete={this.alertnOnDelete} formName="Sales"/> : <div><p>{helper().translate("NO_DATA")}</p></div>}  */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withToast(Sales);
