import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from "react-dom";
import { BrowserRouter as Router, useLocation, useHistory } from "react-router-dom";
import { ToastProvider, useToasts } from 'react-toast-notifications';
import HeaderTools from '../../Header/HeaderTools';
import apiService from '../../../Services/apiServices';
import { confirmAlert } from 'react-confirm-alert'; 
import DataTable from '../Tables/DataTable';
import { MDBInput } from 'mdb-react-ui-kit';
import Select from 'react-select';
import globals from '../../../Services/globals';
import Modal from "react-bootstrap/Modal";
import Search from '../../Search/Search_Old';
import Switch from "react-switch";
import helper from '../../../Services/helper';
import { reference } from '@popperjs/core';
  

const AdministrationForm = props => {
    let location = useLocation();
    const history = useHistory()

    const [referenceData, setReferenceData] = useState([]);
    const [addReference, setAddReference] = useState(false);
    const { addToast } = useToasts();
    const [errors, setErrors ] = useState();
    const [referenceObj, setReferenceObj] = useState(props.model)

    const [search, setSearch] = useState();

    const [show, setShow] = useState(false);
    const [refDataName, setRefDataName] = useState('');

    const modalClose = () => {
        setShow(false);
        setAddReference(false)
        // setNewClick(0)
    }
    const modalShow = () => setShow(true);

    const [newClick, setNewClick] = useState(0)

    const headers = props.headers;
    const table = props.table;
    const selectQuery = props.selectQuery;

    const showAddReference = () => {
        setAddReference(!addReference)
    }

    const getReferenceData = async (table) => {
        apiService()
        .get(selectQuery ? selectQuery : table)
            .then((response) => {
                if (response.success) {
                    setReferenceData(response.data)
                } else {
                    console.log('Error:' + response.errorMessage);
                }
            });
    }

    const getType = (pathname) => {
        let type;
        if(pathname === 'categories') type = 'category';
        if(pathname === 'units') type = 'unit';
        setReferenceObj({...referenceObj, type: type})
    }

    const addNewReference = () => {
        setAddReference(true);
        setReferenceObj(props.model)
        setShow(true);
        setErrors('');
        if(addReference){
            getFocus();
        }
    }   

    const editReference = (item) => {
        headers.forEach(header => {
            header.backData ? props.backData(header.options ? header.options?.find(v => v.value == item?.[header.name]) : '') : ''
        });
        
        setAddReference(true);
        setReferenceObj(item);
        setShow(true);
        //setReferenceObj({...referenceObj, id: item.id, name: item.name})
        if(addReference){
            getFocus();
        }
    }

    const getFocus = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        document.getElementById("elemFirst")?.focus();
    }

    const selectStyles = {
        control: (provided, state) => ({
          ...provided,
          background: '#fff',
          borderColor: '#9e9e9e',
          minHeight: '28.59px',
          height: '28.59px',
          boxShadow: state.isFocused ? null : null,
        }),
    
        valueContainer: (provided, state) => ({
          ...provided,
          height: '28.59px',
          padding: '0 6px'
        }),
    
        input: (provided, state) => ({
          ...provided,
          margin: '0px',
        }),
        indicatorSeparator: state => ({
          display: 'none',
        }),
        indicatorsContainer: (provided, state) => ({
          ...provided,
          height: '28.59px',
        }),
      };

    const generateForm = () => {
        return headers?.map((header, index) => {
            if(header.type === 'input') return <div className={props.formType === 'modal' ? (index != 0) ? "mt-3" : "" : 'mr-3'}><MDBInput id={index === 0 ? 'elemFirst' : `elem${header.name}`} type="text" className="form-control-sm" label={header.columnName} value={referenceObj?.[header.name]} onChange={(e) => setReferenceObj({...referenceObj, [header.name]:e.target.value})} name={header.name} />
            {errors?.[header.name] != '' && <span className='error'>{errors?.[header.name]}</span>}
            </div>
            if(header.type === 'select') return <div className={[index !== 0 ? "myClassName mt-3" : "myClassName", props.formType !== 'modal' ? 'myClassName mr-3' : 'myClassName mt-3' ]} style={{minWidth:"200px"}}><Select id={index === 0 ? 'elemFirst' : `elem${header.name.charAt(0).toUpperCase() + header.name.slice(1)}`} className="" placeholder={'Select ' + header.columnName} autosize={true} options={header.options} value={header.options ? header.options?.filter(v => v.value == referenceObj?.[header.name]) : {value:0, label: ''}} onChange={(e) => { setReferenceObj({...referenceObj, [header.name]:e.value, [header.subItem]: '', warehouseName: ''}); header.backData ? props.backData(e) : '';}} name={header.name} styles={selectStyles}/>
            {errors?.[header.name] != '' && <span className='error'>{errors?.[header.name]}</span>}
            </div>

            if(header.type === 'switch') 
                return <div className={[index !== 0 ? "myClassName mt-3" : "myClassName", props.formType !== 'modal' ? 'myClassName mr-3' : 'myClassName mt-3' ]} style={{minWidth:"200px"}}>
                    <Switch
                        name={header.name} 
                        onColor="#86d3ff"
                        onHandleColor="#2693e6"
                        handleDiameter={25}
                        width={55}
                        height={20}
                        uncheckedIcon={<span style={{ display: 'flex', margin: '-4px 0 0 -5px', color: '#fff' }}>{helper().translate('NO')}</span>}
                        checkedIcon={<span style={{ display: 'flex', margin: '-4px 0 0 -5px', color: '#fff' }}>{helper().translate('YES')}</span>}
                        padding="7px 0px"
                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                        checked={referenceObj?.[header.name]} 
                        onChange={(e) => { header.onlyTrue ? e ? setReferenceObj({...referenceObj, [header.name]: e}) : 0 : setReferenceObj({...referenceObj, [header.name]: e }); }}
                    />
                {errors?.[header.name] != '' && <span className='error'>{errors?.[header.name]}</span>}
            </div>
        });
    }

    const renderForm = () => {
        //var headers = getFields();
        if(props.formType !== "modal") {
            return generateForm();
        }
        else {
            return <Modal 
                show={show} 
                onHide={modalClose}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton className="no-shadow btn-header px-3 py-0">
                    {/* <Modal.Title>{ referenceObj.id != 0 ? 'Edit' : 'Add new'}</Modal.Title> */}
                    <HeaderTools save={() => save()} />
                </Modal.Header>
                <Modal.Body closeButton className="px-3">
                    
                     {generateForm()}
                </Modal.Body>
            </Modal>
        }
    }

    const validate = async () => {
        let errorsArr = []
        await headers.map((header, index) => {
            if(header.required){
                if(!referenceObj?.[header.name]) {
                    errorsArr[header.name] = 'Cannot be blank'
                    
                }
            } 
        });

        setErrors(errorsArr)
        return returnValidate(errorsArr);
    }

    const returnValidate = (errors) => {
        if(Object.values(errors).every(x => x === null || x === '')) { 
            return true
        }
        else {
            return false
        }
    } 

    const save = () => { 
        referenceObj.createdBy = globals().getLogedInUser().data.username;
        referenceObj.id !== 0 ? referenceObj.changedBy = globals().getLogedInUser().data.username : referenceObj.changedBy = "";
        referenceObj.id !== 0 ? referenceObj.changedAt = new Date() : referenceObj.changedAt = "";
        validate().then(function(result) {
            if(result){
                if(referenceObj.id !== 0){
                    apiService().put(table, referenceObj)
                        .then((response)=>{ 
                            if(table === 'projects') {
                                if(response.success) {
                                    apiService().put('updateDefaultProject', {id: referenceObj.id, warehouseId: referenceObj.warehouseId})
                                        .then((response) => {
                                            responseMessage(response, 'Updated Successfully')
                                        })
                                }
                                else {
                                    responseMessage(response, 'Updated Successfully')
                                }
                            }
                            else {
                                responseMessage(response, 'Updated Successfully')
                            }
                        });
                }
                else {
                    apiService().post(table, referenceObj)
                        .then((response)=>{ responseMessage(response, 'Saved Successfully')});
                }
            }
        });
    }

    const remove = (id) => {
        confirmAlert({
            customUI: ({ onClose }) => {
              return (
                <div className="custom-ui" >
                  <h1>A jeni te sigurt?</h1>
                  <p>Dëshironi të fshini këtë artikull?</p>
                  <button
                    onClick={() => {
                        apiService().delete(table, id)
                            .then((response)=>{responseMessage(response, 'Delete Successfully')});
                        onClose();
                    }}
                  >
                    Po , Fshije!
                  </button>
                  <button onClick={onClose}>Jo</button>
                </div>
              );
            }
        })   
    }

    const responseMessage = (response, message) => {
        if(response.success){
            getReferenceData(table);
            addToast( message, { appearance: 'success', autoDismiss: true, });
            if(show) modalClose()
        }
        else{
            addToast('Ka ndodhur nje gabim!', { appearance: 'error', autoDismiss: true, });
        }
    }

    const escFunction = (event) => {
        if(event.keyCode === 27) {
            setAddReference(false)
        }
    }

    const searchResult = () => {
        referenceData?.forEach(x => {
            if(x.isDefault === false) { x.isDefault = 0}
            if(x.isDefault === true) { x.isDefault = 1}
        })
        let searchItems = referenceData,
        searchString = search?.trim().toLowerCase();

        if (searchString?.length > 0) {
                searchItems = searchItems.filter(item => {
                return (                   
                    item?.name.toString().toLowerCase().match(searchString)                    
                )
            });
            return searchItems
        }
        else {
            return searchItems
        }
    }

    useEffect(() => {
        document.addEventListener("keydown", escFunction, false);

        let refdn = (props.name)? props.name: '';
        setRefDataName(refdn);

            return () => {
                document.removeEventListener("keydown", escFunction, false);
                setNewClick(0)
            }
    }, [])

    useEffect(() => {
        getReferenceData(table);
        getType(table);
    }, [location])

    useEffect(() => {
        if(addReference){
            getFocus();
        }
    }, [addReference]);

    useEffect(() => {
        if (newClick) {
            addNewReference()
        } else {
            setNewClick(props.new+1)
        }
    }, [props.new]);

    return (
        <>
            <div>                
                <div className="container-fluid px-2 mt-3">              
                    <Search newHandle={() => addNewReference()} save={props.formType != 'modal' && addReference ? () => save() : ''} change={(e) => setSearch(e.target.value)}/>
                    {addReference &&
                    <div className="card border-left-primary shadow h-100 mt-1 py-2">
                        <div className="card-body pt-0 pb-1">
                            <label className="text-xs font-weight-bold text-primary text-uppercase mb-2">{referenceObj.id != 0 ? helper().translate('EDIT') + ' ' +refDataName : helper().translate('ADD') + ' ' +refDataName }</label>
                            <div className="d-flex align-items-center">
                                {renderForm()}
                            </div>
                        </div>
                    </div>
                    }
                    <div className="card shadow mt-1 mb-3">                       
                        <DataTable data={searchResult()} headers={headers} edit={editReference} delete={remove} />
                    </div>
                </div>
            </div>
        </>
    )
}

export default AdministrationForm
