import React, { useState, useEffect, useRef } from 'react';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import SubNav from '../../PageComponents/Nav/SubNav'
import Sales from './Sales'
import Sale from './SRegsiter/Sale';
import helper from '../../Services/helper';

const SalesMenus = (props) => {
    const links = [
        // {path:"sales", name:"Lista e Faturave", icon:"fas fa-file-invoice-dollar mr-2", component: Sales, type:"menu"},
        {path:"sales", name:"Faturë shitje", icon:"fas fa-money-bill mr-2", component: Sales, type:"link"},
        // {path:"sale/0/type?1", name:"Faturë shitje", icon:"fas fa-money-bill mr-2", component: Sale, type:"menu"},
        // {path:"sale/0/type?6", name:"Fletdergesat", icon:"fas fa-fw fa-user mr-2", component: Sale, type:"menu"},
        // {path:"sale/0/type?7", name:"Oferta", icon:"fas fa-comments-dollar mr-2", component: Sale, type:"menu"},
        {path:"sale/:id", name:"EditSale", icon:"fas fa-comments-dollar mr-2", component: Sale, type:"link"},
        {path:"localSales", name:helper().translate("LOCAL_SALES"), icon:"fas fa-fw fa-layer-group mr-2", component: '', subMenu:[
            {path:"sales/type?1", name:helper().translate("LOCAL_SALE"), icon:"fas fa-money-bill mr-2", component: Sales, type:"menu"},
            {path:"sales/type?8", name:helper().translate("INVESTMENT_SALES"), icon:"fas fa-money-bill mr-2", component: Sales, type:"menu"},
            {path:"sales/type?4", name:helper().translate("RETURN_OF_SALES"), icon:"fas fa-money-bill mr-2", component: Sales, type:"menu"},
            {path:"sales/type?10", name:helper().translate("PRO-FORMA_INVOICE"), icon:"fas fa-money-bill mr-2", component: Sales, type:"menu"},
            {path:"sales/type?7", name:helper().translate("OFFERS"), icon:"fas fa-comments-dollar mr-2", component: Sales, type:"menu"},
        ], type:"menu"},
        {path: "export", name:helper().translate("EXPORT_SALES"), icon:"fas fa-fw fa-layer-group mr-2", component: '', subMenu:[
            {path:"sales/type?2", name:helper().translate("EXPORT_SALE"), icon:"fas fa-money-bill mr-2", component: Sales, type:"menu"},
            {path:"sales/type?9", name:helper().translate("INVESTMENT_SALES") +" Ex", icon:"fas fa-money-bill mr-2", component: Sales, type:"menu"},
            {path:"sales/type?11", name:helper().translate("PRO-FORMA_INVOICE") +" Ex", icon:"fas fa-money-bill mr-2", component: Sales, type:"menu"},
            {path:"sales/type?12", name:helper().translate("OFFERS") +" Ex", icon:"fas fa-comments-dollar mr-2", component: Sales, type:"menu"},
        ], type: 'menu'},
        {path: "otherSales", name:helper().translate("OTHER_SALES"), icon:"fas fa-fw fa-layer-group mr-2", component: '', subMenu:[
            {path:"sales/type?5", name:helper().translate("REVERSE_LOAD"), icon:"fas fa-money-bill mr-2", component: Sales, type:"menu"},
            {path:"sales/type?6", name:helper().translate("CONSIGNMENT_NOTE"), icon:"fas fa-fw fa-user mr-2", component: Sales, type:"menu"},
            {path:"sales/type?3", name:helper().translate("NOT_DEBIT"), icon:"fas fa-money-bill mr-2", component: Sales, type:"menu"},
            
        ], type: 'menu'}
    ]
    return (
        <div className="d-flex">
            <div className="p-0 position-relative sub-menu">
                <SubNav links={links} />
            </div>
            <div className="w-100 overflow-auto overflow-auto pr-0">
                {props.location.pathname === '/administration' && <AddUser />}
                <Router>
                    <Switch>
                        {links.map((link)=>{
                            return <Route path={`/${link.path}`} component={link.component} />
                        })}
                    </Switch>
                </Router>
            </div>
        </div>
    )
}

export default SalesMenus
