import React, { useEffect, useState, useRef } from 'react'
import apiService from '../../../Services/apiServices';
import DataTable from '../../../PageComponents/UI/Tables/DataTable';
import helper from '../../../Services/helper';
import Print from '../../../PageComponents/Print/Print';
import Search from '../../../PageComponents/Search/Search_Old';
import Select from "react-select"
import { MDBInput } from 'mdb-react-ui-kit';
import ExcelExport from '../../../PageComponents/Excel/ExcelExport';
import LoaderComponent from '../../../PageComponents/UI/Components/LoaderComponent';

const ItemProfitabilityList = () => {
    const selectStyles = {
        control: (provided, state) => ({
            ...provided,
            background: '#fff',
            borderColor: '#9e9e9e',
            minHeight: '28.59px',
            height: '28.59px',
            boxShadow: state.isFocused ? null : null,
        }),

        valueContainer: (provided, state) => ({
            ...provided,
            height: '28.59px',
            padding: '0 6px'
        }),

        input: (provided, state) => ({
            ...provided,
            margin: '0px',
        }),
        indicatorSeparator: state => ({
            display: 'none',
        }),
        indicatorsContainer: (provided, state) => ({
            ...provided,
            height: '28.59px',
        }),
    };

    const [items, setItems] = useState();
    const [warehouses, setWarehouses] = useState([])
    const searchObj = { dateEnd: '01/01/1900', warehouseId: 0}
    const [searchQuery, setSearchQuery] = useState(searchObj)
    const [loader , setLoader] = useState(false);

    const excelExportRef = useRef();
    const printRef = useRef();

    const headers = [
        { key: 'nr', columnName: helper().translate("NR"),  dontShowMenu : true  },
        { key: 'productNo', columnName: helper().translate("PRODUCT_NUMBER") },
        { key: 'barcode', columnName: helper().translate("BARCODE") },
        { key: 'itemName', columnName: helper().translate("ITEMNAME") , type:"text" },
        { key: 'quantity', columnName: helper().translate("ACTUAL_QUANTITY") , type:"number" },
        { key: 'unit', columnName: helper().translate("UNIT") },
        { key: 'sales', columnName: helper().translate("SALES"), calculate: true , type:"number" },
        { key: 'retailPrice', columnName: helper().translate("PRICE") , type:"number"},
        { key: 'avgPrice', columnName: helper().translate("AVG_PRICE"), calculate: true , type:"number"},
        //{ key: 'retailPrice', columnName: helper().translate("PRICE_WITHOUT_VAT")},
       // { key: 'withVatPrice', columnName: helper().translate("PRICE_WITH_VAT")},
        { key: 'total', columnName: helper().translate("TOTAL") , calculate: true, type:"number" }
      ]; 
    
    const checkColumn = (header, items) => {
        if(header && header.subHeader){
            //const isTrue = (currentValue) => parseFloat(currentValue[header.key]) != 0;
            const isTrue = (currentValue) => !currentValue[header.key] || currentValue[header.key] == 0;
            if(!items?.every(isTrue)) {
                return header
            }
        }
        else{
            return header
        }
    }

    const getItems =() => {
        setLoader(true)
        var params = `dateEnd=${searchQuery.dateEnd}&warehouseId=${searchQuery.warehouseId}`;
            apiService().query('RptItemProfitabilityList', params )
                .then(response => {
                    if(response.success) {
                        setLoader(false)
                        setItems(response.data)
                    }
                    else {
                        console.error(response)
                    }
                })
    }

    const getWarehouses = (name, method) => {
        apiService().get('warehouses').then((response) => {
            if (response.success) {
                let records = response.data
                records = records.map(record => ({ value: record.id, label: record.name }))
                setWarehouses(records)
            }
        })
    }

    const print = () => {
        printRef.current.printPreview()
    }

    const exportToExcel = () => {
        let fileName = helper().translate("ARTICLES_BY_PROFITABILITY");
        excelExportRef.current.exportToExcel(fileName)
    }

    useEffect(() => {
      getItems()
      getWarehouses()
    }, [])
    
    
    return (
        <>
        <Print data={items?.length ? items : []} headers={headers.filter(header => header !== false)} query={searchQuery} ref={printRef} reportName={helper().translate("ARTICLES_BY_PROFITABILITY")} formName="ItemProfitabilityList"/>
        <div className="container-fluid px-2 mt-3">
            <Search search={false} excel={() => exportToExcel()} printClick={print} back="#/reports"/>
            <div className="card border-left-primary shadow h-100 mt-1">
                <div className="card-body pt-3 pb-1">
                    <div className="row">
                        <div className="col-md-12 col-12">
                            <div className="row no-gutters align-items-center">
                                <div className="col mr-2 row">
                                    <div className='w-100 d-md-flex d-lg-flex' >
                                        <div className="col-lg-3 mb-3 mb-md-0 pe-0 d-flex align-items-center">
                                            <div style={{ width: '95%' }}>
                                                <MDBInput
                                                    name="dateEnd"
                                                    className="form-control-sm active"
                                                    onChange={(e) => setSearchQuery({ ...searchQuery, dateEnd: e.target.value })}
                                                    type="date"
                                                    label={helper().translate("START_DATE")}
                                                    value={searchQuery.dateEnd}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-lg-3 mb-3 mb-md-0 pe-0">
                                            <Select styles={selectStyles} placeholder={helper().translate("WAREHOUSE")} value={warehouses?.find(w => w.value === searchQuery.warehouseId)} options={warehouses} onChange={(e) => { setSearchQuery({ ...searchQuery, warehouseId: e.value }) }} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-auto">
                                    <a className="searchbutton" onClick={() => getItems()}>
                                        <i className="fas fa-search fa-2x text-dark"  />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card shadow mt-1">
                <div className="card-header py-2">
                    <h6 className="m-0 font-weight-bold text-primary text-center">{helper().translate("ARTICLES_BY_PROFITABILITY")}</h6>
                </div>
                {loader && <LoaderComponent /> } 
                {!loader ? items?.length ? <DataTable data={items?.length ? items : []} headers={headers?.filter(header => checkColumn(header, items))} formName="ItemProfitabilityList" /> : <div className="p-5 text-center">{helper().translate("NO_DATA")}</div>:"" }
                <ExcelExport data={items?.length ? items : []} headers={headers} type={'other'} ref={excelExportRef} hideContent={true} />
            </div>
            </div>
        </>
    )
}

export default ItemProfitabilityList