import React, { useEffect, useMemo, useState } from 'react';
import { HashRouter as Router, useLocation, Route, Switch, Redirect } from 'react-router-dom';
import { ToastProvider } from 'react-toast-notifications';
import Body from './PageComponents/Body/Body';
import Auth from './Auth/Auth';
import globals from './Services/globals';
import Databases from './Databases/Databases';
import apiService from "./Services/apiServices";
import helper from "./Services/helper";
import loginService from './Services/loginService';
import config from './config/config';


export default function App() {

    const [user, setUser] = useState();
    const [company, setCompany] = useState("");

    const setUserCompany = (user) => {
        //save login infos on cookie if user clicked rememberMe for 4 days 
        var jsValue = JSON.stringify(user);
        let rememberMe = helper().getItem('rememberMe');
        let exdays = (rememberMe) ? 4 : 0;
        helper().setItem("logedInUser", jsValue, exdays);
        window.location = config.homepage;
        // if (user.company) {
        //     console.log(user.company);
        //     globals().getSettings();
        //     globals().getUserSettings(user, true).then(() => {
        //         window.location = config.homepage;
        //     });
        // }
    }

    useEffect(() => {
        //debugger
        var user = globals().getLogedInUser();

        if (user) {
            setUser(user);
            if (user.company) {
                setCompany(user.company);
                globals().getSettings();
                globals().getUserSettings(user);
            }
        }
        else {
            //debugger
            var authCode = helper().getParameterByName('authcode');
            var rememberMe = helper().getParameterByName('rememberme');

            if (rememberMe) {
                helper().setItem('rememberMe', rememberMe);
            }

            if (authCode) {
                loginService().useAuthCode(authCode).then(function (user) {
                    if (user.success) {
                        apiService().getDatabases("getUserDatabases?username=" + user.data.username).then((response) => {
                            if (response.success) {
                                if (response.data.length == 1) {
                                    user.company = response.data[0].Name;
                                    user.subscription = response.data[0].Id;
                                    setUserCompany(user);
                                }
                                else {
                                    setUserCompany(user);
                                }
                            }
                        });
                    }
                    else {
                        alert(response.errorMessage);
                    }
                });
            } else {
                var currentLocation = window.location;
                window.location = config.loginUrl + '?redirectUrl=' + currentLocation;
            }
        }
    }, []);
    return (
        <Router>
            <Switch>
                <ToastProvider>
                    {/* {user ? <Route path="/" component={Body} /> : null} */}
                    {user && company ? <Route path="/" component={Body} /> : null}
                    {user && !company ? <Route path="/" component={Databases} /> : null}
                </ToastProvider>
            </Switch>
        </Router>
    )
}



