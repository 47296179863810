import globals from "../Services/globals";
class PaymentModel {
    constructor(obj){
        var user = globals().getLogedInUser().data
        
        if(obj){ 
            this.id = obj.id;
            this.date = obj.date;
            this.description = obj.description;
            this.partnerId = obj.partnerId
            this.paymentType = obj.paymentType
            this.warehouseId = obj.warehouseId
            this.userId = obj.userId ? obj.userId : user.id
            this.bankId = obj.bankId
            this.cashBoxId = obj.cashBoxId
            this.projectId = obj.projectId
            this.accountId = obj.accountId
            this.debit = parseFloat(obj.debit)
            this.credit = parseFloat(obj.credit)
            this.saldo = parseFloat(obj.saldo)
            this.createdAt = obj.createdAt ? obj.createdAt : new Date()
            this.createdBy = obj.createdBy ? obj.createdBy : user.name +" "+ user.surname
            this.changedAt = new Date() //obj.changedAt 
            this.changedBy = user.name +" "+ user.surname //obj.changedBy
            this.status = obj.status
         }
        else {
            this.id = 0
            this.date = new Date()
            this.description = ""
            this.partnerId = 0 
            this.paymentType = 0
            this.warehouseId = 0
            this.userId = user.id
            this.bankId = 0
            this.cashBoxId = 0
            this.projectId = 0
            this.accountId = 0
            this.debit = 0.0
            this.credit =  0.0
            this.saldo = 0.0
            this.createdAt = new Date
            this.createdBy = user.name +" "+ user.surname
            this.changedAt = new Date
            this.changedBy = user.name +" "+ user.surname
            this.status = 0
        }
    }
}

export default PaymentModel