import React, { useState, useEffect, useRef } from 'react';

const DropdownGrid = (props) => {

    const [show, setShow] =  useState(false);
    const [searchName, setSearchName] = useState('');

    const valuesMember ='value';
    const displayMember = 'label';
    const [data , setData] = useState();

    useEffect(() => {
        if(props.valuesMember){
            valuesMember = props.valuesMember;
        }

        if(props.displayMember){
            displayMember = props.displayMember;
        }
    }, []);

    useEffect(() => {
       if(props.value){
            selectRow(props.value);
        }
    }, [props.value]);

    useEffect(() => {
        if(props.data && props.data.length>0){
            setData(props.data);
            selectRow(props.value);
        }
    }, [props.data]);

    const selectRow = function(value, callEvent){
        
        if(data && data.length> 0){

            let row = null;
            let rows = null;

            rows = data.filter(r=>r[valuesMember] === value);

            if(rows && rows.length > 0){
                row = rows[0];

                setSearchName(row[displayMember]);
                //if defined event handler onClick then call
                if(props.onChange && callEvent){
                    var e = {value: row.value, label: row.label};
                    var obj = {name:props.name};
                    props.onChange(e, obj);
                }
        
                setShow(false);

            }
        }
    }

    const searchChange = function(e){
        setSearchName(e.target.value);
        var filteredData = props.data.filter(function(d){
            var strSearchHeaderName = props.headers[0].name;
            let strValue = d[strSearchHeaderName].toLowerCase();
            if(strValue){
                return strValue.includes(e.target.value.toLowerCase());
            }
            else{
                return false;
            }
        });
        setData(filteredData);
    }

    const renderRows = function (rows) {   
        let gridStyles = {'position':'absolute', zIndex:100, backgroundColor:'#fff', width:"200px"};
        if(props.gridStyles){
            for(var prop in  props.gridStyles){
                gridStyles[prop] = props.gridStyles[prop];
            }
        }

        if(props.data && props.headers){
            return <table style={gridStyles} className='table table-hover text-center'>
            <thead>
            <tr>{props.headers.map(h=> <th>{h.displayName}</th>)}</tr>
            </thead>
            <tbody>
            {data && data.map(d=><tr onClick={()=>{selectRow(d[valuesMember],true)}}>{props.headers.map(h=><td>{d[h.name]}</td>)}</tr>)}            
            </tbody>
        </table>
        }
        else{
            return <div style={{position:'absolute', backgroundColor:'#fff'}}>Empty!</div>
        }
        
    }

    return (
        <div class="form-outline" style={{position:'relative'}}>
            <input className="form-control active form-control-sm active"  onClick={()=> setShow(!show)} value={searchName}
                onChange={searchChange}
            />
            {show && renderRows(props.data)}
            <label className="form-label">{props.placeholder}</label>
            <div className="form-notch">
                <div className="form-notch-leading"></div>
                <div className="form-notch-middle" style={{width: 70.2  + 'px'}}></div>
                <div className="form-notch-trailing"></div>
            </div>
        </div>
    )
}


export default DropdownGrid;