class Partner {
    constructor(obj){
        if(obj){
            this.address = obj.address ? obj.address : '';
            this.businessNo = obj.businessNo ? obj.businessNo : '';
            this.city = obj.city ? obj.city : 0;
            this.comment = obj.comment ? obj.comment : '';
            this.companyName = obj.companyName ? obj.companyName : '';
            this.country = obj.country ? obj.country : 0;
            this.createdBy = obj.createdBy ? obj.createdBy : '';
            this.customer = obj.customer ? obj.customer : false;
            this.discount = obj.discount ? obj.discount : 0.0;
            this.fiscalNo = obj.fiscalNo ? obj.fiscalNo : '';
            this.id = obj.id ? obj.id : 0;
            this.importOrLocal = obj.importOrLocal ? obj.importOrLocal : 0;
            this.mobilePhone = obj.mobilePhone ? obj.mobilePhone : '';
            this.name = obj.name ? obj.name : '';
            this.partnerOrigin = obj.partnerOrigin ? obj.partnerOrigin : 0;
            this.partnerType = obj.partnerType ? obj.partnerType : 0;
            this.paymentTypeId = obj.paymentTypeId ? obj.paymentTypeId : 0;
            this.phone = obj.phone ? obj.phone : '';
            this.saveAs = obj.companyName ? obj.companyName : '';
            this.sid = obj.sid ? obj.sid : 0;
            this.status = obj.status ? obj.status : true;
            this.supplier = obj.supplier ? obj.supplier : false;
            this.surname = obj.surname ? obj.surname : '';
            this.vatNo = obj.vatNo ? obj.vatNo : '';
            this.unitNumber = obj.unitNumber ? obj.unitNumber : '';
            this.partnerBranchId = obj.partnerBranchId ? obj.partnerBranchId : 0;
            this.parentId = obj.parentId ? obj.parentId : 0;
            this.termsOfPaymentsId = obj.termsOfPaymentsId ? obj.termsOfPaymentsId : 0;
        }
        else {
            this.address =  '';
            this.businessNo =  '';
            this.city =  0;
            this.comment =  '';
            this.companyName =  '';
            this. country =  0;
            this.createdBy =  'admin';
            this.customer =  false;
            this.discount =  0.0;
            this.fiscalNo =  '';
            this.id =  0;
            this.importOrLocal =  0;
            this.mobilePhone =  '';
            this.name =  '';
            this.partnerOrigin =  0;
            this.partnerType =  0;
            this.paymentTypeId =  0;
            this.phone =  '';
            this.saveAs =  '';
            this.sid =  0;
            this.status =  true;
            this.supplier =  false;
            this.surname =  '';
            this.vatNo =  '';
            this.unitNumber = null;
            this.partnerBranchId = null;
            this.parentId = 0;
            this.termsOfPaymentsId = 0;
        }
    }
}
// (accountA, accountB, accountC, akciza, assembled, barcode, categoryId, changedBy, createdBy, description, devaluation, duty, expense, expired, id, inventoryItem, itemGoods, itemName, material, producerId, productNo, production, retailPrice, service,sid, status. unitId, vat)

export default Partner







  // var offer = new Sale();
  // offer.date = new Date();
  
  // createSaleFromOffer(offer){
//   var sale = new Sale(offer);
//   sale.sateType = 1;

//   var savedSale = await apiService.post('sales',sale);
//