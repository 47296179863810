import React, { useEffect, useState } from 'react'
import AdministrationForm from '../../PageComponents/UI/AdministrationForm/AdministrationForm'
import apiService from '../../Services/apiServices'
import mdlBank from '../../Models/Bank.js'

const Bank = () => {
    const [accounts, setAccounts] = useState()

    const bankObj = {id: 0, name: "", sId: null, account: "", type: "", orderNo: null, accountId: 0, createdBy: "", changedAt:"", changedBy:"", status: 0}

    const bankTypes = [
        {value: 'B', label: 'Bank'},
        {value: 'P', label: 'Pos'}
    ]

    const getAccounts = () => {
        apiService().get('accounts')
            .then((response)=> {
                if(response.success) {
                    let records = response.data.map(record => ({ value: record.id, label: record.name }));
                    setAccounts(records)
                }
            })
    } 

    const headers = [
        { key: 'NAME', name: 'name', columnName:'name', type: 'input', required: true },
        { key: 'ACCOUNT_NUMBER', name: 'account', columnName:'account', type: 'input', required: false },
        { key: 'TYPE', name: 'type', columnName:'type', type: 'select', options: bankTypes, required: false },
        { key: 'ACCOUNT', name: 'accountId', columnName:'accountName', type: 'select', options: accounts, required: false }
    ]

    useEffect(() => {
        getAccounts();
    }, [])

    return (
        <div>
            <AdministrationForm headers={headers} table="banks" model={new mdlBank()} />
        </div>
    )
}

export default Bank
