class User {
    constructor(obj){
        if(obj){
            this.id = obj.id;
            this.userName = obj.userName;
            this.password = obj.password;
            this.email= obj.email;
            this.name= obj.name;
            this.surname= obj.surname;
            this.cashboxId= obj.cashboxId;
            this.warehouseId= obj.warehouseId;
            this.dateAdded= obj.dateAdded;
            this.roleId= obj.roleId;
            this.phone= obj.phone;
            this.branch= obj.branch;
        }
        else {
            this.id = 0;
            this.userName = "";
            this.password = "";
            this.email= "";
            this.name= "";
            this.surname= "";
            this.cashboxId= 0;
            this.warehouseId= 0;
            this.dateAdded= new Date();
            this.roleId= 2;
            this.phone= "";
            this.branch= "";
        }
    }
}

export default User;