import axios from "axios";
import config from "../config/config";
import globals from "./globals";

export default function apiService() {
  var myservices = "";
  var baseUrl = "";

  function addAuthorizationHeader(dbName) {
    var currentUser = globals().getLogedInUser();
    if (dbName) {
      currentUser.company = dbName
    }

    baseUrl = config?.baseUrl + currentUser?.company + "/mapi";

    axios.defaults.headers.common["userid"] = `${currentUser?.data?.id || 0}`;
    axios.defaults.headers.common["Authorization"] = `Bearer ${currentUser?.data?.token || ""
      }`;
  }

  return {
    test() {
      alert("test function");
    },
    async getDatabases(tableName) {
      myservices = config?.baseUrl + "myservices/mapi";
      //addAuthorizationHeader();
      //baseUrl = config.baseUrl + config.company + '/mapi';
      return await axios.get(myservices + `/${tableName}`).then((response) => {
        //console.log(response.data);
        return response.data;
      });
    },
    async querymyservices(queryName, params) {
      //addAuthorizationHeader();
      var myservices = config?.baseUrl + "myservices/mapi";
      return await axios
        .get(myservices + `/query/${queryName}?${params}`)
        .then((response) => {
          //console.log(response.data);
          return response.data;
        });
    },
    get(tableName) {
      addAuthorizationHeader();
      //baseUrl = config.baseUrl + config.company + '/mapi';
      return axios.get(baseUrl + `/${tableName}`).then((response) => {
        //console.log(response.data);
        return response.data;
      });
    },
    getById(tableName, id) {
      addAuthorizationHeader();
      return axios.get(baseUrl + `/${tableName}/${id}`).then((response) => {
        //console.log(response.data);
        return response.data;
      });
    },
    search(name, params) {
      addAuthorizationHeader();

      if (!params) {
        params = "";
      }
      return axios
        .get(baseUrl + `/search/${name}/?${params}`)
        .then((response) => {
          //console.log(response.data);
          return response.data;
        });
    },
    post(tableName, obj, dbName) {
      addAuthorizationHeader(dbName);
      return axios.post(baseUrl + `/${tableName}`, obj).then((response) => {
        //console.log(response.data);
        return response.data;
      });
    },
    query(queryName, params) {
      addAuthorizationHeader();
      return axios
        .get(baseUrl + `/query/${queryName}?${params}`)
        .then((response) => {
          //console.log(response.data);
          return response.data;
        });
    },
    execute(queryName, params, method = 'get') {
      addAuthorizationHeader();
      if (method && method === 'post') {
        return axios
          .post(baseUrl + `/query/${queryName}`, params)
          .then((response) => {
            //console.log(response);
            return response.data;
          });
      } else if (method && method === 'get') {
        return axios
          .get(baseUrl + `/execute/${queryName}?${params}`)
          .then((response) => {
            //console.log(response.data);
            return response.data;
          });
      }
    },
    put(tableName, obj) {
      addAuthorizationHeader();
      return axios.put(baseUrl + `/${tableName}`, obj).then((response) => {
        //console.log(response.data);
        return response.data;
      });
    },
    delete(tableName, id) {
      addAuthorizationHeader();
      return axios.delete(baseUrl + `/${tableName}/${id}`).then((response) => {
        //console.log(response.data);
        return response.data;
      });
    },
    saveChanges(objs) {
      addAuthorizationHeader();
      return axios.put(baseUrl + `/savechanges`, objs).then((response) => {
        //console.log(response);
        return response.data;
      });
    },
    uploadFile(obj) {
      let logedInUser = globals().getLogedInUser();
      addAuthorizationHeader();
      baseUrl = `http://localhost:3003/${logedInUser?.company}/upload`;
      return axios.post(baseUrl, {image: obj}).then((response) => {
        return response;
      });
    },


  };
}
