import React, { useState } from 'react'
import helper from '../../Services/helper';

const GridView = (props) => {

    const [selectedCell, setSelectedCell] = useState();
    const [showHiddeBox, setShowHiddeBox] = useState();

    const getHeaders = function () {   
        if (props.headers ) {
            return props.headers;
        }
        else {
            //nese nuk eshte definu props headers nxjerre prej datasetit
            if(props.data && props.data.length>0){
                var firstRow = props.data[0];
                
                let cols = Object.keys(firstRow).map((key, index) => {
                        return { key: key, name: key }
                    });
                return cols;
            }
            else return [];
        }
    }

    const renderRows = function (rows) {   
      
        if(rows && rows.length > 0) {
            return rows?.map((row, index) => {
                return <tr key={index} ><td></td>
                    { renderCells(row,index) }
                </tr>
            })
        }
        else {
            return <tr><td colSpan={3}>Not found!</td></tr>
        }
    }

    const editModeCell= function (cell, index){
        props.data.find(d=> d.id === cell.id).index = index;;
        setSelectedCell({x:cell.id, y: index});
    }

    const renderCell = (cell, index) =>{
        if(cell.editMode){
            return <td key={index}>Edit-o {cell.value}</td>
        }
        else{
            if(cell.type === 'number'){
                return <td key={index} className="text-right pe-2">{cell.value}</td>
            }else if (cell.type === 'text'){
                return <td key={index} className="text-left ps-3" style={{color: row[header.key+"_color"] ? row[header.key+"_color"] : '', fontWeight: 'bold'}}>{row[header.name] ? row[header.name] : row[header.key]}</td>
            }else if (cell.type === 'comment'){
                return <td key={index} className="text-left ps-3" style={{fontWeight: 'bold'}}>{cell.value.substring(8)}</td>
            } else{
            return <td key={index} onClick={()=>editModeCell(cell,index)}>{cell.value}</td>
            }
        }
    }

    const renderCells = (row) => {       
        var headers =  getHeaders();
        
        return headers.map((header, index) => {

            if(!header.hidden){
                let cell = {};
                cell.value =  header ? ((header.key) ? row[header.key] : row[header.name]) : '';
                cell.dataType = header.type;
                cell.id = row.id;
                cell.editMode = (index === row.index);

                return renderCell(cell, index);
            }
        });
    }

    const onChangePage = (dataPage) => {
        setState({ data: dataPage });
    }

    const renderColumns = () => {
        let headers = getHeaders();
        return headers.map((header, index) => {
            if (header.dontShowMenu !== true) {
                return <div className='cursor-pointer' onClick={() => removeColumn(header.key)}>
                    <input className='me-2' type="checkbox" checked={header.hidden ? 'checked' : ''} name={header.name}></input><label>{header.name}</label></div>
            }
        });
    }

    const removeColumn = (key) => {
        props.headers.forEach(h => {
            if (h.key === key) h.showHidde = !h.showHidde;
        });
        setShowHiddeBox(!showHiddeBox);
    }

    return (
        
        <div className="card-body table-responsive">
            {
                showHiddeBox && <div className='datatable-columns'>
                    {renderColumns()}
                </div>
            }
            <table id="mytable" className="table table-hover text-center">   
                <thead>
                    {<th className="w-45" style={{ width: 50 }}><i className="fas fa-ellipsis-v mx-1 mt-1 cursor-pointer" 
                    onClick={() => setShowHiddeBox(!showHiddeBox)}></i></th>}
                   {
                    getHeaders().map((h,index) => (
                      !h.hidden &&  <th key={index}>{h.name? h.name: h.key}</th>
                    ))
                   }
                </thead>            
                <tbody>
                    {renderRows(props.data)}
                </tbody>
            </table>
        </div>
    );
}

export default GridView;
