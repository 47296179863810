import globals from "../Services/globals";

class Purchases {
    constructor(obj){
        var user = globals().getLogedInUser().data
        
        if(obj){ 
            this.id = obj.id;
            this.sId = obj.sId;
            this.date = obj.date;
            this.dudDate = obj.dudDate;
            this.warehouseId = obj.warehouseId
            this.partnerId = obj.partnerId
            this.transportId = obj.transportId
            this.paymentMethodId = obj.paymentMethodId
            this.totalSum = obj.totalSum
            this.transport = obj.transport
            this.overvalue = obj.overvalue
            this.excise = obj.excise
            this.duty = obj.duty
            this.withVat = obj.withVat
            this.import = obj.import
            this.vatSum = obj.vatSum
            this.purchaseTypeId = obj.purchaseTypeId
            this.invoiceNo = obj.invoiceNo
            this.reference = obj.reference
            this.registered = obj.registered
            this.dateRegistered = obj.dateRegistered
            this.comment = obj.comment
            this.registeredBy = obj.registeredBy
            this.createdAt = obj.createdAt ? obj.createdAt : new Date()
            this.createdBy = obj.createdBy ? obj.createdBy : user.name +" "+ user.surname
            this.changedAt = new Date() //obj.changedAt 
            this.changedBy = user.name +" "+ user.surname //obj.changedBy
            this.status = obj.status
            this.subTotal = obj.subTotal
            this.dudNo = obj.dudNo
            this.totalWithOvervalue = obj.totalWithOvervalue
            this.pay = obj.pay
            this.amount = obj.amount
         }
        else {
            this.id = 0
            this.sId = null
            this.date = new Date()
            this.dudDate = new Date()
            this.warehouseId = 0
            this.partnerId = 0
            this.transportId = 0
            this.paymentMethodId = this.pay ? 1 : 3
            this.totalSum = 0.0
            this.transport =  0.0
            this.overvalue = 0.0
            this.excise = 0.0
            this.duty = 0.0
            this.withVat = 0
            this.import = 0
            this.vatSum = 0.0
            this.purchaseTypeId = 0
            this.invoiceNo = ''
            this.reference = ''
            this.registered = 0
            this.dateRegistered = ''
            this.comment = ''
            this.registeredBy = ''
            this.createdAt = new Date
            this.createdBy = user.name +" "+ user.surname
            this.changedAt = new Date
            this.changedBy = user.name +" "+ user.surname
            this.status = 0
            this.subTotal = 0.0
            this.dudNo = "0"
            this.totalWithOvervalue = 0.0
            this.pay = false
            this.amount = 0.0
        }
    }
}

export default Purchases