import React, { Component, useEffect, useRef, useState } from 'react'
import ItemForm from './ItemForm'
import ItemImage from './ItemImage'
import ItemOtherDetails from './ItemOtherDetails';
import ItemAccounts from './ItemAccounts';
import HeaderTools from '../../PageComponents/Header/HeaderTools';
import Tabs from '../../PageComponents/Tabs/Tabs'
import UserPermissionContext from '../Context/UserPermissionContext';

class Item extends Component {
    static contextType = UserPermissionContext;
    constructor(props) {
        super(props);
        this.state = {
            articleType: "",
            item: {}
        }
    }

    componentDidmount(){
        window.addEventListener("hashchange", e =>  alert(this.props.location.hash ));
        this.getUrl()
    }

    handleCallback = (articleType, response) =>{
        this.setState({articleType: articleType})
        this.setState({
            item: response,
        });
        if(response?.id !== 0){
            if(this.props.pc) this.props.pc(response)
        }
    }

    getUrl=() =>{
        const originalString = window.location.hash;
        const letterToAdd = "s";
        // const index = originalString.indexOf("#");
        // const firstPart = originalString.slice(0, index);
        // const secondPart = originalString.slice(index);
        // const modifiedStringUrl = firstPart + letterToAdd + secondPart;
        const firstIndex = originalString.indexOf("#");
        const secondIndex = originalString.indexOf("#", firstIndex + 1);
        const firstPart = originalString.slice(0, secondIndex);
        const secondPart = originalString.slice(secondIndex);
        const modifiedStringUrl = firstPart + letterToAdd + secondPart;
        return modifiedStringUrl
    }

    stringUrl = this.getUrl()
        
    render() {
        let {articleType, item} = this.state; 
        let tabs = [
            {
                id: 1,
                title: 'Të dhanat bazike',
                body: <ItemForm save={click => this.save = click} newHandle={click => this.newItem = click} item={item} props={this.props} parentCallback = {this.handleCallback} />,
                show: true
            },
            // {
            //     id:2,
            //     title: 'Detaje Tjera',
            //     body: <ItemOtherDetails save={click => this.save = click} props={this.props} />,
            //     show: (articleType !== 'expense' && articleType !== 'service' && articleType !== 'inventoryItem') ? true : false
            // },
            {
                id:3,
                title: 'Kontot',
                body: <ItemAccounts save={click => this.save = click} props={this.props} item={item} parentCallback = {this.handleCallback} articleType={this.state.articleType} />,
                show: true
            },
            {
                id:4,
                title: 'Fotografia',
                body: <ItemImage props={this.props} />,
                //show: this.props.location?.hash === '#itemGoods' ? true : false
                show: articleType === "itemGoods" ? true : false
            }
        ]
        return (
            <div className="container-fluid px-2 mt-3">
                <div className="card border-left-primary shadow h-100 py-0">
                    <div className="card-body py-0">
                        <div className="row no-gutters align-items-center">
                            <div className="col mr-2 row">
                                <div className="col-lg-12 form-group my-0 d-flex align-items-center">
                                    <div className="d-table-cell no-shadow">
                                        <HeaderTools disableSaveButton={this.context.userPermission?.find((f)=> f.moduleName === "ITEMS")?.allowEdit === 0  ? true : ''} newHandle={() => this.newItem()} save={() => this.save()} back={this.stringUrl} />
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Tabs tabs={tabs} />
            </div>
        )
    }
} 

export default Item
