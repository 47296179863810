import React, { useState, useEffect } from "react";
import apiService from "../../../Services/apiServices";
import Select from "react-select"
import helper from "../../../Services/helper";

function SelectByGroup(props) {

  const [group , setGroup] = useState([]);
  const [ elements , setElements] = useState([]);
  const [ elementGroupId , setElementGroupId] = useState("");
  const [ firstItemLabel , setFirstITemLabel] = useState("");
  const [ valueInc , setValueInc] = useState(0);
  const [selectOptions , setSelectOptions] = useState();


  useEffect(() => {
    setGroup(props.parent);
},[props.parent])

  useEffect(() => {
    setElements(props.child);
},[props.child])

useEffect(() => {
  setElementGroupId(props.childParentId);
},[props.childParentId])

useEffect(() => {
  setFirstITemLabel(props.firstItemLabel);
},[props.firstItemLabel])

useEffect(() => {
  setValueInc(props.value);
},[props.value])

useEffect(() => {
   if(group?.length != 0 && elements?.length != 0 && elementGroupId != "" && firstItemLabel != "" && valueInc != 0){
     createOption(group,elements,elementGroupId); 
    }
},[group,elements,elementGroupId , firstItemLabel , valueInc])



const createOption = (gr,el , elGroupId) => {
  let allGroup = gr;
  let allElements = el;
  let finalOptions = [];
  let groupOptions ;

  allGroup?.forEach(g => {
    groupOptions = {
      label: g.name,
      options: [] 
    }
    allElements?.filter(e => e[elGroupId] == g.id).map(record =>  groupOptions.options.push({label: record.name , value: record.id}))
    finalOptions.push(groupOptions);
  });
  if(typeof( props.firstItemLabel) !== "undefined"){
    let firstITem = {label: props.firstItemLabel , value: null};
    finalOptions.unshift(firstITem);
  }

  setSelectOptions(finalOptions);
}
// const test = [
//   {
//     label: "Group 1",
//     options: [
//       { label: "Group 1, option 1", value: "value_1" },
//       { label: "Group 1, option 2", value: "value_2" }
//     ]
//   },
//   {
//     label: "Group 1",
//     options: [
//       { label: "Group 1, option 1", value: "value_1" },
//       { label: "Group 1, option 2", value: "value_2" }
//     ]
//   }
// ];

  return(
            <>
                {/* <label className="text-xs font-weight-bold text-primary text-uppercase mb-1">{props.label}</label>
                <select name={props.name} onChange={props.changed} value={props.value}  className="form-control form-control">
                <option value="0">{props.selectText}</option>
                {props.data.map((item, key) =>
                    <option key={key} value={item.Id}>{item.Name}</option>
                )}
                </select> */}
                
                 {/* {console.log(selectOptions?.find((g) => g?.options?.find(({value}) => value == 2)))} */}
                <Select 
                options={selectOptions}
                name = {props?.name}
                className = {props?.className}
                styles = {props?.styles}
                onChange = {props?.onChange}
                value= {selectOptions?.find((g) => g?.value == valueInc)  ?  selectOptions?.find((g) => g?.value == valueInc) : selectOptions?.find((g) => g?.options?.find(({value}) => value === valueInc))?.options?.find(({value}) => value === valueInc)}
                placeholder = {props.placeholder ? props.placeholder : ''}
                isDisabled={props.isDisabled}
                />
            </>

        )
};


export default SelectByGroup;
