class AccountingPlansModel {
    constructor(obj){
        if(obj){
            this.accountNumber = obj.accountNumber;
            this.name= obj.name;
            this.grupiId= obj.grupiId;
            this.pozicioniId= obj.pozicioniId;
        }
        else {
           this.id = 0;
           this.accountNumber = 0;
           this.name= '';
           this.grupiId= null;
           this.pozicioniId= null;
           this.expendable = false;
           this.accountNumberPrefix = 0;
           this.accountNumberInput = '';        }
    }
}


export default AccountingPlansModel;