import React, { useState, useEffect, useContext } from "react";
import Modal from "react-bootstrap/Modal";
import HeaderTools from "../../Header/HeaderTools";
import AccountingPlansModel from "../../../Models/AccountingPlansModel";
import apiService from "../../../Services/apiServices";
import Select from "react-select";
import Switch from "react-switch";
import { useToasts } from "react-toast-notifications";
import Item from "../../../Models/Item";
import { confirmAlert } from "react-confirm-alert";
import "../../../App.css";
import helper from "../../../Services/helper";
import UserPermissionContext from "../../../components/Context/UserPermissionContext";

function AccountingPlansNew(props) {
  const [accountingItem, setAccountingItem] = useState(
    new AccountingPlansModel()
  );
  const [itemSpend, setItemSpend] = useState(new Item());
  const [grupiKontabel, setGrupiKontabel] = useState([]);
  // const [pozicioniPasqyre, setPozicioniPasqyre] = useState([]);
  const [klasaKontabel, setKlasaKontabel] = useState([]);
  const [insertGrupi, setInsertGrupi] = useState([]);
  const [updateGrupi, setUpdateGrupi] = useState([]);
  const [validate, setValidate] = useState(false);
  const [validateAdd, setValidateAdd] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);
  const { addToast } = useToasts();
  const {userPermission} = useContext(UserPermissionContext);

  const styles = {
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  };

  const getGrupiKontabel = () => {
    let options;
    apiService()
      .query("getGrupiKontabel")
      .then((response) => {
        if (response.success) {
          options = response.data.map((item, index) => ({
            value: item.id,
            label: item.name,
            grupiId: item.grupiId,
            klasaId: item.klasaId,
            grRange: item.grRange,
          }));
          setGrupiKontabel(options);
        } else {
          alert("Error: getting Plani Kontabel" + response.errorMessage);
        }
      });
  };
  // const getPozicioniPasqyre = () => {
  //   let options;
  //   apiService()
  //     .query("getPozicioniPasqyre")
  //     .then((response) => {
  //       if (response.success) {
  //         options = response.data.map((item, index) => ({
  //           value: item.id,
  //           label: item.name,
  //         }));
  //         setPozicioniPasqyre(options);
  //       } else {
  //         alert("Error: getting Pozicioni Pasqyre" + response.errorMessage);
  //       }
  //     });
  // };
  const getKlasaKontabel = () => {
    let options;
    apiService()
      .query("getKlasaKontabel")
      .then((response) => {
        if (response.success) {
          options = response.data.map((item, index) => ({
            value: item.id,
            label: item.name,
          }));
          setKlasaKontabel(options);
        } else {
          alert("Error: getting Klasa Kontabel" + response.errorMessage);
        }
      });
  };

  useEffect(() => {
    getGrupiKontabel();
    // getPozicioniPasqyre();
    getKlasaKontabel();
  }, []);
  // useEffect(() => {
  //   if(props.onNewClick){
  //     setAccountingItem(new AccountingPlansModel());
  //   }
  // } ,[props.onNewClick])

  useEffect(() => {
    if (props.dataSend != "") {
      let data = props.dataSend;
      let numberLength = data.accountNumber.length;
      let prefix = data.accountNumber.slice(0, 3);
      let numberInput = data.accountNumber.slice(3, numberLength);
      setAccountingItem({
        ...data,
        accountNumberPrefix: prefix,
        accountNumberInput: numberInput,
      });
      if (props.onNewClick) {
        setValidate(false);
        setAccountingItem(new AccountingPlansModel());
        setValidateAdd(false);
        setInsertGrupi([]);
      }
    }
  }, [props.dataSend]);

  // useEffect(() => {
  //   setItemSpend({...itemSpend ,  barcode:accountingItem.accountNumber , expense: true , itemName: accountingItem.name , productNo: accountingItem.accountNumber });
  // } , [sendSpendItem])

  const fieldChange = (e) => {
    if (e.target.name === "accountNumberInput") {
      if (parseInt(e.target.value.length) <= 4) {
        setAccountingItem({
          ...accountingItem,
          accountNumberInput: e.target.value,
          accountNumber: parseInt(
            accountingItem.accountNumberPrefix + e.target.value
          ),
        });
        setItemSpend({
          ...itemSpend,
          barcode: parseInt(
            accountingItem.accountNumberPrefix + e.target.value
          ),
          productNo: parseInt(
            accountingItem.accountNumberPrefix + e.target.value
          ),
        });
      }
    } else if (e.target.name === "addGroupNum") {
      if (parseInt(e.target.value.length) <= 4) {
        setInsertGrupi({ ...insertGrupi, grupiId: parseInt(e.target.value) });
      }
    } else if (e.target.name === "updateGrupi") {
      setUpdateGrupi({
        ...updateGrupi,
        newName: e.target.value,
        updateId: grupiKontabel.find((g) => g.value == accountingItem.grupiId)
          ?.value,
        range: grupiKontabel.find((g) => g.value == accountingItem.grupiId)
          ?.grRange,
      });
    } else if (e.target.name === "name") {
      setAccountingItem({ ...accountingItem, [e.target.name]: e.target.value });
      setItemSpend({ ...itemSpend, itemName: e.target.value });
    } else if (e.target.name === "addName") {
      setInsertGrupi({ ...insertGrupi, name: e.target.value });
    }
    //  else if (e.target.name === "btn1") {
    //   setInsertGrupi({ ...insertGrupi, grupiId: e.target.value });
    // } else if (e.target.name === "btn2") {
    //   setInsertGrupi({ ...insertGrupi, grupiId: e.target.value });
    // } else if (e.target.name === "btn3") {
    //   setInsertGrupi({ ...insertGrupi, grupiId: e.target.value });
    // } else if (e.target.name === "btn4") {
    //   setInsertGrupi({ ...insertGrupi, grupiId: e.target.value });
    // }
    else {
      setAccountingItem({ ...accountingItem, [e.target.name]: e.target.value });
    }
  };
  const selectChange = (e, obj) => {
    if (obj.name === "grupiId") {
      let arr = grupiKontabel;
      let grupi = arr.find((item) => item.value == e.value);
      setAccountingItem({
        ...accountingItem,
        accountNumberPrefix: grupi.grupiId,
        accountNumber: parseInt(
          grupi.grupiId+ accountingItem.accountNumberInput
        ),
        [obj.name]: e.value
      });
      if (showUpdate) {
        setUpdateGrupi({
          ...updateGrupi,
          newName: grupiKontabel.find((g) => g.value == e.value)?.label,
          updateId: grupiKontabel.find((g) => g.value == e.value)?.value,
          range: grupiKontabel.find((g) => g.value == e.value)
            ?.grRange,
        });
      }
      if (grupi.grupiId.toString().slice(0, 1) == 6) {
        setItemSpend({ ...itemSpend, expense: true });
      }
    } else if (obj.name === "addClass") {
      setInsertGrupi({ ...insertGrupi, klasaId: e.value, grupiPrefix: "" });
      grupiKontabel.filter((g) => g.klasaId == e.value && g.grRange > 0);
    } else if (obj.name === "selectGroup") {
      var range = grupiKontabel.find((i) => i.grupiId == e.grupiId);
      var params = `grIdStart=${e.grupiId}&grIdEnd=${
        e.grupiId + range.grRange
      }`;
      apiService()
        .query("getRangeKontabel", params)
        .then((response) => {
          if (response.success) {
            setInsertGrupi({
              ...insertGrupi,
              length: response.data.length,
              grupiId: e.grupiId + response.data.length,
              grupiPrefix: e.grupiId,
              available: range.grRange - response.data.length,
              grRange: 0,
            });
          } else {
            alert("Error:" + response.errorMessage);
          }
        });
    } else {
      setAccountingItem({ ...accountingItem, [obj.name]: e.value });
    }
  };

  // const switchChange =() => {
  //   var test = !accountingItem.expendable;
  //   if(test){
  //     setItemSpend({...itemSpend ,  barcode:accountingItem.accountNumber , expense: true , itemName: accountingItem.name , productNo: accountingItem.accountNumber });
  //   }else{
  //     setItemSpend(new Item());
  //   }
  //   setAccountingItem({...accountingItem , expendable: test });
  // }

  const showalert = (msg) => {
    addToast(msg, {
      appearance: "error",
      autoDismiss: true,
    });
  };

  const newHandle = () => {
    setValidate(false);
    setAccountingItem(new AccountingPlansModel());
    setValidateAdd(false);
    setInsertGrupi([]);
    setUpdateGrupi([]);
  };
  const validateForm = () => {
    if (
      accountingItem.grupiId != null &&
      // accountingItem.pozicioniId != null &&
      accountingItem.name != "" &&
      accountingItem.accountNumberInput.length == 4
    ) {
      return true;
    } else {
      return false;
    }
  };

  const insertItemSpend = (item) => {
    apiService()
      .post("items", item)
      .then((response) => {
        console.log(response.success);
      });
  };

  const validateAddFrom = () => {
    if (
      insertGrupi.klasaId != null &&
      insertGrupi.grupiPrefix != null &&
      insertGrupi.grupiPrefix != "" &&
      typeof insertGrupi.grupiPrefix !== "undefined" &&
      insertGrupi.name != ""
    ) {
      return true;
    } else {
      return false;
    }
  };

  const saveGroup = () => {
    if (validateAddFrom()) {
      if (insertGrupi.available > 0) {
        apiService()
          .execute("insertGrupiKontabel", insertGrupi, "post")
          .then((response) => {
            if (response.success) {
              setShowAdd(false);
              getGrupiKontabel();
              addToast(helper().translate("SUCCESSFULLY_ADDED"), {
                appearance: "success",
                autoDismiss: true,
              });
            } else {
              addToast(helper().translate("ERROR_SAVING"), {
                appearance: "error",
                autoDismiss: true,
              });
            }
          });
      } else {
        addToast(helper().translate("CANT_ADD_MORE"), {
          appearance: "error",
          autoDismiss: true,
        });
      }

      setValidateAdd(false);
    } else {
      setValidateAdd(true);
      addToast(helper().translate("FILL_REQUIRED_FIELDS"), {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };

  const updateGroup = () => {
    apiService()
      .execute("updateGrupiKontabel", updateGrupi, "post")
      .then((response) => {
        if (response.success) {
          addToast(helper().translate("SUCCESSFULLY_MODIFIED"), {
            appearance: "success",
            autoDismiss: true,
          });
          getGrupiKontabel();
          setShowUpdate(false);
          setUpdateGrupi([]);
        } else {
          addToast(helper().translate("ERROR_MODIFIED"), {
            appearance: "error",
            autoDismiss: true,
          });
        }
      });
  };

  const deleteGroup = () => {
    if (updateGrupi.range === 0) {
      apiService()
        .query("deleteGrupiKontabel", "updateId="+updateGrupi.updateId)
        .then((response) => {
          if (response.success) {
            addToast(helper().translate("SUCCESSFULLY_DELETED"), {
              appearance: "success",
              autoDismiss: true,
            });
            getGrupiKontabel();
            setShowUpdate(false);
            setUpdateGrupi([]);
          } else {
            addToast(helper().translate("ERROR_DELETING"), {
              appearance: "error",
              autoDismiss: true,
            });
          }
        });
    } else {
      addToast(helper().translate("CANT_DELETE_THIS"), {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };

  const deleteGroupAlert = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <h1>{helper().translate("ARE_YOU_SURE")}</h1>
            <p>{helper().translate("WANT_TO_DELETE_THIS_GROUP")}</p>
            <button
              onClick={() => {
                deleteGroup();
                onClose();
              }}
            >
              {helper().translate("YES")} , {helper().translate("DELETE")}!
            </button>
            <button onClick={onClose}>{helper().translate("NO")}</button>
          </div>
        );
      },
    });
  };

  const save = () => {
    if (accountingItem.id === 0) {
      if (validateForm()) {
        var prefixNumber = accountingItem.accountNumberPrefix;
        apiService()
          .query(
            "searchAccPlaniKontabel",
            "accountNumber=" + accountingItem.accountNumber
          )
          .then((response) => {
            if (response.data.length === 0) {
              apiService()
                .execute("insertAccountingPlan", accountingItem, "post")
                .then((response) => {
                  if (response.success) {
                    var resId = response.data[0].id;
                    if (prefixNumber.toString().slice(0, 1) == 6) {
                      insertItemSpend({ ...itemSpend, accountA: resId });
                    }
                    addToast(helper().translate("SUCCESSFULLY_ADDED"), {
                      appearance: "success",
                      autoDismiss: true,
                    });
                    props.getAccountingPlan();
                    setValidate(false);
                    setAccountingItem({ ...accountingItem, id: resId });
                  } else {
                    addToast(helper().translate("ERROR_SAVING"), {
                      appearance: "error",
                      autoDismiss: true,
                    });
                  }
                });
            } else {
              addToast(helper().translate("THIS_ACCOUNT_NUMBER_EXISTS"), {
                appearance: "error",
                autoDismiss: true,
              });
            }
          });
      } else {
        setValidate(true);
        addToast(helper().translate("FILL_REQUIRED_FIELDS"), {
          appearance: "error",
          autoDismiss: true,
        });
      }
    } else {
      if (validateForm()) {
        apiService()
          .execute("updateAccountingPlan", accountingItem, "post")
          .then((response) => {
            if (response.success) {
              addToast(helper().translate("SUCCESSFULLY_MODIFIED"), {
                appearance: "success",
                autoDismiss: true,
              });
              props.getAccountingPlan();
              setValidate(false);
            } else {
              addToast(helper().translate("ERROR_MODIFIED"), {
                appearance: "error",
                autoDismiss: true,
              });
            }
          });
      }else {
        setValidate(true);
        addToast(helper().translate("FILL_REQUIRED_FIELDS"), {
          appearance: "error",
          autoDismiss: true,
        });
      }
    }
  };

  return (
    <>
      <Modal
        {...props}
        size="lg"
        scrollable={true}
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        onHide={()=> {props.onHide(); newHandle() ; setShowAdd(false) ; setShowUpdate(false)}}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {helper().translate("ACCOUNTING_PLAN")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            {showAdd == false && showUpdate == false ? <HeaderTools disableSaveButton={userPermission?.find((f)=> f.moduleName === "FINANCE")?.allowEdit === 0  ? true : ''} headersFrom={"FINANCE"} newHandle={newHandle} save={save} /> : '' }
            <div className="justify-content-center">
              <div className="card o-hidden border-0 shadow-lg border-custom  ">
                <div className="card-body p-0 m-3">
                  {showAdd ? (
                    <div>
                      {" "}
                      <div className="row">
                      <div className="col-lg-6 col-12 offset-md-3">
                      <h4 className="text-center">{helper().translate("ADD_GROUP")}</h4>{" "}                     
                      </div>
                      <div className="col-3  d-none d-lg-block">
                        <div className="d-flex justify-content-end">
                        <a className="arrowLeft" onClick={()=> { setShowAdd(false); setValidate(false);setValidateAdd(false); setInsertGrupi([]);setUpdateGrupi([]);}} ><i className="fas fa-arrow-left fa-lg" > </i></a>
                        </div>
                      </div>
                      </div>
                      <div className="row my-2">
                        <div className="col-lg-6 col-12 mb-2 pe-lg-1 ">
                          <label className="text-sm font-weight-bold text-primary  mb-0">
                          {helper().translate("CLASS")}
                          </label>
                          <Select
                            menuPosition={"fixed"}
                            required
                            name="addClass"
                            options={klasaKontabel}
                            onChange={(e, obj) => selectChange(e, obj)}
                            value={
                              klasaKontabel.find(
                                (k) => k.value == insertGrupi.klasaId
                              ) || ""
                            }
                          />
                          {validateAdd ? (
                            insertGrupi.klasaId == null ? (
                              <span className="text-center text-danger small">
                                {helper().translate("CHOOSE_CLASS")}
                              </span>
                            ) : (
                              ""
                            )
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="col-lg-6 col-12 mb-2 ps-lg-2 ">
                          <label className="text-sm font-weight-bold text-primary  mb-0">
                          {helper().translate("GROUP")}
                          </label>
                          <Select
                            menuPosition={"fixed"}
                            required
                            name="selectGroup"
                            options={grupiKontabel.filter(
                              (g) =>
                                g.klasaId == insertGrupi.klasaId &&
                                g.grRange > 0
                            )}
                            onChange={(e, obj) => selectChange(e, obj)}
                            value={
                              grupiKontabel.find(
                                (g) => g.grupiId == insertGrupi.grupiPrefix
                              ) || ""
                            }
                          />
                          {validateAdd ? (
                            insertGrupi.grupiPrefix == null ||
                            insertGrupi.grupiPrefix == "" ? (
                              <span className="text-center text-danger small">
                                {helper().translate("CHOOSE_GROUP")}
                              </span>
                            ) : (
                              ""
                            )
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="col-lg-6 col-12 mb-2 pe-lg-1 ">
                          <label className="text-sm font-weight-bold text-primary  mb-0">
                          {helper().translate("DESIGNATION")}
                          </label>
                          <div className=" pe-0 ">
                            <input
                              type="text"
                              className="form-control"
                              name="addName"
                              onChange={(e) => fieldChange(e)}
                              value={insertGrupi.name || ""}
                            />
                            {validateAdd ? (
                              insertGrupi.name == null ? (
                                <span className="text-center text-danger small">
                                 {helper().translate("CHOOSE_DESIGNATION")}
                                </span>
                              ) : (
                                ""
                              )
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        <div className=" col-lg-4 col-12 mb-2 ps-lg-2 ">
                          <label className="text-sm font-weight-bold text-primary  mb-0">
                          {helper().translate("GROUP")}-id
                          </label>
                          <div className=" pe-0 ">
                            <input
                              type="text"
                              disabled
                              className="form-control text-truncate"
                              value={insertGrupi.grupiId || ""}
                            />
                          </div>
                          {/* <div >
                          <button  className={insertGrupi.grupiId == 1 ? "btn btn-success mx-2 active" : 'btn btn-success mx-2 '}    type="button" value={1} name="btn1"  onClick={(e) => fieldChange(e)}>1</button>
                      </div>
                      <div >
                          <button  className={insertGrupi.grupiId == 2 ? "btn btn-success mx-2 active" : 'btn btn-success mx-2 '} type="button" value={2} name="btn2"  onClick={(e) => fieldChange(e)}>2</button>
                      </div>
                      <div >
                          <button  className={insertGrupi.grupiId == 3 ? "btn btn-success mx-2 active" : 'btn btn-success mx-2 '} type="button" value={3} name="btn3"  onClick={(e) => fieldChange(e)}>3</button>
                      </div>
                      <div >
                          <button  className={insertGrupi.grupiId == 4 ? "btn btn-success mx-2 active" : 'btn btn-success mx-2 '} type="button" value={4} name="btn4"  onClick={(e) => fieldChange(e)}>4</button>
                      </div> */}
                        </div>

                        <div className="col-lg-2 col-12 mb-2 ps-lg-0 d-flex align-items-end justify-content-center">
                          <div>
                            <button
                              className="btn btn-primary "
                              onClick={saveGroup}
                            >
                              {helper().translate("SAVE")}
                            </button>
                          </div>
                        </div>
                      </div>
                      
                    </div>
                  ) : (
                    ""
                  )}
                  {showUpdate ? (
                    <div>
                       <div className="row">
                      <div className="col-lg-6 col-12 offset-md-3">
                      <h4 className="text-center">{helper().translate("ADD_GROUP")}</h4>{" "}                     
                      </div>
                      <div className="col-3  d-none d-lg-block">
                        <div className="d-flex justify-content-end">
                        <a className="accounting-arrowLeft" onClick={()=> { setShowUpdate(false); setValidate(false);setValidateAdd(false); setInsertGrupi([]);setUpdateGrupi([]);}} ><i className="fas fa-arrow-left fa-lg" > </i></a>
                        </div>
                      </div>
                      </div>
                      <div className="row mb-2">
                        <div className="col-lg-6 col-12 mb-2 text-center">
                          <label className="text-sm font-weight-bold text-primary  mb-0">
                            Modifiko Grupin
                          </label>
                          <div className="input-group">
                          <input
                            type="text"
                            className="form-control "
                            name="updateGrupi"
                            value={updateGrupi.newName || ""}
                            onChange={(e) => fieldChange(e)}
                          />
                          <span className="input-group-text bg-light" > " "</span>
                          </div>
                          <div className="position-relative ">
                            <a
                              className="accountingDelete-btn bg-white px-2"
                              title="Delete Grupi"
                              onClick={deleteGroupAlert}
                            >
                              <i className="fas fa-minus-circle fa-lg"> </i>
                            </a>
                          </div>
                        </div>
                        <div className="col-lg-2 col-12 mb-2 ps-lg-0 d-flex align-items-end justify-content-center justify-content-lg-start">
                          <div>
                            <button
                              className="btn btn-primary ms-2 "
                              onClick={updateGroup}
                            >
                              {helper().translate("SAVE")}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {showAdd == false && showUpdate == false ? <div>   <div className="row">
                    <div className="col-lg-6 col-12 mb-2 ">
                      <label className="text-sm font-weight-bold text-primary  mb-0">
                      {helper().translate("GROUP")}
                      </label>
                      <Select
                        menuPosition={"fixed"}
                        required
                        name="grupiId"
                        options={grupiKontabel}
                        value={
                          grupiKontabel.find(
                            (g) => g.value == accountingItem.grupiId
                          ) || ""
                        }
                        onChange={(e, obj) => selectChange(e, obj)}
                      />
                      {validate ? (
                        accountingItem.grupiId == null ? (
                          <span className="text-center text-danger small">
                            {helper().translate("CHOOSE_GROUP_TYPE")}
                          </span>
                        ) : (
                          ""
                        )
                      ) : (
                        ""
                      )}
                      <div className="position-relative ">
                        <a
                          className="accountingAdd-btn bg-white px-1"
                          title="Shto"
                          onClick={() => {
                            setShowAdd(true);
                            setShowUpdate(false);
                            setValidateAdd(false);
                            setInsertGrupi([]);
                          }}
                        >
                          <i className="fas fa-plus fa-lg"> </i>
                        </a>
                        <a
                          className="accountingEdit-btn bg-light px-2 "
                          title="Edito"
                          onClick={() => {{ accountingItem?.grupiId ? setShowUpdate(true) : showalert(helper().translate("CHOOSE_GROUP"));}setShowAdd(false); setUpdateGrupi({
                              ...updateGrupi,
                              newName: grupiKontabel.find(
                                (g) => g.value == accountingItem.grupiId
                              )?.label,
                              updateId: grupiKontabel.find(
                                (g) => g.value == accountingItem.grupiId
                              )?.value,
                              range: grupiKontabel.find(
                                (g) => g.value == accountingItem.grupiId
                              )?.grRange,
                            });
                          }}
                        >
                          <i className="fas fa-pen-square fa-lg"> </i>
                        </a>
                      </div>
                    </div>
                    <div className="col-lg-6 col-12 mb-2 pe-4 ">
                      <label className="text-sm font-weight-bold text-primary  mb-0">
                      {helper().translate("ACCOUNT")}Nr
                      </label>
                      <div className="row">
                        <div className="col-2 text-center pe-0 ">
                          <input
                            type="text"
                            className="form-control text-center p-0 border-0"
                            name="accountNumberPrefix"
                            disabled
                            value={accountingItem.accountNumberPrefix}
                          />
                        </div>
                        <p className="col p-0 pt-1 m-0 text-center">─</p>
                        <div className="col-9 p-0 ps-0">
                          <input
                            type="number"
                            className="form-control"
                            placeholder="0000-9999"
                            name="accountNumberInput"
                            onChange={(e) => fieldChange(e)}
                            value={accountingItem.accountNumberInput}
                            style={{ marginRight: "12px" }}
                          />
                          {validate ? (
                            accountingItem.accountNumberInput.length !== 4 ? (
                              <span className="text-center text-danger small">
                                {helper().translate("NUMBER_BETWEEN_0000-9999")}
                              </span>
                            ) : (
                              ""
                            )
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    {/* <div className="col-lg-6 col-12 mb-2">
                      <label className="text-sm font-weight-bold text-primary  mb-0">
                      {helper().translate("POSITION")}
                      </label>
                      <Select
                        menuPosition={"fixed"}
                        required
                        name="pozicioniId"
                        options={pozicioniPasqyre}
                        onChange={(e, obj) => selectChange(e, obj)}
                        value={
                          pozicioniPasqyre.find(
                            (p) => p.value == accountingItem.pozicioniId
                          ) || ""
                        }
                        menuPortalTarget={AccountingPlansNew.body}
                      />
                      {validate ? (
                        accountingItem.pozicioniId == null ? (
                          <span className="text-center text-danger small">
                            {helper().translate("CHOOSE_POSITION_TYPE")}
                          </span>
                        ) : (
                          ""
                        )
                      ) : (
                        ""
                      )}
                    </div> */}
                    <div className="col-lg-6 col-12 mb-2">
                      <label className="text-sm font-weight-bold text-primary  mb-0">
                      {helper().translate("ACCOUNT_DESIGNATION")}
                      </label>
                      <input
                        type="text"
                        name="name"
                        className="form-control"
                        onChange={(e) => fieldChange(e)}
                        value={accountingItem.name}
                      />
                      {validate ? (
                        accountingItem.name == "" ? (
                          <span className="text-center text-danger small">
                            {helper().translate("CHOOSE_ACCOUNT_NAME")}
                          </span>
                        ) : (
                          ""
                        )
                      ) : (
                        ""
                      )}
                    </div>
                  </div></div> : ''}
                  {/* <div className="row">
                     <div className="col-lg-6 col-12 mb-2">
                     <div className=" d-flex flex-column align-items-center">
                            <label className="text-sm font-weight-bold text-primary">
                             Ruaj si Artikull Shpenzues
                            </label>
                            <div className="text-light">
                            <Switch      
                             name="withVat"                     
                             onColor="#86d3ff"
                             onHandleColor="#2693e6"
                             handleDiameter={25}
                             width={96}
                             height={30}
                             uncheckedIcon={"  JO"}
                             checkedIcon={'   PO'}
                             boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                             activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                             onChange={switchChange} 
                             checked={accountingItem.expendable}
                            />
                            </div>
                          </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default AccountingPlansNew;
