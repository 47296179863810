import React, { useState, useEffect ,useRef } from "react";
import Select from "react-select";
import { MDBInput } from "mdb-react-ui-kit";
import apiService from "../../../Services/apiServices";
import { useToasts } from "react-toast-notifications";
import Search from "../../../PageComponents/Search/Search_Old";
import globals from "../../../Services/globals";
import { confirmAlert } from "react-confirm-alert";
import helper from "../../../Services/helper";
import calculate from "../../../Services/calculate";
import Pagination from "../../../PageComponents/UI/Pagination/Pagination";
import SelectByGroup from './../../../PageComponents/UI/Inputs/SelectByGroup';
import ExcelExport from "../../../PageComponents/Excel/ExcelExport";
import Print from "../../../PageComponents/Print/Print";
import DataTable from "../../../PageComponents/UI/Tables/DataTable";
import LoaderComponent from "../../../PageComponents/UI/Components/LoaderComponent";



function CashBoxBook(props) {
    const [findByDate , setFindByDate] = useState({cashboxId: null  , dateStart: null, dateEnd:null , dateStartDisabled: true , dateEndDisabled: true });
    const [items, setItems] = useState([]);
    const [pageOfItems, setPageOfItems] = useState([]);
    const [find, setFind] = useState([]);
    const [cashBoxes, setCashBoxes] = useState([]);
    const [dataShow, setDataShow] = useState(true);
    const { addToast } = useToasts();
    const excelExportRef = useRef();
    const [loader , setLoader] = useState(false);
    const printRef = useRef();
    const searchObj = { CompanyBranch: 0, PurchaseType: -1, StartDate: helper().formatDate(findByDate.dateStart,'MM-dd-yyyy'), EndDate: helper().formatDate(findByDate.dateEnd,'MM-dd-yyyy') }


    useEffect(() => {
      getDate();
      getCashBoxes();
  },[])

  const exportToExcel = () => {
    // let table = document.getElementById('table-to-xls-1').innerHTML;
    let fileName = helper().translate("CASHBOX_BOOK");
    // return helper().exportToExcel(table, fileName)
    excelExportRef.current.exportToExcel(fileName)
}

const print = () => {
  // window.open(`#/excel-print?CompanyBranch=${searchQuery.CompanyBranch}&YEAR=${searchQuery.YEAR}&StartMonth=${searchQuery.StartMonth}&EndMonth=${searchQuery.EndMonth}`, 'popUpWindow', 'height=1300,width="100%",left=100,top=100,right=100,bottom=100,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes')
  //setOpen(!open)
  printRef.current.printPreview()
}

const headers = [
    { key: 'no', columnName: helper().translate("NR") , dontShowMenu : true  },
    { key: 'documentId', columnName: helper().translate("REFERENCE_NO") },
    { key: 'date', columnName: helper().translate("DATE") },
    { key: 'companyName', columnName: helper().translate("SUBJECT") , type:"text" },
    { key: 'arka', columnName: helper().translate("CASHBOX") , type:"text"  },
    { key: 'paymentType', columnName: helper().translate("PAYMENT_TYPE") , type:"text"  },
    { key: 'description', columnName: helper().translate("DESCRIPTION") , type:"comment" },
    { key: 'debi', columnName: helper().translate("IN") , calculate: true , type:"number"},
    { key: 'credi', columnName: helper().translate("OUT"), calculate: true  , type:"number"},
    { key: 'saldo', columnName: "Saldo" , calculate: true ,  type:"number"} 
  ];
  const getAtkCashBoxBook =(cashboxId ,dateStart , dateEnd  ) =>{
    setLoader(true);
    let strStartDate = helper().formatDate(dateStart,'MM-dd-yyyy');
    let strEndDate = helper().formatDate(dateEnd,'MM-dd-yyyy') + " 23:59:59"
    var params = `&cashBox=${cashboxId}&dateStart=${strStartDate}&dateEnd=${strEndDate}`;
    apiService()
    .query('RptGetCashBoxTransactionsBook', params )
    .then((response) => {
        if (response.success) {
          setLoader(false);
          var items = response.data;
          var previusSaldo = 0.00;
          // for (var i = 0; i < items.length; i++) {
          //     let inOut = items[i].debi - items[i].credi;
          //     console.log(inOut, "in");
          //     items[i].saldo = inOut + items[i-1]?.saldo;
          //     console.log(saldo, "sa")
          // }
          items.forEach(e => {
              let inOut = e.debi - e.credi;
              e.saldo = ( parseFloat(inOut)  + parseFloat(previusSaldo));
              e.date = helper().formatDate(e.date);
              e.debi = e.debi;
              e.credi = e.credi !== null ?  e.credi : 0;
              previusSaldo = e.saldo;
            });
          if(items.length > 0){
            setFind(items);
            setItems( items);
            setDataShow(true)
          }else{
            setDataShow(false)
          }
        } else {
          console.log('Error:' + response.errorMessage);
        }
      });
  }

  const getDate = () => {
    // var getDate = new Date();
    //     var dateNow = new Date().toISOString().substring(0,10);
    //     var time = getDate.getHours() + ':' + getDate.getMinutes() + ':' + getDate.getSeconds();
    //     var dateTimeNow = dateNow+" " + time;
    //     let day = `${getDate.getDate()}`.padStart(2, "0")
    //     let month = `${getDate.getMonth()+1}`.padStart(2, "0");
    //     var dateTimeStart = ((getDate.getFullYear()+"-"+month+"-"+ "01"))+ ' ' + "00:00:00";

        var dateTimeNow = new Date();
        var dateTimeStart = new Date(dateTimeNow.getFullYear(),dateTimeNow.getMonth(),2); 
        //get usercashboxid
        let currentUserId = globals().getLogedInUser().data.username;
        apiService().query('user',  'userName='+currentUserId )
        .then((response) => {
          if (response.success) {
            let cashBox = response.data[0].cashBoxId;
            setFindByDate({...findByDate , dateStart: dateTimeStart , dateEnd: dateTimeNow , cashboxId: cashBox});
            getAtkCashBoxBook(cashBox, dateTimeStart , dateTimeNow );
    
          } else {
            console.log('Error: getting user cashbox' + response.errorMessage);
          }
        });
  }


    const getCashBoxes = () => {
        let options;
        apiService()
          .query("getCashBoxes")
          .then((response) => {
            if (response.success) {
              let data = response.data;
              options = data.map((item, index) => ({
                value: item.id,
                label: item.name
              }));
              setCashBoxes(options);
            } else {
              alert("Error: getting CashBoxes" + response.errorMessage);
            }
          });
      };


    const searchBtn = () => {

      if(findByDate.cashboxId != null){
        let dateStart = findByDate.dateStart;
        let dateEnd = findByDate.dateEnd;
        
        if(findByDate.dateStartDisabled === false){
          //getyear
          let dates = new Date(dateEnd).getFullYear();
          dateStart = `${dates}-01-01 00:00:00`;
        }else if (findByDate.dateEndDisabled == false){
          dateEnd = "2222-01-01 00:00:00";
        }
        getAtkCashBoxBook(findByDate.cashboxId, dateStart , dateEnd )
      }else{
        addToast(helper().translate("CHOOSE_CASHBOX"), {
                    appearance: "error",
                    autoDismiss: true,
                  });
      }
    }

    const selectStyles = {
        control: (provided, state) => ({
          ...provided,
          background: "#fff",
          borderColor: "#9e9e9e",
          minHeight: "28.59px",
          height: "28.59px",
          boxShadow: state.isFocused ? null : null,
        }),
    
        valueContainer: (provided, state) => ({
          ...provided,
          height: "28.59px",
          padding: "0 6px",
        }),
    
        input: (provided, state) => ({
          ...provided,
          margin: "0px",
        }),
        indicatorSeparator: (state) => ({
          display: "none",
        }),
        indicatorsContainer: (provided, state) => ({
          ...provided,
          height: "28.59px",
        }),
      };
    const fieldChange = (e) => {
        if(e.target.name === "dateStart"){
            setFindByDate({...findByDate , dateStart: e.target.value + " 00:00:00" });
        }else
        if(e.target.name === "dateEnd"){ 
          setFindByDate({...findByDate , dateEnd: e.target.value + " 23:59:59"});
         
      }
    };

    const selectChange = (e,obj) => {
        if (obj.name === "cashBox"){
            setFindByDate({...findByDate, cashboxId:e.value})
        }else if (obj.name === "warehouseId"){
          setFindByDate({...findByDate, warehouseId:e.value})
        }
      };

      const findChange = (e) => {
        if (e.target.value == "") {
        setFind(items);
        }
        var searchItems;
        let searchString = e.target.value.trim().toLowerCase();
    
        if (searchString.length > 0) {
          // We are searching. Filter the results.
          //searchItems = searchItems.filter((e) => e.ItemName.toLowerCase().match(searchString));
          searchItems = items.filter((item) => {
            return (
              item?.documentId?.toString().toLowerCase().match(searchString) ||
              item?.companyName?.toString().toLowerCase().match(searchString) 
            );
          });
          if(searchItems.length > 0){
            setFind(searchItems);
          }else{
            setFind(items);
          }
        }
      };

     
    return (
        <div className="container-fluid px-2 mt-3">
          <Search  change={(e) => findChange(e)} excel={() => exportToExcel()} printClick={print} back="#/reports"/>
          <ExcelExport data={find.length ? find : []} headers={headers} type={'other'} ref={excelExportRef} hideContent={true} />
          <Print data={find.length ? find : []} headers={headers}  ref={printRef} query={searchObj} reportName={helper().translate("CASHBOX_BOOK")} />
          <div className="card border-left-primary shadow h-100 mt-1">
            <div className="card-body pt-3 pb-1">
            <div className="row">
                <div className="col-11">
                  <div className="row no-gutters align-items-center">
                    <div className="col row">
                      <div className="col-lg-3 mb-3 mb-md-0 pe-0 d-flex align-items-center">
                        {/* <label className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                              Data e fillimit
                            </label> */}
                          <div style={{width: '5%'}}>
                            <input type="checkbox" name="includeStartDate" checked={findByDate.dateStartDisabled} onChange={(e) => setFindByDate({...findByDate, dateStartDisabled: e.target.checked})} ></input>
                          </div>
                          <div style={{width: '95%'}}>
                            <MDBInput
                            name="dateStart"
                            disabled={!findByDate.dateStartDisabled}
                            className="form-control-sm active"
                            onChange={(e) =>  setFindByDate({...findByDate , dateStart: new Date(e.target.value)})}
                            type="date"
                            label={helper().translate("START_DATE")}
                            value={helper().isValidDate(findByDate.dateStart)  ?  findByDate.dateStart.toISOString().substring(0,10): ''}
                            />
                          </div>
                      </div>
                      <div className="col-lg-3 mb-3 mb-md-0 pe-0 d-flex align-items-center">
                        {/* <label className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                              Data e Mbarimit
                            </label> */}
                        <div style={{width: '5%'}}>
                            <input type="checkbox" name="includeStartDate" checked={findByDate.dateEndDisabled}  onChange={(e) => setFindByDate({...findByDate, dateEndDisabled: e.target.checked})} ></input>
                        </div>
                        <div style={{width: '95%'}}>
                          <MDBInput
                            name="dateEnd"
                            disabled={!findByDate.dateEndDisabled}
                            className="form-control-sm active"
                            onChange={(e) =>  setFindByDate({...findByDate , dateEnd: new Date(e.target.value)})}
                            type="date"
                            label={helper().translate("END_DATE")}
                            value={helper().isValidDate(findByDate.dateEnd)  ?  findByDate.dateEnd.toISOString().substring(0,10): ''}
                          />
                        </div>
                      </div>
                      <div style={{paddingTop: "2px"}} className="col-lg-3 mb-3 mb-md-0 pe-0 ">
                        {/* <label className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                          Depo
                          </label> */}
                        <Select
                          name="cashBox"
                          placeholder={helper().translate("CHOOSE_CASHBOX")}
                          styles={selectStyles}
                          options={cashBoxes}
                          onChange={(e, obj) => selectChange(e, obj)}
                          value={cashBoxes.find(c => c.value === findByDate.cashboxId)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                  <div className="col-md-1 col-12 d-flex align-items-center justify-content-center  justify-content-lg-end">
                        <div>
                          <a className="searchbutton" onClick={() => searchBtn()}>
                            <i className="fas fa-search fa-2x text-dark" />
                          </a>
                        </div>
                  </div>
              </div>
            </div>
        </div>
        <div className="card shadow mt-1">
        <div className="card-header py-2"><h6 className="m-0 font-weight-bold text-primary text-center">{helper().translate("CASHBOX_BOOK")}</h6></div>
          {/* {dataShow ? <div className="card-body table-responsive">
            <table className="table table-hover text-center ">
              <thead>
                <tr>
                  <th className="align-middle">{helper().translate("NR")}</th>
                  <th className="align-middle">{helper().translate("REFERENCE_NO")}</th>
                  <th className="align-middle">{helper().translate("DATE")}</th>
                  <th className="align-middle">{helper().translate("SUBJECT")}</th>
                  <th className="align-middle">{helper().translate("CASHBOX")}</th>
                  <th className="align-middle">{helper().translate("PAYMENT_TYPE")}</th>
                  <th className="align-middle">{helper().translate("DESCRIPTION")}</th>
                  <th className="align-middle">{helper().translate("IN")}</th>
                  <th className="align-middle">{helper().translate("OUT")}</th>
                  <th className="align-middle">Saldo</th>
                </tr>
              </thead>
              <tbody>
                {pageOfItems.map((item, key) => (
                  <tr key={key}>
                    <td style={{width: "50px"}}>{item.no}</td>
                    <td className="text-left ps-5 ">{item.documentId}</td>
                    <td className="text-left ps-5 ">{item.no != 0 ? item.date : ''}</td>
                    <td className="text-left ps-5 ">{item.companyName}</td>
                    <td className="text-left ps-5 ">{item.arka}</td>
                    <td className="text-left ps-5 ">{item.paymentType}</td>
                    <td className="text-left ps-5 ">{item.description}</td>
                    <td className="text-left ps-5 ">{item.debi}</td>
                    <td className="text-left ps-5 ">{item.credi}</td>
                    <td className="text-left ps-3 ">{item.saldo}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <Pagination items={find} onChangePage={(pageOfItems) => setPageOfItems(pageOfItems)} />
          </div>
          : 
          <div style={{height: '300px'}} className=" d-flex align-items-center justify-content-center  "><div><p>{helper().translate("NO_DATA")}</p></div></div>
          } */}
          {loader && <LoaderComponent /> } 
          {!loader ? find.length ? <DataTable data={find} headers={headers} formName="cashBoxBookRpt" /> : <div className="p-5 text-center">{helper().translate("NO_DATA")}</div> : "" }
        </div>
        </div>

    )
}

export default CashBoxBook;
