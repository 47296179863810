import React, { useState, useEffect, useRef } from 'react';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import SubNav from '../../PageComponents/Nav/SubNav'
import AddUser from './AddUser'
import UserConfigurations from './UserConfigurations';
import UserProfile from './UserProfile';
import Permissions from './Permissions';
import ResetPassword from './ResetPassword';
import Loader from 'react-loader-spinner';
import helper from '../../Services/helper';
import globals from '../../Services/globals';
import apiService from '../../Services/apiServices';
import loginService from '../../Services/loginService';
import { data } from 'jquery';

const Administration = (props) => {
    const [roleId, setRoleId] = useState();
    const t = (key) => {
        return helper().translate(key);
    }

    const [loader, setLoader] = useState(false);
    const [mainUser, setMainUser] = useState();
    const [databases, setDatabases] = useState([]);
    const [username, setUsername] = useState();

    useEffect(() => {
        var logedInUser = globals().getLogedInUser();
        setLoader(true);
        if (logedInUser) {
            setUsername(logedInUser.data.username);
            getMainUser(logedInUser.data.username);
            getCompanies(logedInUser.data.username);
            getRole(logedInUser.data.username);
        }
    }, []);

    const getMainUser = async (username) => {
        await loginService().queryMyLogins('user', 'userName=' + username)
            .then((response) => {
                if (response.success) {
                    let u = response.data[0];
                    u.password = "";
                    setMainUser(u)
                }
            });
    }

    const getCompanies = async (username) => {
        const companies = await apiService().getDatabases("getUserDatabases?username=" + username);
        if (companies.success) {
            // console.log(companies)
            setDatabases(companies.data);
            setLoader(false);
        }
        else {
            console.log("Error: " + companies.errorMessage);

        }

        // await api("t3services@t3").get("/getUserDatabases?username=" + username).then((response) => {
        //     console.log(response)
        //     // if (response.success) {
        //     //     var databases = response.data;
        //     //     console.log(databases)
        //     //     // setRequestMade(true);
        //     //     // setDatabases(databases);
        //     //     // setLoader(false);
        //     // }
        //     // else {
        //     //     // setRequestFailed(true);
        //     //     console.log(response.errorMessage)
        //     // }
        // });
    }
    const getRole = async (username) => {
        await apiService().query('userRoles', 'username=' + username)
            .then((response) => {
                if (response.success && response.data && response.data.length > 0) {
                    //return response.data[0].roleId
                    setRoleId(response.data[0].roleId)
                }
            })
    }

    let li = [
        { path: "user-profile", name: t('USER'), icon: "fas fa-users-cog mr-2", component: UserProfile, type: "menu" },
        { path: "administration", name: t('USER_ACCESS'), icon: "fas fa-users-cog mr-2", component: AddUser, type: "menu" },
        { path: "reset-password", name: t('RESET_PASSWORD'), icon: "fas fa-key mr-2", component: ResetPassword, type: "menu" },
        { path: "permissions", name: t('PERMISSIONS_AND_ROLES'), icon: "fas fa-gavel mr-2", component: Permissions, type: "menu" },
        // {path:"veglat", name: t('TOOLS'), icon:"fas fa-fw fa-wrench mr-2", subMenu:[
        //     {path: 'admin/recalculation', name:'Rikalkulimi', icon: "", type:"link"},
        // ], type:"menu"},
        // {path: 'admin/recalculation', name:'Rikalkulimi', icon: "", component: Admin,  type:"link"},
    ]

    const [links, setLinks] = useState(li)

    useEffect(() => {
        const interval = setInterval(() => {
            let li1 = [
                { path: "user-profile", name: t('USER'), icon: "fas fa-user mr-2", component: UserProfile, type: "menu" },
                { path: "administration", name: t('USER_ACCESS'), icon: "fas fa-unlock-alt mr-2", component: AddUser, type: "menu" },
                { path: "reset-password", name: t('RESET_PASSWORD'), icon: "fas fa-key mr-2", component: ResetPassword, type: "menu" },
                { path: "permissions", name: t('PERMISSIONS_AND_ROLES'), icon: "fas fa-gavel mr-2", component: Permissions, type: "menu" },
                // {path:"veglat", name: t('TOOLS'), icon:"fas fa-fw fa-wrench mr-2", subMenu:[
                //     {path: 'admin/recalculation', name:'Rikalkulimi', icon: "", type:"link"},
                // ], type:"menu"},
                // {path: 'admin/recalculation', name:'Rikalkulimi', icon: "", component: Admin,  type:"link"},
            ]
            setLinks(li1)
        }, 1000);
        return () => clearInterval(interval);
    }, [])

    const removeSubscription = async (subscriptionId, username) => {
        if (window.confirm("Deshironi ta hiqni kete kompani?")) {
            await apiService().queryT3Services('removeSubscription', 'username=' + username + "&subscriptionId=" + subscriptionId).then((response) => {
                if (response.success) {
                    getCompanies();
                }
                else {
                    console.log(response.errorMessage)
                }
            });
        }
    }

    return (
        <div className='d-flex'>
            <SubNav links={links} />
            <div className="container-fluid px-2 mt-3">
                <section>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-4">
                                <div className="card mb-4">
                                    <div className="card-body text-center">
                                        <img
                                            src="https://t4.ftcdn.net/jpg/03/49/49/79/360_F_349497933_Ly4im8BDmHLaLzgyKg2f2yZOvJjBtlw5.jpg"
                                            alt="avatar"
                                            className="rounded-circle img-fluid"
                                            style={{ width: 150 }}
                                        />
                                        <h5 className="my-3">{mainUser?.firstName} {mainUser?.lastName}</h5>
                                        <p className="text-muted mb-4">{mainUser?.userName}</p>
                                        {/* <p className="text-muted mb-4">Bay Area, San Francisco, CA</p> */}
                                        <div className="d-flex justify-content-center mb-2">
                                            {/* <button type="button" className="btn btn-primary">
                                                Follow
                                            </button>
                                            <button type="button" className="btn btn-outline-primary ms-1">
                                                Message
                                            </button> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="card mb-4 mb-lg-0">
                                    <div className="card-body p-0">
                                        {loader && <Loader type="ThreeDots" className="text-center w-100" color="#049cdc" height="100" width="100" />}
                                        {!loader &&
                                            <ul className="list-group list-group-flush rounded-3">
                                                <li className="list-group-item d-flex justify-content-between align-items-center p-3">
                                                    Linked Companies
                                                </li>
                                                {databases?.map((c) => (
                                                    <li key={c.Name} className="list-group-item d-flex justify-content-between align-items-center p-3">
                                                        <i className="fas fa-globe fa-lg text-warning" />
                                                        <p className="mb-0 mr-5">{c.Name}</p>
                                                        <i className="fas fa-minus-circle text-danger" onClick={() => removeSubscription(c.Id, c.UserName)}></i>
                                                    </li>
                                                ))}
                                            </ul>}
                                    </div>
                                </div>
                            </div>
                            <Router>
                                <Switch>
                                    {links.map((link, index) => {
                                        return <Route key={index} path={`/${link.path}`} component={link.component} />
                                    })}
                                </Switch>
                            </Router>
                        </div>
                    </div>
                </section>
            </div>
        </div>





        // <div className="d-flex">
        //     <SubNav links={links} />
        //     <div className="w-100 overflow-auto pr-0">
        //         {props.location.pathname === '/administration' && <UserProfile />}
        //         <Router>
        //             <Switch>
        //                 {links.map((link) => {
        //                     return <Route path={`/${link.path}`} component={link.component} />
        //                 })}
        //             </Switch>
        //         </Router>
        //     </div>
        // </div>
    )
}

export default Administration
