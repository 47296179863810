import React, { Component } from 'react'
import { Page, Text, View, Document, StyleSheet, PDFViewer, PDFDownloadLink, Image, Font } from '@react-pdf/renderer';
import apiService from "../../Services/apiServices";
import globals from '../../Services/globals';
import { right } from '@popperjs/core';
import calculate from '../../Services/calculate';
import helper from '../../Services/helper';
import LoaderComponent from '../UI/Components/LoaderComponent';
import config from '../../config/config';

class Invoice2 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sale: {},
            saleDetails: [],
            partner: {},
            saleType: {},
            units: {},
            settings: {},
            saleId: props.match ? props.match.params.id : props.saleId,
            //vatGroup: []
            loader: true
        }
        
    }

    componentDidMount() {
      

        apiService().query('getSale', 'id=' + this.state.saleId)
        .then((response) => {
            if (response.success) {
                var sale = response.data[0];
                apiService()
                .query('getWarehouseWithBranchDetails', 'warehouseId=' + sale.warehouseId)
                .then((response) => {
                    if (response.success) {
                      let warData = response.data[0];
                      sale.warehouseAddress = warData.address;
                      sale.unitNo = warData.unitNo;
                      sale.branchPhone = warData.phone;
                      sale.email = warData.email;
                      sale.totalTvsh = (parseFloat(sale.totalSum) + parseFloat(sale.vatSum));
                      this.setState({
                          sale: sale
                      });
                      this.getSaleDetails(sale.id, sale.partnerId, sale.salesTypeId)
                    } else {
                        console.log('Error:' + response.errorMessage);
                    }
                });
            }
            else {
                console.log('ka gabim: ', response);
            }
        });
            apiService().get('units').then((response) => {
                if (response.success) {
                  this.setState({ units: response.data });
                  
                } else {
                  console.log('Error:' + response.errorMessage);
                }
              });  

                  globals().getSettings().then((response) => {
                    if (response) {
                    let sett = response;
                    this.setState({ settings: sett });
                    }
                })
            
    }

    grupByVat = (items) => {
        let group = items.reduce((r, a) => {
            r[a.vat] = [...r[a.vat] || [], a];
            return r;
        }, {});
        this.vatGroup(group)
    }

    getSaleDetails(saleId, partnerId, salesTypeId) {
        apiService().query('getSaleDetails', 'saleId='+saleId)
            .then((response) => {
                var data = response.data;
                if(response.success){
                    for(var i= 0; i< data.length; i++){ 
                        if(data[i].discount> 0){
                
                          data[i].priceDiscount = calculate().calculateSale('priceDiscount')(data[i].price, data[i].discount);
                          data[i].priceTvsh = calculate().calculateSale('priceTvsh')(data[i].priceDiscount ,data[i].vat);
                          data[i].totalPrice= calculate().calculateSale('totalPrice')(data[i].priceDiscount,data[i].quantity);
                          data[i].totalPriceTvsh= calculate().calculateSale('totalPriceTvsh')(data[i].totalPrice,data[i].vat);
                          // data[i].totalPriceItemTvsh= calculate().calculateSale('totalPriceItemTvsh')(data[i].totalPriceTvsh , data[i].quantity);
                
                        } else{
                          data[i].priceTvsh = calculate().calculateSale('priceTvsh')(data[i].price ,data[i].vat);
                          data[i].totalPrice= calculate().calculateSale('totalPrice')(data[i].price , data[i].quantity );
                          data[i].totalPriceTvsh= calculate().calculateSale('totalPriceTvsh')(data[i].totalPrice,data[i].vat);
                          // data[i].totalPriceItemTvsh= calculate().calculateSale('totalPriceItemTvsh')(data[i].totalPriceTvsh , data[i].quantity);
                
                        }
                      }
                      this.grupByVat(data)
                      this.setState({ saleDetails: data });
                      this.setState({
                        loader: false
                    })
                }
                else{
                    console.log('ka gabim sd: ', response);
                    //this.props.addToast('Ka ndodhur nje gabim!', { appearance: 'error', autoDismiss: true, });
                }
            });
        apiService()
            .query('partner', 'id='+partnerId)
                .then((response) => {
                    if (response.success) {
                        this.setState({
                            partner: response.data[0]
                        });
                        //console.log('Partner: ', response.data);
                    } else {
                        console.log('Error:' + response.errorMessage);
                    }
                });    
            apiService()
                .query('getSaleType', 'id='+salesTypeId)
                    .then((response) => {
                        if (response.success) {
                            this.setState({
                                saleType: response.data[0]
                            });
                        } else {
                            console.log('Error:' + response.errorMessage);
                        }
                    });
    }

    checkColumn = (property, items) => {
        // const isTrue = (currentValue) => currentValue[property];
        // return items?.every(isTrue)
        return true
    }

    vatGroup = (group) => {
        let arr = []
        Object.entries(group).forEach(([key, value]) => {
            arr.push({key: key, value: this.calculateVat(value)})
        })

        this.setState({
            vatGroup: arr
        })
    }
    calculateVat = (items) => {
        return items?.reduce((accumulator, current) => parseFloat(accumulator) + current.vatSum, 0)
    }

    render() {
        Font.register({ family: 'Times-Italic'});
        let styles = StyleSheet.create({
            italicFont: {
                fontFamily:"Times-Italic"
            },
            page: {
                display: 'flex',
                //flexDirection: 'row',
                //alignItems:'baseline',
                backgroundColor: '#fff',
                width: '100%',
                flexDirection: 'row'
            },
            section: {
                //paddingHorizontal: 5,
                flexGrow: 1,
                width: '50%'
            },
            td: {
                marginLeft: 10,
                marginRight: 10
            },
            text: {
                // borderBottom: 1, 
                fontSize: 9,
                paddingTop: 2,
                paddingHorizontal: 2
            },
            text10: {
                fontSize: 8,
                paddingVertical: 2
            },
            pageNumbers: {
                position: 'absolute',
                bottom: 10,
                left: 0,
                right: 0,
                textAlign:'right', 
                fontSize:8,
                borderTop: 1,
                // top: '15%'
                marginLeft:5,
                marginRight:5,
                marginTop: 10,
                marginBottom: -5                
              },
          });
          let sale = this.state.sale;
          let saleDetails = this.state.saleDetails;
          let partner = this.state.partner
          let vatGroup = this.state.vatGroup
          let loader = this.state.loader
          let settings = this.state.settings;

        return (
            
            <div className="pdf my-4">
            {loader && <LoaderComponent type="ThreeDots" className="text-center w-100" color="#2BAD60" height="100" width="100" /> }
            {!loader &&
            <PDFViewer>
                <Document >
                    <Page  size="A4" style={{paddingVertical: 10, paddingHorizontal: 15, marginTop: 5}} wrap>
                        <View style={styles.page}>
                            <View style={styles.section}>
                                <Text style={[styles.text10, {fontSize: 11, fontWeight: 'bold'}]}>{settings.companyName}</Text>
                                <View style={styles.page}>
                                    <View style={styles.section}>
                                        {settings.businessNumber?.length > 2 && <Text style={styles.text10}>{helper().translate("NUI")}: {settings.businessNumber}</Text> }
                                        { settings.fiscalNumber?.length > 2 &&  <Text style={styles.text10}>{helper().translate("FISCAL_NUMBER")}: {settings.fiscalNumber}</Text>}
                                        { settings.vatNumber?.length > 2 &&  <Text style={styles.text10}>{helper().translate("VAT_NUMBER")}: {settings.vatNumber}</Text>}
                                        { settings.accNo?.length > 2 && <Text style={styles.text10}>{helper().translate("BANK_NUMBER")}: {settings.accNo}</Text>}
                                    </View>
                                    <View style={styles.section}>
                                        { sale.unitNo?.length > 2 && <Text style={styles.text}>{helper().translate("UNIT_NUMBER")}: {sale.unitNo}</Text>}
                                        {sale.warehouseAddress?.length > 2 && <Text style={styles.text}>{helper().translate("ADDRESS")}: {sale.warehouseAddress}</Text>}
                                        {sale.email?.length > 2 && <Text style={[styles.text]}>{helper().translate("EMAIL")}: {sale.email}</Text> }
                                        {settings.phoneNo?.length > 2 && <Text style={styles.text}>Tel: {settings.phoneNo}</Text> }
                                        {sale.branchPhone?.length > 2 && <Text style={[styles.text]}>{helper().translate("BRANCHE_PHONE_NUMBER")}: {sale.branchPhone}</Text>} 
                                    </View>
                                </View> 
                                <Text style={[styles.italicFont ,{fontSize: 9, fontWeight: 'bold', fontStyle: 'italic', marginTop: 10}]}>{settings.announcement}</Text>
                            </View>
                            <View style={[styles.section,{display:"flex" , alignItems: "center", justifyContent: "center"}]}>
                                <Image src={settings?.img ? settings?.img : config.noLogo} style={{width: settings?.img ? '120px' : '120px'}} />
                            </View>
                        </View>
                        <hr style={{height:12}}></hr>
                        <View style={[styles.page, {borderTop: 1, marginTop:2, paddingVertical: 5, alignItems: "flex-end"}]}>
                            <View style={[styles.section, {width: '60%', padding: 0}]}>
                                <Text style={[styles.text10, {fontSize: 11, fontWeight: 'bold', width: '100%'}]}>{helper().translate("BUYER")}: {partner.partnerType !== 6 ? partner.companyName : partner.subjectName}</Text>
                                {partner?.partnerType === 6 && <Text style={[styles.text, {fontSize: 12, fontWeight: 'bold', width: '100%'}]}>{helper().translate("UNIT")}: {partner?.companyName }</Text>}
                                <View style={[styles.page, { width: '100%'}]}>
                                    <View style={styles.section}>
                                        { partner.businessNo?.length > 2 &&  <Text style={styles.text10}>{helper().translate("NUI")}: {partner.businessNo}</Text>}
                                        {partner.fiscalNo?.length > 2 && <Text style={styles.text10}>{helper().translate("FISCAL_NUMBER")}: {partner.fiscalNo}</Text> }
                                        { partner.vatNo?.length > 2 && <Text style={styles.text10}>{helper().translate("VAT_NUMBER")}: {partner.vatNo}</Text>}
                                    </View>
                                    <View style={[styles.section, {borderLeft: 1, paddingLeft: 5}]}>
                                        {partner.address?.length > 2 && <Text style={styles.text10}>{helper().translate("ADDRESS")}: {partner.address}</Text>}
                                        {partner.city?.length > 2 &&<Text style={styles.text10}>{helper().translate("CITY")}: {partner.city}</Text>}
                                        {partner.phone?.length > 2 &&<Text style={styles.text10}>{helper().translate("PHONE")}: {partner.phone}</Text>}
                                    </View>
                                </View>
                                <Text style={{border: 1, fontSize: 10, padding: 2, width: '50%', marginTop: 5}}>{helper().translate("DATE")}: {helper().formatDate(sale.date)}</Text>
                            </View>
                            <View style={[styles.section, {width: '40%' , paddingVertical: 5, paddingHorizontal: 20}]}>
                                <Text style={{fontSize: 18, fontWeight: 'bold', textAlign: 'center', backgroundColor: 'gray', paddingVertical: 3, color: '#fff'}}>{sale.salesTypeId == 1 ? helper().translate("SALE_INVOICE") : (sale.salesTypeId == 2) ? 'Faturë '+this.state.saleType.Name : this.state.saleType.Name }</Text>
                                <Text style={{fontSize: 18, fontWeight: 'bold', textAlign: 'center', height: 30}}></Text>
                                <Text style={{fontSize: 18, fontWeight: 'bold', textAlign: 'center', borderBottom: '1px dotted #000'}}>{sale.invoiceNo}</Text>
                            </View>
                        </View>
                        <View  style={{border: 1, marginVertical: 0}}>
                            {/* width 565px */}
                            <View style={[styles.page, { borderBottom: 1, paddingHorizontal: 0, minHeight: '12px' , backgroundColor: "#F1F1F1"}]}>
                                <Text style={[styles.text10, {width: '20px', textAlign:'center', borderRight: 0.5}]}>{helper().translate("NR")}</Text>
                                <Text style={[styles.text10, {width: '80px', textAlign:'center', borderRight: 0.5}]}>{helper().translate("BARCODE")}</Text>
                                <Text style={[styles.text10, {width: '125px', textAlign:'center', borderRight: 0.5, flexGrow: 3}]}>{helper().translate("DESIGNATION")}</Text>
                                <Text style={[styles.text10, {width: '35px', textAlign:'center', borderRight: 0.5}]}>{helper().translate("UNIT")}</Text>
                                {this.checkColumn('quantity', saleDetails) &&
                                    <Text style={[styles.text10, {width: '35px', textAlign:'center', borderRight: 0.5}]}>{helper().translate("QUANTITY")}</Text>
                                }
                                {this.checkColumn('price', saleDetails) &&
                                    <Text style={[styles.text10, {width: '45px', textAlign:'center', borderRight: 0.5}]}>{helper().translate("PRICE")}</Text>
                                }
                                {this.checkColumn('priceTvsh', saleDetails) && settings.withVat === true &&  
                                    <Text style={[styles.text10, {width: '45px', textAlign:'center', borderRight: 0.5}]}>{helper().translate("PRICE_WITH_VAT")}</Text>
                                }
                                {this.checkColumn('discount', saleDetails) &&
                                    <Text style={[styles.text10, {width: '30px', textAlign:'center', borderRight: 0.5}]}>{helper().translate("DISCOUNT")} %</Text>
                                }
                                {this.checkColumn('priceDiscount', saleDetails) &&
                                    <Text style={[styles.text10, {width: '45px', textAlign:'center', borderRight: 0.5}]}>{helper().translate("PRICE_WITH_DISCOUNT")} %</Text>
                                }
                                {this.checkColumn('vat', saleDetails) && settings.withVat === true &&  
                                    <Text style={[styles.text10, {width: '30px', textAlign:'center', borderRight: 0.5}]}>{helper().translate("VAT")} %</Text>
                                }
                                {this.checkColumn('vatSum', saleDetails) && settings.withVat === true &&  
                                    <Text style={[styles.text10, {width: '45px', textAlign:'center', borderRight: 0.5}]}>{helper().translate("VAT_VALUE")}</Text>
                                }
                                {this.checkColumn('totalPriceTvsh', saleDetails) &&
                                    <Text style={[styles.text10, {width: '60px', textAlign:'center'}]}>{ settings.withVat === true ? helper().translate("TOTAL_WITH_VAT") : helper().translate("TOTAL")}</Text>
                                }
                            </View>
                            {saleDetails?.map((item, index) => (
                                <View key={index} style={[styles.page, {borderTop: 1, paddingHorizontal: 0, overflow: 'hidden', height:'auto', backgroundColor: 'transparent'}]}>
                                    <Text style={[styles.text10, {width: '20px', textAlign:'center', borderRight: 0.5}]}>{item.no}</Text>
                                    <Text style={[styles.text10, {width: '80px', textAlign:'center', borderRight: 0.5}]}>{item.barcode}</Text>
                                    <Text style={[styles.text10, {width: '125px', textAlign:'center', borderRight: 0.5, flexGrow: 2}]}>{item.itemName}</Text>
                                    <Text style={[styles.text10, {width: '35px', textAlign:'center', borderRight: 0.5}]}>{(this.state.units.find(u => u.id === item.unitId))?.name}</Text>
                                    {this.checkColumn('quantity', saleDetails) &&
                                        <Text style={[styles.text10, {width: '35px', textAlign:'center', borderRight: 0.5}]}>{item.quantity}</Text>
                                    }
                                    {this.checkColumn('price', saleDetails) &&
                                        <Text style={[styles.text10, {width: '45px', textAlign:'center', borderRight: 0.5}]}>{typeof(item.price) == 'undefined' ? 
                                        item.price : helper().formatNumber(item.price,true)}</Text>
                                    }         
                                    {this.checkColumn('priceTvsh', saleDetails) && settings.withVat === true && 
                                        <Text style={[styles.text10, {width: '45px', textAlign:'center', borderRight: 0.5}]}>{typeof(item.priceTvsh) == 'undefined' ? 
                                        item.priceTvsh :helper().formatNumber(item.priceTvsh,true)  }</Text>
                                    }
                                    {this.checkColumn('discount', saleDetails) &&
                                        <Text style={[styles.text10, {width: '30px', textAlign:'center', borderRight: 0.5}]}>{item.discount}</Text>
                                    }
                                    {this.checkColumn('priceDiscount', saleDetails) &&
                                        <Text style={[styles.text10, {width: '45px', textAlign:'center', borderRight: 0.5}]}>{typeof(item.priceDiscount) == 'undefined' ? 
                                        item.priceDiscount : helper().formatNumber(item.priceDiscount,true)  }</Text>
                                    }
                                    {this.checkColumn('vat', saleDetails) && settings.withVat === true && 
                                        <Text style={[styles.text10, {width: '30px', textAlign:'center', borderRight: 0.5}]}>{item.vat}</Text>
                                    }
                                    {this.checkColumn('vatSum', saleDetails) && settings.withVat === true && 
                                        <Text style={[styles.text10, {width: '45px', textAlign:'center', borderRight: 0.5}]}>{typeof(item.vatSum) == 'undefined' ? 
                                        item.vatSum : helper().formatNumber(item.vatSum,true) }</Text>
                                    }
                                    {this.checkColumn('totalPriceTvsh', saleDetails) &&
                                        <Text style={[styles.text10, {width: '60px', textAlign:'center'}]}>{typeof(item.totalPriceTvsh) == 'undefined' ? 
                                        item.totalPriceTvsh : helper().formatNumber(item.totalPriceTvsh,true)  }</Text>
                                    }
                                </View>
                            ))}
                        </View>
                        <View wrap={false} style={[styles.page, {marginTop: 5, paddingVertical: 5, alignItems: "flex-start"}]}>
                            <View style={[ {width: '65%', padding: 5}]}>
                                <Text style={[styles.text10, {paddingRight: 10}]}>{sale.comment ? sale.comment : ""}</Text>
                            </View>
                            <View style={[ {width: '35%' , paddingVertical: 0, borderLeft: 1}]}>
                                <View style={[styles.page, {borderBottom: 1, borderTop: 1, marginTop: 0, paddingVertical: 0, paddingHorizontal:5, alignItems: "flex-end", borderRight: 1}]}>
                                    <View style={{width: '50%', borderRight: 1}}>
                                        <Text style={[styles.text10, {textAlign:'left'}]}>{helper().translate("TOTAL")}: </Text>
                                    </View>
                                    <View style={{width: '50%'}}>
                                        <Text style={[styles.text10, {textAlign:'right'}]}>{ 
                                        typeof((sale.totalSum )) == 'undefined' ? 
                                        (sale.totalSum) : helper().formatNumber(sale.totalSum) 
                                        }</Text>
                                    </View>
                                </View>
                                {
                                settings.withVat === true &&  vatGroup?.map((item) => (
                                    <View style={[styles.page, {borderBottom: 1, marginTop: 0, paddingVertical: 0, paddingHorizontal:5, alignItems: "flex-end", borderRight: 1}]}>
                                        <View style={{width: '50%', borderRight: 1}}>
                                            <Text style={[styles.text10, {textAlign:'left'}]}>{helper().translate("VAT_VALUE")} ({item.key}%): </Text>
                                        </View>
                                        <View style={{width: '50%'}}>
                                            <Text style={[styles.text10, {textAlign:'right'}]}>{helper().formatNumber(parseFloat(item.value)) }</Text>
                                        </View>     
                                    </View>
                                    ))
                                }
                                 {settings.withVat === true && <View style={[styles.page, {borderBottom: 1, marginTop: 0, paddingVertical: 0, paddingHorizontal:5, alignItems: "flex-end", borderRight: 1}]}>
                                    <View style={{width: '50%', borderRight: 1}}>
                                        <Text style={[styles.text10, {textAlign:'left'}]}>{helper().translate("TOTAL_WITH_VAT")}: </Text>
                                    </View>
                                    {console.log('sale: ', sale)}
                                    <View style={{width: '50%'}}>
                                        <Text style={[styles.text10, {textAlign:'right'}]}>{typeof(sale.totalSum) == 'undefined' ? 
                                    (sale.totalTvsh) : helper().formatNumber(parseFloat(sale.totalTvsh))  }</Text>
                                    </View>
                                </View>}
                            </View>
                        </View> 
                        <View wrap={false} style={[styles.page, { width: '100%', marginTop: 80}]}>
                            <View style={[{textAlign: 'center', width: '50%'}]}>
                                <Text style={[styles.text10, {width: '60%', borderTop: 1, margin: 'auto'}]}>{helper().translate("SUBMITTED")}</Text>
                            </View>
                            <View style={[{textAlign: 'center', width: '50%'}]}>
                                <Text style={[styles.text10, {width: '60%', borderTop: 1, margin:'auto'}]}>{helper().translate("RECEIVED")}</Text>
                            </View>
                        </View>
                        <Text style={[styles.pageNumbers,styles.italicFont]} fixed>{helper().translate("IMPLEMENTED_BY")} Nsum Solution</Text>
                    </Page>
                </Document>
            </PDFViewer>
            }
            </div>
        )
    }
}

export default Invoice2
