import globals from "../Services/globals";
class PurchaseExpense {
    constructor(obj){
        
        if(obj){ 
            this.id = obj.id;
            this.sId = obj.sId;
            this.purchaseId = obj.purchaseId;
            this.no = obj.no;
            this.itemId = obj.itemId;
            this.expenseId = obj.expenseId;
            this.projectId = obj.projectId;
            this.invoiceId = obj.invoiceId;
            this.quantity = obj.quantity;
            this.price =  obj.price;
            this.vat = obj.vat;
            this.vatSum = obj.vatSum;
            this.createdAt = obj.createdAt;
            this.createdBy = obj.createdBy;
            this.changedAt = obj.changedAt;
            this.changedBy = obj.changedBy;
            this.status = obj.status;
         }
        else {
            this.id = 0;
            this.sId = null;
            this.purchaseId = 0;
            this.no = 0;
            this.itemId = null;
            this.expenseId = 0;
            this.projectId = null;
            this.invoiceId = 0;
            this.quantity = 0.0;
            this.price =  0.0;
            this.vat = 0;
            this.vatSum = 0;
            this.createdAt = new Date();
            this.createdBy = null;
            this.changedAt = null;
            this.changedBy = null;
            this.status = 0;
        }
    }
}

export default PurchaseExpense;