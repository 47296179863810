import React, { useState, useEffect } from 'react';
import { MDBInput } from 'mdb-react-ui-kit';
import Select from "react-select";
import apiService from '../../Services/apiServices';
import helper from '../../Services/helper';
import SaleAddPartner from '../../PageComponents/UI/Modal/SaleAddPartner';
import ModelTransportDetail from '../../Models/TransportDetail';
import { data } from 'jquery';

const ImportDetails = (params) => {
    const [partners, setPartners] = useState()
    const obj = new ModelTransportDetail() //{id: 0, no: 1, value: 0.0, partnerId: 0, date: new Date(), comment: ""}
    const [purchase, setPurchase] = useState(params.purchaseData ? params.purchaseData : {});
    const [errors, setErrors] = useState({})
    const [items, setItems] = useState([obj])
    const [showTrasnportDetails, setShowTrasnportDetails] = useState(false)
    const status = { added: 1, deleted: -1, changed: 2, unchanged: 0 };

    const onRowClick = (no) => {
        let rowItem = [...items]
        rowItem.forEach(i => i.editMode = false);
        rowItem[no - 1].editMode = !rowItem[no - 1].editMode;
        setItems(rowItem);
    }

    const addRow = () => {
        let newItem = obj
        newItem.no = Math.max(...items.map(o => o.no), 0) + 1
        const newState = [...items, newItem];
        setItems(newState)
    }

    const deleteRow = (rowId) => {
        let arrayCopy = [...items]
        let item = arrayCopy.find(i => i.no === rowId);
        item.status = status.deleted;
        //arrayCopy = arrayCopy.filter((row) => row.no !== rowId);
        let count = 1;
        arrayCopy.forEach(i => { i.no = count; count++ });
        //setItems([])
        const newArr = [arrayCopy]
        setItems(arrayCopy)
        calculateTransport(arrayCopy)
    }

    const onFieldChange = (index) => (e, a) => {
        let newArr = [...items];
        newArr[index - 1]['status'] = status.changed;
        let prop = !a ? e.target.name : a.name
        newArr[index - 1][prop] = !a ? e.target.value : e.value;
        setItems(newArr)
    }

    const calculateTransport = (objs) => {
        let objects = objs ? objs : items
        objects = objects.filter((row) => row.status !== status.deleted);
        if(objs) {
           setPurchase({ ...purchase, transport: objects.reduce((accumulator, current) => parseFloat(accumulator) + parseFloat(current.invoiceSum), 0) }) 
        }
        //return objects.reduce((accumulator, current) => parseFloat(accumulator) + parseFloat(current.invoiceSum), 0)  

    }

    const total = (ts, trs, ov, dt, ex, vs) => {
        let totalSum = ts ? parseFloat(purchase.totalSum) : 0
        let transport = trs ? parseFloat(purchase.transport) : 0
        let overvalue = ov ? parseFloat(purchase.overvalue) : 0
        let duty = dt ? parseFloat(purchase.duty) : 0
        let excise = ex ? parseFloat(purchase.excise) : 0
        let vatSum = vs ? parseFloat(purchase.vatSum) : 0

        return parseFloat(totalSum + transport + overvalue + duty + excise + vatSum).toFixed(2)
    }

    const total100 = () => {
        let transport = parseFloat(document.getElementsByName('transport100')[0]?.value)
        let overvalue = parseFloat(document.getElementsByName('overvalue100')[0]?.value)
        let duty = parseFloat(document.getElementsByName('duty100')[0]?.value)
        let excise = parseFloat(document.getElementsByName('excise100')[0]?.value)
        let vatSum = parseFloat(document.getElementsByName('vatSum100')[0]?.value)

        return parseFloat(transport + overvalue + duty + excise + vatSum).toFixed(6)
    }

    const toFixed2 = (name, value) => {
        let elem = document.getElementsByName(name);
        if (elem[0] === document.activeElement) {
            return value;
        } else {
            if (params?.pdLength > 1) return parseFloat(value).toFixed(2)
            else return formatAsNumber(parseFloat(value).toFixed(2))
        }
    }

    function formatAsNumber(num) {
        var value = Number(num);
        var res = num.split(".");
        if (res.length == 1 || (res[1].length < 3)) {
            value = value.toFixed(2);
        }
        return value
    }

    const getPartners = () => {
        apiService().get('partners')
            .then((response) => {
                if (response.success) {
                    let records = response.data;
                    records = records.filter(record => record?.supplier)
                    records = records.map(record => ({ value: record.id, label: record.name ? record.name : record.saveAs }))
                    setPartners(records)
                }
            })
    }

    const setTransportItems = () => {
        params.transportDetails?.length ? setItems(params.transportDetails) : setItems([obj])
    }

    const save = () => {
        let errorsArr = {}

        items?.forEach(detail => {
            if (detail.status !== -1) {
                !detail.date ? detail.date = new Date() : '';
                !detail.partnerId ? errorsArr['td_partner_' + detail.no] = 'Zgjidhe' : '';
                !detail.invoiceSum ? errorsArr['td_invoiceSum_' + detail.no] = 'Zgjidhe' : '';
                !detail.invoiceSum ? errorsArr['td_invoiceNo_' + detail.no] = 'Zgjidhe' : '';
            }
        })

        const isEmpty = !Object.values(errorsArr).some(x => x !== null && x !== '');

        setErrors(errorsArr)

        if (isEmpty) {
            // calculateTransport(tra); 
            setShowTrasnportDetails(false);
            params.onTransportDetails();
            params.setTransportDetails(items)
        }

    }

    const styles = {
        container: provided => ({
            ...provided,
            Width: '100%'
        }),
        control: (provided, state) => ({
            ...provided,
            background: '#fff',
            borderColor: '#9e9e9e',
            minHeight: '28.59px',
            height: '28.59px',
            boxShadow: state.isFocused ? null : null,
        }),
        menuPortal: base => ({ ...base, zIndex: 9999 }),
        valueContainer: (provided, state) => ({
            ...provided,
            height: '28.59px',
            padding: '0 6px'
        }),

        input: (provided, state) => ({
            ...provided,
            margin: '0px',
        }),
        indicatorSeparator: state => ({
            display: 'none',
        }),
        indicatorsContainer: (provided, state) => ({
            ...provided,
            height: '28.59px',
        }),
    };

    const calculatePurchasePercentes = () => {
        let transportPercente = parseFloat(purchase.transport && purchase.totalSum > 0 ? purchase.transport * 100 / purchase.totalSum : 0).toFixed(6);
        return transportPercente;
    }

    useEffect(() => {
        getPartners();
    }, [])

    useEffect(() => {
        calculateTransport(params.transportDetails)
    }, [params.transportDetails])

    useEffect(() => {
        let purchase = params.purchaseData;
        if (purchase.id !== 0) {
            setPurchase(purchase);
        }
    }, [params.purchaseData.subTotal])

    useEffect(() => {
        setPurchase(params.purchaseData)
    }, [params.purchaseData])

    useEffect(() => {
        //alert("totalet ndryshun!");
        params.onPurchaseTotalsChange(purchase);
    }, [purchase])

    return (
        <div className='mt-4'>
            {showTrasnportDetails && <>
                <button className="btn btn-primary btn-transport-save position-relative" style={{ right: '70px' }} onClick={() => { setShowTrasnportDetails(false); params.onTransportDetails() }}>{helper().translate('BACK')}</button>
                <button className="btn btn-success btn-transport-save" onClick={() => save()}>{helper().translate('SAVE')}</button>
                <table style={{ overflowX: 'scroll', tableLayout: 'fixed' }} className="table table-hover text-center sales-table purchase-table transpot-table">
                    <thead>
                        <tr>
                            <th style={{ width: '25px' }}>{helper().translate('NU')}.</th>
                            <th style={{ width: '150px' }}>{helper().translate('DATE')}</th>
                            <th style={{ width: '150px' }}>{helper().translate('INVOICE_NO')}</th>
                            <th style={{ width: '200px' }}>{helper().translate('PARTNER')} <br></br><SaleAddPartner onClose="" props="" /></th>
                            <th>{helper().translate('COMMENT')}</th>
                            <th style={{ width: '150px' }}>{helper().translate('SUM')}</th>
                            <th style={{ width: '25px' }}>+</th>
                            <th style={{ width: '25px' }}>-</th>
                        </tr>
                    </thead>
                    <tbody className='pb-5'>
                        {[...items]?.map((item, key) => (item.status !== status.deleted &&
                            <tr key={item.no} >
                                <td>{item.no}</td>
                                {item.editMode ?
                                    <td><MDBInput type="date" value={item.date ? helper().formatDate(item.date, 'yyyy-MM-dd') : (new Date()).toISOString().substring(0, 10)} onChange={onFieldChange(item.no)} name="date" className={`form-control form-control-sm ${errors?.['td_date_' + item.no] ? 'is-invalid mb-0' : ''}`} /></td>
                                    :
                                    <td onClick={() => onRowClick(item.no)}> <span className={`text-center text-${errors?.['td_date_' + item.no] ? 'danger' : 'dark'} small`}>{item.date ? item.date : 'Data'}</span></td>
                                }
                                {item.editMode ?
                                    <td><MDBInput type="text" value={item.invoiceNo} onChange={onFieldChange(item.no)} name="invoiceNo" className={`form-control form-control-sm ${errors?.['td_invoiceNo_' + item.no] ? 'is-invalid mb-0' : ''}`} placeholder="Numri i faturës" /></td>
                                    :
                                    <td onClick={() => onRowClick(item.no)}> <span className={`text-center text-${errors?.['td_invoiceNo_' + item.no] ? 'danger' : 'dark'} small`}>{item.invoiceNo ? item.invoiceNo : 'Nr. Faturës'}</span></td>
                                }
                                {item.editMode ?
                                    <td>
                                        <Select
                                            name="partnerId"
                                            placeholder="Partneri"
                                            value={partners ? partners.find(v => v.value === item.partnerId) : ''}
                                            options={partners}
                                            styles={styles}
                                            onChange={onFieldChange(item.no)}
                                            className={errors?.['td_partner_' + item.no] ? 'is-invalid-s' : ''}
                                        />
                                    </td>
                                    :
                                    <td onClick={() => onRowClick(item.no)}><span className={`text-center text-${errors?.['td_partner_' + item.no] ? 'danger' : 'dark'} small`}>{item.partnerId > 0 ? partners?.find(i => i.value === item.partnerId)?.label : 'Zgjidh Partnerin'}</span></td>
                                }
                                {item.editMode ?
                                    <td><MDBInput textarea value={item.comment} onChange={onFieldChange(item.no)} name="comment" row="1" className="form-control form-control-sm" /></td>
                                    :
                                    <td onClick={() => onRowClick(item.no)}> <span className="text-center text-dark small">{item.comment ? item.comment : 'Komenti'}</span></td>
                                }
                                {item.editMode ?

                                    <td><MDBInput type="number" min="1" value={item.invoiceSum} onChange={onFieldChange(item.no)} name="invoiceSum" className={`form-control form-control-sm ${errors?.['td_invoiceSum_' + item.no] ? 'is-invalid mb-0' : ''}`} /></td>
                                    :
                                    <td onClick={() => onRowClick(item.no)}> <span className={`text-center text-${errors?.['td_invoiceSum_' + item.no] ? 'danger' : 'dark'} small`}>{item.invoiceSum ? item.invoiceSum : 'Shuma'}</span></td>
                                }
                                <td><button className="btn-edit" onClick={() => addRow()}><span className="icon text-white-40"><i className="fas fa-plus-circle"></i></span></button></td>

                                {
                                    item.partnerId ? <td><button onClick={() => deleteRow(item.no)} className="btn-delete"><span className="icon text-white-40"><i className="fas fa-minus-circle"></i></span></button></td> : <td></td>
                                }
                            </tr>
                        ))}
                    </tbody>
                </table>
            </>
            }
            {!showTrasnportDetails &&
                <div className="d-md-flex">
                    {/*Tolali */}
                    <div className="mb-3 calc-item">
                        <div className="col-12 px-0 pr-2">
                            <MDBInput
                                name="totalSum"
                                className="form-control-sm active"
                                type="number"
                                label="Totali i faturës"
                                value={toFixed2('totalSum', purchase.totalSum)}
                                onChange={(e) => setPurchase({ ...purchase, totalSum: e.target.value })}
                            />
                        </div>
                        <div className="col-12 mt-2 px-0 pr-2">
                            <button className="btn btn-secondary w-100 text-capitalize p-1" style={{ top: "-2px", fontSize: '12px', fontWeight: 'bold', letterSpacing: '1px' }} onClick={() => { setShowTrasnportDetails(true); params.onTransportDetails();  setTransportItems() }}>{`${helper().translate('TRANSPORT')} / ${helper().translate('OTHER')}`}</button>
                        </div>
                    </div>
                    <div className="mb-3 calc-item">
                        <div className="col-12 px-0 pr-2">
                            <MDBInput
                                name="transport"
                                className="form-control-sm active"
                                type="number"
                                label={`${helper().translate('TRANSPORT')}/${helper().translate('OTHER')}`}
                                value={toFixed2('transport', purchase.transport)}
                                disabled
                                //value={toFixed2('transport', calculateTransport(params.transportDetails))}
                                onChange={(e) => setPurchase({ ...purchase, transport: e.target.value })}
                            />
                        </div>
                        <div className="col-12 mt-2 px-0 pr-2">
                            <MDBInput
                                name="transport100"
                                className="form-control-sm active"
                                type="text"
                                disabled
                                label="%"
                                value={toFixed2('transport100', parseFloat(purchase.transport * 100 / purchase.totalSum).toFixed(6))}
                            />
                        </div>
                    </div>
                    {/* Mbivlerësimi */}
                    <div className="mb-3 calc-item">
                        <div className="col-12 px-0 pr-2">
                            <MDBInput
                                name="overvalue"
                                className="form-control-sm active"
                                type="number"
                                label={helper().translate('OVERVALUE')}
                                value={toFixed2('overvalue', purchase.overvalue)}
                                onChange={(e) => setPurchase({ ...purchase, overvalue: e.target.value })}
                            />
                        </div>
                        <div className="col-12 mt-2 px-0 pr-2">
                            <MDBInput
                                name="overvalue100"
                                className="form-control-sm active"
                                type="text"
                                label="%"
                                value={toFixed2('overvalue100', parseFloat(purchase.overvalue * 100 / total(true, true)).toFixed(6))}
                            />
                        </div>
                    </div>
                    {/* Dogana */}
                    <div className="mb-3 calc-item">
                        <div className="col-12 px-0 pr-2">
                            <MDBInput
                                name="duty"
                                className="form-control-sm active"
                                type="number"
                                label={helper().translate('DUTY')}
                                value={toFixed2('duty', purchase.duty)}
                                onChange={(e) => setPurchase({ ...purchase, duty: e.target.value })}
                            />
                        </div>
                        <div className="col-12 mt-2 px-0 pr-2">
                            <MDBInput
                                name="duty100"
                                className="form-control-sm active"
                                type="text"
                                label="%"
                                value={toFixed2('duty100', parseFloat(purchase.duty * 100 / total(true, true, true)).toFixed(6))}
                            />
                        </div>
                    </div>
                    {/* Akciza */}
                    <div className="mb-3 calc-item">
                        <div className="col-12 px-0 pr-2">
                            <MDBInput
                                name="excise"
                                className="form-control-sm active"
                                type="number"
                                label={helper().translate('EXCISE')}
                                value={toFixed2('excise', purchase.excise)}
                                onChange={(e) => setPurchase({ ...purchase, excise: e.target.value })}
                            />
                        </div>
                        <div className="col-12 mt-2 px-0 pr-2">
                            <MDBInput
                                name="excise100"
                                className="form-control-sm active"
                                type="text"
                                label="%"
                                value={toFixed2('excise100', parseFloat(purchase.excise * 100 / total(true, true, true, true)).toFixed(6))}
                            />
                        </div>
                    </div>
                    {/* TVSH  */}
                    <div className="mb-3 calc-item">
                        <div className="col-12 px-0 pr-2">
                            <MDBInput
                                name="vatSum"
                                className="form-control-sm active"
                                type="number"
                                label={helper().translate('VAT')}
                                value={toFixed2('vatSum', purchase.vatSum)}
                                onChange={(e) => setPurchase({ ...purchase, vatSum: e.target.value })}
                            />
                        </div>
                        <div className="col-12 mt-2 px-0 pr-2">
                            <MDBInput
                                name="vatSum100"
                                className="form-control-sm active"
                                type="text"
                                label="%"
                                value={toFixed2('vatSum100', parseFloat(purchase.vatSum * 100 / total(true, true, true, true, true)).toFixed(6))}
                            />
                        </div>
                    </div>
                    {/* Total */}
                    <div className="mb-3 calc-item">
                        <div className="col-12 px-0 pr-2">
                            <MDBInput
                                name="totali"
                                className="form-control-sm active"
                                type="number"
                                label={helper().translate('TOTAL')}
                                value={toFixed2('totali', total(true, true, true, true, true, true))}
                                // value={total(true, true, true, true, true, true)}
                            />
                        </div>
                        <div className="col-12 mt-2 px-0 pr-2">
                            <MDBInput
                                name="totali100"
                                className="form-control-sm active"
                                type="text"
                                label="%"
                                value={toFixed2('totali100', total100())}
                            />
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default ImportDetails
