import React, { useState, useEffect } from "react";
import Select from "react-select";
import { MDBInput } from "mdb-react-ui-kit";
import apiService from "../../Services/apiServices";
import { useToasts } from "react-toast-notifications";
import Search from "../../PageComponents/Search/Search_Old";
import { confirmAlert } from "react-confirm-alert";
import helper from "../../Services/helper";
import globals from "../../Services/globals";

function ItemsWithDiscount() {
    const [item, setItem] = useState([]);
    const [find, setFind] = useState([]);
    const [findByDate , setFindByDate] = useState({ partnerId:0, dateStart: null, dateEnd:null , dateStartDisabled: true , dateEndDisabled: true });
    const [partners, setPartners] = useState([]);
    const [dataShow, setDataShow] = useState(true);
    const [userColumns , setUserColumns] = useState([]);
    const [newColumns , setNewColumns] = useState([ "START_DATE" , "END_DATE" , "WAREHOUSE" , "REGISTRATION_DATE" , "REGISTERED_BY", "DATE_OF_MODIFICATION" ,"MODIFIED_BY" ]);
    const [showColMenu , setShowColMenu] = useState(false);
    const { addToast } = useToasts();



    const printRow = (item) => {
      if(item) {
          //window.location.href = `#/printPurchase/${item.id}`
          window.open(`#/print-internal_movement/${item.id}`, 'popUpWindow','height=3000,width=32000,left=100,top=100,right=100,bottom=100,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes')
      }
      else {
          console.log("print isnt working right now please try again");
      }
  }

  const getParnters = () => {
    let options;
     apiService().get('partners').then((response) => {
       if (response.success) {
         let data = response.data;
         let firstItem = {id: 0 , companyName: helper().translate("ALL_PARTNERS")};
        data.unshift(firstItem);
         options = data.map((item, index) => ({
           value: item.id,
           label: item.companyName
         }));
         setPartners(options)
       } else {
         console.log('Error:' + response.errorMessage);
       }
     });
   }

      const getItemsWithDiscount = (fromDelete) => {
        if(fromDelete){
          var dateTimeStart = findByDate.dateStart;
          var dateTimeNow = findByDate.dateEnd;
        }else {
          var getDate = new Date();
          var dateNow = new Date().toISOString().substring(0,10);
          var time = getDate.getHours() + ':' + getDate.getMinutes() + ':' + getDate.getSeconds();
          var dateTimeNow = dateNow+" " + time;
          var dateTimeStart = dateNow +" " + "00:00:00"
        }
        
        var params = `&dateStart=${dateTimeStart}&dateEnd=${dateTimeNow}&partnerId=${findByDate.partnerId}`;
        apiService()
        .query("getDiscountItems", params)
        .then((response) => {
            if (response.success) {
              var count = 1;
              var data = response.data;
                data.forEach(e=> {
                   e.countNumber = count; count++,
                   e.startDate = helper().formatDate(e.startDate),
                   e.endDate = helper().formatDate(e.endDate),
                   e.createdAt = helper().formatDate(e.createdAt),
                   e.changedAt = e.changedAt ?  helper().formatDate(e.changedAt) : ''
                 });
            setFind(data);
            setItem(data);
            }
        }
        )

        setFindByDate({...findByDate , dateStart: dateTimeStart , dateEnd: dateTimeNow});
      };


    useEffect(() => {
        getItemsWithDiscount();
        getParnters();
        let user = globals().getLogedInUser().data.username;
        getUserColumns(user);
    },[])

    const getUserColumns = (user) => {
      apiService().query('userColumns', `username=${user}&formname=itemsWithDiscount`)
          .then(response => {
              if(response.success && response.data.length > 0) {
                  let results = response.data[0].hColumns.split(',')
                  setUserColumns( results);
              }
              else {
              setUserColumns(newColumns);
             let obj = {userName: user, formName: 'itemsWithDiscount', hColumns: newColumns.toString()};
             apiService().post('userColumns', obj)
             .then(response => {
                //  console.log(response)
             })
              }
          })
  }

  const filterRowMenu = () => {
    let menuColOpen =   showColMenu;
    if(menuColOpen){
      menuColOpen = false
    }else{
      
      menuColOpen = true
    }
    setShowColMenu(menuColOpen)
  }

  const filterRowOnEyeClick = (itemToRemove) => {
    let col = [...userColumns] ;
    let findCol = col.find(c => c == itemToRemove);
    if(findCol){
      var result = col.filter(c => c !== itemToRemove);
    }else{
      col.push(itemToRemove)
      var result = col;
    }
    let user = globals().getLogedInUser().data.username;
    if(user){
      let obj = {userName: user, formName: 'itemsWithDiscount', hColumns: result.toString()};
      apiService().put('userColumns', obj)
      .then(response => {
          if(response.success){
            setUserColumns(result);
          }
      })
    }
  
  }

  const checkRowIfVisible = (itemToCheck) => {
    filterRowOnEyeClick(itemToCheck);
    
  }

    const selectStyles = {
        control: (provided, state) => ({
          ...provided,
          background: "#fff",
          borderColor: "#9e9e9e",
          minHeight: "28.59px",
          height: "28.59px",
          boxShadow: state.isFocused ? null : null,
        }),
    
        valueContainer: (provided, state) => ({
          ...provided,
          height: "28.59px",
          padding: "0 6px",
        }),
    
        input: (provided, state) => ({
          ...provided,
          margin: "0px",
        }),
        indicatorSeparator: (state) => ({
          display: "none",
        }),
        indicatorsContainer: (provided, state) => ({
          ...provided,
          height: "28.59px",
        }),
      };
    
      const fieldChange = (e) => {
          if(e.target.name === "dateStart"){
              setFindByDate({...findByDate , dateStart: e.target.value });
          }else
          if(e.target.name === "dateEnd"){
            setFindByDate({...findByDate , dateEnd: e.target.value }); 

        }
      };
      const selectChange = (e,obj) => {
        if (obj.name === "partners"){
            setFindByDate({...findByDate, partnerId:e.value })
        }
      };

      const findChange = (e) => {
        if (e.target.value == "") {
          getItemsWithDiscount();
        }
        var searchPayment;
        let searchString = e.target.value.trim().toLowerCase();
    
        if (searchString.length > 0) {
          // We are searching. Filter the results.
          //searchItems = searchItems.filter((e) => e.ItemName.toLowerCase().match(searchString));
          searchPayment = item.filter((item) => {
            return (
              item?.countNumber?.toString().toLowerCase().match(searchString) ||
              item?.warehouse?.toString().toLowerCase().match(searchString) 
            );
          });
          setFind(searchPayment);
        }
      };

      const alertnOnDelete = (id)=> {
        confirmAlert({
            customUI: ({ onClose }) => {
              return (
                <div className="custom-ui">
                  <h1>{helper().translate("ARE_YOU_SURE")}</h1>
                  <p>{helper().translate("WANT_TO_DELETE_THIS_ITEM_DISCOUNT")}</p>
                  <button
                    onClick={() => {
                      handleDelete(id);
                      onClose();
                    }}
                  >
                    {helper().translate("YES")} , {helper().translate("DELETE")}!
                  </button>
                  <button onClick={onClose}>{helper().translate("NO")}</button>
                </div>
              );
            },
          });
      }

      const searchBtn = () => {

        let startDate = findByDate.dateStart, endDate = findByDate.dateEnd , warehouseFrom = findByDate.warehouseFrom , warehouseTo = findByDate.warehouseTo;

        if(!findByDate.dateStartDisabled) startDate = '1950-02-02';
        if(!findByDate.dateEndDisabled) endDate = '2550-02-02';

        var params = `&dateStart=${startDate}&dateEnd=${endDate}&partnerId=${findByDate.partnerId}`;
        apiService().query('getDiscountItems', params).then((response) => {
            if(response.success){               
              var count = 1;
              var data = response.data;
              data.forEach(e=> {
                e.countNumber = count; count++,
                e.startDate = helper().formatDate(e.startDate),
                e.endDate = helper().formatDate(e.endDate),
                e.createdAt = helper().formatDate(e.createdAt),
                e.changedAt = e.changedAt ?  helper().formatDate(e.changedAt) : ''
              });
              if(data.length == 0){
                setDataShow(false);
              }else{
                setDataShow(true);
              }
              setFind(data);
              setItem(data);
            }else {
              console.log("error" , response.errorMessage)
            }
        })
    };
      
      const handleDelete = (id)=> {
        apiService()
        .query("deleteDiscountITem", "id=" + id)
        .then((response) => {
          if (response.success) {
            getItemsWithDiscount(true);
          } else {
            alert("Error:" + response.errorMessage);
          }
        });
      }
    
      return (
        <div className="container-fluid px-2 mt-3">
          <Search new="#/item-discount/new/0" change={(e) => findChange(e)} />
          <div className="card border-left-primary shadow h-100 mt-1">
            <div className="card-body pt-3 pb-1">
              <div className="row">
                <div className="col-12">
                  <div className="row no-gutters align-items-center">
                    <div className="col row">
                      <div className="col-lg-3 mb-3 mb-md-0 pe-0 d-flex align-items-center">
                        {/* <label className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                              Data e fillimit
                            </label> */}
                          <div style={{width: '5%'}}>
                            <input type="checkbox" name="includeStartDate" checked={findByDate.dateStartDisabled} onChange={(e) => setFindByDate({...findByDate, dateStartDisabled: e.target.checked})} ></input>
                          </div>
                          <div style={{width: '95%'}}>
                            <MDBInput
                            name="dateStart"
                            disabled={!findByDate.dateStartDisabled}
                            className="form-control-sm active"
                            onChange={(e) => fieldChange(e)}
                            type="date"
                            label={helper().translate("START_DATE")}
                            value={findByDate.dateStart == null ? '' : findByDate.dateStart.substring(0,10)}
                            />
                          </div>
                      </div>
                      <div className="col-lg-3 mb-3 mb-md-0 pe-0 d-flex align-items-center">
                        {/* <label className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                              Data e Mbarimit
                            </label> */}
                        <div style={{width: '5%'}}>
                            <input type="checkbox" name="includeStartDate" checked={findByDate.dateEndDisabled}  onChange={(e) => setFindByDate({...findByDate, dateEndDisabled: e.target.checked})} ></input>
                        </div>
                        <div style={{width: '95%'}}>
                          <MDBInput
                            name="dateEnd"
                            disabled={!findByDate.dateEndDisabled}
                            className="form-control-sm active"
                            onChange={(e) => fieldChange(e)}
                            type="date"
                            label={helper().translate("END_DATE")}
                            value={findByDate.dateEnd == null ? '' : findByDate.dateEnd.substring(0,10)}
                          />
                        </div>
                      </div>
                      <div style={{paddingTop: "2px"}} className="col-lg-2 mb-3 mb-md-0 pe-0 ">
                        {/* <label className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                          Depo
                          </label> */}
                        <Select
                          name="partners"
                          placeholder={helper().translate("PARTNER")}
                          styles={selectStyles}
                          options={partners}
                          onChange={(e, obj) => selectChange(e, obj)}
                          value={partners?.find(p => p.value == findByDate.partnerId)}
                        />
                      </div>
                      <div className="col-md-4 col-12 d-flex align-items-center justify-content-center  justify-content-lg-end">
                        <div>
                          <a className="searchbutton" onClick={() => searchBtn()}>
                            <i className="fas fa-search fa-2x text-dark" />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card shadow mt-1">
        <div className="card-header py-2"><h6 className="m-0 font-weight-bold text-primary text-center">{helper().translate("ITEMS_WITH_DISCOUNT")}</h6></div>
          {dataShow ? <div className="card-body table-responsive"  style={{minHeight: "500px"}}>
          {showColMenu ?   <div style={{width: 'auto' , height: 'auto' }} className = "position-relative bg-primary " >
                <div style={{position: "absolute" , top: `${document.getElementById('itemsWithDiscountTr').offsetHeight}px` , left: '20px' , zIndex: '222' }} className="bg-light border border-5 border-dark rounded-2  ">
                <table  className="table table-hover text-left my-1 ">
                  <tbody className="">
                    {newColumns.map((i, key) => (
                      <tr key={key} className="align-middle">
                        <td className="border-0 py-1 px-4 align-middle "><input onChange={() => checkRowIfVisible(i)} checked={userColumns.find(f => f == i) ? true : false}  type="checkbox" className=" bigCheckBox align-middle me-2"></input><span className="fw-bold">{helper().translate(i) }</span> </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                </div>
              </div> : ""}
            <table className="table table-hover text-center ">
              <thead>
                <tr id="itemsWithDiscountTr">
                  <th className="align-middle ">{helper().translate("NR")} <i className="fas fa-ellipsis-v align-right mx-2  mt-1 cursor-pointer"  onClick={() => filterRowMenu()} ></i></th>
                  <th className="align-middle" >{helper().translate("EDIT")}</th>
                  { userColumns?.find( f => f == "START_DATE") ? <th className="align-middle text-truncate">{helper().translate("START_DATE")} <i className="fas fa-eye align-right ms-1  mt-1 cursor-pointer " onClick={() => filterRowOnEyeClick('START_DATE')}></i></th> : "" }  
                  { userColumns?.find( f => f == "END_DATE") ? <th className="align-middle text-truncate">{helper().translate("END_DATE")} <i className="fas fa-eye align-right ms-1  mt-1 cursor-pointer " onClick={() => filterRowOnEyeClick('END_DATE')}></i></th> : "" }  
                  { userColumns?.find( f => f == "WAREHOUSE") ? <th className="align-middle text-truncate">{helper().translate("WAREHOUSE")} <i className="fas fa-eye align-right ms-1  mt-1 cursor-pointer " onClick={() => filterRowOnEyeClick('WAREHOUSE')}></i></th> : "" } 

                  { userColumns?.find( f => f == "REGISTRATION_DATE") ? <th className="align-middle text-truncate">{helper().translate("REGISTRATION_DATE")} <i className="fas fa-eye align-right ms-1  mt-1 cursor-pointer " onClick={() => filterRowOnEyeClick('REGISTRATION_DATE')}></i></th> : "" } 

                  { userColumns?.find( f => f == "REGISTERED_BY") ? <th className="align-middle text-truncate">{helper().translate("REGISTERED_BY")} <i className="fas fa-eye align-right ms-1  mt-1 cursor-pointer " onClick={() => filterRowOnEyeClick('REGISTERED_BY')}></i></th> : "" } 

                  { userColumns?.find( f => f == "DATE_OF_MODIFICATION") ? <th className="align-middle text-truncate">{helper().translate("DATE_OF_MODIFICATION")} <i className="fas fa-eye align-right ms-1  mt-1 cursor-pointer " onClick={() => filterRowOnEyeClick('DATE_OF_MODIFICATION')}></i></th> : "" } 

                  { userColumns?.find( f => f == "MODIFIED_BY") ? <th className="align-middle text-truncate">{helper().translate("MODIFIED_BY")} <i className="fas fa-eye align-right ms-1  mt-1 cursor-pointer " onClick={() => filterRowOnEyeClick('MODIFIED_BY')}></i></th> : "" } 
                  <th className="buttons">{helper().translate("DELETE")}</th>
                </tr>
              </thead>
              <tbody>
                {find.map((item, key) => (
                  <tr key={key}>
                    <td style={{width: "50px"}}>{item.countNumber}</td>
                    <td style={{width: "50px"}}>
                      <a href={"#/item-discount/new/"+item.id}>
                      <button
                        className="btn-edit">
                    <span className="icon text-white-40"><i className="fas fa-edit"></i></span>
                    </button>
                      </a>
                    </td>
                    {userColumns?.find( f => f == "START_DATE")? <td>  { item.startDate }</td> : "" } 
                    {userColumns?.find( f => f == "END_DATE")? <td >  { item.endDate}</td> : "" } 
                    {userColumns?.find( f => f == "WAREHOUSE")? <td >  { item.warehouse }</td> : "" } 
                    {userColumns?.find( f => f == "REGISTRATION_DATE")? <td className="text-left ps-4 ">  { item.createdAt}</td> : "" } 
                    {userColumns?.find( f => f == "REGISTERED_BY")? <td className="text-left ps-4 ">  { item?.createdBy }</td> : "" } 
                    {userColumns?.find( f => f == "DATE_OF_MODIFICATION")? <td className="text-left ps-4 ">  { item.changedAt }</td> : "" } 
                    {userColumns?.find( f => f == "MODIFIED_BY")? <td  className="text-left ps-4 ">  { item.changedBy}</td> : "" } 
                    {/* <td>  
                        <button className="btn-print" onClick={() => printRow(item)}><span className="icon text-white-40"><i className="fas fa-print"></i></span> </button> 
                    </td> */}
                    <td>
                      <a onClick={() => alertnOnDelete(item.id)}>
                        <button className="btn-delete">
                          <span className="icon text-white-40">
                            <i className="fas fa-trash"></i>
                          </span>
                        </button>
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          : 
          <div style={{height: '300px'}} className=" d-flex align-items-center justify-content-center  "><div><p>{helper().translate("NO_DATA")}</p></div></div>
          }
        </div>
        </div>
      );
}
export default ItemsWithDiscount;