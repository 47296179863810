import React, { useState, useEffect, useRef } from 'react'
import { Doughnut } from 'react-chartjs-2';
import apiService from '../../../Services/apiServices';
import helper from '../../../Services/helper';

function getRandomColor() {
    const blue = Math.floor(Math.random() * 128) + 128;
    const red = Math.floor(Math.random() * blue / 2) + blue / 4;
    const green = Math.floor(Math.random() * blue / 2) + blue / 4;
    return "rgb(" + red + ", " + green + ", " + blue + ")";
}
const ChartExpenses = () => {
    const [expenses, setExpenses] = useState();
    const [account, setAccount] = useState();
    const [colors, setColors] = useState();
    const data = {
        labels: account,
        datasets: [
            {
                data: expenses,
                label: helper().translate('PURCHASES'),
                borderColor: colors,
                backgroundColor: colors,
                lineTension: 0.4,
            },
            // {
            //     data: paymentsSales,
            //     label: helper().translate('SALES'),
            //     borderColor: "red",//"rgba(145,216,246,255)",
            //     backgroundColor: "red",//"rgba(145,216,246,255)",
            //     lineTension: 0.4,
            // },
        ],
    };

    const options = {
        maintainAspectRatio: false,
        scales: {
            y: {
                beginAtZero: true,
            },
            x: {
                grid: {
                    display: false
                }
            }
        },
    };
    const getDate = () => {
        var getDate = new Date();
        var dateTimeStart = ((getDate.getFullYear() + "-" + getDate.getMonth() + "-" + "01"));
        var dateTimeEnd = ((getDate.getFullYear() + "-" + (getDate.getMonth() + 1) + "-" + "01"));
        var time = '00:00:00';
        var dateTimeNow = dateTimeEnd + " " + time;
        getExpenses(dateTimeStart, dateTimeNow, 0)
    }
    const getExpenses = (dateStart, dateEnd, projectId) => {
        // console.log(dateStart, dateEnd);
        var params = `&dateStart=${dateStart}&dateEnd=${dateEnd}&project=${projectId}`;
        apiService()
            .query('RptTrialBalanceSheet', params)
            .then((response) => {
                if (response.success) {
                    var data = response.data;
                    var acc = [];
                    var col = [];
                    var exp = [];
                    response.data.filter((e) => e.account.includes("Shpenzim")).map(e => {
                        exp.push(e.debits);
                        acc.push(e.account);
                        col.push(getRandomColor());
                    })
                    // console.log(response.data)

                    setAccount(acc);
                    setExpenses(exp);
                    setColors(col);
                } else {
                    console.log('Error:' + response.errorMessage);
                }
            });
    }

    useEffect(() => {
        getDate();
    }, [])
    return (
        <div>
            <Doughnut data={data} options={options} height="450" />
        </div>
    )
}

export default ChartExpenses
