import React, { useState, useEffect , useRef } from "react";
import Select from "react-select";
import { MDBInput } from "mdb-react-ui-kit";
import apiService from "../../../Services/apiServices";
import { useToasts } from "react-toast-notifications";
import Search from "../../../PageComponents/Search/Search_Old";
import { confirmAlert } from "react-confirm-alert";
import helper from "../../../Services/helper";
import calculate from "../../../Services/calculate";
import Pagination from "../../../PageComponents/UI/Pagination/Pagination";
import SelectByGroup from './../../../PageComponents/UI/Inputs/SelectByGroup'
import ExcelExport from "../../../PageComponents/Excel/ExcelExport";
import Print from "../../../PageComponents/Print/Print";
import DataTable from "../../../PageComponents/UI/Tables/DataTable";
import LoaderComponent from "../../../PageComponents/UI/Components/LoaderComponent";



function ArticleHistory(props) {
    const [findByDate , setFindByDate] = useState({itemId: null , warehouseId: null , dateStart: null, dateEnd:null , dateStartDisabled: true , dateEndDisabled: true });
    const [items, setItems] = useState([]);
    const [pageOfItems, setPageOfItems] = useState([]);
    const [find, setFind] = useState([]);
    const [branches , setBranches] = useState([]);
    const [articleItems , setArticleITems] = useState();
    const [warehouse ,setWarehouse] = useState([]);
    const [dataShow, setDataShow] = useState(true);
    const { addToast } = useToasts();
    const excelExportRef = useRef();
    const [loader , setLoader] = useState(false);
    const printRef = useRef();
    const searchObj = { CompanyBranch: 0, PurchaseType: -1, StartDate: findByDate.dateStart, EndDate: findByDate.dateEnd, WarehouseId: findByDate.warehouseId}


    useEffect(() => {
      
      getDate();
      getWarehouses();
      getArticleItems();
      getBranches();
  },[])

  const exportToExcel = () => {
    // let table = document.getElementById('table-to-xls-1').innerHTML;
    let fileName = helper().translate("ARTICLE_HISTORY");
    // return helper().exportToExcel(table, fileName)
    excelExportRef.current.exportToExcel(fileName)
}

const print = () => {
  // window.open(`#/excel-print?CompanyBranch=${searchQuery.CompanyBranch}&YEAR=${searchQuery.YEAR}&StartMonth=${searchQuery.StartMonth}&EndMonth=${searchQuery.EndMonth}`, 'popUpWindow', 'height=1300,width="100%",left=100,top=100,right=100,bottom=100,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes')
  //setOpen(!open)
  printRef.current.printPreview()
}

const headers = [
  { key: 'no', columnName: helper().translate("NR") ,  dontShowMenu : true },
  { key: 'date', columnName: helper().translate("DATE") },
  { key: 'partnerName', columnName: helper().translate("PARTNER_NAME") , type:"text" },
  { key: 'depo', columnName: helper().translate("WAREHOUSE") , type:"text" },
  { key: 'itemName', columnName: helper().translate("ITEMNAME") , type:"text" },
  { key: 'unit', columnName: helper().translate("UNIT") , type:"text"},
  { key: 'inQty', columnName: helper().translate("IN") , type:"number" },
  { key: 'outQty', columnName: helper().translate("OUT")  , type:"number"},
  { key: 'saldo', columnName: helper().translate("SALDO") , type:"number" },
  { key: 'price', columnName: helper().translate("PRICE"), calculate: true , type:"number"},
  { key: 'vat', columnName: helper().translate("VAT")  , type:"number"},
  { key: 'priceTvsh', columnName: helper().translate("PRICE_WITH_VAT") , type:"number" }
];


  const getItemsHistory =( dateEnd ,  warehouseId) =>{
    setLoader(true);
    let warehouse = warehouseId;
    if(warehouse == null){
      warehouse = 0;
    }
    var params = `&dateEnd=${dateEnd}&warehouseId=${warehouse}`;
    apiService()
    .query('RptGetItemsHistory', params )
      .then((response) => {
        if (response.success) {
          setLoader(false);
          var items = response.data;
          var saldo = 0.0;
          var prevItem = 0;
          for (var i = 0; i < items.length; i++) {
              if (items[i].itemId === prevItem) {
                  saldo = saldo + items[i].quantity;
              } else {
                  saldo = items[i].quantity;
              }
              items[i].saldo = saldo;
              items[i].total = saldo * items[i].price;
              prevItem = items[i].itemId;
          }
          items.forEach(e => {
            e.price = e.price;
            e.date = helper().formatDate(e.date);
            e.priceTvsh = calculate().calculateSale('priceTvsh')(e.price,e.vat);
            e.total = e.outQty > 0 ? ((e.price*e.quantity*-1))  : ((e.price*e.quantity));
            e.totalTvsh = e.outQty > 0 ? ((calculate().calculateSale('priceTvsh')(e.price,e.vat)*e.quantity*-1) ) : ((calculate().calculateSale('priceTvsh')(e.price,e.vat)*e.quantity) )
            
          });
          // items =  [
          //     {no:'1', type:'emriiii'}, 
          //     {no:'2', type:'emriiii22'}
          // ]
          if(items.length > 0){
            setFind(items);
            setItems( items);
            setDataShow(true)
          }else{
            setDataShow(false)
          }
        } else {
          console.log('Error:' + response.errorMessage);
        }
      });
  }

  const getDate = () => {
    var getDate = new Date();
        var dateNow = new Date().toISOString().substring(0,10);
        var time = getDate.getHours() + ':' + getDate.getMinutes() + ':' + getDate.getSeconds();
        var dateTimeNow = dateNow+" " + time;
        let day = `${getDate.getDate()}`.padStart(2, "0")
        let month = `${getDate.getMonth()}`.padStart(2, "0");
        if(getDate.getMonth() == 0){
          var dateTimeStart = (((getDate.getFullYear()-1)+"-"+12+"-"+ day))+ ' ' + "00:00:00";
        }else{
          var dateTimeStart = ((getDate.getFullYear()+"-"+month+"-"+ day))+ ' ' + "00:00:00";
        }

        setFindByDate({...findByDate , dateStart: dateTimeStart , dateEnd: dateTimeNow});
  }


    const getWarehouses = () => {
      apiService()
        .get("warehouses")
        .then((response) => {
          if (response.success) {
            let data = response.data;
            setWarehouse(data);
          } else {
            alert("Error: getting warehouses" + response.errorMessage);
          }
        });
  }

  const getBranches = () => {
    apiService().get('branches')
        .then((response)=> {
            if(response.success) {
                let records = response.data;
                setBranches(records);
            }
        })
  }

    const getArticleItems = () => {
      let options;
      apiService().get('items').then((response) => {
        if (response.success) {
          let data = response.data.filter(d => d.itemGoods == true);
          options = data.map((item, index) => ({
            value: item.id,
            label: item.itemName
          }));
          setArticleITems(options)
        } else {
          console.log('Error:' + response.errorMessage);
        }
      });
    }

    const searchBtn = () => {

      getItemsHistory( findByDate.dateEnd , findByDate.warehouseId)
   
    }

    const selectStyles = {
        control: (provided, state) => ({
          ...provided,
          background: "#fff",
          borderColor: "#9e9e9e",
          minHeight: "28.59px",
          height: "28.59px",
          boxShadow: state.isFocused ? null : null,
        }),
    
        valueContainer: (provided, state) => ({
          ...provided,
          height: "28.59px",
          padding: "0 6px",
        }),
    
        input: (provided, state) => ({
          ...provided,
          margin: "0px",
        }),
        indicatorSeparator: (state) => ({
          display: "none",
        }),
        indicatorsContainer: (provided, state) => ({
          ...provided,
          height: "28.59px",
        }),
      };
    const fieldChange = (e) => {
        if(e.target.name === "dateStart"){
            setFindByDate({...findByDate , dateStart: e.target.value });
        }else
        if(e.target.name === "dateEnd"){ 
          setFindByDate({...findByDate , dateEnd: e.target.value });
         
      }
    };

    const selectChange = (e,obj) => {
        if (obj.name === "item"){
            setFindByDate({...findByDate, itemId:e.value})
        }else if (obj.name === "warehouseId"){
          setFindByDate({...findByDate, warehouseId:e.value})
        }
      };

      const findChange = (e) => {
        if (e.target.value == "") {
        setFind(items);
        }
        var searchItems;
        let searchString = e.target.value.trim().toLowerCase();
    
        if (searchString.length > 0) {
          // We are searching. Filter the results.
          //searchItems = searchItems.filter((e) => e.ItemName.toLowerCase().match(searchString));
          searchItems = items.filter((item) => {
            return (
              item?.partnerName?.toString().toLowerCase().match(searchString) ||
              item?.invoice_num?.toString().toLowerCase().match(searchString) ||
              item?.itemName?.toString().toLowerCase().match(searchString) 
            );
          });
          if(searchItems.length > 0){
            setFind(searchItems);
          }else{
            setFind(items);
          }
        }
      };

    return (
        <div className="container-fluid px-2 mt-3">
          <Search  change={(e) => findChange(e)} excel={() => exportToExcel()} printClick={print} back="#/reports"/>
          <ExcelExport data={find.length ? find : []} headers={headers} type={'other'} ref={excelExportRef} hideContent={true} />
          <Print data={find.length ? find : []} headers={headers}  ref={printRef} query={searchObj} reportName={helper().translate("ARTICLE_HISTORY")}  />
          <div className="card border-left-primary shadow h-100 mt-1">
            <div className="card-body pt-3 pb-1">
            <div className="row">
            <div className="col-11">
                  <div className="row no-gutters align-items-center">
                    <div className="col row">
                      <div className="col-lg-3 mb-3 mb-md-0 pe-0 d-flex align-items-center">
                        {/* <label className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                              Data e fillimit
                            </label> */}
                          <div style={{width: '5%'}}>
                            <input type="checkbox" name="includeStartDate" checked={findByDate.dateStartDisabled} onChange={(e) => setFindByDate({...findByDate, dateStartDisabled: e.target.checked})} ></input>
                          </div>
                          <div style={{width: '95%'}}>
                            <MDBInput
                            name="dateStart"
                            disabled={!findByDate.dateStartDisabled}
                            className="form-control-sm active"
                            onChange={(e) => fieldChange(e)}
                            type="date"
                            label={helper().translate("START_DATE")}
                            value={findByDate.dateStart == null ? '' : findByDate.dateStart.substring(0,10)}
                            />
                          </div>
                      </div>
                      <div className="col-lg-3 mb-3 mb-md-0 pe-0 d-flex align-items-center">
                        {/* <label className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                              Data e Mbarimit
                            </label> */}
                        <div style={{width: '5%'}}>
                            <input type="checkbox" name="includeStartDate" checked={findByDate.dateEndDisabled}  onChange={(e) => setFindByDate({...findByDate, dateEndDisabled: e.target.checked})} ></input>
                        </div>
                        <div style={{width: '95%'}}>
                          <MDBInput
                            name="dateEnd"
                            disabled={!findByDate.dateEndDisabled}
                            className="form-control-sm active"
                            onChange={(e) => fieldChange(e)}
                            type="date"
                            label={helper().translate("END_DATE")}
                            value={findByDate.dateEnd == null ? '' : findByDate.dateEnd.substring(0,10)}
                          />
                        </div>
                      </div>
                      <div style={{paddingTop: "2px"}} className="col-lg-3 mb-3 mb-md-0 pe-0 ">
                        {/* <label className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                          Depo
                          </label> */}
                        <Select
                          name="item"
                          placeholder={helper().translate("ITEM")}
                          styles={selectStyles}
                          options={articleItems}
                          onChange={(e, obj) => selectChange(e, obj)}
                          value={articleItems?.find(a=> a.value == findByDate.itemId)}
                        />
                      </div>
                      <div style={{paddingTop: "2px"}} className="col-lg-3 mb-3 mb-md-0 pe-0 ">
                        {/* <label className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                          Depo
                          </label> */}
                        {/* <Select
                          name="warehouseId"
                          placeholder="Depo"
                          styles={selectStyles}
                          options={warehouse}
                          onChange={(e, obj) => selectChange(e, obj)}
                          value={warehouse?.find(w => w.value == findByDate.warehouseId)}
                          
                        /> */}
                          <SelectByGroup 
                          name="warehouseId"
                          parent={branches} 
                          child={warehouse} 
                          childParentId={"branchId"} 
                          firstItemLabel={helper().translate("ALL_WAREHOUSES")}
                          placeholder={"Depo"}
                          styles={selectStyles}
                          onChange={(e, obj) => selectChange(e, obj)}
                          value={findByDate.warehouseId}
                          />
                      </div>
                    </div>
                  </div>
                </div>
                  <div className="col-md-1 col-12 d-flex align-items-center justify-content-center  justify-content-lg-end">
                        <div>
                          <a className="searchbutton" onClick={() => searchBtn()}>
                            <i className="fas fa-search fa-2x text-dark"  />
                          </a>
                        </div>
                  </div>
              </div>
            </div>
        </div>
        <div className="card shadow mt-1">
        <div className="card-header py-2"><h6 className="m-0 font-weight-bold text-primary text-center">{helper().translate("ARTICLE_HISTORY")}</h6></div>
          {/* {dataShow ? <div className="card-body table-responsive">
            <table className="table table-hover text-center ">
              <thead>
                <tr>
                  <th className="align-middle">{helper().translate("NR")}</th>
                  <th className="align-middle">{helper().translate("EDIT")}</th>
                  <th className="align-middle">{helper().translate("INVOICE_NO")}</th>
                  <th className="align-middle">{helper().translate("DATE")}</th>
                  <th className="align-middle">{helper().translate("PARTNER_NAME")}</th>
                  <th className="align-middle">{helper().translate("WAREHOUSE")}</th>
                  <th className="align-middle">{helper().translate("BARCODE")}</th>
                  <th className="align-middle">{helper().translate("ITEMNAME")}</th>
                  <th className="align-middle">{helper().translate("UNIT")}</th>
                  <th className="align-middle">{helper().translate("IN")}</th>
                  <th className="align-middle">{helper().translate("OUT")}</th>
                  <th className="align-middle">{helper().translate("SALDO")}</th>
                  <th className="align-middle">{helper().translate("PURCHASEPRICE")}</th>
                  <th className="align-middle">{helper().translate("SALEPRICE")}</th>
                  <th className="align-middle">{helper().translate("DISCOUNT")}</th>
                  <th className="align-middle">{helper().translate("SALEPRICE_WITH_DISCOUNT")}</th>
                  <th className="align-middle">{helper().translate("VAT")}</th>
                  <th className="align-middle">{helper().translate("SALEPRICE_WITH_DISCOUNT_VAT")}</th>
                  <th className="align-middle">{helper().translate("TOTAL")}</th>
                  <th className="align-middle">{helper().translate("TOTAL_WITH_VAT")}</th>
                </tr>
              </thead>
              <tbody>
                {pageOfItems.map((item, key) => (
                  <tr key={key}>
                    <td style={{width: "50px"}}>{item.no}</td>
                    <td  style={{width: "50px"}}>
                      <a href={item.type == "Sale" ? "#/sale/"+item.invoice_id: "#/purchase/"+item.invoice_id}> 
                      <button
                        className="btn-edit">
                    <span className="icon text-white-40"><i className="fas fa-edit"></i></span>
                    </button>
                      </a>
                    </td>
                    <td className="text-left ps-3 ">{item.invoice_num}</td>
                    <td>{item.date}</td>
                    <td className="text-left ps-3 ">{item.partnerName}</td>
                    <td className="text-left ps-2 ">{item.depo}</td>
                    <td className="text-left ps-3 ">{item.barcode}</td>
                    <td className="text-left ps-5 ">{item.itemName}</td>
                    <td className="text-left ps-3 ">{item.unit}</td>
                    <td className="text-left ps-3 ">{helper().formatNumber(item.inQty)}</td>
                    <td className="text-left ps-4 ">{helper().formatNumber(item.outQty)}</td>
                    <td className="text-left ps-3 ">{helper().formatNumber(item.saldo)}</td>
                    <td className="text-left ps-5 ">{item.purchaseprice}</td>
                    <td className="text-left ps-5 ">{item.saleprice}</td>
                    <td className="text-left ps-5 ">{helper().formatNumber(item.discount) + "%"}</td>
                    <td className="text-left ps-5 ">{item.salesPriceWithDiscount}</td>
                    <td className="text-left ps-5 ">{helper().formatNumber(item.vat) + "%"}</td>
                    <td className="text-left ps-4 ">{item.salesPriceWithDiscountVat}</td>

                    <td className="text-left ps-5 ">{item.total}</td>
                    <td className="text-left ps-5 ">{item.totalWithVat}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <Pagination items={find} onChangePage={(pageOfItems) => setPageOfItems(pageOfItems)} />
          </div>
          : 
          <div style={{height: '300px'}} className=" d-flex align-items-center justify-content-center  "><div><p>{helper().translate("NO_DATA")}</p></div></div>
          } */}
          {loader && <LoaderComponent /> } 
          {!loader ? find.length ? <DataTable data={find} headers={headers} formName="articleHistoryRpt" /> : <div className="p-5 text-center">{helper().translate("NO_DATA")}</div>: "" }
        </div>
        </div>

    )
}

export default ArticleHistory;
