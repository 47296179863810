import React, { useState, useEffect, useRef , useContext } from 'react';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import SubNav from '../../PageComponents/Nav/SubNav';
import Partners from '../Partners/Partners';
import Partner from '../Partners/Partner';
import Items from '../Items/Items';
import Item from '../Items/Item';
import ReferenceData from '../../PageComponents/ReferenceData/ReferenceData';
import Bank from './Bank';
import CashBox from './CashBox';
import TermsOfPayments from './TermsOfPayments';
import helper from '../../Services/helper';
import ItemsWithDiscount from './ItemsWithDiscount';
import ItemDiscount from './ItemDiscount';
import UserPermissionContext from '../Context/UserPermissionContext';

const Registration = (props) => {

    const {userPermission} = useContext(UserPermissionContext);
    const t = (key) => {
        return helper().translate(key);
    }

    const links = [
        {path:"partners", name: t('PARTNERS'), icon:"fas fa-fw fa-handshake mr-2", component: Partners, subMenu: [
            {path: 'active', name: t('ACTIVE_PARTNERS'), icon: "", type:"hash"},
            {path: 'inactive', name:t('INACTIVE_PARTNERS'), icon: "", type:"hash"}
        ], type:"menu"},
        // {path: 'activePartners', name:'Parnerët aktiv', icon: "", type:"link", component: Partners},
        // {path: 'inactivePartners', name:'Parnerët joaktiv', icon: "", type:"link", component: Partners},
        {path:"partner/:id", name: t('PARTNERS'), icon:"fas fa-fw fa-handshake mr-2", component: Partner, type:"link"},
        {path:"partner", name: t('PARTNERS'), icon:"fas fa-fw fa-handshake mr-2", component: Partner, type:"link"},
        {path:"items", name: t('ITEMS'), icon:"fas fa-fw fa-layer-group mr-2", component: Items, subMenu:[
            {path: 'itemGoods', name: t('ITEM_GOODS'), icon: "", type:"hash"},
            {path: 'service', name: t('SERVICE'), icon: "", type:"hash"},
            // {path: 'expense', name: t('EXPENSE'), icon: "", type:"hash"},
            {path: 'inventoryItem', name: t('INVENTORY_ITEM'), icon: "", type:"hash"},
            {path: 'material', name: t('MATERIAL'), icon: "", type:"hash"},
            {path: 'assembled', name: t('ASSEMBLED'), icon: "", type:"hash"},
            // {path: 'production', name: t('PRODUCTION'), icon: "", type:"hash"},
            {path: 'expired', name: t('EXPIRED'), icon: "", type:"hash"},
        ], type:"menu"},
        {path:"item/:id", name:"", icon:"fas fa-fw fa-handshake mr-2", component: Item, type:"link"},
        {path:"item", name:"", icon:"fas fa-fw fa-handshake mr-2", component: Item, type:"link"},
        {path:"categories", name: t('CATEGORIES'), icon:"fas fa-th-large fa-layer-group mr-2", component: ReferenceData, type:"menu"},
        {path:"units", name: t('UNITS'), icon:"fas fa-th-list fa-layer-group mr-2", component: ReferenceData, type:"menu"},
        {path:"cashBoxes", name: t('CASHBOXES'), icon:"fas fa-cash-register mr-2", component: CashBox, type:"menu"},
        {path:"banks", name: t('BANKS'), icon:"fas fa-money-check-alt mr-2", component: Bank, type:"menu"},
        {path:"terms-of-payments", name: t('TERMS_OF_PAYMENTS'), icon:"fas fa-money-check-alt mr-2", component: TermsOfPayments, type:"menu"},
        {path:"items-with-discount", name: t("ITEMS_DISCOUNTS"), icon:"fas fa-money-check-alt mr-2", component: ItemsWithDiscount, type:"menu"},
        {path:"item-discount/new/:id", name:"item Discount", icon:"fas fa-money-bill mr-2", component: ItemDiscount, type:"link"},
    ]

    let indexOFItems = links.findIndex((l) => l.name === t('ITEMS'))
    
    if(userPermission?.find((f)=> f.moduleName === 'ITEMS')?.allowView === 0){
        links[indexOFItems].disabled = true;
    }

    return (
        <div className="d-flex">
            <div className="p-0 position-relative sub-menu">
                <SubNav links={links} />
            </div>
            <div className="w-100 overflow-auto pr-0">
                {props?.location?.pathname === '/administration' && <AddUser />}
                <Router>
                    <Switch>
                        {links.map((link, index)=>{
                            return <Route key={index} path={`/${link.path}`} component={link.component} />
                        })}
                    </Switch>
                </Router>
            </div>
        </div>
    )
}

export default Registration
