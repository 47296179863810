import React, { useState, useEffect } from 'react'
import Company from './Company'
import Invoice from './Invoice';
import Tabs from '../../PageComponents/Tabs/Tabs'
import HeaderTools from '../Header/HeaderTools';
import apiService from '../../Services/apiServices';
import { useToasts } from 'react-toast-notifications';
import Branch from './Branch';
import Warehouse from './Warehouse';
import Project from './Project';
import DocumentNumber from './DocumentNumber';
import helper from '../../Services/helper';
import globals from '../../Services/globals';


const Settings = (props) => {
    const { addToast } = useToasts();

    const [settings, setSettings] = useState();
    
    const save = () => {
        console.log(settings)
        apiService().put('settings', settings)
            .then((response)=>{
                if(response.success){
                    responseMessage(response, 'Updated Successfully')
                    globals().getSettings(true);
                }
            });
    }

    const responseMessage = (response, message) => {
        if(response.success){
            //alert('success')
            //getSettings()
            addToast( message, { appearance: 'success', autoDismiss: true, });
        }
        else{
            addToast(response.errorMessage, { appearance: 'error', autoDismiss: true, });
        }
    }
    
    const t = (key) => {
        return helper().translate(key);
    }

    useEffect(() => {
        document.getElementById('navi')?.classList.add("nav-mobile")
        return () => {
            if(document.getElementById('subNav')) {
                document.getElementById('navi')?.classList.remove("nav-mobile")
            } 
        }
    }, [])

    const [modal, setModal] = useState(false);

    let tabs = [
        {
            id: 1,
            title: t('COMPANY'),
            body: <Company setSettings={setSettings} save={save} sett={settings} props={props} />,
            show: true
        },
        {
            id:2,
            title: t('BRANCHES'),
            body: <Branch modal={modal} setSettings={setSettings} sett={settings} />,
            show: true
        },
        {
            id:3,
            title: t('WAREHOUSES'),
            body: <Warehouse modal={modal} setSettings={setSettings} sett={settings} />,
            show: true
        },
        {
            id:4,
            title: t('PROJECTS'),
            body: <Project modal={modal} setSettings={setSettings} sett={settings} />,
            show: true
        },
        {
            id:5,
            title: t('DOCUMENT_NUMBER'),
            body: <DocumentNumber save={save} modal={modal} setSettings={setSettings} sett={settings} />,
            show: true
        },
        {
            id:6,
            title: t('INVOICES_TEMPLATES'),
            body: <Invoice setSettings={setSettings} save={save} sett={settings} />,
            show: true
        },

    ]

    return (
        <div className="container-fluid px-2 mt-5">

            {/* <HeaderTools newHandle={() => setModal(prev => prev + 1)} save={() => { save() }} /> */}
            <Tabs tabs={tabs} />
        </div>
    )
}

export default Settings
