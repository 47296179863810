import React, { Component } from 'react'
import { render } from 'react-dom';
import apiService from '../../Services/apiServices'

class ItemImage extends Component {
    constructor(props) {
        super(props);
        this.showState = this.showState.bind(this);
        this.uploadFile = this.uploadFile.bind(this);
        this.state = {
            itemId: props?.props?.match?.params?.id ? props.props.match.params.id : 0,
            item: '',
            buffer: '',
            blob: {
                itemsId: props?.props?.match?.params?.id ? props.props.match.params.id : 0,
                name: 'test',
                blobData: ''
            }
        }
    }

    componentDidMount() {
        // console.log('foto: ', this.state.itemId)

        if(this.state.itemId) {
            this.getItem('getItemBlobs', 'itemId', this.state.itemId)   
        }

    }
    showState() {
        console.log('staaateee: ', this.state)
    }

    getItem(queryName, paramName, paramValue) {
        apiService()
            .query(queryName, paramName+'='+paramValue)
                .then((response) => {
                    if (response.success) {
                        this.setState({
                            item: response.data[0],
                        });  
                        //console.log('itemiii: ', this.state.item)
                        if(response.data.length > 0) {
                            this.arrayBufferToBase64(response.data[0].blobData)
                        }                        
                    } else {
                        console.log('Error:' + response.errorMessage);
                    }
                });  
    }

    arrayBufferToBase64( buffer ) {
        var binary = '';
        var bytes = new Uint8Array( buffer.data );
        var len = bytes.byteLength;
        for (var i = 0; i < len; i++) {
            binary += String.fromCharCode( bytes[ i ] );
        }
        //console.log('buff', buffer.data );
        //console.log('test',  window.btoa( binary ) );

        var data = window.btoa( binary );
        let blob = this.state.blob
        blob['blobData'] = 'data:image/bmp;base64,'+data;
        this.setState(blob); 
    }

    uploadFile = (e) => {
        var inputElement = e.target;
        //console.log('inp: ', e.target.files);
        var file = inputElement.files[0];
        var reader = new FileReader();
        let that = this;
        let blob = this.state.blob
        reader.onloadend = function() {
            //console.log('Encoded Base 64 File String:', reader.result);
            var data=(reader.result).split(',')[1];
            blob['blobData'] = 'data:image/bmp;base64,'+data;
            that.setState(blob);
            //console.log('buuuu: ', data)  
        }
        reader.readAsDataURL(file);         
    }

    storeBlob = () => {
        // console.log('obja: ', this.state.blob);
        apiService().execute( 'insertItemBlobs', this.state.blob, 'post')
            .then((response) => {
                if(response.success){
                    console.log('me sukses: ', response);
                    //this.props.addToast('Success!')
                    //this.props.addToast( st ? 'Saved Successfully' : 'Updated Successfully', { appearance: 'success', autoDismiss: true, });
                }
                else{
                    console.log('ka gabim: ', response);
                    //this.props.addToast('Ka ndodhur nje gabim!', { appearance: 'error', autoDismiss: true, });
                }
            });
    }

    render() {
        return (
            <div className="p-5">
                <button type="button" onClick={this.storeBlob} className="btn btn-primary float-right">Save</button>
                <input type="file" name="image" onChange={this.uploadFile}/>
                <img className="col-md-12 mt-3 p-0" src={this.state.blob.blobData}></img>
            </div>
        )
    }
}

export default ItemImage;