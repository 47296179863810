import React, { useState, useEffect , useRef } from "react";
import Select from "react-select";
import { MDBInput } from "mdb-react-ui-kit";
import apiService from "../../../Services/apiServices";
import { useToasts } from "react-toast-notifications";
import Search from "../../../PageComponents/Search/Search_Old";
import globals from "../../../Services/globals";
import { confirmAlert } from "react-confirm-alert";
import helper from "../../../Services/helper";
import calculate from "../../../Services/calculate";
import Pagination from "../../../PageComponents/UI/Pagination/Pagination";
import SelectByGroup from './../../../PageComponents/UI/Inputs/SelectByGroup'
import ExcelExport from "../../../PageComponents/Excel/ExcelExport";
import Print from "../../../PageComponents/Print/Print";



function CashFlow(props) {


    return (
        <div className="container-fluid px-2 mt-3">
          <Search  back="#/reports"/>
         <div className="card shadow">
            <div className="card-header border-left-primary py-2"><h6 className="m-0 font-weight-bold text-primary text-center">{helper().translate("CASH_FLOW")}</h6></div>
         </div>
        <div className="card border-left-primary shadow h-100 mt-1 pt-1 pb-3 text-dark">
          <div className="container">
            <div className="row font-weight-bold my-4" >
              <div className="col-12  d-flex justify-content-center justify-content-md-start text-center ">
              <h6 className="m-0 w-100 font-weight-bold text-primary text-center ">Pasqyra e rrjedhjes së parasë për vitin që përfundon me:</h6>
              </div>
            </div>

            <div className="row mt-2 font-weight-bold inputMinWitdth" >
              <div className=" col-12 col-md-5 offset-md-1 d-flex justify-content-center justify-content-md-start ">
              </div> 
              <div className="col-6 col-md-3  d-flex justify-content-center  ">
                <label><p className="font-weight-bold text-dark"><u>{`31 dhjetor 2021`}</u></p></label>
              </div>
              <div className="col-6 col-md-3  d-flex justify-content-center   ">
              <label><p className="font-weight-bold text-dark"><u>{`31 dhjetor 2020`}</u></p></label>
              </div>
            </div>

            <div className="row mt-4  " >
                <div className="col-12 col-md-5 offset-md-1 d-flex justify-content-center justify-content-md-start">
                  <div  className="text-left">
                    <p className="font-weight-bold text-dark m-0">Rrjedha e parasë nga aktivitetet operative</p>
                  </div>
                </div>
            </div>

            <div className="row mt-1 inputMinWitdth" >
                <div className="col-12 col-md-5 offset-md-1 d-flex justify-content-center justify-content-md-start">
                  <div  className="text-left">
                    <p>Fitimi / (humbja) i / e vitit</p>
                  </div>
                </div>
                <div className="col-6 col-md-3  d-flex justify-content-center justify-content-md-start ">
                    <input type="number" className="form-control form-control-sm font-weight-bold text-right " disabled ></input>
                </div>
                <div className="col-6 col-md-3  d-flex justify-content-center justify-content-md-start ">
                    <input type="number" className="form-control form-control-sm font-weight-bold text-right " disabled ></input>
                </div>
            </div>

            <div className="row mt-2  " >
                <div className="col-12 col-md-5 offset-md-1 d-flex justify-content-center justify-content-md-start">
                  <div  className="text-left">
                    <p className="font-weight-bold text-dark m-0">Rregullimet për zërat jo në para të shpenzimeve dhe të hyrave</p>
                  </div>
                </div>
            </div>
            <div className="row"  >
              <div className="col-12 col-md-11 offset-md-1 text-dark">
                <hr className="m-0 hrSolid"></hr>
              </div>
            </div>

            <div className="row mt-2  inputMinWitdth" >
                <div className="col-12 col-md-5 offset-md-1 d-flex justify-content-center justify-content-md-start">
                  <div  className="text-left">
                    <p>Zhvlerësimi i pronës, pajisjeve dhe impianteve</p>
                  </div>
                </div>
                <div className="col-6 col-md-3  d-flex justify-content-center justify-content-md-start ">
                    <input type="number" className="form-control form-control-sm font-weight-bold text-right " disabled ></input>
                </div>
                <div className="col-6 col-md-3  d-flex justify-content-center justify-content-md-start ">
                    <input type="number" className="form-control form-control-sm font-weight-bold text-right " disabled ></input>
                </div>
            </div>
            <div className="row mt-1  inputMinWitdth" >
                <div className="col-12 col-md-5 offset-md-1 d-flex justify-content-center justify-content-md-start">
                  <div  className="text-left">
                    <p>Dëmtimi i pronës, pajisjeve dhe impianteve</p>
                  </div>
                </div>
                <div className="col-6 col-md-3  d-flex justify-content-center justify-content-md-start ">
                    <input type="number" className="form-control form-control-sm font-weight-bold text-right " disabled ></input>
                </div>
                <div className="col-6 col-md-3  d-flex justify-content-center justify-content-md-start ">
                    <input type="number" className="form-control form-control-sm font-weight-bold text-right " disabled ></input>
                </div>
            </div>
            <div className="row mt-1  inputMinWitdth" >
                <div className="col-12 col-md-5 offset-md-1 d-flex justify-content-center justify-content-md-start">
                  <div  className="text-left">
                    <p>Amortizimi i pasurive të paprekshme</p>
                  </div>
                </div>
                <div className="col-6 col-md-3  d-flex justify-content-center justify-content-md-start ">
                    <input type="number" className="form-control form-control-sm font-weight-bold text-right " disabled ></input>
                </div>
                <div className="col-6 col-md-3  d-flex justify-content-center justify-content-md-start ">
                    <input type="number" className="form-control form-control-sm font-weight-bold text-right " disabled ></input>
                </div>
            </div>

            <div className="row mt-1  inputMinWitdth" >
                <div className="col-12 col-md-5 offset-md-1 d-flex justify-content-center justify-content-md-start">
                  <div  className="text-left">
                    <p>Humbjet nga dëmtimi në pasuritë e paprekshme</p>
                  </div>
                </div>
                <div className="col-6 col-md-3  d-flex justify-content-center justify-content-md-start ">
                    <input type="number" className="form-control form-control-sm font-weight-bold text-right " disabled ></input>
                </div>
                <div className="col-6 col-md-3  d-flex justify-content-center justify-content-md-start ">
                    <input type="number" className="form-control form-control-sm font-weight-bold text-right " disabled ></input>
                </div>
            </div>

            <div className="row mt-1  inputMinWitdth" >
                <div className="col-12 col-md-5 offset-md-1 d-flex justify-content-center justify-content-md-start">
                  <div  className="text-left">
                    <p>Ndryshimi i vlerës së pronës investuese</p>
                  </div>
                </div>
                <div className="col-6 col-md-3  d-flex justify-content-center justify-content-md-start ">
                    <input type="number" className="form-control form-control-sm font-weight-bold text-right " disabled ></input>
                </div>
                <div className="col-6 col-md-3  d-flex justify-content-center justify-content-md-start ">
                    <input type="number" className="form-control form-control-sm font-weight-bold text-right " disabled ></input>
                </div>
            </div>
            <div className="row mt-1  inputMinWitdth" >
                <div className="col-12 col-md-5 offset-md-1 d-flex justify-content-center justify-content-md-start">
                  <div  className="text-left">
                    <p>Të ardhurat financiare</p>
                  </div>
                </div>
                <div className="col-6 col-md-3  d-flex justify-content-center justify-content-md-start ">
                    <input type="number" className="form-control form-control-sm font-weight-bold text-right " disabled ></input>
                </div>
                <div className="col-6 col-md-3  d-flex justify-content-center justify-content-md-start ">
                    <input type="number" className="form-control form-control-sm font-weight-bold text-right " disabled ></input>
                </div>
            </div>
            <div className="row mt-1  inputMinWitdth" >
                <div className="col-12 col-md-5 offset-md-1 d-flex justify-content-center justify-content-md-start">
                  <div  className="text-left">
                    <p>Shpenzimet financiare</p>
                  </div>
                </div>
                <div className="col-6 col-md-3  d-flex justify-content-center justify-content-md-start ">
                    <input type="number" className="form-control form-control-sm font-weight-bold text-right " disabled ></input>
                </div>
                <div className="col-6 col-md-3  d-flex justify-content-center justify-content-md-start ">
                    <input type="number" className="form-control form-control-sm font-weight-bold text-right " disabled ></input>
                </div>
            </div>
            <div className="row mt-1  inputMinWitdth" >
                <div className="col-12 col-md-5 offset-md-1 d-flex justify-content-center justify-content-md-start">
                  <div  className="text-left">
                    <p>(Fitimi) / humbja nga shitja e pronës, fabrikës dhe pajisjeve</p>
                  </div>
                </div>
                <div className="col-6 col-md-3  d-flex justify-content-center justify-content-md-start ">
                    <input type="number" className="form-control form-control-sm font-weight-bold text-right " disabled ></input>
                </div>
                <div className="col-6 col-md-3  d-flex justify-content-center justify-content-md-start ">
                    <input type="number" className="form-control form-control-sm font-weight-bold text-right " disabled ></input>
                </div>
            </div>
           

          <div className="row mt-1  font-weight-bold inputMinWitdth" >
                <div className="col-12 col-md-5 offset-md-1 d-flex justify-content-center justify-content-md-start">
                  <div  className="text-left">
                    <p className="font-weight-bold text-dark"><u>Shpenzimet e tatimit në fitim</u> </p>
                  </div>
                </div>
                <div className="col-6 col-md-3  d-flex justify-content-center justify-content-md-start ">
                    <input type="number" className="form-control form-control-sm font-weight-bold text-right border-dark " disabled ></input>
                </div>
                <div className="col-6 col-md-3  d-flex justify-content-center justify-content-md-start ">
                    <input type="number" className="form-control form-control-sm font-weight-bold text-right border-dark" disabled ></input>
                </div>
            </div>

            <div className="row mt-2  " >
                <div className="col-12 col-md-5 offset-md-1 d-flex justify-content-center justify-content-md-start">
                  <div  className="text-left">
                    <p className="font-weight-bold text-dark m-0">Ndryshimet në pasuritë dhe detyrimet operative</p>
                  </div>
                </div>
            </div>
            <div className="row"  >
              <div className="col-12 col-md-11 offset-md-1 text-dark">
                <hr className="m-0 hrSolid"></hr>
              </div>
            </div>
            <div className="row mt-2  inputMinWitdth" >
                <div className="col-12 col-md-5 offset-md-1 d-flex justify-content-center justify-content-md-start">
                  <div  className="text-left">
                    <p>(Rritja) / zvogëlim në llogaritë e arkëtueshme tregtare dhe të tjera</p>
                  </div>
                </div>
                <div className="col-6 col-md-3  d-flex justify-content-center justify-content-md-start ">
                    <input type="number" className="form-control form-control-sm font-weight-bold text-right " disabled ></input>
                </div>
                <div className="col-6 col-md-3  d-flex justify-content-center justify-content-md-start ">
                    <input type="number" className="form-control form-control-sm font-weight-bold text-right " disabled ></input>
                </div>
            </div>
            <div className="row mt-1  inputMinWitdth" >
                <div className="col-12 col-md-5 offset-md-1 d-flex justify-content-center justify-content-md-start">
                  <div  className="text-left">
                    <p>(Rritja) / zvogëlimi në stoqe</p>
                  </div>
                </div>
                <div className="col-6 col-md-3  d-flex justify-content-center justify-content-md-start ">
                    <input type="number" className="form-control form-control-sm font-weight-bold text-right " disabled ></input>
                </div>
                <div className="col-6 col-md-3  d-flex justify-content-center justify-content-md-start ">
                    <input type="number" className="form-control form-control-sm font-weight-bold text-right " disabled ></input>
                </div>
            </div>
            <div className="row mt-1  inputMinWitdth" >
                <div className="col-12 col-md-5 offset-md-1 d-flex justify-content-center justify-content-md-start">
                  <div  className="text-left">
                    <p>(Rritja) / zvogëlimi në pasuritë e tjera	</p>
                  </div>
                </div>
                <div className="col-6 col-md-3  d-flex justify-content-center justify-content-md-start ">
                    <input type="number" className="form-control form-control-sm font-weight-bold text-right " disabled ></input>
                </div>
                <div className="col-6 col-md-3  d-flex justify-content-center justify-content-md-start ">
                    <input type="number" className="form-control form-control-sm font-weight-bold text-right " disabled ></input>
                </div>
            </div>
            <div className="row mt-1  inputMinWitdth" >
                <div className="col-12 col-md-5 offset-md-1 d-flex justify-content-center justify-content-md-start">
                  <div  className="text-left">
                    <p>Rritja / (zvogëlimi) në llogaritë e pagueshme tregtare dhe të tjera</p>
                  </div>
                </div>
                <div className="col-6 col-md-3  d-flex justify-content-center justify-content-md-start ">
                    <input type="number" className="form-control form-control-sm font-weight-bold text-right " disabled ></input>
                </div>
                <div className="col-6 col-md-3  d-flex justify-content-center justify-content-md-start ">
                    <input type="number" className="form-control form-control-sm font-weight-bold text-right " disabled ></input>
                </div>
            </div>
            <div className="row mt-1  font-weight-bold inputMinWitdth" >
                <div className="col-12 col-md-5 offset-md-1 d-flex justify-content-center justify-content-md-start">
                  <div  className="text-left">
                    <p className="font-weight-bold text-dark"><u>Rritja / (zvogëlimi) në detyrimet e tjera</u> </p>
                  </div>
                </div>
                <div className="col-6 col-md-3  d-flex justify-content-center justify-content-md-start ">
                    <input type="number" className="form-control form-control-sm font-weight-bold text-right border-dark " disabled ></input>
                </div>
                <div className="col-6 col-md-3  d-flex justify-content-center justify-content-md-start ">
                    <input type="number" className="form-control form-control-sm font-weight-bold text-right border-dark" disabled ></input>
                </div>
            </div>

            <div className="row mt-1  inputMinWitdth" >
                <div className="col-12  col-md-5 offset-md-1 d-flex justify-content-center justify-content-md-start">
                  <div  className="text-left">
                    <p>Tatimi në fitim i paguar</p>
                  </div>
                </div>
                <div className="col-6 col-md-3  d-flex justify-content-center justify-content-md-start ">
                    <input type="number" className="form-control form-control-sm font-weight-bold text-right " disabled ></input>
                </div>
                <div className="col-6 col-md-3  d-flex justify-content-center justify-content-md-start ">
                    <input type="number" className="form-control form-control-sm font-weight-bold text-right " disabled ></input>
                </div>
            </div>
            <div className="row mt-1  inputMinWitdth" >
                <div className="col-12 col-md-5 offset-md-1 d-flex justify-content-center justify-content-md-start">
                  <div  className="text-left">
                    <p>Interesi i paguar</p>
                  </div>
                </div>
                <div className="col-6 col-md-3  d-flex justify-content-center justify-content-md-start ">
                    <input type="number" className="form-control form-control-sm font-weight-bold text-right " disabled ></input>
                </div>
                <div className="col-6 col-md-3  d-flex justify-content-center justify-content-md-start ">
                    <input type="number" className="form-control form-control-sm font-weight-bold text-right " disabled ></input>
                </div>
            </div>
            <div className="row mt-1  font-weight-bold inputMinWitdth" >
                <div className="col-12 col-md-5 offset-md-1 d-flex justify-content-center justify-content-md-start">
                  <div  className="text-left">
                    <p className="font-weight-bold text-dark"><u>Rrjedha neto e parasë nga aktivitetet operative</u> </p>
                  </div>
                </div>
                <div className="col-6 col-md-3  d-flex justify-content-center justify-content-md-start ">
                    <input type="number" className="form-control form-control-sm font-weight-bold text-right border-dark " disabled ></input>
                </div>
                <div className="col-6 col-md-3  d-flex justify-content-center justify-content-md-start ">
                    <input type="number" className="form-control form-control-sm font-weight-bold text-right border-dark" disabled ></input>
                </div>
            </div>

            <div className="row mt-2  " >
                <div className="col-12 col-md-5 offset-md-1 d-flex justify-content-center justify-content-md-start">
                  <div  className="text-left">
                    <p className="font-weight-bold text-dark m-0">Rrjedha e parasë nga aktivitetet investuese</p>
                  </div>
                </div>
            </div>
            <div className="row"  >
              <div className="col-12 col-md-11 offset-md-1 text-dark">
                <hr className="m-0 hrSolid"></hr>
              </div>
            </div>

            <div className="row mt-2  inputMinWitdth" >
                <div className="col-12 col-md-5 offset-md-1 d-flex justify-content-center justify-content-md-start">
                  <div  className="text-left">
                    <p>Pagesat për blerjet e pronës, pajisjeve dhe impianteve</p>
                  </div>
                </div>
                <div className="col-6 col-md-3  d-flex justify-content-center justify-content-md-start ">
                    <input type="number" className="form-control form-control-sm font-weight-bold text-right " disabled ></input>
                </div>
                <div className="col-6 col-md-3  d-flex justify-content-center justify-content-md-start ">
                    <input type="number" className="form-control form-control-sm font-weight-bold text-right " disabled ></input>
                </div>
            </div>
            <div className="row mt-1  inputMinWitdth" >
                <div className="col-12 col-md-5 offset-md-1 d-flex justify-content-center justify-content-md-start">
                  <div  className="text-left">
                    <p>Arkëtimet për shitjet e pronës, pajisjeve dhe impianteve</p>
                  </div>
                </div>
                <div className="col-6 col-md-3  d-flex justify-content-center justify-content-md-start ">
                    <input type="number" className="form-control form-control-sm font-weight-bold text-right " disabled ></input>
                </div>
                <div className="col-6 col-md-3  d-flex justify-content-center justify-content-md-start ">
                    <input type="number" className="form-control form-control-sm font-weight-bold text-right " disabled ></input>
                </div>
            </div>
            <div className="row mt-1  inputMinWitdth" >
                <div className="col-12 col-md-5 offset-md-1 d-flex justify-content-center justify-content-md-start">
                  <div  className="text-left">
                    <p>Pagesat për blerjet e pasurive të paprekshme</p>
                  </div>
                </div>
                <div className="col-6 col-md-3  d-flex justify-content-center justify-content-md-start ">
                    <input type="number" className="form-control form-control-sm font-weight-bold text-right " disabled ></input>
                </div>
                <div className="col-6 col-md-3  d-flex justify-content-center justify-content-md-start ">
                    <input type="number" className="form-control form-control-sm font-weight-bold text-right " disabled ></input>
                </div>
            </div>

            <div className="row mt-1  inputMinWitdth" >
                <div className="col-12 col-md-5 offset-md-1 d-flex justify-content-center justify-content-md-start">
                  <div  className="text-left">
                    <p>Arkëtimet për shitjen e pasurive të paprekshme</p>
                  </div>
                </div>
                <div className="col-6 col-md-3  d-flex justify-content-center justify-content-md-start ">
                    <input type="number" className="form-control form-control-sm font-weight-bold text-right " disabled ></input>
                </div>
                <div className="col-6 col-md-3  d-flex justify-content-center justify-content-md-start ">
                    <input type="number" className="form-control form-control-sm font-weight-bold text-right " disabled ></input>
                </div>
            </div>
            <div className="row mt-1  inputMinWitdth" >
                <div className="col-12 col-md-5 offset-md-1 d-flex justify-content-center justify-content-md-start">
                  <div  className="text-left">
                    <p>Interesi i arkëtuar</p>
                  </div>
                </div>
                <div className="col-6 col-md-3  d-flex justify-content-center justify-content-md-start ">
                    <input type="number" className="form-control form-control-sm font-weight-bold text-right " disabled ></input>
                </div>
                <div className="col-6 col-md-3  d-flex justify-content-center justify-content-md-start ">
                    <input type="number" className="form-control form-control-sm font-weight-bold text-right " disabled ></input>
                </div>
            </div>
            <div className="row mt-1  inputMinWitdth" >
                <div className="col-12 col-md-5 offset-md-1 d-flex justify-content-center justify-content-md-start">
                  <div  className="text-left">
                    <p>Dividendat e arkëtuara</p>
                  </div>
                </div>
                <div className="col-6 col-md-3  d-flex justify-content-center justify-content-md-start ">
                    <input type="number" className="form-control form-control-sm font-weight-bold text-right " disabled ></input>
                </div>
                <div className="col-6 col-md-3  d-flex justify-content-center justify-content-md-start ">
                    <input type="number" className="form-control form-control-sm font-weight-bold text-right " disabled ></input>
                </div>
            </div>
            <div className="row mt-1  font-weight-bold inputMinWitdth" >
                <div className="col-12 col-md-5 offset-md-1 d-flex justify-content-center justify-content-md-start">
                  <div  className="text-left">
                    <p className="font-weight-bold text-dark"><u>Rrjedha neto e parasë nga aktivitetet investuese</u> </p>
                  </div>
                </div>
                <div className="col-6 col-md-3  d-flex justify-content-center justify-content-md-start ">
                    <input type="number" className="form-control form-control-sm font-weight-bold text-right border-dark " disabled ></input>
                </div>
                <div className="col-6 col-md-3  d-flex justify-content-center justify-content-md-start ">
                    <input type="number" className="form-control form-control-sm font-weight-bold text-right border-dark" disabled ></input>
                </div>
            </div>
            <div className="row mt-2  " >
                <div className="col-12 col-md-5 offset-md-1 d-flex justify-content-center justify-content-md-start">
                  <div  className="text-left">
                    <p className="font-weight-bold text-dark m-0">Rritja/(zvogëlimi) neto i parasë dhe ekuivalentëve të parasë</p>
                  </div>
                </div>
            </div>
            <div className="row"  >
              <div className="col-12 col-md-11 offset-md-1 text-dark">
                <hr className="m-0 hrSolid"></hr>
              </div>
            </div>
            <div className="row mt-2  inputMinWitdth" >
                <div className="col-12 col-md-5 offset-md-1 d-flex justify-content-center justify-content-md-start">
                  <div  className="text-left">
                    <p>Paraja dhe ekuivalentët e parasë në fillim të vitit</p>
                  </div>
                </div>
                <div className="col-6 col-md-3  d-flex justify-content-center justify-content-md-start ">
                    <input type="number" className="form-control form-control-sm font-weight-bold text-right " disabled ></input>
                </div>
                <div className="col-6 col-md-3  d-flex justify-content-center justify-content-md-start ">
                    <input type="number" className="form-control form-control-sm font-weight-bold text-right " disabled ></input>
                </div>
            </div>
            <div className="row mt-1  font-weight-bold inputMinWitdth" >
                <div className="col-12 col-md-5 offset-md-1 d-flex justify-content-center justify-content-md-start">
                  <div  className="text-left">
                    <p className="font-weight-bold text-dark"><u>Paraja dhe ekuivalentët e parasë në fund të vitit</u> </p>
                  </div>
                </div>
                <div className="col-6 col-md-3  d-flex justify-content-center justify-content-md-start ">
                    <input type="number" className="form-control form-control-sm font-weight-bold text-right border-dark " disabled ></input>
                </div>
                <div className="col-6 col-md-3  d-flex justify-content-center justify-content-md-start ">
                    <input type="number" className="form-control form-control-sm font-weight-bold text-right border-dark" disabled ></input>
                </div>
            </div>

            
          </div>
        </div>
      </div>

    )
}

export default CashFlow;
