import React, { Component } from 'react';
import apiService from '../../Services/apiServices';
import { HashRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import Items from '../../components/Items/Items';
import Footer from '../Footer/Footer.js';
import Header from '../Header/Header.js';
import Nav from '../Nav/Nav.js';
import Warehouse from '../../components/Reports/Warehouse.js';
import Dashboard from '../../Dashboard/Dashboard.js';
import HeaderTools from '../Header/HeaderTools.js';
import Sales from '../../components/Sales/Sales';
//import NRegister from "../../components/Sales/SRegsiter/NRegister";
import Sale from "../../components/Sales/SRegsiter/Sale";
import ItemsHistory from '../../components/Reports/ItemsHistory.js';

import PurchaseBook from '../../components/Reports/PurchaseBook';
import Item from '../../components/Items/Item'
import Partners from '../../components/Partners/Partners'
import Partner from '../../components/Partners/Partner'
import PDF from '../PDF/PDF'
import Invoice1 from '../PDF/invoice1';
import Invoice2 from '../PDF/invoice2';
import ReferencesData from '../ReferenceData/ReferenceData';
import Settings from '../Settings/Settings';
import AddUser from '../../components/Administration/AddUser';
import UserConfigurations from '../../components/Administration/UserConfigurations';
import UserProfile from '../../components/Administration/UserProfile';
import Bank from '../../components/Registration/Bank';
import Company from '../../components/Administration/Company';
import CashBox from '../../components/Registration/CashBox';
import FinancialOrders from '../../components/Finances/Transactions';
import AccountingPlans from '../../components/Finances/AccountingPlans';
import Administration from '../../components/Administration/Administration';
import Registration from '../../components/Registration/Registration';
import SalesMenus from '../../components/Sales/SalesMenus';
import Finances from '../../components/Finances/Finances';

import Purchases from '../../components/Purchases/Purchases';
import Expense from '../../components/Expenses/Expense';
import Expenses from '../../components/Expenses/Expenses';

import ReportStockMenus from "../../components/Reports/ReportStocks/ReportStockMenus"
import PaymentsMenus from '../../components/Payments/PaymentsMenus';
import ReportFinancialMenus from '../../components/Reports/ReportAccounting/ReportFinancialMenus';
import ReportATKMenus from '../../components/Reports/ReportATK/ReportATKMenus';
import ReportBySupplierMenus from '../../components/Reports/ReportBySupplier/ReportBySupplierMenus';
import ReportByBuyerMenus from '../../components/Reports/ReportByBuyer/ReportByBuyerMenus';
import Features from '../../components/Features/Features';
import InvoicePurchase from '../PDF/Purchase/InvoicePurchase';
import ReportsDashboard from '../../components/Reports/ReportsDashboard';
import ReportsDashboardMap from '../../components/Reports/ReportsDashboardMap';
import ExcelExport from '../Excel/ExcelExport';
import Stock from './../../components/Reports/ReportStocks/Stock';
import ArticleHistory from './../../components/Reports/ReportStocks/ArticleHistory';
import ArticleCard from '../../components/Reports/ReportStocks/ArticleCard';
import ReportAtkPurchaseBook from '../../components/Reports/ReportATK/ReportAtkPurchaseBook';
import ReportAtkSaleBook from '../../components/Reports/ReportATK/ReportAtkSaleBook';
import CashBoxBook from '../../components/Reports/ReportATK/ReportAtkCashBoxBook';
import BankBook from '../../components/Reports/ReportATK/ReportAtkBankBook';
import AccountsReceivable from '../../components/Reports/ReportByBuyer/AccountsReceivable';
import BuyerCard from '../../components/Reports/ReportByBuyer/BuyerCard';
import AccountsPayable from '../../components/Reports/ReportBySupplier/AccountsPayable';
import SupplierCard from '../../components/Reports/ReportBySupplier/SupplierCard';
import ReportPurchaseBook from '../../components/Reports/ReportATK/ReportPurchaseBook';
import ReportSaleBook from '../../components/Reports/ReportATK/ReportSaleBook';
import TrialBalance from '../../components/Reports/ReportAccounting/TrialBalance';
import GeneralBook from '../../components/Reports/ReportAccounting/GeneralBook';
import TopSuppliers from '../../components/Reports/ReportBySupplier/TopSuppliers';
import TopBuyers from '../../components/Reports/ReportByBuyer/TopBuyers';
import ItemsWithSalePrice from '../../components/Reports/ReportStocks/ItemsWithSalePrice';
import IncomeStatement from '../../components/Reports/ReportFinancial/IncomeStatement'
import BalanceSheet from '../../components/Reports/ReportFinancial/BalanceSheet'
import CashFlow from '../../components/Reports/ReportFinancial/CashFlow'
import ShareHolderEquity from '../../components/Reports/ReportFinancial/ShareHolderEquity'
import ListOfAssets from '../../components/Reports/ReportAccounting/ListOfAssets'
import RptWarehouseTransfer from '../../components/Reports/RptWarehouseTransfer';
import WarehouseTransferPDF from '../PDF/Reports/WarehouseTransferPDF';
import SalesInDetail from '../../components/Reports/ReportByBuyer/SalesInDetail';
import PurchasesInDetails from "../../components/Reports/ReportPurchases/PurchasesInDetails"
// import Databases from '../../Databases/Databases';
import PurchaseTermsOfConditions from '../../components/Reports/ReportPurchases/PurchaseTermsOfConditions';
import SaleTermsOfConditions from '../../components/Reports/ReportSales/SaleTermsOfConditions';
import ItemProfitabilityList from '../../components/Reports/ReportStocks/ItemProfitabilityList';
import Databases from '../../Databases/Databases';
import globals from '../../Services/globals';
import Invoice3 from '../PDF/invoice3';
import Invoice4 from '../PDF/invoice4';
import Invoice5 from '../PDF/invoice5';
import Invoice6 from '../PDF/invoice6';
import UserPermissionContext from '../../components/Context/UserPermissionContext';
import AddInvoice from '../../components/Tools/AddInvoice';
import TestGrid from '../../mycomponents/Grids/TestGrid.js';

// import loginService from './Services/loginService';


class Body extends Component {
    state = {
        isBoxVisible: true,
        userPermission: []
    };
    toggleBox = () => {
        this.setState(prevState => ({ isBoxVisible: !prevState.isBoxVisible }));
    };

    componentDidMount() {
        globals().getSettings().then((response) => {
            this.setState({
                //invoiceForm: response.invoiceForm
            })
        });
        
        var params = `&username=${globals().getLogedInUser().data.username}`;
        apiService().query('getUserPermissions',params)
            .then((response) => {
             if(response.success){
                this.setState({
                    userPermission: response.data
                })
             }
            })
    }

    render() {
        const { isBoxVisible } = this.state;
        const isPrint = this.props.location.pathname.includes("print")
        const chngdb = this.props.location.pathname.includes("databases")

        return (

            <>
                {/* Page Wrapper   */}
                <div id="wrapper">
                    {/* Sidebar */}

                    <div id="content-wrapper" className="d-flex flex-column">
                        <div id="content">
                            {/* Topbar */}
                            {!chngdb && !isPrint && <Header clickbtn={this.toggleBox} />}
                            <div className="" style={{ marginTop: "40px" }}>
                            <UserPermissionContext.Provider value={{userPermission: this.state.userPermission}}>
                                <Router>
                                    <Switch>
                                        <Redirect from="/home" to='/' />
                                        <Route path="/testgrid" component={TestGrid} />
                                        <Route path="/" exact component={Dashboard} />
                                        {/* {userPermissions?.length > 0 ? <Route path="/" exact component={() => <Dashboard permission={userPermissions} props={this.props} />}   /> : "" }  */}
                                        <Route path="/clients" component={Dashboard} />
                                        <Route path="/selectcompany" component={Databases} />
                                        <Route path="/items" component={Registration} /> 
                                        <Redirect from="/edit_item/:id" to='/item/:id' />
                                        <Route path="/item/:id" component={Registration} />
                                        <Route path="/item" component={Registration} />
                                        <Route path="/sales" component={SalesMenus} />
                                        <Redirect from="/edit_sale/:id" to='/sale/:id' />
                                        <Route path="/sale/:id" component={SalesMenus} />
                                        <Route path="/insertClients" component={Dashboard} />
                                        <Route path="/partners" component={Registration} />
                                        <Route path="/activePartners" component={Registration} />
                                        <Route path="/inactivePartners" component={Registration} />
                                        <Redirect from="/edit_partner/:id" to='/partner/:id' />
                                        <Route path="/partner/:id" component={Registration} />
                                        <Route path="/partner" component={Registration} />
                                        <Route path="/print1/:id" component={Invoice1} />
                                        <Route path="/print2/:id" component={Invoice2} />
                                        <Route path="/print3/:id" component={Invoice3} />
                                        <Route path="/print4/:id" component={Invoice4} />
                                        <Route path="/print5/:id" component={Invoice5} />
                                        <Route path="/print6/:id" component={Invoice6} />
                                        <Route path="/categories" component={Registration} />
                                        <Route path="/units" component={Registration} />
                                        <Redirect from="/settingsTrue" to='/settings' />
                                        <Route path="/settings" component={Settings} />
                                        <Route path="/user-profile" component={Administration} />
                                        <Route path="/user-configuration" component={Administration} />
                                        <Route path="/reset-password" component={Administration} />
                                        <Route path="/permissions" component={Administration} />
                                        <Route path="/cashBoxes" component={Registration} />
                                        <Route path="/banks" component={Registration} />
                                        <Route path="/company" component={Company} />
                                        <Route path="/finances/transactions" component={Finances} />
                                        <Redirect from="/finances/edit_transactionDetails/:id" to='/finances/transactionDetails/:id' />
                                        <Route path="/finances/transactionDetails/:id" component={Finances} />
                                        <Route path="/finances/accounting" component={Finances} />
                                        <Route path="/features/warehouse-transfers/" component={Features} />                                        
                                        <Route path="/features/warehouse-transfer/:id" component={Features} />
                                        <Route path="/features/recalculation" component={Features} />
                                        <Route path="/administration" component={Administration} />
                                        <Route path="/registration" component={Registration} />
                                        {/* {userPermissions?.length > 0 ? <Route path="/registration" component={() => <Registration permission={userPermissions} props={this.props} />}   /> : "" } */}
                                        <Route path="/finances" component={Finances} />
                                        <Route path="/purchases" exact component={Purchases} />
                                        <Route path="/purchase/:id" exact component={Purchases} />

                                        <Route path="/expenses" exact component={Expenses} />
                                        <Route path="/expense/:id" component={Expense} />

                                        {/* <Route path="/purchases?type=1" component={Purchases} /> */}
                                        <Route path="/purchase" exact component={Purchases} />
                                        <Route path="/imports" exact component={Purchases} />
                                        <Route path="/import/:id" exact component={Purchases} />
                                        <Route path="/import" exact component={Purchases} /> 
                                        <Route path="/reports" component={ReportsDashboardMap} /> 

                                        {/* Raportet Stock */}
                                        <Route path="/report/stocks/warehouse" component={Stock} />
                                        <Route path="/report/stocks/itemshistory" component={ArticleHistory} />
                                        <Route path="/report/stocks/articlecard" component={ArticleCard} />
                                        <Route path="/report/stocks/items-with-sale-price" component={ItemsWithSalePrice} />

                                        {/* Raportet Financiale */}
                                        <Route path="/report/financial/balance-sheet" component={BalanceSheet} />
                                        <Route path="/report/financial/income-statement" component={IncomeStatement} />
                                        <Route path="/report/financial/cash-flow" component={CashFlow} />
                                        <Route path="/report/financial/shareholder-equity" component={ShareHolderEquity} />
                                        {/* Raportet taxave */}

                                        <Route path="/report/atk-purchase-book" component={ReportAtkPurchaseBook} />
                                        <Route path="/report/atk-sale-book" component={ReportAtkSaleBook} />
                                        <Route path="/report/atk/cashbox-book" component={CashBoxBook} />
                                        <Route path="/report/atk/bank-book" component={BankBook} />

                                        {/* Raportet e furnitorit */}
                                        <Route path="/report/buyer/accounts-receivable" component={AccountsReceivable} />
                                        <Route path="/report/buyer/buyer-card" component={BuyerCard} />
                                        <Route path="/report/supplier/top-buyers" component={TopBuyers} />
                                        <Route path="/report/buyer/sales-in-detail" component={SalesInDetail} />

                                        {/* Raportet e bleresve */}

                                        <Route path="/report/supplier/accounts-payable" component={AccountsPayable} />
                                        <Route path="/report/supplier/supplier-card" component={SupplierCard} />
                                        <Route path="/report/supplier/top-suppliers" component={TopSuppliers} />

                                        {/* Raportet kontabilitet  */}

                                        <Route path="/report/rpt-purchase-book/:id" component={ReportPurchaseBook} />
                                        <Route path="/report/rpt-list-of-assets" component={ListOfAssets} />
                                        <Route path="/report/rpt-sale-book" component={ReportSaleBook} />
                                        <Route path="/report/rpt-trial-balance" component={TrialBalance} />
                                        <Route path="/report/rpt-general-book" component={GeneralBook} />
                                        <Route path="/report/rpt-warehouse-transfer" component={RptWarehouseTransfer} />
                                        <Route path="/print-warehouse-transfer/:id" component={WarehouseTransferPDF} />
                                        <Route path="/report/purchases-in-details" component={PurchasesInDetails} />


                                        <Route path="/payments" component={PaymentsMenus} />
                                        <Route path="/payments/banks" component={PaymentsMenus} />
                                        <Route path="/payments/cash" component={PaymentsMenus} />
                                        <Route path="/paymentcash/:id" component={PaymentsMenus} />
                                        <Route path="/paymentbank/:id" component={PaymentsMenus} />
                                        <Redirect from="/edit_cashbox/:id" to='/payment/cashbox/:id' />
                                        <Redirect from="/edit_bank/:id" to='/payment/bank/:id' />
                                        {/* {userPermissions?.length > 0 ? <Route path="/features" component={() => <Features permission={userPermissions} props={this.props} />}   /> : "" }  */}
                                        <Route path="/features" component={Features}   />
                                        <Route path="/print-purchase/:id" component={InvoicePurchase} />                                        
                                        {/* <Route path="/printPurchase/:id" component={InvoicePurchase} /> */}
                                        <Route path="/excel-print" component={ExcelExport} />
                                        <Route path="/terms-of-payments" component={Registration} />
                                        <Route path="/report/purchases-terms-of-payments" component={PurchaseTermsOfConditions} />
                                        <Route path="/report/sales-terms-of-payments" component={SaleTermsOfConditions} />
                                        <Route path="/items-with-discount" component={Registration} />
                                        <Redirect from="/edit-discount/:id" to='/item-discount/new/:id' />
                                        <Route path="/item-discount/new/:id" component={Registration} />

                                        <Route path='/report/item-profitability-list' component={ItemProfitabilityList} />

                                        <Route path='/add-invoice' component={AddInvoice} />
                                        <Route path='/edit-invoice/:id' component={AddInvoice} />
                                    </Switch>
                                </Router>
                                </UserPermissionContext.Provider>
                            </div>

                        </div>


                        {/* Footer */}
                        <Footer />
                        {/* End of Footer */}
                    </div>

                    {/* End of Content Wrapper */}

                </div>
                {/* End of Page Wrapper */}
                {/* Scroll to Top Button*/}
                <a className="scroll-to-top rounded" href="#page-top">
                    <i className="fas fa-angle-up" />
                </a>
                {/* Logout Modal*/}
                <div className="modal fade" id="logoutModal" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Ready to Leave?</h5>
                                <button className="close" type="button" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">Select "Logout" below if you are ready to end your current session.</div>
                            <div className="modal-footer">
                                <button className="btn btn-secondary" type="button" data-dismiss="modal">Cancel</button>
                                <a className="btn btn-primary" href="login.html">Logout</a>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Bootstrap core JavaScript*/}
                {/* Core plugin JavaScript*/}
                {/* Custom scripts for all pages*/}
                {/* Page level plugins */}
                {/* Page level custom scripts */}
            </>

        );
    }
}

/*
 <Switch>
                    <Route path="/" exact component={Dashboard} />
                    <Route path="/clients" component={Clients} />
                    <Route path="/items" component={Items} />
                    <Route path="/warehouse" component={Warehouse} />
                    <Route path="/insertClients" component={InsertClients} />
                    <Route path="/reports" component={Warehouse} />
                    <Route path="/login" component={Auth} />

                </Switch>
 */

export default Body;
