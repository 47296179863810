import React, { useState, useEffect, useRef, isValidElement } from 'react';
import Select from "react-select";
import Switch from "react-switch";
import SaleAddPartner from '../../PageComponents/UI/Modal/SaleAddPartner';
import Search from '../../PageComponents/Search/Search_Old';
import apiService from '../../Services/apiServices';
import PurchasesModel from '../../Models/Purchases';
import PurchaseExpenseModel from '../../Models/PurchaseExpense';
import helper from '../../Services/helper';
import globals from '../../Services/globals';
import { ToastProvider, useToasts } from 'react-toast-notifications';
import LoaderComponent from '../../PageComponents/UI/Components/LoaderComponent';
import Modal from "react-bootstrap/Modal";
import Item from '../Items/Item';
import SelectByGroup from '../../PageComponents/UI/Inputs/SelectByGroup';
import { confirmAlert } from 'react-confirm-alert';
import PaymentModel from '../../Models/PaymentModel';
import PaymentPurchases from '../Purchases/PaymentPurchases';

const Expense = (params) => {

    const { addToast } = useToasts();
    const selectRef = useRef(null);
    const logedInUser = globals().getLogedInUser();
    const [user, setUser] = useState();
    const [userSettings, setUserSettings] = useState();
    const [loader, setLoader] = useState(true)
    const [partners, setPartners] = useState()
    const [purchase, setPurchase] = useState(new PurchasesModel())

    const [purchaseTotals, setPurchaseTotals] = useState(new PurchasesModel())

    const [payMethods, setPayMethods] = useState()
    const [warehouses, setWarehouses] = useState()
    //const [units, setUnits] = useState()

    const [expenseAccounts, setExpenseAccounts] = useState();
    const [items, setItems] = useState();

    const [config, setConfig] = useState();
    const [errors, setErrors] = useState({});
    const [showModal, setShowModal] = useState(false)
    const [vatLevels, setVatLevels] = useState()
    const [projects, setProjects] = useState();
    const [branches, setBranches] = useState();
    const [payments, setPayments] = useState()
    const status = { added: 1, deleted: -1, changed: 2, unchanged: 0 };
    const [showPayments, setShowPayments] = useState(false)
    const localPurchases = [1, 2, 3, 7];
    const otherPurchases = [4, 5, 6]
    const [pType, setpType] = useState()
    const formName = 'PurchaseExpenseDetails';
    const [showedHeaders, setShowedHeaders] = useState();
    const [showHiddeBox, setShowHiddeBox] = useState(false);
    const [userColumns, setUserColumns] = useState([])
    const [cashBox, setCashBox] = useState()
    const [uncheckedHdrs, setUncheckedHdrs] = useState(0)

    const [headers, setHeaders] = useState([
        { key: 'EXPENSE' },
        { key: 'PROJECT' },
        { key: 'ITEM'},
        { key: 'UNIT' },
        { key: 'QUANTITY' },
        { key: 'PRICE' },
        { key: 'VAT' },
        { key: 'PRICE_WITH_VAT' },
       // { key: 'TOTAL' },
       // { key: 'VAT_VALUE' },
       // { key: 'TOTAL_WITH_VAT' },
        // { key: helper().translate('DISCOUNT') },
        // { key: helper().translate('PRICE_WITH_DISCOUNT') }
    ])

    const [disableSaveButton, setDisableSaveButton] = useState(true)

    let purchaseObj = {
        id: 0, sId: null, purchaseId: 0, no: 1, expenseId: 0, itemId: null, projectId: null, invoiceId: 0, quantity: 1, price: 0, priceWithVat: 0, vat: 0, vatSum: 0.0, createdAt: '', createdBy: '', changedAt: '', changedBy: '', status: 0, editMode: false, total: 0.0, total: 0.0,
    }

    let [purchaseDetails, setPurchaseDetails] = useState([purchaseObj])

    const tables = [
        //{ name: 'units', method: setUnits },
        { name: 'paymentMethod', method: setPayMethods },
        { name: 'warehouses', method: setWarehouses },
        { name: 'vatLevel', method: setVatLevels },
        { name: 'projects', method: setProjects }
    ];

    const modalShow = () => {
        setShowModal(true)
    }

    const modalClose = () => {
        setShowModal(false)
    }

    const styles = {
        container: provided => ({
            ...provided,
            Width: '100%'
        }),
        control: (provided, state) => ({
            ...provided,
            background: '#fff',
            borderColor: '#9e9e9e',
            minHeight: '28.59px',
            height: '28.59px',
            boxShadow: state.isFocused ? null : null,
        }),
        menuPortal: base => ({ ...base, zIndex: 9999 }),
        valueContainer: (provided, state) => ({
            ...provided,
            height: '28.59px',
            padding: '0 6px'
        }),

        input: (provided, state) => ({
            ...provided,
            margin: '0px',
        }),
        indicatorSeparator: state => ({
            display: 'none',
        }),
        indicatorsContainer: (provided, state) => ({
            ...provided,
            height: '28.59px',
        }),
    };

    const getUser = async () => {
        apiService().query('user', 'userName=' + logedInUser.data.username)
            .then((response) => {
                if (response.success) {
                    let u = response.data[0];
                    getCashbox(u.cashBoxId)
                    setUser(u)
                }
                else alert('errr')
            })
    }

    const getBranches = () => {
        apiService().get('branches')
            .then((response) => {
                if (response.success) {
                    let records = response.data;
                    setBranches(records);
                }
            })
    }
    const getCashbox = async (id) => {
        apiService().query('cashBox', 'id=' + id)
            .then((response) => {
                if (response.success) {
                    let cb = response.data[0];
                    setCashBox(cb)
                    // setPurchase({warehouseId: cb?.warehouseId})
                }
                else alert('errr')
            })
    }

    const getPartners = (incomeData) => {
        apiService().get('partners')
            .then((response) => {
                if (response.success) {
                    //let dataFilter = response.data.filter(i => i.branchNo?.length > 0 && parseInt(i.branchNo) === incomeData.branchId || i.branchNo?.length === 0 );
                    let records = response.data;//dataFilter;
                    records = records.filter(record => record?.supplier && record?.status)
                    records = records.map(record => ({ value: record.id, label: record.name ? record.name : record.saveAs }))
                    setPartners(records)
                }
            })
    }

    const getItems = (name) => {
        let options;
        apiService().get(name ? name : 'items')
            .then((response) => {
                if (response.success) {
                    options = response.data.map((item, index) => ({
                        value: item.id,
                        label: item.itemName
                    }));
                    setItems(options);
                }
            })
    }

    const getExpensesAccounts = (name) => {
        let options;
        apiService()
            .get("getExpenseItems")
            .then((response) => {
                if (response.success) {
                    options = response.data.map((item, index) => ({
                        value: item.id,
                        label: item.accountNumber + ' - ' + item.name
                    }));
                    setExpenseAccounts(options);
                } else {
                    alert("Error: getting default accounts for typesale" + response.errorMessage);
                }
            });
    }

    const getSettings = () => {
        globals().getSettings().then((response) => {
            if (response) {
                setConfig(response)
                setLoader(false)
            }
        })
    }

    const getDefaults = (name, method) => {
        apiService().get(name)
            .then((response) => {
                if (response.success) {
                    let records = response.data
                    if (name === 'purchaseType') {
                        records = records.map(record => ({ value: record.id, label: name === 'vatLevel' ? record.value : helper().translate(`${record.name}`), warehouseId: name === 'projects' ? record.warehouseId : 0 }))
                    }
                    else if (name === 'units') {
                        records = response.data.map(record => ({ value: record.typeId, label: record.name }));
                    }
                    else if (name === 'projects' || name === 'vatLevel') {
                        records = records.map(record => ({ value: record.id, label: name === 'vatLevel' ? record.value : record.name, warehouseId: name === 'projects' ? record.warehouseId : 0 }))
                    }
                    else {
                        records = records.map(record => ({ id: record.id, name: name === 'vatLevel' ? record.value : record.name, warehouseId: name === 'projects' ? record.warehouseId : 0, branchId: record?.branchId ? record?.branchId : 0 }))
                    }

                    method(records)
                }
            })
    }

    const getPurchase = (id) => {

        if (id) {
            apiService().query('getPurchase', `id=${id}`)
                .then((response) => {
                    if (response.success && response.data.length) {
                        let purchase = response.data[0]
                        purchase.date = new Date(purchase.date);
                        
                        setPurchase(purchase)
                        getPurchaseDetails(purchase)
                        getPayments(purchase)
                        setpType(purchase.purchaseTypeId)
                        //setLoader(false)
                    }
                    else {
                        let obj = new PurchasesModel();
                        obj.total = 0.0;
                        obj.withVat = config?.withVat;
                        obj.warehouseId = cashBox?.warehouseId;
                        obj.date = new Date();
                        setPurchase(obj);
                        let purchaseDetailsObj = new PurchaseExpenseModel();
                        let pp = [];
                        pp.push(purchaseDetailsObj);
                        setPurchaseDetails(pp);
                    }
                })
        }
        else {

            let purchaseTotals = new PurchasesModel();
            purchaseTotals.vatSum = 0.0;
            purchaseTotals.total = 0.0;
            purchaseTotals.total = 0.0;
            setPurchaseTotals(purchaseTotals);
    
            let newPurchase = new PurchasesModel();
            newPurchase.withVat = config?.withVat;
            newPurchase.purchaseTypeId = 3;
            setPurchase(newPurchase);
            
            let purchasesDetails = [];
            let purchseDetails = new PurchaseExpenseModel();
            purchseDetails.no = 1;
            calculate(purchseDetails);   
    
            purchasesDetails.push(purchseDetails);  
            setPurchaseDetails(purchasesDetails);
            calculateTotals();
        }
    }

    const getPurchaseDetails = (purchase) => {
        apiService().query('GetPurchaseExpenseDetails', `purchaseId=${purchase.id}`)
            .then((response) => {
                if (response.success && response.data && response.data.length) {
                    let records = response.data
                    records.sort(function (a, b) {return a.no - b.no;});
                    //calculate columns
                    records.forEach(record => {
                        record.total = record.price * record.quantity;
                        calculate(record);
                    });
                    purchaseDetails = [...records];                    
                    //calculate totav values
                    calculateTotals();                    
                }
                else {
                    setPurchaseDetails([purchaseObj]);
                }
            })
    }

    const getPayments = (obj) => {
        apiService().query('getPaymentPurchases', `purchaseId=${obj.id}`)
            .then((response) => {
                if (response.success) {
                    let records = response.data
                    setPayments(records)
                }
            })
    }

    const getUserColumns = () => {
        apiService().query('userColumns', `username=${logedInUser.data.username}&formname=${formName}`)
            .then(response => {
                if (response.success && response.data.length > 0) {
                    let results = response.data[0].hColumns.split(',')
                    setUserColumns(results)
                    setColumns(results)
                }
                else {
                    setColumns()
                }
            })
    }

    const setColumns = (userColumns) => {
        var hdrs = []
        let countFalseHdrs = 0;
        headers.forEach(h => {
            if (userColumns) {
                // userColumns.includes(h?.key) ? h['showHidde'] = true : h['showHidde'] = false ;
                if (userColumns.includes(h?.key)) {
                    h['showHidde'] = true;
                } else {
                    h['showHidde'] = false;
                    countFalseHdrs++;
                }
            }
            else {
                h ? h['showHidde'] = true : 0;
            }
            hdrs.push(h)
        })
        setUncheckedHdrs(countFalseHdrs);
        setShowedHeaders(hdrs);
    }

    const removeColumn = (key) => {
        let arr = []
        showedHeaders.forEach(h => {
            if (h.key === key) h.showHidde = !h.showHidde
            arr.push(h)
        })
        // this.renderHeader(arr)
        setShowedHeaders(arr)
        savePrepare(arr)
    }

    const savePrepare = (hs) => {
        if (formName) {
            let hc = []
            let headers = hs ? hs : showedHeaders
            headers?.forEach(h => {
                h.showHidde ? hc.push(h.key) : ''
            })
            let obj = { userName: logedInUser.data.username, formName: formName, hColumns: hc.toString() }
            saveUserColumns(obj, hc.to)
        }
    }

    const saveUserColumns = (obj) => {
        if (!userColumns.length && !allAreTrue(showedHeaders)) {
            apiService().post('userColumns', obj)
                .then(response => {
                    getUserColumns()
                })
        }
        else if (userColumns.length && obj.hColumns !== userColumns.toString()) {
            apiService().put('userColumns', obj)
                .then(response => {
                    if (response.success) {
                        getUserColumns()
                    }
                })
        }
    }

    const allAreTrue = (arr) => {
        return arr.every(element => element.showHidde === true);
    }

    const checkHeader = (key) => {
        var find = showedHeaders?.find(s => s.key === key);
        if (find) {
            return find['showHidde'];
        }
        else {
            return true;
        }
    }

    const renderColumns = () => {
        var headersMap = showedHeaders ? showedHeaders : headers;
        return headersMap.map((header, index) => {
            return <div className='cursor-pointer' onClick={() => removeColumn(header.key)}><input className='me-2' type="checkbox" checked={header.showHidde ? 'checked' : ''} name={header.key}></input><label>{helper().translate(header.key)}</label></div>
        });
    }

    const setProject = (projectId) => {
        return projects?.find(p => p.value === projectId)?.label
    }

    const calculate = (itemDetails) =>{
        //console.log(itemDetails);
        if(itemDetails) {
            itemDetails.priceWithVat = itemDetails.price * (1 + itemDetails.vat/100);
            itemDetails.vatSum =  itemDetails.total * itemDetails.vat/100;
            itemDetails.totalWithVat = itemDetails.total + itemDetails.vatSum;
        }

    }

    const calculateTotals = () => {
        //return;
        let purchaseObj =  { ...purchase }
        let _details = purchaseDetails.filter((row) => row.status !== status.deleted);
        let vatSum = 0.0, total = 0.0, totalWithVat = 0.0;

        for (var i = 0; i < _details.length; i++) {
            vatSum += _details[i].vatSum;
            total += _details[i].total;
            totalWithVat += _details[i].totalWithVat;
        }

        //test
        purchaseObj.vatSum = vatSum;
        purchaseObj.total = total;
        purchaseObj.totalWithVat = totalWithVat;
        setPurchaseTotals(purchaseObj);
        setPurchaseDetails(purchaseDetails);
    }

    const onRowClick = (no) => {
        let rowItem = [...purchaseDetails]
        rowItem.forEach(i => i.editMode = false);
        rowItem[no - 1].editMode = !rowItem[no - 1].editMode;
        setPurchaseDetails(rowItem);
    }

    const addRow = () => {
        let newItem = new PurchaseExpenseModel();
       
        newItem.no = Math.max(...purchaseDetails.map(o => o.no), 0) + 1;
        const newState = [...purchaseDetails, newItem];
        newItem.quantity = 1;

        newState.forEach(item => {
            item.no !== newItem.no ? item.editMode = false : '';
        });

        newItem.editMode = true;

        setPurchaseDetails(newState);
        setTimeout( function () { selectRef.current.focus() }, 500 );
    }

    const getFocus = (no) => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        // document.getElementById("item_"+no)?.getElementsByTagName("input")[0]?.focus();
        let elem = document.getElementById("expenseId" + no)?.getElementsByTagName("input")[0];
        elem.style.color = 'red';
    }

    const deleteRow = (rowId) => {
        let arrayCopy = [...purchaseDetails]
        let item = arrayCopy.find(i => i.no === rowId);
        item.status = status.deleted;
        //arrayCopy = arrayCopy.filter((row) => row.no !== rowId);
        let count = 1;
        arrayCopy.forEach(i => { i.no = count; count++ });
        //setPurchaseDetails([])
        const newArr = [arrayCopy]
        setPurchaseDetails(arrayCopy)
        calculateTotals();
    }

    const reOrderItems = (items) => {
        let count = 1;
        items.forEach(i => {
            if (i.status !== -1) {
                i.no = count;
                count++
            }
            else {
                i.no = 0;
            }
        });

        return items;
    }

    const setEditMode = () => {
        let rowItem = [...purchaseDetails]
        rowItem.forEach(i => i.editMode = false);
        setPurchaseDetails(rowItem);
    }

    const onFieldChange = (index, itemId) => (e, a) => {
        let newArr = [...purchaseDetails];
        let purchDetails = newArr[index - 1];
        purchDetails.status = status.changed;
        
        let  prop = '';
        let value;
        
        if (a) {
            prop = a.name;    
            value = e.value;   
        }
        else{
            prop = e.target.name;
            value = e.target.value;
        }

        if(prop === 'quantity' || prop === "price"){
            purchDetails[prop] = parseFloat(value);
            purchDetails.priceWithVat = purchDetails.price * (1 + purchDetails.vat/100);
            purchDetails.total = purchDetails.price * purchDetails.quantity;
        }
        else if(prop === 'total'){
            purchDetails.total = parseFloat(value);
            purchDetails.price = (purchDetails.quantity!= 0) ? purchDetails.total / purchDetails.quantity : purchDetails.price;
            purchDetails.priceWithVat = purchDetails.price * (1 + purchDetails.vat/100);
        }
        else{
            purchDetails[prop] = value;
        }

        calculate(purchDetails);
        calculateTotals();

        setPurchaseDetails(newArr);

    }

    const changeField = (e) => {
        let pur = { ...purchase };
        pur.date = new Date(e.target.value);
        setPurchase(pur)
    }

    const newPurchase = () => {

        purchaseDetails?.some(function (el) {
            el.editMode === true ?
                confirmAlert({
                    customUI: ({ onClose }) => {
                        return (
                            <div className="custom-ui" >
                                <h1>A jeni te sigurt?</h1>
                                <p>Dëshironi të hapni shpenzimin e ri pa i ruajtur ndryshimet paraprake?</p>
                                <button
                                    onClick={() => {
                                        // !type || type === purchase.purchaseTypeId ?  getPurchase(0) : window.location.href = `#/purchase?type=${purchase.purchaseTypeId}`;
                                        continueToNew()

                                        onClose();
                                    }}
                                >
                                    Vazhdo!
                                </button>
                                <button onClick={onClose}>Jo</button>
                            </div>
                        );
                    }
                })
                :
                continueToNew()
        });

    }

    const continueToNew = () => {
        location.href = `#/expense/0`;
        setPayments([]);

        let purchaseTotals = new PurchasesModel();
        purchaseTotals.vatSum = 0.0;
        purchaseTotals.total = 0.0;
        purchaseTotals.total = 0.0;
        setPurchaseTotals(purchaseTotals);

        let newPurchase = new PurchasesModel();
        newPurchase.withVat = config?.withVat;
        setPurchase(newPurchase);
        
        let purchasesDetails = [];
        let purchseDetails = new PurchaseExpenseModel();
        purchseDetails.no = 1;
        calculate(purchseDetails);   

        purchasesDetails.push(purchseDetails);  
        setPurchaseDetails(purchasesDetails);
        calculateTotals();
    }

    const escFunction = (event) => {
        if (event.keyCode === 27) {
            modalClose()
        }
    }

    const responseMessage = (appearance, message) => {
        appearance === 'error' ? toggleSaveButtonState(false) : '';
        addToast(message, { appearance: appearance, autoDismiss: true, });
    }

    const afterNewItem = (c) => {
        if (c) {
            modalClose();
            getItems();
            getExpensesAccounts();
        }
    }

    const toggleSaveButtonState = (val) => {
        if (val) {
            setDisableSaveButton(val)
        }
        else {
            setTimeout(
                function () {
                    setDisableSaveButton(false)
                }, 5000);
        }

    }

    const checkInvoiceNo = () => {
        toggleSaveButtonState(true)
        if (purchase.id !== 0) {
            save();
        }
        else {
            apiService().query('checkPurchaseInvoiceNo', 'invoiceNo=' + purchase?.invoiceNo)
                .then((response) => {
                    if (response.success && response.data.length) {
                        setErrors({ ...errors, invoiceNo: 'Ky numer i fatures ekziston!' })
                        responseMessage('error', 'Ky numer i fatures ekziston!')
                    }
                    else {
                        // setErrors({...errors, invoiceNo: ''})
                        save();
                    }
                })
        }
    }

    const save = () => {

        let errorsArr = {}, requiredFields = ['partnerId', 'warehouseId', 'paymentMethodId', 'invoiceNo'];
        requiredFields.forEach(field => {
            field != '' ? !purchase?.[field] ? errorsArr[field] = 'Zgjedhe' : '' : 0
        })

        purchaseDetails?.forEach(detail => {
            !detail.expenseId ? errorsArr['pd_expense_' + detail.no] = 'Zgjedhe' : '';
            !detail.price ? errorsArr['pd_price_' + detail.no] = 'Zgjedhe' : '';
        });

        const isEmpty = !Object.values(errorsArr).some(x => x !== null && x !== '');
        setErrors(errorsArr)

        if (purchase.id !== 0) {
            if (purchaseDetails.length && purchaseDetails[0].expenseId) {
                purchase.changedBy = globals().getLogedInUser().data.username;
                purchase.vatSum = purchaseTotals.vatSum;
                purchase.totalSum = purchaseTotals.total;
                // console.log(purchase)
                apiService().put('purchases', purchase)
                    .then((response) => {
                        if (response.success) {
                            //responseMessage(response.success ? 'success' : '', 'U ruajt me sukses')
                            //setPurchase(response.data)
                            if (purchase.paymentMethodId === 1) {
                                savePayment(purchase)
                                getPurchase(purchase.id)
                            }
                            savePurchaseExpensesDetails(purchase)
                        }
                        else {
                            console.error(response)
                            responseMessage('error', response.errorMessage ? response.errorMessage : 'Ka ndodhur nje gabim!')
                        }
                    })
            }
            else {
                toggleSaveButtonState(false);
            }
        }
        else {
            purchase.dateRegistered = new Date();
            purchase.createdBy = globals().getLogedInUser().data.username;
            purchase.vatSum = purchaseTotals.vatSum;
            purchase.totalSum = purchaseTotals.total;
            if (purchaseDetails.length && purchaseDetails[0].expenseId) {
                apiService().post('purchases', purchase)
                    .then((response) => {
                        if (response.success) {
                            let resp = response.data
                            resp.date = new Date(resp.date);
                            if (resp.paymentMethodId === 1) {
                                savePayment(response.data)
                            }
                            setPurchase(resp)
                            savePurchaseExpensesDetails(response.data)
                        }
                        else {
                            responseMessage('error', response.errorMessage ? response.errorMessage : 'Ka ndodhur nje gabim!')
                        }
                    })
            }
            else {
                toggleSaveButtonState(false);
            }
        }

    }

    const savePurchaseExpensesDetails = async (purchase) => {
        let objs = [], items = reOrderItems([...purchaseDetails]), count = 1;
        items ? await items.forEach(item => {
            if (purchase.withVat === false) {
                item.vat = 0;
                item.vatSum = 0;
            }
            item.purchaseId = purchase.id;
            if (item.id === 0) {
                let obj = {
                    id: 0,
                    entity: new PurchaseExpenseModel(item),
                    status: status.added,
                    type: 'PurchasesExpensesDetails'
                }
                obj.entity.expenseId && obj.entity.price && item.no !== 0 && item.status !== -1 ? objs.push(obj) : 0
            }
            else {
                let obj = {
                    entity: new PurchaseExpenseModel(item),
                    status: item.status ? item.status : status.unchanged,
                    type: 'PurchasesExpensesDetails'
                }
                if (obj.status !== status.unchanged) {
                    obj.entity.expenseId && obj.entity.price ? objs.push(obj) : 0
                }
            }
        }) :
            0

        objs ? apiService().saveChanges(objs)
            .then((response) => {
                // console.log(response)
                if (response.success) {
                    responseMessage(response.success ? 'success' : '', 'U ruajt me sukses!')
                    window.location.href = '#/expense/' + purchase.id;
                    setEditMode()
                    toggleSaveButtonState(false)
                }
                else {
                    responseMessage('error', response.errorMessage ? response.errorMessage : 'Ka ndodhur nje gabim!')
                }
            }) :
            0
    }

    const savePayment = (purchase) => {
        let payment = new PaymentModel();
        payment.credit = purchase.amount;
        payment.saldo = payment.debit - payment.credit;
        payment.partnerId = purchase.partnerId;
        payment.paymentType = purchase.paymentMethodId;
        payment.warehouseId = purchase.warehouseId;
        payment.cashBoxId = user.cashBoxId;
        payment.reference = purchase.reference;
        payment.date = purchase.date;
        apiService().execute('insertPaymentFromPurchase', payment, 'post')
            .then((response) => {
                if (response.success) {
                    savePaymentPurchase(response.data[0].id, payment, purchase)
                }
                else {
                    responseMessage('error', response.errorMessage ? response.errorMessage : 'Ka ndodhur nje gabim gjate regjistrimit te pageses!')
                }
            })
    }

    const savePaymentPurchase = (id, payment, purchase) => {
        let paymentPurchases = {};
        paymentPurchases.paymentId = id;
        paymentPurchases.purchaseId = purchase.id;
        paymentPurchases.amountPaid = payment.credit;
        paymentPurchases.createdAt = new Date();
        paymentPurchases.createdBy = user.name + " " + user.surname;
        apiService().post('insertPaymentPurchases', paymentPurchases)
            .then((response) => {
                if (!response.success) {
                    responseMessage('error', response.errorMessage ? response.errorMessage : 'Ka ndodhur nje gabim gjate regjistrimit te detajeve te pageses!')
                }
            })
    }

    const print = () => {
        window.open(`#/print-purchase/${purchase.id}`, 'popUpWindow', 'height=1300,width="100%",left=100,top=100,right=100,bottom=100,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes')
    }

    const getUserSettings = () => {
        globals().getUserSettings(logedInUser).then((response) => {
            setUserSettings(response)
            getPartners(response);
        });
    }

    useEffect(() => {
        getUserColumns()
        document.addEventListener("keydown", escFunction, false);
        return () => {
            document.removeEventListener("keydown", escFunction, false);
        }
    }, [])

    useEffect(() => {
        getUserSettings()
        let id = params.match.params.id
        let type = 3;

        getUser();
        getBranches();
        getItems();
        getExpensesAccounts();

        tables.forEach((table, index) => {
            getDefaults(table.name, table.method)
            index === tables.length - 1 ? getSettings() : ""
        })

        setpType(parseInt(type));

        setTimeout(
            function () {
                setDisableSaveButton(false)
            }, 1000);
    }, [])

    useEffect(() => {
        let parmId = params.match.params.id ?  params.match.params.id : 0;
        let id = parseInt(parmId);
        getPurchase(id);
    }, [loader])


    useEffect(() => {
        setPurchase({ ...purchase, warehouseId: cashBox?.warehouseId })
    }, [cashBox])

    return (
        <div>
            <div style={{ transition: 'all 2s' }} className="container-fluid px-2 mt-3">
                <Search back={`#/expenses`} search={false} save={checkInvoiceNo} newHandle={newPurchase} printClick={!otherPurchases.includes(pType) ? print : 0} disableSaveButton={disableSaveButton} />
                <div className="card border-left-primary shadow h-100 mt-1 pt-1 pb-3">
                    {loader && <LoaderComponent type="ThreeDots" className="text-center w-100" color="#2BAD60" height="100" width="100" />}
                    {/* <PurchaseData data={partners}></PurchaseData> */}
                    {!loader && <>

                        {!showPayments &&
                            <div className={`form col-md-12 px-4`} >
                                <div className="row">
                                    <div className={`col-md-9 col-12`}>
                                        <div className="row">
                                            <div className={`col-md-4 col-12 pl-0`}>
                                                <div className="row align-items-center">
                                                    <div className="col">
                                                        <label className="text-xs font-weight-bold text-primary mb-0">{helper().translate('PARTNER')}</label>
                                                        <Select
                                                            name="partnerId"
                                                            placeholder={helper().translate("CHOOSE_PARTNER")}
                                                            value={partners?.find(v => v.value == purchase?.partnerId) || ''}
                                                            onChange={(e) => { setPurchase({ ...purchase, partnerId: e.value }); errors?.partnerId ? setErrors({ ...errors, partnerId: '' }) : '' }}
                                                            options={partners}
                                                            styles={styles}
                                                            className={errors?.partnerId ? 'is-invalid-s' : ''}
                                                        />
                                                    </div>
                                                </div>
                                                <SaleAddPartner onClose={getPartners} props={params} />
                                            </div>
                                            <div className={`col-md-4 col-12 l-straight pl-0 d-md-flex`}>
                                                <div className="col-md-6 pl-0 pr-1">
                                                    <label className="text-xs font-weight-bold text-primary mb-0">{helper().translate('INVOICE_NO')}</label>
                                                    <input className={`form-control form-control-sm ${errors?.invoiceNo ? 'is-invalid' : ''}`} name="invoiceNo" value={purchase?.invoiceNo} onChange={(e) => { setPurchase({ ...purchase, invoiceNo: e.target.value }); errors?.purchaseTypeId ? setErrors({ ...errors, invoiceNo: '' }) : '' }}></input>
                                                </div>
                                                <div className="col-md-6 pr-0 pl-1">
                                                    <label className="text-xs font-weight-bold text-primary mb-0">{helper().translate('REFERENCE_NO')}</label>
                                                    <input className="form-control form-control-sm" name="reference" value={purchase?.reference} onChange={(e) => setPurchase({ ...purchase, reference: e.target.value })}></input>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            {localPurchases.includes(pType) &&
                                                <div className={`col-md-4 col-12 pl-0 ${purchase.paymentMethodId === 1 ? 'd-flex' : ''}`}>
                                                    {!payments?.length &&
                                                        <div className="col-3 px-0">
                                                            <label className="text-xs font-weight-bold text-primary mb-0">{helper().translate('PAYMENT')}</label>
                                                            <div className="text-light">

                                                                <Switch
                                                                    name="pay"
                                                                    onColor="#86d3ff"
                                                                    onHandleColor="#2693e6"
                                                                    handleDiameter={25}
                                                                    width={55}
                                                                    height={20}
                                                                    uncheckedIcon={<span style={{ display: 'flex', margin: '-4px 0 0 -5px' }}>{helper().translate('NO')}</span>}
                                                                    checkedIcon={<span style={{ display: 'flex', margin: '-4px 0 0 -5px' }}>{helper().translate('YES')}</span>}
                                                                    padding="7px 0px"
                                                                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                                                    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                                                    checked={purchase.pay}
                                                                    onChange={(e) => { setPurchase({ ...purchase, pay: e, paymentMethodId: e ? 1 : 3, amount: (purchaseTotals.total + purchaseTotals.vatSum) }) }}
                                                                />
                                                            </div>
                                                        </div>
                                                    }
                                                    {!payments?.length && purchase.pay && <>
                                                        <div className="col-9 pr-0">
                                                            <label className="text-xs font-weight-bold text-primary mb-0">{helper().translate('SUM')}</label>
                                                            <input name="amount" value={purchase.amount} onChange={(e) => setPurchase({ ...purchase, amount: e.target.value })} className="form-control form-control-sm" ></input>
                                                        </div>
                                                    </>
                                                    }
                                                    {payments?.length > 0 && <div className="row mx-0 w-100">
                                                        <div className="col-6 px-0">
                                                            <label className="text-xs font-weight-bold text-primary mb-0">{helper().translate('PAID_SUM')}</label>
                                                            <br /><span>{payments?.reduce((accumulator, current) => parseFloat(accumulator) + parseFloat(current.amountPaid), 0)}</span>
                                                        </div>
                                                        <div className="col-6 px-0 mt-3">
                                                            <button className="btn btn-link" onClick={() => setShowPayments(true)}>{helper().translate('PAYMENTS')}</button>
                                                        </div>
                                                    </div>
                                                    }

                                                </div>
                                            }
                                            {!localPurchases.includes(pType) &&
                                                <div className={`col-md-4 col-12 pl-0`}>
                                                    <div className="">
                                                        <label className="text-xs font-weight-bold text-primary mb-0">{helper().translate('PAYMENT_METHOD')}
                                                        </label  >
                                                        <Select
                                                            name="paymentMethodId"
                                                            value={payMethods?.find(v => v.value == purchase?.paymentMethodId)}
                                                            onChange={(e) => { setPurchase({ ...purchase, paymentMethodId: e.value }); errors?.paymentMethodId ? setErrors({ ...errors, paymentMethodId: '' }) : '' }}
                                                            options={!localPurchases.includes(pType) ? payMethods?.filter(record => record.value != 1) : payMethods}
                                                            styles={styles}
                                                            className={errors?.paymentMethodId ? 'is-invalid-s' : ''}
                                                        />
                                                    </div>
                                                </div>
                                            }
                                            <div className={` col-md-4 col-12 pl-0`}>
                                                <div className="">
                                                    <div className='col-md-12 col-12 pr-0 pl-0'>
                                                        <label className="text-xs font-weight-bold text-primary mb-0">{helper().translate('INVOICE_DATE')}</label>
                                                        <input
                                                            name="date"
                                                            className="form-control form-control-sm"
                                                            type="date"
                                                            value={helper().isValidDate(purchase?.date) ? purchase?.date.toISOString().substring(0, 10) : ""}
                                                            // max="9999-06-14T00:00"
                                                            onChange={(e) => changeField(e)}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={`col-md-4 col-12 pl-0`}>
                                                <div className='row'>
                                                    <div className="col-md-4 col-12 pl-0">
                                                        <div className=" d-flex flex-column align-items-center">
                                                            <label className="text-xs font-weight-bold text-primary mt-2 mb-0">{helper().translate('WITH_VAT')}</label>
                                                            {config?.withVat}
                                                            <div className="text-light">
                                                                <Switch
                                                                    name="withVat"
                                                                    onColor="#86d3ff"
                                                                    onHandleColor="#2693e6"
                                                                    handleDiameter={25}
                                                                    width={55}
                                                                    height={20}
                                                                    uncheckedIcon={<span style={{ display: 'flex', margin: '-4px 0 0 -5px' }}>{helper().translate('NO')}</span>}
                                                                    checkedIcon={<span style={{ display: 'flex', margin: '-4px 0 0 -5px' }}>{helper().translate('YES')}</span>}
                                                                    padding="7px 0px"
                                                                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                                                    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                                                    onChange={(e) => setPurchase({ ...purchase, withVat: e })}
                                                                    checked={purchase?.withVat ? true : false}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-8 col-12 pl-0">
                                                        <label className="text-xs font-weight-bold text-primary mb-0">{helper().translate('WAREHOUSE')}
                                                        </label  >
                                                        {/* <Select
                                                    name="warehouseId"
                                                    placeholder={helper().translate("CHOOSE_WAREHOUSE")}
                                                    value={warehouses?.filter(v => v.value === purchase?.warehouseId)}
                                                    onChange={(e) => {setPurchase({ ...purchase, warehouseId: e.value }); errors?.warehouseId ? setErrors({...errors, warehouseId: ''}) :''}}
                                                    options={warehouses}
                                                    styles={styles}
                                                    className={errors?.warehouseId ? 'is-invalid-s': ''}
                                                    isDisabled={!userSettings?.allowToChangeWarehouse}
                                                /> */}
                                                        <SelectByGroup
                                                            name="warehouseId"
                                                            parent={branches}
                                                            child={warehouses}
                                                            childParentId={"branchId"}
                                                            placeholder={helper().translate("CHOOSE_WAREHOUSE")}
                                                            styles={styles}
                                                            isDisabled={!userSettings?.allowToChangeWarehouse}
                                                            onChange={(e) => { setPurchase({ ...purchase, warehouseId: e.value }); errors?.warehouseId ? setErrors({ ...errors, warehouseId: '' }) : '' }}
                                                            value={purchase?.warehouseId}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`text-box d-sm-none d-none d-md-block col-3 pl-0`}>
                                        <label className="text-xs font-weight-bold text-primary mb-0">{helper().translate('COMMENT')}</label>
                                        <textarea className="textareas form-control h-81" name="comment" value={purchase.comment} onChange={(e) => setPurchase({ ...purchase, comment: e.target.value })}
                                        />
                                    </div>
                                </div>
                            </div>}

                        {showPayments &&
                            <div className={`col-md-12 col-lg-12 col-xl-12`}>
                                <label className="text-xs font-weight-bold text-primary mb-3">{helper().translate('PAYMENTS')}</label>
                                <PaymentPurchases payments={payments} showPayments={() => setShowPayments(!showPayments)} purchase={purchase} partners={partners} getPurchase={getPurchase} user={user} />
                            </div>
                        }
                    </>}

                </div>
                <Modal
                    show={showModal}
                    size='lg'
                    scrollable={true}
                    onHide={modalClose}
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header closeButton>
                        <Modal.Title className="ms-3" >Shto Artikull</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Item props={params} fromPurchase={true} articleType={purchase.purchaseTypeId === 1 ? 1 : 4} pc={afterNewItem}></Item>
                    </Modal.Body>
                </Modal >
                <div className="card shadow mt-1">
                    {/* <div className="card-header py-2">
                        <h6 className="m-0 font-weight-bold text-primary">Artikujt</h6>
                    </div> */}
                    {
                        showHiddeBox && <div className='datatable-columns' style={{ margin: '50px' }}>
                            {renderColumns()}
                        </div>
                    }

                    <div className="card-body table-responsive pb-0 pt-1 px-2 mt-1 purchase-tab">
                        {loader && <LoaderComponent />}
                        {!loader && <table style={{ overflowX: 'scroll', tableLayout: 'fixed' }} className="table table-hover text-center sales-table purchase-table">
                            <thead>
                                <tr>
                                    <th style={{ width: '45px' }}>{helper().translate('NU')}. <i className="fas fa-ellipsis-v float-right mx-2 mt-1 cursor-pointer" onClick={() => setShowHiddeBox(!showHiddeBox)}></i></th>

                                    {checkHeader('EXPENSE') && <th style={{ width: '250px' }}>{helper().translate('EXPENSE')}</th>}
                                    {checkHeader('PROJECT') && <th style={{ width: '120px' }}>{helper().translate('PROJECT')}</th>}
                                    {checkHeader('ITEM') && <th style={{ width: '250px' }}>{helper().translate('ITEM')}</th>}
                                    {checkHeader('UNIT') && <th style={{ width: '250px' }}>{helper().translate('UNIT')}</th>}
                                    {checkHeader('QUANTITY') && <th style={{ width: '75px' }}>{helper().translate('QUANTITY')}</th>}
                                    {checkHeader('PRICE') && <th style={{ width: '75px' }}>{helper().translate('PRICE')}</th>}
                                    {checkHeader('VAT') && purchase.withVat ? <th>{helper().translate('VAT')} %</th> : ''}
                                    {checkHeader('PRICE_WITH_VAT') && <th style={{ width: '75px' }}>{helper().translate('PRICE_WITH_VAT')}</th>}
                                    {<th>{ helper().translate('TOTAL')}</th>}
                                    {purchase.withVat ? <th style={{ width: '75px' }}>{helper().translate('VAT_VALUE')}</th> : ''}
                                    {purchase.withVat ? <th style={{ width: '75px' }}>{helper().translate('TOTAL_WITH_VAT')}</th> : ''}
                                    <th style={{ width: '25px' }}>+</th>
                                    <th style={{ width: '25px' }}>-</th>
                                </tr>
                            </thead>
                            <tbody className='pb-5'>
                                {[...purchaseDetails]?.map((item, key) => (item.status !== status.deleted &&
                                    <tr key={item.no} >
                                        <td>{item.no}</td>

                                        {/* Expense */}
                                        {
                                            item.editMode  ?
                                            checkHeader('EXPENSE') && <td className="position-relative"><Select className={errors?.['pd_expense_' + item.no] ? 'is-invalid-s' : ''} styles={styles} menuPortalTarget={document.body} value={expenseAccounts?.map(record => ({ value: record.value, label: record.label })).find(i => i.value === item.expenseId)} onChange={onFieldChange(item.no, item.expenseId)} options={expenseAccounts?.map(record => ({ value: record.value, label: record.label }))} ref={item.editMode ? selectRef : ''} id={`expenseId${item.no}`} name="expenseId" ></Select></td>
                                            :
                                            checkHeader('EXPENSE') && <td onClick={function () { onRowClick(item.no) }}><span className={`text-center text-${errors?.['pd_item_' + item.no] ? 'danger' : 'dark'} small`}>{item.expenseId > 0 ? expenseAccounts?.find(i => i.value === item.expenseId)?.label : 'Zgjedh shpenzimin'}</span></td>
                                        }
                                        {/* Projekti */}
                                        {
                                            item.editMode  ?
                                            checkHeader('PROJECT') && <td><Select styles={styles} menuPortalTarget={document.body} value={projects.find(i => i.id === item.projectId )} onChange={onFieldChange(item.no, item.projectId)} options={projects} name="projectId"></Select></td>
                                            :
                                            checkHeader('PROJECT') && <td onClick={function () { onRowClick(item.no) }}><span className="text-center text-dark small">{item.projectId ? setProject(item.projectId) : 'Zgjedh projektin'}</span></td>
                                        }
                                        {/* Item name */}
                                        {
                                            item.editMode ?
                                            checkHeader('ITEM') && <td className="position-relative"><Select className={errors?.['pd_item_' + item.no] ? 'is-invalid-s' : ''} styles={styles} menuPortalTarget={document.body} value={items?.map(record => ({ value: record.value, label: record.label })).find(i => i.value === item.itemId)} onChange={onFieldChange(item.no, item.itemId)} options={items?.map(record => ({ value: record.value, label: record.label }))}  id={`itemId_${item.no}`} name="itemId" ></Select></td>
                                            :
                                            checkHeader('ITEM') && <td onClick={function () { onRowClick(item.no) }}><span className={`text-center text-${errors?.['pd_item_' + item.no] ? 'danger' : 'dark'} small`}>{item.itemId > 0 ? items?.find(i => i.value === item.itemId)?.label : 'Zgjedh arttikullin'}</span></td>
                                        }
                                         {/* Unit  */}
                                         {
                                            checkHeader('UNIT') && <td> <span className={`text-center text-dark' small`}>{ item.unit}</span></td>
                                        }

                                        {/* sasia */}
                                        {
                                            item.editMode && item.expenseId ?
                                            checkHeader('QUANTITY') && <td><input type="number" value={item.quantity} onChange={onFieldChange(item.no)} name="quantity" className="form-control form-control-sm text-center" /></td>
                                            :
                                            checkHeader('QUANTITY') && <td onClick={function () { onRowClick(item.no) }}><span className="text-center text-dark small">{item.quantity ? item.quantity : '0'}</span></td>
                                        }
                                        {/* cmimi pa tvsh */}
                                        {
                                            item.editMode && item.expenseId ?
                                            checkHeader('PRICE') && <td><input type="number" min="0" step="any" value={helper().toFixed2('price', item.price, true)} onChange={onFieldChange(item.no)} name="price" className={`form-control form-control-sm text-center ${errors?.['pd_price_' + item.no] ? 'is-invalid mb-0' : ''}`} /></td>
                                            :
                                            checkHeader('PRICE') && <td onClick={function () { onRowClick(item.no) }}><span className={`text-center text-${errors?.['pd_price_' + item.no] ? 'danger' : 'dark'} small`}>{errors?.['pd_price_' + item.no] ? 'Çmimi nuk mund të jetë 0!' : item.price ? helper().toFixed2('price', item.price, true) : '0'}</span></td>
                                        }
                                        
                                        {/* tvsh */}
                                        {
                                            checkHeader('VAT') && purchase.withVat ?
                                            (item.editMode)  ?                                            
                                            <td><Select styles={styles} menuPortalTarget={document.body} value={vatLevels.find(i => i.value === item.vat)} onChange={onFieldChange(item.no, item.itemId)} options={vatLevels} name="vat" ></Select></td>
                                            :
                                            <td onClick={function () { onRowClick(item.no) }}><span className="text-center text-dark small">{item.vat ? item.vat : '0'}</span></td>
                                            : ''
                                        }
                                        {/* cmimi me tvsh */}
                                        {
                                            checkHeader('PRICE_WITH_VAT') && <td onClick={function () { onRowClick(item.no) }}> <span className={`text-center text-${errors?.['pd_priceWithVat_' + item.no] ? 'danger' : 'dark'} small`}>{errors?.['pd_priceWithVat_' + item.no] ? 'Çmimi nuk mund të jetë 0!' : item.priceWithVat ? helper().toFixed2('priceWithVat', item.priceWithVat, true) : '0'}</span></td>
                                        }
                                        {/* total */}
                                        {
                                            item.editMode  ? 
                                            <td><input type="number" value={item.total} onChange={onFieldChange(item.no)} name="total" className="form-control form-control-sm text-center" /></td>
                                            :
                                            <td onClick={function () { onRowClick(item.no) }}><span className="text-center text-dark small">{helper().formatNumber(parseFloat(item.total), true)}</span></td>
                                        }

                                        {/* shuma tvsh-se */}
                                        {
                                            purchase.withVat ? <td onClick={function () { onRowClick(item.no) }}><span className="text-center text-dark small">{
                                                helper().formatNumber(parseFloat(item.vatSum), true)
                                            }</span>
                                                <input type="hidden" name="vatSum" value={helper().toFixed2('vatSum', item.vatSum, true)} />
                                            </td> : ''
                                        }
                                        {/* totali me tvsh */}
                                        {
                                            purchase.withVat ? <td onClick={function () { onRowClick(item.no) }}><span className="text-center text-dark small">{
                                                helper().formatNumber(parseFloat(item.totalWithVat), true)
                                            }</span></td> : ''
                                        }
                                        {
                                            purchaseDetails.filter(p => p.status !== status.deleted).length === key + 1 && item.expenseId ? <td><button className="btn-edit" onClick={() => addRow()}><span className="icon text-white-40"><i className="fas fa-plus-circle"></i></span></button></td> : <td></td>
                                        }
                                        {
                                            purchaseDetails.length > 1 ? <td><button onClick={() => deleteRow(item.no)} className="btn-delete"><span className="icon text-white-40"><i className="fas fa-minus-circle"></i></span></button></td> : <td></td>
                                        }
                                    </tr>
                                ))}
                                <tr style={{ fontSize: '14px' }}>
                                    <td colspan={purchase.withVat ? 9 - uncheckedHdrs : 8 - uncheckedHdrs} className="border-bottom-0"></td>
                                    {<td style={{ background: '#f8f9fc', fontSize: '14px' }} className="font-weight-bold"><small>{helper().translate('TOTAL')} {purchase.withVat ? helper().translate('WITHOUT_VAT') : ''}</small><br></br>{helper().formatNumber(parseFloat(purchaseTotals.total))}</td>}

                                    {purchase.withVat ? <td style={{ background: '#f8f9fc', fontSize: '14px' }} className="font-weight-bold"><small>{helper().translate('VAT')}</small><br></br>{helper().formatNumber(parseFloat(purchaseTotals.vatSum))}</td> : ''}
                                    {(purchase.withVat) ? <td style={{ background: '#f8f9fc', fontSize: '14px' }} className="font-weight-bold"><small>{helper().translate('TOTAL_WITH_VAT')}</small><br></br>{helper().formatNumber((parseFloat(purchaseTotals.total)))}</td> : ''}
                                </tr>
                            </tbody>
                        </table>
                        }

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Expense
