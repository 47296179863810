import React, { useEffect, useState } from 'react';
import { MDBInput } from 'mdb-react-ui-kit';
import HeaderTools from '../../PageComponents/Header/HeaderTools'
import User from '../../Models/User';
import Switch from "react-switch";
import apiService from '../../Services/apiServices';
import globals from '../../Services/globals';
import helper from '../../Services/helper';
import { Alert } from 'bootstrap';
import { useToasts } from 'react-toast-notifications';
import Loader from 'react-loader-spinner';
import loginService from '../../Services/loginService';
const UserData = () => {
    const { addToast } = useToasts();
    var logedInUser = globals().getLogedInUser();


    const settingsObj = {
        id: 0,
        userId: logedInUser ? logedInUser.data.id : 0,
        allowToChangeSalePrice: false,
        digitsOnDetails: 2,
        digits: 2,
        style: "",
        searchStatus: false,
        allowToChangeWarehouse: false,
        allowToDelete: false,
        backupPath: "",
        username: "",
        language: ""
    }

    const [mainUser, setMainUser] = useState();
    const [user, setUser] = useState();
    const [cashBox, setCashBox] = useState();
    const [settings, setSettings] = useState(settingsObj);
    const [loader, setLoader] = useState(false);

    // const colors = ['#2d2c2c', '#4e73df', 'Green', 'OliveDrab', 'Goldenrod', 'Crimson', 'Maroon', 'Olive', 'DarkGreen', 'SaddleBrown', 'OrangeRed'];
    const [roleId, setRoleId] = useState();

    const colors = ['#5a5c69', '#39107B', '#858796', '#36b9cc', '#1cc88a']
    const languages = ['sq', 'en']

    const [haveSettings, setHaveSettings] = useState(false)
    //const userSettings = helper().getItem('userSettings');



    const getMainUser = async () => {
        await loginService().queryMyLogins('user', 'userName=' + logedInUser.data.username)
            .then((response) => {
                if (response.success) {
                    let u = response.data[0];
                    u.password = "";
                    setMainUser(u);
                    setLoader(false);
                }
                else responseMessage(response, response.errorMessage + "serverlogin")
            });

    }
    const getUser = async () => {
        await apiService().query('user', 'userName=' + logedInUser.data.username)
            .then((response) => {
                if (response.success) {
                    let u = response.data[0];
                    u.password = "";
                    setUser(u);
                    apiService().query('cashBox', 'id=' + u.cashBoxId)
                        .then((response) => {
                            if (response.success) {
                                let u = response.data[0];
                                setCashBox(u);
                                setLoader(false);
                            }
                            else responseMessage(response, response.errorMessage)
                        })
                }
                else responseMessage(response, response.errorMessage + "user lokal")
            })
    }

    const getUsertSettings = async () => {
        await apiService().query('userSettings', `username=${logedInUser.data.username}`)
            .then((response) => {
                if (response.success && response.data?.length > 0) {
                    let u = response.data[0]
                    setSettings(u)
                    setHaveSettings(true);
                    setLoader(false);
                }
            })
    }

    const saveSettings = () => {
        //settings.userId_1 = user.id;
        if (!haveSettings) {
            apiService().post('userSettings', settings)
                .then((response) => {
                    globals().getUserSettings(logedInUser, true)
                    responseMessage(response, 'Saved Successfully')
                })
        }
        else {
            apiService().put('userSettings', settings)
                .then((response) => {
                    globals().getUserSettings(logedInUser, true)
                    responseMessage(response, 'Saved Successfully')
                })
        }
    }

    const save = () => {
        loginService().putMyLogins('users', mainUser, 'logins')
        .then((response) => {
            if (response.success) {
                    saveSettings();
                }
                else {
                    responseMessage(response, response.errorMessage)
                }
            });
    }

    const responseMessage = (response, message) => {
        if (response.success) {
            addToast(message, { appearance: 'success', autoDismiss: true, });
            // getUsertSettings();
            // getUser();
            // getMainUser();
        }
        else {
            addToast(message, { appearance: 'error', autoDismiss: true, });
        }
    }

    useEffect(() => {
        setLoader(true);
        getMainUser().then((response) => {
            getUser();
            getUsertSettings();


        });
    }, []);
    return (

        <div className="col-lg-8">
            {loader && <Loader type="ThreeDots" className="text-center w-100" color="#049cdc" height="100" width="100" />}
            {!loader && <div className='mb-5'>
                <div className="card border-left-primary shadow mb-2">
                    <div className="card-body py-0">
                        <div className="row no-gutters align-items-center">
                            <div className="col mr-2 row">
                                <div className="col-lg-12 form-group my-0 d-flex align-items-center">
                                    <div className="d-table-cell no-shadow">
                                        <HeaderTools save={() => save()} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card mb-4">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-sm-3">
                                <p className="mb-0">{helper().translate('NAME')}</p>
                            </div>
                            <div className="col-sm-9">
                                {roleId !== 100 && <MDBInput type="text" className="form-control text-black mb-3" onChange={(e) => setMainUser({ ...mainUser, firstName: e.target.value })} value={mainUser?.firstName ? mainUser?.firstName : ""} label={helper().translate('NAME')}></MDBInput>}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-3">
                                <p className="mb-0">{helper().translate('SURNAME')}</p>
                            </div>
                            <div className="col-sm-9">
                                {roleId !== 100 && <MDBInput type="text" className="form-control text-black mb-3" onChange={(e) => setMainUser({ ...mainUser, lastName: e.target.value })} value={mainUser?.lastName ? mainUser?.lastName : ""} label={helper().translate('SURNAME')}></MDBInput>}
                            </div>
                        </div>
                        <hr />
                        <div className="row">
                            <div className="col-sm-3">
                                <p className="mb-0">{helper().translate('EMAIL')}</p>
                            </div>
                            <div className="col-sm-9">
                                {roleId !== 100 && <MDBInput type="text" className="form-control text-black mb-3" onChange={(e) => setMainUser({ ...mainUser, email: e.target.value })} value={mainUser?.email ? mainUser?.email : ""} label={helper().translate('EMAIL')}></MDBInput>}
                            </div>
                        </div>
                        <hr />
                        <div className="row">
                            <div className="col-sm-3">
                                <p className="mb-0">{helper().translate('PHONE')}</p>
                            </div>
                            <div className="col-sm-9">
                                {roleId !== 100 && <MDBInput type="text" className="form-control text-black mb-3" onChange={(e) => setMainUser({ ...mainUser, phone: e.target.value })} value={mainUser?.phone ? mainUser?.phone : ""} label={helper().translate('PHONE')}></MDBInput>}

                            </div>
                        </div>
                        <hr />
                        <div className="row">
                            <div className="col-sm-3">
                                <p className="mb-0">{helper().translate('CASHBOX')}</p>
                            </div>
                            <div className="col-sm-9">
                                <p className="text-muted mb-0 fw-bold">{cashBox?.name}</p>
                            </div>
                        </div>
                        <hr />
                        <div className="row">
                            <div className="col-sm-3">
                                <p className="mb-0">{helper().translate('LANGUAGE')}</p>
                            </div>
                            <div className="col-sm-9">
                                {languages.map(lang => (
                                    <div key={lang} className="form-check form-check-inline mr-2">
                                        {/* <input className="form-check-inpur" type="radio" name="style" checked={settings?.language === lang} id={`lang_${lang}`} value={lang} onChange={(e) => setSettings({...settings, language:e.target.value})} />
                                                    <label style={{background: 'red'}} className="rform-check-label fas fa-check" for={`lang_${lang}`}>{lang}</label> */}

                                        <input type="radio" className="btn-check" name="language" checked={settings?.language === lang} id={`lang_${lang}`} value={lang} onChange={(e) => setSettings({ ...settings, language: e.target.value })} />
                                        <label className="btn btn-outline-success" htmlFor={`lang_${lang}`}>{helper().translate([lang])}</label>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-7">
                        <div className="card mb-4 mb-md-0">
                            <div className="card-body">
                                <p className="mb-4">
                                    <span className="text-primary font-italic me-1 h5">
                                       {helper().translate('OTHERS')}
                                    </span>
                                </p>
                                <div className="col-md-12 col-lg-12 col-xl-12 mb-3">
                                    <label className="mr-3">{helper().translate('ALLOW_TO_CHANGE_SALE_PRICE')}</label>
                                    <Switch
                                        id="withVat"
                                        className="text-light float-right"
                                        onColor="#86d3ff"
                                        onHandleColor="#2693e6"
                                        handleDiameter={25}
                                        width={65}
                                        height={25}
                                        uncheckedIcon={helper().translate('NO')}
                                        checkedIcon={helper().translate('YES')}
                                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                        onChange={(e) => setSettings({ ...settings, allowToChangeSalePrice: e })}
                                        checked={settings?.allowToChangeSalePrice}
                                    />
                                </div>
                                <div className="col-md-12 col-lg-12 col-xl-12 mb-3">
                                    <label className="mr-3">{helper().translate('ARTICLE_SEARCH')}</label>
                                    <Switch
                                        id="withVat"
                                        className="text-light float-right"
                                        onColor="#86d3ff"
                                        onHandleColor="#2693e6"
                                        handleDiameter={25}
                                        width={65}
                                        height={25}
                                        uncheckedIcon={helper().translate('NO')}
                                        checkedIcon={helper().translate('YES')}
                                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                        onChange={(e) => setSettings({ ...settings, searchStatus: e })}
                                        checked={settings?.searchStatus}
                                    />
                                </div>
                                <div className="col-md-12 col-lg-12 col-xl-12 mb-3">
                                    <label className="mr-3">{helper().translate('ALLOW_TO_CHANGE_WAREHOUSE')}</label>
                                    <Switch
                                        id="withVat"
                                        className="text-light float-right"
                                        onColor="#86d3ff"
                                        onHandleColor="#2693e6"
                                        handleDiameter={25}
                                        width={65}
                                        height={25}
                                        uncheckedIcon={helper().translate('NO')}
                                        checkedIcon={helper().translate('YES')}
                                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                        onChange={(e) => setSettings({ ...settings, allowToChangeWarehouse: e })}
                                        checked={settings?.allowToChangeWarehouse}
                                    />
                                </div>
                                <div className="col-md-12 col-lg-12 col-xl-12 mb-3">
                                    <label className="mr-3">{helper().translate('ALLOW_TO_DELETE')}</label>
                                    <Switch
                                        id="withVat"
                                        className="text-light float-right"
                                        onColor="#86d3ff"
                                        onHandleColor="#2693e6"
                                        handleDiameter={25}
                                        width={65}
                                        height={25}
                                        uncheckedIcon={helper().translate('NO')}
                                        checkedIcon={helper().translate('YES')}
                                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                        onChange={(e) => setSettings({ ...settings, allowToDelete: e })}
                                        checked={settings?.allowToDelete}
                                    />
                                </div>
                                <MDBInput type="text" name="digits" value={settings?.digits} onChange={(e) => setSettings({ ...settings, digits: e.target.value })} className={`form-control text-black mb-3 ${settings?.digits ? 'active' : ''}`} id="inputDigits" label={helper().translate('DIGITS_NO')} />
                                <MDBInput type="number" name="digitsOnDetails" value={settings?.digitsOnDetails} onChange={(e) => setSettings({ ...settings, digitsOnDetails: e.target.value })} className={`form-control text-black mb-3 ${settings?.digitsOnDetails ? 'active' : ''}`} id="inputDigitsOnDetails" label={helper().translate('DIGITS_NO_DETAILS')} />

                            </div>
                        </div>
                    </div>
                    <div className="col-md-5">
                        <div className="card mb-4 mb-md-0">
                            <div className="card-body">
                                <p className="mb-4">
                                    <span className="text-primary font-italic me-1 h5">
                                        {helper().translate('COLORS')}
                                    </span>
                                </p>
                                {colors.map(color => (
                                    <div key={color} role="button" className="progress rounded mb-4" onClick={(e) => setSettings({ ...settings, style: color })}
                                        style={{ height: settings?.style === color ? 35 : 23, backgroundColor: color }}>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>}
        </div>

    )
}

export default UserData
