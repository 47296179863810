
import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import { MDBInput } from "mdb-react-ui-kit";
import apiService from "../../../Services/apiServices";
import { useToasts } from "react-toast-notifications";
import Search from "../../../PageComponents/Search/Search_Old";
import globals from "../../../Services/globals";
import { confirmAlert } from "react-confirm-alert";
import helper from "../../../Services/helper";
import ExcelExport from "../../../PageComponents/Excel/ExcelExport";
import Print from "../../../PageComponents/Print/Print";
import Switch from "react-switch";



function BalanceSheet(props) {

  const { addToast } = useToasts();
  const excelExportRef = useRef();
  const [mainData, setMainData] = useState([]);
  const [dataForPrint, setDataForPrint] = useState([]);
  const [filterPrintData, setFilterPrintData] = useState([]);
  const [searchParams, setSearchParams] = useState({ projectId : 0, branchId: 0, includeStartDate : true, includeEndDate: true});
  const [projects, setProjects] = useState([]);
  const [isPositive, setIsPositive] = useState();
  const [branches, setBranches] = useState([]);
  const [accountingGroup, setAccountingGroup] = useState([]);
  const [showProgress, setShowProgress] = useState(false);
  const searchObj = { CompanyBranch: 0, PurchaseType: -1 , StartDate: helper().formatDate(searchParams?.startDate,'MM-dd-yyyy')  ,  EndDate: helper().formatDate(searchParams?.endDate,'MM-dd-yyyy')}
  const printRef = useRef();

  

  ///const [startDate, setStartDate] = useState(new Date());

  useEffect(() => {
    getDates();
    getBranches();
    getProjects();
    getAccountingGroups();
    setIsPositive(false);

  }, [])


  useEffect(() => {
    if(isPositive === true){
     setFilterPrintData(dataForPrint)
    }else{
    let filteredArray = dataForPrint.filter(obj => obj.listItem != true);
    setFilterPrintData(filteredArray);
    }

  }, [dataForPrint,isPositive])

  const exportToExcel = () => {
    // let table = document.getElementById('table-to-xls-1').innerHTML;
    let fileName = helper().translate("INCOME_STATEMENT");
    // return helper().exportToExcel(table, fileName)
    excelExportRef.current.exportToExcel(fileName)
    //"Conversion failed when converting date and/or time from character string."
  }

  const print = () => {
    printRef.current.printPreview()
  }

  const headers = [
    { key: 'name', columnName: helper().translate("NAME") , type:"text" },
    { key: 'currentValue', columnName: helper().translate("CURRENT_YEAR") , type:"number" }
  ];


  const data = (mData) =>{
    const modifiedData = [];

for (const item of mData) {
  modifiedData.push({
    name: item.name,
    calculateId: item.calculateId,
    currentValue: item.currentValue,
    mainItem: true
  });

  if (item.groupsInfo && item.groupsInfo.length > 0) {
    for (const groupInfo of item.groupsInfo) {
      modifiedData.push({
        name: groupInfo.name.trim(), 
        currentValue: groupInfo.grValue,
        listItem: true
      });
    }
  }
}
  setDataForPrint(modifiedData)
  }

  const getAccountingGroups = () => {
    let options;
    apiService()
      .query("getAccountingGroups")
      .then((response) => {
        if (response.success) {
          setAccountingGroup(response.data);
          // console.log(response.data)
        } else {
          alert("Error: getting PlaniKontabel" + response.errorMessage);
        }
      });
  };


  const calculatesameAccNumberTransactions =(transactions) => {
    const aggregatedTransactions = transactions.reduce((result, transaction) => {
      const { accountNumber, debits, credits } = transaction;
      if (!result[accountNumber]) {
        result[accountNumber] = { ...transaction };
      } else {
        result[accountNumber].debits += debits;
        result[accountNumber].credits += credits;
      }
      return result;
    }, {});
  
    return Object.values(aggregatedTransactions);
  }


  const formatData = function (response) {

    let formatedData = [];
    // Balance sheet main classes  =  1 ASSETS - Pasurit  2 LIABILITIES - detyrimet and 3 EQUITY - Ekuiteti
    let mainClasses = [
      {  groups:[400,460,410] ,  name: "Të Hyrat" , calculateId: 1 , saldo: "credits"},
      { groups:[500,560], name: "Kostoja e shitjes" , calculateId: 1 , saldo: "credits"},
      { name: "Fitimi / (humbja) bruto" , bold: true , calculateTotalId: 1  , calculateId: 2 } ,
      { groups:[402], name: "Të ardhurat tjera" , calculateId: 2 , saldo: "credits"},
      { groups:[600], name: "Shpenzimet e shpërndarjes" , calculateId: 2 , saldo: "credits"},
      { groups:[650], name: "Shpenzimet e administrative" , calculateId: 2  , saldo: "credits"},
      { groups:[670], name: "Shpenzimet e tjera" , calculateId: 2  , saldo: "credits"},
      {  name: "Fitimi / (humbja) operative" , bold: true , calculateTotalId: 2 , calculateId: 3 },
      { groups:[800], name: "Shpenzimet Financiare" , calculateId: 3  , saldo: "credits"},
      { groups:[461], name: "Të ardhurat Financiare" , calculateId: 3 , saldo: "credits"},
      { name: "Fitimi / (humbja) para tatimit" , bold: true , calculateTotalId: 3  , calculateId: 4   },
      { groups:[700], name: "Shpenzimet e tatimit në fitim" , calculateId: 4 , saldo: "credits"},
      { name: "Fitimi / (humbja) i/e vitit" , bold: true , calculateTotalId: 4  , calculateId: 5  }
    ]

    mainClasses.forEach((m) => {
      let mainClass = {}
      if(m?.groups?.length >1 ){
        mainClass.name = m.name;
        mainClass.calculateId = m.calculateId;
        let resultMultipleGroups = 0;
        // for(let g = 0; g < 2; g++){
        //   console.log(m.groups[g])
        // 
        var groupInfo = [];
        m.groups.forEach(g => {
          let gValue= 0;
          if(m.saldo === "credits"){
            gValue = response.filter(i => i.groupId === g).map(i => i.credits - i.debits).reduce((partialSum, a) => partialSum + a, 0); 
            if(response.filter(i => i.groupId === g)[0]?.groupName){
              let filtered = response.filter(i => i.groupId === g)[0];
              let obj= {name: filtered?.groupName, grValue: gValue , accountNumber: filtered?.accountNumber}
              groupInfo.push(obj)
            }
            // let obj = {grName:response.filter(i => i.groupId === g).groupName, }
          }else{
            gValue = response.filter(i => i.groupId === g).map(i => i.debits - i.credits).reduce((partialSum, a) => partialSum + a, 0); 
            if(response.filter(i => i.groupId === g)[0]?.groupName){

              let obj= {name:response.filter(i => i.groupId === g)[0]?.groupName,  grValue: gValue , accountNumber: [0].accountNumber}
              groupInfo.push(obj)
            }
          }
          resultMultipleGroups = resultMultipleGroups + gValue;
          mainClass.currentValue = resultMultipleGroups;
          mainClass.groupsInfo = groupInfo;
        });
      }else if(m?.groups?.length === 1){
        mainClass.name = m.name;
        mainClass.calculateId = m.calculateId;
        var groupInfo = [];
        let gValue = 0;
        if(m.saldo === "credits"){
          
          gValue = response.filter(i => i.groupId === m.groups[0]).map(i => i.credits - i.debits).reduce((partialSum, a) => partialSum + a, 0);
          let resAccObj = response.filter(i => i.groupId === m.groups[0]);
          let aggregatedTransactions = calculatesameAccNumberTransactions(resAccObj);
          aggregatedTransactions.forEach(e => {
            let obj= {name:e?.accName, grValue: parseFloat(e.credits - e.debits), accountNumber: e.accountNumber }
            groupInfo.push(obj)
          });
        }else{
          gValue = response.filter(i => i.groupId === m.groups[0]).map(i => i.debits - i.credits).reduce((partialSum, a) => partialSum + a, 0);
          let resAccObj = response.filter(i => i.groupId === m.groups[0]);
          let aggregatedTransactions = calculatesameAccNumberTransactions(resAccObj);
          aggregatedTransactions.forEach(e => {
            let obj= {name:e?.accName, grValue: parseFloat( e.debits - e.credits )}
            groupInfo.push(obj)
          });
        }
        mainClass.currentValue = gValue;
        mainClass.groupsInfo = groupInfo;
        // console.log(gValue)
        // mainClass.name = accountingGroup.find(f => f.groupId === gId)?.name;

      }else{
        mainClass.name = m.name;
        mainClass.calculateTotalId = m.calculateTotalId;
        mainClass.calculateId = m.calculateId;
        let totalCalculated = formatedData.filter(f => f.calculateId === m.calculateTotalId).reduce((sum, currentItem) => {
          return sum + currentItem.currentValue;
        }, 0);
        mainClass.currentValue = totalCalculated;
      }
      formatedData.push(mainClass);
      // console.log(mainClass)
      
      // console.log(mainClass);
    });

    // console.log(formatedData)
    return formatedData;
  }

  const getDates = () => {

    let eDate = new Date();
    let sDate = new Date(eDate.getFullYear(),0,1,1); 

    setSearchParams({ ...searchParams, startDate: sDate, endDate: eDate});
    
    getGeneralBookWithDetails(sDate, eDate, 0, 0);

  };


  const getGeneralBookWithDetails = (startDate, endDate, branchId, projectId) => {

    if (!projectId) {
      projectId = 0;
    }

    let strStartDate = helper().formatDate(startDate,'MM-dd-yyyy');
    let strEndDate = helper().formatDate(endDate,'MM-dd-yyyy') + " 23:59:59";

    setShowProgress(true);

    var params = `&startDate=${strStartDate}&endDate=${strEndDate}&branchId=${branchId}&projectId=${projectId}`;
    apiService().query('fnGeneralBookWithDetails', params).then((response) => {
      console.log(response)
      if (response.success) {
        let formatedData = formatData(response.data);
        
        let pStartDate = new Date(endDate.getFullYear() - 1, 0, 1);
        let month = endDate.getMonth();
        let day = endDate.getDate();
        let pEndDate = new Date(endDate.getFullYear() -1, month, day);

        setSearchParams({ ...searchParams, startDate: startDate, endDate: endDate, prevStartDate: pStartDate, prevEndDate: pEndDate});
   
        setMainData(formatedData);
        data(formatedData);
        setShowProgress(false);

      } else {
        console.log('Error:' + response.errorMessage);
      }
    });
  };

  
  const getProjects = () => {
    
    apiService()
      .query("getProjects").then((response) => {
        if (response.success) {
          let data = response.data;
         
         

          let firstItem = { id: null, name: helper().translate("ALL_PROJECTS") };

          data.unshift(firstItem);

          let options = data.map((item, index) => ({
            value: item.id,
            label: item.name
          }));

          setProjects(options);

        } else {
          alert("Error: getting Projects" + response.errorMessage);
        }
      });
  };

  const getBranches = () => {
   
    apiService()
      .get("Branches").then((response) => {
        if (response.success) {
          
          let data = response.data;

          let firstItem = { id: null, name: helper().translate("ALL_BRANCHES") };
          data.unshift(firstItem);

          let options = data.map((item, index) => ({
            value: item.id,
            label: item.name
          }));

          setBranches(options);

        } else {
          alert("Error: getting branches" + response.errorMessage);
        }
      });
  };

  const selectChange = (e,  obj) =>{

    if(obj.name==='branches'){
      setSearchParams({...searchParams, branchId: e.value});
    }
    else if(obj.name==='projects'){
      setSearchParams({...searchParams, projectId: e.value});
    }

  }

  const onSearchClick = () => {

    let startDate = searchParams.startDate;
    let endDate = searchParams.endDate;

    if (searchParams.includeStartDate === false) {
      let year = new Date(endDate).getFullYear();
      startDate = `${year}-01-01 00:00:00`;
    } else if (searchParams.includeEndDate === false) {
      endDate = "2222-01-01";
    }

    if(searchParams.branchId === null){
      searchParams.branchId = 0;
    }

    getGeneralBookWithDetails(startDate, endDate, searchParams.branchId, searchParams.projectId)
  }

  const selectStyles = {
    control: (provided, state) => ({
      ...provided,
      background: "#fff",
      borderColor: "#9e9e9e",
      minHeight: "28.59px",
      height: "28.59px",
      boxShadow: state.isFocused ? null : null,
    }),

    valueContainer: (provided, state) => ({
      ...provided,
      height: "28.59px",
      padding: "0 6px",
    }),

    input: (provided, state) => ({
      ...provided,
      margin: "0px",
    }),
    indicatorSeparator: (state) => ({
      display: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "28.59px",
    }),
  };

  const listStyle ={

  }

  const dateChange = (e) => {
    let selected = new Date(e.target.value);
    setStartDate(selected);
  }

  return (
    <div className="container-fluid px-2 mt-3 ">
      <Search back="#/reports" printClick={print} excel={() => exportToExcel()}   />
      <ExcelExport data={filterPrintData.length ? filterPrintData : []} headers={headers} type={'other'} ref={excelExportRef} hideContent={true} />
      <Print data={filterPrintData.length ? filterPrintData : []} headers={headers.filter(header => header !== false)}  ref={printRef} query={searchObj} reportName={helper().translate("INCOME_STATEMENT")} />
      <div className="card shadow">
        <div className="card-header border-left-primary py-2"><h6 className="m-0 font-weight-bold text-primary text-center">{helper().translate("INCOME_STATEMENT")}</h6></div>
       {showProgress && 
            <div style={{position:'absolute',top:'10px', right:'40px', zIndex:1000, color:'#4E73DF', fontSize:18}}>{helper().translate('LOADING')} ...</div>
       }
      </div>

      <div className="card border-left-primary text-dark  overflow-hidden  shadow  mt-1 pt-1 pb-3">
        <div className="container mt-2 ">         
          <div className="row">
            <div className="col-12 col-md-10 mt-2">
              <div className="row no-gutters align-items-center">
                <div className="col row">
                  <div className="col-lg-3 mb-3 mb-md-0 pe-0 d-flex align-items-center justify-content-center ">
                    {/* <label className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                              Data e fillimit
                            </label> */}
                    <div style={{ paddingRight: '3px', border:'1px solid #fff' }}>
                      <input type="checkbox" name="includeStartDate" checked={ searchParams.includeStartDate}  onChange={(e) => setSearchParams({...searchParams, includeStartDate: e.target.checked})} />
                    </div>
                    <div style={{ width: '95%' }}>
                      <MDBInput 
                        name="startDate"
                        disabled={!searchParams.includeStartDate}
                        className="form-control-sm active"
                        onChange={(e) => setSearchParams({ ...searchParams, startDate: new Date(e.target.value)})}
                        type="date"
                        label={helper().translate("START_DATE")}
                        value={helper().isValidDate(searchParams.startDate)  ?  searchParams.startDate.toISOString().substring(0,10): ''}
                      />
                      {/* <input type="date" onChange={pula} value={startDate  ?  startDate.toISOString().substring(0,10): ''}></input> */}
                    </div>
                  </div>
                  <div className="col-lg-3 mb-3 mb-md-0 pe-0 d-flex align-items-center">
                    {/* <label className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                              Data e Mbarimit
                    </label> */}
                    <div style={{ paddingRight: '3px'}}>
                      <input type="checkbox" name="includeStartDate" checked={searchParams.includeEndDate} onChange={(e) => setSearchParams({ ...searchParams, includeEndDate: e.target.checked })} ></input>
                    </div>
                    <div style={{ width: '95%' }}>
                      <MDBInput
                        name="endDate"
                        disabled={!searchParams.includeEndDate}
                        className="form-control-sm active"
                        onChange={(e) => setSearchParams({ ...searchParams, endDate: new Date(e.target.value)})}
                        type="date"
                        label={helper().translate("END_DATE")}
                        value={helper().isValidDate(searchParams.endDate)  ?  searchParams.endDate.toISOString().substring(0,10): ''}
                      />
                    </div>
                  </div>
                  <div style={{ paddingTop: "2px" }} className="col-lg-3 mb-3 mb-md-0 pe-0 ">

                    <Select
                      name="branches"
                      placeholder={helper().translate("BRANCHES")}
                      styles={selectStyles}
                      options={branches}
                      onChange={(e, obj) => selectChange(e, obj)}
                      value={branches?.find(p => p.value === branches.id)}

                    />
                  </div>
                  <div style={{ paddingTop: "2px" }} className="col-lg-3 mb-3 mb-md-0 pe-0 ">

                    <Select
                      name="projects"
                      placeholder={helper().translate("ALL_PROJECTS")}
                      styles={selectStyles}
                      options={projects}
                      onChange={(e, obj) => selectChange(e, obj)}
                      value={projects?.find(p => p.value === projects.projectId)}

                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-1 col-12  d-flex flex-column justify-content-center align-items-center  text-center ">
            <label className="text-xs font-weight-bold text-primary  mb-0">
                            {helper().translate("SHOW_DETAILS")}
                            </label>
            <Switch
                          name="withVat"                     
                          onColor="#86d3ff"
                          offColor="#000000"
                          onHandleColor="#000000"
                          className="text-light "
                          handleDiameter={25}
                          width={55}
                          height={20}
                          uncheckedIcon={<span style={{display:'flex', margin: '-4px 0 0 -4px'}}><p>{helper().translate("NO")}</p></span>}
                          checkedIcon={<span style={{display:'flex', margin: '-4px 0 0 -4px'}}><p>{helper().translate("YES")}</p></span>}
                          padding="0px 0px"
                          boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                          activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                          onChange={(e) => setIsPositive(e)}
                          checked={isPositive}
                      />
            </div>
            <div className="col-md-1 col-12 d-flex align-items-center justify-content-center mt-3 mt-md-1 justify-content-lg-end">
              <div>
                <a className="searchbutton" onClick={() => onSearchClick()}>
                  <i className="fas fa-search fa-2x text-dark"  />
                </a>
              </div>
            </div>
          </div>
         
          {searchParams.startDate && searchParams.endDate && 
          // <div className="row mt-2 font-weight-bold inputMinWitdth" >
          //   {/* <div className=" col-12 col-md-6 offset-md-3 d-flex justify-content-center justify-content-md-start align-items-bottom">
          //   <h6 className="m-0 mr-2 font-weight-bold text-primary text-center ">{helper().translate('INCOME_STATEMENT_BETWEEN_DATES')}:</h6>
          //     <label className="m-0 align-middle"><p className="font-weight-bold text-dark "><u >{ helper().formatDate(searchParams.startDate) + " - " +helper().formatDate(searchParams.endDate) }</u></p></label>
          //   </div> */}
          //   <div className="col-12 col-md-3  d-flex justify-content-center  ">
          //   {/* <h6 className="m-0 w-100 font-weight-bold text-primary text-center ">{helper().translate('INCOME_STATEMENT_BETWEEN_DATES')}:</h6> */}
          //     <label><p className="font-weight-bold text-dark"><u>{ helper().formatDate(searchParams.startDate) + " - " +helper().formatDate(searchParams.endDate) }</u></p></label>
          //   </div>
          // </div>
          <div>
               <div className="row font-weight-bold my-4" >
               <div className="col-12  d-flex justify-content-center justify-content-md-start text-center ">
                 <h6 className="m-0 w-100 font-weight-bold text-primary text-center ">{helper().translate('INCOME_STATEMENT_BETWEEN_DATES')}:</h6>
               </div>
             </div>
             <div className="row mt-2 font-weight-bold inputMinWitdth" >
               <div className=" col-12 col-md-3 offset-md-3 d-flex justify-content-center justify-content-md-start ">
               </div>
               <div className="col-12 col-md-3  d-flex justify-content-center  ">
                 <label><p className="font-weight-bold text-dark"><u>{ helper().formatDate(searchParams.startDate) + " - " +helper().formatDate(searchParams.endDate) }</u></p></label>
               </div>
             </div>
             </div>
          
          }
          {
            
            mainData.map(main => {
              return <>
              <div className={`row  inputMinWitdth ${main.calculateTotalId ? "mt-1" : "mt-3"}`} >
                <div className=" col-6 col-md-6 offset-md-1 d-flex justify-content-start   ">
                  <div  className={`  ${main.calculateTotalId ? "font-weight-bold text-dark text-decoration-underline " : ""}`}>
                    <p className="m-0">{main.name + " :"}</p>
                  </div>
                </div> 
                <div className="col-6 col-md-3  d-flex justify-content-center justify-content-md-start ">
                  <input  className={`form-control form-control-sm font-weight-bold text-right ${main.calculateTotalId ? "border-dark" : ""}`} disabled value={helper().formatNumber(main.currentValue)} >
                  </input>
                </div>
            </div>
            {isPositive && main?.groupsInfo?.length > 0 && main.groupsInfo.map(gr => {
              return <>
              
                <div className={`row my-1 inputMinWitdth `} >
                <div className=" col-6 col-md-2 offset-md-1 d-flex  ">
                      <p className="font-italic m-0">{gr.accountNumber}</p>
                </div> 
                <div className=" col-6 col-md-4 d-flex  ">
                      <p className="font-italic m-0">{gr.name}</p>
                </div> 
                <div className="col-6 col-md-3  d-flex justify-content-center justify-content-md-start ">
                  <input  className={`form-control form-control-sm font-weight-bold text-right ${main.calculateTotalId ? "border-dark" : ""}`} disabled value={helper().formatNumber(gr.grValue)} >
                  </input>
                </div>
            </div>
           
              </>
            })}
              </>
            })
          }

        </div>
      </div>
    </div>

  )
}

export default BalanceSheet;