import React, { Component } from "react";
import "../Sales.css";
import HeaderTools from "../../../PageComponents/Header/HeaderTools";
import Select , {components} from "react-select";
import Switch from "react-switch";
import apiService from "../../../Services/apiServices";
import SaleModel from '../../../Models/Sale';
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css'
import { useToasts } from 'react-toast-notifications';
import globals from "../../../Services/globals";
import SaleAddPartner from "../../../PageComponents/UI/Modal/SaleAddPartner";
import calculate from "../../../Services/calculate";
import Search from "../../../PageComponents/Search/Search_Old";
import LoaderComponent from "../../../PageComponents/UI/Components/LoaderComponent";
import SelectByGroup from "../../../PageComponents/UI/Inputs/SelectByGroup";
import helper from "../../../Services/helper";
import PaymentSales from "./PaymentSales";

function withToast(Component) {
  return function WrappedComponent(props) {
    const toastFuncs = useToasts()
    return <Component {...props} {...toastFuncs} />;
  }
}


class Sale extends Component {

  constructor(props) {
    super(props);
    this.state = {
      sale: new SaleModel(),
      saleDetails: [{no: 1 , id:0,itemId:0, itemName:helper().translate("CHOOSE_ITEM"), barcode:helper().translate("CHOOSE_BARCODE"),quantity: parseInt((this.props.location.search).substring(1,this.props.location.search.length)) === 4 ? -1 : 1 ,
      price:0.0, avgPrice: 0, vat: 0 , vatSum:0 , projectId: 0  , addButton: true , validate: true}],
      payment: [{name: "to be paid" , inputValue: 0 } , {name: "cash" , inputValue: 0 , objective: "pay"} , {name: "pos1" , bankId: 0 , inputValue: 0 , objective: "pay" , disabled: false} , {name: "pos2" ,bankId: 0, inputValue: 0 , objective: "pay" , disabled: false } ,{name: "pos3" , bankId: 0, inputValue: 0 , objective: "pay" , disabled: false} , {name:"totalPayment" , inputValue: 0} , {name: "remainingDebt" , inputValue: 0} , {name: "returnMoney" , inputValue: 0}],
      paymentBtn: {showPayFrom : false  , showPayments: false , pay: false , savePayment: false },
      paymentSales: [],
      defaultAccount: {defaultCashAccount: 0 , defaultBankAccount: 0},
      banksPos: [],
      warehouse: [],
      branches: [],
      partners: [],
      salesType:[],
      loader: true ,
      settings: [],
      items: [],
      fullItems: [],
      projects: [],
      currentUserCashBoxId: 0,
      units:[],
      vatLevel:[],
      payMethod: [],
      invoiceIdFormat: true,
      validate: false,
      partnerShow: false,
      userSettings: '',
      logedInUser: globals().getLogedInUser()
  };

  this.withVatChanged = this.withVatChanged.bind(this);
  this.withCashPayment = this.withCashPayment.bind(this);
  this.rowClick = this.rowClick.bind(this);
  // this.search = this.search.bind(this);
}

componentDidMount() {


  apiService().get('warehouses').then((response) => {
      if (response.success) {
        this.setState({ warehouse: response.data });
      } else {
        console.log('Error:' + response.errorMessage);
      }
    });
    
    var saleId = this.props.match.params.id;
    if(saleId>0){
      this.getSaleDetails(saleId);
      this.getDefaultCashAccount();
      this.getDefaultBankAccount();
      this.getInvoiceGenerateNumberSettings();
      
      
    }else if(saleId == 0){
      this.getWithWatSettings();
      this.getDefaultCashAccount();
      this.getDefaultBankAccount();
      this.getInvoiceGenerateNumberSettings();
    }
    this.getPartners();
    
    

    apiService().query('getProjects').then((response) => {
      if (response.success) {
        this.setState({ projects: response.data });
      } else {
        console.log('Error:' + response.errorMessage);
      }
    });

    apiService().query('getBanksPos').then((response) => {
      if (response.success) {
        this.setState({ banksPos: response.data });
      } else {
        console.log('Error:' + response.errorMessage);
      }
    });
  
    apiService().get('salesType').then((response) => {
      if (response.success) {
        let data = response.data;
        data.forEach(e => {
          e.tokenName = e.name,
          e.name = helper().translate(`${e.name}`)
        });
        this.setState({ salesType: data });
        if(this.props.match.params.id == 0){
          var sale = this.state.sale;
          sale.salesTypeId = parseInt((this.props.location.search).substring(1,this.props.location.search.length));
          this.setState(sale);
          }
      } else {
        console.log('Error:' + response.errorMessage);
      }
    });

    apiService().get('branches')
          .then((response)=> {
              if(response.success) {
                  let records = response.data;
                  this.setState({ branches: records });
              }
    });
  
    apiService().get('units').then((response) => {
      if (response.success) {
        this.setState({ units: response.data });
      } else {
        console.log('Error:' + response.errorMessage);
      }
    });
    let currentUser = globals().getLogedInUser().data.username;
    apiService().query('user',  'userName='+currentUser )
    .then((response) => {
      if (response.success) {
        let cashBox = response.data[0].cashBoxId;
        this.setState({ currentUserCashBoxId: cashBox});

      } else {
        console.log('Error: getting user cashbox' + response.errorMessage);
      }
    });

    
    if(saleId > 0) {
    apiService().query('getSale',  'id='+saleId )
    .then((response) => {
      if (response.success) {
        var data = response.data[0];
        data.date = new Date(data.date)
        data.totalSum = data.totalSum + data.vatSum;
        data.subTotal = data.totalSum - data.vatSum;
        let saleCopy = new SaleModel(data) ;
        globals().getSettings().then((response) => {
          saleCopy.allowLackItems = response.allowLackItems;
          saleCopy.hideOrShowLackItems = response.hideOrShowLackItems;
        });
        this.setState({ sale: saleCopy });
        this.getPaymentSales(saleId);
        this.getItems(data.partnerId , data.warehouseId,true);

      } else {
        console.log('Error:' + response.errorMessage);
      }
    });
  }
  
  apiService().get('vatLevel').then((response) => {
    if (response.success) {
      this.setState({ vatLevel: response.data });
    } else {
      console.log('Error:' + response.errorMessage);
    }
  });
  this.getSettings()
  this.getUserSettings()
}

getSettings = () => {
  globals().getSettings().then((response) => {
    if (response) {
      let settings = response
      this.setState({
          invoiceForm: settings.invoiceForm
      });
  }
})
}

getUserSettings = () => {
  globals().getUserSettings(this.state.logedInUser).then((response) => {
      this.setState({
          userSettings: response
      })
  });
}

componentDidUpdate(prevProps) {
  // Typical usage (don't forget to compare props):
  if (parseInt((this.props.location.search).substring(1,this.props.location.search.length)) !== parseInt((prevProps.location.search).substring(1,prevProps.location.search.length))) {
    if(this.props.match.params.id == 0){
      var sale = this.state.sale;
      sale.salesTypeId = parseInt((this.props.location.search).substring(1,this.props.location.search.length));
      this.setState(sale);
      }
  }
}
getPartners = (incWarehouseVal) => {
  apiService().query('getPartners').then((response) => {
    if (response.success) {
      // if(incWarehouseVal){
      //   var dataFilter = response.data.filter(i => i.status === 1 && (i.partnerBranchId !== null && i.partnerBranchId === this.state.warehouse?.find(ware => ware.id == incWarehouseVal)?.branchId|| i.partnerBranchId === null ));
        
      // }else{
      //   var dataFilter = response.data.filter(i => i.status === 1 && (i.partnerBranchId !== null && parseInt(i.partnerBranchId) === this.state.userSettings.branchId || i.partnerBranchId === null ));
      // }
      
      let dataFilter = response.data.filter(i => i.status === 1);
      
      let firstItem = {id: null , companyName: helper().translate("BUYER")+ ":",fiscalNo: helper().translate("FISCAL_NUMBER")+ ":" , cityName: helper().translate("CITY")+ ":" , isDisabled: true};
      dataFilter.unshift(firstItem)
      this.setState({ partners: dataFilter });
    } else {
      console.log('Error:' + response.errorMessage);
    }
  });
}

getPaymentSales = (saleId) => {
  let saleArr = this.state.sale;
  apiService().query('getPaymentSales', "saleId="+saleId).then((response) => {
    if(response.success){
      var data = response.data;
      let totalPayment = 0;
      data.forEach(i=> {totalPayment = totalPayment +  parseFloat(i.amountPaid)});
      let amountLeft = (parseFloat(saleArr.totalSum) - parseFloat(totalPayment));
      saleArr.totalSalePayment = totalPayment;
      saleArr.amountLeft = amountLeft;
      this.setState({sale: saleArr});
    }
  })
}



getItems = (partnerId, warehouseId,isUpdate) => {
  // let partnerId = this.state.sale.partnerId;
  // let warehouseId = this.state.sale.warehouseId;
  var params = `&partnerId=${partnerId}&warehouseId=${warehouseId}`;
  apiService()
  .query('rptGetItemsWithDiscount', params )
    .then((response) => {
      if(response.success){
        this.setState({fullItems: response.data})
        if(this.state.sale.hideOrShowLackItems === false){
            var resItems = response.data.filter(i => (i.quantity > 0))
        }else{
          var resItems = response.data;
        }
        if(this.state.sale.salesTypeId === 8 || this.state.sale.salesTypeId === 9){
          let dataArr = resItems.filter(i => (i.inventoryItem === true));
          let firstItem = {id: 0 , itemName: helper().translate("ITEM")+ ":", barcode: helper().translate("BARCODE")+ ":", quantity:helper().translate("QUANTITY")+ ":" , isDisabled: true};
          dataArr.unshift(firstItem)
          this.setState({ items: dataArr });
        }else{
          let dataArr = resItems.filter(i => (i.expense === false) && (i.inventoryItem === false));
          let firstItem = {id: 0 , itemName: helper().translate("ITEM")+ ":", barcode: helper().translate("BARCODE")+ ":", quantity:helper().translate("QUANTITY")+ ":" , isDisabled: true};
          dataArr.unshift(firstItem)
          this.setState({ items: dataArr });
        }
      }
    });
}


getWithWatSettings = (sType) =>{
  globals().getSettings().then((response) => {
    if (response) {
      let data = response;
      let saleArr = new SaleModel();
      saleArr.salesTypeId = sType ? sType : parseInt((this.props.location.search).substring(1,this.props.location.search.length));
      saleArr.withVat = data.withVat;
      saleArr.withCashPayment = false;
      saleArr.subTotal = 0;
      saleArr.warehouseId = data.warehouseId;
      saleArr.allowLackItems = data.allowLackItems;
      saleArr.hideOrShowLackItems = data.hideOrShowLackItems;
      this.setState({sale: saleArr})
      this.getUser();
  } else {
    alert('Error: 3' + response.errorMessage);
  }
})
}

getAllowLackItemsSettings = () =>{
  globals().getSettings().then((response) => {
    if (response) {
      let data = response;
      let saleArr = this.state.sale;
      saleArr.allowLackItems = data.allowLackItems;
      saleArr.hideOrShowLackItems = data.hideOrShowLackItems;
      this.setState({sale: saleArr})
  } else {
    alert('Error: 2' + response.errorMessage);
  }
})
}
getInvoiceGenerateNumberSettings = () =>{
  globals().getSettings().then((response) => {
    if (response) {
      let saleArr = this.state.sale;
      let data = response;
      let invoiceFormat;
      if(data.autoGenerateInvoiceNumber === false){
        invoiceFormat = false;
        saleArr.invoiceFormat=false;
      }else {
        invoiceFormat = true;
        saleArr.invoiceFormat=true;
      }
      this.setState({invoiceIdFormat: invoiceFormat});
      this.setState({sale: saleArr});
  } else {
    alert('Error: 1' + response.errorMessage);
  }
})
}
getUser = () => {
  apiService().query('user', 'userName='+globals().getLogedInUser().data.username)
      .then((response) => {
          if(response.success) {
            let saleArr = this.state.sale;
            saleArr.warehouseId = response.data[0].warehouseId;
            saleArr.cashBoxId = response.data[0].cashBoxId;
            this.setState({sale: saleArr})
            this.setState({loader: false})
          }
          else alert('errr')
      })
}

getDefaultCashAccount = () => {

  apiService().query('getDefaultAccount',  'name='+"Transitional_Cash_Account" )
  .then((response) => {
    if (response.success) {
      
      let defaultAcc = this.state.defaultAccount;
      defaultAcc.defaultCashAccount = response.data[0].accountId;
      this.setState({defaultAccount: defaultAcc});
    } else {
      console.log('Error: getting default cash account' + response.errorMessage);
    }
  });
}

getDefaultBankAccount = () => {

  apiService().query('getDefaultAccount',  'name='+"Transitional_Bank_Account" )
  .then((response) => {
    if (response.success) {
      let defaultAcc = this.state.defaultAccount;
      defaultAcc.defaultBankAccount = response.data[0].accountId;
      this.setState({defaultAccount: defaultAcc});
    } else {
      console.log('Error:getting default bank account' + response.errorMessage);
    }
  });
}
saleTypeChange = () =>{
  if(this.props.match.params.id == 0){
    var sale = this.state.sale;
    sale.salesTypeId = parseInt((this.props.location.search).substring(1,this.props.location.search.length));
    this.setState(sale)
    }
}

getSaleDetails = (saleId) => {
  apiService().query('getSaleDetails', "saleId="+saleId).then((response) => {
    if (response.success) {
      this.getAllowLackItemsSettings();
      var data = response.data;
      if(data.length === 0 ){
        data = [{no: 1 , id:0,itemId:0, itemName:'select item', barcode:'select barcode',quantity:1,
        price:0.0, vat: 0 , vatSum:0  , addButton: true , validate: true}];
      }
       var count = 1;
       data.forEach(i=> {i.no = count; count++, i.initialQuantity = i.quantity ,i.initialUnchangedQty = i.quantity });
      for(var i= 0; i< data.length; i++  ){
        if(i === (data.length - 1)) {
          data[i].addButton = true;
          
          }  
        if(data[i].discount> 0){

          data[i].priceDiscount = calculate().calculateSale('priceDiscount')(data[i].price, data[i].discount);
          data[i].priceTvsh = calculate().calculateSale('priceTvsh')(data[i].priceDiscount ,data[i].vat);
          data[i].totalPrice= calculate().calculateSale('totalPrice')(data[i].priceDiscount,data[i].quantity);
          // data[i].totalPriceTvsh= calculate().calculateSale('totalPriceTvsh')(data[i].totalPrice,data[i].vat);//1
          data[i].totalPriceTvsh= (data[i].totalPrice + data[i].vatSum);
          data[i].validate = true;
          // data[i].totalPriceItemTvsh= calculate().calculateSale('totalPriceItemTvsh')(data[i].totalPriceTvsh , data[i].quantity);

        } else{
          
          data[i].priceTvsh = calculate().calculateSale('priceTvsh')(data[i].price ,data[i].vat);
          data[i].totalPrice= calculate().calculateSale('totalPrice')(data[i].price , data[i].quantity );
          // data[i].totalPriceTvsh= calculate().calculateSale('totalPriceTvsh')(data[i].totalPrice,data[i].vat);//1
          data[i].totalPriceTvsh= (data[i].totalPrice + data[i].vatSum);
          data[i].validate = true;
          // data[i].totalPriceItemTvsh= calculate().calculateSale('totalPriceItemTvsh')(data[i].totalPriceTvsh , data[i].quantity);

        }
      }
      this.setState({ saleDetails: data });
      this.setState({loader: false});
    } else {
      console.log('Error:' + response.errorMessage);
    }
  });
}


saleDetailsFirstItem = ()=> {
  var saleId = this.props.match.params.id;
  var sDetails = this.state.saleDetails;
  if(saleId == 0) {
    sDetails = [{no: 1 , id:0,itemId:0, itemName:'select item', barcode:'select barcode',quantity:1,
    price:0.0, vat: 0 , vatSum:0 , validate: true}]
  }
  this.setState({saleDetails : sDetails})
}

//ugSaleDetails

//ugDetails

withVatChanged =() => {
  var saleObj = this.state.sale;
  var saleDet = this.state.saleDetails;
  let obj = saleDet.filter(o => o.itemId === 0);
  if(obj.length === 0 ){

    saleObj.withVat= !saleObj.withVat
    if(saleObj.withVat === false ){
      saleObj.amountLeft = (saleObj.subTotal - saleObj.totalSalePayment);
      saleDet.forEach(e => {
        if(e.status === 0){
          e.status = 2;
        }
        e.vat = 0;
        e.vatSum = 0;
      });
    }else{
      saleObj.amountLeft = (saleObj.totalSum - saleObj.totalSalePayment);
      saleDet.forEach(e => { 
        if(e.status === 0){
          e.status = 2;
        }
      var item = this.state.items.find(i=> i.id === e.itemId)
       e.vat = item.vat;
       if(e.discount > 0) {
        e.priceDiscount = calculate().calculateSale('priceDiscount')(e.price, e.discount);
        e.priceTvsh = calculate().calculateSale('priceTvsh')(e.priceDiscount ,e.vat);
        e.totalPrice= calculate().calculateSale('totalPrice')(e.priceDiscount,e.quantity);
        // e.totalPriceTvsh= calculate().calculateSale('totalPriceTvsh')(e.totalPrice,e.vat);//1
        e.vatSum = calculate().calculateSale('vatSum')(e.totalPrice,e.vat);
        e.totalPriceTvsh = (e.totalPrice + e.vatSum);
       } else {

         e.priceTvsh= calculate().calculateSale('priceTvsh')(e.price , e.vat );
         e.totalPrice= calculate().calculateSale('totalPrice')(e.price,e.quantity);
        //  e.totalPriceTvsh= calculate().calculateSale('totalPriceTvsh')(e.totalPrice,e.vat);
         e.vatSum = calculate().calculateSale('vatSum')(e.totalPrice,e.vat);
         e.totalPriceTvsh = (e.totalPrice + e.vatSum);
       }
       });
    }
  } else {
    this.validateForm();
    this.validationAlert(helper().translate("FILL_BLANK_ITEMS"));
  }
  this.setState({sale: saleObj});
  this.setState({saleDetails: saleDet});
  this.totalSale();
}

withCashPayment = () => {

  let saleCopy = this.state.sale;
  let payBtn = this.state.paymentBtn;
  if(typeof(saleCopy.cashToPay) === 'undefined' || saleCopy.cashToPay <= 0){
    this.validationAlert(helper().translate("PAYMENT_SUM_SHOULD_BE_BIGGER_THAN_ZERO"))
  }else {

    saleCopy.withCashPayment = !saleCopy.withCashPayment;
  }
  if(saleCopy.withCashPayment){
    saleCopy.paymentMethod = 1;
    payBtn.savePayment = false;
    saleCopy.totalSalePayment = saleCopy.cashToPay;
    saleCopy.amountLeft = (saleCopy.totalSum - saleCopy.totalSalePayment);
  }else{
    saleCopy.paymentMethod = 3;
    saleCopy.totalSalePayment = 0;
    saleCopy.amountLeft = saleCopy.totalSum;
  }
  this.setState({sale: saleCopy , paymentBtn: payBtn});
}

onFieldChange = (e,obj) => {
  var saleObj = this.state.sale;
  if(e.target.name === 'date'){
    //  var today = new Date();
    //  time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
     saleObj.date =  new Date(e.target.value);
  }else if(e.target.name === 'cashToPay'){
    if(e.target.value <= saleObj.totalSum && e.target.value > 0){

      saleObj.cashToPay = e.target.value;
      if(saleObj.withCashPayment){
        saleObj.totalSalePayment = e.target.value;
        saleObj.amountLeft = (saleObj.totalSum - saleObj.totalSalePayment);
      }
    }else{
      this.validationAlert(helper().translate("PAYMENT_SUM_SHOULDNT_BE_BIGGER_THAN_INVOICE_TOTAL"))
    }
  }else if(e.target.name === 'reference'){
    saleObj[e.target.name] = e.target.value;
    saleObj.referenceExist = false;
    saleObj.referenceChanged = true;
 }else if(e.target.name === 'invoiceNo'){
  saleObj[e.target.name] = e.target.value;
  saleObj.invoiceExist = false;
  saleObj.invoiceChanged = true;
}
  else{
    saleObj[e.target.name] = e.target.value;
  }

  saleObj.isChanged = true
  this.setState({sale: saleObj})
};

onSelectChange = (e,obj) => {
  var sale = this.state.sale;
  if(obj.name === "partnerId"){
      sale[obj.name] = e.value;
      sale.isChanged = true;
      if(this.state.sale.warehouseId > 0 ){
        this.getItems(e.value,this.state.sale.warehouseId);
      }
    }else {
    sale[obj.name] = e.value;
    sale.isChanged = true;
    this.getPartners(e.value)
    if(this.state.sale.partnerId > 0){
      this.getItems(this.state.sale.partnerId,e.value);
    }
    }
  this.setState( {[obj.name] : e} );
  this.setState(sale);
  
};


onButtonClickPay = () => {
let saleCopy = this.state.sale;
if(saleCopy.subTotal > 0 ){
  let payBtn = this.state.paymentBtn;
  payBtn.showPayFrom = true;
  payBtn.pay = true;
  let paymentCopy = this.state.payment;
  paymentCopy[0].inputValue = (this.state.sale.totalSum);
  if(paymentCopy[1].inputValue === 0){
    paymentCopy[1].inputValue = (this.state.sale.totalSum);

  }
  paymentCopy[5].inputValue = (this.state.sale.totalSum);
  
  // fillin pay selects
  let bankOptions = this.state.banksPos;
  if(bankOptions.length > 0){
    for(let i=2; i<=(bankOptions.length+1); i++){
      paymentCopy[i].bankId = bankOptions[i-2].id;
    }
  }
    paymentCopy[4].disabled = true;
    paymentCopy[3].disabled = true;
    paymentCopy[2].disabled = true;
  this.setState({paymentBtn: payBtn , payment: paymentCopy});
  }else{
    this.validationAlert(helper().translate("AMOUNT_LEFT_SHOULD_BE_GREATER_THAN_ZERO"))
  }
}
onButtonClickPayments = () => {
  let payBtn = this.state.paymentBtn;
  let saleCopy= this.state.sale;
  let paymentSalesCopy = this.state.paymentSales;
  let totalPayments = 0;
  paymentSalesCopy.forEach(e => {
    totalPayments = totalPayments + e.amountPaid;
  });
  saleCopy.totalPayments = parseFloat(totalPayments);
  payBtn.showPayments = true;
  this.setState({paymentBtn: payBtn , sale: saleCopy})
}
hidePayments = () => {
  let sId = this.props.match.params.id;
  let payBtn = this.state.paymentBtn;
  payBtn.showPayments = false;
  this.setState({paymentBtn: payBtn })
  this.getPaymentSales(sId);
}

onArrowClick = () =>{
  let payBtn = this.state.paymentBtn;
  payBtn.showPayFrom  = false;
  payBtn.showPayments = false;
  this.setState({paymentBtn: payBtn });
}

itemChange = (e, obj,no) => { 
  var salecopy = this.state.sale;
  var sDetails =  this.state.saleDetails;
  
  if(obj.name === "projectId") {
    sDetails[no-1][obj.name] = e.value;
    this.setState({saledetails: sDetails});
  }else {
    var saleDetailItem = sDetails.find(i=> i.no === no);
    var item = this.state.items.find(i=> i.id === e.value)
    saleDetailItem.barcode= item.barcode;
    saleDetailItem.itemName= item.itemName;
    saleDetailItem.itemId= item.id;
    saleDetailItem.unitId = item.unitId;
    let fullProject = this.state.projects?.find( p => p.WarehouseId === this.state.sale.warehouseId && p.IsDefault === true);
    saleDetailItem.projectId = fullProject?.id ? fullProject.id : 0;

    if(this.state.sale.allowLackItems === false){
      let itemsQuantity = sDetails.filter(s => s.itemId === saleDetailItem.itemId)
      let totalQuantity = 0;
      let totalInitialQty = 0;
      itemsQuantity.forEach(s => {
          totalQuantity = parseFloat(s.quantity)  + totalQuantity;
          if(s.id > 0){
            totalInitialQty = parseFloat(s.initialUnchangedQty)  + totalInitialQty;
          }
      });
      if(  totalQuantity - totalInitialQty > item.quantity  ){
        saleDetailItem.isntAllowedQuantity = true;
      }else {
        saleDetailItem.isntAllowedQuantity = false;
      }
    }
    if(salecopy.withVat){
      saleDetailItem.vat= item.vat
    } else {
      saleDetailItem.vat= 0;
    }
    saleDetailItem.price= item.retailPrice;
    saleDetailItem.avgPrice = item.avgPrice;
    saleDetailItem.priceTvsh = (calculate().calculateSale('priceTvsh')(saleDetailItem.price ,saleDetailItem.vat));
    if(item.discount > 0){
      saleDetailItem.discount = item.discount;
      saleDetailItem.priceDiscount = calculate().calculateSale('priceDiscount')(saleDetailItem.price, saleDetailItem.discount);
      saleDetailItem.priceTvsh = calculate().calculateSale('priceTvsh')(saleDetailItem.priceDiscount ,saleDetailItem.vat);
      saleDetailItem.totalPrice= calculate().calculateSale('totalPrice')(saleDetailItem.priceDiscount,saleDetailItem.quantity);
      // saleDetailItem.totalPriceTvsh= calculate().calculateSale('totalPriceTvsh')(saleDetailItem.totalPrice,saleDetailItem.vat);/1
      saleDetailItem.vatSum = calculate().calculateSale('vatSum')(saleDetailItem.totalPrice,saleDetailItem.vat);
      saleDetailItem.totalPriceTvsh = (parseFloat(saleDetailItem.totalPrice) + saleDetailItem.vatSum);

    }else{
      saleDetailItem.discount = 0;
      saleDetailItem.priceDiscount = 0;
      saleDetailItem.totalPrice = calculate().calculateSale('totalPrice')(saleDetailItem.price,saleDetailItem.quantity);
      saleDetailItem.vatSum = calculate().calculateSale('vatSum')(saleDetailItem.totalPrice,saleDetailItem.vat);
      saleDetailItem.totalPriceTvsh = (saleDetailItem.totalPrice + saleDetailItem.vatSum);
    }
   
    this.setState({saleDetails:sDetails});
    this.totalSale();
  }

}

onFieldDetailsChange = (e,obj, no) => {
  var saleArr = this.state.sale;
  var sDetails = this.state.saleDetails;
  var saleDetailItem = sDetails.find(i=> i.no === no);

  if([e.target.name] == "price") {

      saleDetailItem[e.target.name] = e.target.value;
  
      if(saleDetailItem.discount>0){
      saleDetailItem.priceDiscount = calculate().calculateSale('priceDiscount')(saleDetailItem.price, saleDetailItem.discount);
      saleDetailItem.priceTvsh = calculate().calculateSale('priceTvsh')(saleDetailItem.priceDiscount ,saleDetailItem.vat);
      saleDetailItem.totalPrice= calculate().calculateSale('totalPrice')(saleDetailItem.priceDiscount,saleDetailItem.quantity);
      // saleDetailItem.totalPriceTvsh= calculate().calculateSale('totalPriceTvsh')(saleDetailItem.totalPrice,saleDetailItem.vat);//1
      saleDetailItem.vatSum = calculate().calculateSale('vatSum')(saleDetailItem.totalPrice,saleDetailItem.vat);
      saleDetailItem.totalPriceTvsh =  (parseFloat(saleDetailItem.totalPrice) + parseFloat( saleDetailItem.vatSum));
  
      }else{
        saleDetailItem.priceTvsh = calculate().calculateSale('priceTvsh')(saleDetailItem.price ,saleDetailItem.vat);
        saleDetailItem.priceDiscount = calculate().calculateSale('priceDiscount')(saleDetailItem.price, saleDetailItem.discount);
        saleDetailItem.totalPrice= calculate().calculateSale('totalPrice')(saleDetailItem.price,saleDetailItem.quantity);
        // saleDetailItem.totalPriceTvsh= calculate().calculateSale('totalPriceTvsh')(saleDetailItem.totalPrice,saleDetailItem.vat);//1
        saleDetailItem.vatSum = calculate().calculateSale('vatSum')(saleDetailItem.totalPrice,saleDetailItem.vat);
        saleDetailItem.totalPriceTvsh = (parseFloat(saleDetailItem.totalPrice)+ saleDetailItem.vatSum);
      }
    

  } else if ([e.target.name] == "priceTvsh"){


      saleDetailItem[e.target.name] = e.target.value;
  
      if(saleDetailItem.discount>0){
        saleDetailItem.price= calculate().calculateSale('priceDiscountTvsh')(saleDetailItem.priceTvsh ,saleDetailItem.discount ,saleDetailItem.vat);
        saleDetailItem.priceDiscount = calculate().calculateSale('priceDiscount')(saleDetailItem.price, saleDetailItem.discount);
        saleDetailItem.totalPrice= calculate().calculateSale('totalPrice')(saleDetailItem.priceDiscount,saleDetailItem.quantity);
        // saleDetailItem.totalPriceTvsh= calculate().calculateSale('totalPriceTvsh')(saleDetailItem.totalPrice,saleDetailItem.vat);//1
        saleDetailItem.vatSum = calculate().calculateSale('vatSum')(saleDetailItem.totalPrice,saleDetailItem.vat);
        saleDetailItem.totalPriceTvsh =  (parseFloat(saleDetailItem.totalPrice) + parseFloat( saleDetailItem.vatSum));
      }else{
  
        saleDetailItem.price = calculate().calculateSale('price')(saleDetailItem.priceTvsh ,saleDetailItem.vat);
        saleDetailItem.totalPrice= calculate().calculateSale('totalPrice')(saleDetailItem.price,saleDetailItem.quantity);
        // saleDetailItem.totalPriceTvsh= calculate().calculateSale('totalPriceTvsh')(saleDetailItem.totalPrice,saleDetailItem.vat);//1
        saleDetailItem.vatSum = calculate().calculateSale('vatSum')(saleDetailItem.totalPrice,saleDetailItem.vat);
        saleDetailItem.totalPriceTvsh = (parseFloat(saleDetailItem.totalPrice) + saleDetailItem.vatSum);
      }

    


  }else if([e.target.name] == "discount"){
    if(e.target.value>=0 && e.target.value <= 300){

      saleDetailItem[e.target.name] = e.target.value;
  
      saleDetailItem.priceDiscount = calculate().calculateSale('priceDiscount')(saleDetailItem.price, saleDetailItem.discount);
      parseInt(e.target.value)  === 0 ? saleDetailItem.priceTvsh = calculate().calculateSale('priceTvsh')(saleDetailItem.price ,saleDetailItem.vat) : saleDetailItem.priceTvsh = calculate().calculateSale('priceTvsh')(saleDetailItem.priceDiscount ,saleDetailItem.vat)
      ;
      saleDetailItem.totalPrice= calculate().calculateSale('totalPrice')(saleDetailItem.priceDiscount,saleDetailItem.quantity);
      // saleDetailItem.totalPriceTvsh= calculate().calculateSale('totalPriceTvsh')(saleDetailItem.totalPrice,saleDetailItem.vat);/1
      saleDetailItem.vatSum = calculate().calculateSale('vatSum')(saleDetailItem.totalPrice,saleDetailItem.vat);
      saleDetailItem.totalPriceTvsh = (parseFloat(saleDetailItem.totalPrice) + saleDetailItem.vatSum);
    }else{
      this.validationAlert("Ju lutem vendoseni zbritjen ne menyre te rregullt ")
    }


  } 
  else if([e.target.name] == "vat") {
    saleDetailItem[e.target.name] = parseInt(e.target.value);

    if(saleDetailItem.discount>0){
    saleDetailItem.priceDiscount = calculate().calculateSale('priceDiscount')(saleDetailItem.price, saleDetailItem.discount);
    saleDetailItem.priceTvsh = calculate().calculateSale('priceTvsh')(saleDetailItem.priceDiscount ,saleDetailItem.vat);
    saleDetailItem.totalPrice= calculate().calculateSale('totalPrice')(saleDetailItem.priceDiscount,saleDetailItem.quantity);
    // saleDetailItem.totalPriceTvsh= calculate().calculateSale('totalPriceTvsh')(saleDetailItem.totalPrice,saleDetailItem.vat);//1
    saleDetailItem.vatSum = calculate().calculateSale('vatSum')(saleDetailItem.totalPrice,saleDetailItem.vat);
    saleDetailItem.totalPriceTvsh = (saleDetailItem.totalPrice + saleDetailItem.vatSum);
    }else{
      
      saleDetailItem.priceTvsh = calculate().calculateSale('priceTvsh')(saleDetailItem.price ,saleDetailItem.vat);
      // saleDetailItem.totalPriceTvsh= calculate().calculateSale('totalPriceTvsh')(saleDetailItem.totalPrice,saleDetailItem.vat);//1
      saleDetailItem.vatSum = calculate().calculateSale('vatSum')(saleDetailItem.totalPrice,saleDetailItem.vat);
      saleDetailItem.totalPriceTvsh = (parseFloat(saleDetailItem.totalPrice) + saleDetailItem.vatSum);
    }
  } 
  else if([e.target.name] == "totalPrice"){

    const totalPrice = () => {
      saleDetailItem[e.target.name] =  e.target.value;
      if(saleDetailItem.discount>0 ) {

        saleDetailItem.priceDiscount = calculate().calculateSale('priceFromTotal')(saleDetailItem.totalPrice ,saleDetailItem.quantity);
        saleDetailItem.price = calculate().calculateSale('priceFromDiscount')(saleDetailItem.priceDiscount , saleDetailItem.discount);
      } else{
        saleDetailItem.price = calculate().calculateSale('priceFromTotal')(saleDetailItem.totalPrice ,saleDetailItem.quantity);
      }
       
     this.totalSale();
    }
    if(parseInt( saleArr.salesTypeId) != 4){
      totalPrice();
    }else {
      if(parseFloat(e.target.value) < 0){
        totalPrice();
      }
    }
      
   }
  else if([e.target.name] == "totalPriceTvsh"){

    const totalPriceTvsh = () => {
      saleDetailItem[e.target.name] =  e.target.value;

      if(saleDetailItem.discount>0 ){
       saleDetailItem.priceTvsh = calculate().calculateSale('totalPriceItemTvsh')(saleDetailItem.totalPriceTvsh ,saleDetailItem.quantity);
       saleDetailItem.price= calculate().calculateSale('priceDiscountTvsh')(saleDetailItem.priceTvsh ,saleDetailItem.discount ,saleDetailItem.vat);
       saleDetailItem.priceDiscount = calculate().calculateSale('priceDiscount')(saleDetailItem.price, saleDetailItem.discount);
       saleDetailItem.totalPrice= calculate().calculateSale('totalPrice')(saleDetailItem.priceDiscount,saleDetailItem.quantity);
       saleDetailItem.vatSum = calculate().calculateSale('vatSumTvshDiscount')(saleDetailItem.totalPriceTvsh,saleDetailItem.totalPrice);
      }else{
       saleDetailItem.priceTvsh = calculate().calculateSale('totalPriceItemTvsh')(saleDetailItem.totalPriceTvsh ,saleDetailItem.quantity);
       saleDetailItem.price = calculate().calculateSale('price')(saleDetailItem.priceTvsh ,saleDetailItem.vat);
       saleDetailItem.totalPrice= calculate().calculateSale('totalPrice')(saleDetailItem.price,saleDetailItem.quantity);
       saleDetailItem.vatSum = calculate().calculateSale('vatSum')(saleDetailItem.totalPrice,saleDetailItem.vat);
       }
      
     this.totalSale();
    }
   
    if(parseInt( saleArr.salesTypeId) != 4){
      totalPriceTvsh();
    }else {
      if(parseFloat(e.target.value) < 0){
        totalPriceTvsh();
      }
    }
    
  }
  else if([e.target.name] == "priceDiscount"){
    
      saleDetailItem[e.target.name] =  e.target.value;
 
      if(saleDetailItem.discount>0 ){
        saleDetailItem.priceTvsh = calculate().calculateSale('priceTvsh')(saleDetailItem.priceDiscount ,saleDetailItem.vat);
        saleDetailItem.price= calculate().calculateSale('priceDiscountTvsh')(saleDetailItem.priceTvsh ,saleDetailItem.discount ,saleDetailItem.vat);
        saleDetailItem.totalPrice= calculate().calculateSale('totalPrice')(saleDetailItem.priceDiscount,saleDetailItem.quantity);
        // saleDetailItem.totalPriceTvsh= calculate().calculateSale('totalPriceTvsh')(saleDetailItem.totalPrice,saleDetailItem.vat);
        saleDetailItem.vatSum = calculate().calculateSale('vatSum')(saleDetailItem.totalPrice,saleDetailItem.vat);
        saleDetailItem.totalPriceTvsh = ( parseFloat(saleDetailItem.totalPrice) + saleDetailItem.vatSum);
        
      }
   
   }
   else if([e.target.name] == "quantity"){

    if(saleDetailItem.itemId != 0){
      const quantityChange = (e,no) => {
  
        if(this.state.sale.allowLackItems === false){
          saleDetailItem[e.target.name] = e.target.value;
          let itemMaxQuantity = this.state.fullItems.find(s => s.id === saleDetailItem.itemId)
          // if(saleDetailItem.id > 0 ){
          //   // this.updateItem(itemMaxQuantity.id , saleDetailItem , itemMaxQuantity )
          //   this.setState(prevState => {
          //     // Create a copy of the array
          //     const updatedItems = [...prevState.fullItems];
              
          //     // Find the specific object to update
          //     const itemToUpdate = updatedItems.find(item => item.id ===  itemMaxQuantity.id);
          //     let saleQuantityDiff = 0;
          //     let itemQuantity = itemToUpdate.quantity;
          //     if (itemToUpdate) {
          //       // Update the desired property of the object
          //       saleQuantityDiff = ( saleDetailItem.initialQuantity - parseFloat(saleDetailItem.quantity)  )
          //       saleDetailItem.initialQuantity = parseFloat(saleDetailItem.quantity);
          //       itemToUpdate.quantity = itemQuantity  + saleQuantityDiff;
          //       itemMaxQuantity.quantity = itemToUpdate.quantity;
          //     }
              
          //     // Update the state with the modified array
          //     return { fullItems: updatedItems };
          //   });
          // } 
          let itemsQuantity = sDetails.filter(s => s.itemId === saleDetailItem.itemId)
          let totalQuantity = 0;
          let totalInitialQty = 0;
          itemsQuantity.forEach(s => {
              totalQuantity = parseFloat(s.quantity)  + totalQuantity;
              if(s.id > 0){
                totalInitialQty = parseFloat(s.initialUnchangedQty)  + totalInitialQty;
              }
          });
          if((totalQuantity - totalInitialQty) > itemMaxQuantity?.quantity){
            saleDetailItem.isntAllowedQuantity = true
          }else {
            saleDetailItem.isntAllowedQuantity = false
          }
        }
        else{
          saleDetailItem[e.target.name] = e.target.value;
        }
  
  
    
        if(saleDetailItem.discount>0){
          
          saleDetailItem.totalPrice= calculate().calculateSale('totalPrice')(saleDetailItem.priceDiscount , saleDetailItem.quantity);
          // saleDetailItem.totalPriceTvsh= calculate().calculateSale('totalPriceTvsh')(saleDetailItem.totalPrice , saleDetailItem.vat);//1
          saleDetailItem.vatSum = calculate().calculateSale('vatSum')(saleDetailItem.totalPrice,saleDetailItem.vat);
          saleDetailItem.totalPriceTvsh =(saleDetailItem.totalPrice + saleDetailItem.vatSum);
        } else {
    
          saleDetailItem.totalPrice= calculate().calculateSale('totalPrice')(saleDetailItem.price,saleDetailItem.quantity);
          // saleDetailItem.totalPriceTvsh= calculate().calculateSale('totalPriceTvsh')(saleDetailItem.totalPrice,saleDetailItem.vat);
          saleDetailItem.vatSum = calculate().calculateSale('vatSum')(saleDetailItem.totalPrice,saleDetailItem.vat);
          saleDetailItem.totalPriceTvsh = (parseFloat(saleDetailItem.totalPrice)+ saleDetailItem.vatSum);
        }
      }
  
         if(parseInt( saleArr.salesTypeId) != 4){
            quantityChange(e,no);
          }else if(parseFloat(e.target.value) < 0 || e.target.value === ""){
              quantityChange(e,no);
            }

    }else{
      this.props.addToast(helper().translate("PLEASE_CHOOSE_ITEM_FRIST"), { appearance: 'error', autoDismiss: true, });
    }


  }
   else {
    
    saleDetailItem[e.target.name] = e.target.value;

    if(saleDetailItem.discount>0){
      
      saleDetailItem.totalPrice= calculate().calculateSale('totalPrice')(saleDetailItem.priceDiscount , saleDetailItem.quantity);
      // saleDetailItem.totalPriceTvsh= calculate().calculateSale('totalPriceTvsh')(saleDetailItem.totalPrice , saleDetailItem.vat);//1
      saleDetailItem.vatSum = calculate().calculateSale('vatSum')(saleDetailItem.totalPrice,saleDetailItem.vat);
    } else {

      saleDetailItem.totalPrice= calculate().calculateSale('totalPrice')(saleDetailItem.price,saleDetailItem.quantity);
      saleDetailItem.totalPriceTvsh= calculate().calculateSale('totalPriceTvsh')(saleDetailItem.totalPrice,saleDetailItem.vat);
      saleDetailItem.vatSum = calculate().calculateSale('vatSum')(saleDetailItem.totalPrice,saleDetailItem.vat);
    }
  }
  this.setState({saleDetails:sDetails});
  this.totalSale();
  
};

paymentChange = (e, obj , no) => {
 var paymentCopy = this.state.payment;
 paymentCopy[no].bankId = e.value;
 this.setState({payment: paymentCopy})

}

paymentFieldChange = (e,no)=>{
  let payReturn = false;
  if(e.target.value >= 0) {
    let paymentCopy = this.state.payment;
    paymentCopy[no].inputValue = parseFloat(e.target.value);
    let bankSum = parseFloat(paymentCopy[2].inputValue ) + parseFloat(paymentCopy[3].inputValue )  + parseFloat(paymentCopy[4].inputValue );

    paymentCopy[5].inputValue = (parseFloat(paymentCopy[1].inputValue)  + parseFloat(paymentCopy[2].inputValue) + parseFloat(paymentCopy[3].inputValue)+ parseFloat(paymentCopy[4].inputValue));

    if(parseFloat(paymentCopy[1].inputValue) >= parseFloat(paymentCopy[0].inputValue)){
      paymentCopy[2].disabled = true;
      paymentCopy[2].inputValue = 0;
      paymentCopy[3].disabled = true;
      paymentCopy[3].inputValue = 0;
      paymentCopy[4].disabled = true;
      paymentCopy[4].inputValue = 0;
      paymentCopy[5].inputValue = (parseFloat(paymentCopy[1].inputValue)  + parseFloat(paymentCopy[2].inputValue) + parseFloat(paymentCopy[3].inputValue)+ parseFloat(paymentCopy[4].inputValue));
      paymentCopy[7].inputValue = (parseFloat(paymentCopy[5].inputValue) - parseFloat(paymentCopy[0].inputValue));
      payReturn= true;
    }else{
      let bankOptions = this.state.banksPos;
      if(bankOptions.length === 2){
        paymentCopy[2].disabled = false;
        paymentCopy[3].disabled = false;
      }else if(bankOptions.length === 1){
        paymentCopy[2].disabled = false;
      } else if (bankOptions.length >= 3){
        paymentCopy[4].disabled = false;
        paymentCopy[3].disabled = false;
        paymentCopy[2].disabled = false;
      }
    }

    if( parseFloat(paymentCopy[5].inputValue)  <  parseFloat(paymentCopy[0].inputValue) ){
      paymentCopy[6].inputValue = (paymentCopy[5].inputValue - parseFloat(paymentCopy[0].inputValue));
    }else {
      paymentCopy[6].inputValue = 0;
    }

    if((paymentCopy[5].inputValue - parseFloat(paymentCopy[0].inputValue)) >= 0 && bankSum == 0){
      paymentCopy[7].inputValue = (paymentCopy[5].inputValue - parseFloat(paymentCopy[0].inputValue));
    }else{
      if(payReturn === false){
        paymentCopy[7].inputValue = 0;
      }
    }
    

    this.setState({payment: paymentCopy});
  }else{
    // let paymentCopy = this.state.payment;
    // paymentCopy[no].inputValue = 0;
    // this.setState({payment: paymentCopy});
    this.validationAlert("Please write Positive Number");

  }
}



validateAllowSaleNegative = () => {

  if(this.state.sale.allowLackItems === false){
    let itemsInStock = this.state.items;
    let saleDet = this.state.saleDetails;
  
    // saleDet.forEach(s => {
    //   let itemFound = itemsInStock.filter( i => i.id === s.itemId)
    //   if(parseFloat(s.quantity)  > itemFound[0].quantity){
    //     s.isntAllowedQuantity = true;
    //   }else {
    //     s.isntAllowedQuantity = false;

    //   }
    // });

    let saleDetQuantity = saleDet.filter( s => s.isntAllowedQuantity === true)

    if(saleDetQuantity.length > 0 ){
      return false
    }else{
      return true
    }

  }else{
    return true
  }
}

validateForm = () => {
  var saleVal = this.state.sale;
  var saleDet = this.state.saleDetails;
  let obj = saleDet.filter(o => (o.itemId === 0) );
  // let projectLength = saleDet.filter(o => o.projectId === 0);
  obj.forEach(e => {
    e.validate = false;
    e.editMode = false;
  });
  this.setState({saleDetails: saleDet})
  if(saleVal.partnerId !== null && saleVal.warehouseId !== null && saleVal.salesTypeId !== null  && saleVal.date !== '' && obj.length == 0){
      return true
  } else {
    
      return false
  }
}
validatePaymentForm = () => {
  let returnValue = true;
  let paymentCopy = this.state.payment;
  let payment0 = parseFloat(paymentCopy[0].inputValue); 
  let payment1 = parseFloat(paymentCopy[1].inputValue);
  let payment2 = parseFloat(paymentCopy[2].inputValue);
  let payment3 = parseFloat(paymentCopy[3].inputValue);
  let payment4 = parseFloat(paymentCopy[4].inputValue);
  let payment5 = parseFloat(paymentCopy[5].inputValue);
  let payment6 = parseFloat(paymentCopy[6].inputValue);
  let payment7 = parseFloat(paymentCopy[7].inputValue);

  let posSum = payment2 + payment3 + payment4;
  if(posSum > 0){
    if( posSum > payment0 || (payment1 + posSum) > payment0 ){
      this.validationAlert("nuk lejohet qe pagesa me banke te jete me e madhe sesa shuma per pagese");
      returnValue = false;
    }
  }
  let totalSum = payment1 + payment2 + payment3 + payment4;
  if(totalSum <=0){
    this.validationAlert("nuk mund te beni pagese me vlere 0");
      returnValue = false;
  }
    
  return returnValue;
}


validationAlert = (msg) => {
  this.props.addToast(msg, { appearance: 'error', autoDismiss: true, });
}

getSalesType = () => {
  var name = this.props.location.pathname;
  var splitstring = name.split('/');
  return splitstring[splitstring.length-1];
}

newHandle = () => {
  var sale = this.state.sale;
  var id = this.props.match.params.id;
  let yesSave = true;
  var saleDet = this.state.saleDetails;
  let obj = saleDet.filter(e => (e.status === 2) || (e.status === 1));
  this.onClear()


  if(obj.length === 0 &&  typeof(sale.isChanged) === 'undefined' ){
    this.onClear();
  }else{
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui" >
            <h1>{helper().translate("ARE_YOU_SURE")}</h1>
            <p>{helper().translate("WANT_TO_SAVE_ACTUAL_CHANGES")}</p>
            <button
              onClick={() => {
                this.saveSale(id,yesSave);
                onClose();
              }}
            >
              Po 
            </button>
            <button onClick={() =>{
              this.onClear();
              onClose();
            }} className="ms-3">Jo</button>
          </div>
        );
      }
    })
  }


}

 onClear = () =>{
  let sale = this.state.sale;
  this.getWithWatSettings(sale.salesTypeId);
  this.getInvoiceGenerateNumberSettings();
  var saleDetails = [{no: 1 , id:0,itemId:0, itemName:'select item', barcode:'select barcode',quantity: parseInt((sale.salesTypeId)) === 4 ? -1 : 1 ,
  price:0.0, vat: 0 , vatSum:0  , addButton: true , validate : true}];
  this.setState({saleDetails: saleDetails});
}

totalSale = (incomingSaleDetails) => {
  if(incomingSaleDetails){
    var sDetails = incomingSaleDetails;
  }else{
    
    var sDetails = this.state.saleDetails;
  }
  var mainSale = this.state.sale;
  var totalSum = 0;
  var vatSum = 0;
  var subTotal = 0
  if(this.state.sale.withVat === true){
    sDetails.forEach(e => {
      subTotal = subTotal + Number(e.totalPrice);
      vatSum = vatSum + e.vatSum;
      totalSum = totalSum + Number(e.totalPriceTvsh);
    });
  } else {
    sDetails.forEach(e => {
      subTotal = subTotal + Number(e.totalPrice);
      totalSum = subTotal;
    });
  }
  
  mainSale.subTotal = subTotal;
  mainSale.vatSum = vatSum;
  mainSale.totalSum = totalSum;
  mainSale.cashToPay = parseFloat(totalSum);
  if(mainSale.withVat){
    mainSale.amountLeft = (mainSale.totalSum - mainSale.totalSalePayment);
  }else{
    mainSale.amountLeft = (mainSale.subTotal - mainSale.totalSalePayment);
  }
  this.setState({sale: mainSale})
}

// getDateTime = () => {

// var today = new Date();
// var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
// var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
// var dateTime = date+' '+time;
// return dateTime
// }

updateSale = (id, yesSave) =>{
  if(this.validateForm()) { 
    this.state.sale.changedBy =  globals().getLogedInUser().data.username;
    if(id > 0 ){
      this.state.sale.id= id
    }else {
      this.state.sale.id = this.props.match.params.id;
    }
    var saleDet = this.state.saleDetails;
    apiService().execute('updateSale', this.state.sale , 'post')
    .then((response) => {
      if(response.success){
        var updateSaleDetails = [];
        let obj = saleDet.filter(e => (e.status === 2) || (e.status === 1));
        obj.forEach(e => {
          var contextObj = {
            entity: e,
            status: e.status,
            type: 'salesDetails'
          }
          updateSaleDetails.push(contextObj);
        });
        apiService().saveChanges(updateSaleDetails).then((response) => {
           if(response.success){
            saleDet.forEach(e => {
              e.status = 0
            });
           };
           if(yesSave === true) {
            this.onClear();
          }else {
          this.props.history.push('/edit_sale/'+ this.props.match.params.id);
          }
        })
        //update payments and paymentsales
        // if(this.state.sale.paymentMethod == 1){
        //   let updatePaymentSale = { debit: this.state.sale.totalSum , amountPaid: this.state.sale.totalSum , changedBy: globals().getLogedInUser().data.name + globals().getLogedInUser().data.surname };
        //   apiService().query("getPaymentSales", "saleId="+this.props.match.params.id).then((response) => {
        //     if(response.success){
        //       if(response.data.length != 0){
        //         let paymentId = response.data[0].paymentId;
        //         updatePaymentSale.paymentId = paymentId;
        //         updatePaymentSale.saleId = this.props.match.params.id;
        //         apiService().execute('updatePaymentSales', updatePaymentSale , 'post').then((response) => {
        //           if(response.success){
        //             console.log("me sukses update i paymentSales")
        //           }else{
        //             his.props.addToast('ju lutem rregulloni pagesen ', { appearance: 'error', autoDismiss: true, });
        //           }
        //         })
        //       }
        //     }})
        // }
        // this.props.addToast('Update Success', { appearance: 'success', autoDismiss: true, });
        this.props.addToast('Update Success', { appearance: 'success', autoDismiss: true, });
      }
      else{
        this.props.addToast('Update Error', { appearance: 'error', autoDismiss: true, });
              }
    });
   }else {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui" >
            <h1>Gabim</h1>
            <p>Plotesoni fushat e kerkuara</p>
            <button onClick={onClose}>Ne Rregull</button>
          </div>
        );
      }
    
    })
    // this.setState({validate: true})
  }
}

saveOnlyOnce = () => {
  var element = document.getElementById("saveOnce");
  element.classList.toggle("disabled");
}


saveSale = (id, yesSave) => {

  if(this.validateAllowSaleNegative() || this.state.sale.salesTypeId === 4 ){

  if(id> 0){
    var saleId = id;
  } else {

    var saleId = this.props.match.params.id;
  }
 var sDetails = this.state.saleDetails;

 
  if(saleId === '0'){
    this.saveOnlyOnce();
    let invoiceNo =  this.state.invoiceIdFormat === false ?  this.state.sale.invoiceNo : '';
    let params = `reference=${this.state.sale.reference}&invoiceNo=${invoiceNo}`;
    apiService()
    .query(
      "searchSaleReference",
      params
    )
    .then((response) => {
      if((response.success && response.data[0].reference === 0 && response.data[0].invoice === 0 ) ){
        if(this.validateForm()) {
          this.state.sale.createdBy =  globals().getLogedInUser().data.username;
          this.state.sale.invoiceFormatString =  this.state.salesType.find(st => st.id == this.state.sale.salesTypeId).tokenName ? this.state.salesType.find(st => st.id == this.state.sale.salesTypeId).tokenName : "LOCAL_SALE" ;
          if(this.state.paymentBtn.savePayment === true ){
            if(this.state.payment[1].inputValue > 0){
              this.state.sale.paymentMethod = 1
            }else if ((this.state.payment[2].inputValue + this.state.payment[3].inputValue + this.state.payment[4].inputValue) > 0 ){
              this.state.sale.paymentMethod = 2
            }
          }
          apiService().execute('insertSale', this.state.sale , 'post')
          .then((response) => {
            if(response.success){
             
              // insert payment and paymentSales
              if(this.state.paymentBtn.savePayment == true || this.state.sale.paymentMethod == 1){ 
                if(this.state.paymentBtn.savePayment == true){
                  var pay = this.state.payment.filter( p => p.objective == "pay");
                  // arka = totali i pageses kur arka > totali i pageses
                  if(this.state.payment[1].inputValue >= this.state.payment[0].inputValue){
                    pay[0].inputValue = this.state.payment[0].inputValue;
                  }
                }
                if(this.state.sale.paymentMethod == 1){
                  var pay = this.state.payment.filter( p => p.name == "cash");
                }
              let toBePaid = pay.filter(p => p.inputValue > 0);
              let saleCopy = this.state.sale;
              let saleId = response.data[0].id;
              if(toBePaid.length > 0){
                toBePaid.forEach(e => {
                  let paidObj = new Object;
                  paidObj.debit = e.inputValue;
                  paidObj.date = saleCopy.date;
                  paidObj.reference = '';
                  paidObj.partnerId = saleCopy.partnerId;
                  paidObj.warehouseId = saleCopy.warehouseId;
                  paidObj.credit = 0;
                  paidObj.saldo = e.inputValue;
                  paidObj.userId = globals().getLogedInUser().data.id;
                  paidObj.description = '';
                  paidObj.paymentType = 3;
                  paidObj.projectId = 0; // me e vendos
                  paidObj.createdBy = globals().getLogedInUser().data.username;
                  if(e.name === "cash"){
                    paidObj.cashBoxId = this.state.currentUserCashBoxId;
                    paidObj.bankId = 0;
                    paidObj.accountId = this.state.defaultAccount.defaultCashAccount;
                  
                  } else {
                    paidObj.cashBoxId = 0;
                    paidObj.bankId = e.bankId;
                    paidObj.accountId = this.state.defaultAccount.defaultBankAccount;
                  }
  
                  // console.log(paidObj);
                  apiService().execute('insertPaymentFromSale', paidObj , 'post')
                          .then((response) => {
                            if(response.success){
                              let paymentId = response.data[0].id;
                              let insertPaymentSales = {paymentId: paymentId , saleId: saleId , amountPaid: paidObj.debit , createdBy: paidObj.createdBy };
                              apiService().execute('insertPaymentSales', insertPaymentSales , 'post')
                              .then((response) => {
                                if(response.success){
                                  console.log("insertimi me sukses");
                                }
                              })
                            }
                          })
                });
              }}
  
              sDetails.forEach(e => {
                e.saleId =  response.data[0].id;
                e.status = 0;
                apiService().execute('insertSaleDetails', e, 'post').then((response) => {
                  if(response.success){
                      if(yesSave === true) {
                        this.onClear();
                      }else {
                      this.props.history.push('/edit_sale/'+ e.saleId);
                      }
                    
                  }
                })
              });
              // this.getSaleDetails(response.data[0].id);
              this.props.addToast('Insert Success', { appearance: 'success', autoDismiss: true, });
              //this.props.history.push('/sales')
              this.setState({validate: false});
            }
            else{
              this.saveOnlyOnce();
              this.props.addToast('Ka ndodhur nje gabim', { appearance: 'error', autoDismiss: true, });
                    }
          });
        } else {
          this.saveOnlyOnce();
          confirmAlert({
            customUI: ({ onClose }) => {
              return (
                <div className="custom-ui" >
                  <h1>Gabim</h1>
                  <p>Plotesoni fushat e kerkuara</p>
                  <button onClick={onClose}>Ne Rregull</button>
                </div>
              );
            }
          
          })
          this.setState({validate: true})
        }
      }else if (response.data[0].reference > 0){
        this.props.addToast(helper().translate("REFERENCE_NUMBER_EXISTS"), { appearance: 'error', autoDismiss: true, });
        let saleArr = this.state.sale;
        saleArr.referenceExist = true;
        this.setState({sale: saleArr})
        this.saveOnlyOnce();
      } else if (response.data[0].invoice > 0){
        this.props.addToast(helper().translate("INVOICE_NUMBER_EXISTS"), { appearance: 'error', autoDismiss: true, });
        let saleArr = this.state.sale;
        saleArr.invoiceExist = true;
        this.setState({sale: saleArr})
        this.saveOnlyOnce();
      }
    })
    
  } else {
          //Update
          let invoiceNo =  this.state.invoiceIdFormat === false ?  this.state.sale.invoiceNo : '';
          let params = `reference=${this.state.sale.reference}&invoiceNo=${invoiceNo}`;
          apiService()
          .query(
            "searchSaleReference",
            params
          )
          .then((response) => {
            if(this.state.sale.referenceChanged === true || this.state.sale.invoiceChanged === true){
              if((response.success && response.data[0].reference === 0 && response.data[0].invoice === 0 ) ){
                this.updateSale(id, yesSave);
   
               }else if (response.data[0].reference > 0){
                this.props.addToast(helper().translate("REFERENCE_NUMBER_EXISTS"), { appearance: 'error', autoDismiss: true, });
                let saleArr = this.state.sale;
                saleArr.referenceExist = true;
                this.setState({sale: saleArr})
              } else if (response.data[0].invoice > 0){
                this.props.addToast(helper().translate("INVOICE_NUMBER_EXISTS"), { appearance: 'error', autoDismiss: true, });
                let saleArr = this.state.sale;
                saleArr.invoiceExist = true;
                this.setState({sale: saleArr})
              }
            }else {
              this.updateSale(id, yesSave);
            }
           
          })
          
  }
}else{
  this.props.addToast(helper().translate('NEGATIVE_SALE_ISNT_ALLOWED'), { appearance: 'error', autoDismiss: true, });
}
}

savePayment = () => {
  let payBtn = this.state.paymentBtn;
  let saleObj = this.state.sale;
  let paymentCopy = this.state.payment;
  let paymentSumCheck = parseFloat(paymentCopy[1].inputValue) +parseFloat(paymentCopy[2].inputValue) +parseFloat(paymentCopy[3].inputValue) +parseFloat(paymentCopy[4].inputValue);
  if(this.validatePaymentForm()){
    if(paymentSumCheck > 0){
      payBtn.savePayment = true;
      saleObj.totalSalePayment = (paymentCopy[5].inputValue);
      saleObj.amountLeft = (paymentCopy[0].inputValue - saleObj.totalSalePayment);
      this.props.addToast( "Saved Successfully", { appearance: 'success', autoDismiss: true, });
      this.setState({paymentBtn: payBtn , sale: saleObj});
      this.onArrowClick();
    }
  }
 
}
addItemsForm =() =>{
  var sDetails =  this.state.saleDetails;
  var saleId = this.props.match.params.id;
  if(saleId>0){

    var newItem = {no: Math.max(...sDetails.map(o => o.no), 0) + 1 , id:0,itemId:0, itemName:'zgjidheni artikullin', barcode:'zgjidheni barkodin',quantity: parseInt(this.state?.sale?.salesTypeId) === 4 ? -1 : 1 ,saleId:  parseInt(saleId),price:0.0, avgPrice:0, vat: 0 , vatSum:0 , projectId: 0, addButton: true , validate: true , totalPrice: 0 , totalPriceTvsh: 0 , status: 1};
  }else{

    var newItem = {no: Math.max(...sDetails.map(o => o.no), 0) + 1 , id:0,itemId:0, itemName:'zgjidheni artikullin', barcode:'zgjidheni barkodin',quantity: parseInt((this.props.location.search).substring(1,this.props.location.search.length)) === 4 ? -1 : 1 ,
    price:0.0, avgPrice:0, vat: 0 , vatSum:0 , projectId: 0, addButton: true , validate: true , totalPrice: 0 , totalPriceTvsh: 0 };
  }
  sDetails.push(newItem);

  for(var i= 0 ; i< sDetails.length ; i++){
    sDetails[i].addButton = false;
    sDetails[i].editMode = false;
    if(i === (sDetails.length - 1)) {
    sDetails[i].addButton = true;
    sDetails[i].editMode = true;
    }
    }

  this.setState({saleDetails: sDetails});
}

deleteitem = (rowId) => {
  var sDetails = this.state.saleDetails;
  var getRow = this.state.saleDetails.filter((row) => row.no == rowId);
  if(getRow[0].id > 0) {
    apiService().query('deleteSaleDetails', "id="+getRow[0].id).then((response) => {
      if (response.success) {
        var sDetails = this.state.saleDetails.filter((row) => row.no !== rowId);
        if(sDetails.length === 0 ){
        var newItem = {no: Math.max(...sDetails.map(o => o.no), 0) + 1 , id:0,itemId:0, itemName:'zgjidheni artikullin', barcode:'zgjidheni barkodin',quantity:1,price:0.0, avgPrice: 0, saleId: parseInt(this.props.match.params.id) , vat: 0 , vatSum:0 , addButton: true , validate: true , status: 1};
        sDetails.push(newItem);
        }
        for(var i= 0; i< sDetails.length; i++  ){
          if(i === (sDetails.length - 1)) {
            sDetails[i].addButton = true;
            }}
        var num = 1;
        sDetails.forEach(i=> {i.no = num; num++});
        // console.log("sdetails" , sDetails);
        var updateSaleDetails = [];
        var count = 1;
        var arr = this.state.saleDetails.filter((i) => i.id !== getRow[0].id );
        arr.forEach(i=> {i.no = count; count++});
        // console.log("Arr" , arr);
        arr.forEach(e => {
             var contextObj = {
              entity: e,
              status: 2,
              type: 'salesDetails'
           }
             updateSaleDetails.push(contextObj);
           });
          //  console.log("items to update" ,updateSaleDetails)
           apiService().saveChanges(updateSaleDetails).then((response) => {
             if(response.success){
               console.log("me sukses")
             }
          }) 
         
        
        this.setState({saleDetails: sDetails});
        this.getItems(this.state.sale.partnerId,this.state.sale.warehouseId)
      } else {
        console.log('Error:' + response.errorMessage);
      }
    });
  } else {
    var sDetails = this.state.saleDetails.filter((row) => row.no !== rowId);
    for(var i= 0; i< sDetails.length; i++  ){
      if(i === (sDetails.length - 1)) {
        sDetails[i].addButton = true;
        }}
    var count = 1;
    sDetails.forEach(i=> {i.no = count; count++});
    if(sDetails.length === 0 ){
      var newItem = {no: Math.max(...sDetails.map(o => o.no), 0) + 1 , id:0,itemId:0, itemName:'select item', barcode:'select barcode',quantity:1,
    price:0.0, vat: 0 , vatSum:0 , addButton: true};
    sDetails.push(newItem);
    }
  }

  this.totalSale(sDetails);
  this.setState({saleDetails: sDetails});
}

addRowEnter = (event)=>{
  if(event.key === 'Enter'){
    this.addItemsForm();
  }
}


alertnOnDelete= (no,id) => {

if(id > 0) {

  confirmAlert({
    customUI: ({ onClose }) => {
      return (
        <div className="custom-ui" >
          <h1>A jeni te sigurt?</h1>
          <p>Dëshironi të fshini këtë artikull?</p>
          <button
            onClick={() => {
              this.deleteitem(no);
              onClose();
            }}
          >
            Po , Fshije!
          </button>
          <button onClick={onClose}>Jo</button>
        </div>
      );
    }
  
  })
}else{
  this.deleteitem(no);
}
  
};

rowClick = (no)=>{
  if(this.state.sale.partnerId > 0 && this.state.sale.warehouseId > 0 && this.state.warehouse?.find(ware => ware.id == this.state.sale.warehouseId)){

    var saleDetails = this.state.saleDetails;
  
    saleDetails.forEach(i=> i.editMode = false);
  
    saleDetails[no-1].editMode = !saleDetails[no-1].editMode;
  
    if(saleDetails[no-1].id > 0){
      saleDetails[no-1].status = 2;
    }else{
      saleDetails[no-1].status = 1;
    }
    this.setState(saleDetails);
  }else{
    this.props.addToast(helper().translate("PLEASE_CHOOSE_PARTNER_AND_WAREHOUSE"), { appearance: 'error', autoDismiss: true, });
  }
}
partnerBtn= () => {
  return(
    this.setState({partnerShow: true})
  )
}

print = (item, invoiceForm) => {
  window.open(`#/print${invoiceForm}/${item.id}`, 'popUpWindow','height=1300,width="100%",left=100,top=100,right=100,bottom=100,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes')
}






  render() {
    const { saleDetails , paymentSales,warehouse , salesType , payMethod , partners, items , units , vatLevel , projects , banksPos, invoiceForm, fullItems, branches} = this.state;
    
    // const warehouseOptions = warehouse.map((item, index) => ({
    //     value: item.id,
    //     label: item.name
    //   }));
      
      const partnerOptions = partners.map((item , index) => ({
        value: item.id,
        label: item.companyName,
        fiscalNo: item.fiscalNo,
        cityName: item.cityName,
        isDisabled: item.isDisabled ? item.isDisabled : false
      }) )

   
      const banksPosOption = banksPos.map((item , index) => ({
        value: item.id,
        label: item.name
      }) )
      const projectsOptions = projects.map((item , index) => ({
        value: item.id,
        label: item.name
      }) )
      const salesOptions = salesType.map((item, index) => ({
        value: item.id,
        label: item.name
      }));
      const payOptions = payMethod.map((item, index) => ({
        value: item.id,
        label: item.name
      }));
      const itemOptions = items.map((item, index) => ({
        value: item.id,
        label: item.itemName,
        barcode: item.barcode,
        quantity: item.quantity,
        isDisabled: item.isDisabled ? item.isDisabled : false
      }))
      const fullItemBarcodeOptions = fullItems.map((item, index) => ({
        value: item.id,
        label: item.barcode
      }))
      const itemNameOptions = items.map((item, index) => ({
        value: item.id,
        label: item.itemName
      }))
      const fullIemNameOptions = fullItems.map((item, index) => ({
        value: item.id,
        label: item.itemName
      }))
      const unitsOptions = units.map((item, index) => ({
        value: item.typeId,
        label: item.name
      }))

      const styles =  {
        container: provided => ({
          ...provided,
          minWidth: '160px'
        }),
        control: (provided, state) => ({
          ...provided,
          background: '#fff',
          borderColor: '#9e9e9e',
          minHeight: '28.59px',
          height: '28.59px',
          boxShadow: state.isFocused ? null : null,
        }),
        menuPortal: base => ({ ...base, zIndex: 9999 }),
        valueContainer: (provided, state) => ({
          ...provided,
          height: '28.59px',
          padding: '0 6px'
        }),
    
        input: (provided, state) => ({
          ...provided,
          margin: '0px',
        }),
        indicatorSeparator: state => ({
          display: 'none',
        }),
        indicatorsContainer: (provided, state) => ({
          ...provided,
          height: '28.59px',
        }),
      };
      const multipleItemStyle = {
        container: provided => ({
          ...provided,
          minWidth: '190px'
        }),
        option: (provided, state) => ({
          ...provided,
          fontWeight: state.isDisabled ? 'bold' : 'normal',
          color: state.isDisabled ? 'black' : state.isSelected ? 'white' : '#858796',
        }),
        control: (provided, state) => ({
          ...provided,
          background: "#fff",
          borderColor: "#9e9e9e",
          minHeight: "28.59px",
          height: "28.59px",
          boxShadow: state.isFocused ? null : null,
        }),
        menu: (provided, state) => ({
         ...provided,
         width: "auto"
        //  marginTop: "30px"
       }),
        menuList: (provided, state) => ({
         ...provided,
         width: "auto"
       }),
      
        valueContainer: (provided, state) => ({...provided,height: "28.59px",padding: "0 6px",}),
        input: (provided, state) => ({ ...provided, margin: "0px",}), indicatorSeparator: (state) => ({ display: "none", }),
        indicatorsContainer: (provided, state) => ({...provided,height: "28.59px",}),
      };
      const partnerOption = props => {
        return (
          <components.Option {...props}>
            
            <div className="d-flex text-truncate">
              <p style={{width: ` ${ 250}px`}} className="m-0 text-left ">{props.data.label}</p>|<p style={{width: ` ${180}px`}} className="m-0 text-center ">{props.data.fiscalNo}</p>|<p style={{width: ` ${ 180}px`}} className="m-0 text-center ">{props.data.cityName}</p></div>
          </components.Option>
        );
      };

      const itemOption = props => {
        return (
          <components.Option {...props}>
            <div className="d-flex text-truncate">
              <p style={{width: ` ${ 250}px`}} className="m-0 text-left text-wrap ">{props.data.label}</p>|<p style={{width: ` ${ 160}px`}} className="m-0 text-center ">{props.data?.barcode}</p>|<p style={{width: ` ${ 100}px`}} className="m-0 text-center ">{props.data.quantity}</p></div>
          </components.Option>
        );
      };

      const customFilterBuyerOption = (option, inputValue) => {
        // Customize the filter logic here based on your desired columns to search
        const labelMatches = option.data?.label?.toLowerCase().includes(inputValue.toLowerCase());
        const fiscalNoMatches = option.data?.fiscalNo?.toLowerCase().includes(inputValue.toLowerCase());
        const cityNameMatches = option.data?.cityName?.toLowerCase().includes(inputValue.toLowerCase());
        
        return labelMatches || fiscalNoMatches || cityNameMatches;
      };
      const customFilterItemOption = (option, inputValue) => {
        // Customize the filter logic here based on your desired columns to search
        const labelMatches = option.data?.label?.toLowerCase().includes(inputValue.toLowerCase());
        const barcodeMatches = option.data?.barcode?.toLowerCase().includes(inputValue.toLowerCase());
        
        return labelMatches || barcodeMatches ;
      };

      
 
      let that = this;
    return (
      <div>
      <div id="Sale" className={this.state.paymentBtn.showPayFrom || this.state.paymentBtn.showPayments ? "d-none" : ""}>
        <div className="container-fluid px-2 mt-3">
        <Search headersFrom={"SALES"}  search={false} printClick={()=>this.print(this.state.sale, invoiceForm)} save={this.saveSale} newHandle={this.newHandle} new={`#/sale/0/type?${this.state.sale?.salesTypeId}`} back="#/sales"/>
        </div>
        {this.state.loader && <LoaderComponent /> }
        {!this.state.loader && <div> <div className="container-fluid px-2 ">
          <div className="card border-left-primary shadow h-100 mt-1 pt-1 pb-3">
            <div className="form  px-4" >
              <div className="row ps-2 ">
                <div className="col-md-9 col-12">
                  <div className="row">
                    <div className="col-md-4 col-12 pl-0">
                      <div className="row align-items-center">
                      <div className="col">
                        <label className="text-xs font-weight-bold text-primary mb-0">
                        {helper().translate("BUYER")}
                        </label>
                        <Select  
                          name="partnerId"
                          openMenuOnFocus={true}
                          components={{ Option: partnerOption }}
                          className={this.state.validate ? this.state.sale.partnerId == null ? "is-invalid-s" : '' : '' }
                          placeholder={helper().translate("CHOOSE_THE_BUYER")}
                          value={partnerOptions.find(partner => partner.value == (this.state.sale.partnerId != null ? this.state.sale.partnerId : '' )) || ''}
                          onChange={this.onSelectChange} 
                          options={partnerOptions}
                          filterOption={customFilterBuyerOption}
                          styles={multipleItemStyle}
                           />
                           {/* { this.state.validate ? this.state.sale.partnerId == null ? <span className="text-center text-danger small">ju lutem zgjidheni bleresin</span> : '' : '' } */}
                      </div>
                      <SaleAddPartner  onClose={this.getPartners} props={that.props}/>
                      </div>
                    </div>
                    <div className="col-md-4 col-12 pl-0">
                      <div className="">
                        <label className="text-xs font-weight-bold text-primary mb-0">
                        {helper().translate("SALE_TYPE")}
                        </label>
                        <Select 
                          name="salesTypeId" 
                          openMenuOnFocus={true}
                          isDisabled
                          placeholder="Zgjedh Tipin" 
                          className={this.state.validate ? this.state.sale.salesTypeId == null ? "is-invalid-s" : '' : ''}
                          value={salesOptions?.find(s => s.value == this.state.sale.salesTypeId)}
                          // defaultValue={salesOptions?.find(s => s.value == (this.props.location.search).substring(1,this.props.location.length))}
                          onChange={this.onSelectChange}
                          options={salesOptions}
                          styles={styles}  />
                          {/* {console.log((this.props.location.search).substring(1,this.props.location.length))} */}
                          {/* {this.state.validate ? this.state.sale.salesTypeId == null ? <span className="text-center text-danger small">zgjidheni tipin shitjes</span> : '' : ''} */}
                      </div>
                    </div>
            
                    <div className="col-md-4 col-12 l-straight pl-0">
                      <div className="">
                        <label className="text-xs font-weight-bold text-primary mb-0">
                        {helper().translate("INVOICE_NO")}
                        </label>
                         <input
                            className={`form-control form-control-sm ${this.state.sale?.invoiceExist ? 'is-invalid': ''}`}
                            name="invoiceNo"
                            type="text"
                            disabled={this.state.invoiceIdFormat ===  false ?  false : true}
                            onChange={this.onFieldChange}
                            value={this.state.sale?.invoiceNo}
                            />
                      </div>
                    </div>
                    </div>
                    
                    <div className="row">
                    <div className="col-md-4 col-12 pl-0">
                          <label className="text-xs font-weight-bold text-primary mb-0">
                          {helper().translate("REFERENCE")}
                          </label>
                          <input
                            className={`form-control form-control-sm ${this.state.sale?.referenceExist ? 'is-invalid': ''}`}
                            name="reference"
                            type="text"
                            onChange={this.onFieldChange}
                            value={this.state.sale?.reference}
                          />
                   
                    </div>

                    <div className="col-md-4 col-12 pl-0">
                      <div className="row">
                        <div className="col-md-8 col-12 ">
                          <label className="text-xs font-weight-bold text-primary mb-0">
                          {helper().translate("INVOICE_DATE")}
                          </label>
                          <input
                            name="date"
                            className={this.state.validate ? this.state.sale.date == '' ? "form-control form-control-sm is-invalid" : 'form-control form-control-sm ' : 'form-control form-control-sm '}
                            type="date"
                            value={helper().isValidDate(this.state.sale?.date)  ?  this.state.sale.date.toISOString().substring(0,10): ''}
                            onChange={this.onFieldChange}
                          />
                          {/* { this.state.validate ? this.state.sale.date == '' ? <span className="text-center text-danger small">zgjidheni daten</span> : '' : '' } */}
                        </div>
                        <div className="col-md-4 col-12 pl-0">
                          <div className=" d-flex flex-column align-items-center">
                            <label className="text-xs font-weight-bold text-primary mt-2 mb-0">
                            {helper().translate("WITH_VAT")}
                            </label>
                            <div className="text-light">
                            <Switch      
                             name="withVat"                     
                             onColor="#86d3ff"
                             offColor="#000000"
                             onHandleColor="#000000"
                             handleDiameter={25}
                             width={55}
                             height={20}
                             uncheckedIcon={<span style={{display:'flex', margin: '-4px 0 0 -5px'}}>{helper().translate("NO")}</span>}
                             checkedIcon={<span style={{display:'flex', margin: '-4px 0 0 -5px'}}>{helper().translate("YES")}</span>}
                             padding="7px 0px"
                             boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                             activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                             onChange={this.withVatChanged} 
                             checked={this.state.sale.withVat}
                            />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 col-12 pl-0">
                      <div className="">
                        <label className="text-xs font-weight-bold text-primary mb-0">
                        {helper().translate("WAREHOUSE")}
                        </label  >
                        {/* <Select 
                        name="warehouseId" 
                        openMenuOnFocus={true}
                        className={ this.state.validate ? this.state.sale.warehouseId == null ? "is-invalid-s" : '' : ''}
                        // placeholder="Zgjedh Tipin" 
                        value={warehouseOptions?.find(ware => ware.value == this.state.sale.warehouseId) }  
                        onChange={this.onSelectChange} 
                        options={warehouseOptions}
                        styles={styles}  
                        isDisabled={!this.state.userSettings?.allowToChangeWarehouse}
                        /> */}
                        <SelectByGroup 
                        name="warehouseId"
                        className={ this.state.validate ? this.state.sale.warehouseId == null ? "is-invalid-s" : '' : ''}
                        parent={branches} 
                        child={warehouse} 
                        childParentId={"branchId"} 
                        placeholder={helper().translate("CHOOSE_WAREHOUSE")}
                        styles={styles}
                        isDisabled={!this.state.userSettings?.allowToChangeWarehouse}
                        onChange={this.onSelectChange}
                        value={this.state.sale.warehouseId}
                        />
                        {/* { this.state.validate ? this.state.sale.warehouseId == null ? <span className="text-center text-danger small">zgjidheni depon</span> : '' : '' } */}
                      </div>
                    </div>
                  </div>

                </div>
                <div className="text-box d-sm-none d-none d-md-block col-3  pl-0">
                  <label className="text-xs font-weight-bold text-primary mb-0">
                  {helper().translate("COMMENT")}
                  </label>
                  <br></br>
                  <textarea 
                  className="textareas form-control h-81" 
                  name="comment"
                  // defaultValue={this.state.sale.comment}
                  value={this.state.sale.comment}
                  onChange={this.onFieldChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid px-2 mt-1">
          <div className="card shadow mt-1">
            <div className="card-header py-2">
              <h6 className="m-0 font-weight-bold text-primary">{helper().translate("INVOICE")}</h6>
            </div>
          <div className="card-body table-responsive p-0 mt-1">
          <table  onKeyPress={this.addRowEnter} className="table table-hover text-center sales-table">
                <thead>
                  <tr>
                    <th>{helper().translate("NR")}</th>
                    {/* <th>{helper().translate("BARCODE")}</th> */}
                    <th>{helper().translate("ITEM")}</th>
                    {/* <th>Njesia</th> */}
                    <th>{helper().translate("PROJECT")}</th>
                    <th>{helper().translate("UNIT")}</th>
                    <th>{helper().translate("QUANTITY")}</th>
                    <th className="text-truncate ">{helper().translate("PRICE_WITHOUT_VAT")}</th>
                    <th className="text-truncate ">{helper().translate("DISCOUNT")} %</th>
                    <th className="text-truncate ">{helper().translate("PRICE_WITH_DISCOUNT")}</th>
                    {this.state.sale.withVat? <th style={{width: '65px' }} >{helper().translate("VAT")} %</th> :'' }
                    {this.state.sale.withVat? <th className="text-truncate ">{helper().translate("PRICE_WITH_VAT")}</th> :'' }
                    <th >{helper().translate("TOTAL")}</th>
                    {this.state.sale.withVat? <th className="text-truncate ">{helper().translate("VAT_VALUE")}</th> :'' }
                    {this.state.sale.withVat? <th className="text-truncate " >{helper().translate("TOTAL_WITH_VAT")}</th> :'' }
                    <th>{helper().translate("ADD")}</th>
                    <th>{helper().translate("DELETE")}</th>
                  </tr>
                </thead>
            <tbody className='pb-5' >
            {saleDetails.map((item, key) => 

                    <tr  key={item.no} className="mainTable">
                      <td>{item.no}</td>
                      {/* Barcode */}                   
                      { 
                        item.editMode ?  
                        <td>
                           <Select  
                          openMenuOnFocus={true}
                          components={{ Option: itemOption }}
                          menuPortalTarget={document.body}  
                          // className={this.state.validate ? this.state.sale.partnerId == null ? "is-invalid-s" : '' : '' }
                          // placeholder={helper().translate("CHOOSE_THE_BUYER")}
                          value={fullIemNameOptions.find(i => i.value === item.itemId)} 
                          onChange={function(e,obj) { that.itemChange(e,obj,item.no)} } 
                          options={itemOptions}
                          filterOption={customFilterItemOption}
                          styles={multipleItemStyle}
                           /> 
                        {item.validate ? '' : '' }</td>
                          : 
                        <td onClick={function(){ that.rowClick(item.no)}}>
                          {item.validate === false ? item?.itemId === 0 ? <span className="text-center text-danger small">{helper().translate("CHOOSE_ITEM")}</span> :item.itemName.substring(0,25) : item.itemName.substring(0,25) }
                          </td>
                      } 
                     {/* ItemName / artikullit */}
                     {/* { 
                        item.editMode ?  
                        <td><Select openMenuOnFocus={true}  styles={styles} menuPortalTarget={document.body}  value={fullIemNameOptions.find(i => i.value === item.itemId)}  
                         onChange={ (e,obj)=> that.itemChange(e,obj,item.no)}  options={itemNameOptions} ></Select>{item.validate ? '' : '' }</td>
                          : 
                        <td onClick={function(){ that.rowClick(item.no)}}>
                          {item.validate === false ? item?.itemId === 0 ? <span className="text-center text-danger small">{helper().translate("CHOOSE_ITEM")}</span> : item.itemName.substring(0,25) : item.itemName.substring(0,25) }
                          </td>

                      }   */}
                      {/* Project / projekti */}
                     { 
                        item.editMode ?  
                        <td><Select openMenuOnFocus={true} name="projectId" onChange={ (e,obj)=> that.itemChange(e,obj,item.no)}  styles={styles} menuPortalTarget={document.body}  value={projectsOptions.find(i => i.value === item.projectId)}  options={projectsOptions} ></Select>{item.validate ? '' : '' }</td>
                          : 
                        <td onClick={function(){ that.rowClick(item.no)}}>
                          {projectsOptions.find(p => p.value === item?.projectId)?.label }
                          </td>

                      }
                        
                      {/* unitName / njesia */}
                      {/* {item.editMode ? <td><Select isDisabled={true} styles={stylesUnit} value={unitsOptions.find(u => u.value === item.unitId)} options={unitsOptions}  ></Select></td> : <td onClick={function(){ that.rowClick(item.no)}}>{typeof(unitsOptions.find(u => u.value === item.unitId)) !== 'undefined' ? unitsOptions.find(u => u.value === item.unitId).label : ''}</td> } */}

                      {item.editMode ? <td><input style={{minWidth: "100px"}} type='text' disabled className="form-control form-control-sm text-center w-100 "  value={unitsOptions.find(u => u.value === item.unitId)?.label} 
                      onChange={function(e,obj) { that.onFieldDetailsChange(e,obj,item.no)} } ></input></td> : <td onClick={function(){ that.rowClick(item.no)}}>{typeof(unitsOptions.find(u => u.value === item.unitId)) !== 'undefined' ? unitsOptions.find(u => u.value === item.unitId).label : ''}</td> }
                      

                      {/* Quantity / sasia */}
                     {
                         item.editMode ? 
                        <td name="quantity" onClick={function(){ that.rowClick(item.no)}}><input name="quantity"  style={{minWidth: "100px"}} className={` form-control form-control-sm text-center w-100  ${this.state.saleDetails[item.no-1].isntAllowedQuantity === true ? "is-invalid mb-0" : ""}`}  type="number" value={this.state.saleDetails[item.no-1].quantity}  onChange={(e,obj)=> that.onFieldDetailsChange(e,obj,item.no)}></input></td>
                         :
                         <td className={`${item.isntAllowedQuantity === true ? "is-invalid-i" : ""}`} onClick={function(){ that.rowClick(item.no)}}>{item.quantity}</td>

                      }
                      {/* Price / qmimi pa tvsh*/}

                      {item.editMode ? <td><input name="price"   style={{width: '100%'}}  className="form-control form-control-sm text-center " min="0"  type="number" value={helper().toFixed2("price",this.state.saleDetails[item.no-1].price,true)}  onChange={(e,obj)=> that.onFieldDetailsChange(e,obj,item.no)}></input></td>  : <td onClick={function(){ that.rowClick(item.no)}}>{ typeof(item.price) != 'undefined' ? helper().formatNumber( parseFloat(item.price)  , true)  : item.price }</td> }
                      

                      
                      
                      
                      {/* Discount / zbritja*/}
                      {item.editMode ? <td><input name="discount"  style={{minWidth: "100px"}} className="form-control form-control-sm text-center " type="number" value={ helper().toFixed2("discount",this.state.saleDetails[item.no-1].discount)} min="0" max="100"  onChange={(e,obj)=> that.onFieldDetailsChange(e,obj,item.no)}></input></td> : <td onClick={function(){ that.rowClick(item.no)}}>{item.discount}</td>  }
                      

                      {/* Price with discount / qmimi me zbritje*/}
                      {item.editMode ? <td ><input name="priceDiscount"   style={{width: '100%' }} value={helper().toFixed2("priceDiscount",this.state.saleDetails[item.no-1].priceDiscount,true)} className="form-control form-control-sm text-center " type="number" onChange={(e,obj)=> that.onFieldDetailsChange(e,obj,item.no)} ></input></td> : <td onClick={function(){ that.rowClick(item.no)}}>{item.discount > 0 ? helper().formatNumber( parseFloat(item.priceDiscount), true) : item.priceDiscount}</td> }
                      

                      {/* vat */}
                      {/* {
                        this.state.sale.withVat ? <td><input name="vat"  style={{width: '80px'}} className="form-control form-control-sm text-center " type="number" value={this.state.saleDetails[item.no-1].vat}  onChange={(e,obj)=> that.onFieldDetailsChange(e,obj,item.no)} ></input></td> : ''
                      } */}
                      { item.editMode ? ( this.state.sale.withVat ? 
                          <td><select
                          name="vat"
                          openMenuOnFocus={true}
                          onChange={(e,obj)=> that.onFieldDetailsChange(e,obj,item.no)}
                          className='form-select form-select-sm '
                          value={this.state.saleDetails[item.no-1].vat}
                          style={{width: "65px" , height: "28.59px" }}
                          
                          >
                          {vatLevel.map(val => {
                            return(
                              <option value={val.id}>{val.id}</option>
                              )
                            }) }
                          </select>
                          </td> : '') : ( this.state.sale.withVat ? (<td onClick={function(){ that.rowClick(item.no)}}>{item.vat}</td>) : '')}
                          
                      {/* PriceTvsh / qmimi me tvsh*/}
                      { item.editMode ?  (this.state.sale.withVat ? <td><input name="priceTvsh"    style={{width: '100%'}} className="form-control form-control-sm text-center"  min="0" type="number" value={helper().toFixed2("priceTvsh",this.state.saleDetails[item.no-1].priceTvsh,true)} onChange={(e,obj)=> that.onFieldDetailsChange(e,obj,item.no)}></input></td> : '') : ( this.state.sale.withVat ? <td onClick={function(){ that.rowClick(item.no)}}>{typeof(item.priceTvsh) != 'undefined' ? helper().formatNumber( parseFloat(item.priceTvsh)  , true) : item.priceTvsh }</td> : '') }
                      
                      
                      {/* TotalPrice / totali pa tvsh*/}

                      { this.state.sale.withVat ?  
                       item.editMode ? (<td><input name="totalPrice"  disabled style={{minWidth: "100px"}}   className="form-control form-control-sm text-center w-100 " type="number" value={helper().toFixed2("totalPrice",this.state.saleDetails[item.no-1]?.totalPrice,true) }  onChange={(e,obj)=> that.onFieldDetailsChange(e,obj,item.no)}></input></td>) : ( <td onClick={function(){ that.rowClick(item.no)}}>{ typeof(item.totalPrice) != 'undefined' ? helper().formatNumber( parseFloat(item.totalPrice)  , true)  : item.totalPrice }</td>)
                       : 
                        item.editMode ? (<td><input name="totalPrice" style={{width: '100%'}}  className="form-control form-control-sm text-center " type="number" value={helper().toFixed2("totalPrice",this.state.saleDetails[item.no-1]?.totalPrice,true)}  onChange={(e,obj)=> that.onFieldDetailsChange(e,obj,item.no)}></input></td>) : ( <td onClick={function(){ that.rowClick(item.no)}}>{ typeof(item.totalPrice) != 'undefined'  ?  helper().formatNumber( parseFloat(item.totalPrice)  , true)  : item.totalPrice }</td>) }
                      

                      {/* vatSum */}
                       { item.editMode ?  (this.state.sale.withVat ? <td><input name="vatSum" disabled   style={{width: '100%'}} className="form-control form-control-sm text-center "  type="number" value={ helper().toFixed2("vatSum",this.state.saleDetails[item.no-1]?.vatSum,true)}  onChange={function(e,obj) { that.itemChange(e,obj,item.no)} }></input></td> : '') : ( this.state.sale.withVat ? <td onClick={function(){ that.rowClick(item.no)}}>{ typeof(item.vatSum) != 'undefined' ? helper().formatNumber( parseFloat(item.vatSum)  , true) : item.vatSum }</td> : '') }
                      
                      

                      {/* TotalPrice / totali me tvsh*/}
                      { item.editMode ?  (this.state.sale.withVat ? <td><input name="totalPriceTvsh"  style={{width: '100%'}} className="form-control form-control-sm text-center " type="number" value={helper().toFixed2("totalPriceTvsh",this.state.saleDetails[item.no-1].totalPriceTvsh,true)}  onChange={(e,obj)=> that.onFieldDetailsChange(e,obj,item.no)}></input></td> : '') : ( this.state.sale.withVat ? <td onClick={function(){ that.rowClick(item.no)}}>{ typeof(item.totalPriceTvsh) != 'undefined' ? helper().formatNumber( parseFloat(item.totalPriceTvsh)   , true)  : item.totalPriceTvsh }</td> : '') }

                      {/* Add Btn */}
                      {
                         this.state.saleDetails[item.no-1].addButton ? <td><button className="btn-edit" onClick={this.addItemsForm}><span className="icon text-white-40"><i className="fas fa-plus-circle"></i></span></button></td> : <td></td> 
                      }
                      
                      
                      {/* Delete btn */}
                      <td><button className="btn-delete" onClick={() =>this.alertnOnDelete(item.no , item.id)}><span className="icon text-white-40">
                            <i className="fas fa-minus-circle"></i>
                          </span></button></td>
                    </tr>      
            )}
            </tbody>
            <tfoot className="mb-2 mt-2 table-borderless no-colors">
              <tr>
              <td colSpan="8"></td>
              {this.state.sale.withVat? <th></th> :''}
              {this.state.sale.withVat? <th></th> :''}
              <td className="font-weight-bold align-middle ">{helper().translate("TOTAL")}:</td>
              {this.state.sale.withVat ? <td className="text-center" ><input type="text" className="form-control text-center pb-2 px-0" disabled  value={typeof(this.state.sale.subTotal) != 'undefined' ? helper().formatNumber( parseFloat(this.state.sale.subTotal)  )  : this.state.sale.subTotal } ></input></td> : '' }
              {this.state.sale.withVat ? <td className="text-center"><input type="text" className="form-control text-center px-0" disabled  value={typeof(this.state.sale.vatSum) != 'undefined' ? helper().formatNumber( parseFloat(this.state.sale.vatSum) ) : this.state.sale.vatSum } ></input></td> : ''}

              {<td className="text-center"><input type="text" className="form-control text-center px-0 "   disabled  value={typeof(this.state.sale?.totalSum) != 'undefined' ?  helper().formatNumber( parseFloat(this.state.sale.totalSum)  ) : this.state.sale.totalSum} ></input></td>}
              <td colSpan={2} className={this.props.match.params.id == 0 ? "font-weight-bold align-middle" : "d-none" } ><button  onClick={this.onButtonClickPay} style={{height: "28.6px" , fontSize: "12px"}}  className="btn  btn-primary text-capitalize font-weight-bold align-middle p-1 text-nowrap">{helper().translate("PAYMENT")}</  button></td>
                <td colSpan={2} className={this.props.match.params.id > 0 ? "font-weight-bold align-middle" : "d-none" }><button onClick={this.onButtonClickPayments}  style={{height: "28.6px" , fontSize: "12px"}}  className="btn  btn-primary text-capitalize w-100  ">{helper().translate("PAYMENT")}</  button></td>
              </tr>
              <tr>
                <td colSpan={this.state.sale.withVat ? "12" : "8"}></td>
                <td className="font-weight-bold align-middle ">{helper().translate("PAYMENT_TOTAL")}:</td>
                {<td className="text-center"><input type="text" className="form-control text-center px-0 "   disabled  value={helper().formatNumber( parseFloat(this.state.sale?.totalSalePayment) )  } ></input></td>}
                
              </tr>
              <tr>
                <td colSpan={this.state.sale.withVat ? "12" : "8"}></td>
                <td className="font-weight-bold align-middle ">{helper().translate("REMAINING_DEBT")}:</td>
                <td className="text-center"><input type="text" className="form-control text-center px-0 "   disabled  value={helper().formatNumber(this.state.sale?.amountLeft) } ></input></td>
              </tr>
            </tfoot>
          </table>
        </div>
        </div>
        </div>
        </div> }
       
      </div>

      {/*  payment */}
      <div id="payment" className={!this.state.paymentBtn.showPayFrom  ? "d-none" : ""}>
      <div className="container-fluid px-2 mt-3">
        <div className="card border-left-primary shadow h-100 mt-1 pt-1 pb-3">
          <div className="container">
            <div className="row font-weight-bold my-4" style={{height: "30px"}}>
              <div className="col-12 col-md-2 offset-md-4 d-flex justify-content-center justify-content-md-start">
              <h6 className="m-0 font-weight-bold text-primary">{helper().translate("PAYMENT")}</h6>
              </div>
              <div className="col-2 ">
                <div className="d-flex justify-content-end">
                    <a className="arrowLeft"  onClick={this.onArrowClick} ><i className="fas fa-arrow-left fa-lg" > </i></a>
                </div>
              </div>
            </div>
            <div className="row pt-2 font-weight-bold" >
              <div className=" col-12 col-md-3 offset-md-2 d-flex justify-content-center justify-content-md-start ">
                  <div  className="text-left">
                    <p>{helper().translate("PAYMENT_AMOUNT")}:</p>
                  </div>
              </div> 
              <div className="col-12 col-md-3  d-flex justify-content-center justify-content-md-start ">
                <input type="text"  className="form-control form-control-sm font-weight-bold text-right " disabled value={helper().formatNumber(this.state.payment[0].inputValue)}>
                </input>
              </div>
            </div>
            <div className="row pt-2 font-weight-bold" >
                <div className="col-12 col-md-3 offset-md-2 d-flex justify-content-center justify-content-md-start">
                  <div  className="text-left">
                    <p>{helper().translate("CASH")}:</p>
                  </div>
                </div>
              <div className="col-12 col-md-3  d-flex justify-content-center justify-content-md-start ">
                <input type="number" name="payCash" onChange={(e,obj)=> that.paymentFieldChange(e,1)} value={helper().toFixed2("payCash",this.state.payment[1].inputValue) } className="form-control form-control-sm font-weight-bold text-right " >
                </input>
              </div>
            </div>
            <div className="row pt-2 " >
              <div className="col-12 col-md-3 offset-md-2 d-flex justify-content-center justify-content-md-start">
                <div >
                  <Select styles={styles }  openMenuOnFocus={true}  onChange={function(e,obj) { that.paymentChange(e,obj,2)} } value={banksPosOption.find(i => i.value === this.state.payment[2].bankId) }  options={banksPosOption} ></Select>
                </div>
              </div>
              <div className="col-12 col-md-3  d-flex justify-content-center justify-content-md-start pt-2 pt-md-0" style={{height: "40px"}}>
                <input type="number" min="0" name="payBank1" disabled={this.state.payment[2].disabled} onChange={(e,obj)=> that.paymentFieldChange(e,2)} value={helper().toFixed2("payBank1",this.state.payment[2].inputValue) } className="form-control form-control-sm font-weight-bold text-right " >
                </input>
              </div>
            </div>
            <div className="row pt-2 " >
              <div className="col-12 col-md-3 offset-md-2 d-flex justify-content-center justify-content-md-start">
                <div>
                  <Select styles={styles } openMenuOnFocus={true}  onChange={function(e,obj) { that.paymentChange(e,obj,3)} } value={banksPosOption.find(i => i.value === this.state.payment[3].bankId)}   options={banksPosOption} ></Select>
                </div>
              </div>
              <div className="col-12 col-md-3  d-flex justify-content-center justify-content-md-start pt-2 pt-md-0" style={{height: "40px"}}>
                <input type="number" name="payBank2" disabled={this.state.payment[3].disabled}  onChange={(e,obj)=> that.paymentFieldChange(e,3)} value={helper().toFixed2("payBank2",this.state.payment[3].inputValue) } className="form-control form-control-sm font-weight-bold text-right " >
                </input>
              </div>
            </div>
            <div className="row pt-2 " >
              <div className="col-12 col-md-3 offset-md-2 d-flex justify-content-center justify-content-md-start">
                <div >
                  <Select styles={styles }  openMenuOnFocus={true}  onChange={function(e,obj) { that.paymentChange(e,obj,4)} } value={banksPosOption.find(i => i.value === this.state.payment[4].bankId)}   options={banksPosOption} ></Select>
                </div>
              </div>
              <div className="col-12 col-md-3  d-flex justify-content-center justify-content-md-start pt-2 pt-md-0" style={{height: "40px"}}>
                <input type="number" name="payBank3" disabled={this.state.payment[4].disabled} onChange={(e,obj)=> that.paymentFieldChange(e,4) } value={helper().toFixed2("payBank2",this.state.payment[4].inputValue)} className="form-control form-control-sm font-weight-bold text-right " >
                </input>
              </div>
            </div>
            <div className="row font-weight-bold pt-2" >
              <div className="col-12 col-md-3 offset-md-2 d-flex justify-content-center justify-content-md-start">
                  <div className="text-left">
                    <p>{helper().translate("PAYMENT_TOTAL")}:</p>
                  </div>
              </div>
              <div className="col-12 col-md-3  d-flex justify-content-center justify-content-md-start  ">
                <input type="text"  value={helper().formatNumber(this.state.payment[5].inputValue)}  disabled className="form-control font-weight-bold form-control-sm text-right " >
                </input>
              </div>
            </div>
            <div className="row font-weight-bold pt-2" >
              <div className="col-12 col-md-3 offset-md-2 d-flex justify-content-center justify-content-md-start">
                  <div  className="text-left">
                    <p>{helper().translate("REMAINING_DEBT")}:</p>
                  </div>
              </div>
              <div className="col-12 col-md-3  d-flex justify-content-center justify-content-md-start  ">
                <input style={{color: "red"}} type="text"  value={helper().formatNumber(this.state.payment[6].inputValue)}  disabled  className="form-control font-weight-bold form-control-sm text-right " >
                </input>
              </div>
            </div>
            <div className="row font-weight-bold pt-2" >
              <div className="col-12 col-md-3 offset-md-2 d-flex justify-content-center justify-content-md-start">
                  <div  className="text-left">
                    <p>{helper().translate("RETURN_SUM")}:</p>
                  </div>
              </div>
              <div className="col-12 col-md-3  d-flex justify-content-center justify-content-md-start">
                <input style={{color: "red"}} type="text" value={helper().formatNumber(this.state.payment[7].inputValue)}  disabled  className="form-control font-weight-bold form-control-sm text-right " >
                </input>
              </div>
            </div>
            <div className="row  pt-2 my-4 " >
              <div className="col-6 ps-0 offset-2 d-flex justify-content-center align-item-center ">
                  <button onClick={this.savePayment} className="btn btn-success ms-3 w-100"><p className="text-capitalize m-0">{helper().translate("SAVE")}</p></button>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
      
      {/* Payments Show */}

      {this.state.paymentBtn.showPayments ?  
      <div id="payments" >

      <PaymentSales 
      saleId={this.props.match.params.id}
      defaultAccount={this.state.defaultAccount} 
      partners={partnerOptions}
      sale={this.state.sale}
      cashBoxId={this.state.currentUserCashBoxId}
      hidePayments={this.hidePayments}
      />
      
    </div>
      : ''}
      
      </div>
    );
  }
}

export default withToast(Sale);
