import React, { useEffect, useState } from 'react'
import AdministrationForm from '../UI/AdministrationForm/AdministrationForm'
import apiService from '../../Services/apiServices'
import helper from '../../Services/helper'

const Project = (props) => {
    const [warehouses, setWarehouses] = useState()
    const [modal, setModal] = useState()

    const t = (key) => {
        return helper().translate(key);
    }

    const headers = [
        //{ key: 'id', columnName: t('Id'), type: 'input', required: true },
        { key: 'NAME',  name: 'name',  columnName: t('name'), type: 'input', required: true },
        //{ key: 'WAREHOUSEID', name: "warehouseId", columnName: t('warehouseId'), type: 'select', options: warehouses,  required: false },
        { key: 'WAREHOUSE', name: "warehouseId", columnName: t('warehouseName'), type: 'select', options: warehouses,  required: false },
        { key: 'CONATAC_PERSON', name: 'contactPerson', columnName: t('contactPerson'), type: 'input', required: false },
        { key: 'ADDRESS', name: 'address', columnName: t('address'), type: 'input', required: false },
        { key: 'PHONE', name: 'phone', columnName: t('phone'), type: 'input', required: false },
        { key: 'IS_DEFAULT', name: 'isDefault', columnName: t('isDefault'), type: 'switch', onlyTrue: true, options: [{value: 0, label: 'Jo'}, {value: 1, label: 'Po'}], required: false },
    ]

    const model = {
        id: 0,
        name: "",
        warehouseId: 0,
        contactPerson: "", 
        address: "",
        phone: "",
        status: 0,
        isDefault: false
    }

    const getWarehouses = () => {
        apiService().get('warehouses')
            .then((response)=> {
                if(response.success) {
                    let records = response.data.map(record => ({ value: record.id, label: record.name }));
                    setWarehouses(records)
                }
            })
    }

    const performRefresh = () => {
        alert('reffff')
    }

    useEffect(() => {
        getWarehouses();
    }, [])

    useEffect(() => {
        setModal(props.modal)
    }, [props.modal]);

    return (
        <div>
            <AdministrationForm new={modal} headers={headers} table="projects" name="Projekti" model={model} formType="modal" />
        </div>
    )
}

export default Project
