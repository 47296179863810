import React, { Component } from 'react';
import loginService from '../Services/loginService.js';
import apiService from '../Services/apiServices';
import config from '../config/config';

class Auth extends Component {

    constructor(props) {
        super(props);

        this.state = {
            //company: '',
            email: '',
            password: '',
            rememberMe: false
        }

        this.loginService = new loginService();
        this.login = this.login.bind(this);

    }

    componentDidMount() {

    }


    // checkValidity(value, rules) {
    //     let isValid = true;
    //     return isValid;
    // }

    onFieldChange = (e) => {
        this.setState({
            [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value
        })
    }

    login = (e) => {
        //config.company = this.state.company;
        //e.preventDefault();
        loginService().login(this.state.email, this.state.password, this.state.rememberMe)
            .then(function (result) {
                if (result.success) {

                    window.location = "#/";
                }
                else {
                    alert('Gabim ne kyqje! Emri i shfrytesuesi apo fjalekalimi jane gabim!')
                }
            });
    }

    render() {

        return (
            <div className="container">
                {/* Outer Row */}
                <div className="row justify-content-center">
                    <div className="col-xl-10 col-lg-12 col-md-9">
                        <div className="card o-hidden border-0 shadow-lg my-5">
                            <div className="card-body p-0">
                                {/* Nested Row within Card Body */}
                                <div className="row">
                                    <div className="col-lg-6 d-none d-lg-block bg-light" />
                                    <div className="col-lg-6">
                                        <div className="p-5">
                                            <div className="text-center">
                                                <h1 className="h4 text-gray-900 mb-4">Welcome Back!</h1>
                                            </div>
                                            <div className="user">
                                                <div className="form-group">
                                                    {/* <div className="form-group">
                                                        <input className="form-control form-control-user" type="text" placeholder="Company"
                                                            name="company" onChange={this.onFieldChange} required />
                                                    </div> */}
                                                    <div className="form-group">
                                                        <input className="form-control form-control-user" placeholder="Mail Address"
                                                            name="email" onChange={this.onFieldChange} required />
                                                    </div>
                                                    <div className="form-group">
                                                        <input className="form-control form-control-user" type="password" placeholder="Password"
                                                            name="password" onChange={this.onFieldChange} required />
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <div className="custom-control custom-checkbox small">
                                                        <input type="checkbox" className="custom-control-input" id="customCheck" />
                                                        <label className="custom-control-label" htmlFor="customCheck">Remember Me</label>
                                                    </div>
                                                </div>
                                                <button className="btn btn-primary btn-user btn-block" onClick={this.login}>
                                                    Login
                                                </button>
                                            </div>
                                            <hr />
                                            <div className="text-center">
                                                <a className="small" href="forgot-password.html">Forgot Password?</a>
                                            </div>
                                            <div className="text-center">
                                                <a className="small" href="register.html">Create an Account!</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


export default Auth;
