class CashBox {
    constructor(obj){
        if(obj){
            this.id = obj.id;
            this.sId = obj.sId;
            this.name= obj.name;
            this.warehouseId= obj.warehouseId;
            this.accountId= obj.accountId;
            this.createdBy= obj.createdBy;
            this.changedAt= obj.changedAt;
            this.changedBy= obj.changedBy;
            this.status= obj.status;
            this.branchId = obj.branchId
        }
        else {
            this.id = 0;
            this.sId = null;
            this.name= "";
            this.warehouseId= "";
            this.accountId= 0;
            this.createdBy= "";
            this.changedAt= new Date();
            this.changedBy= "";
            this.status= "";
            this.branchId = ""
        }
    }
}

export default CashBox;