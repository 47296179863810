import React from 'react'

const Company = () => {
    return (
        <div>
            <h2>To do Comapny...</h2>
        </div>
    )
}

export default Company
