import React, { useState, useEffect } from 'react';
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Switch, useLocation, useHistory } from "react-router-dom";
import { ToastProvider, useToasts } from 'react-toast-notifications';
import HeaderTools from '../Header/HeaderTools';
import apiService from '../../Services/apiServices';
import { confirmAlert } from 'react-confirm-alert'; 
import Search from '../Search/Search_Old';
import { MDBInput } from 'mdb-react-ui-kit';
import helper from '../../Services/helper';
import globals from '../../Services/globals';
  

const ReferenceData = () => {
    let location = useLocation();
    const history = useHistory()
    //console.log(props)
    const logedInUser = globals().getLogedInUser();
    const [userSettings, setUserSettings] = useState();
    const [referenceData, setReferenceData] = useState([]);
    const [addReference, setAddReference] = useState(false);
    const { addToast } = useToasts();
    const [error, setError ] = useState();
    const [referenceObj, setReferenceObj] = useState({id: 0, name: '', type: '', duration: 0});
    const [search, setSearch] = useState();

    const showAddReference = () => {
        setAddReference(!addReference)
    }

    const getUserSettings = () => {
        globals().getUserSettings(logedInUser).then((response) => {
            setUserSettings(response)
        });
    }

    const getReferenceData = async (table) => {
        apiService()
        .get(table)
            .then((response) => {
                if (response.success) {
                    setReferenceData(response.data)
                } else {
                    console.log('Error:' + response.errorMessage);
                }
            });
    }

    const getType = (pathname) => {
        let type;
        if(pathname === 'categories') type = 'category';
        if(pathname === 'units') type = 'unit';
        setReferenceObj({...referenceObj, type: type})
    }

    const addNewReference = () => {
        setAddReference(true);
        setReferenceObj({...referenceObj, id: 0, name: ''})
        setError('');
        if(addReference){
            getFocus();
        }
    }   

    const editReference = (item) => {
        setAddReference(true)
        setReferenceObj({...referenceObj, id: item.id, name: item.name})
        if(addReference){
            getFocus();
        }
    }

    const getFocus = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        document.getElementById("refName").focus();
    }

    // const onPathChange = () => {
    //     return history.listen((location) => { 
    //         console.log(`You changed the page to: ${location.pathname}`) 
    //         getReferenceData(location.pathname.substring(1));
    //         getType(location.pathname.substring(1));
    //         showAddReference()
    //      })
    // }

    const save = () => {
        if(referenceObj.name){
            if(referenceObj.id != 0){
                apiService().put('referenceData', referenceObj)
                    .then((response)=>{responseMessage(response, 'Updated Successfully')});
            }
            else {
                apiService().post('referenceData', referenceObj)
                    .then((response)=>{responseMessage(response, 'Saved Successfully')});
            }
            setError('')
        }
        else{
            setError('Cannot be blank')
        }
    }

    const remove = (id) => {
        confirmAlert({
            customUI: ({ onClose }) => {
              return (
                <div className="custom-ui" >
                  <h1>A jeni te sigurt?</h1>
                  <p>Dëshironi të fshini këtë artikull?</p>
                  <button
                    onClick={() => {
                        apiService().delete('referenceData', id)
                            .then((response)=>{responseMessage(response, 'Delete Successfully')});
                        onClose();
                    }}
                  >
                    Po , Fshije!
                  </button>
                  <button onClick={onClose}>Jo</button>
                </div>
              );
            }
        })   
    }

    const responseMessage = (response, message) => {
        if(response.success){
            getReferenceData(location.pathname.substring(1));
            addToast( message, { appearance: 'success', autoDismiss: true, });
        }
        else{
            addToast('Ka ndodhur nje gabim!', { appearance: 'error', autoDismiss: true, });
        }
    }

    const handleChange = (event) => {
        let searchValue = event.target.value;
        setSearch(searchValue);
    }

    const searchResult = () => {
        let searchItems = referenceData,
        searchString = search?.trim().toLowerCase();

        if (searchString?.length > 0) {
            //searchItems = searchItems.filter((e) => e.name.toLowerCase().match(searchString));
            searchItems = searchItems.filter(item => {
                return (
                    item.id.toString().toLowerCase().match(searchString) ||
                    item.name.toLowerCase().match(searchString) ||
                    item.type.toLowerCase().match(searchString) 
                )
            });
            return searchItems
        }
        else {
            return searchItems
        }
    }

    useEffect(() => {
        getUserSettings()
    }, [])
    
    
    useEffect(() => {
        getReferenceData(location.pathname.substring(1));
        getType(location.pathname.substring(1));
    }, [location])

    useEffect(() => {
        if(addReference){
            getFocus();
        }
      }, [addReference]);

    useEffect(() => {
        searchResult();
    }, [search])

    return (
        <>
            <div>
                <div className="container-fluid px-2 mt-3">                  
                        <Search newHandle={() => addNewReference()} save={addReference ? () => save() : ''} change={(e) => setSearch(e.target.value)}/>

                        {addReference &&
                        <div className="card border-left-primary shadow h-100 mt-1 py-2">
                            <div className="card-body py-0">
                                <div className="row no-gutters align-items-center">
                                    <div className="form-group mb-0 pl-0">
                                        <MDBInput id="refName" type="text" className="form-control-sm" label="Shkruaj emrin e references" value={referenceObj.name} onChange={(e) => setReferenceObj({...referenceObj, name:e.target.value})} name="name" />
                                        {error && <span className='error'>{error}</span>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        }

                        <div className="card shadow mt-1">
                        <div className="card-header py-2">
                            <h6 className="m-0 font-weight-bold text-primary">{referenceObj.type == 'category' ? helper().translate("CATEGORIES") : helper().translate("UNITS")}</h6>
                        </div>
                        <div className="card-body table-responsive">
                            <table className="table table-hover">
                            <thead>
                                <tr>
                                {/* <th scope="col">Nr</th> */}
                                <th scope="col">{helper().translate('ID')}</th>
                                <th scope="col">{helper().translate('NAME')}</th>
                                <th scope="col">{helper().translate('TYPE')} </th>
                                {/* <th scope="col">Sasia</th>
                                <th scope="col">Cmimi Shitjes</th>
                                <th scope="col">Cmimi Mesatar</th>
                                <th scope="col">Totali</th> */}
                                {userSettings?.allowToDelete && 
                                    <th className="buttons" scope="col">Edit / Delete</th>
                                }
                                </tr>
                            </thead>
                            {searchResult().map((item, index) => (
                                <tbody>
                                <tr key={index}>
                                    <td> <a href={`#/item/${item.id}`}>{item.id}</a></td>
                                    <td> {item.name} </td>
                                    <td> {referenceObj.type} </td>
                                    {userSettings?.allowToDelete && 
                                        <td>
                                            <button onClick={() => editReference(item)} className="btn-edit mr-2">
                                                <span className="icon text-white-40">
                                                <i className="fas fa-edit" />
                                                </span>
                                            </button>
                                            <button onClick={() => remove(item.id)} className="btn-delete mr-2">
                                                <span className="icon text-white-40">
                                                <i className="fas fa-trash" />
                                                </span>
                                            </button>
                                        </td>
                                    }
                                </tr>
                                </tbody>
                            ))}
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ReferenceData
