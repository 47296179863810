import React, { useState, useEffect, forwardRef, useRef, useImperativeHandle } from "react";
import apiService from "../../Services/apiServices";
import helper from "../../Services/helper";
import { ToastProvider, useToasts } from 'react-toast-notifications';
import Select from "react-select";
import LoaderComponent from "../../PageComponents/UI/Components/LoaderComponent";

const ImportData = forwardRef((params, ref) => {
  const { addToast } = useToasts();
  const [file, setFile] = useState();
  const [array, setArray] = useState([]);
  const [units, setUnits] = useState();
  const [items, setItems] = useState();
  const [partners, setPartners] = useState();
  const [vatLevel, setVatLevel] = useState();
  const [projects, setProjects] = useState();
  const [accounts, setAccounts] = useState();
  const [errors, setErrors] = useState();
  const status = { added: 1, deleted: -1, changed: 2, unchanged: 0 };
  const hiddenFields = [
    params?.queryName === 'items' ? 'unitId' : '', 
    params?.queryName === 'partners' ? 'customer' : '', 
    params?.queryName === 'partners' ? 'supplier' : '',
    params?.queryName === 'partners' ? 'partnerType' : '',
    params?.queryName === 'partners' ? 'saveAs' : '',
    params?.queryName === 'transactionDetails' ? 'projectId12' : '',
    'last', 'zlast', 'status', 'editMode'
  ]
  const [currentTab, setCurrentTab] = useState(0)
  const [loader, setLoader] = useState(false)
  //var currentTab = 0;
  const styles = {
    container: provided => ({
      ...provided,
      Width: '100%'
    }),
    control: (provided, state) => ({
      ...provided,
      background: '#fff',
      borderColor: '#9e9e9e',
      minHeight: '25px',
      height: '25px',
      boxShadow: state.isFocused ? null : null,
    }),
    menuPortal: base => ({ ...base, zIndex: 9999999 }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: '25px',
      padding: '0 6px'
    }),

    input: (provided, state) => ({
      ...provided,
      margin: '0px',
    }),
    indicatorSeparator: state => ({
      display: 'none',
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: '25px',
    }),
  };

  useImperativeHandle(ref, () => ({

    itemsToCsv() {
      getItems()
    }

  }));

  const partnerTypes = [
    { value: 1, label: 'Customer' },
    { value: 2, label: 'Supplier' },
    { value: 3, label: 'Customer/Supplier' }
  ]

  const getUnits = () => {
    apiService().get('units').then(response => {
      if (response.success) {
        let records = response.data;
        records = records.map(record => ({ value: record.id, label: record.name }))
        setUnits(records)
      }
    })
  }

  const getVat = () => {
    apiService().get('vatlevel').then(response => {
      if (response.success) {
        let records = response.data;
        records = records.map(record => ({ value: record.id, label: record.value }))
        setVatLevel(records)
      }
    })
  }

  const getProjects = () => {
    apiService().get('projects').then(response => {
      if (response.success) {
        let records = response.data;
        records = records.map(record => ({ value: record.id, label: record.name }))
        setProjects(records)
      }
    })
  }

  const getAccounts = () => {
    apiService().query('getAccountingPlan').then(response => {
      if (response.success) {
        let records = response.data;
        records = records.map(record => ({ value: record.accountNumber, label: record.name }))
        setAccounts(records)
      }
    })
  }

  const getItems = () => {
    apiService().get('items').then(response => {
      if (response.success) {
        setItems(response.data)
        if (params?.queryName === 'itemsRegistration') {
          exportCsv(response.data)
        }
      }
    })
  }

  const getPartners = () => {
    apiService().get('partners').then(response => {
      if (response.success) {
        setPartners(response.data)
        if (params?.queryName === 'transactionDetails') {
          exportCsv(response.data)
        }
      }
    })
  }

  const fileReader = new FileReader();

  const handleOnChange = (e) => {
    setFile(e.target.files[0]);
  };

  const csvFileToArray = string => {
    const csvHeader = string.slice(0, string.indexOf("\n")).split(",");
    const csvRows = string.slice(string.indexOf("\n") + 1).split("\n");

    const array = csvRows.map(i => {
      const values = i.split(",");
      const obj = csvHeader.reduce((object, header, index) => {
        object[header] = values[index];
        return object;
      }, {});
      return obj;
    });

    if(params?.queryName === 'partners') {
      array.map((item) => 
        {
          if(item?.type == 1) item['customer'] = true;
          else if(item?.type == 2) item['supplier'] = true;
          else if(item?.type == 3) {
            item['customer'] = true;
            item['supplier'] = true;
          }
          item['partnerType'] = 1;
          item['saveAs'] = item['companyName']
        }
      )
    }

    {
      array.map((item) => (
        Object.entries(item).map((val) => (
          (val[0] == 'unit' && item.no !== '') ? setUnitId(val, item, array) : ''
        ))))
    }
    setArray(array?.filter(d => d.no !== ''));
    //prepareData(array)
    setLoader(false)
  };

 const setUnitId = (val, item, array) => {
    let findRecord = units?.find(v => v?.label.toLowerCase() == val[1].toLowerCase());
    if (findRecord) {
      let newArr = [...array];
      let index = item.no;
      newArr[index - 1]['status'] = status.changed;
      let prop = 'unitId'
      newArr[index - 1][prop] = findRecord.value;
      setArray(newArr)
    }
  }

  const exportCsv = (items) => {
    let headers;
    params?.queryName == 'itemsRegistration' ? headers = ['no', 'itemName', 'itemId', 'unitId', 'vat'] :
    params?.queryName == 'transactionDetails' ? headers = ['no', 'companyName', 'description', 'partnerId', 'projectId', 'accountNumber', 'credit', 'debit'] : 0
    //helper().exportCsv(items.filter(i => i.itemGoods), true)
    let newArr = params?.queryName == 'itemsRegistration' ? items?.filter(i => i.itemGoods) : items;
    let count = 1;
    newArr?.filter((value, index, self) => {
      value['no'] = count;
      count++;
      Object.keys(value).sort((a,b) => headers.indexOf(a)-headers.indexOf(b)).forEach(function (key) {

        if (key === 'id' && params?.queryName == 'itemsRegistration') value['itemId'] = value['id']
        if (key === 'id' && params?.queryName == 'transactionDetails') value['partnerId'] = value['id']
        if (key === 'id' && params?.queryName == 'transactionDetails') value['description'] = value['comment']
        if (key === 'vat') value['vat'] = 0
        if (!headers.includes(key.replace(/\s/g, ''))) {
          delete value[key];
        }
        // else {
        //   console.log(headers.indexOf(key), key)
        // }
        if(params?.queryName == 'transactionDetails') {
          value['projectId'] = 0
          value['accountNumber'] = params?.transactionType == 3 ? returnSpecificAccount("Llogaritë e pagueshme") : params?.transactionType == 4 ? returnSpecificAccount('Llogaritë e arkëtueshme') : 0
          value['debit'] = 0
          value['credit'] = 0
        }
        value['zlast'] = '';
      })
    })

    // newArr?.map((value, index, self) => (
    //   Object.keys(value).sort((a,b) => headers.indexOf(a)-headers.indexOf(b)).forEach(function (key) {
    //     console.log(value)
    //   }
    // )))

    //console.log(newArr)
    helper().exportCsv(newArr, true, params?.queryName)
  }

  const returnSpecificAccount = (type) => {
    let account = accounts.find(acc => acc.label.trim() == type.trim());
    return account?.value;
  }

  const sortByObject = (data) =>
    data.reduce((obj, item, index) => {
      return {
        ...obj,
        [item]: index,
      };
    }, {});

    const customSort = ({ data, sortBy, sortField }) => {
      const sortByObject = sortBy?.reduce((obj, item, index) => {
        return {
          ...obj,
          [item]: index,
        };
      }, {});
      return data?.sort(
        (a, b) => sortByObject[a[sortField]] - sortByObject[b[sortField]]
      );
    };

  const prepareData = (items) => {
    var objs = [];
    let model = params?.model

    items.forEach(item => {
      
      let objItem = { ...params?.model };

      headerKeys?.map(header => {
        Object.keys(model).forEach(function (key) {
          if (header === key) {
            objItem[key] = item[header]
          }
        })
      })
      let obj = {
        id: 0,
        entity:
          objItem
        ,
        status: 1,
        type: params?.queryName
      }
      objs.push(obj)
    })

    return objs;
  }


  const validate = (array) => {
    let errors = [];
    if (params?.queryName === 'items') {
      [...array].forEach(item => {
        !item.unitId ? errors['unit_' + item.no] = 'Zgjidhe' : '';
        items.find(i => i.barcode === item.barcode) ? errors['barcode_' + item.no] = 'Exist' : '';
        items.find(i => i.productNo === item.productNo) ? errors['productNo_' + item.no] = 'Exist' : '';
      })
    }

    if (params?.queryName === 'partners') {
      [...array].forEach(item => {
        !item.fiscalNo ? errors['fiscalNo_' + item.no] = 'Zgjidhe' : '';
        partners.find(i => i.fiscalNo === item.fiscalNo) ? errors['fiscalNo_' + item.no] = 'Exist' : '';
        //partners.find(i => i.productNo === item.productNo) ? errors['productNo_' + item.no] = 'Exist' : '';
      })
    }
    const isEmpty = !Object.values(errors).some(x => x !== null && x !== '');
    setErrors(errors)
    return isEmpty;
  }

  const save = (array) => {
    if (validate(array)) {
      let objs = prepareData(array?.filter(d => d.no !== ''));
      if (!params?.getBackData) {
        apiService()
          .saveChanges(objs)
          .then((response) => {
            if (response.success) {
              responseMessage(response.success ? 'success' : '', 'U ruajt me sukses!')
              if (params?.callBack) params.callBack()
            }
            else {
              console.log('Error:', response);
              responseMessage('error', response.errorMessage ? response.errorMessage : 'Ka ndodhur nje gabim!')
            }
          });
      }
      else {
        params?.getBackData(headerKeys, array);
      }
    }


  }

  const onRowClick = (no) => {
    let rowItem = [...array]
    rowItem.forEach(i => i.editMode = false);
    rowItem[no - 1].editMode = !rowItem[no - 1].editMode;
    setArray(rowItem);
  }

  const deleteRow = (rowId) => {
    let arrayCopy = [...array]
    let item = arrayCopy.find(i => i.no === rowId);
    //item.status = status.deleted
    //item.id !== 0 ?  item.status = status.deleted : arrayCopy = arrayCopy.filter((row) => row.no !== rowId);
    arrayCopy = arrayCopy.filter((row) => row.no !== rowId);
    let count = 1;
    arrayCopy.forEach(i => {
      if (i.status !== status.deleted) {
        i.no = count; count++
      }
    });
    //setPurchaseDetails([])
    const newArr = [arrayCopy]
    setArray(arrayCopy)
  }

  const handleOnSubmit = (e) => {
    e?.preventDefault();

    if (file) {
      setLoader(true)
      fileReader.onload = function (event) {
        const text = event.target.result;
        csvFileToArray(text);
      };

      fileReader.readAsText(file);
    }

  };

  const headerKeys = Object.keys(Object.assign({}, ...array));

  const responseMessage = (appearance, message) => {
    addToast(message, { appearance: appearance, autoDismiss: true, });
  }



  const showTab = (n) => {
    var x = document.getElementsByClassName("tab");
    x[n].style.display = "block";
    if (n == 0) {
      document.getElementById("prevBtn").style.display = "none";
    } else {
      document.getElementById("prevBtn").style.display = "inline";
    }
    if (n == (x.length - 1)) {
      document.getElementById("nextBtnText").innerHTML = !params?.getBackData ? helper().translate('SAVE') : helper().translate('UPLOAD');
      document.getElementById("nextBtnIcon").innerHTML = !params?.getBackData ? '<i class="fas fa-save" />' : '<i class="fas fa-list" />';
    } else {
      document.getElementById("nextBtnText").innerHTML = helper().translate('NEXT');
      document.getElementById("nextBtnIcon").innerHTML = '<i class="fas fa-arrow-right" />'
    }
    if (n == 2) {
      handleOnSubmit(this)
    }
    fixStepIndicator(n)
  }

  const nextPrev = (n) => {
    var x = document.getElementsByClassName("tab");
    if (n == 1 && currentTab == 1 && !validateForm(n)) return false;
    else {
      if (n > 0) {
        document.getElementsByClassName("step")[currentTab].className += " finish";
      }
    }
    let ct = currentTab + n;

    if (ct >= x.length) {
      //if(params?.queryName == 'itemsRegistration') {
      save(array);
      return false;
      //e return false;
    }
    else {
      x[currentTab].style.display = "none";
      setCurrentTab(ct);
    }
    showTab(ct);
  }

  const validateForm = (n) => {
    var x, y, i, valid = true;
    x = document.getElementsByClassName("tab");
    y = x[currentTab].getElementsByTagName("input");
    for (i = 0; i < y.length; i++) {
      if (y[i].value == "") {
        y[i].className += " invalid";
        valid = false;
      }
    }
    if (valid) {
      if (n > 0) {
        document.getElementsByClassName("step")[currentTab].className += " finish";
      }
    }
    return valid;
  }

  const fixStepIndicator = (n) => {
    var i, x = document.getElementsByClassName("step");
    for (i = 0; i < x.length; i++) {
      x[i].className = x[i].className.replace(" active", "");
      if (n < 0) {
        x[i].className = x[i].className.replace(" finish", "");
      }
    }
    x[n].className += " active";
  }

  const onFieldChange = (index, itemId) => (e, a) => {
    let newArr = [...array];
    newArr[index - 1]['status'] = status.changed;
    if (a) {
      let prop = a.name
      newArr[index - 1][prop] = e.value;
    }
    else {
      let prop = e.target.name;
      let value = e.target.value;
      newArr[index - 1][prop] = e.target.value;
    }
    setArray(newArr)
  }

  const downloadSchema = () => {
    if (params?.queryName === 'itemsRegistration') {
      getItems()
    }
    else if (params?.queryName === 'transactionDetails') {
      getPartners()
    }
    else {
      let obj = {}
      if (params?.queryName === 'items') {
        obj = {
          no: 1, itemName: 'Simple item', itemGoods: 1, unit: 'kg', barcode: '112233', productNo: '223344', vat: 18, retailPrice: 10, last: ''
        }
      }
      else if (params?.queryName === 'partners') {
        obj = {
          no: 1, companyName: 'Partner name', type: 1, fiscalNo: 112233, last: ''
        }
      }

      helper().exportCsv([obj], true, params?.queryName)
    }
  }

  useEffect(() => {
    getUnits();
    getVat();
    params?.queryName === 'items' ? getItems() : ''
    params?.queryName === 'partners' ? getPartners() : ''
    params?.queryName === 'transactionDetails' ? getProjects() : ''
    params?.queryName === 'transactionDetails' ? getAccounts() : ''
    showTab(currentTab);
  }, [])


  return (
    <div className="import-form pb-3" style={{ textAlign: "center", overflow: 'hidden', background: 'beige' }}>

      <div className="d-flex justify-content-center" style={{ textAlign: 'center', marginTop: '40px' }}>
        <ul className="d-flex">
          <li className="step">
            <div><p>1</p></div>
          </li>
          <li className="step">
            <div><p>2</p></div>
          </li>
          <li className="step">
            <div><p>3</p></div>
          </li>
        </ul>
      </div>

      <div className="tab p-2 p-md-5 p-lg-5 p-xl-5">
        <button type="button" className="btn btn-primary" onClick={() => downloadSchema()}>{helper().translate('DOWNLOAD_TEMPLATE')}</button>
      </div>

      <div className="tab p-2 p-md-5 p-lg-5 p-xl-5">
        <input
          type={"file"}
          id={"csvFileInput"}
          accept={".csv"}
          onChange={handleOnChange}
        />
      </div>

      <div className="tab p-2 p-md-5 p-lg-5 p-xl-5 position-relative">
        {/* <button className="btn btn-primary btn-icon-split btn-sm mt-2 mb-2 mr-2 import-btn"
          onClick={(e) => {
            handleOnSubmit(e);
          }}
        >
          <span className="icon text-white-40">
            <i className="fas fa-eye" />
          </span>
          <span className="text">{helper().translate('PREVIEW')}</span>
        </button> */}

        {/* </form> */}
        {/* <button className="btn btn-success btn-icon-split btn-sm mt-2 mb-2 mr-2  import-btn" onClick={() => save(array)}>
          <span className="icon text-white-40">
            <i className={`fas ${!params?.getBackData ? 'fa-save' : 'fa-list'}`} />
          </span>
          <span className="text">{!params?.getBackData ? helper().translate('SAVE') : helper().translate('UPLOAD')}</span>
        </button> */}
        {loader && <LoaderComponent />}
        <div className="card-body table-responsive position-relative">
          {!loader && <table className="table table-hover text-center bg-white ">
            <thead>
              {array.length > 0 && <tr key={"header"}>
                {headerKeys.map((key) => (
                  !hiddenFields.includes(key.replace(/\s/g, '')) ?
                    <th>{key}</th>
                    : ''
                ))}
                <th>{helper().translate('DELETE')}</th>
              </tr>}
            </thead>

            <tbody>
              {array.map((item) => (
                <tr key={item.no}>

                  {Object.entries(item).map((val) => (
                    !hiddenFields.includes(val[0].replace(/\s/g, '')) ? val[0] == 'unit' ?
                      <td>
                        <Select
                          name="unitId"
                          placeholder={helper().translate("SELECT")}
                          value={units ? units.find(v => v?.label.toLowerCase() == val[1]?.toLowerCase()) : ''}
                          onChange={onFieldChange(item.no)}
                          options={units}
                          styles={styles}
                          className={errors?.['unit_' + item.no] ? 'is-invalid-s' : ''}
                        />
                      </td> : val[0] == 'vat' ?
                        <td>
                          <Select
                            name={val[0]}
                            placeholder={helper().translate("SELECT")}
                            value={vatLevel ? vatLevel?.find(v => v?.label == val[1]) : ''}
                            onChange={onFieldChange(item.no)}
                            options={vatLevel}
                            styles={styles}
                            className={errors?.['vat_' + item.no] ? 'is-invalid-s' : ''}
                          />
                        </td> : val[0] == 'type' ?
                          <td>
                            <Select
                              name="type"
                              placeholder={helper().translate("SELECT")}
                              value={partnerTypes ? partnerTypes.find(v => v?.value == val[1]) : ''}
                              onChange={onFieldChange(item.no)}
                              options={partnerTypes}
                              styles={styles}
                              className={errors?.['partnerType_' + item.no] ? 'is-invalid-s' : ''}
                            />
                          </td> : val[0] == 'projectId' ?
                          <td>
                            <Select
                              name="projectId"
                              placeholder={helper().translate("SELECT")}
                              value={projects ? projects.find(v => v?.value == val[1]) : ''}
                              onChange={onFieldChange(item.no)}
                              options={projects}
                              styles={styles}
                              className={errors?.['project_' + item.no] ? 'is-invalid-s' : ''}
                            />
                          </td> : val[0] == 'accountNumber' ?
                          <td>
                            <Select
                              name="accountNumber"
                              placeholder={helper().translate("SELECT")}
                              value={accounts ? accounts.find(v => v?.value == val[1]) : ''}
                              onChange={onFieldChange(item.no)}
                              options={accounts}
                              styles={styles}
                              className={errors?.['account_' + item.no] ? 'is-invalid-s' : ''}
                            />
                          </td> :
                          !item.editMode ?
                            <td onClick={function () { onRowClick(item.no) }}>
                              {val[1]}<br></br>
                              {errors?.[val[0] + '_' + item.no] && <small className="text-red">Ky {params?.queryName === 'items' ? 'artikull' : 'partner'} ekziston</small>}
                            </td>
                            :
                            <td style={{ maxWidth: '120px' }}>
                              <input type="text" className={`form-control form-control-sm mx-auto ${errors?.[val[0] + '_' + item.no] ? 'is-invalid' : ''}`} style={{ width: '95%' }} name={val[0]} onChange={onFieldChange(item.no)} value={val[1]}></input>
                            </td>
                      : ''
                  ))}
                  <td><button onClick={() => deleteRow(item.no)} className="btn-delete"><span className="icon text-white-40"><i className="fas fa-minus-circle"></i></span></button></td>
                </tr>
              ))}
            </tbody>
          </table> }
        </div>
      </div>

      <div style={{ overflow: 'auto' }} className="px-2 px-md-5 px-lg-5 px-xl-5">
        <div style={{ float: 'right' }}>
          <button className="btn btn-success btn-icon-split btn-sm mt-2 mb-2 mr-2 import-btn" type="button" id="prevBtn" onClick={() => nextPrev(-1)}>
            <span className="icon text-white-40">
              <i class="fas fa-arrow-left" />
            </span>
            <span className="text">{helper().translate('PREVIOUS')}</span>
          </button>
          <button className="btn btn-success btn-icon-split btn-sm mt-2 mb-2 mr-2  import-btn" type="button" id="nextBtn" onClick={() => nextPrev(1)}>
            <span id="nextBtnText" className="text"></span>
            <span id="nextBtnIcon" className="icon text-white-40">
              <i />
            </span>
          </button>
        </div>
      </div>

    </div>
  );
});

export default ImportData