import React, { Component } from 'react';
import Pagination from '../Pagination/Pagination';
import globals from '../../../Services/globals';
import apiService from '../../../Services/apiServices';
import helper from '../../../Services/helper';
import UserPermissionContext from '../../../components/Context/UserPermissionContext';

export default class DataTable extends Component {

    static contextType = UserPermissionContext;
    constructor(props) {
        super(props);
        this.getHeader = this.getHeader.bind(this);
        this.renderHeader = this.renderHeader.bind(this);
        this.renderRow = this.renderRow.bind(this);
        this.renderRows = this.renderRows.bind(this);

        this.onChangePage = this.onChangePage.bind(this);

        this.state = {
            logedInUser: globals().getLogedInUser(),
            data: [],
            hs: [],
            showHiddeBox: false,
            userColumns: [],
            settings: [],
            userSettings: '',
            logedInUser: globals().getLogedInUser()
        }
    }

    componentDidMount = () => {
        if (this.props.formName) {
            this.getUserColumns()
        }
        else {
            this.setColumns()
        }
        this.getUserSettings()
        this.getSettings()
    }

    getUserColumns = () => {
        apiService().query('userColumns', `username=${this.state.logedInUser.data.username}&formname=${this.props.formName}`)
            .then(response => {
                if (response.success && response.data.length > 0) {
                    let results = response.data[0].hColumns.split(',');
                    //to lowercase

                    const lowercaseWords = results.map(word => word.toLowerCase());

                    this.setState({
                        userColumns: lowercaseWords
                    })
                    this.setColumns(lowercaseWords)
                }
                else {
                    this.setColumns()
                }
            })
    }

    getSettings = () => {
        globals().getSettings().then((response) => {
            this.setState({
                settings: response
            })
        });
    }
    getUserSettings = () => {
        globals().getUserSettings(this.state.logedInUser).then((response) => {
            this.setState({
                userSettings: response
            })
        });
    }

    savePrepare = (hs) => {
        if (this.props.formName) {
            let hc = []
            let headers = hs ? hs : this.state.hs
            headers?.forEach(h => {
                h.showHidde ? hc.push(h.key) : ''
            })
            let obj = { userName: this.state.logedInUser.data.username, formName: this.props.formName, hColumns: hc.toString() }
            this.saveUserColumns(obj, hc.to)
        }
    }

    saveUserColumns = (obj) => {
        if (!this.state.userColumns.length && !this.allAreTrue(this.state.hs)) {
            apiService().post('userColumns', obj)
                .then(response => {
                    this.getUserColumns()
                })
        }
        else if (this.state.userColumns.length && obj.hColumns !== this.state.userColumns.toString()) {
            apiService().put('userColumns', obj)
                .then(response => {
                    //console.log(response)
                })
        }
    }

    setColumns = (userColumns) => {
        var hdrs = []
        this.props.headers.forEach(h => {
            if (userColumns) {
                userColumns.includes(h?.key.toLowerCase()) ? h['showHidde'] = true : h['showHidde'] = false
            }
            else {
                h ? h['showHidde'] = true : 0;
            }
            hdrs.push(h)
        })

        this.setState({
            hs: hdrs
        })
    }

    getHeader = function () {
        // [{ key: 'id', columnName: 'Id' }, { key: 'name', columnName: helper().translate('NAME') }]
        if (this.props.headers) {
            return this.props.headers;
        }
        else {
            //nese nuk eshte definu props headers nxjerre prej datasetit
            var data = this.props.data || [];
            var firstRow = data[0];
            if (firstRow) {
                Object.keys(firstRow).map((key, index) => {
                    return { key: key, columnName: key }
                });
            }
        }
    }

    showHiddeBox = () => {
        this.setState({
            showHiddeBox: !this.state.showHiddeBox
        })
    }

    allAreTrue = (arr) => {
        return arr.every(element => element.showHidde === true);
    }

    removeColumn = (key) => {
        let arr = []
        this.state.hs.forEach(h => {
            if (h.key === key) h.showHidde = !h.showHidde
            arr.push(h)
        })
        this.renderHeader(arr)
        this.setState({ hs: arr })
        this.savePrepare(arr)
    }

    renderColumns = () => {
        var headers = this.state.hs ? this.state.hs : this.getHeader();
        return headers.map((header, index) => {
            if (header.dontShowMenu !== true) {
                return <div className='cursor-pointer' onClick={() => this.removeColumn(header.key)}>
                    <input className='me-2' type="checkbox" checked={header.showHidde ? 'checked' : ''} name={header.columnName}></input><label>{helper().translate(header.columnName)}</label></div>
            }
        });
    }

    /* <input id="refName" type="text" className="form-control" placeholder="Shkruaj emrin e references" value={referenceObj.name} onChange={(e) => setReferenceObj({...referenceObj, name:e.target.value})} name="name" /> */
    renderHeader = (h) => {
        var headers = h ? h : this.getHeader();
        return headers.map((header, index) => {
            if (header.showHidde && header.dontShowMenu !== true) {
                return <th key={index}>{helper().translate(header.key)}{<i className="fas fa-eye ms-1 mt-1 cursor-pointer" onClick={() => this.removeColumn(header.key)}></i>}</th>
            }
        });
    }

    renderRow = (row) => {
        var headers = this.state.hs ? this.state.hs : this.getHeader();
        var colspan = 0;
        return headers.map((header, index) => {
            if (header.showHidde) {
                if (!row['isCalculate']) {
                    if (header.type === 'number') {
                        return <td key={index} className="text-right pe-2" style={{ color: row[header.key + "_color"] ? row[header.key + "_color"] : '', fontWeight: 'bold' }}>{row[header.columnName] ? helper().formatNumber(parseFloat(row[header.columnName])) : row[header.key] === 0 ? 0 : helper().formatNumber(parseFloat(row[header.key]))}</td>
                    } else if (header.type === 'text') {
                        return <td key={index} className="text-left ps-3" style={{ color: row[header.key + "_color"] ? row[header.key + "_color"] : '', fontWeight: 'bold' }}>{row[header.columnName] ? row[header.columnName] : row[header.key]}</td>
                    } else if (header.type === 'comment') {
                        return <td
                            key={index}
                            className={`text-left ps-3 `}
                            // ${row[header.key] ? "tool" : "" }  removed from classname for pop up on comment hover
                            data-tip={row[header.key]}
                            style={{ color: row[header.key + "_color"] ? row[header.key + "_color"] : '', fontWeight: 'bold' }}>
                            {(row[header.key])}
                        </td>
                    } else if (header.dontShowDataTable === true) {
                        return ""
                    } else if (header.color === 'red') {
                        return <td key={index} className={'text-danger'} style={{ fontWeight: 'bold' }}>
                            {(row[header.key])?.substring(0, 22)}
                        </td>
                    } else {
                        return <td key={index} style={{ color: row[header.key + "_color"] ? row[header.key + "_color"] : '', fontWeight: 'bold' }}>{row[header.columnName] ? row[header.columnName] : row[header.key]}</td>
                    }
                }
                else {
                    if (row[header.key]) {
                        let td = <>{colspan ? <td colSpan={colspan} className='backgroundColor-lightdark text-black fw-bold text-right pe-3'>{row[header.key + "_label"]}</td> : ""}<td className='backgroundColor-lightdark text-black fw-bold' key={index}>{row[header.columnName] ? row[header.columnName] : row[header.key]}</td></>
                        colspan = 0;
                        return td;
                    }
                    else {
                        colspan++;
                    }
                }
            }
        });
    }

    renderRows = function (rows) {
        let datas = this.props.data;
        if (datas.length > 0) {
            return rows?.map((row, index) => {
                return <tr key={index} >
                    {this.props.edit && <td>
                        <button disabled={this.context.userPermission?.find((f) => f.moduleName === this.props.headersFrom)?.allowEdit === 0 ? true : ""} onClick={() => this.props.edit(row)} className="btn-edit mr-2">
                            <i className="fas fa-edit" />
                        </button>
                    </td>
                    }
                    {this.renderRow(row)}
                    {this.props.print &&
                        <td>
                            <button onClick={() => this.props.print(row, this.state.settings.invoiceForm)} className="btn-print mr-2">
                                <i className="fas fa-print" />
                            </button>
                        </td>
                    }
                    {this.props.delete && <td>
                        <button disabled={this.context.userPermission?.find((f) => f.moduleName === this.props.headersFrom)?.allowDelete === 0 ? true : ""} onClick={() => this.props.delete(row.id, row?.countNumber, row?.paymentMethod)} className={`btn-delete ${this.context.userPermission?.find((f) => f.moduleName === this.props.headersFrom)?.allowDelete === 0 ? "is-disabled" : ""}`} >
                            <i className="fas fa-trash" />
                        </button>
                    </td>
                    }
                </tr>
            })
        }
        else {
            return <tr><td colSpan={this.props.headers.length + 2}>Not found!</td></tr>
        }
    }

    onChangePage = (dataPage) => {
        this.setState({ data: dataPage });
    }

    render() {
        const { data, hs, showHiddeBox } = this.state

        const rows = data.length > 0 ? data : this.props.data;

        return (
            <div className="card-body table-responsive">
                {
                    showHiddeBox && <div className='datatable-columns'>
                        {this.renderColumns()}
                    </div>
                }

                <table id="mytable" className="table table-hover text-center">
                    <thead>
                        <tr>
                            {<th className="w-45" style={{ width: 50 }}><i className="fas fa-ellipsis-v mx-1 mt-1 cursor-pointer" onClick={() => this.showHiddeBox()}></i></th>}
                            {!this.props.noHead && this.renderHeader(hs)}
                            {this.props.print && <th className="w-45">{helper().translate("PRINT")}</th>}
                            {this.props.delete ? <th className="w-45">{helper().translate("DELETE")}</th> : <th></th>}
                        </tr>
                    </thead>
                    <tbody>
                        {this.renderRows(rows)}
                    </tbody>
                </table>
                <hr />
                {(this.props.data?.length > 0) && <Pagination items={this.props.data} hash={this.props.hash} onChangePage={this.onChangePage} />}
            </div>
        );
    }
}
