import React, { Component } from 'react';
import apiService from '../../Services/apiServices';
import './Warehouse.js';
import DropDownInput from '../../PageComponents/UI/Inputs/DropDownInput';
import '../Reports/reports.css';
import HeaderTools from '../../PageComponents/Header/HeaderTools';
import Pagination from '../../PageComponents/UI/Pagination/Pagination';
import Select from 'react-select';

class Warehouse extends Component {
  constructor(props) {
    super(props);

    this.state = {
        pageOfItems: [],
        items: [],
        warehouse: [],
        date: null,
        warehouseId: null,
        lackItems: 0,
    };

    this.search = this.search.bind(this);
    this.onChangePage = this.onChangePage.bind(this);

  }

  componentDidMount() {
    apiService().get('warehouses').then((response) => {
        if (response.success) {
          this.setState({ warehouse: response.data });
        } else {
          console.log('Error:' + response.errorMessage);
        }
      });


      apiService().execute('addSale',{id:0,date:'2021-01-01'},'post');
  }
  handleChange = (warehouseId) => {
    this.setState({ warehouseId});
  };
  onChangePage(pageOfItems) {
    this.setState({ pageOfItems: pageOfItems });
}
  onFieldChange = (e) => {
    this.setState({
      [e.target.name]:
        e.target.type === 'checkbox' ? e.target.checked : e.target.value,
    });
  };

  search = () => {
    var date = this.state.date;
    var warehouseId = this.state.warehouseId;
    var params = `DateIn=${date}&WarehouseId=${warehouseId.value}`;

    apiService()
      .execute('getItemsInStock', params)
      .then((response) => {
        if (response.success) {
          this.setState({ items: response.data });
        } else {
          console.log('Error:' + response.errorMessage);
        }
      });
  };


  render() {
    const { items,pageOfItems,warehouse } = this.state;
    const stateOptions = warehouse.map((item, index) => ({
        value: item.Id,
        label: item.Name
      }));

      const selectStyles = {
        control: (provided, state) => ({
          ...provided,
          background: '#fff',
          borderColor: '#9e9e9e',
          minHeight: '28.59px',
          height: '28.59px',
          boxShadow: state.isFocused ? null : null,
        }),
    
        valueContainer: (provided, state) => ({
          ...provided,
          height: '28.59px',
          padding: '0 6px'
        }),
    
        input: (provided, state) => ({
          ...provided,
          margin: '0px',
        }),
        indicatorSeparator: state => ({
          display: 'none',
        }),
        indicatorsContainer: (provided, state) => ({
          ...provided,
          height: '28.59px',
        }),
      };

    return (
      <div>
        
        <div className="container-fluid px-2 mt-3">
          <div className="card border-left-primary shadow h-100 py-2">
            <div className="card-body py-0">
              <div className="row no-gutters align-items-center">
                <div className="col mr-2 row">
                  <div className="col-lg-3 form-group">
                    <label className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                      Data
                    </label>
                    <input
                      name="date"
                      className="form-control form-control-sm"
                      onChange={this.onFieldChange}
                      type="date"
                    />
                  </div>
                  <div className="col-lg-3 form-group">
                  <label className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                      Depo
                    </label>
                  <Select styles={selectStyles} name="warehouseId" placeholder="Zgjedh" value={this.state.warehouseId} onChange={this.handleChange} options={stateOptions} />
                    {/* <DropDownInput
                      name="warehouseId"
                      changed={this.onFieldChange}
                      data={warehouse}
                      label="Depo"
                      selectText="Zgjidh Depon"
                    /> */}
                  </div>
                </div>
                <div className="col-auto">
                  <a className="searchbutton" onClick={this.search}>
                    <i className="fas fa-search fa-2x text-dark"  />
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="card shadow mt-1">
            <div className="card-header py-2">
              <h6 className="m-0 font-weight-bold text-primary">Stoku</h6>
            </div>
            <div className="card-body table-responsive">
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th scope="col">Nr</th>
                    <th scope="col">Emri</th>
                    <th scope="col">Barkodi</th>
                    <th scope="col">Njesia</th>
                    <th scope="col">Sasia</th>
                    <th scope="col">Cmimi Shitjes</th>
                    <th scope="col">Cmimi Mesatar</th>
                    <th scope="col">Totali</th>
                  </tr>
                </thead>
                {pageOfItems.map((item, key) => (
                  <tbody>
                    <tr key={item.Id}>
                      <td> {item.Nr}</td>
                      <td> {item.ItemName}</td>
                      <td> {item.Barcode} </td>
                      <td> {item.Unit}</td>
                      <td> {item.Quantity.toFixed(2)}</td>
                      <td> {item.SalePrice.toFixed(2)}</td>
                      <td> {item.AvgPrice.toFixed(2)}</td>
                      <td> {(item.AvgPrice * item.Quantity).toFixed(2)}</td>
                    </tr>
                  </tbody>
                ))}
              </table>
              <hr/>
              <Pagination items={items} onChangePage={this.onChangePage} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Warehouse;
