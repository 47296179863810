import React, { Component } from 'react'
import { ToastProvider, useToasts } from 'react-toast-notifications'
import Select from 'react-select'
import CreatableSelect from 'react-select/creatable';
import apiService from '../../Services/apiServices'
import mdlPartner from '../../Models/Partner'
import Switch from "react-switch";
import { MDBInput, MDBRadio } from 'mdb-react-ui-kit';
import helper from '../../Services/helper';
 
function withToast(Component) {
    return function WrappedComponent(props) {
        const toastFuncs = useToasts() 
        return <Component {...props} {...toastFuncs} />;
    }
}

class PartnerForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            partners: [],
            partnerTypes: [],
            countries: [],
            cities: [],
            branches:[],
            paymentMethods: [],
            partner: new mdlPartner(),
            partnerId: props.props.match ? props.props.match.params.id : 0,
            errors: new mdlPartner(),
            termsOfPayments: []
        }
        this.customStyles = {
            container: provided => ({
                ...provided,
                minWidth: '160px'
              }),
              control: (provided, state) => ({
                ...provided,
                background: '#fff',
                borderColor: '#9e9e9e',
                minHeight: '28.59px',
                height: '28.59px',
                boxShadow: state.isFocused ? null : null,
              }),
              menuPortal: base => ({ ...base, zIndex: 9999 }),
              valueContainer: (provided, state) => ({
                ...provided,
                height: '28.59px',
                padding: '0 6px'
              }),
          
              input: (provided, state) => ({
                ...provided,
                margin: '0px',
              }),
              indicatorSeparator: state => ({
                display: 'none',
              }),
              indicatorsContainer: (provided, state) => ({
                ...provided,
                height: '28.59px',
              }),
        };

        const customStyles = {
            control: (base, state) => ({
              ...base,
              boxShadow: "none"
              // You can also use state.isFocused to conditionally style based on the focus state
            })
          };

        this.importOrLocal = [{value: 0, label: 'Vendor'}, {value: 1, label: 'I jashtëm'}]

        this.responseMessage = this.responseMessage.bind(this);
        this.save = this.save.bind(this);
        this.newItem = this.newItem.bind(this);
    }

    getNonBranchPartners = () => {
        apiService().query('getNonBranchPartners')
            .then(response => {
                console.log(response)
                if(response.success){
                    var records = response.data.map(record => ({ value: record.id, label: record.companyName }));
                    this.setState({
                        ['partners']: records
                    });
                }
            })
    }

    getTermsOfPayments = () => {
        apiService().get('termsOfPayments')
            .then(response => {
                var records = response?.data?.map(record => ({ value: record.typeId, label: record.name + " - " + record.duration + " " + helper().translate("DAYS") }));
                this.setState({
                    ['termsOfPayments']: records
                });
            })
    }
    getBranches = () => {
        apiService()
          .get("branches")
          .then((response) => {
            if (response.success) {
              var records = response.data.map(record => ({ value: record.id, label: record.name }));
              this.setState({
                  ['branches']: records
              });
            } else {
              alert("Error: getting branches" + response.errorMessage);
            }
          });
      }

    componentDidMount() {
        const tables = ['partnerTypes', 'cities', 'countries', 'paymentMethods'];
        tables.forEach(table => {
            apiService()
                .get(table)
                    .then((response) => {
                        if (response.success) {
                            var records = response.data.map(record => ({ value: record.id, label: record.name }));
                            this.setState({
                                [table]: records
                            });
                        } else {
                            console.log('Error:' + response.errorMessage);
                        }
                    });
        })

        if(this.state.partnerId) {
            apiService()
                .query('partner', 'id='+this.state.partnerId)
                    .then((response) => {
                        console.log(response)
                        if (response.success) {
                            this.setState({
                                partner: new mdlPartner(response.data[0])
                            });
                        } else {
                            console.log('Error:' + response.errorMessage);
                        }
                    });    
        } 
        this.props.save(() => this.save(this.state.partnerId ? false : true));
        this.props.newHandle(() => this.newItem());
        this.getNonBranchPartners()
        this.getTermsOfPayments();
        this.getBranches();
    }

    onFieldChange = (e, action) => {
        let objItem = this.state.partner;
        if(action != undefined) {
            this.setState({
                partner: {
                    ...this.state.partner,
                    [action.name]: e.value,
                }
            });
        }
        else {
            if(e.target.type === 'checkbox' || e.target.type === 'radio'){
                if(e.target.name === 'customer'){
                    objItem[e.target.name] = e.target.checked;
                    objItem['supplier'] = false
                }
                else if(e.target.name === 'supplier'){
                    objItem[e.target.name] = e.target.checked;
                    objItem['customer'] = false
                }
                else if(e.target.name === 'customerSupplier') {
                    objItem['customer'] = e.target.checked
                    objItem['supplier'] = e.target.checked
                }
                else{
                    objItem[e.target.name] = e.target.checked;
                }
                this.setState(objItem);
            }
            else {
                if(e.target.name == 'companyName') {
                    this.state.partner.saveAs = e.target.value
                }
                this.setState({
                    partner: {
                        ...objItem,
                        [e.target.name]:
                        e.target.type === 'checkbox' ? e.target.checked : e.target.value,
                    }
                });
            }
        }
    };

    onSwitch = (value, name) => {
        this.setState({
            partner: {
                ...this.state.partner,
                'status': value,
            }
        });
    }

    newItem = () => {
        this.setState({
            partner: new mdlPartner(),
            partnerId: 0
        }); 
        window.location.href = `#/partner` 
    }

    validate() {
       var partner = this.state.partner;
       if(partner.companyName==='' || partner.saveAs ===''){
        return false;
       }
       else{
        return true;
       }
    }

    save = (st) => {   
        let that = this;
        let partner = this.state.partner;
        if(this.validate()){            
            if(partner.id !==0){
                apiService().put('partners',that.state.partner)
                .then((response)=>{that.responseMessage(response, st)});
            }
            else{
                apiService().post('partners',that.state.partner)
                .then((response)=>{that.responseMessage(response, st)});
            }
        }
        else{
            that.responseMessage('required', st);
        }
    }

    responseMessage(response, st){
        let that = this;
        if(response.success){
            that.props.addToast( st ? 'Saved Successfully' : 'Updated Successfully', { appearance: 'success', autoDismiss: true, });
            if(st) {
                this.setState({
                    partnerId: response.data.id
                });
                if(!this.props.props.noRedirect){
                    window.location.href = '#/edit_partner/'+response.data.id
                }else{
                    this.props.props.closeModal();
                }
            }
        }
        else{
            that.props.addToast('Ka ndodhur nje gabim!', { appearance: 'error', autoDismiss: true, });
        }
    }

    render() {
        const {errors, partner} = this.state;
        return (
            <div className="col-xl-12 col-lg-12 col-md-12 px-0 px-md-0 mt-1" >
                <div className="row justify-content-center">
                    <div className="col-xl-12 col-lg-12 col-md-12">
                        <div className="card o-hidden border-0 shadow-lg mb-3 border-custom">
                            <div className="card-body p-0">
                                <div className="row">
                                    {/* <div className="col-lg-6 d-none d-lg-block bg-light"></div> */}
                                    <div className="col-md-12 col-lg-12 col-xl-12">
                                    <div className="col-md-12 px-md-4 px-0 pt-1 pb-4 mx-auto">
                                        <form className="row user">
                                            <div className="col-md-12 col-lg-12 col-xl-12 mt-4">
                                                <div className="col-md-12 col-lg-12 col-xl-12">
                                                    <MDBRadio className="position-relative" name="supplier" label={helper().translate("SUPPLIER")} onChange={this.onFieldChange} checked={(this.state.partner.supplier && !this.state.partner.customer) ? 'checked': '' } id="inlineRadio1" inline />                                                  
                                                    <MDBRadio className="position-relative" name="customer" label={helper().translate("BUYER")} onChange={this.onFieldChange} checked={(!this.state.partner.supplier && this.state.partner.customer) ? 'checked': '' } id="inlineRadio2" inline />
                                                    <MDBRadio className="position-relative" name="customerSupplier" label={helper().translate("SUPPLIER") + " / " + helper().translate("BUYER")} onChange={this.onFieldChange} checked={(this.state.partner.supplier && this.state.partner.customer) ? 'checked': '' } id="inlineRadio3" inline />
                                                </div>
                                                {(errors.partnerType != '' && <span className='error'>Select one option</span>)}
                                            </div>
                                            <div className="col-md-12 col-lg-12 col-xl-12 d-md-flex d-lg-flex d-xl-flex">
                                                <div className="col-md-6 col-lg-6 col-xl-6 px-0">
                                                    <div className="col-md-12 col-lg-12 col-xl-12 mt-3 form-floating">
                                                        <MDBInput label={helper().translate("COMPANY_NAME")} type="text" name="companyName" value={this.state.partner.companyName} onChange={this.onFieldChange} className="form-control-sm text-black" id="inputCompanyName"/>
                                                        {errors.companyName != '' && <span className='error'>{errors.companyName}</span>}
                                                    </div>
                                                    <div className="col-md-12 col-lg-12 col-xl-12 mt-3">
                                                        <Select
                                                            onChange={this.onFieldChange}
                                                            options={this.state.partnerTypes}
                                                            required
                                                            placeholder={helper().translate("PARTNER_TYPE")}
                                                            name="partnerType"
                                                            value={this.state.partnerTypes.find(type => type.value == this.state.partner.partnerType)}
                                                            styles={this.customStyles}
                                                        />
                                                    </div>
                                                    <div className="col-md-12 col-lg-12 col-xl-12 mt-3">
                                                        <Select
                                                            onChange={this.onFieldChange}
                                                            options={this.state.branches}
                                                            placeholder={helper().translate("BRANCH")}
                                                            name="partnerBranchId"
                                                            value={this.state.branches.find(type => type.value == this.state.partner.partnerBranchId)}
                                                            styles={this.customStyles}
                                                        />
                                                    </div>
                                                    
                                                    <div className="col-md-12 col-lg-12 col-xl-12 mt-3 form-floating">
                                                        <MDBInput type="text" name="saveAs" value={this.state.partner.saveAs} onChange={this.onFieldChange} className="form-control-sm text-black" id="inputSaveAs" label={helper().translate("SAVE_AS")} />
                                                        {errors.saveAs != '' && <span className='error'>{errors.saveAs}</span>}
                                                    </div>
                                                    <div className="col-md-12 col-lg-12 col-xl-12 mt-3 form-floating">
                                                        <MDBInput type="text" name="fiscalNo" value={this.state.partner.fiscalNo} onChange={this.onFieldChange} className="form-control-sm text-black" id="inputNitNrf" label="NIT/NRF" />
                                                        {errors.fiscalNo != '' && <span className='error'>{errors.fiscalNo}</span>}
                                                    </div>
                                                    <div className="col-md-12 col-lg-12 col-xl-12 mt-3 form-floating">
                                                        <MDBInput type="text" name="businessNo" value={this.state.partner.businessNo} onChange={this.onFieldChange} className="form-control-sm text-black" id="inputNRB" label="NRB" />
                                                    </div>
                                                    
                                                    <div className="col-md-12 col-lg-12 col-xl-12 mt-3 form-floating">
                                                        <MDBInput type="text" name="vatNo" value={this.state.partner.vatNo} onChange={this.onFieldChange} className="form-control-sm text-black" id="inputTVSH" label={helper().translate("VAT_NUMBER")} 
                                                        />
                                                    </div>
                                                    {partner.partnerType === 6 && <>
                                                        <div className="col-md-12 col-lg-12 col-xl-12 mt-3">
                                                            <MDBInput type="text" name="unitNumber" value={this.state.partner.unitNumber != null ?  this.state.partner.unitNumber : ""} onChange={this.onFieldChange} className="form-control-sm text-black" id="inputunitNumber" label={helper().translate("UNIT_NUMBER")} />
                                                            {errors.unitNumber != '' && <span className='error'>{errors.unitNumber}</span>}
                                                        </div>
                                                        <div className="col-md-12 col-lg-12 col-xl-12 mt-3">
                                                            <Select
                                                                options={this.state.partners}
                                                                placeholder="Subjekti bazë"
                                                                name="parentId"
                                                                onChange={this.onFieldChange}
                                                                value={this.state.partners?.find(subject => subject.value === this.state.partner.parentId)}
                                                                styles={this.customStyles}
                                                            />
                                                            {errors.parentId != '' && <span className='error'>{errors.parentId}</span>}
                                                        </div>
                                                    </>}
                                                    <div className="col-md-12 col-lg-12 col-xl-12 mt-3">
                                                            <Select
                                                                options={this.state.termsOfPayments}
                                                                placeholder={helper().translate("TERMS_OF_PAYMENTS")} 
                                                                name="termsOfPaymentsId"
                                                                onChange={this.onFieldChange}
                                                                value={this.state.termsOfPayments?.find(subject => subject.value === this.state.partner.termsOfPaymentsId)}
                                                                styles={this.customStyles}
                                                            />
                                                            {errors.parentId != '' && <span className='error'>{errors.parentId}</span>}
                                                        </div>
                                                    <div className="col-md-12 col-lg-12 col-xl-12 mt-3 form-floating">
                                                        <div className="d-flex align-items-baseline ml-3 pt-3">
                                                            <Switch  
                                                                id="activInactiv"  
                                                                className="text-light"                       
                                                                onColor="#86d3ff"
                                                                onHandleColor="#2693e6"
                                                                handleDiameter={25}
                                                                width={85}
                                                                height={25}
                                                                uncheckedIcon={<span style={{display:'flex', margin: '-2px 0 0 -30px'}}>Joaktiv</span>}
                                                                checkedIcon={<span style={{display:'flex', margin: '-2px 0 0 -5px'}}>Aktiv</span>}
                                                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                                                onChange={this.onSwitch} 
                                                                checked={this.state.partner.status}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 col-lg-12 col-xl-12 mt-4">
                                                        <Select
                                                            options={this.importOrLocal}
                                                            placeholder="Vendor / I jashtëm"
                                                            name="importOrLocal"
                                                            onChange={this.onFieldChange}
                                                            value={this.importOrLocal.find(origin => origin.value == this.state.partner.importOrLocal)}
                                                            styles={this.customStyles}
                                                        />
                                                    </div>
                                                    <div className="col-md-12 col-lg-12 col-xl-12 mt-3 mb-5">
                                                        <Select
                                                            menuPlacement="top"
                                                            onChange={this.onFieldChange}
                                                            options={this.state.paymentMethods}
                                                            placeholder={helper().translate("PAYMENT_METHOD")} 
                                                            name="paymentTypeId"
                                                            value={this.state.paymentMethods.find(method => method.value == this.state.partner.paymentTypeId)}
                                                            styles={this.customStyles}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-lg-6 col-xl-6 px-0">
                                                    <div className="col-md-12 col-lg-12 col-xl-12 mt-3 form-floating">
                                                        <MDBInput type="tel" name="phone" value={this.state.partner.phone} onChange={this.onFieldChange} className="form-control-sm text-black" id="inputPhoneFix" label={helper().translate("FIX_PHONE")} />
                                                    </div>
                                                    <div className="col-md-12 col-lg-12 col-xl-12 mt-3 form-floating">
                                                        <MDBInput type="tel" name="mobilePhone" value={this.state.partner.mobilePhone} onChange={this.onFieldChange} className="form-control-sm text-black" id="inputPhoneMobile" label={helper().translate("MOBILE_PHONE")} />
                                                    </div>
                                                    <div className="col-md-12 col-lg-12 col-xl-12 mt-3 form-floating">
                                                        <MDBInput type="text" name="address" value={this.state.partner.address} onChange={this.onFieldChange} className="form-control-sm text-black" id="inputAddress" label={helper().translate("ADDRESS")} />
                                                    </div>
                                                    <div className="col-md-12 col-lg-12 col-xl-12 mt-3">
                                                        <Select
                                                            onChange={this.onFieldChange}
                                                            options={this.state.countries}
                                                            required
                                                            placeholder={helper().translate("STATE")}
                                                            name="country"
                                                            value={this.state.countries.find(country => country.value == this.state.partner.country)}
                                                            styles={this.customStyles}
                                                        />
                                                    </div>
                                                    <div className="col-md-12 col-lg-12 col-xl-12 mt-3">
                                                        <Select
                                                            onChange={this.onFieldChange}
                                                            options={this.state.cities}
                                                            required
                                                            placeholder={helper().translate("CITY")}
                                                            name="city"
                                                            value={this.state.cities.find(city => city.value == this.state.partner.city)}
                                                            styles={this.customStyles}
                                                        />
                                                    </div>
                                                    <div className="col-md-12 col-lg-12 col-xl-12 mt-3 form-floating">
                                                        <MDBInput name="comment" value={this.state.partner.comment} onChange={this.onFieldChange} className="form-control-sm text-black"  style={{height: 'auto', minHeight: '208px'}} id="inputComent" textarea rows={4} label={helper().translate("COMMENT")}/>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div className="col-md-12 col-lg-12 col-xl-12">
                                                <button type="button" onClick={() => this.save(this.state.partnerId ? false : true)} className="btn btn-primary float-right">{this.state.partnerId ? 'Update' : 'Save'}</button>
                                            </div> */}
                                        </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withToast(PartnerForm)
