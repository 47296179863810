import React, { useState, useEffect , useContext , useRef } from "react";
import Select from "react-select";
import { MDBInput } from "mdb-react-ui-kit";
import apiService from "../../Services/apiServices";
import { useToasts } from "react-toast-notifications";
import Search from "../../PageComponents/Search/Search_Old";
import { confirmAlert } from "react-confirm-alert";
import helper from "../../Services/helper";
import globals from "../../Services/globals";
import LoaderComponent from "../../PageComponents/UI/Components/LoaderComponent";
import UserPermissionContext from "../Context/UserPermissionContext";
import ExcelExport from "../../PageComponents/Excel/ExcelExport";

function CashPayments() {
    const [payment, setPayment] = useState([]);
    const [find, setFind] = useState([]);
    const [findByDate , setFindByDate] = useState({cashBox: null , dateStart: null, dateEnd:null , dateStartDisabled: true , dateEndDisabled: true });
    const [cashBoxes, setCashBoxes] = useState([]);
    const [dataShow, setDataShow] = useState(true);
    const [userColumns , setUserColumns] = useState([]);
    const [newColumns , setNewColumns] = useState([ "DOCUMENT_ID" ,"DATE" , "COMPANY" , "REFERENCE" , "DESCRIPTION", "ACCOUNT" ,"CASHBOX" , "PAYMENT_TYPE" , "DEBIT_IN" , "CREDIT_OUT" , "SALDO" ]);
    const [showColMenu , setShowColMenu] = useState(false);
    const [loader , setLoader] = useState(false);
    const { addToast } = useToasts();
    const excelExportRef = useRef();
    const {userPermission} = useContext(UserPermissionContext);

    const exportToExcel = () => {
      // let table = document.getElementById('table-to-xls-1').innerHTML;
      let fileName = helper().translate("CASHBOX_BOOK");
      // return helper().exportToExcel(table, fileName)
      excelExportRef.current.exportToExcel(fileName)
    }

    const headers = [
      { key: 'countNumber', columnName: helper().translate("NR") },
      { key: 'documentId', columnName: helper().translate("DOCUMENT_ID") },
      { key: 'date', columnName: helper().translate("DATE") },
      { key: 'company', columnName: helper().translate("COMPANY") , type:"text"  },
      { key: 'reference', columnName: helper().translate("REFERENCE") },
      { key: 'description', columnName: helper().translate("DESCRIPTION") },
      { key: 'accountName', columnName: helper().translate("ACCOUNT") , type:"text"  },
      { key: 'cashBoxName', columnName: helper().translate("CASHBOX") , type:"text" },
      { key: 'paymentName', columnName: helper().translate("PAYMENT_TYPE") , type:"text" },
      { key: 'debit', columnName: helper().translate("DEBIT_IN") , type:"number" },
      { key: 'credit', columnName: helper().translate("CREDIT_OUT") , type:"number" },
      { key: 'saldo', columnName: helper().translate("SALDO") , type:"number" }
    ];

      const getCashBoxes = () => {
        let options;
        apiService()
          .query("getCashBoxes")
          .then((response) => {
            if (response.success) {
              let data = response.data;
              let firstItem = {id: 0 , name: helper().translate("ALL_CASHBOXES")};
              data.unshift(firstItem);
              options = data.map((item, index) => ({
                value: item.id,
                label: item.name
              }));
              setCashBoxes(options);
            } else {
              alert("Error: getting CashBoxes" + response.errorMessage);
            }
          });
      };

      const getUserColumns = (user) => {
        apiService().query('userColumns', `username=${user}&formname=paymentCashBox`)
            .then(response => {
                if(response.success && response.data.length > 0) {
                    let results = response.data[0].hColumns.split(',')
                    setUserColumns( results);
                }
                else {
                setUserColumns(newColumns);
               let obj = {userName: user, formName: 'paymentCashBox', hColumns: newColumns.toString()};
               apiService().post('userColumns', obj)
               .then(response => {
                  //  console.log(response)
               })
                }
            })
    }

      const getPaymentsByMonth = () => {
        setLoader(true);
        let dateTimeNow = new Date();
        let dateTimeStart = new Date(dateTimeNow.getFullYear(),0,1,1); 
        let strStartDate = helper().formatDate(dateTimeStart,'MM-dd-yyyy');
        let strEndDate = helper().formatDate(dateTimeNow,'MM-dd-yyyy') + " 23:59:59"
        var params = `dateStart=${strStartDate}&dateEnd=${strEndDate}&cashBoxId=0`;
        apiService()
        .query("GetCashPayments", params)
        .then((response) => {
            if (response.success) {
              var count = 1;
              var data = response.data;
              data.forEach(e=> {e.countNumber = count; count++ , e.date = helper().formatDate(e.date)});
            setFind(data);
            setPayment(data);
            setLoader(false)
            setDataShow(response.data?.length > 0 ? true : false);
            }
        }
        )

        setFindByDate({...findByDate , dateStart: dateTimeStart , dateEnd: dateTimeNow});
      };


    useEffect(() => {
        // getPayments();
        setLoader(true)
        getPaymentsByMonth();
        getCashBoxes();
        let user = globals().getLogedInUser().data.username;
        getUserColumns(user);

    },[])

    const selectStyles = {
        control: (provided, state) => ({
          ...provided,
          background: "#fff",
          borderColor: "#9e9e9e",
          minHeight: "28.59px",
          height: "28.59px",
          boxShadow: state.isFocused ? null : null,
        }),
    
        valueContainer: (provided, state) => ({
          ...provided,
          height: "28.59px",
          padding: "0 6px",
        }),
    
        input: (provided, state) => ({
          ...provided,
          margin: "0px",
        }),
        indicatorSeparator: (state) => ({
          display: "none",
        }),
        indicatorsContainer: (provided, state) => ({
          ...provided,
          height: "28.59px",
        }),
      };
    
      const fieldChange = (e) => {
          if(e.target.name === "dateStart"){
              setFindByDate({...findByDate , dateStart: e.target.value + " 00:00:00" });
          }else
          if(e.target.name === "dateEnd"){
            setFindByDate({...findByDate , dateEnd: e.target.value + " 23:59:59" }); 
        }
      };
      const selectChange = (e,obj) => {
        if (obj.name === "cashBoxes"){
            setFindByDate({...findByDate, cashBox:e.value})
        }
      };

      const findChange = (e) => {
        if (e.target.value == "") {
          getPaymentsByMonth();
        }
        var searchPayment;
        let searchString = e.target.value.trim().toLowerCase();
    
        if (searchString.length > 0) {
          // We are searching. Filter the results.
          //searchItems = searchItems.filter((e) => e.ItemName.toLowerCase().match(searchString));
          searchPayment = payment.filter((item) => {
            return (
              item?.company?.toString().toLowerCase().match(searchString) ||
              item?.documentId?.toString().toLowerCase().match(searchString) ||
              item?.description?.toString().toLowerCase().match(searchString) ||
              item?.reference?.toString().toLowerCase().match(searchString) ||
              item?.accountName?.toString().toLowerCase().match(searchString) ||
              item?.paymentName?.toString().toLowerCase().match(searchString) 
            );
          });
          setFind(searchPayment);
        }
      };

      const alertnOnDelete = (id)=> {
        confirmAlert({
            customUI: ({ onClose }) => {
              return (
                <div className="custom-ui">
                  <h1>{helper().translate("ARE_YOU_SURE")}</h1>
                  <p>{helper().translate("WANT_TO_DELETE_THIS_PAYMENT")}</p>
                  <button
                    onClick={() => {
                      handleDelete(id);
                      onClose();
                    }}
                  >
                    {helper().translate("YES")} , {helper().translate("DELETE")}!
                  </button>
                  <button onClick={onClose}>{helper().translate("NO")}</button>
                </div>
              );
            },
          });
      }

      const filterRowMenu = () => {
        let menuColOpen =   showColMenu;
        if(menuColOpen){
          menuColOpen = false
        }else{
          
          menuColOpen = true
        }
        setShowColMenu(menuColOpen)
      }

      const filterRowOnEyeClick = (itemToRemove) => {
        let col = [...userColumns] ;
        let findCol = col.find(c => c == itemToRemove);
        if(findCol){
          var result = col.filter(c => c !== itemToRemove);
        }else{
          col.push(itemToRemove)
          var result = col;
        }
        let user = globals().getLogedInUser().data.username;
        if(user){
          let obj = {userName: user, formName: 'paymentCashBox', hColumns: result.toString()};
          apiService().put('userColumns', obj)
          .then(response => {
              if(response.success){
                setUserColumns(result);
              }
          })
        }
      
      }

      const checkRowIfVisible = (itemToCheck) => {
        filterRowOnEyeClick(itemToCheck);
        
      }

      const searchBtn = () => {
        setLoader(true);
        let startDate = findByDate.dateStart, endDate = findByDate.dateEnd, cashBoxId = findByDate.cashBox;
        var getDate = new Date();
        let strStartDate = helper().formatDate(startDate,'MM-dd-yyyy');
        let strEndDate = helper().formatDate(endDate,'MM-dd-yyyy') + " 23:59:59"

        if(!findByDate.dateStartDisabled) strStartDate = `01-01-${getDate.getFullYear()}`;
        if(!findByDate.dateEndDisabled) strEndDate = null;
        
        var params = `dateStart=${strStartDate}&dateEnd=${strEndDate}&cashBoxId=${cashBoxId}`;
          apiService().query('GetCashPayments', params).then((response) => {
              if(response.success){           
                var count = 1;
                var data = response.data;
                data.forEach(e=> {e.countNumber = count; count++ ,  e.date = helper().formatDate(e.date)});
                setFind(data);
                setPayment(data);
                setLoader(false)
                setDataShow(response.data?.length > 0 ? true : false);
              }else {
                console.log("error" , response.errorMessage)
              }
          })
    };
      
      const handleDelete = (id)=> {
        apiService()
        .query("deletePayment", "id=" + id)
        .then((response) => {
          if (response.success) {
            getPaymentsByMonth();
          } else {
            alert("Error:" + response.errorMessage);
          }
        });
      }
    
      return (
        <div className="container-fluid px-2 mt-3">
          <Search back="#/" new="#/paymentcash/0" change={(e) => findChange(e)} excel={() => exportToExcel()}  />
          <ExcelExport data={find.length ? find : []} headers={headers} type={'other'} ref={excelExportRef} hideContent={true} />
          <div className="card border-left-primary shadow h-100 mt-1">
            <div className="card-body pt-3 pb-1">
              <div className="row">
                <div className="col-12">
                  <div className="row no-gutters align-items-center">
                    <div className="col row">
                      <div className="col-lg-3 mb-3 mb-md-0 pe-0 d-flex align-items-center">
                        {/* <label className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                              Data e fillimit
                            </label> */}
                          <div style={{width: '5%'}}>
                            <input type="checkbox" name="includeStartDate" checked={findByDate.dateStartDisabled} onChange={(e) => setFindByDate({...findByDate, dateStartDisabled: e.target.checked})} />
                          </div>
                          <div style={{width: '95%'}}>
                            <MDBInput
                            name="dateStart"
                            disabled={!findByDate.dateStartDisabled}
                            className="form-control-sm active"
                            onChange={(e) =>  setFindByDate({...findByDate , dateStart: new Date(e.target.value)})}
                            type="date"
                            label={helper().translate("START_DATE")}
                            value={helper().isValidDate(findByDate.dateStart)  ?  findByDate.dateStart.toISOString().substring(0,10): ''}
                            
                            />
                          </div>
                      </div>
                      <div className="col-lg-3 mb-3 mb-md-0 pe-0 d-flex align-items-center">
                        {/* <label className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                              Data e Mbarimit
                            </label> */}
                        <div style={{width: '5%'}}>
                            <input type="checkbox" name="includeStartDate" checked={findByDate.dateEndDisabled}  onChange={(e) => setFindByDate({...findByDate, dateEndDisabled: e.target.checked})} />
                        </div>
                        <div style={{width: '95%'}}>
                          <MDBInput
                            name="dateEnd"
                            disabled={!findByDate.dateEndDisabled}
                            className="form-control-sm active"
                            onChange={(e) =>  setFindByDate({...findByDate , dateEnd: new Date(e.target.value)})}
                            type="date"
                            label={helper().translate("END_DATE")}
                            value={helper().isValidDate(findByDate.dateEnd)  ?  findByDate.dateEnd.toISOString().substring(0,10): ''}
                          />
                        </div>
                      </div>
                      <div style={{paddingTop:"2px"}} className="col-lg-3 mb-3 mb-md-0 pe-0">
                        {/* <label className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                          Depo
                          </label> */}
                        <Select
                          name="cashBoxes"
                          placeholder={helper().translate("COUNTER")}
                          styles={selectStyles}
                          options={cashBoxes}
                          onChange={(e, obj) => selectChange(e, obj)}
                          value={cashBoxes?.find(c => c.value == findByDate.cashBox)}
                        />
                      </div>
                      <div className="col-md-3 col-12 d-flex align-items-center justify-content-center  justify-content-lg-end">
                        <div>
                          <a className="searchbutton" onClick={() => searchBtn()}>
                            <i className="fas fa-search fa-2x text-dark"  />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card shadow mt-1">
        <div className="card-header py-2"><h6 className="m-0 font-weight-bold text-primary">{helper().translate("CASH_PAYMENTS")}</h6></div>
        {loader && <LoaderComponent /> } 
        {!loader ? dataShow ?  <div className="card-body table-responsive" style={{minHeight: "500px"}}>
        {showColMenu ?   <div style={{width: 'auto' , height: 'auto' }} className = "position-relative bg-primary " >
                <div style={{position: "absolute" , top: `${document.getElementById('paymentChashBoxTr').offsetHeight}px` , left: '20px' , zIndex: '222' }} className="bg-light border border-5 border-dark rounded-2  ">
                <table  className="table table-hover text-left my-1 ">
                  <tbody className="">
                    {newColumns.map((i, key) => (
                      <tr key={key} className="align-middle">
                        <td className="border-0 py-1 px-4 align-middle "><input onChange={() => checkRowIfVisible(i)} checked={userColumns.find(f => f == i) ? true : false}  type="checkbox" className=" bigCheckBox align-middle me-2"></input><span className="fw-bold">{helper().translate(i) }</span> </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                </div>
              </div> : ""}

            <table className="table table-hover text-center">
              <thead>
                <tr id="paymentChashBoxTr">
                <th className="align-middle ">{helper().translate("NR")} <i className="fas fa-ellipsis-v align-right mx-2  mt-1 cursor-pointer"  onClick={() => filterRowMenu()} ></i></th>
                  <th className="align-middle" >{helper().translate("EDIT")}</th>
                  { userColumns?.find( f => f == "DOCUMENT_ID") ? <th className="align-middle text-truncate">{helper().translate("DOCUMENT_ID")} <i className="fas fa-eye align-right ms-1  mt-1 cursor-pointer " onClick={() => filterRowOnEyeClick('DOCUMENT_ID')}></i></th> : "" }  
                  { userColumns?.find( f => f == "DATE") ? <th className="align-middle text-truncate">{helper().translate("DATE")} <i className="fas fa-eye align-right ms-1  mt-1 cursor-pointer " onClick={() => filterRowOnEyeClick('DATE')}></i></th> : "" }  
                  { userColumns?.find( f => f == "COMPANY") ? <th className="align-middle text-truncate">{helper().translate("COMPANY")} <i className="fas fa-eye align-right ms-1  mt-1 cursor-pointer " onClick={() => filterRowOnEyeClick('COMPANY')}></i></th> : "" } 

                  { userColumns?.find( f => f == "REFERENCE") ? <th className="align-middle text-truncate">{helper().translate("REFERENCE")} <i className="fas fa-eye align-right ms-1  mt-1 cursor-pointer " onClick={() => filterRowOnEyeClick('REFERENCE')}></i></th> : "" } 

                  { userColumns?.find( f => f == "DESCRIPTION") ? <th className="align-middle text-truncate">{helper().translate("DESCRIPTION")} <i className="fas fa-eye align-right ms-1  mt-1 cursor-pointer " onClick={() => filterRowOnEyeClick('DESCRIPTION')}></i></th> : "" } 

                  { userColumns?.find( f => f == "ACCOUNT") ? <th className="align-middle text-truncate">{helper().translate("ACCOUNT")} <i className="fas fa-eye align-right ms-1  mt-1 cursor-pointer " onClick={() => filterRowOnEyeClick('ACCOUNT')}></i></th> : "" } 

                  { userColumns?.find( f => f == "CASHBOX") ? <th className="align-middle text-truncate">{helper().translate("CASHBOX")} <i className="fas fa-eye align-right ms-1  mt-1 cursor-pointer " onClick={() => filterRowOnEyeClick('CASHBOX')}></i></th> : "" } 

                  { userColumns?.find( f => f == "PAYMENT_TYPE") ? <th className="align-middle text-truncate">{helper().translate("PAYMENT_TYPE")} <i className="fas fa-eye align-right ms-1  mt-1 cursor-pointer " onClick={() => filterRowOnEyeClick('PAYMENT_TYPE')}></i></th> : "" } 

                  { userColumns?.find( f => f == "DEBIT_IN") ? <th className="align-middle text-truncate">{helper().translate("DEBIT_IN")} <i className="fas fa-eye align-right ms-1  mt-1 cursor-pointer " onClick={() => filterRowOnEyeClick('DEBIT_IN')}></i></th> : "" } 

                  { userColumns?.find( f => f == "CREDIT_OUT") ? <th className="align-middle text-truncate">{helper().translate("CREDIT_OUT")} <i className="fas fa-eye align-right ms-1  mt-1 cursor-pointer " onClick={() => filterRowOnEyeClick('CREDIT_OUT')}></i></th> : "" } 

                  { userColumns?.find( f => f == "SALDO") ? <th className="align-middle text-truncate">{helper().translate("SALDO")} <i className="fas fa-eye align-right ms-1  mt-1 cursor-pointer " onClick={() => filterRowOnEyeClick('SALDO')}></i></th> : "" } 
                  <th className="buttons">{helper().translate("DELETE")}</th>
                </tr>
              </thead>
              <tbody>
                {/* {console.log(userPermission?.find((f)=> f.moduleName === "PAYMENTS")?.allowEdit)} */}
                {find.map((item, key) => (
                  <tr key={key}>
                    <td style={{width: "50px"}}>{item.countNumber}</td>
                    <td style={{width: "50px"}}>
                      <a  className={`${userPermission?.find((f)=> f.moduleName === "PAYMENTS")?.allowEdit === 1 ? "" : "pe-none"}`} href={"#/paymentcash/"+item.id}> 
                      <button
                      disabled={userPermission?.find((f)=> f.moduleName === "PAYMENTS")?.allowEdit === 1 ? false : true}
                        className={`btn-edit `}>
                    <span className="icon text-white-40"><i className="fas fa-edit"></i></span>
                    </button>
                      </a>
                    </td>
                    {userColumns?.find( f => f == "DOCUMENT_ID")? <td>  { item.documentId }</td> : "" } 
                    {userColumns?.find( f => f == "DATE")? <td>  { item.date }</td> : "" } 
                    {userColumns?.find( f => f == "COMPANY")? <td className="text-left ps-4 ">  { item.company }</td> : "" } 
                    {userColumns?.find( f => f == "REFERENCE")? <td >  { item.reference }</td> : "" } 
                    {userColumns?.find( f => f == "DESCRIPTION")? <td className={`${item.description.length > 0 ? "tool" : ""}`} data-tip={item.description} >  { item.description?.substring(0,15) }</td> : "" } 
                    {userColumns?.find( f => f == "ACCOUNT")? <td className="text-left ps-4 " >  { item.accountName }</td> : "" } 
                    {userColumns?.find( f => f == "CASHBOX")? <td className="text-left ps-4 " >  { item.cashBoxName }</td> : "" } 
                    {userColumns?.find( f => f == "PAYMENT_TYPE")? <td  >  { item.paymentName }</td> : "" } 
                    {userColumns?.find( f => f == "DEBIT_IN")? <td className="text-right pe-2 " >  {helper().formatNumber(item.debit) }</td> : "" } 
                    {userColumns?.find( f => f == "CREDIT_OUT")? <td  className="text-right pe-2 ">  { helper().formatNumber(item.credit) }</td> : "" } 
                    {userColumns?.find( f => f == "SALDO")? <td className="text-right pe-2 " >  { helper().formatNumber(item.saldo)}</td> : "" } 
                    <td>
                      <a className={`${userPermission?.find((f)=> f.moduleName === "PAYMENTS")?.allowDelete === 1 ? "" : "pe-none is-disabled"}`} onClick={() => alertnOnDelete(item.id)}>
                        <button 
                        className="btn-delete">
                          <span className="icon text-white-40">
                            <i className="fas fa-trash"></i>
                          </span>
                        </button>
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          :
          <div style={{height: '300px'}} className=" d-flex align-items-center justify-content-center  "><div><p>{helper().translate("NO_DATA")}</p></div></div> : ""}
        </div>
        </div>
      );
}
export default CashPayments;