import React, { useState, useEffect, useRef } from 'react'
import { Page, Text, View, Document, StyleSheet, PDFViewer, PDFDownloadLink } from '@react-pdf/renderer';
import apiService from '../../../Services/apiServices';
import ModelPurchases from '../../../Models/Purchases'
import calculate from '../../../Services/calculate';
import helper from '../../../Services/helper';
import globals from '../../../Services/globals';

const InvoicePurchase = (params) => {
    const [purchase, setPurchase] = useState(new ModelPurchases())
    const [purchaseTotals, setPurchaseTotals] = useState(new ModelPurchases())
    const [items, setItems] = useState()
    const [purchaseDetails, setPurchaseDetails] = useState()
    const [partner, setPartner] = useState()
    const [settings, setSettings] = useState()
    const [units, setUnits] = useState()
    const [purchaseTypes, setPurchaseTypes] = useState()
    const [isImport, setIsImport] = useState(false)
    const itemsByPurchaseType = {
        1: 'getNoexpenseItems', 2: 'getInventoryItems', 3: 'getExpenseItems'
    }

    const styles = StyleSheet.create({
        page: {
            display: 'flex',
            //flexDirection: 'row',
            alignItems: 'center',
            backgroundColor: '#fff',
            width: '100%',
            flexDirection: 'row'
        },
        section: {
            //paddingHorizontal: 5,
            flexGrow: 1,
            width: '50%',
            margin: 3,
            padding: 5
        },
        td: {
            marginLeft: 10,
            marginRight: 10
        },
        text: {
            // borderBottom: 1, 
            fontSize: 9,
            paddingTop: 2,
            paddingHorizontal: 2
        },
        text10: {
            fontSize: 8,
            paddingVertical: 2
        },
        pageNumbers: {
            position: 'absolute',
            bottom: 20,
            left: 0,
            right: 0,
            textAlign: 'right',
            fontSize: 8,
            borderTop: 1,
            // top: '15%'
            marginLeft: 5,
            marginRight: 5,
            marginBottom: -5
        },
        more35: {
            marginTop: 60
        },
        less35: {
            position: 'absolute',
            bottom: 30
        }
    });

    const getPurchase = (id) => {
        if (id) {
            apiService().query('getPurchase', `id=${id}`)
                .then((response) => {
                    if (response.success && response.data.length) {
                        let purchase = response.data[0]
                        purchase.import ? setIsImport(true) : 0
                        purchase.documentName = purchaseTypes?.find(p => p.id === purchase.purchaseTypeId)
                        getItems(itemsByPurchaseType[purchase.purchaseTypeId], purchase);
                        setPurchase(purchase)
                        getPartner(purchase.partnerId)
                    }
                })
        }
        else {
            alert('Not finded')
        }
    }

    const getItems = (name, purchase) => {
        apiService().get(name ? name : 'items')
            .then((response) => {
                if (response.success) {
                    setItems(response.data)
                    getPurchaseDetails(purchase, response.data)
                }
            })
    }

    // const getPurchaseDetails = (purchase, items) => {
    //     apiService().query('getPurchaseDetails', `purchaseId=${purchase.id}`)
    //         .then((response) => {
    //             if(response.success) {
    //                 let records = response.data
    //                 records.sort(function (a, b) {
    //                     return a.no - b.no;
    //                   });
    //                 records.forEach(record => {
    //                     let item = items?.find(i => i.id === record.itemId) 
    //                     record['unitId'] = item?.unitId
    //                     record['productNo'] = item?.productNo
    //                     calculate().calculatePrice(record, record.price);
    //                     record['itemName'] = item?.itemName;
    //                     record['discountValue'] = record.price * record.discount / 100
    //                     record['subTotal'] = parseFloat(record['quantity']) * parseFloat(record['price_discount'])
    //                     record['totalWithoutDiscount'] = parseFloat(record['quantity']) * parseFloat(record['purchasePrice'])
    //                     record['total'] = parseFloat(record['subTotal']) + parseFloat(record['vatSum'])
    //                     if(purchase.import) {
    //                         record['transportPercentage'] = parseFloat(record['transport']) * 100 / parseFloat(record['subTotal']);
    //                         record['dutyPercentage'] = parseFloat(record['duty']) * 100 / (parseFloat(record['subTotal']) + parseFloat(record['transport']) + parseFloat(record['overvalue']));
    //                         calculate().importCalculate(record)
    //                     }

    //                 })
    //                 calculateTotals(records, purchase)
    //                 setPurchaseDetails(records)
    //             }
    //         })
    // }

    const getPurchaseDetails = (purchase, items) => {
        apiService().query('getPurchaseDetails', `purchaseId=${purchase.id}`)
            .then((response) => {
                if (response.success && response.data && response.data.length) {
                    let records = response.data
                    records.sort(function (a, b) {
                        return a.no - b.no;
                    });
                    records.forEach(record => {
                        let item = items?.find(i => i.id === record.itemId)
                        record['itemName'] = item?.itemName;
                        record['productNo'] = item?.productNo;
                        record['unitId'] = item?.unitId;
                        calculate().calculateImportPercents(record);
                    });

                    calculateTotals(records, purchase)
                    setPurchaseDetails(records)
                }
            })
    }

    const getPartner = (partnerId) => {
        apiService().query('partner', 'id=' + partnerId)
            .then((response) => {
                if (response.success) {
                    setPartner(response.data[0])
                } else {
                    console.log('Error:' + response.errorMessage);
                }
            });
    }

    const getSettings = async () => {
        globals().getSettings().then((response) => {
            setSettings(response)
        });
    }

    const getUnits = () => {
        apiService().get('units')
            .then((response) => {
                if (response.success) {
                    setUnits(response.data);
                } else {
                    console.log('Error:' + response.errorMessage);
                }
            });
    }

    const getPurchaseTypes = () => {
        apiService().get('purchaseType')
            .then((response) => {
                if (response.success) {
                    setPurchaseTypes(response.data);
                } else {
                    console.log('Error:' + response.errorMessage);
                }
            });
    }

    const translate = (key) => {
        return helper().translate(key);
    }

    const setUnit = (unitId) => {
        return units?.find(u => u.id === unitId)?.name
    }

    const columnTotal = (property) => {
        return purchaseDetails?.reduce((accumulator, current) => parseFloat(accumulator) + parseFloat(current[property]), 0)
    }

    // const calculateTotals = (details, _purchase) => {
    //     let purchaseObj = _purchase ? _purchase : purchase
    //     let _details = details.filter((row) => row.status !== -1);

    //     purchaseObj.vatSum = _details.reduce((accumulator, current) => parseFloat(accumulator) + current.vatSum, 0)
    //     purchaseObj.subTotal = _details.reduce((accumulator, current) => parseFloat(accumulator) + current.subTotal, 0)
    //     purchaseObj.totalSum = _details.reduce((accumulator, current) => parseFloat(accumulator) + current.total, 0)
    //     purchaseObj.totalWithOvervalue = _details.reduce((accumulator, current) => parseFloat(accumulator) + current.totalWithOvervalue, 0)
    //     purchaseObj.transport = _details.reduce((accumulator, current) => parseFloat(accumulator) + parseFloat(current.transport), 0)
    //     purchaseObj.overvalue = _details.reduce((accumulator, current) => parseFloat(accumulator) + parseFloat(current.overvalue), 0)
    //     purchaseObj.duty = _details.reduce((accumulator, current) => parseFloat(accumulator) + parseFloat(current.duty), 0)
    //     purchaseObj.excise = _details.reduce((accumulator, current) => parseFloat(accumulator) + parseFloat(current.excise), 0)
    //     purchaseObj.amount = purchaseObj.totalSum
    //     setPurchase(purchaseObj)
    // }

    const calculateTotals = (details, _purchase) => {
        //return;
        let purchaseObj = _purchase ? { ..._purchase } : { ...purchase }
        let _details = details.filter((row) => row.status !== status.deleted);
        let vatSum = 0.0, subTotal = 0.0, totalWithTransportAndOvervalueAndDutyExcise = 0.0;
        let duty = 0.0, transport = 0.0;
        for (var i = 0; i < _details.length; i++) {
            vatSum += _details[i].vatSum;
            subTotal += _details[i].subTotal;
            duty += _details[i].duty;
            transport += _details[i].transport;
            totalWithTransportAndOvervalueAndDutyExcise += _details[i].totalWithTransportAndOvervalueAndDutyExcise;
        }

        //test

        purchaseObj.transport = transport;
        purchaseObj.duty = duty;
        purchaseObj.vatSum = vatSum;
        purchaseObj.subTotal = subTotal;
        purchaseObj.totalWithTransportAndOvervalueAndDutyExcise = totalWithTransportAndOvervalueAndDutyExcise;
        purchaseObj.totalWithTransportAndOvervalueAndDutyExcise = totalWithTransportAndOvervalueAndDutyExcise;

        //setPurchaseTotals(purchaseObj);
        setPurchase(purchaseObj);
    }

    const checkColumn = (property, items) => {
        // const isTrue = (currentValue) => currentValue[property] == 0;
        // return !items?.every(isTrue)
        return true
    }

    useEffect(() => {
        getPurchaseTypes()
        getPurchase(params.match.params.id)
        getSettings()
        getUnits()

    }, [])

    return (

        <div className='pdf my-4'>
            {

            }
            <PDFViewer>
                <Document style={{ justifyContent: 'center' }}>
                    <Page orientation='landscape' size="A4" style={{ paddingVertical: 10, paddingHorizontal: 15, marginTop: 5 }} wrap>
                        <View style={styles.page}><Text style={{ marginHorizontal: 'auto', textDecoration: 'underline' }}>{translate(purchaseTypes?.find(pt => pt.id === purchase.purchaseTypeId)?.name)}</Text></View>
                        <View style={styles.page}>
                            <View style={[styles.section, { border: '1px' }]}>
                                <Text style={{ fontSize: 12 }}>Të dhënat e kompanisë</Text>
                                <Text style={styles.text}>NRB: {settings?.businessNumber}, NRF: {settings?.fiscalNumber}, NRTVSH: {settings?.vatNumber}</Text>
                                <Text style={styles.text}>Adresa: {settings?.address}, {settings?.city}, {settings?.country}</Text>
                                <Text style={styles.text}>Tel: {settings?.phoneNo}, Fax: {settings?.faxNo}</Text>
                                <Text style={styles.text}>Email: {settings?.email}</Text>
                            </View>
                            <View style={styles.section}>
                                <Text style={styles.text}>Dokumenti: {translate(purchaseTypes?.find(pt => pt.id === purchase.purchaseTypeId)?.name)}</Text>
                                <Text style={styles.text}>Nr. Dokumentit: {purchase.calculationId}</Text>
                                <Text style={styles.text}>{translate('DATE')}: {helper().formatDate(purchase.date)}</Text>
                            </View>
                            <View style={styles.section}>
                                <Text style={styles.text}>{translate('INVOICE_NO')}: {purchase.invoiceNo} </Text>
                                {isImport && <Text style={styles.text}>{translate('DUD_NO')}: {purchase.dudNo} </Text>}
                            </View>
                            <View style={[styles.section, { border: '1px' }]}>
                                <Text style={[styles.text, { fontWeight: 'extrabold' }]}>Subjekti: {partner?.partnerType !== 6 ? partner?.saveAs : partner?.subjectName}</Text>
                                {partner?.partnerType === 6 && <Text style={[styles.text, { fontWeight: 'extrabold' }]}>Njësia: {partner?.saveAs}</Text>}
                                <Text style={styles.text}>NRB: {partner?.businessNo}, NRF: {partner?.fiscalNo}, NRTVSH: {partner?.vatNo}</Text>
                                <Text style={styles.text}>{translate('ADDRESS')}: {partner?.address}, {partner?.city}, {partner?.country}</Text>
                                <Text style={styles.text}>Tel: {partner?.phone}</Text>
                                <Text style={styles.text}>{translate('INVOICE_NO')}: </Text>
                            </View>
                        </View>
                        <View style={{ border: 1, marginVertical: 0, marginTop: 5 }}>
                            <View style={[styles.page, { paddingHorizontal: 0, minHeight: '12px', backgroundColor: '#e3e6f0' }]}>
                                <Text style={[styles.text10, { width: '2%', textAlign: 'center', borderRight: 0.5 }]}>{translate('NU')}</Text>
                                <Text style={[styles.text10, { width: '7%', textAlign: 'center', borderRight: 0.5 }]}>Shifra</Text>
                                <Text style={[styles.text10, { width: '20%', textAlign: 'center', borderRight: 0.5 }]}>Përshkrimi</Text>
                                <Text style={[styles.text10, { width: '5%', textAlign: 'center', borderRight: 0.5 }]}>{translate('UNIT')}</Text>
                                <Text style={[styles.text10, { width: '5%', textAlign: 'center', borderRight: 0.5 }]}>{translate('QUANTITY')}</Text>
                                <Text style={[styles.text10, { width: '7%', textAlign: 'center', borderRight: 0.5 }]}>Çm. Furn.</Text>
                                {checkColumn('totalWithoutDiscount', purchaseDetails) && !isImport &&
                                    <Text style={[styles.text10, { width: '7%', textAlign: 'center', borderRight: 0.5 }]}>Tot. pa Zb.</Text>
                                }
                                {checkColumn('discount', purchaseDetails) && !isImport &&
                                    <Text style={[styles.text10, { width: '7%', textAlign: 'center', borderRight: 0.5 }]}>Zbritja (%)</Text>
                                }
                                {checkColumn('discountValue', purchaseDetails) && !isImport &&
                                    <Text style={[styles.text10, { width: '7%', textAlign: 'center', borderRight: 0.5 }]}>Zbritja (€)</Text>
                                }
                                {checkColumn('subTotal', purchaseDetails) &&
                                    <Text style={[styles.text10, { width: '10%', textAlign: 'center', borderRight: 0.5 }]}>Vlera pa tvsh</Text>
                                }
                                {checkColumn('transport', purchaseDetails) && isImport &&
                                    <Text style={[styles.text10, { width: '7%', textAlign: 'center', borderRight: 0.5 }]}>Transport</Text>
                                }
                                {checkColumn('overvalue', purchaseDetails) && isImport &&
                                    <Text style={[styles.text10, { width: '7%', textAlign: 'center', borderRight: 0.5 }]}>Sh. Doganore</Text>
                                }
                                {checkColumn('duty', purchaseDetails) && isImport &&
                                    <Text style={[styles.text10, { width: '7%', textAlign: 'center', borderRight: 0.5 }]}>Dogana</Text>
                                }
                                {checkColumn('excise', purchaseDetails) && isImport &&
                                    <Text style={[styles.text10, { width: '7%', textAlign: 'center', borderRight: 0.5 }]}>Akciza</Text>
                                }
                                {checkColumn('vat', purchaseDetails) &&
                                    <Text style={[styles.text10, { width: '5%', textAlign: 'center', borderRight: 0.5 }]}>Tvsh (%)</Text>
                                }
                                {checkColumn('vatSum', purchaseDetails) &&
                                    <Text style={[styles.text10, { width: '9%', textAlign: 'center', borderRight: 0.5 }]}>Tvsh (€)</Text>
                                }
                                {checkColumn('total', purchaseDetails) &&
                                    <Text style={[styles.text10, { width: '11%', textAlign: 'center' }]}>Vlera me tvsh</Text>
                                }

                            </View>
                            {purchaseDetails ? purchaseDetails?.map((item, index) => (
                                <View key={index} style={[styles.page, { borderTop: 1, paddingHorizontal: 0, overflow: 'hidden', minHeight: '12px', height: 'auto' }]}>
                                    <Text style={[styles.text10, { width: '2%', textAlign: 'center', borderRight: 0.5 }]}>{item.no}</Text>
                                    <Text style={[styles.text10, { width: '7%', textAlign: 'center', borderRight: 0.5 }]}>{item.productNo}</Text>
                                    <Text style={[styles.text10, { width: '20%', textAlign: 'center', borderRight: 0.5 }]}>{item.itemName}</Text>
                                    <Text style={[styles.text10, { width: '5%', textAlign: 'center', borderRight: 0.5 }]}>{setUnit(item.unitId)}</Text>
                                    <Text style={[styles.text10, { width: '5%', textAlign: 'center', borderRight: 0.5 }]}>{item.quantity}</Text>
                                    <Text style={[styles.text10, { width: '7%', textAlign: 'center', borderRight: 0.5 }]}>{helper().formatNumber(parseFloat(item.price),true) }</Text>
                                    {checkColumn('totalWithoutDiscount', purchaseDetails) && !isImport &&
                                        <Text style={[styles.text10, { width: '7%', textAlign: 'center', borderRight: 0.5 }]}>{helper().formatNumber(parseFloat(item.totalWithoutDiscount),true)}</Text>
                                    }
                                    {checkColumn('discount', purchaseDetails) && !isImport &&
                                        <Text style={[styles.text10, { width: '7%', textAlign: 'center', borderRight: 0.5 }]}>{item.discount}</Text>
                                    }
                                    {checkColumn('discountValue', purchaseDetails) && !isImport &&
                                        <Text style={[styles.text10, { width: '7%', textAlign: 'center', borderRight: 0.5 }]}>{helper().formatNumber(parseFloat(item.discountValue),true) }</Text>
                                    }
                                    {checkColumn('subTotal', purchaseDetails) &&
                                        <Text style={[styles.text10, { width: '10%', textAlign: 'center', borderRight: 0.5 }]}>{helper().formatNumber(parseFloat(item.subTotal),true) }</Text>
                                    }
                                    {checkColumn('transport', purchaseDetails) && isImport &&
                                        <Text style={[styles.text10, { width: '7%', textAlign: 'center', borderRight: 0.5 }]}>{helper().formatNumber(parseFloat(item.transport),true) }</Text>
                                    }
                                    {checkColumn('overvalue', purchaseDetails) && isImport &&
                                        <Text style={[styles.text10, { width: '7%', textAlign: 'center', borderRight: 0.5 }]}>{helper().formatNumber(parseFloat(item.overvalue),true)}</Text>
                                    }
                                    {checkColumn('duty', purchaseDetails) && isImport &&
                                        <Text style={[styles.text10, { width: '7%', textAlign: 'center', borderRight: 0.5 }]}>{helper().formatNumber(parseFloat(item.duty),true) }</Text>
                                    }
                                    {checkColumn('excise', purchaseDetails) && isImport &&
                                        <Text style={[styles.text10, { width: '7%', textAlign: 'center', borderRight: 0.5 }]}>{helper().formatNumber(parseFloat(item.excise),true) }</Text>
                                    }
                                    {checkColumn('vat', purchaseDetails) &&
                                        <Text style={[styles.text10, { width: '5%', textAlign: 'center', borderRight: 0.5 }]}>{item.vat}</Text>
                                    }
                                    {checkColumn('vatSum', purchaseDetails) &&
                                        <Text style={[styles.text10, { width: '9%', textAlign: 'center', borderRight: 0.5 }]}>{helper().formatNumber(parseFloat(item.vatSum),true)}</Text>
                                    }
                                    {checkColumn('total', purchaseDetails) &&
                                        <Text style={[styles.text10, { width: '11%', textAlign: 'center' }]}>{helper().formatNumber(parseFloat(item.totalWithTransportAndOvervalueAndDutyExcise + item.vatSum),true)}</Text>
                                    }

                                </View>
                            )) :
                                <View style={[styles.page, { borderTop: 1, paddingHorizontal: 0, overflow: 'hidden', minHeight: '12px', height: 'auto' }]}>
                                    <Text style={[styles.text10, { width: '100%', textAlign: 'center' }]}>Ska te dhena!</Text>
                                </View>
                            }
                        </View>
                        {purchaseDetails && <View style={{ marginVertical: 0, marginTop: 1, display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', marginTop: 10 }}>
                            <View style={{ width: '50%' }}>

                            </View>
                            <View style={[styles.page, { width: '50%', paddingHorizontal: 0, minHeight: '25px', position: 'relative', float: 'right', border: 1, borderColor: '#000' }]}>
                                <View style={{ textAlign: 'center', flex: '1 1 0px', borderRight: 1 }}>
                                    <Text style={[styles.text10, { textAlign: 'center', backgroundColor: '#e3e6f0', minHeight: '12px' }]}></Text>
                                    <Text style={[styles.text10, { fontSize: '12px', textAlign: 'center', backgroundColor: '#e3e6f0', minHeight: '12px' }]}>Totalet</Text>
                                </View>
                                {columnTotal('totalWithoutDiscount') > 0 && !isImport &&
                                    <View style={{ textAlign: 'center', flex: '1 1 0px', borderRight: 1 }}>
                                        <Text style={[styles.text10, { textAlign: 'center', backgroundColor: '#e3e6f0', minHeight: '12px' }]}>Totali pa zbritje</Text>
                                        <Text style={[styles.text10, { fontSize: '12px', borderTop: 0.5, textAlign: 'center', backgroundColor: '#e3e6f0', minHeight: '12px' }]}>{
                                        helper().formatNumber(parseFloat(columnTotal('totalWithoutDiscount')),true)}</Text>
                                    </View>
                                }
                                {columnTotal('discountValue') > 0 && !isImport &&
                                    <View style={{ textAlign: 'center', flex: '1 1 0px', borderRight: 1 }}>
                                        <Text style={[styles.text10, { textAlign: 'center', backgroundColor: '#e3e6f0', minHeight: '12px' }]}>Zbritja (€)</Text>
                                        <Text style={[styles.text10, { fontSize: '12px', borderTop: 0.5, textAlign: 'center', backgroundColor: '#e3e6f0', minHeight: '12px' }]}>{
                                        helper().formatNumber(parseFloat(columnTotal('discountValue')),true)
                                        }</Text>
                                    </View>
                                }
                                {columnTotal('transport') > 0 && isImport &&
                                    <View style={{ textAlign: 'center', flex: '1 1 0px', borderRight: 1 }}>
                                        <Text style={[styles.text10, { textAlign: 'center', backgroundColor: '#e3e6f0', minHeight: '12px' }]}>Transporti</Text>
                                        <Text style={[styles.text10, { fontSize: '12px', borderTop: 0.5, textAlign: 'center', backgroundColor: '#e3e6f0', minHeight: '12px' }]}>{
                                        helper().formatNumber(parseFloat(columnTotal('transport')),true)
                                        
                                        }</Text>
                                    </View>
                                }
                                {columnTotal('overvalue') > 0 && isImport &&
                                    <View style={{ textAlign: 'center', flex: '1 1 0px', borderRight: 1 }}>
                                        <Text style={[styles.text10, { textAlign: 'center', backgroundColor: '#e3e6f0', minHeight: '12px' }]}>Sh. Doganore</Text>
                                        <Text style={[styles.text10, { fontSize: '12px', borderTop: 0.5, textAlign: 'center', backgroundColor: '#e3e6f0', minHeight: '12px' }]}>{
                                        helper().formatNumber(parseFloat(columnTotal('overvalue')),true)
                                        }</Text>
                                    </View>
                                }
                                {columnTotal('duty') > 0 && isImport &&
                                    <View style={{ textAlign: 'center', flex: '1 1 0px', borderRight: 1 }}>
                                        <Text style={[styles.text10, { textAlign: 'center', backgroundColor: '#e3e6f0', minHeight: '12px' }]}>Dogana</Text>
                                        <Text style={[styles.text10, { fontSize: '12px', borderTop: 0.5, textAlign: 'center', backgroundColor: '#e3e6f0', minHeight: '12px' }]}>{
                                        helper().formatNumber(parseFloat(columnTotal('duty')),true)
                                        }</Text>
                                    </View>
                                }
                                {columnTotal('excise') > 0 && isImport &&
                                    <View style={{ textAlign: 'center', flex: '1 1 0px', borderRight: 1 }}>
                                        <Text style={[styles.text10, { textAlign: 'center', backgroundColor: '#e3e6f0', minHeight: '12px' }]}>Akciza</Text>
                                        <Text style={[styles.text10, { fontSize: '12px', borderTop: 0.5, textAlign: 'center', backgroundColor: '#e3e6f0', minHeight: '12px' }]}>{
                                        helper().formatNumber(parseFloat(columnTotal('excise')),true)
                                        }</Text>
                                    </View>
                                }
                                {columnTotal('subTotal') > 0 &&
                                    <View style={{ textAlign: 'center', flex: '1 1 0px', borderRight: 1 }}>
                                        <Text style={[styles.text10, { textAlign: 'center', backgroundColor: '#e3e6f0', minHeight: '12px' }]}>Vlera pa tvsh</Text>
                                        <Text style={[styles.text10, { fontSize: '12px', borderTop: 0.5, textAlign: 'center', backgroundColor: '#e3e6f0', minHeight: '12px' }]}>{
                                        helper().formatNumber(parseFloat(columnTotal('subTotal')))
                                        }</Text>
                                    </View>
                                }
                                {columnTotal('vatSum') > 0 &&
                                    <View style={{ textAlign: 'center', flex: '1 1 0px', borderRight: 1 }}>
                                        <Text style={[styles.text10, { textAlign: 'center', backgroundColor: '#e3e6f0', minHeight: '12px' }]}>Tvsh 1 (€)</Text>
                                        <Text style={[styles.text10, { fontSize: '12px', borderTop: 0.5, textAlign: 'center', backgroundColor: '#e3e6f0', minHeight: '12px' }]}>{
                                        helper().formatNumber(parseFloat(columnTotal('vatSum')))
                                        }</Text>
                                    </View>
                                }
                                {columnTotal('totalWithTransportAndOvervalueAndDutyExcise') > 0 &&
                                    <View style={{ textAlign: 'center', flex: '1 1 0px' }}>
                                        <Text style={[styles.text10, { textAlign: 'center', backgroundColor: '#e3e6f0', minHeight: '12px' }]}>Vlera me tvsh</Text>
                                        <Text style={[styles.text10, { fontSize: '12px', borderTop: 0.5, textAlign: 'center', backgroundColor: '#e3e6f0', minHeight: '12px' }]}>{
                                        helper().formatNumber( parseFloat(columnTotal('totalWithTransportAndOvervalueAndDutyExcise') + columnTotal('vatSum')))
                                        }</Text>
                                    </View>
                                }
                            </View>
                        </View>}
                        <View style={[{ width: '100%', display: 'flex', flexDirection: 'row' }, purchaseDetails?.length < 35 ? styles.less35 : styles.more35]}>
                            <View style={[{ textAlign: 'left', marginLeft: 20, width: '50%' }]}>
                                <Text style={[styles.text10, { width: '100%', margin: 'auto' }]}>Kalkuloi: _____________________</Text>
                            </View>
                            <View style={[{ textAlign: 'right', width: '50%' }]}>
                                <Text style={[styles.text10, { width: '100%', margin: 'auto' }]}>Data e shtypjes: {helper().formatDate(new Date)}</Text>
                            </View>
                        </View>
                    </Page>
                </Document>
            </PDFViewer>
        </div>
    )
}

export default InvoicePurchase
