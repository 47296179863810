import React, { useEffect, useState } from "react";
import { Page, Text, View, Document, StyleSheet, PDFViewer, PDFDownloadLink, Image,Font } from '@react-pdf/renderer';
import apiService from "../../Services/apiServices";
import globals from '../../Services/globals';
import { auto, right } from '@popperjs/core';
import calculate from '../../Services/calculate';
import helper from '../../Services/helper';
import LoaderComponent from '../UI/Components/LoaderComponent';
import config from '../../config/config';
import FontUbuntuRegular from './fonts/Ubuntu-Regular.ttf';
import FontUbuntuItalic from './fonts/Ubuntu-Italic.ttf';
import FontUbuntu700 from './fonts/Ubuntu-Bold.ttf';
import FontUbuntuLight from './fonts/Ubuntu-Light.ttf';
import Switch from "react-switch";

const Invoice5 = (props) => {
  const [saleId, setSaleId] = useState(
    props.match ? props.match.params.id : props.saleId
  );
  const [sale, setSale] = useState({});
  const [saleDetails, setSaleDetails] = useState([]);
  const [partner, setPartner] = useState({});
  const [saleType, setSaleType] = useState({});
  const [units, setUnits] = useState({});
  const [settings, setSettings] = useState({});
  const [vatGroup, setVatGroup] = useState();
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    apiService().query('getSale', 'id=' + saleId)
    .then((response) => {
        if (response.success) {
            var sale = response.data[0];
            apiService()
            .query('getWarehouseWithBranchDetails', 'warehouseId=' + sale.warehouseId)
            .then((response) => {
                if (response.success) {
                  let warData = response.data[0];
                  sale.warehouseAddress = warData.address;
                  sale.unitNo = warData.unitNo;
                  sale.branchPhone = warData.phone;
                  sale.email = warData.email;
                  sale.totalTvsh = (parseFloat(sale.totalSum) + parseFloat(sale.vatSum));
                  setSale(sale);
                  getSaleDetails(sale.id, sale.partnerId, sale.salesTypeId);
                } else {
                    console.log('Error:' + response.errorMessage);
                }
            });
          
        }
        else {
            console.log('ka gabim: ', response);
        }
    });
    apiService()
      .get("units")
      .then((response) => {
        if (response.success) {
          setUnits(response.data);
          //   this.setState({ units: response.data });
        } else {
          console.log("Error:" + response.errorMessage);
        }
      });

      globals().getSettings().then((response) => {
        if (response) {
        let sett = response;
        setSettings(sett);
        }
    })
  }, []);

  const grupByVat = (items) => {
    let group = items.reduce((r, a) => {
      r[a.vat] = [...(r[a.vat] || []), a];
      return r;
    }, {});
    vatGroupFunc(group);
  };

  const getSaleDetails = (saleId, partnerId, salesTypeId) => {
    apiService()
      .query("getSaleDetails", "saleId=" + saleId)
      .then((response) => {
        var data = response.data;
        if (response.success) {
          for (var i = 0; i < data.length; i++) {
            if (data[i].discount > 0) {
              data[i].priceDiscount = calculate().calculateSale(
                "priceDiscount"
              )(data[i].price, data[i].discount);
              data[i].priceTvsh = calculate().calculateSale("priceTvsh")(
                data[i].priceDiscount,
                data[i].vat
              );
              data[i].totalPrice = calculate().calculateSale("totalPrice")(
                data[i].priceDiscount,
                data[i].quantity
              );
              data[i].totalPriceTvsh = calculate().calculateSale(
                "totalPriceTvsh"
              )(data[i].totalPrice, data[i].vat);
              // data[i].totalPriceItemTvsh= calculate().calculateSale('totalPriceItemTvsh')(data[i].totalPriceTvsh , data[i].quantity);
            } else {
              data[i].priceTvsh = calculate().calculateSale("priceTvsh")(
                data[i].price,
                data[i].vat
              );
              data[i].totalPrice = calculate().calculateSale("totalPrice")(
                data[i].price,
                data[i].quantity
              );
              data[i].totalPriceTvsh = calculate().calculateSale(
                "totalPriceTvsh"
              )(data[i].totalPrice, data[i].vat);
              // data[i].totalPriceItemTvsh= calculate().calculateSale('totalPriceItemTvsh')(data[i].totalPriceTvsh , data[i].quantity);
            }
          }
          grupByVat(data);
          setSaleDetails(data);
          setLoader(false);
        } else {
          console.log("ka gabim sd: ", response);
          //this.props.addToast('Ka ndodhur nje gabim!', { appearance: 'error', autoDismiss: true, });
        }
      });
    apiService()
      .query("partner", "id=" + partnerId)
      .then((response) => {
        if (response.success) {
            setPartner(response.data[0]);
          //console.log('Partner: ', response.data);
        } else {
          console.log("Error:" + response.errorMessage);
        }
      });
    apiService()
      .query("getSaleType", "id=" + salesTypeId)
      .then((response) => {
        if (response.success) {
            setSaleType(response.data[0]);
        } else {
          console.log("Error:" + response.errorMessage);
        }
      });
  };

  const checkColumn = (property, items) => {
    // const isTrue = (currentValue) => currentValue[property];
    // return items?.every(isTrue)
    return true;
  };

  const vatGroupFunc = (group) => {
    let arr = [];
    Object.entries(group).forEach(([key, value]) => {
      arr.push({ key: key, value: calculateVat(value) });
    });
    setVatGroup(arr);
  };
  const calculateVat = (items) => {
    return items?.reduce(
      (accumulator, current) => parseFloat(accumulator) + current.vatSum,
      0
    );
  };

  Font.register({ family: 'Times-Italic'});
  Font.register({
    family: 'Ubuntu',
    fonts: [
      {
        src: FontUbuntuRegular,
      },
      {
        src: FontUbuntuItalic,
        fontWeight: 'italic',
      },
      {
        src: FontUbuntu700,
        fontWeight: 'bold',
      },
      {
        src: FontUbuntuLight,
        fontWeight: 'light',
      },
    ],
  });
let styles = StyleSheet.create({
    page: {
        display: 'flex',
        //flexDirection: 'row',
        //alignItems:'baseline',
        backgroundColor: '#fff',
        width: '100%',
        flexDirection: 'row'
    },
    section: {
        //paddingHorizontal: 5,
        flexGrow: 1,
        width: '50%',
    },
    td: {
        marginLeft: 10,
        marginRight: 10
    },
    text: {
        // borderBottom: 1, 
        fontSize: 9,
        paddingTop: 2,
        paddingHorizontal: 2
    },
    textTblHeader: {
        paddingVertical: 2,fontFamily: 'Ubuntu' , fontSize: 10, fontWeight: 'bold'
    },
    textTotal: {
        paddingVertical: 3,fontFamily: 'Ubuntu' , fontSize: 10, fontWeight: 'bold'
    },
    text10: {
        paddingVertical: 2,fontFamily: 'Ubuntu' , fontSize: 10, fontWeight: 'light'
    },
    pageNumbers: {
      fontFamily: "Times-Italic",
      position: 'absolute',
      bottom: 10,
      left: 0,
      right: 0,
      textAlign:'center', 
      fontSize:8,
      // top: '15%'
      marginLeft:5,
      marginRight:5,
      marginTop: 10,
      marginBottom: -5  
    },
});

  return (

    <div className="pdf my-4">
       
        {loader && <LoaderComponent />}
        {!loader &&
            <PDFViewer>
                <Document style={{ justifyContent: 'center' }}>
                    <Page size="A4" style={{ paddingVertical: 20, paddingHorizontal: 30, margin: 0 }} wrap>
                        <View style={{display:'flex', flexDirection:'row',marginTop:'5px', marginBottom:'250px'}}>
                           
                        </View>
                        
                        <View>
                            <Text style={{ fontFamily: 'Ubuntu' , fontSize: 24,width:'50%' }}>{sale.salesTypeId == 1 ? helper().translate("SALE_INVOICE") : (sale.salesTypeId == 2) ? 'Faturë ' + saleType.Name : saleType.Name}</Text>
                        </View>
                        <View style={{display:'flex', flexDirection:'row',marginTop:'5px', borderBottom:1}}>
                            <Text style={{ fontFamily: 'Ubuntu' , fontSize: 14}}>{helper().translate("INVOICE_NO")}: 
                                <Text style={{ fontFamily: 'Ubuntu',fontWeight: 'light' , fontSize: 14}}> {sale.invoiceNo}</Text>
                            </Text>
                            <Text style={{ fontFamily: 'Ubuntu', fontSize: 14,marginLeft:'auto' }}>{helper().translate("DATE")}: 
                                <Text style={{ fontFamily: 'Ubuntu',fontWeight: 'light' , fontSize: 14}}> {helper().formatDate(sale.date)}</Text>
                            </Text>
                        </View>
                        <View style={{marginTop:'15px', marginBottom: "15px"}}>
                            <Text style={{ fontFamily: 'Ubuntu' , fontSize: 11}}>{helper().translate("BILL_TO")}: <Text style={{ fontFamily: 'Ubuntu',fontWeight: 'light' , fontSize: 11}}> {partner.partnerType !== 6 ? partner.companyName : partner.subjectName}</Text> </Text>

                            {partner.businessNo?.length > 2 && <Text style={[{ fontFamily: 'Ubuntu' , fontSize: 11 }]}>{helper().translate("NUI")}: &nbsp; 
                                <Text style={[{ fontFamily: 'Ubuntu' , fontSize: 11, fontWeight: 'light'}]}> 
                                {partner.businessNo}
                                </Text> 
                            </Text>}
                            {partner.fiscalNo?.length > 2 && <Text style={[{ fontFamily: 'Ubuntu' , fontSize: 11 }]}>{helper().translate("FISCAL_NUMBER")}: &nbsp; 
                                <Text style={[{ fontFamily: 'Ubuntu' , fontSize: 11, fontWeight: 'light'}]}> 
                                {partner.fiscalNo}
                                </Text> 
                            </Text>}
                            {partner.vatNo?.length > 2 && <Text style={[{ fontFamily: 'Ubuntu' , fontSize: 11 }]}>{helper().translate("VAT_NUMBER")}: &nbsp; 
                                <Text style={[{ fontFamily: 'Ubuntu' , fontSize: 11, fontWeight: 'light'}]}> 
                                {partner.vatNo}
                                </Text> 
                            </Text>}
                            
                            {partner.address?.length > 1 &&  <Text style={[{ fontFamily: 'Ubuntu' , fontSize: 11 }]}>{helper().translate("ADDRESS")}: &nbsp; 
                                <Text style={[{ fontFamily: 'Ubuntu' , fontSize: 11, fontWeight: 'light'}]}> 
                                {partner.address} {partner.city}
                                </Text> 
                            </Text> }
                           
                           {partner.phone?.length > 2 && <Text style={[{ fontFamily: 'Ubuntu' , fontSize: 11 }]}>{helper().translate("PHONE")}: &nbsp; 
                                <Text style={[{ fontFamily: 'Ubuntu' , fontSize: 11, fontWeight: 'light'}]}> 
                                {partner.phone}
                                </Text> 
                            </Text>}
                            
                        </View>

                        <View style={{ marginTop:'15px' }}>
                            {/* width 565px */}
                            <View style={[styles.page, { borderTop: 1,borderBottom:1,borderLeft:1, paddingHorizontal: 0, minHeight: '28px'}]}>
                                <Text style={[styles.textTblHeader, { width: '20px', textAlign: 'center',borderRight: 1 }]}>{helper().translate("NR")}</Text>
                                {/* <Text style={[styles.textTblHeader, { width: '70px', textAlign: 'center',borderRight: 1 }]}>{helper().translate("BARCODE")}</Text> */}
                                <Text style={[styles.textTblHeader, { width: '105px', textAlign: 'center', flexGrow: 3 ,borderRight: 1 }]}>{helper().translate("DESIGNATION")}</Text>
                                {checkColumn('quantity', saleDetails) &&
                                    <Text style={[styles.textTblHeader, { width: '35px', textAlign: 'center',borderRight: 1 }]}>{helper().translate("QUANTITY")}</Text>
                                }
                                <Text style={[styles.textTblHeader, { width: '40px', textAlign: 'center' ,borderRight: 1 }]}>{helper().translate("UNIT")}</Text>
                                 {sale.withVat && checkColumn('price', saleDetails) &&
                                    <Text style={[styles.textTblHeader, { width: '60px', textAlign: 'center', borderRight: 1 }]}>{helper().translate("PRICE")}</Text>
                                }
                                {checkColumn('discount', saleDetails) &&
                                    <Text style={[styles.textTblHeader, { width: '45px', textAlign: 'center' ,borderRight: 1 }]}>{helper().translate("DISCOUNT")} %</Text>
                                }
                                 {sale.withVat && checkColumn('priceDiscount', saleDetails) && settings.withVat === true && 
                                    <Text style={[styles.textTblHeader, { width: '55px', textAlign: 'center',borderRight: 1 }]}>{helper().translate("VAT")} %</Text>
                                }
                                {checkColumn('priceTvsh', saleDetails) &&  settings.withVat === true && 
                                    <Text style={[styles.textTblHeader, { width: '60px', textAlign: 'center', borderRight: 1 }]}>{helper().translate(sale.withVat ? "PRICE_WITH_VAT" :"PRICE")}</Text>
                                }
                                {checkColumn('totalPriceTvsh', saleDetails) &&
                                    <Text style={[styles.textTblHeader, { width: '70px', textAlign: 'center',borderRight: 1}]}>{helper().translate("TOTAL")}</Text>
                                }
                            </View>
                            {saleDetails?.map((item, index) => (
                                <View wrap={false}  key={index} style={[styles.page, { borderBottom: 1,borderLeft:1,minHeight: '18px', paddingHorizontal: 0, overflow: 'hidden', height: 'auto', backgroundColor: 'transparent' }]}>
                                    <Text style={[styles.text10, { borderRight: '1px solid #000',width: '20px', textAlign: 'center' }]}>{item.no}</Text>
                                    {/* <Text style={[styles.text10, { borderRight: '1px solid #000',width: '70px', textAlign: 'center'}]}>{item.barcode}</Text> */}
                                    <Text style={[styles.text10, { borderRight: '1px solid #000',width: '105px', textAlign: 'center', flexGrow: 2 }]}>{item.itemName}</Text>
                                    {checkColumn('quantity', saleDetails) &&
                                        <Text style={[styles.text10, {borderRight: 1, width: '35px', textAlign: 'center'}]}>{item.quantity}</Text>
                                    }
                                    <Text style={[styles.text10, { borderRight: 1,width: '40px', textAlign: 'center'}]}>{(units.find(u => u.id === item.unitId))?.name}</Text>
                                    {sale.withVat && checkColumn('price', saleDetails) &&
                                        <Text style={[styles.text10, {borderRight: 1, width: '60px', textAlign: 'center' }]}>{typeof (item.price) == 'undefined' ?
                                            item.price : helper().formatNumber(item.price,true)}</Text>
                                    }
                                    {checkColumn('discount', saleDetails) &&
                                        <Text style={[styles.text10, {borderRight: 1,  width: '45px', textAlign: 'center'}]}>{item.discount}</Text>
                                    }
                                    {sale.withVat && checkColumn('vat', saleDetails) && settings.withVat === true && 
                                        <Text style={[styles.text10, {borderRight: 1,  width: '55px', textAlign: 'center'}]}>{item.vat}</Text>
                                    }
                                    {checkColumn('priceTvsh', saleDetails) &&  settings.withVat === true && 
                                        <Text style={[styles.text10, {borderRight: 1,  width: '60px', textAlign: 'center'}]}>{typeof (item.priceTvsh) == 'undefined' ?
                                            item.priceTvsh : helper().formatNumber(item.priceTvsh,true)}</Text>
                                    }
                                    {checkColumn('totalPriceTvsh', saleDetails) &&
                                        <Text style={[styles.text10, {borderRight: 1, width: '70px', textAlign: 'center' }]}>{typeof (item.totalPriceTvsh) == 'undefined' ?
                                            item.totalPriceTvsh : helper().formatNumber(item.totalPriceTvsh,true)}</Text>
                                    }
                                </View>
                            ))}
                        </View>
                        <View wrap={false}  style={[styles.page, { marginTop: 5, paddingVertical: 10, alignItems: "flex-start" }]}>
                            <View style={[{ width: '55%', padding: 5 }]}>
                                <Text style={styles.textTblHeader}>{helper().translate("BANK_NUMBER")}:</Text>
                                <Text style={styles.text10}>{settings.accNo}</Text>
                                <Text style={[styles.textTblHeader,{marginTop:'5px'}]}>{helper().translate("COMMENT")}:</Text>
                                <Text style={[styles.text10, { paddingRight: 10 }]}>{sale.comment ? sale.comment : ""}</Text>
                            </View>
                            <View style={[{ width: '45%', paddingVertical: 0,border:1 }]}>
                                <View style={[styles.page, { borderBottom: settings.withVat === true ? 1 : 0, marginTop: 0, paddingHorizontal: 5, alignItems: "flex-end"}]}>
                                    <View style={{ width: '50%'}}>
                                        <Text style={[styles.textTotal, { textAlign: 'left' }]}>{helper().translate("TOTAL")}: </Text>
                                    </View>
                                    <View style={{ width: '50%' }}>
                                        <Text style={[styles.textTotal, { textAlign: 'right' }]}>{
                                            typeof ((sale.totalSum)) == 'undefined' ?
                                                (sale.totalSum) : helper().formatNumber(sale.totalSum )
                                        }</Text>
                                    </View>
                                </View>
                                {
                                  settings.withVat === true &&   vatGroup?.map((item) => (
                                        <View style={[styles.page, { borderBottom:1, marginTop: 0, paddingHorizontal: 5, alignItems: "flex-end" }]}>
                                            <View style={{ width: '50%'}}>
                                                <Text style={[styles.textTotal, { textAlign: 'left' }]}>{helper().translate("VAT_VALUE")}({item.key}%): </Text>
                                            </View>
                                            <View style={{ width: '50%' }}>
                                                <Text style={[styles.textTotal, { textAlign: 'right' }]}>{helper().formatNumber(item.value)}</Text>
                                            </View>
                                        </View>
                                    ))
                                }
                                {settings.withVat === true && 
                                <View style={[styles.page, { marginTop: 0, paddingHorizontal: 5, alignItems: "flex-end"}]}>
                                    <View style={{ width: '50%'}}>
                                        <Text style={[styles.textTotal, { textAlign: 'left' }]}>{helper().translate("TOTAL_WITH_VAT")}: </Text>
                                    </View>
                                    <View style={{ width: '50%' }}>
                                        <Text style={[styles.textTotal, { textAlign: 'right' }]}>{typeof (sale.totalTvsh) == 'undefined' ?
                                            (sale.totalTvsh) : helper().formatNumber(parseFloat(sale.totalTvsh))
                                             }</Text>
                                    </View>
                                </View>
                                
                                }
                            </View>
                        </View>
                        <Text style={styles.pageNumbers} render={({ pageNumber, totalPages  }) => (
                             pageNumber  === totalPages && (
                              <Text style={styles.pageNumbers} fixed>{helper().translate("IMPLEMENTED_BY")} Nsum Solution</Text>
                            )
                          )} />
                    </Page>
                </Document>
            </PDFViewer>
        }
    </div>
)
};

export default Invoice5;
