import React, { useState, useEffect, useRef , useContext } from 'react';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import SubNav from '../../PageComponents/Nav/SubNav'
import helper from '../../Services/helper';
import WarehouseTransfers from './WarehouseTransfers';
import WarehouseTransfer from './WarehouseTransfer';
//import Admin from '../AdministrationOld/administrationOld';
import RecalculateAvgPrice from '../../components/Tools/RecalculateAvgPrice.js';
import UserPermissionContext from '../Context/UserPermissionContext';

const links = [
    {path:"features/warehouse-transfers", name:helper().translate("WAREHOUSE_TRANSFERS"), icon:"fas fa-file-invoice-dollar mr-2", component: WarehouseTransfers, type:"menu" },
    {path:"features/warehouse-transfer/:id", name:"Transfer depo id", icon:"fas fa-money-bill mr-2", component: WarehouseTransfer, type:"link"},
    {path:'features/recalculation', name:helper().translate("RECALCULATION"), icon: "fas fa-fw fa-wrench mr-2", component: RecalculateAvgPrice,  type:"menu"},
]

const Features = (props) => {
    
    const {userPermission} = useContext(UserPermissionContext);
    if(userPermission?.find((f)=> f.moduleName === 'WAREHOUSE_TRANSFERS')?.allowView === 0){
        links[0].disabled = true
    }
    
    return (
        <div className="d-flex">
        <div className="p-0 position-relative sub-menu">
            <SubNav links={links} />
        </div>
        <div className="w-100 overflow-auto pr-0">
            {props?.location?.pathname === '/features'  && userPermission?.find((f)=> f.moduleName === 'WAREHOUSE_TRANSFERS')?.allowView === 1 ?  <WarehouseTransfers /> : ""}
            <Router>
                <Switch>
                    {links.map((link)=>{
                        return <Route path={`/${link.path}`} component={link.component} />
                    })}
                </Switch>
            </Router>
        </div>
    </div> 
   
    )
}

export default Features
