import React, { useState, useEffect } from "react";
import Select ,{components} from "react-select";
import { MDBInput } from "mdb-react-ui-kit";
import apiService from "../../Services/apiServices";
import { useToasts } from "react-toast-notifications";
import Search from "../../PageComponents/Search/Search_Old";
import { confirmAlert } from "react-confirm-alert";
import calculate from "../../Services/calculate";
import helper from "../../Services/helper";
import globals from "../../Services/globals";


function ItemDiscount(props) {
  const [items , setItems] = useState([]);
  const [warehouse , setWarehouse] = useState([]);
  const [partners , setPartners] = useState([]);
  const [partnerStringLength , setPartnerStringLength] = useState(0);
  const { addToast } = useToasts();

  const mTransaction = {
    id:0,
    date:(new Date()).toISOString().substring(0, 10) + ' ' + new Date().getHours() + ':' + new Date().getMinutes() + ':' + new Date().getSeconds(),
    startDate: (new Date()).toISOString().substring(0, 10) + " " + "00:00:00" ,
    endDate: (new Date()).toISOString().substring(0, 10) + " " + "23:59:59",
    warehouseId: null,
    partnerId: null,
    partnerSelected: false,
    warehouseSelected: false,
    comment: '',
    createdBy: '',
    changedAt: null,
    changedBy: null,
    isChanged: false
  }

  const newTrasaction = {
    no: 1,
    id: 0,
    discountItemsId: 0,
    itemId: 0,
    itemName: helper().translate("CHOOSE_ITEM"),
    discount: 0,
    discountPrice: 0,
    price: 0,
    vat:0,
    priceWithVat:0,
    validate: true,
    addButton: true,
    status: 0,
  };
  const [mainTransaction , setMainTransaction] = useState({...mTransaction});
  const [transaction, setTransaction] = useState([{...newTrasaction , editMode: true}]);

  const printRow = (itemId) => {
    if(itemId) {
        //window.location.href = `#/printPurchase/${item.id}`
        window.open(`#/print-internal_movement/${itemId}`, 'popUpWindow','height=3000,width=32000,left=100,top=100,right=100,bottom=100,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes')
    }
    else {
        console.log("print isnt working right now");
    }
}

const saveOnlyOnce = () => {
  var element = document.getElementById("saveOnce");
  element.classList.toggle("disabled");
}



  const getStockItems = () => {
    let date = (new Date()).toISOString().substring(0, 10) + ' ' + new Date().getHours() + ':' + new Date().getMinutes() + ':' + new Date().getSeconds();
    var params = `&dateEnd=${date}&warehouseId=${0}`;
    apiService()
    .query('RptGetItemsInStock', params )
      .then((response) => {
        var stockItems = response.data;
        apiService().get('items').then((response) => {
          if (response.success) {
            var data = response.data;
            let itemsArr = []; 
            stockItems.forEach(e => {
             let findRow = data.find(d => d.id === e.id);
             if(findRow){
               findRow.avgPrice = e.avgPrice;
               itemsArr.push(findRow);
             }
            });
            let dataFilter = itemsArr.filter(i => i.service === false && i.expense === false);
            let options = dataFilter.map((item, index) => ({
              value: item.id,
              label: item.barcode + " - " +  item.itemName,
              name: item.itemName,
              unitId: item.unitId,
              avgPrice: item.avgPrice,
              price: item.retailPrice,
              vat: item.vat
            }));
            setItems(options);
          } else {
            console.log('Error:' + response.errorMessage);
          }
        });
      });
  }
  
  const getDiscountItem = (id) => {
     apiService().query("getDiscountITem" , "id="+id).then((response) => {
          if (response.success) {
            var data = response.data[0];
            data.startDate = data.startDate;
            data.endDate = data.endDate;
            setMainTransaction({...data});
            apiService().query("getDiscountITemsDetails" , "discountId="+ id).then((response) => {
              if (response.success) {
                var res = response.data;
                for(var i= 0; i< res.length; i++  ){
                  if(i === (res.length - 1)) {
                    res[i].addButton = true;
                    }
                    res[i].editMode = false;
                    res[i].discountPrice = calculate().calculateSale('priceDiscount')(res[i].price, res[i].discount);
                    res[i].priceWithVat = calculate().calculateSale('priceTvsh')(res[i].discountPrice ,res[i].vat);
                  // data[i].totalPrice= calculate().calculateSale('totalPrice')(data[i].priceDiscount,data[i].quantity);
                  // data[i].totalPriceTvsh= (data[i].totalPrice + data[i].vatSum);
                  }
               
                setTransaction(res);
                
              } else {
                console.log('Error:' + response.errorMessage);
              }
            });
            
          } else {
            console.log('Error:' + response.errorMessage);
          }
        });
  }
 

  const getWarehouses = () => {
    apiService()
      .get("warehouses")
      .then((response) => {
        if (response.success) {
          let data = response.data;
            let options = data.map((item, index) => ({
              value: item.id,
              label: item.name
            }));
          setWarehouse(options);
        } else {
          alert("Error: getting warehouses" + response.errorMessage);
        }
      });
}

const getParnters = () => {
    let options;
     apiService().query('getPartners').then((response) => {
       if (response.success) {
         let data = response.data.filter(i => i.status === 1);
         options = data.map((item, index) => ({
           value: item.id,
           label: item.companyName
         }));
         setPartners(options)
       } else {
         console.log('Error:' + response.errorMessage);
       }
     });
   }


 
 useEffect(()=>{
      var id = props.match.params.id;
      if(parseInt(id) > 0){
        getDiscountItem(id);
      }
      getWarehouses();
      getParnters();
      getStockItems();
 },[])

 const selectStyles = {
   container: provided => ({
     ...provided,
     minWidth: '190px'
   }),
   control: (provided, state) => ({
     ...provided,
     background: "#fff",
     borderColor: "#9e9e9e",
     minHeight: "28.59px",
     height: "28.59px",
     boxShadow: state.isFocused ? null : null,
   }),

   valueContainer: (provided, state) => ({...provided,height: "28.59px",padding: "0 6px",}),
   input: (provided, state) => ({ ...provided, margin: "0px",}), indicatorSeparator: (state) => ({ display: "none", }),
   indicatorsContainer: (provided, state) => ({...provided,height: "28.59px",}),
 };

 const multipleItemStyle = {
  container: provided => ({
    ...provided,
    minWidth: '190px'
  }),
  control: (provided, state) => ({
    ...provided,
    background: "#fff",
    borderColor: "#9e9e9e",
    minHeight: "28.59px",
    height: "28.59px",
    boxShadow: state.isFocused ? null : null,
  }),
  menu: (provided, state) => ({
    ...provided,
    width: "auto"
  }),
   menuList: (provided, state) => ({
    ...provided,
    width: "auto"
  }),

  valueContainer: (provided, state) => ({...provided,height: "28.59px",padding: "0 6px",}),
  input: (provided, state) => ({ ...provided, margin: "0px",}), indicatorSeparator: (state) => ({ display: "none", }),
  indicatorsContainer: (provided, state) => ({...provided,height: "28.59px",}),
};


 const addButtonLastRow =  (arrTransaction) => {   
  for(var i= 0 ; i< arrTransaction.length ; i++){
    arrTransaction[i].addButton = false;
    //arrTransaction[i].editMode = false;
    if(i === (arrTransaction.length -1 )) {
      arrTransaction[i].addButton = true;
    }
  }
 }
 const filterTransactions = (searchString) => { 

  if (searchString.length > 0) {   
      let searchTransaction = transaction.filter((item) => {
      return (       
           //item?.partnerName?.toString().toLowerCase().match(searchString)
           item.partnerName === '' || item.editMode ||  item?.partnerName?.toString().toLowerCase().match(searchString)
        )
      });
   
      addButtonLastRow(searchTransaction);
      // var count = 1;
      // searchTransaction.forEach(i=> {i.no = count; count++});
      // setTransactionFilter(searchTransaction);
      return searchTransaction;
    } 
    else {
      let allTransaction = [...transaction];
      addButtonLastRow(allTransaction);
      return allTransaction;
    }
 
  };


  const onfieldChange = (e,no) => {
    var newObj = {...mainTransaction};
    if(e.target.name === "startDate" || e.target.name === "endDate"){
      newObj[e.target.name] = e.target.name === "startDate" ? e.target.value + " " + "00:00:00" : e.target.value + " " + "23:59:59";
      newObj.isChanged= true;
      setMainTransaction(newObj);
    } else{
      newObj[e.target.name] = e.target.value;
      newObj.isChanged= true;
      setMainTransaction(newObj);
    }
  };

  const onSelectChange =(e,obj) => {
    let newObj = {...mainTransaction};
    if(obj.name === "partnerId"){
        newObj[obj.name] = e.value;
        newObj.partnerSelected= false;
        newObj.isChanged = true;
    }else 
    if(obj.name === "warehouseId"){
        newObj[obj.name] = e.value;
        newObj.isChanged = true;
        newObj.warehouseSelected=false;
    }
    setMainTransaction(newObj)
  }

  const validateEachField = (no) => {
    var newArr = [...transaction];
    if(newArr[no-1].validate === false){
      if(newArr[no-1].itemId !== 0 && newArr[no-1].price !== 0 && newArr[no-1].discount !== 0 && newArr[no-1].discountPrice !== 0 && newArr[no-1].priceWithVat !== 0){
        return true
      }else{
        return false
      }
    }
    
  }

  const selectChange = (e,obj,no) => {
    var newArr = [...transaction];
    if(obj.name === "item"){
    let transactionDetails = newArr.find(i=> i.no === no);
    let item = items.find(i=> i.value === e.value);
    transactionDetails.itemId = item.value;
    transactionDetails.vat = item.vat;
    transactionDetails.price = item.price;
    transactionDetails.priceWithVat = calculate().calculateSale('priceTvsh')(transactionDetails.price ,transactionDetails.vat);
    if(validateEachField(no)){
    transactionDetails.validate = true;
    }
    setTransaction(newArr);
    }
  };

  const fieldChange = (e,no) => {
    var newArr = [...transaction];
    if(e.target.name === "discount" ){
        if(parseFloat(e.target.value) >= 0 || e.target.value === ""){
            newArr[no-1][e.target.name] =parseFloat(e.target.value);
            newArr[no-1].discountPrice = calculate().calculateSale('priceDiscount')(newArr[no-1].price, newArr[no-1].discount);
            newArr[no-1].priceWithVat = calculate().calculateSale('priceTvsh')(newArr[no-1].discountPrice ,newArr[no-1].vat);
        }
    }else if(e.target.name === "discountPrice"){
        newArr[no-1][e.target.name] = parseFloat(e.target.value) ;
        newArr[no-1].priceWithVat = calculate().calculateSale('priceTvsh')(newArr[no-1].discountPrice ,newArr[no-1].vat);
        newArr[no-1].price= calculate().calculateSale('priceDiscountTvsh')(newArr[no-1].priceWithVat ,newArr[no-1].discount ,newArr[no-1].vat);
    }else if(e.target.name === "price"){
      newArr[no-1][e.target.name] = parseFloat(e.target.value) ;
      newArr[no-1].discountPrice = calculate().calculateSale('priceDiscount')(newArr[no-1].price, newArr[no-1].discount);
      newArr[no-1].priceWithVat = calculate().calculateSale('priceTvsh')(newArr[no-1].discountPrice ,newArr[no-1].vat);
    }else if(e.target.name === "vat"){
        newArr[no-1][e.target.name] = parseFloat(e.target.value) ;
    }
    else if(e.target.name === "priceWithVat"){
        newArr[no-1][e.target.name] = parseFloat(e.target.value) ;
        newArr[no-1].price= calculate().calculateSale('priceDiscountTvsh')(newArr[no-1].priceWithVat ,newArr[no-1].discount ,newArr[no-1].vat);
        newArr[no-1].discountPrice = calculate().calculateSale('priceDiscount')(newArr[no-1].price, newArr[no-1].discount);
    }
    if(newArr[no-1].validate === false){
      if(newArr[no-1].itemId !== 0 && newArr[no-1].price !== 0 && newArr[no-1].discount !== 0 && newArr[no-1].discountPrice !== 0 && newArr[no-1].priceWithVat !== 0){
        newArr[no-1].validate = true;
      }
    }
    setTransaction(newArr);
  };

  const addNewRow = () => {
    var id = props.match.params.id
    let newItem = newTrasaction;
    newItem.no = Math.max(...transaction.map(o => o.no), 0) + 1;
    newItem.discountItemsId = parseInt(id);
    
    //newItem.unChangedId = Math.max(...transaction.map(o => o.no), 0) + 1;
    newItem.status = 1;

    const newState = [...transaction, newItem];

    for(var i= 0 ; i< newState.length ; i++){
      newState[i].addButton = false;
      newState[i].editMode = false;
      if(i === (newState.length - 1)) {
        newState[i].addButton = true;
        newState[i].editMode = true;
        }
      }
      setTransaction(newState);      
  };

  const addRowEnter = () => {
    if(event.key === 'Enter'){
      addNewRow();
    }
  }


  const rowClick = (no) => {
    //enter edit mode
    let newItem = [...transaction];
    newItem.forEach(e => e.editMode = false);
    newItem[no-1].editMode = true;
    if(newItem[no-1].id > 0){
      newItem[no-1].status = 2;
    }
    setTransaction(newItem);
  }

  function onClear(){
    props.history.push("/edit-discount/0");
  }


  const newHandle = () => {
    let mTrans = {...mainTransaction}
    let trans = [...transaction];
    let obj = trans.filter(e => (e.status === 2) || (e.status === 1));
    var newPressed = true;
    console.log(obj.length,mTrans.isChanged)
    if(obj.length === 0 && (mTrans.isChanged === false || typeof(mTrans.isChanged) === 'undefined')){
      onClear();
    }else{
        confirmAlert({
          customUI: ({ onClose }) => {
            return (
              <div className="custom-ui" >
                <h1>{helper().translate("ARE_YOU_SURE")}</h1>
                <p>{helper().translate("WANT_TO_SAVE_ACTUAL_CHANGES")}</p>
                <button
                  onClick={() => {
                    save(newPressed);
                    onClose();
                  }}
                >
                  {helper().translate("YES")}
                </button>
                <button onClick={() =>{
                  onClear();
                  onClose();
                }} className="ms-3">{helper().translate("NO")}</button>
              </div>
            );
          }
        })
    }
  }

 
  const handleDelete = (id , no)=> {
    if(id>0){
      var removeRow =  transaction.filter((row) => row.no !== no);
      if(removeRow.length >= 1){
        apiService().query('deleteDiscountItemsDetails', "id="+id).then((response) => {
          if (response.success) {
            var dDetails =  transaction.filter((row) => row.no !== no);
            if(dDetails.length === 0 ){
            var newItem = {...mTransaction};
            newItem.no = Math.max(...transaction.map(o => o.no), 0) + 1 ;
            newItem.discountItemsId = parseInt(props.match.params.id);
            dDetails.push(newItem);
            }
            for(var i= 0; i< dDetails.length; i++  ){
              if(i === (dDetails.length - 1)) {
                dDetails[i].addButton = true;
                }}
            var num = 1;
            dDetails.forEach(i=> {i.no = num; num++});
            // console.log("sdetails" , sDetails);
            var updateDiscountDetails = [];
            var count = 1;
            var arr = transaction.filter((i) => i.id !== id );
            arr.forEach(i=> {i.no = count; count++});
            // console.log("Arr" , arr);
            arr.forEach(e => {
                 var contextObj = {
                  entity: e,
                  status: 2,
                  type: 'discountItemsDetails'
               }
                 updateDiscountDetails.push(contextObj);
               });
              //  console.log("items to update" ,updateDiscountDetails)
               apiService().saveChanges(updateDiscountDetails).then((response) => {
                 if(response.success){
                   console.log("success")
                 }
                  }) 
                
            setTransaction(dDetails);
          } else {
            console.log('Error:' + response.errorMessage);
          }
        });
      }else {
        addToast(helper().translate("CANT_DELETE_LAST_ROW"), {
          appearance: "error",
          autoDismiss: true,
        });
      }
    }
    
    else {
          let newItem = [...transaction];
          let arr = newItem.filter((row) => row.no !== no);
          var num = 1;
          arr.forEach(i=> {i.no = num; num++});
           for(var i= 0; i< arr.length; i++  ){
            if(i === (arr.length - 1)) {
              arr[i].addButton = true;
              }}
          if(arr.length == 0){
            setTransaction([newTrasaction]);
          }else 
          if(arr.length >0){
            setTransaction(arr);
          }
    }
    }

  const alertnOnDelete = (id , no)=> {
    if(id > 0){
      confirmAlert({
          customUI: ({ onClose }) => {
            return (
              <div className="custom-ui">
                <h1>{helper().translate("ARE_YOU_SURE")}</h1>
                <p>{helper().translate("WANT_TO_DELETE_THIS_ITEM_DISCOUNT")}</p>
                <button
                  onClick={() => {
                    handleDelete(id, no);
                    onClose();
                  }}
                >
                  {helper().translate("YES")} , {helper().translate("DELETE")}!
                </button>
                <button onClick={onClose}>{helper().translate("NO")}</button>
              </div>
            );
          },
        });
    }else if( id == 0) {
      handleDelete(id, no);
    }
  }

  const validateMain = () => {
  let main = {...mainTransaction};
  if(main.partnerId != null && main.warehouseId != null){
    return true
  }else{
    addToast(helper().translate("FILL_REQUIRED_FIELDS"), {
      appearance: "error",
      autoDismiss: true,
    });

    if(main.partnerId === null){
      main.partnerSelected = true;
    }
    if(main.warehouseId === null){
      main.warehouseSelected = true;
    }
    setMainTransaction(main);
    return false
  }
//   console.log("hi")
  }
  const validateTransactionDetails = () => {
    let details = [...transaction];
    details.forEach(e => {
    if(e.itemId === 0 || e.price === 0 || e.discount === 0 || e.discountPrice === 0 || e.priceWithVat === 0){
      e.validate = false,
      e.editMode = true
    }
  });
  setTransaction(details);
  let obj = details.filter(o => (o.itemId === 0 || o.validate === false) );
  if(obj.length === 0){
    return true
  }else {
    addToast(helper().translate("FILL_REQUIRED_FIELDS"), {
        appearance: "error",
        autoDismiss: true,
      });
  }
  }
  
  const save = (newPressed) => {
    console.log(newPressed)
  if(validateMain() && validateTransactionDetails()){

    var id = props.match.params.id;

    var discountItems = {...mainTransaction};
    var discountItemsDetails = [...transaction];
    if(parseInt(id) === 0){
          saveOnlyOnce();
          discountItems.createdBy = globals().getLogedInUser().data.username;
          // setMainTransaction({...mainTransaction , createdBy: globals().getLogedInUser().data.username})
          apiService().post('DiscountItems', discountItems)
          .then((response) => {
            if(response.success){
              let resId = response.data.id;
              var insertDiscountItemsDetails = [];

              discountItemsDetails.forEach(e => {
                e.discountItemsId = resId;
                e.createdBy = globals().getLogedInUser().data.username;
                var contextObj = {
                  entity: e,
                  status: 1,
                  type: 'discountItemsDetails'
                }
                insertDiscountItemsDetails.push(contextObj);
              });
              apiService().saveChanges(insertDiscountItemsDetails).then((response) => {
              if(response.success){
                saveOnlyOnce();
                addToast(helper().translate("SUCCESSFULLY_ADDED"), {
                  appearance: "success",
                  autoDismiss: true,
                });
                if(newPressed !== true){
                  props.history.push("/edit-discount/"+ resId);
                }else{
                  props.history.push("/edit-discount/0");
                }
              }
             })
            }
            else{
              addToast(helper().translate("ERROR_SAVING"), {
                appearance: "success",
                autoDismiss: true,
              });
                  }
          });
        
    }else if(parseInt(id) >= 0){
        discountItems.changedBy = globals().getLogedInUser().data.username;
        apiService().put('DiscountItems', discountItems )
        .then((response) => {
          if(response.success){
            var updateDiscountItemsDetails = [];
            let obj = discountItemsDetails.filter(e => (e.status === 2) || (e.status === 1));
            obj.forEach(e => {
              e.changedBy = globals().getLogedInUser().data.username;
              var contextObj = {
                entity: e,
                status: e.status,
                type: 'discountItemsDetails'
              }
              updateDiscountItemsDetails.push(contextObj);
            });
            apiService().saveChanges(updateDiscountItemsDetails).then((response) => {
               if(response.success){
                discountItemsDetails.forEach(e => {
                  e.status = 0
                });
               };
               
               if(newPressed !== true){
                props.history.push("/edit-discount/"+ id);
              }else{
                props.history.push("/edit-discount/0");
              }
            })
        
            addToast(helper().translate("SUCCESSFULLY_MODIFIED"), {
              appearance: "success",
              autoDismiss: true,
            });
          }
          else{
            addToast(helper().translate("ERROR_MODIFIED"), {
              appearance: "success",
              autoDismiss: true,
            });
                  }
        });
    }
  }
  };
  const itemOption = props => {
    if(props.data.label.length > partnerStringLength){
      setPartnerStringLength(props.data.label.length);
    }
    return (
      <components.Option {...props}>
        {/* {console.log(stringMaxLength)} */}
        <div className="d-flex text-truncate"><p style={{width: `${partnerStringLength * 7}px`}} className="m-0 text-left ">{props.data.label}</p></div>
      </components.Option>
    );
  };

  
  return (
    <div id="addTransaction">
      <div className="container-fluid px-2 mt-3">
        <Search search={false}  newHandle={newHandle} save={save} back="#/items-with-discount"  />
        <div className="card border-left-primary shadow h-100 mt-1">
            <div className="row mt-2" >
                <div className="col-md-6 col-12 my-md-0 my-1">
                    <div className="card-body pt-1 pb-1">
                      <div className="row mt-2" >
                          <div className="col-md-6 col-12 my-md-0 my-1">
                            <div className="row">
                                <div>
                                  <Select
                                    name="partnerId"
                                    placeholder={helper().translate("PARTNER")}
                                    styles={selectStyles}
                                    className={mainTransaction.partnerSelected == true ? " is-invalid-s ": '' }
                                    options={partners}
                                    onChange={(e, obj) => onSelectChange(e, obj)}
                                    value={partners?.find(p => p.value == mainTransaction.partnerId)}
                                  />
                                </div>
                            </div>
                          </div>
                          <div className="col-md-6 col-12 my-md-0 my-1">
                            <div className="row">
                                <div>
                                    <div style={{width: '100%'}}>
                                        <MDBInput
                                        name="startDate"
                                        // disabled={!findByDate.dateStartDisabled}
                                        className="form-control-sm active"
                                        onChange={(e) => onfieldChange(e)}
                                        type="date"
                                        label={helper().translate("START_DATE")}
                                        value={mainTransaction?.startDate.substring(0,10) || ''}
                                        />
                                    </div>
                                </div>
                            </div>
                          </div>
                      </div>
                    </div>
                    <div className="card-body pt-1 pb-1">
                        <div className="row mb-2">
                        <div className="col-md-6 col-12 my-md-0 my-1">
                            <div className="row">
                                <div>
                                    {/* <label className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                                      Depo
                                      </label> */}
                                    <Select
                                      name="warehouseId"
                                      placeholder={helper().translate("WAREHOUSE")}
                                      styles={selectStyles}
                                      className={mainTransaction.warehouseSelected == true ? " is-invalid-s ": '' }
                                      options={warehouse}
                                      onChange={(e, obj) => onSelectChange(e, obj)}
                                      value={warehouse?.find(w => w.value == mainTransaction.warehouseId)}
                                    />
                                </div>
                            </div>
                        </div> 
                        <div className="col-md-6 col-12 my-md-0 my-1">
                            <div className="row">
                                <div>
                                    <div style={{width: '100%'}}>
                                        <MDBInput
                                        name="endDate"
                                        // disabled={!findByDate.dateStartDisabled}
                                        className="form-control-sm active"
                                        onChange={(e) => onfieldChange(e)}
                                        type="date"
                                        label={helper().translate("END_DATE")}
                                        value={ mainTransaction?.endDate.substring(0,10) || ''}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div> 
                    </div>
                </div>
                {/* <div className="col-md-6 col-12 my-md-0 my-1">
                     <div className="col-md-6 col-12">
                        <div className="text-box ps-3 pe-md-0 pe-3 ">
                          <label className="text-xs font-weight-bold text-primary mb-0">
                          {helper().translate("COMMENT")}
                          </label>
                          <textarea 
                          className="textareas form-control textArea-none " 
                          name="comment"
                          value={mainTransaction.comment}
                          onChange={(e) => onfieldChange(e)}
                          />
                        </div>
                      </div>
                </div> */}
                <div className="col-lg-3 col-12">
                        <div className="pt-2" >
                            <MDBInput
                            textarea
                            rows={3}
                            name="comment"
                            className="form-control-sm active"
                            onChange={(e) => onfieldChange(e)}
                            type="text"
                            label={helper().translate("COMMENT")}
                            value={mainTransaction.comment}
                            />
                        </div>
                    </div>
            </div>
        </div>
        <div className="card shadow h-100 mt-1">
      <div className="card-header py-2"><h6 className="m-0 font-weight-bold text-primary text-center">{helper().translate("LIST_OF_ITEMS")}</h6></div>
      <div className="card-body table-responsive px-2">
          <table onKeyPress={addRowEnter}  className="table table-hover text-center ">
            <thead>
                <tr>
                  <th>{helper().translate("NR")}</th>
                  <th>{helper().translate("ITEM")}</th>
                  <th>{helper().translate("DISCOUNT")+"%"}</th>
                  <th>{helper().translate("PRICE_WITH_DISCOUNT")}</th>
                  <th>{helper().translate("PRICE_WITHOUT_VAT")}</th>
                  <th>{helper().translate("VAT")+"%"}</th>
                  <th>{helper().translate("PRICE_WITH_VAT")}</th>
                  <th>{helper().translate("ADD")}</th>
                  <th>{helper().translate("DELETE")}</th>
                </tr>
            </thead>
            <tbody>
            {/* search ? transactionFilter :  */}
                { transaction.map((item,key)=> (
                    <tr key={key}>
                        <td>{item.no}</td>
                        {item.editMode ?  <td>
                            <Select
                            name="item"
                            placeholder=""
                            components={{ Option: itemOption }}
                            className={transaction[item.no-1]?.validate == false ? transaction[item.no-1]?.itemId === 0 ? " is-invalid-s ": ''   : ''}
                            menuPosition={"fixed"}
                            styles={multipleItemStyle}
                            options={items}
                            onChange={(e, obj) => selectChange(e, obj , item.no)}
                            value={items?.find(p => p.value == transaction[item.no-1]?.itemId) || ''}
                            />
                        </td> : <td onClick={() => rowClick(item.no)}>
                        <span  className={item?.itemId === 0 ? item?.validate === false ? " text-center text-danger small ": 'text-center text-dark small'   : 'text-center text-dark small'}>{item.itemId > 0 ? items?.find(p => p.value == transaction[item.no-1]?.itemId)?.name: helper().translate("CHOOSE_ITEM")}</span>
                    
                        </td> }
                        

                      { item.editMode ? <td >
                            <MDBInput
                            name="discount"
                            className={transaction[item.no-1]?.validate == false ? transaction[item.no-1]?.discount === 0 ? " form-control-sm text-center is-invalid mb-0 ": 'form-control-sm text-center'   : 'form-control-sm text-center'}
                            onChange={(e) => fieldChange(e , item.no)}
                            type="number"
                            value= { helper().toFixed2('discount',transaction[item.no-1]?.discount) }
                            />
                        </td> : <td onClick={() => rowClick(item.no)}>{item.discount?.toFixed(2) } </td>}

                      { item.editMode ? <td >
                            <MDBInput
                            name="discountPrice"
                            className={transaction[item.no-1]?.validate == false ? transaction[item.no-1]?.discountPrice === 0 ? " form-control-sm text-center is-invalid mb-0 ": 'form-control-sm text-center'   : 'form-control-sm text-center'}
                            onChange={(e) => fieldChange(e , item.no)}
                            type="number"
                            value= { helper().toFixed2('discountPrice',transaction[item.no-1]?.discountPrice) }
                            />
                        </td> : <td onClick={() => rowClick(item.no)}>{item.discountPrice?.toFixed(2)} </td>}

                        { item.editMode ? <td >
                            <MDBInput
                            name="price"
                            className={transaction[item.no-1]?.validate == false ? transaction[item.no-1]?.price === 0 ? " form-control-sm text-center is-invalid mb-0 ": 'form-control-sm text-center'   : 'form-control-sm text-center'}
                            onChange={(e) => fieldChange(e , item.no)}
                            type="number"
                            value= { helper().toFixed2('price', transaction[item.no-1]?.price) }
                            />
                        </td> : <td onClick={() => rowClick(item.no)}>{item.price?.toFixed(2)} </td>}

                        { item.editMode ? <td >
                            <MDBInput
                            name="vat"
                            className="form-control-sm text-center"
                            onChange={(e) => fieldChange(e , item.no)}
                            disabled={true}
                            type="number"
                            value= { helper().toFixed2('vat', transaction[item.no-1]?.vat) }
                            />
                        </td> : <td onClick={() => rowClick(item.no)}>{item.vat?.toFixed(2)} </td>}

                        { item.editMode ? <td >
                            <MDBInput
                            name="priceWithVat"
                            className={transaction[item.no-1]?.validate == false ? transaction[item.no-1]?.priceWithVat === 0 ? " form-control-sm text-center is-invalid mb-0 ": 'form-control-sm text-center'   : 'form-control-sm text-center'}
                            onChange={(e) => fieldChange(e , item.no)}
                            type="number"
                            value= {  helper().toFixed2('priceWithVat', transaction[item.no-1]?.priceWithVat)   }
                            />
                        </td> : <td onClick={() => rowClick(item.no)}>{item.priceWithVat?.toFixed(2)} </td>}

                       
                        {transaction[item.no-1]?.addButton ? <td ><button className="btn-edit pt-1" onClick={addNewRow}><span className="icon text-white-40"><i className="fas fa-plus-circle fa-lg"></i></span></button></td> : <td></td> }
                        {/* edit btn */} 
                        <td ><button className="btn-delete pt-1" onClick={() => alertnOnDelete(item.id, item.no)}><span className="icon text-white-40">
                        <i className="fas fa-minus-circle fa-lg"></i>
                        </span></button></td> 
                        
                    </tr>
                ))}
            </tbody>
            {/* <tfoot className="mb-2 mt-2 table-borderless no-colors">
              
              <tr>
              <td colSpan="6"></td>
                <td className="font-weight-bold align-middle">{helper().translate("TOTAL")}:</td>
                <td ><input style={{width: '99%'}} type="number" disabled defaultValue="0" className="form-control text-center pb-2 " value={totalDebit?.toFixed(2)} ></input></td>
                <td><input style={{width: '99%'}} type="number" disabled defaultValue="0" className="form-control text-center pb-2" value={totalCredit?.toFixed(2)} ></input></td>
              </tr>
            </tfoot> */}
          </table>
        </div>
        </div>
      </div>
    </div>
  );
}

export default ItemDiscount;
