class Item {
    constructor(obj){
        if(obj){
            this.accountA = obj.accountA;
            this.accountB = obj.accountB;
            this.accountC = obj.accountC;
            this.akciza = obj.akciza;
            this.assembled = obj.assembled;
            this.barcode = obj.barcode;
            this.categoryId = obj.categoryId;
            //this.changedAt = obj.changedAt;
            this.changedBy = obj.changedBy;
            //this.createdAt = obj.createdAt;
            this.createdBy = obj.createdBy;
            this.description = obj.description;
            this.devaluation = obj.devaluation;
            this.duty = obj.duty;
            this.expense = obj.expense;
            this.expired = obj.expired;
            this.id = obj.id;
            this.inventoryItem = obj.inventoryItem;
            this.itemGoods = obj.itemGoods;
            this.itemName = obj.itemName;
            this.material = obj.material;
            this.producerId = obj.producerId;
            this.productNo = obj.productNo;
            this.production = obj.production;
            this.retailPrice = obj.retailPrice;
            this.service = obj.service;
            this.sid = obj.sid;
            this.status = obj.status;
            this.unitId = obj.unitId;
            this.vat = obj.vat;
        }
        else {
            this.accountA =  0;
            this.accountB =  0;
            this.accountC =  0;
            this.akciza =  0;
            this.assembled =  null;
            this.barcode =  '';
            this.categoryId =  0;
            //this.changedAt =  "2018-04-04T09:26:38.990Z";
            this.changedBy =  '';
            //this.createdAt =  "2008-01-21T00:00:00.000Z";
            this.createdBy =  null;
            this.description =  '';
            this.devaluation =  null;
            this.duty =  0;
            this.expense =  false;
            this.expired =  false;
            this.id =  0;
            this.inventoryItem =  false;
            this.itemGoods =  false;
            this.itemName =  '';
            this.material =  false;
            this.producerId =  0;
            this.productNo =  '';
            this.production =  false;
            this.retailPrice =  0;
            this.service =  false;
            this.sid =  0;
            this.status =  0;
            this.unitId =  0;
            this.vat =  0;
        }
    }
}
// (accountA, accountB, accountC, akciza, assembled, barcode, categoryId, changedBy, createdBy, description, devaluation, duty, expense, expired, id, inventoryItem, itemGoods, itemName, material, producerId, productNo, production, retailPrice, service,sid, status. unitId, vat)

export default Item







  // var offer = new Sale();
  // offer.date = new Date();
  
  // createSaleFromOffer(offer){
//   var sale = new Sale(offer);
//   sale.sateType = 1;

//   var savedSale = await apiService.post('sales',sale);
//