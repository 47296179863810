import React, { useState, useEffect , useRef } from "react";
import Select from "react-select";
import { MDBInput } from "mdb-react-ui-kit";
import apiService from "../../../Services/apiServices";
import { useToasts } from "react-toast-notifications";
import Search from "../../../PageComponents/Search/Search_Old";
import globals from "../../../Services/globals";
import { confirmAlert } from "react-confirm-alert";
import helper from "../../../Services/helper";
import calculate from "../../../Services/calculate";
import Pagination from "../../../PageComponents/UI/Pagination/Pagination";
import SelectByGroup from './../../../PageComponents/UI/Inputs/SelectByGroup'
import ExcelExport from "../../../PageComponents/Excel/ExcelExport";
import Print from "../../../PageComponents/Print/Print";
import DataTable from "../../../PageComponents/UI/Tables/DataTable";
import LoaderComponent from "../../../PageComponents/UI/Components/LoaderComponent";



function ItemsWithSalePrice(props) {
    const [findByDate , setFindByDate] = useState({itemId: null , warehouseId: null , dateStart: null, dateEnd:null , dateStartDisabled: true , dateEndDisabled: true });
    const [items, setItems] = useState([]);
    const [pageOfItems, setPageOfItems] = useState([]);
    const [find, setFind] = useState([]);
    const [branches , setBranches] = useState([]);
    const [warehouse ,setWarehouse] = useState([]);
    const [dataShow, setDataShow] = useState(true);
    const [itemsTotal, setItemsTotal] = useState(0);
    const { addToast } = useToasts();
    const excelExportRef = useRef();
    const [loader , setLoader] = useState(false);
    const printRef = useRef();
    const searchObj = { CompanyBranch: 0, PurchaseType: -1, StartDate: findByDate.dateStart, EndDate: findByDate.dateEnd, WarehouseId: 0}


    useEffect(() => {
      getDate();
      getWarehouses();
      getBranches();
      apiService()
      .get('settings')
          .then((response) => {
              if (response.success) {
              } else {
                  console.log('Error:' + response.errorMessage);
              }
          });   
  },[])

  const exportToExcel = () => {
    // let table = document.getElementById('table-to-xls-1').innerHTML;
    let fileName = helper().translate("LIST_OF_ITEMS_WITH_SALE_PRICE");
    // return helper().exportToExcel(table, fileName)
    excelExportRef.current.exportToExcel(fileName)
}

const print = () => {
  // window.open(`#/excel-print?CompanyBranch=${searchQuery.CompanyBranch}&YEAR=${searchQuery.YEAR}&StartMonth=${searchQuery.StartMonth}&EndMonth=${searchQuery.EndMonth}`, 'popUpWindow', 'height=1300,width="100%",left=100,top=100,right=100,bottom=100,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes')
  //setOpen(!open)
  printRef.current.printPreview()
}

const headers = [
  { key: 'nr', columnName: helper().translate("NR"), dontShowMenu : true  },
  { key: 'productNo', columnName: helper().translate("PRODUCT_NUMBER") },
  { key: 'barcode', columnName: helper().translate("BARCODE") },
  { key: 'itemName', columnName: helper().translate("ITEMNAME"), type:"text" },
  { key: 'unit', columnName: helper().translate("UNIT") },
  { key: 'quantity', columnName: helper().translate("ACTUAL_QUANTITY"), noPrint: true , type:"number"},
  { key: 'salePrice', columnName: helper().translate("PURCHASE_PRICE"), noPrint: true , type:"number"},
  { key: 'retailPrice', columnName: helper().translate("PRICE_WITHOUT_VAT") , type:"number"},
  { key: 'withVatPrice', columnName: helper().translate("PRICE_WITH_VAT") , type:"number"},
  { key: 'total', columnName: helper().translate("TOTAL") , calculate: false, noPrint: true  , type:"number"}
];

  const getItemsInStock =( dateEnd ,  warehouseId) =>{
    setLoader(true);
    let warehouse = warehouseId;
    if(warehouse == null){
      warehouse = 0;
    }
    var params = `&dateEnd=${dateEnd}&warehouseId=${warehouse}`;
    apiService()
    .query('RptItemProfitabilityList', params )
      .then((response) => {
        if (response.success) {
          setLoader(false);
          var items = response.data;
          var count = 1;
          let filterItems  =items.filter(i => i.quantity > 0)
          filterItems.forEach(i => {
            i.withVatPrice = (i.retailPrice * ((i.vat/100)+1));
            i.nr = count++;
          });
          if(filterItems.length > 0){
            setFind(filterItems);
            setItems( filterItems);
            setDataShow(true)
          }else{
            setDataShow(false)
          }
        } else {
          console.log('Error:' + response.errorMessage);
        }
      });
  }

  const getDate = () => {
    var getDate = new Date();
        var dateNow = new Date().toISOString().substring(0,10);
        var time = getDate.getHours() + ':' + getDate.getMinutes() + ':' + getDate.getSeconds();
        var dateTimeNow = dateNow+" " + time;
        let day = `${getDate.getDate()}`.padStart(2, "0")
        let month = `${getDate.getMonth()}`.padStart(2, "0");
        var dateTimeStart = ((getDate.getFullYear()+"-"+"01"+"-"+ "01"))+ ' ' + "00:00:00";

        let currentUserId = globals().getLogedInUser().data.username;
        apiService().query('user',  'userName='+currentUserId )
        .then((response) => {
          if (response.success) {
            let warehouseId = response.data[0].warehouseId;
            setFindByDate({...findByDate , dateStart: dateTimeStart , dateEnd: dateTimeNow , warehouseId: warehouseId});
            getItemsInStock( dateTimeNow , warehouseId)
    
          } else {
            console.log('Error: getting user cashbox' + response.errorMessage);
          }
        });
  }




    const getWarehouses = () => {
      apiService()
        .get("warehouses")
        .then((response) => {
          if (response.success) {
            let data = response.data;
            setWarehouse(data);
          } else {
            alert("Error: getting warehouses" + response.errorMessage);
          }
        });
  }

  const getBranches = () => {
    apiService().get('branches')
        .then((response)=> {
            if(response.success) {
                let records = response.data;
                setBranches(records);
            }
        })
}

    const searchBtn = () => {

      getItemsInStock( findByDate.dateEnd , findByDate.warehouseId)
   
    }

    const selectStyles = {
        control: (provided, state) => ({
          ...provided,
          background: "#fff",
          borderColor: "#9e9e9e",
          minHeight: "28.59px",
          height: "28.59px",
          boxShadow: state.isFocused ? null : null,
        }),
    
        valueContainer: (provided, state) => ({
          ...provided,
          height: "28.59px",
          padding: "0 6px",
        }),
    
        input: (provided, state) => ({
          ...provided,
          margin: "0px",
        }),
        indicatorSeparator: (state) => ({
          display: "none",
        }),
        indicatorsContainer: (provided, state) => ({
          ...provided,
          height: "28.59px",
        }),
      };
    const fieldChange = (e) => {
        if(e.target.name === "dateStart"){
            setFindByDate({...findByDate , dateStart: e.target.value });
        }else
        if(e.target.name === "dateEnd"){ 
          setFindByDate({...findByDate , dateEnd: e.target.value });
         
      }
    };

    const selectChange = (e,obj) => {
        if (obj.name === "item"){
            setFindByDate({...findByDate, itemId:e.value})
        }else if (obj.name === "warehouseId"){
          setFindByDate({...findByDate, warehouseId:e.value})
        }
      };

      const findChange = (e) => {
        if (e.target.value == "") {
        setFind(items);
        }
        var searchItems;
        let searchString = e.target.value.trim().toLowerCase();
    
        if (searchString.length > 0) {
          // We are searching. Filter the results.
          //searchItems = searchItems.filter((e) => e.ItemName.toLowerCase().match(searchString));
          searchItems = items.filter((item) => {
            return (
              item?.itemName?.toString().toLowerCase().match(searchString) ||
              item?.barcode?.toString().toLowerCase().match(searchString) ||
              item?.productNo?.toString().toLowerCase().match(searchString) 
            );
          });
          if(searchItems.length > 0){
            setFind(searchItems);
          }else{
            setFind(items);
          }
        }
      };

    return (
        <div className="container-fluid px-2 mt-3">
          <Search  change={(e) => findChange(e)} excel={() => exportToExcel()} printClick={print} back="#/reports"/>
          <ExcelExport data={find.length ? find : []} headers={headers} type={'other'} ref={excelExportRef} hideContent={true} />
          <Print data={find.length ? find : []} headers={headers}  ref={printRef} query={searchObj} reportName={helper().translate("LIST_OF_ITEMS_WITH_SALE_PRICE")} />
          <div className="card border-left-primary shadow h-100 mt-1">
            <div className="card-body pt-3 pb-1">
            <div className="row">
                <div className="col-11">
                  <div className="row no-gutters align-items-center">
                    <div className="col row align-items-center">
                      <div className="col-lg-4 mb-3 mb-md-0 pe-0 align-items-center ">
                        {/* <label className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                              Data e Mbarimit
                            </label> */}
                        <div className="pt-1">
                          <MDBInput
                            name="dateEnd"
                            disabled={!findByDate.dateEndDisabled}
                            className="form-control-sm active "
                            onChange={(e) => fieldChange(e)}
                            type="date"
                            label={helper().translate("DATE")}
                            value={findByDate.dateEnd == null ? '' : findByDate.dateEnd.substring(0,10)}
                          />
                        </div>
                      </div>
                      <div style={{paddingTop: "2px"}} className="col-lg-4 mb-3 mb-md-0 pe-0 ">
                        {/* <label className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                          Depo
                          </label> */}
                        {/* <Select
                          name="warehouseId"
                          placeholder="Depo"
                          styles={selectStyles}
                          options={warehouse}
                          onChange={(e, obj) => selectChange(e, obj)}
                          value={warehouse?.find(w => w.value == findByDate.warehouseId)}
                          
                        /> */}
                          <SelectByGroup 
                          name="warehouseId"
                          parent={branches} 
                          child={warehouse} 
                          childParentId={"branchId"} 
                          firstItemLabel={helper().translate("ALL_WAREHOUSES")}
                          placeholder={"Depo"}
                          styles={selectStyles}
                          onChange={(e, obj) => selectChange(e, obj)}
                          value={findByDate.warehouseId}
                          />
                      </div>
                    </div>
                  </div>
                </div>
                  <div className="col-md-1 col-12 d-flex align-items-center justify-content-center  justify-content-lg-end">
                        <div>
                          <a className="searchbutton" onClick={() => searchBtn()}>
                            <i className="fas fa-search fa-2x text-dark"/>
                          </a>
                        </div>
                  </div>
              </div>
            </div>
        </div>
        <div className="card shadow mt-1">
        <div className="card-header py-2"><h6 className="m-0 font-weight-bold text-primary text-center">{helper().translate("LIST_OF_ITEMS_WITH_SALE_PRICE")     }</h6></div>
          {/* {dataShow ? <div className="card-body table-responsive">
            <table className="table table-hover text-center ">
              <thead>
                <tr>
                  <th className="align-middle">{helper().translate("NR")}</th>
                  <th className="align-middle">{helper().translate("PRODUCT_NUMBER")}</th>
                  <th className="align-middle">{helper().translate("BARCODE")}</th>
                  <th className="align-middle">{helper().translate("ITEMNAME")}</th>
                  <th className="align-middle">{helper().translate("UNIT")}</th>
                  <th className="align-middle">{helper().translate("AVG_PRICE")}</th>
                  <th className="align-middle">{helper().translate("PRICE_WITHOUT_VAT")}</th>
                  <th className="align-middle">{helper().translate("PRICE_WITH_VAT")}</th>
                </tr>
              </thead>
              <tbody>
                {pageOfItems.map((item, key) => (
                  <tr key={key}>
                    <td style={{width: "50px"}}>{item.nr}</td>
                    <td className="text-left ps-5 ">{item.productNo}</td>
                    <td className="text-left ps-5 ">{item.barcode}</td>
                    <td className="text-left ps-5 ">{item.itemName}</td>
                    <td className="text-left ps-5 ">{item.unit}</td>
                    <td className="text-left ps-5 ">{ helper().formatNumber(item.avgPrice) }</td>
                    <td className="text-right pe-3">{ helper().formatNumber(item.retailPrice) }</td>
                    <td className="text-right pe-3">{helper().formatNumber(item.withVatPrice) }</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <Pagination items={find} onChangePage={(pageOfItems) => setPageOfItems(pageOfItems)} />
          </div>
          : 
          <div style={{height: '300px'}} className=" d-flex align-items-center justify-content-center  "><div><p>{helper().translate("NO_DATA")}</p></div></div>
          } */}
          {loader && <LoaderComponent /> } 
          {!loader ? find.length ? <DataTable data={find} headers={headers} formName="itemsWithSalePriceRpt" /> : <div className="p-5 text-center">{helper().translate("NO_DATA")}</div> : "" }
        </div>
        </div>

    )
}

export default ItemsWithSalePrice;
