import React, { useState, useEffect ,useRef } from "react";
import Select from "react-select";
import { MDBInput } from "mdb-react-ui-kit";
import apiService from "../../../Services/apiServices";
import { useToasts } from "react-toast-notifications";
import Search from "../../../PageComponents/Search/Search_Old";
import { confirmAlert } from "react-confirm-alert";
import helper from "../../../Services/helper";
import calculate from "../../../Services/calculate";
import Pagination from "../../../PageComponents/UI/Pagination/Pagination";
import SelectByGroup from '../../../PageComponents/UI/Inputs/SelectByGroup';
import ExcelExport from "../../../PageComponents/Excel/ExcelExport";
import Print from "../../../PageComponents/Print/Print";
import LoaderComponent from "../../../PageComponents/UI/Components/LoaderComponent";



function AccountsPayable(props) {
    const [findByDate , setFindByDate] = useState({ warehouseId: null , dateStart: null, dateEnd:null , dateStartDisabled: true , dateEndDisabled: true });
    const [items, setItems] = useState([]);
    const [pageOfItems, setPageOfItems] = useState([]);
    const [find, setFind] = useState([]);
    const [branches , setBranches] = useState([]);
    const [warehouse ,setWarehouse] = useState([]);
    const [dataShow, setDataShow] = useState(true);
    const [totals, setTotals] = useState({startSaldo: 0 , debits: 0 , credits: 0 , saldo: 0});
    const { addToast } = useToasts();
    const excelExportRef = useRef();
    const [loader , setLoader] = useState(false);
    const printRef = useRef();
    const searchObj = { CompanyBranch: 0, PurchaseType: -1, StartDate: helper().formatDate(findByDate.dateStart,'MM-dd-yyyy'), EndDate: helper().formatDate(findByDate.dateEnd,'MM-dd-yyyy') , WarehouseId: findByDate.warehouseId}



    useEffect(() => {
      setLoader(true);
      getDate();
      getWarehouses();
      getBranches();
  },[])

  const exportToExcel = () => {
    // let table = document.getElementById('table-to-xls-1').innerHTML;
    let fileName = helper().translate("ACCOUNTS_PAYABLE");
    // return helper().exportToExcel(table, fileName)
    excelExportRef.current.exportToExcel(fileName)
}

const print = () => {
  // window.open(`#/excel-print?CompanyBranch=${searchQuery.CompanyBranch}&YEAR=${searchQuery.YEAR}&StartMonth=${searchQuery.StartMonth}&EndMonth=${searchQuery.EndMonth}`, 'popUpWindow', 'height=1300,width="100%",left=100,top=100,right=100,bottom=100,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes')
  //setOpen(!open)
  printRef.current.printPreview()
}

const headers = [
  { key: 'countNumber', columnName: helper().translate("NR") },
  { key: 'partnerName', columnName: helper().translate("PARTNER") , type: "text"},
  { key: 'startSaldo', columnName: helper().translate("START_SALDO"), calculate: true , type: "number"},
  { key: 'debits', columnName: helper().translate("DEBIT"), calculate: true , type: "number"},
  { key: 'credits', columnName: helper().translate("CREDIT") , calculate: true , type: "number"},
  { key: 'saldo', columnName: "Saldo", calculate: true , type: "number" }
];


  const getPartnerCartsPayable =(warehouseId, dateStart , dateEnd  ) =>{
    setLoader(true);
    let strStartDate = helper().formatDate(dateStart,'MM-dd-yyyy');
    let strEndDate = helper().formatDate(dateEnd,'MM-dd-yyyy') + " 23:59:59"
    let warehouse = warehouseId;
    if(warehouse == null){
      warehouse = 0;
    }
    var params = `&warehouseId=${warehouse}&dateStart=${strStartDate}&dateEnd=${strEndDate}`;
    apiService()
    .query('RptPartnerCartsPayable', params )
      .then((response) => {
        if (response.success) {
          var count = 1;
          var data = response.data;
          totals.startSaldo = 0;
          totals.credits = 0;
          totals.debits = 0;
          totals.saldo = 0;
          data.forEach(e=> {
            e.countNumber=count++;
            totals.startSaldo = totals.startSaldo + parseFloat(e.startSaldo);
            totals.credits = totals.credits + parseFloat(e.credits);
            totals.debits = totals.debits + parseFloat(e.debits);
            totals.saldo = totals.saldo + parseFloat(e.saldo);
          });
            setFind(data);
            setItems( data);
            setLoader(false)
            setDataShow(data.length > 0  ? true : false);
        
        } else {
          console.log('Error:' + response.errorMessage);
        }
      });
  }

  const getDate = () => {
        // var getDate = new Date();
        // let day = `${getDate.getDate()}`.padStart(2, "0")
        // let month = `${getDate.getMonth()}`.padStart(2, "0");
        // var dateNow = new Date().toISOString().substring(0,10);
        // var time = getDate.getHours() + ':' + getDate.getMinutes() + ':' + getDate.getSeconds();
        // var dateTimeNow = dateNow+" " + time;
        // var dateTimeStart = ((getDate.getFullYear()+"-"+"01"+"-"+ "01"));

        let dateTimeNow = new Date();
        let dateTimeStart = new Date(dateTimeNow.getFullYear(),0,1,1); 
        setFindByDate({...findByDate , dateStart: dateTimeStart , dateEnd: dateTimeNow});
        getPartnerCartsPayable( 0,dateTimeStart , dateTimeNow )
  }

    // console.log(new Date().getUTCFullYear())
    // const getWarehouses = () => {
    //   let options;
    //     apiService()
    //       .get("warehouses")
    //       .then((response) => {
    //         if (response.success) {
    //           let data = response.data;
    //           let firstItem = {id: null , name: helper().translate("ALL_WAREHOUSES")};
    //           data.unshift(firstItem);
    //           options = data.map((item, index) => ({
    //             value: item.id,
    //             label: item.name
    //           }));
    //           setWarehouse(options)
    //         } else {
    //           alert("Error: getting warehouses" + response.errorMessage);
    //         }
    //       });
    // }

    const getWarehouses = () => {
      apiService()
        .get("warehouses")
        .then((response) => {
          if (response.success) {
            let data = response.data;
            setWarehouse(data);
          } else {
            alert("Error: getting warehouses" + response.errorMessage);
          }
        });
  }

  const getBranches = () => {
    apiService().get('branches')
        .then((response)=> {
            if(response.success) {
                let records = response.data;
                setBranches(records);
            }
        })
}

    const searchBtn = () => {

      
        let dateStart = findByDate.dateStart;
        let dateEnd = findByDate.dateEnd;
        
        // if(findByDate.dateStartDisabled == false){
        //   let dates = new Date(dateEnd).getFullYear();
        //   dateStart = `${dates}-01-01 00:00:00`;
        // }else if (findByDate.dateEndDisabled == false){
        //   dateEnd = "2222-01-01";
        // }

      // let strStartDate = helper().formatDate(dateStart,'MM-dd-yyyy');
      // let strEndDate = helper().formatDate(dateEnd,'MM-dd-yyyy') + " 23:59:59"
      
      if(!findByDate.dateStartDisabled) dateStart = `01-01-${new Date().getFullYear()}`;
      if(!findByDate.dateEndDisabled) dateEnd = "2222-01-01";

        getPartnerCartsPayable( findByDate.warehouseId ,dateStart , dateEnd )
    }

    const selectStyles = {
        control: (provided, state) => ({
          ...provided,
          background: "#fff",
          borderColor: "#9e9e9e",
          minHeight: "28.59px",
          height: "28.59px",
          boxShadow: state.isFocused ? null : null,
        }),
    
        valueContainer: (provided, state) => ({
          ...provided,
          height: "28.59px",
          padding: "0 6px",
        }),
    
        input: (provided, state) => ({
          ...provided,
          margin: "0px",
        }),
        indicatorSeparator: (state) => ({
          display: "none",
        }),
        indicatorsContainer: (provided, state) => ({
          ...provided,
          height: "28.59px",
        }),
      };
    const fieldChange = (e) => {
        if(e.target.name === "dateStart"){
            setFindByDate({...findByDate , dateStart: e.target.value  });
        }else
        if(e.target.name === "dateEnd"){ 
          setFindByDate({...findByDate , dateEnd: e.target.value + " " + "23:59:59" });
         
      }
    };

    const selectChange = (e,obj) => {
        if (obj.name === "partner"){
            setFindByDate({...findByDate, partnerId:e.value})
        }else if (obj.name === "warehouseId"){
          setFindByDate({...findByDate, warehouseId:e.value})
        }
      };

      const findChange = (e) => {
        if (e.target.value == "") {
        setFind(items);
        }
        var searchItems;
        let searchString = e.target.value.trim().toLowerCase();
    
        if (searchString.length > 0) {
          // We are searching. Filter the results.
          //searchItems = searchItems.filter((e) => e.ItemName.toLowerCase().match(searchString));
          searchItems = items.filter((item) => {
            return (
              item?.partnerName?.toString().toLowerCase().match(searchString) 
            );
          });
          if(searchItems.length > 0){
            setFind(searchItems);
          }else{
            setFind(items);
          }
        }
      };

     
    return (
        <div className="container-fluid px-2 mt-3">
          <Search  change={(e) => findChange(e)} excel={() => exportToExcel()}  printClick={print} back="#/reports"/>
          <ExcelExport data={find.length ? find : []} headers={headers} type={'other'} ref={excelExportRef} hideContent={true} />
          <Print data={find.length ? find : []} headers={headers}  ref={printRef} query={searchObj} reportName={helper().translate("ACCOUNTS_PAYABLE")} />
          <div className="card border-left-primary shadow h-100 mt-1">
            <div className="card-body pt-3 pb-1">
            <div className="row">
                <div className="col-11">
                  <div className="row no-gutters align-items-center">
                    <div className="col row">
                      <div className="col-lg-3 mb-3 mb-md-0 pe-0 d-flex align-items-center">
                        {/* <label className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                              Data e fillimit
                            </label> */}
                          <div style={{width: '5%'}}>
                            <input type="checkbox" name="includeStartDate" checked={findByDate.dateStartDisabled} onChange={(e) => setFindByDate({...findByDate, dateStartDisabled: e.target.checked})} ></input>
                          </div>
                          <div style={{width: '95%'}}>
                            <MDBInput
                            name="dateStart"
                            disabled={!findByDate.dateStartDisabled}
                            className="form-control-sm active"
                            onChange={(e) =>  setFindByDate({...findByDate , dateStart: new Date(e.target.value)})}
                            type="date"
                            label={helper().translate("START_DATE")}
                            value={helper().isValidDate(findByDate.dateStart)  ?  findByDate.dateStart.toISOString().substring(0,10): ''}
                            />
                          </div>
                      </div>
                      <div className="col-lg-3 mb-3 mb-md-0 pe-0 d-flex align-items-center">
                        {/* <label className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                              Data e Mbarimit
                            </label> */}
                        <div style={{width: '5%'}}>
                            <input type="checkbox" name="includeStartDate" checked={findByDate.dateEndDisabled}  onChange={(e) => setFindByDate({...findByDate, dateEndDisabled: e.target.checked})} ></input>
                        </div>
                        <div style={{width: '95%'}}>
                          <MDBInput
                            name="dateEnd"
                            disabled={!findByDate.dateEndDisabled}
                            className="form-control-sm active"
                            onChange={(e) =>  setFindByDate({...findByDate , dateEnd: new Date(e.target.value)})}
                            type="date"
                            label={helper().translate("END_DATE")}
                            value={helper().isValidDate(findByDate.dateEnd)  ?  findByDate.dateEnd.toISOString().substring(0,10): ''}
                          />
                        </div>
                      </div>
                      <div style={{paddingTop: "2px"}} className="col-lg-3 mb-3 mb-md-0 pe-0 ">
                        {/* <label className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                          Depo
                          </label> */}
                        {/* <Select
                          name="warehouseId"
                          placeholder="Depo"
                          styles={selectStyles}
                          options={warehouse}
                          onChange={(e, obj) => selectChange(e, obj)}
                          value={warehouse?.find(w => w.value == findByDate.warehouseId)}
                          
                        /> */}
                          <SelectByGroup 
                          name="warehouseId"
                          parent={branches} 
                          child={warehouse} 
                          childParentId={"branchId"} 
                          firstItemLabel={helper().translate("ALL_WAREHOUSES")}
                          placeholder={"Depo"}
                          styles={selectStyles}
                          onChange={(e, obj) => selectChange(e, obj)}
                          value={findByDate.warehouseId}
                          />
                      </div>
                    </div>
                  </div>
                </div>
                  <div className="col-md-1 col-12 d-flex align-items-center justify-content-center  justify-content-lg-end">
                        <div>
                          <a className="searchbutton" onClick={() => searchBtn()}>
                            <i className="fas fa-search fa-2x text-dark"  />
                          </a>
                        </div>
                  </div>
              </div>
            </div>
        </div>
        <div className="card shadow mt-1">
        <div className="card-header py-2"><h6 className="m-0 font-weight-bold text-primary text-center">{helper().translate("ACCOUNTS_PAYABLE")}</h6></div>
        {loader && <LoaderComponent /> } 
        {!loader ? dataShow ? <div className="card-body table-responsive">
            <table className="table table-hover text-center ">
              <thead>
                <tr>
                  <th className="align-middle">{helper().translate("NR")}</th>
                  <th className="align-middle">{helper().translate("PARTNER")}</th>
                  <th className="align-middle">{helper().translate("START_SALDO")}</th>
                  <th className="align-middle">{helper().translate("DEBIT")}</th>
                  <th className="align-middle">{helper().translate("CREDIT")}</th>
                  <th className="align-middle">Saldo</th>
                </tr>
              </thead>
              <tbody>
                {pageOfItems.map((item, key) => (
                  <tr key={key}>
                    <td style={{width: "50px"}}>{item.countNumber}</td>
                    <td className="text-left ps-5 ">{item.partnerName}</td>
                    <td className="text-right pe-5 ">{helper().formatNumber(item.startSaldo)}</td>
                    <td className="text-right pe-5 ">{helper().formatNumber(item.debits)}</td>
                    <td className="text-right pe-4 ">{helper().formatNumber(item.credits) }</td>
                    <td className="text-right pe-5 ">{helper().formatNumber(item.saldo) }</td>
                  </tr>
                ))}
              </tbody>
              <tfoot className="mb-2 mt-2 table-borderless no-colors">
              <tr>
              <td colSpan="1"></td>
                <td className="font-weight-bold align-middle">{helper().translate("TOTAL")}:</td>
                <td><input style={{width: '99%'}} type="text" disabled defaultValue="0" className="form-control text-center pb-2" value={helper().formatNumber(totals.startSaldo)} ></input></td>
                <td><input style={{width: '99%'}} type="text" disabled defaultValue="0" className="form-control text-center pb-2" value={helper().formatNumber(totals.debits)} ></input></td>
                <td><input style={{width: '99%'}} type="text" disabled defaultValue="0" className="form-control text-center pb-2" value={helper().formatNumber(totals.credits)} ></input></td>
                <td><input style={{width: '99%'}} type="text" disabled defaultValue="0" className="form-control text-center pb-2" value={helper().formatNumber(totals.saldo)} ></input></td>
              </tr>
            </tfoot>
            </table>
            <Pagination items={find} onChangePage={(pageOfItems) => setPageOfItems(pageOfItems)} />
          </div>
          : 
          <div style={{height: '300px'}} className=" d-flex align-items-center justify-content-center  "><div><p>{helper().translate("NO_DATA")}</p></div></div>: ""
          }
        </div>
        </div>

    )
}

export default AccountsPayable;
