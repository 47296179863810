import React, { useState, useEffect, useRef } from 'react';
import { HashRouter as Router, Route, Switch, Link } from 'react-router-dom';
import SubNav from '../../PageComponents/Nav/SubNav';
import DataTable from '../../PageComponents/UI/Tables/DataTable';
import apiService from '../../Services/apiServices';
import { confirmAlert } from 'react-confirm-alert'; 
import { ToastProvider, useToasts } from 'react-toast-notifications';
import Expense from './Expense';

import helper from '../../Services/helper';
import globals from '../../Services/globals';
import Search from '../../PageComponents/Search/Search_Old';
import LoaderComponent from '../../PageComponents/UI/Components/LoaderComponent';
import { MDBInput } from 'mdb-react-ui-kit';
import ExcelExport from '../../PageComponents/Excel/ExcelExport';
import Select from 'react-select';

const Expenses = (props) => {
    const { addToast } = useToasts();
    const [defaults, setDefaults] = useState(1)
    const [purchases, setPurchases] = useState()
    const [partners, setPartners] = useState()
    const [purchaseTypes, setPurchaseTypes] = useState(3)
    const [payMethods, setPayMethods] = useState()
    const [warehouses, setWarehouses] = useState()
    const [search, setSearch] = useState();
    const [loader, setLoader] = useState(true)
    const localPurchases = [1, 2, 3, 7];
    const logedInUser = globals().getLogedInUser();
    const [userSettings, setUserSettings] = useState();
    const excelExportRef = useRef();
        
    const styles = {
        container: provided => ({
            ...provided,
            Width: '100%'
        }),
        control: (provided, state) => ({
            ...provided,
            background: '#fff',
            borderColor: '#9e9e9e',
            minHeight: '28.59px',
            height: '28.59px',
            boxShadow: state.isFocused ? null : null,
        }),
        menuPortal: base => ({ ...base, zIndex: 9999 }),
        valueContainer: (provided, state) => ({
            ...provided,
            height: '28.59px',
            padding: '0 6px'
        }),

        input: (provided, state) => ({
            ...provided,
            margin: '0px',
        }),
        indicatorSeparator: state => ({
            display: 'none',
        }),
        indicatorsContainer: (provided, state) => ({
            ...provided,
            height: '28.59px',
        }),
    };

    const t = (key) => {
        return helper().translate(key);
    }
    const searchObj = {
        dateStart: new Date(new Date().getFullYear(),new Date().getMonth(),new Date().getDate()-30),
        dateEnd: new Date(), 
        purchaseTypeId: props.location.search ? parseInt(props.location.search.replace('?type=', '')) : -1,
        includeStartDate: true,
        includeEndDate: true
    }

    const [searchQuery, setSearchQuery] = useState(searchObj);

    const tables = [
        { name: 'partners', method: setPartners },
        { name: 'purchaseType', method: setPurchaseTypes },
        { name: 'paymentMethod', method: setPayMethods },
        { name: 'warehouses', method: setWarehouses },
    ];
    const links = [
        {path:"expense/:id", component: Expense, type:"link"},
        {path:"expenses", name: t('PURCHASE_OF_EXPENSES'), icon:"fas fa-fw fa-layer-group mr-2", component: '' },
    ]

    const headers = [
        { key: 'date', columnName: t('DATE') },
        { key: 'partner', columnName: t('COMPANY_NAME')  , type:"text" },
        { key: 'fiscalNo', columnName: t('FISCAL_NUMBER') },
        { key: 'comment', columnName: t('COMMENT')  , type:"comment" },
        { key: 'paymentMethod', columnName: t('PAYMENT_METHOD')  , type:"text"},
        // { key: 'purchaseType', columnName: t('PURCHASE_TYPE')  , type:"text" },
        { key: 'vatSum', columnName: t('VAT_VALUE') , type:'number'  },
        { key: 'totalSum', columnName: t('TOTAL_SUM') , type:'number' },
        { key: 'totalWithVat', columnName: t('TOTAL_WITH_VAT') , type:'number'  },
        { key: 'createdAt', columnName: helper().translate('REGISTRATION_DATE')   },
        { key: 'createdBy', columnName: helper().translate('REGISTERED_BY') ,type:"text"  },
        { key: 'changedAt', columnName: helper().translate('DATE_OF_MODIFICATION')   },
        { key: 'changedBy', columnName: helper().translate('MODIFIED_BY'), type:"text"  }
    ]

    const getPurchasesFromTo = (type) => {
        var getDate = new Date();
        let startDate = searchQuery.dateStart, endDate = searchQuery.dateEnd, purchaseTypeId = 3; 
        
        let strStartDate = helper().formatDate(startDate,'MM-dd-yyyy');
        let strEndDate = helper().formatDate(endDate,'MM-dd-yyyy') + " 23:59:59" 
        if(!searchQuery.includeStartDate) strStartDate = `01-01-${getDate.getFullYear()}`;
        if(!searchQuery.includeEndDate) strEndDate = null;


        if(type) setSearchQuery({...searchQuery, purchaseTypeId: type}) 
        
        var params = `dateStart=${strStartDate}&dateEnd=${strEndDate}&purchaseTypeId=3&import=0`;
        apiService().query('purchasesFromTo', params).then((response) => {
            if(response.success){ 
                response.data.forEach(e =>{;
                    e.createdAt = helper().formatDate(e.createdAt);
                    if(e.changedAt) {
                      e.changedAt = helper().formatDate(e.changedAt);
                    }
                  })           
                let records = type ? response.data.filter(record => record.purchaseTypeId === type) : response.data
                // let records = response.data
                setDatas(records)
                setPurchases(records)
                searchResult(records)
                setLoader(false)
            }
        })
    }

    const setDatas = (records) => {
        if(!records) records = purchases
        records?.forEach(record => {
            record.date ? record.date = helper().formatDate(record.date, 'yyyy-MM-dd') : '';
            record.withVat ? record.withVat = 'Po' : record.withVat = 'Jo';
            record.partnerId ? record.partner = partners?.find(p => p.id === record.partnerId)?.companyName : record.partnerId = '-';
            record.partnerId ? record.fiscalNo = partners?.find(p => p.id === record.partnerId)?.fiscalNo : record.partnerId = '-';
            record.warehouseId ? record.warehouse = warehouses?.find(w => w.id === record.warehouseId)?.name : record.warehouseId = '-';
            //record.purchaseTypeId ? record.purchaseType = purchaseTypes?.find(w => w.value === record.purchaseTypeId)?.label : record.purchaseType = '-';
        });
    }

    const getDefaults = (name, method) => {
        apiService().get(name).then((response) => {
            if(response.success){
                let records = response.data
                name === 'purchaseType' ? records = records.map(record => ({ value: record.id, label: helper().translate(`${record.name}`)})) : 0
                if(name === "purchaseType"){
                    let firstItem = {value: -1 , label: helper().translate("EXPENSES") };
                    records.unshift(firstItem);
                }
                method(records) 
                setDefaults(defaults + 1)
            }
        })
    }

    const getUserSettings = () => {
        globals().getUserSettings(logedInUser).then((response) => {
            setUserSettings(response)
        });
    }

    const exportToExcel = () => {
        let fileName = helper().translate("EXPENSES");
        excelExportRef.current.exportToExcel(fileName)
    }

    const edit = (item) => {
        window.location.href = `#/expense/${item.id}`
    }

    const print = (item) => {
        if(localPurchases.includes(item.purchaseTypeId)) {
            window.open(`#/print-purchase/${item.id}`, 'popUpWindow','height=1300,width="100%",left=100,top=100,right=100,bottom=100,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes')
        }
        else {
            responseMessage(true, `Dizajni për këtë lloj të faturës së shpejti do të jetë në funksion`, 'info' )
        }
    }

    const remove = (id) => {
        confirmAlert({
            customUI: ({ onClose }) => {
              return (
                <div className="custom-ui" >
                  <h1>A jeni te sigurt?</h1>
                  <p>Dëshironi të fshini këtë shpenzim?</p>
                  <button
                    onClick={() => {
                        apiService().query('deletePurchases', `id=${id}`)
                        .then((response) => {
                            if (response.success) {
                                  apiService().query("getPaymentPurchases", "purchaseId="+id).then((response) => {
                                    if(response.success){
                                      if(response.data.length != 0){
                                        let paymentId = response.data[0].paymentId;
                                        let deletePaymentPurchases = {paymentId: paymentId , purchaseId: id}
                                        apiService().execute('deletePaymentPurchases', deletePaymentPurchases , 'post').then((response) => {
                                          if(response.success){
                                          console.log("fshirja u be me sukses");
                                          }})
                                      }
                                    }
                                  })
                                  responseMessage(response.success, 'Delete Successfully')
                                  //getPurchases(parseInt(props.location.search ? props.location.search.replace('?type=', '') : ''))
                                  getPurchasesFromTo(parseInt(props.location.search ? props.location.search.replace('?type=', '') : ''))
                              } else {
                                console.log('Error:' + response.errorMessage);
                              }
                        })
                        onClose();
                    }}
                  >
                    Po , Fshije!
                  </button>
                  <button onClick={onClose}>Jo</button>
                </div>
              );
            }
        })   
    }

    const responseMessage = (success, message, appearance) => {
        if (success) {
            addToast(message, { appearance: appearance ? appearance : 'success', autoDismiss: true, });
        }
        else {
            addToast('Ka ndodhur nje gabim!', { appearance: 'error', autoDismiss: true, });
        }
    }

    const searchResult = (records) => {
        let searchItems = records ? records : purchases,
        searchString = search?.trim().toLowerCase();
        if (searchString?.length > 0) {
            searchItems = searchItems.filter(item => {
                return ( 
                    item?.partner?.toString().toLowerCase().match(searchString) ||
                    item?.purchaseType?.toString().toLowerCase().match(searchString) ||
                    item?.warehouse?.toLowerCase().match(searchString) ||
                    item?.invoiceNo?.toLowerCase().match(searchString) ||
                    item?.reference?.toLowerCase().match(searchString) 
                )
            });
            return searchItems
        }
        else {
            return searchItems
        }
    }

    useEffect(() => {
        setLoader(true)
        tables.forEach((table, index) => {
            getDefaults(table.name, table.method)
        })
        getPurchasesFromTo()
        getUserSettings()
    }, [])

    useEffect(() => {
        setDatas()
    }, [purchases])

    useEffect(() => {
        setLoader(true)
        getPurchasesFromTo(parseInt(props.location.search.replace('?type=', '')))
    }, [props.location])

    return (
        <div className="d-flex">
            <div className="p-0 position-relative sub-menu">
                <SubNav links={links} />
            </div>
            <div className="w-100 overflow-auto overflow-auto pr-0">
                <Router>
                    <Switch>
                        {links.map((link, index)=>{
                            return <Route key={index} path={`/${link.path}`} component={link.component} />
                        })}
                    </Switch>
                </Router>
                <div className="container-fluid px-2 px-0 mt-3">
                    <Search back="#/" new={`#/expense/0`} excel={() => exportToExcel()} change={(e) => setSearch(e.target.value)}/>
                    <ExcelExport data={searchResult() ? searchResult() : []} headers={headers} type={'other'} ref={excelExportRef} hideContent={true} />
                    <div className="card border-left-primary shadow h-100 mt-1">
                        <div className="card-body pt-3 pb-1">
                        <div className="row">
                            <div className="col-md-11 col-12">
                            <div className="row no-gutters align-items-center">
                                <div className="col row">
                                <div className="col-lg-3 mb-3 mb-md-0 pe-0 d-flex align-items-center">
                                    <div style={{width: '5%'}}>
                                        <input type="checkbox" name="includeStartDate" checked={searchQuery.includeStartDate ? 'checked': ''} onChange={(e) => setSearchQuery({...searchQuery, includeStartDate: e.target.checked})}></input>
                                    </div>
                                    <div style={{width: '95%'}}>
                                        <MDBInput
                                            name="dateStart"
                                            disabled = {!searchQuery.includeStartDate ? 'disabled': ''}
                                            className="form-control-sm active"
                                            onChange={(e) => setSearchQuery({...searchQuery, dateStart: new Date(e.target.value)})}
                                            type="date"
                                            label={helper().translate("START_DATE")}
                                            value={helper().isValidDate(searchQuery.dateStart)  ?  searchQuery.dateStart?.toISOString().substring(0,10): ''}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-3 mb-3 mb-md-0 pe-0 d-flex align-items-center">
                                    <div style={{width: '5%'}}>
                                        <input type="checkbox" name="includeEndDate" checked={searchQuery.includeEndDate ? 'checked': ''} onChange={(e) => setSearchQuery({...searchQuery, includeEndDate: e.target.checked})}></input>
                                    </div>
                                    <div style={{width: '95%'}}>
                                        <MDBInput
                                            name="dateEnd"
                                            disabled = {!searchQuery.includeEndDate ? 'disabled': ''}
                                            className="form-control-sm active"
                                            onChange={(e) => setSearchQuery({...searchQuery, dateEnd: new Date(e.target.value)})}
                                            type="date"
                                            label={helper().translate("END_DATE")}
                                            value={helper().isValidDate(searchQuery.dateEnd)  ?  searchQuery.dateEnd?.toISOString().substring(0,10): ''}
                                        />
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                            <div className="col-md-1 col-12 d-flex align-items-center justify-content-center  justify-content-lg-end">
                            <div>
                                <a className="searchbutton" onClick={() => getPurchasesFromTo()}>
                                <i className="fas fa-search fa-2x text-dark" />
                                </a>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="card shadow mt-1">
                        <div className="card-header py-2">
                            <h6 className="m-0 font-weight-bold text-primary">{t('EXPENSES')}</h6>
                        </div>  
                        {loader && <LoaderComponent  /> }                      
                        {!loader ? purchases?.length ? <DataTable showEdit={true} data={searchResult() ? searchResult() : []} headers={headers} headersFrom={'EXPENSES'} print={print} edit={edit} delete={remove} formName="Purchases"/> : <div className="p-5 text-center">{helper().translate("NO_DATA")}</div> : ''}                    
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Expenses
