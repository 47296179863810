import React, {useEffect, useState, forwardRef, useRef, useImperativeHandle} from 'react';
// import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import apiService from '../../Services/apiServices';
import AtkPurchaseBookHeader from '../../components/Reports/ReportATK/AtkPurchaseBookHeader';
import AtkSaleBookHeader from '../../components/Reports/ReportATK/AtkSaleBookHeader';
import { read, utils, writeFileXLSX } from 'xlsx';

const ExcelExport = forwardRef((params, ref) => {
    const [data, setData] = useState([])
    const [fileName, setFileName] = useState()

    const getData = (params) => {
        apiService().query('RptAtkPurchaseBook', params)
            .then((response) => {
                if(response.success) {
                    setData(response.data)
                    //params.data = response.data
                }
            })
    }

    const renderHeader = (headers) => {
        return headers.map((header, index) => {
            return header ? <th key={index} style={{textAlign: 'left', fontSize: '9pt', fontFamily: 'Arial', backgroundColor: '#f0f0f0'}}>{header.columnName}</th> : 0
        });
    }

    const renderRow = (row) => {       
        var headers = filterHeaders(params?.headers)
        return headers.map((header, index) => {
            return header ? <td key={index}>{row[header.name] ? row[header.name] : params?.zeroToNull === false ? row[header.key] : row[header.key] !== 0 ? row[header.key] : " " }</td> : 0
        });
    }

    const renderRows = function (rows) {   
        return rows?.map((row, index) => {
            return <tr key={index} style={{textAlign: 'center', fontSize: '9pt', fontFamily: 'Arial'}}>
                { renderRow(row)}
            </tr>
        })
    }

    const filterHeaders = (headers) => {
        return headers.filter(h => h !== "");
    }

    useImperativeHandle(ref, () => ({

        exportToExcel(fileName) {
            var htmls = "";
    
            var table= document.getElementById('print') ? document.getElementById('print') : document.getElementById('table-to-xls-1');
            htmls = table?.outerHTML;
  
            var uri = 'data:application/vnd.ms-excel;charset=utf-8'
            
            var link = document.createElement("a");
            link.download = fileName +".xls";
            // link.href = uri + escape(htmls)
            // link.href = uri + encodeURIComponent(htmls);
            link.href='data:text/csv;charset=utf-8,%EF%BB%BF'+encodeURIComponent(htmls);
            link.click();
        },

        // exportToExcel(type, fn, dl) {
        //     var elt = document.getElementById('print');
        //     var wb = XLSX.utils.table_to_book(elt, { sheet: "sheet1" });
        //     return dl ?
        //         XLSX.write(wb, { bookType: type, bookSST: true, type: 'base64' }) :
        //         XLSX.writeFile(wb, fn || ('MySheetName.' + (type || 'xlsx')));
        // }

        // exportToExcel1() {
        //     const elt = document.getElementById('table-to-xls-13') //document.getElementById('print') ? document.getElementById('print') : document.getElementById('table-to-xls-1');
            
        //     const wb = utils.table_to_book(elt);
        //     writeFileXLSX(wb, "SheetJSReactHTML.xlsx");
        // }

    }));



    useEffect(() => {
      let pm = params?.location?.search
      pm ? getData(pm.substring(1)) : setData(params?.data)
      if(pm) {
        getData(pm.substring(1))
        let fileName = (pm.split("&")[2]).split('=').pop()+"_"+(pm.split("&")[2]).split('=').pop()+"_"+(pm.split("&")[1]).split('=').pop();
        setFileName(fileName)
      }
      else {
        params.fileName ? setFileName(params.fileName) : 0 
        setData(params?.data)
      }
    }, []);
    

    return (
        <div className={`p-5 ${params.hideContent ? 'd-none': ''}`} style={{overflowX: 'scroll'}}>
            <table id="table-to-xls-1">
                {params?.type === 'purchase' && <AtkPurchaseBookHeader />}
                {params?.type === 'sale' && <AtkSaleBookHeader />}
                {params?.type === 'other' && renderHeader(filterHeaders(params?.headers))}
                {renderRows(params?.data)}
            </table>
        </div>
    )
});

export default ExcelExport;
