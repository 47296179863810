import React, { useState, useEffect ,useRef } from "react";
import Select from "react-select";
import { MDBInput } from "mdb-react-ui-kit";
import apiService from "../../../Services/apiServices";
import { useToasts } from "react-toast-notifications";
import Search from "../../../PageComponents/Search/Search_Old";
import { confirmAlert } from "react-confirm-alert";
import helper from "../../../Services/helper";
import calculate from "../../../Services/calculate";
import Pagination from "../../../PageComponents/UI/Pagination/Pagination";
import SelectByGroup from '../../../PageComponents/UI/Inputs/SelectByGroup';
import ExcelExport from "../../../PageComponents/Excel/ExcelExport";
import Print from "../../../PageComponents/Print/Print";
import DataTable from "../../../PageComponents/UI/Tables/DataTable";
import LoaderComponent from "../../../PageComponents/UI/Components/LoaderComponent";



function SupplierCard(props) {
    const [findByDate , setFindByDate] = useState({partnerId: null , warehouseId: null , dateStart: null, dateEnd:null , dateStartDisabled: true , dateEndDisabled: true });
    const [items, setItems] = useState([]);
    const [pageOfItems, setPageOfItems] = useState([]);
    const [find, setFind] = useState([]);
    const [partners , setPartners] = useState([]);
    const [branches , setBranches] = useState([]);
    const [warehouse ,setWarehouse] = useState([]);
    const [dataShow, setDataShow] = useState(true);
    const [loader , setLoader] = useState(false);
    const { addToast } = useToasts();
    const excelExportRef = useRef();
    const printRef = useRef();
    const searchObj = { CompanyBranch: 0, PurchaseType: -1, StartDate: helper().formatDate(findByDate.dateStart,'MM-dd-yyyy'), EndDate: helper().formatDate(findByDate.dateEnd,'MM-dd-yyyy') , WarehouseId: findByDate.warehouseId , partnerName: {partner: partners?.find(a=> a.value == findByDate.partnerId)?.label , label:"SUPPLIER" , fiscalNo: partners?.find(a=> a.value == findByDate.partnerId)?.fiscalNo}}


    useEffect(() => {
      setLoader(false);
      getDate();
      getParnters();
      getWarehouses();
      getBranches();
  },[])

  const exportToExcel = () => {
    // let table = document.getElementById('table-to-xls-1').innerHTML;
    let fileName = helper().translate("SUPPLIER_CARD");
    // return helper().exportToExcel(table, fileName)
    excelExportRef.current.exportToExcel(fileName)
}

const print = () => {
  // window.open(`#/excel-print?CompanyBranch=${searchQuery.CompanyBranch}&YEAR=${searchQuery.YEAR}&StartMonth=${searchQuery.StartMonth}&EndMonth=${searchQuery.EndMonth}`, 'popUpWindow', 'height=1300,width="100%",left=100,top=100,right=100,bottom=100,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes')
  //setOpen(!open)
  printRef.current.printPreview()
}

const headers = [
  { key: 'countNumber', columnName: helper().translate("NR") , dontShowMenu : true  },
  { key: 'date', columnName: helper().translate("DATE") },
  // { key: 'partnerName', columnName: helper().translate("PARTNER_NAME")  },
  { key: 'documentNo', columnName: helper().translate("DOCUMENT_NR") , type:"text" },
  { key: 'comment', columnName: helper().translate("DESCRIPTION") , type:"comment" },
  { key: 'typeId', columnName: helper().translate("TYPE") },
  { key: 'cashBox', columnName: helper().translate("CASHBOX") , type:"text" },
  { key: 'bank', columnName: helper().translate("BANK") , type:"text" },
  { key: 'debits', columnName: helper().translate("DEBIT") , calculate: true , type:"number" },
  { key: 'credits', columnName: helper().translate("CREDIT") , calculate: true , type:"number" },
  { key: 'saldo', columnName: "Saldo" ,  calculate: ['debits','credits']  , type:"number" }
];


  const getPartnerCartPayable =(partnerId , dateStart , dateEnd , warehouseId) =>{
    setLoader(true);
    let warehouse = warehouseId;
    if(warehouse == null){
      warehouse = 0;
    }
    let strStartDate = helper().formatDate(dateStart,'MM-dd-yyyy');
    let strEndDate = helper().formatDate(dateEnd,'MM-dd-yyyy') + " 23:59:59"
    var params = `&partnerId=${partnerId}&dateStart=${strStartDate}&dateEnd=${strEndDate}&warehouseId=${warehouse}`;
    apiService()
    .query('RptPartnerCartPayable', params )
      .then((response) => {
        if (response.success) {
          var count = 1;
          var data = response.data;
          var previusSaldo = 0.00;
          data.forEach(e=> {
            let inOut = e.debits - e.credits;
            e.saldo = ( parseFloat(inOut)  + parseFloat(previusSaldo));
            e.countNumber=count++;
            e.typeId = helper().translate(e.typeId);
            e.date = helper().formatDate(e.date);
            e.debits =  e.debits;
            e.credits = e.credits;
            previusSaldo = e.saldo;
            if(e.comment === ""){
              e.comment = helper().translate(e.transactionName) ;
            }
          });
            setFind(data);
            setItems( data);
            setLoader(false);
            setDataShow(data.length > 0  ? true : false);
        } else {
          console.log('Error:' + response.errorMessage);
        }
      });
  }

  const getDate = () => {
        let dateTimeNow = new Date();
        let dateTimeStart = new Date(dateTimeNow.getFullYear(),0,1,1); 

        setFindByDate({...findByDate , dateStart: dateTimeStart , dateEnd: dateTimeNow});
  }

   const getParnters = () => {
     let options;
      apiService().get('partners').then((response) => {
        if (response.success) {
          let data = response.data;
          let suplierFilter = data.filter(f => f.supplier === true)
          options = suplierFilter.map((item, index) => ({
            value: item.id,
            label: item.companyName,
            fiscalNo: item.fiscalNo
          }));
          setPartners(options)
        } else {
          console.log('Error:' + response.errorMessage);
        }
      });
    }
    // console.log(new Date().getUTCFullYear())
    // const getWarehouses = () => {
    //   let options;
    //     apiService()
    //       .get("warehouses")
    //       .then((response) => {
    //         if (response.success) {
    //           let data = response.data;
    //           let firstItem = {id: null , name: helper().translate("ALL_WAREHOUSES")};
    //           data.unshift(firstItem);
    //           options = data.map((item, index) => ({
    //             value: item.id,
    //             label: item.name
    //           }));
    //           setWarehouse(options)
    //         } else {
    //           alert("Error: getting warehouses" + response.errorMessage);
    //         }
    //       });
    // }

    const getWarehouses = () => {
      apiService()
        .get("warehouses")
        .then((response) => {
          if (response.success) {
            let data = response.data;
            setWarehouse(data);
          } else {
            alert("Error: getting warehouses" + response.errorMessage);
          }
        });
  }

  const getBranches = () => {
    apiService().get('branches')
        .then((response)=> {
            if(response.success) {
                let records = response.data;
                setBranches(records);
            }
        })
}

    const searchBtn = () => {

      if(findByDate.partnerId != null){
        let dateStart = findByDate.dateStart;
        let dateEnd = findByDate.dateEnd;
        
        if(!findByDate.dateStartDisabled) dateStart = `01-01-${new Date().getFullYear()}`;
        if(!findByDate.dateEndDisabled) dateEnd = "2222-01-01";
        getPartnerCartPayable(findByDate.partnerId ,dateStart , dateEnd , findByDate.warehouseId)
      }else{
        addToast(helper().translate("CHOOSE_PARTNER"), {
                    appearance: "error",
                    autoDismiss: true,
                  });
      }
    }

    const selectStyles = {
        control: (provided, state) => ({
          ...provided,
          background: "#fff",
          borderColor: "#9e9e9e",
          minHeight: "28.59px",
          height: "28.59px",
          boxShadow: state.isFocused ? null : null,
        }),
    
        valueContainer: (provided, state) => ({
          ...provided,
          height: "28.59px",
          padding: "0 6px",
        }),
    
        input: (provided, state) => ({
          ...provided,
          margin: "0px",
        }),
        indicatorSeparator: (state) => ({
          display: "none",
        }),
        indicatorsContainer: (provided, state) => ({
          ...provided,
          height: "28.59px",
        }),
      };
    const fieldChange = (e) => {
        if(e.target.name === "dateStart"){
            setFindByDate({...findByDate , dateStart: e.target.value  });
        }else
        if(e.target.name === "dateEnd"){ 
          setFindByDate({...findByDate , dateEnd: e.target.value });
         
      }
    };

    const selectChange = (e,obj) => {
        if (obj.name === "partner"){
            setFindByDate({...findByDate, partnerId:e.value})
        }else if (obj.name === "warehouseId"){
          setFindByDate({...findByDate, warehouseId:e.value})
        }
      };

      const findChange = (e) => {
        if (e.target.value == "") {
        setFind(items);
        }
        var searchItems;
        let searchString = e.target.value.trim().toLowerCase();
    
        if (searchString.length > 0) {
          // We are searching. Filter the results.
          //searchItems = searchItems.filter((e) => e.ItemName.toLowerCase().match(searchString));
          searchItems = items.filter((item) => {
            return (
              item?.documentNo?.toString().toLowerCase().match(searchString) ||
              item?.typeId?.toString().toLowerCase().match(searchString) ||
              item?.description?.toString().toLowerCase().match(searchString) 
            );
          });
          if(searchItems.length > 0){
            setFind(searchItems);
          }else{
            setFind(items);
          }
        }
      };

     
    return (
        <div className="container-fluid px-2 mt-3">
          <Search  change={(e) => findChange(e)} excel={() => exportToExcel()} printClick={print} back="#/reports"/>
          <ExcelExport data={find.length ? find : []} headers={headers} type={'other'} ref={excelExportRef} hideContent={true} />
          <Print data={find.length ? find : []} headers={headers}  ref={printRef} query={searchObj} reportName={helper().translate("SUPPLIER_CARD")} />
          <div className="card border-left-primary shadow h-100 mt-1">
            <div className="card-body pt-3 pb-1">
            <div className="row">
                <div className="col-11">
                  <div className="row no-gutters align-items-center">
                    <div className="col row">
                      <div className="col-lg-3 mb-3 mb-md-0 pe-0 d-flex align-items-center">
                        {/* <label className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                              Data e fillimit
                            </label> */}
                          <div style={{width: '5%'}}>
                            <input type="checkbox" name="includeStartDate" checked={findByDate.dateStartDisabled} onChange={(e) => setFindByDate({...findByDate, dateStartDisabled: e.target.checked})} ></input>
                          </div>
                          <div style={{width: '95%'}}>
                            <MDBInput
                            name="dateStart"
                            disabled={!findByDate.dateStartDisabled}
                            className="form-control-sm active"
                            onChange={(e) =>  setFindByDate({...findByDate , dateStart: new Date(e.target.value)})}
                            type="date"
                            label={helper().translate("START_DATE")}
                            value={helper().isValidDate(findByDate.dateStart)  ?  findByDate.dateStart.toISOString().substring(0,10): ''}
                            />
                          </div>
                      </div>
                      <div className="col-lg-3 mb-3 mb-md-0 pe-0 d-flex align-items-center">
                        {/* <label className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                              Data e Mbarimit
                            </label> */}
                        <div style={{width: '5%'}}>
                            <input type="checkbox" name="includeStartDate" checked={findByDate.dateEndDisabled}  onChange={(e) => setFindByDate({...findByDate, dateEndDisabled: e.target.checked})} ></input>
                        </div>
                        <div style={{width: '95%'}}>
                          <MDBInput
                            name="dateEnd"
                            disabled={!findByDate.dateEndDisabled}
                            className="form-control-sm active"
                            onChange={(e) =>  setFindByDate({...findByDate , dateEnd: new Date(e.target.value)})}
                            type="date"
                            label={helper().translate("END_DATE")}
                            value={helper().isValidDate(findByDate.dateEnd)  ?  findByDate.dateEnd.toISOString().substring(0,10): ''}
                          />
                        </div>
                      </div>
                      <div style={{paddingTop: "2px"}} className="col-lg-3 mb-3 mb-md-0 pe-0 ">
                        {/* <label className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                          Depo
                          </label> */}
                        <Select
                          name="partner"
                          placeholder={helper().translate("PARTNER")}
                          styles={selectStyles}
                          options={partners}
                          onChange={(e, obj) => selectChange(e, obj)}
                          value={partners?.find(a=> a.value == findByDate.partnerId)}
                        />
                      </div>
                      <div style={{paddingTop: "2px"}} className="col-lg-3 mb-3 mb-md-0 pe-0 ">
                        {/* <label className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                          Depo
                          </label> */}
                        {/* <Select
                          name="warehouseId"
                          placeholder="Depo"
                          styles={selectStyles}
                          options={warehouse}
                          onChange={(e, obj) => selectChange(e, obj)}
                          value={warehouse?.find(w => w.value == findByDate.warehouseId)}
                        /> */}
                          <SelectByGroup 
                          name="warehouseId"
                          parent={branches} 
                          child={warehouse} 
                          childParentId={"branchId"} 
                          firstItemLabel={helper().translate("ALL_WAREHOUSES")}
                          placeholder={"Depo"}
                          styles={selectStyles}
                          onChange={(e, obj) => selectChange(e, obj)}
                          value={findByDate.warehouseId}
                          />
                      </div>
                    </div>
                  </div>
                </div>
                  <div className="col-md-1 col-12 d-flex align-items-center justify-content-center  justify-content-lg-end">
                        <div>
                          <a className="searchbutton" onClick={() => searchBtn()}>
                            <i className="fas fa-search fa-2x text-dark"  />
                          </a>
                        </div>
                  </div>
              </div>
            </div>
        </div>
        <div className="card shadow mt-1">
        <div className="card-header py-2"><h6 className="m-0 font-weight-bold text-primary text-center">{helper().translate("SUPPLIER_CARD")}</h6></div>
          {/* {dataShow ? <div className="card-body table-responsive">
            <table className="table table-hover text-center ">
              <thead>
                <tr>
                  <th className="align-middle">{helper().translate("NR")}</th>
                  <th className="align-middle">{helper().translate("DATE")}</th>
                  <th className="align-middle">{helper().translate("DESCRIPTION")}</th>
                  <th className="align-middle">{helper().translate("DOCUMENT_NR")}</th>
                  <th className="align-middle">{helper().translate("TYPE")}</th>
                  <th className="align-middle">{helper().translate("CASHBOX")}</th>
                  <th className="align-middle">{helper().translate("BANK")}</th>
                  <th className="align-middle">{helper().translate("DEBIT")}</th>
                  <th className="align-middle">{helper().translate("CREDIT")}</th>
                  <th className="align-middle">Saldo</th>
                </tr>
              </thead>
              <tbody>
                {pageOfItems.map((item, key) => (
                  <tr key={key}>
                    <td style={{width: "50px"}}>{item.countNumber}</td>
                    <td className="text-left ps-5 ">{item.date}</td>
                    <td className="text-left ps-5 ">{item.description}</td>
                    <td className="text-left ps-5 ">{item.documentNo}</td>
                    <td className="text-left ps-5 ">{helper().translate(item.typeId) }</td>
                    <td className="text-left ps-5 ">{item.cashBox}</td>
                    <td className="text-left ps-3 ">{item.bank}</td>
                    <td className="text-right pe-5 ">{helper().formatNumber(item.debits)}</td>
                    <td className="text-right pe-4 ">{helper().formatNumber(item.credits)}</td>
                    <td className="text-right pe-5 ">{helper().formatNumber(item.saldo)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <Pagination items={find} onChangePage={(pageOfItems) => setPageOfItems(pageOfItems)} />
          </div>
          : 
          <div style={{height: '300px'}} className=" d-flex align-items-center justify-content-center  "><div><p>{helper().translate("NO_DATA")}</p></div></div>
          } */}
          {loader && <LoaderComponent /> } 
          {!loader ? find.length ? <DataTable data={find} headers={headers} formName="supplierCardRpt" /> : <div className="p-5 text-center">{helper().translate("NO_DATA")}</div>: "" }
        </div>
        </div>

    )
}

export default SupplierCard;
