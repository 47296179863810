import React from 'react';

const AtkPurchaseBookHeader = () => {

    return (
        <tbody>
            <tr height="34" style={{textAlign: 'center', lineHeight: 1, fontSize: '10pt', fontFamily: 'Arial', fontWeight: 'bold'}}>
                <td height="34" width="39.20" rowSpan="2" style={{border:'1.0pt solid #000'}}>Nr</td>
                <td width="192" style={{border:'1.0pt solid #000'}} colspan="2">Fatura</td>
                <td width="355.93" colspan="3" style={{border:'1.0pt solid #000'}}>Shitesi</td>
                <td width="346.40" colspan="4" style={{border:'1.0pt solid #000'}}>Blerjet dhe Importet e liruara dhe me TVSH jo te zbritshme</td>
                <td width="860.27" colspan="9" style={{border:'1.0pt solid #000'}}>Blerjet dhe importet e tatushme me 18%, si dhe rregullimet e zbritjeve</td>
                <td width="842.27" colspan="9" style={{border:'1.0pt solid #000'}}>Blerjet dhe importet e tatushme me 8%, si dhe rregullimet e zbritjeve</td>
                <td width="95.93" rowSpan="2" style={{border:'1.0pt solid #000', background: '#BFBFBF'}}>Totali i TVSH-se se zbritshme me 18% dhe 8%</td>
            </tr>
            <tr style={{height: '97px', textAlign: 'center', fontSize: '9pt', fontFamily: 'Arial'}}>
                <td style={{border:'1.0pt solid #000', padding: 0, marggin: 0}}>Data</td>
                <td style={{border:'1.0pt solid #000', padding: 0, marggin: 0}}>Numri i fatures</td>
                <td style={{border:'1.0pt solid #000', padding: 0, marggin: 0}}>Emri i Shit&euml;sit</td>
                <td style={{border:'1.0pt solid #000', padding: 0, marggin: 0}}>Numri fiskal i Shit&euml;sit</td>
                <td style={{border:'1.0pt solid #000', padding: 0, marggin: 0}}>Numri i TVSH-se se shit&euml;sit</td>
                <td style={{border:'1.0pt solid #000', padding: 0, marggin: 0}}>Blerjet dhe importet pa TVSH</td>
                <td style={{border:'1.0pt solid #000', padding: 0, marggin: 0}}>Blerjet dhe importet investive pa TVSH</td>
                <td style={{border:'1.0pt solid #000', padding: 0, marggin: 0}}>Blerjet dhe importet me TVSH jo te zbritshme</td>
                <td style={{border:'1.0pt solid #000', padding: 0, marggin: 0}}>Blerjet dhe importet investive me TVSH jo te zbritshme</td>
                <td style={{border:'1.0pt solid #000', padding: 0, marggin: 0}}>Importet</td>
                <td style={{border:'1.0pt solid #000', padding: 0, marggin: 0}}>Importet investive</td>
                <td style={{border:'1.0pt solid #000', padding: 0, marggin: 0}}>Blerjet vendore</td>
                <td style={{border:'1.0pt solid #000', padding: 0, marggin: 0}}>Blerjet investive vendore</td>
                <td style={{border:'1.0pt solid #000', padding: 0, marggin: 0}}>Nota debitore e pranuar,nota kgrayi, color: 'black'tore e leshuar</td>
                <td style={{border:'1.0pt solid #000', padding: 0, marggin: 0}}>Fatura e borxhit te keq e leshuar</td>
                <td style={{border:'1.0pt solid #000', padding: 0, marggin: 0}}>Rregullimet per te ulur TVSH-ne per pagese</td>
                <td style={{border:'1.0pt solid #000', padding: 0, marggin: 0}}>E drejtae kreditimit te TVSH-se ne lidhje me ngarkesen e kundert</td>
                <td style={{border:'1.0pt solid #000', padding: 0, marggin: 0}} style={{backgroundColor: '#CFFFD3'}}>Totali i TVSH-se se zbritshme me 18%</td>
                <td style={{border:'1.0pt solid #000', padding: 0, marggin: 0}}>Importet</td>
                <td style={{border:'1.0pt solid #000', padding: 0, marggin: 0}}>Importet investive</td>
                <td style={{border:'1.0pt solid #000', padding: 0, marggin: 0}}>Blerjet vendore</td>
                <td style={{border:'1.0pt solid #000', padding: 0, marggin: 0}}>Blerjet investive vendore</td>
                <td style={{border:'1.0pt solid #000', padding: 0, marggin: 0}}>Blerjet nga fermeret(aplikimi i normes se sheshte)</td>
                <td style={{border:'1.0pt solid #000', padding: 0, marggin: 0}}>Nota debitore e pranuar,nota kreditore e leshuar</td>
                <td style={{border:'1.0pt solid #000', padding: 0, marggin: 0}}>Fatura e borxhit te keq e leshuar</td>
                <td style={{border:'1.0pt solid #000', padding: 0, marggin: 0}}>Rregullimet per te ulur TVSH-ne per pagese</td>
                <td style={{border:'1.0pt solid #000', padding: 0, marggin: 0}} style={{backgroundColor: '#CFFFD3'}}>Totali i TVSH-se se zbritshme me 8%</td>
            </tr>
            <tr style={{color: 'black', textAlign: 'center', fontSize: '9pt', fontFamily: 'Arial'}}>
                <td style={{border:'1.0pt solid #000', background: '#BFBFBF', padding: 0, marggin: 0}} colSpan={6}>Numri i kutise ne deklaraten e TVSH-se</td>
                <td style={{border:'1.0pt solid #000', background: '#BFBFBF', padding: 0, marggin: 0}}>[31]</td>
                <td style={{border:'1.0pt solid #000', background: '#BFBFBF', padding: 0, marggin: 0}}>[32]</td>
                <td style={{border:'1.0pt solid #000', background: '#BFBFBF', padding: 0, marggin: 0}}>[33]</td>
                <td style={{border:'1.0pt solid #000', background: '#BFBFBF', padding: 0, marggin: 0}}>[34]</td>
                <td style={{border:'1.0pt solid #000', background: '#BFBFBF', padding: 0, marggin: 0}}>[35]</td>
                <td style={{border:'1.0pt solid #000', background: '#BFBFBF', padding: 0, marggin: 0}}>[39]</td>
                <td style={{border:'1.0pt solid #000', background: '#BFBFBF', padding: 0, marggin: 0}}>[43]</td>
                <td style={{border:'1.0pt solid #000', background: '#BFBFBF', padding: 0, marggin: 0}}>[47]</td>
                <td style={{border:'1.0pt solid #000', background: '#BFBFBF', padding: 0, marggin: 0}}>[53]</td>
                <td style={{border:'1.0pt solid #000', background: '#BFBFBF', padding: 0, marggin: 0}}>[57]</td>
                <td style={{border:'1.0pt solid #000', background: '#BFBFBF', padding: 0, marggin: 0}}>[61]</td>
                <td style={{border:'1.0pt solid #000', background: '#BFBFBF', padding: 0, marggin: 0}}>[65]</td>
                <td style={{border:'1.0pt solid #000', background: '#BFBFBF', padding: 0, marggin: 0}}>[K1]</td>
                <td style={{border:'1.0pt solid #000', background: '#BFBFBF', padding: 0, marggin: 0}}>[37]</td>
                <td style={{border:'1.0pt solid #000', background: '#BFBFBF', padding: 0, marggin: 0}}>[41]</td>
                <td style={{border:'1.0pt solid #000', background: '#BFBFBF', padding: 0, marggin: 0}}>[45]</td>
                <td style={{border:'1.0pt solid #000', background: '#BFBFBF', padding: 0, marggin: 0}}>[49]</td>
                <td style={{border:'1.0pt solid #000', background: '#BFBFBF', padding: 0, marggin: 0}}>[51]</td>
                <td style={{border:'1.0pt solid #000', background: '#BFBFBF', padding: 0, marggin: 0}}>[55]</td>
                <td style={{border:'1.0pt solid #000', background: '#BFBFBF', padding: 0, marggin: 0}}>[59]</td>
                <td style={{border:'1.0pt solid #000', background: '#BFBFBF', padding: 0, marggin: 0}}>[63]</td>
                <td style={{border:'1.0pt solid #000', background: '#BFBFBF', padding: 0, marggin: 0}}>[K2]</td>
                <td style={{border:'1.0pt solid #000', background: '#BFBFBF', padding: 0, marggin: 0}}>[67]</td>
            </tr>
        </tbody>
    )
};

export default AtkPurchaseBookHeader;
