import React, { useState, useEffect } from "react";
import Select ,{components} from "react-select";
import { MDBInput } from "mdb-react-ui-kit";
import apiService from "../../Services/apiServices";
import { useToasts } from "react-toast-notifications";
import Search from "../../PageComponents/Search/Search_Old";
import { confirmAlert } from "react-confirm-alert";
import helper from "../../Services/helper";
import globals from "../../Services/globals";


function WarehouseTransfer(props) {
  const [items , setItems] = useState([]);
  const [warehouse , setWarehouse] = useState([]);
  const [units , setUnits] = useState([]);
  const [search , setSearch] = useState('');
  const [partnerStringLength , setPartnerStringLength] = useState(0);
  const { addToast } = useToasts();

  const mTransaction = {
    id:0,
    date: new Date(),
    fromWarehouse: null,
    toWarehouse: null,
    fromWarehouseVal: false,
    toWarehouseVal: false,
    totalSum: 0,
    comment: '',
    isProduction: 1,
    createdBy: '',
    changedAt: null,
    changedBy: null,
    isChanged: false,
    status: 0
  }

  const newTrasaction = {
    no: 1,
    id: 0,
    warehousetransferId: 0,
    itemId: 0,
    itemName: helper().translate("CHOOSE_ITEM"),
    projectId: 0,
    quantity: 0,
    unit: '',
    avgPrice: 0,
    retailPrice: 0,
    total: 0,
    createdAt: null,
    createdBy: null,
    changedAt: null,
    changedBy: null,
    addButton: true,
    editMode: false,
    validate: true,
    unChangedId: 1,
    status: 0,
  };
  const [mainTransaction , setMainTransaction] = useState({...mTransaction});
  const [transaction, setTransaction] = useState([{...newTrasaction , editMode: true}]);

  const printRow = (itemId) => {
    if(itemId) {
        //window.location.href = `#/printPurchase/${item.id}`
        window.open(`#/print-internal_movement/${itemId}`, 'popUpWindow','height=3000,width=32000,left=100,top=100,right=100,bottom=100,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes')
    }
    else {
        console.log("print isnt working right now");
    }
}

const saveOnlyOnce = () => {
  var element = document.getElementById("saveOnce");
  element.classList.toggle("disabled");
}

  const getStockItems = () => {
    let date = (new Date()).toISOString().substring(0, 10) + ' ' + new Date().getHours() + ':' + new Date().getMinutes() + ':' + new Date().getSeconds();
    var params = `&dateEnd=${date}&warehouseId=${0}`;
    apiService()
    .query('RptGetItemsInStock', params )
      .then((response) => {
        var stockItems = response.data;
        apiService().get('items').then((response) => {
          if (response.success) {
            var data = response.data;
            let itemsArr = []; 
            stockItems.forEach(e => {
             let findRow = data.find(d => d.id === e.id);
             if(findRow){
               findRow.avgPrice = e.avgPrice;
               itemsArr.push(findRow);
             }
            });
            let dataFilter = itemsArr.filter(i => i.service === false && i.expense === false);
            let options = dataFilter.map((item, index) => ({
              value: item.id,
              label: item.barcode + " - " +  item.itemName,
              name: item.itemName,
              unitId: item.unitId,
              avgPrice: item.avgPrice
            }));
            setItems(options);
          } else {
            console.log('Error:' + response.errorMessage);
          }
        });
      });
  }
  
  const getTransferItem = (id) => {
     apiService().get("WarehouseTransfers" , "id="+id).then((response) => {
          if (response.success) {
            var data = response.data[0];
            data.date = new Date(data.date);
            setMainTransaction({...data});
            apiService().get("GetDetailsBywarehousetransferId" , "warehousetransferId="+ id).then((response) => {
              if (response.success) {
                var res = response.data;
                for(var i= 0; i< res.length; i++  ){
                  if(i === (res.length - 1)) {
                    res[i].addButton = true;
                    
                    }
                  }
                res.forEach(e => {
                  e.total = (parseFloat(e.quantity) * parseFloat(e.avgPrice)).toFixed(2);
                });
                setTransaction(res);
                
              } else {
                console.log('Error:' + response.errorMessage);
              }
            });
            
          } else {
            console.log('Error:' + response.errorMessage);
          }
        });
  }

const getWarehouses = () => {
  apiService()
    .get("warehouses")
    .then((response) => {
      if (response.success) {
        let data = response.data;
          let options = data.map((item, index) => ({
            value: item.id,
            label: item.name
          }));
        setWarehouse(options);
      } else {
        alert("Error: getting warehouses" + response.errorMessage);
      }
    });
}

const getUnits = () => {
  apiService().get('units').then((response) => {
    if (response.success) {
      let data =response.data
      setUnits(data)
      
    } else {
      console.log('Error:' + response.errorMessage);
    }
  });
}
 
useEffect(()=>{
    var id = props.match.params.id;
    if(parseInt(id) > 0){
      getTransferItem(id);
    }
    getWarehouses();
    getUnits();
    getStockItems();
 },[])

const selectStyles = {
  container: provided => ({
     ...provided,
     minWidth: '190px'
   }),
   control: (provided, state) => ({
     ...provided,
     background: "#fff",
     borderColor: "#9e9e9e",
     minHeight: "28.59px",
     height: "28.59px",
     boxShadow: state.isFocused ? null : null,
   }),

   valueContainer: (provided, state) => ({...provided,height: "28.59px",padding: "0 6px",}),
   input: (provided, state) => ({ ...provided, margin: "0px",}), indicatorSeparator: (state) => ({ display: "none", }),
   indicatorsContainer: (provided, state) => ({...provided,height: "28.59px",}),
 };

 const multipleItemStyle = {
  container: provided => ({
    ...provided,
    minWidth: '190px'
  }),
  control: (provided, state) => ({
    ...provided,
    background: "#fff",
    borderColor: "#9e9e9e",
    minHeight: "28.59px",
    height: "28.59px",
    boxShadow: state.isFocused ? null : null,
  }),
  menu: (provided, state) => ({
    ...provided,
    width: "auto"
  }),
   menuList: (provided, state) => ({
    ...provided,
    width: "auto"
  }),

  valueContainer: (provided, state) => ({...provided,height: "28.59px",padding: "0 6px",}),
  input: (provided, state) => ({ ...provided, margin: "0px",}), indicatorSeparator: (state) => ({ display: "none", }),
  indicatorsContainer: (provided, state) => ({...provided,height: "28.59px",}),
};

const addButtonLastRow =  (arrTransaction) => {   
  for(var i= 0 ; i< arrTransaction.length ; i++){
    arrTransaction[i].addButton = false;
    //arrTransaction[i].editMode = false;
    if(i === (arrTransaction.length -1 )) {
      arrTransaction[i].addButton = true;
    }
  }
 }
 const filterTransactions = (searchString) => { 

  if (searchString.length > 0) {   
      let searchTransaction = transaction.filter((item) => {
      return (       
           //item?.partnerName?.toString().toLowerCase().match(searchString)
           item.partnerName === '' || item.editMode ||  item?.partnerName?.toString().toLowerCase().match(searchString)
        )
      });
   
      addButtonLastRow(searchTransaction);
      // var count = 1;
      // searchTransaction.forEach(i=> {i.no = count; count++});
      // setTransactionFilter(searchTransaction);
      return searchTransaction;
    } 
    else {
      let allTransaction = [...transaction];
      addButtonLastRow(allTransaction);
      return allTransaction;
    }
  };

  const onfieldChange = (e,no) => {
    var newObj = {...mainTransaction};
    if(e.target.name === "date"){
      var today = new Date(),
      time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
      newObj[e.target.name] = e.target.value+' '+ time;
      newObj.isChanged= true;
      setMainTransaction(newObj);
    } else{
      newObj[e.target.name] = e.target.value;
      newObj.isChanged= true;
      setMainTransaction(newObj);
    }
  };

  const onSelectChange =(e,obj) => {
    let newObj = {...mainTransaction};
    if(obj.name === "fromWarehouse"){
      if(e.value !== mainTransaction.toWarehouse){
        newObj[obj.name] = e.value;
        newObj.isChanged = true;
        newObj.fromWarehouseVal=false;
      }else{
        addToast(helper().translate("NOT_SAME_WAREHOUSE"), {
          appearance: "error",
          autoDismiss: true,
        });
      }
    }else 
    if(obj.name === "toWarehouse"){
      if(e.value !== mainTransaction.fromWarehouse){
        newObj[obj.name] = e.value;
        newObj.isChanged = true;
        newObj.toWarehouseVal=false;
      }else{
        addToast(helper().translate("NOT_SAME_WAREHOUSE"), {
          appearance: "error",
          autoDismiss: true,
        });
      }
    }
    setMainTransaction(newObj)
  }

  const selectChange = (e,obj,no) => {
    var newArr = [...transaction];
    if(obj.name === "item"){
    let transactionDetails = newArr.find(i=> i.no === no);
    let item = items.find(i=> i.value === e.value)
    transactionDetails.itemId = item.value;
    transactionDetails.quantity = 1;
    transactionDetails.unit = item.unitId;
    transactionDetails.avgPrice = item.avgPrice.toFixed(2);
    transactionDetails.retailPrice = 0;
    transactionDetails.total = item.avgPrice.toFixed(2);
    transactionDetails.validate = true;
    setTransaction(newArr);
    }
  };

  const fieldChange = (e,no) => {
    var newArr = [...transaction];
    if(e.target.name === "quantity"){
      newArr[no-1][e.target.name] =parseFloat(e.target.value);
      let total = parseFloat(e.target.value) * parseFloat(newArr[no-1].avgPrice) ;
      newArr[no-1].total = total.toFixed(2);
    }else 
    if(e.target.name === "retailPrice"){
      newArr[no-1][e.target.name] = parseFloat(e.target.value) ;
    }
    setTransaction(newArr);
  };

  const addNewRow = () => {
    var id = props.match.params.id
    let newItem = newTrasaction;
    newItem.no = Math.max(...transaction.map(o => o.no), 0) + 1;
    newItem.warehousetransferId = parseInt(id);
    
    //newItem.unChangedId = Math.max(...transaction.map(o => o.no), 0) + 1;
    newItem.status = 1;

    const newState = [...transaction, newItem];

    for(var i= 0 ; i< newState.length ; i++){
      newState[i].addButton = false;
      newState[i].editMode = false;
      if(i === (newState.length - 1)) {
        newState[i].addButton = true;
        newState[i].editMode = true;
        }
      }
      setTransaction(newState);      
  };

  const addRowEnter = () => {
    if(event.key === 'Enter'){
      addNewRow();
    }
  }


  const rowClick = (no) => {
    //enter edit mode
    let newItem = [...transaction];
    newItem.forEach(e => e.editMode = false);
    newItem[no-1].editMode = true;
    if(newItem[no-1].id > 0){
      newItem[no-1].status = 2;
    }else{
      newItem[no-1].status = 1;
    }
    setTransaction(newItem);
  }

  function onClear(){
    props.history.push("/warehouse-transfer/0");
  }


  const newHandle = () => {
    let mTrans = {...mainTransaction}
    let trans = [...transaction];
    let obj = trans.filter(e => (e.status === 2) || (e.status === 1));
    var newPressed = true;

    if(obj.length === 0 && typeof(mTrans.isChanged) === 'undefined'){
      onClear();
    }else{
      if(validateMain() && validateTransactionDetails()){
        confirmAlert({
          customUI: ({ onClose }) => {
            return (
              <div className="custom-ui" >
                <h1>{helper().translate("ARE_YOU_SURE")}</h1>
                <p>{helper().translate("WANT_TO_SAVE_ACTUAL_CHANGES")}</p>
                <button
                  onClick={() => {
                    save(newPressed);
                    onClose();
                  }}
                >
                  {helper().translate("YES")}
                </button>
                <button onClick={() =>{
                  onClear();
                  onClose();
                }} className="ms-3">{helper().translate("NO")}</button>
              </div>
            );
          }
        })
      }
    }
  }

const handleDelete = (id , no)=> {
    if(id>0){
      var removeRow =  transaction.filter((row) => row.no !== no);
      if(removeRow.length >= 1){
        apiService().delete('WarehouseTransfersDetails', "id="+id).then((response) => {
          if (response.success) {
            var tDetails =  transaction.filter((row) => row.no !== no);
            if(tDetails.length === 0 ){
            var newItem = {
              no: Math.max(...transaction.map(o => o.no), 0) + 1 ,
              id:0,
              warehousetransferId: parseInt(props.match.params.id),
              date:new Date(),
              fromWarehouse: null,
              toWarehouse: null,
              fromWarehouseVal: false,
              toWarehouseVal: false,
              totalSum: 0,
              comment: '',
              isProduction: 1,
              createdBy: '',
              changedAt: null,
              changedBy: null,
              isChanged: false,
              validate: true ,
              status: 1
            }

            tDetails.push(newItem);
            }
            for(var i= 0; i< tDetails.length; i++  ){
              if(i === (tDetails.length - 1)) {
                tDetails[i].addButton = true;
                }}
            var num = 1;
            tDetails.forEach(i=> {i.no = num; num++});
            // console.log("sdetails" , sDetails);
            var updateTransferDetails = [];
            var count = 1;
            var arr = transaction.filter((i) => i.id !== id );
            arr.forEach(i=> {i.no = count; count++});
            // console.log("Arr" , arr);
            arr.forEach(e => {
                 var contextObj = {
                  entity: e,
                  status: 2,
                  type: 'WarehouseTransfersDetails'
               }
                 updateTransferDetails.push(contextObj);
               });
              //  console.log("items to update" ,updateTransferDetails)
               apiService().saveChanges(updateTransferDetails).then((response) => {
                 if(response.success){
                   console.log("me sukses")
                 }
                  }) 
                
            setTransaction(tDetails);
          } else {
            console.log('Error:' + response.errorMessage);
          }
        });
      }else {
        addToast(helper().translate("CANT_DELETE_LAST_ROW"), {
          appearance: "error",
          autoDismiss: true,
        });
      }
    }
    
    else {
          let newItem = [...transaction];
          let arr = newItem.filter((row) => row.no !== no);
          var num = 1;
          arr.forEach(i=> {i.no = num; num++});
           for(var i= 0; i< arr.length; i++  ){
            if(i === (arr.length - 1)) {
              arr[i].addButton = true;
              }}
          if(arr.length == 0){
            setTransaction([newTrasaction]);
          }else 
          if(arr.length >0){
            setTransaction(arr);
          }
    }
    }

  const alertnOnDelete = (id , no)=> {
    if(id > 0){
      confirmAlert({
          customUI: ({ onClose }) => {
            return (
              <div className="custom-ui">
                <h1>{helper().translate("ARE_YOU_SURE")}</h1>
                <p>{helper().translate("WANT_TO_DELETE_THIS_WAREHOUSE_TRANSFER")}</p>
                <button
                  onClick={() => {
                    handleDelete(id, no);
                    onClose();
                  }}
                >
                  {helper().translate("YES")} , {helper().translate("DELETE")}!
                </button>
                <button onClick={onClose}>{helper().translate("NO")}</button>
              </div>
            );
          },
        });
    }else if( id == 0) {
      handleDelete(id, no);
    }
  }

  const validateMain = () => {
  let main = {...mainTransaction};
  if(main.fromWarehouse != null && main.toWarehouse != null){
    return true
  }else{
    addToast(helper().translate("CHOOSE_WAREHOUSE"), {
      appearance: "error",
      autoDismiss: true,
    });

    if(main.fromWarehouse === null){
      main.fromWarehouseVal = true;
    }
    if(main.toWarehouse === null){
      main.toWarehouseVal = true;
    }
    setMainTransaction(main);
  }
  }
  const validateTransactionDetails = () => {
  let details = [...transaction];
  details.forEach(e => {
    if(e.itemId === 0){
      e.validate = false
    }
  });
  let obj = details.filter(o => (o.itemId === 0) );
  if(obj.length === 0){
    return true
  }
  }
  
  const save = (newPressed) => {
    
  if(validateMain() && validateTransactionDetails()){
    var id = props.match.params.id;

    var transferItems = {...mainTransaction};
    var transferItemsDetails = [...transaction];
    var totalSum = 0;
    transferItemsDetails.forEach(e => {
    totalSum= parseFloat(e.total) + totalSum;
    });
    if(parseInt(id) === 0){
          saveOnlyOnce();
         
          transferItems.createdBy = globals().getLogedInUser().data.username;
          transferItems.totalSum = totalSum;
          // setMainTransaction({...mainTransaction , createdBy: globals().getLogedInUser().data.username})
          apiService().post('WarehouseTransfers', transferItems)
          .then((response) => {
            if(response.success){
              let resId = response.data.id;
              var insertTransferItemsDetails = [];

              transferItemsDetails.forEach(e => {
                e.warehousetransferId = resId;
                e.createdBy = globals().getLogedInUser().data.username;
                var contextObj = {
                  entity: e,
                  status: 1,
                  type: 'WarehouseTransfersDetails'
                }
                insertTransferItemsDetails.push(contextObj);
              });
              apiService().saveChanges(insertTransferItemsDetails).then((response) => {
              if(response.success){
                saveOnlyOnce();
                addToast(helper().translate("SUCCESSFULLY_ADDED"), {
                  appearance: "success",
                  autoDismiss: true,
                });
                if(newPressed !== true){
                  props.history.push("/features/warehouse-transfer/"+ resId);
                }
              }
             })
            }
            else{
              addToast(helper().translate("ERROR_SAVING"), {
                appearance: "success",
                autoDismiss: true,
              });
                  }
          });
        
    }else if(parseInt(id) >= 0){
        
        transferItems.changedBy = globals().getLogedInUser().data.username;
        transferItems.totalSum = totalSum;
        apiService().put('WarehouseTransfers', transferItems )
        .then((response) => {
          if(response.success){
            var updateTransferItemsDetails = [];
            let obj = transferItemsDetails.filter(e => (e.status === 2) || (e.status === 1));
            obj.forEach(e => {
              e.changedBy = globals().getLogedInUser().data.username;
              var contextObj = {
                entity: e,
                status: e.status,
                type: 'WarehouseTransfersDetails'
              }
              updateTransferItemsDetails.push(contextObj);
            });
            apiService().saveChanges(updateTransferItemsDetails).then((response) => {
               if(response.success){
                transferItemsDetails.forEach(e => {
                  e.status = 0
                });
               };
               
               if(newPressed !== true){
                props.history.push("/features/warehouse-transfer/"+ id);
              }
            })
        
            addToast(helper().translate("SUCCESSFULLY_MODIFIED"), {
              appearance: "success",
              autoDismiss: true,
            });
          }
          else{
            addToast(helper().translate("ERROR_MODIFIED"), {
              appearance: "success",
              autoDismiss: true,
            });
                  }
        });
    }
  }
  };
  const itemOption = props => {
    if(props.data.label.length > partnerStringLength){
      setPartnerStringLength(props.data.label.length);
    }
    return (
      <components.Option {...props}>
        <div className="d-flex text-truncate"><p style={{width: `${partnerStringLength * 7}px`}} className="m-0 text-left ">{props.data.label}</p></div>
      </components.Option>
    );
  };

  
  return (
    <div id="addTransaction">
      <div className="container-fluid px-2 mt-3">
        <Search headersFrom={"WAREHOUSE_TRANSFER"} search={false} new="#/features/warehouse-transfer/0" newHandle={newHandle} save={save} back="#/features/warehouse-transfers" printClick={parseInt(props.match.params.id) > 0 ? () => printRow(mainTransaction.id) : ""} />
        <div className="card border-left-primary shadow h-100 mt-1">
          <div className="card-body pt-1 pb-1">
              <div className="row mt-2" >

                  <div className="col-md-3 col-12 my-md-0 my-1">
                    <div className="row">
                        <div>
                            <div style={{width: '100%'}}>
                                <MDBInput
                                name="date"
                                className="form-control-sm active"
                                onChange={(e) =>  setMainTransaction({...mainTransaction , date: new Date(e.target.value) , isChanged: true})}
                                type="date"
                                label={helper().translate("START_DATE")}
                                value={helper().isValidDate(mainTransaction?.date)  ?  mainTransaction?.date.toISOString().substring(0,10): ''}
                                />
                            </div>
                        </div>
                    </div>
                  </div>

                  <div className="col-md-3 col-12 my-md-0 my-1">
                    <div className="row">
                      <div>
                          <Select
                            name="fromWarehouse"
                            placeholder={helper().translate("FROM_WAREHOUSE")}
                            styles={selectStyles}
                            className={mainTransaction.fromWarehouseVal == true ? " is-invalid-s ": '' }
                            options={warehouse}
                            onChange={(e, obj) => onSelectChange(e, obj)}
                            value={warehouse?.find(w => w.value == mainTransaction.fromWarehouse)}
                          />
                        </div>
                    </div>
                  </div>

                  <div className="col-md-3 col-12 my-md-0 my-1">
                    <div className="row">
                      <div >
                          <Select
                            name="toWarehouse"
                            placeholder={helper().translate("TO_WAREHOUSE")}
                            styles={selectStyles}
                            className={mainTransaction.toWarehouseVal == true ? " is-invalid-s ": '' }
                            options={warehouse}
                            onChange={(e, obj) => onSelectChange(e, obj)}
                            value={warehouse?.find(w => w.value == mainTransaction.toWarehouse)}
                          />
                      </div>
                    </div>
                  </div> 
              </div>
        </div>
        <div className="row mb-2">
          <div className="col-md-9 col-12">
            <div className="text-box ps-3 pe-md-0 pe-3 ">
              <label className="text-xs font-weight-bold text-primary mb-0">
              {helper().translate("COMMENT")}
              </label>
              <br></br>
              <textarea 
              className="textareas form-control textArea-none " 
              name="comment"
              value={mainTransaction.comment}
              onChange={(e) => onfieldChange(e)}
              />
            </div>
          </div>
        </div>
        </div>
        <div className="card shadow h-100 mt-1">
      <div className="card-header py-2"><h6 className="m-0 font-weight-bold text-primary text-center">Transaksionet</h6></div>
      <div className="card-body table-responsive px-2">
          <table onKeyPress={addRowEnter}  className="table table-hover text-center ">
            <thead>
                <tr>
                  <th>{helper().translate("NR")}</th>
                  <th>{helper().translate("ITEM")}</th>
                  <th>{helper().translate("UNIT")}</th>
                  <th>{helper().translate("QUANTITY")}</th>
                  <th>{helper().translate("AVG_PRICE")}</th>
                  <th>{helper().translate("SALEPRICE")}</th>
                  <th>{helper().translate("TOTAL_AVGPRICE")}</th>
                  <th>{helper().translate("ADD")}</th>
                  <th>{helper().translate("DELETE")}</th>
                </tr>
            </thead>
            <tbody>
            {/* search ? transactionFilter :  */}
                { transaction.map((item,key)=> (
                    <tr key={key}>
                        <td>{item.no}</td>
                        {item.editMode ?  <td>
                            <Select
                            name="item"
                            placeholder=""
                            components={{ Option: itemOption }}
                            // className={transaction[item.no-1]?.validate == false ? transaction[item.no-1]?.paymentType === null ? " is-invalid-s ": ''   : ''}
                            menuPosition={"fixed"}
                            styles={multipleItemStyle}
                            options={items}
                            onChange={(e, obj) => selectChange(e, obj , item.no)}
                            value={items?.find(p => p.value == transaction[item.no-1]?.itemId) || ''}
                            />
                        </td> : <td onClick={() => rowClick(item.no)}>
                        <span  className={item?.itemId === 0 ? item?.validate === false ? " text-center text-danger small ": 'text-center text-dark small'   : 'text-center text-dark small'}>{item.itemId > 0 ? items?.find(p => p.value == transaction[item.no-1]?.itemId)?.name: helper().translate("CHOOSE_ITEM")}</span>
                    
                        </td> }
                        
                        { item.editMode ? <td >
                            <MDBInput
                            name="unit"
                            className="form-control-sm text-center"
                            onChange={(e) => fieldChange(e , item.no)}
                            type="text"
                            disabled
                            value={units.find(u => u.id === item.unit)?.name}
                            />
                        </td> : <td onClick={() => rowClick(item.no)}>{units.find(u => u.id === item.unit)?.name} </td>}

                      { item.editMode ? <td >
                            <MDBInput
                            name="quantity"
                            className="form-control-sm text-center"
                            onChange={(e) => fieldChange(e , item.no)}
                            type="number"
                            value= { transaction[item.no-1]?.quantity}
                            />
                        </td> : <td onClick={() => rowClick(item.no)}>{item.quantity} </td>}

                        

                        { item.editMode ? <td >
                            <MDBInput
                            name="avgPrice"
                            className="form-control-sm text-center"
                            disabled
                            type="number"
                            // disabled={transaction[item.no-1]?.D}
                            // style={{border: !transaction[item.no-1]?.D ? "green solid 3px" : ''} }
                            value= { transaction[item.no-1]?.avgPrice}
                            />
                        </td> : <td onClick={() => rowClick(item.no)}>{parseFloat(item.avgPrice).toFixed(2)} </td>}

                        { item.editMode ? <td >
                            <MDBInput
                            name="retailPrice"
                            className="form-control-sm text-center"
                            onChange={(e) => fieldChange(e , item.no)}
                            type="number"
                            // disabled={transaction[item.no-1]?.D}
                            // style={{border: !transaction[item.no-1]?.D ? "green solid 3px" : ''} }
                            value= { transaction[item.no-1]?.retailPrice}
                            />
                        </td> : <td onClick={() => rowClick(item.no)}>{item.retailPrice.toFixed(2)} </td>}

                        {item.editMode ?  <td>
                            <MDBInput
                            name="total"
                            className="form-control-sm text-center"
                            onChange={(e) => fieldChange(e , item.no)}
                            disabled
                            type="number"
                            // disabled={transaction[item.no-1]?.H}
                            // style={{border: !transaction[item.no-1]?.H ? "green solid 3px" : ''} }
                            value={transaction[item.no-1]?.total}
                            />
                        </td> : <td onClick={() => rowClick(item.no)}>{ item.total } </td> }
                       
                        {/* add btn problem itemn.no-1 */}
                        {transaction[item.no-1]?.addButton ? <td ><button className="btn-edit pt-1" onClick={addNewRow}><span className="icon text-white-40"><i className="fas fa-plus-circle fa-lg"></i></span></button></td> : <td></td> }
                        {/* edit btn */} 
                        <td ><button className="btn-delete pt-1" onClick={() => alertnOnDelete(item.id, item.no)}><span className="icon text-white-40">
                        <i className="fas fa-minus-circle fa-lg"></i>
                        </span></button></td> 
                        
                    </tr>
                ))}
            </tbody>
            {/* <tfoot className="mb-2 mt-2 table-borderless no-colors">
              
              <tr>
              <td colSpan="6"></td>
                <td className="font-weight-bold align-middle">{helper().translate("TOTAL")}:</td>
                <td ><input style={{width: '99%'}} type="number" disabled defaultValue="0" className="form-control text-center pb-2 " value={totalDebit?.toFixed(2)} ></input></td>
                <td><input style={{width: '99%'}} type="number" disabled defaultValue="0" className="form-control text-center pb-2" value={totalCredit?.toFixed(2)} ></input></td>
              </tr>
            </tfoot> */}
          </table>
        </div>
        </div>
      </div>
    </div>
  );
}

export default WarehouseTransfer;
