import React, { Component } from 'react';
import apiService from '../../Services/apiServices';
import DropDownInput from '../../PageComponents/UI/Inputs/DropDownInput';
import HeaderTools from '../../PageComponents/Header/HeaderTools';
import DataTable from '../../PageComponents/UI/Tables/DataTable';
import moment from 'moment';
import MapSelectServices from '../../Services/mapSelectServices';
import LoaderComponent from '../../PageComponents/UI/Components/LoaderComponent';
import helper from '../../Services/helper';
import Search from '../../PageComponents/Search/Search_Old'

moment().format();

class ItemsHistory extends Component {
    constructor(props) {
        super(props);

        this.state = {
            pageOfItems: [],
            itemsHistory: [],
            date: moment().locale('en').format('YYYY-MM-DD'),
            warehouseId: 0,
            itemId: 0,
            lackItems: 0,
            showSpinner:false
        };

        this.search = this.search.bind(this);
        this.exportCsv = this.exportCsv.bind(this);        
        this.onFieldChange = this.onFieldChange.bind(this);
        //this.validator = new SimpleReactValidator({autoForceUpdate: this,  messages: {date: 'Zgjedh Daten.',default: 'Validation has failed!' }});
    }

    
    componentDidMount() {
        apiService()
            .query('getItems')
            .then((response) => {
                if (response.success) {
                    this.setState({ items: response.data });
                } else {
                    console.log('Error:' + response.errorMessage);
                }
            });

        apiService()
            .query('getWarehouses')
            .then((response) => {
                if (response.success) {
                    this.setState({ warehouses: response.data });
                } else {
                    console.log('Error:' + response.errorMessage);
                }
            });
    }

    onFieldChange = (e) => {
        this.setState({ [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value });
    };

    search = () => {
        //if (this.validator.allValid()) {
        var date = this.state.date;
        var itemId = this.state.itemId || 0;
        var warehouseId = this.state.warehouseId || 0;
        var params = `date=${date}&itemId=${itemId}&warehouseId=${warehouseId}`;

        this.setState({ showSpinner: true, data :[] });

        apiService()
            .execute('getItemsHistory', params)
            .then((response) => {
                if (response.success) {
                    //calculate saldo
                    var items = response.data;
                    var saldo = 0.0;
                    var prevItem = 0;
                    for (var i = 0; i < items.length; i++) {
                        if (items[i].itemId === prevItem) {
                            saldo = saldo + items[i].quantity;
                        } else {
                            saldo = items[i].quantity;
                        }
                        items[i].saldo = saldo;
                        items[i].total = saldo * items[i].price;
                        prevItem = items[i].itemId;
                    }
                    
                    // items =  [
                    //     {no:'1', type:'emriiii'}, 
                    //     {no:'2', type:'emriiii22'}
                    // ]
                    this.setState({ data: items });
                } else {
                    console.log('Error:' + response.errorMessage);
                }

                this.setState({ showSpinner: false });
            });
    };

    exportCsv = (data) => {
        helper().exportCsv(data);
    }

    //{"Nr":"1","Tipi":"Blerje","ID":9,"Name":"Blerje e mallit","Date":"2020-06-28T17:57:46.000Z","PartnerID":5,"SaveAs":"elkos ","FiscalNo":"0","Price":500,"Quantity":2}
    render() {
        const { showSpinner, data, date, items, warehouses } = this.state;
        //this.validator.purgeFields();
        const renderSppiner = () => {
            if (showSpinner) {
                return <div
                    style={{
                        width: "100%",
                        height: "100",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        position: "absolute",
                        top: "100px",
                        zIndex:100
                    }}
                ><div><LoaderComponent  /></div>
                </div>;
            }
        }

         //nese dojm me i marr krjet kolonat prej datasetit
        const firstRow = (data)? data[0] : [];
        const headers1 = (firstRow)? Object.keys(firstRow).map((key) => {
            return { key: key, columnName: helper().translate(key.toUpperCase())}
        }) : [];

        //ose dojme me zgjedh cilat kolona dojme me i shfaqe
        const headers = [
            { key: 'Edit', columnName: '' },
            { key: 'no', columnName: helper().translate('NO') },
            { key: 'type', columnName: helper().translate('TYPE') },
            { key: 'itemName', columnName: helper().translate('ITEMNAME') },
            { key: 'quantity', columnName: helper().translate('QUANTITY') },
            { key: 'unit', columnName: helper().translate('UNIT') },
            { key: 'price', columnName: helper().translate('PRICE') },
            { key: 'date', columnName: helper().translate('DATE') },
            { key: 'saldo', columnName: helper().translate('SALDO') },
            { key: 'total', columnName: helper().translate('TOTAL') }
        ];

        return (
            <div>
                {renderSppiner()}
                <div className="container-fluid px-2 mt-3">
                    <Search excel={() => { this.exportCsv(this.state.data) }}/>
                    <div className="card border-left-primary shadow h-100 py-2 mt-1">
                        <div className="card-body py-0">
                        <div className="row">
                <div className="col-11">
                  <div className="row no-gutters align-items-center">
                    <div className="col row">
                      <div className="col-lg-3 mb-3 mb-md-0 pe-0 d-flex align-items-center">
                        <div style={{width: '5%'}}>
                            <input type="checkbox" name="includeStartDate" checked={findByDate.dateStartDisabled} onChange={(e) => setFindByDate({...findByDate, dateStartDisabled: e.target.checked})} ></input>
                          </div>
                          <div style={{width: '95%'}}>
                            <MDBInput
                            name="dateStart"
                            disabled={!findByDate.dateStartDisabled}
                            className="form-control-sm active"
                            onChange={(e) => fieldChange(e)}
                            type="date"
                            label={helper().translate("START_DATE")}
                            value={findByDate.dateStart == null ? '' : findByDate.dateStart.substring(0,10)}
                            />
                          </div>
                      </div>
                      <div className="col-lg-3 mb-3 mb-md-0 pe-0 d-flex align-items-center">
                        <div style={{width: '5%'}}>
                            <input type="checkbox" name="includeStartDate" checked={findByDate.dateEndDisabled}  onChange={(e) => setFindByDate({...findByDate, dateEndDisabled: e.target.checked})} ></input>
                        </div>
                        <div style={{width: '95%'}}>
                          <MDBInput
                            name="dateEnd"
                            disabled={!findByDate.dateEndDisabled}
                            className="form-control-sm active"
                            onChange={(e) => fieldChange(e)}
                            type="date"
                            label={helper().translate("END_DATE")}
                            value={findByDate.dateEnd == null ? '' : findByDate.dateEnd.substring(0,10)}
                          />
                        </div>
                      </div>
                      <div style={{paddingTop: "2px"}} className="col-lg-3 mb-3 mb-md-0 pe-0 ">
                        <Select
                          name="item"
                          placeholder={helper().translate("ITEM")}
                          styles={selectStyles}
                          options={articleItems}
                          onChange={(e, obj) => selectChange(e, obj)}
                          value={articleItems?.find(a=> a.value == findByDate.itemId)}
                        />
                      </div>
                      <div style={{paddingTop: "2px"}} className="col-lg-3 mb-3 mb-md-0 pe-0 ">
                        <SelectByGroup 
                          name="warehouseId"
                          parent={branches} 
                          child={warehouse} 
                          childParentId={"branchId"} 
                          firstItemLabel={helper().translate("ALL_WAREHOUSES")}
                          placeholder={"Depo"}
                          styles={selectStyles}
                          onChange={(e, obj) => selectChange(e, obj)}
                          value={findByDate.warehouseId}
                          />
                      </div>
                    </div>
                  </div>
                </div>
                  <div className="col-md-1 col-12 d-flex align-items-center justify-content-center  justify-content-lg-end">
                        <div>
                          <a className="searchbutton" onClick={() => searchBtn()}>
                            <i className="fas fa-search fa-2x text-dark"  />
                          </a>
                        </div>
                  </div>
              </div>
                        </div>
                    </div>

                    <div className="card shadow mt-1">
                        <div className="card-header py-2">
                            <h6 className="m-0 font-weight-bold text-primary">Historia e artikujve</h6>
                        </div>                        
                        <DataTable data={data} headers={ headers} />                       
                    </div>
                </div>
            </div>
        );
    }
}

export default ItemsHistory;
