import React, {useEffect, useState} from 'react';
import Select, { components } from 'react-select';
import apiService from '../../Services/apiServices';
import mdlItem from '../../Models/Item'
import { useToasts } from 'react-toast-notifications';

const ItemAccounts = (props) => {
    const { addToast } = useToasts();
    const itemModel = new mdlItem()
    const [item, setItem] = useState(itemModel)
    const [errors, setErrors] = useState(itemModel)
    const [itemId, setItemId] = useState(props.props?.match?.params?.id ? props.props.match.params.id: 0)
    const [articleType, setArticleType] = useState()
    const [accounts, setAccounts] = useState()
    const [firstAccountsGrup, setFirstAccountsGrup] = useState()
    const [secondAccountsGrup, setSecondAccountsGrup] = useState()
    const [thirdAccountsGrup, setThirdAccountsGrup] = useState()
    const [accountPlaceholder, setAccountPlaceholder] = useState({first: 'Malli', second: 'Kosto e shitjes se mallrave', third: 'Te hyrat nga shitja e aktivitetit'})

    const tables = [
        { name: 'accounts', method: setAccounts },
    ];

    let articleTypes = [
        { value: 1, label: 'Artikull mall', name: "itemGoods" },
        { value: 2, label: 'Shërbim', name:"service" },
        { value: 3, label: 'Shpenzim', name:"expense" },
        { value: 4, label: 'Asete', name: "inventoryItem" },
        { value: 5, label: 'Lëndë e parë', name: "material" },
        { value: 6, label: 'Artikull i montuar', name: "assembled" },
        { value: 7, label: 'Prodhim', name: "production" }
    ];

    let customStyles = {
        control: (base, state) => ({
            ...base,
            borderColor: state.isFocused ? "#bac8f3 !important" : "#d1d3e2",
            boxShadow: state.isFocused ? "0 0 0 0.2rem rgb(78 115 223 / 25%)" : "",
        }),
        control: (provided, state) => ({
            ...provided,
            background: '#fff',
            borderColor: '#9e9e9e',
            minHeight: '28.59px',
            height: '28.59px',
            boxShadow: state.isFocused ? null : null,
          }),
      
          valueContainer: (provided, state) => ({
            ...provided,
            height: '28.59px',
            padding: '0 6px'
          }),
      
          input: (provided, state) => ({
            ...provided,
            margin: '0px',
          }),
          indicatorSeparator: state => ({
            display: 'none',
          }),
          indicatorsContainer: (provided, state) => ({
            ...provided,
            height: '28.59px',
          }),
    };

    const getDefaults = (table, method, at) => {
        apiService()
            .get(table)
                .then((response) => {
                    if (response.success) {
                        if(table === 'accounts') {
                           filterAccounts(at, response.data)
                        }
                        //let records = table !== 'accounts' ? response.data.map(record => ({ value: record.id, label: (table != 'vatLevel') ? record.name : record.value })) : response.data;
                        let records = response.data.map(record => ({ value: record.id, label: record.name }))
                        method(records)
                    } else {
                        console.log('Error:' + response.errorMessage);
                    }
                });
    }

    const getItem = (queryName, paramName, paramValue) => {
        apiService()
            .query(queryName, paramName+'='+paramValue)
                .then((response) => {
                    if (response.success) {
                        //setItem(new mdlItem(response.data[0]))
                        setItem(response.data[0])
                        let item = response.data[0];
                        let articleType = articleTypes.find(type => item[type.name] == true)
                        //props.parentCallback(articleType ? articleType.name : '');  
                    } else {
                        console.log('Error:' + response.errorMessage);
                    }
                });  
    }

    const filterAccounts = (articleType, records) => {
        if(articleType === 1) {
            setFirstAccountsGrup(makeForSelect(records?.filter(a => a.grupiId === 4)))
            setSecondAccountsGrup(makeForSelect(records?.filter(a => a.grupiId === 32)))
            setThirdAccountsGrup(makeForSelect(records?.filter(a => a.grupiId === 30)))
        }
        else if(articleType === 2) {
            let g2 = [33, 34, 35, 36];
            setFirstAccountsGrup([])
            setSecondAccountsGrup(makeForSelect(records?.filter(a => a.grupiId === 33)))
            setThirdAccountsGrup(makeForSelect(records?.filter(a => a.grupiId === 31)))
        }
        else if(articleType === 3) {
            let g1 = [34, 35, 36];
            setFirstAccountsGrup(makeForSelect(records?.filter(a => g1?.includes(a.grupiId))))
            setSecondAccountsGrup([])
            setThirdAccountsGrup([])
        }
        else if(articleType === 4) {
            let g1 = [5, 6, 7, 8, 9, 10];
            setFirstAccountsGrup(makeForSelect(records?.filter(a => g1?.includes(a.grupiId))))
            setSecondAccountsGrup(makeForSelect(records))
            setThirdAccountsGrup(makeForSelect(records))
        }
    }

    const makeForSelect = (records) => {
        return records?.map(record => ({ value: record.id, label: record.name }))
    }

    const save = () => {
        if (itemId) {
           apiService().put('items', item)
                .then((response)=>{responseMessage(response, 'U ruajt me sukses!')}); 
        }
        else {
            addToast( 'Se pari ruani te dhanat bazike!', { appearance: 'info', autoDismiss: true, });
        }
    }

    const responseMessage = (response, message) => {
        if(response.success){
            addToast( message, { appearance: 'success', autoDismiss: true, });
        }
        else if(response.info){
            addToast( message, { appearance: 'info', autoDismiss: true, });
        }
        else{
            addToast('Ka ndodhur nje gabim!', { appearance: 'error', autoDismiss: true, });
        }
    }

    useEffect(() => {
        let at = articleTypes.find(type => props.item[type.name] == true);

        tables.forEach((table, index) => {
            getDefaults(table.name, table.method, at?.value)
        })

        if(at.value === 2){
            setAccountPlaceholder({...accountPlaceholder, second: 'Kosto e shërbimit', third: 'Të hyrat nga shërbimet'})
        }
        else if(at.value === 4) {
            setAccountPlaceholder({...accountPlaceholder, first: 'Konto e grupit të astetit', second: 'Kontot e zhvlerësimit të astetit', third: 'Të hyrat tjera'})
        }

        setArticleType(at?.value)
        setItem(props.item)
        props.save(() => save(item));
    }, [])

    useEffect(() => {
        props.save(() => save(item));
        let articleType = articleTypes.find(type => item[type.name] == true)
        props.parentCallback(articleType ? articleType.name : '', item);
        articleType === 1 ? setFirstAccountsGrup(accounts?.filter(a => a.grupiId === 4)): 0
    }, [item, articleType])

    return (
        <div>
            <div className="card o-hidden border-0 shadow-lg">
                    <div className="card-body p-0">
                        <div className="row">
                                <div className="col-md-12 col-lg-12 col-xl-12 mb-2 d-md-flex d-lg-flex d-xl-flex px-2 px-md-0">
                                <div className="col-md-6 px-md-3 px-0 pt-3 p-md-5">
                                        {articleType !== 2 && 
                                            <div className="col-md-12 col-lg-12 col-xl-12 mb-3 px-0">
                                                <Select
                                                    menuPortalTarget={ !props.props.fromPurchase ? document.body : ''} 
                                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                    onChange={(e) => {setItem({ ...item, accountA: e.value })}}
                                                    placeholder={accountPlaceholder?.first}
                                                    name="accountA"
                                                    options={firstAccountsGrup}
                                                    value = {accounts?.filter(account => account.value == item.accountA)}
                                                    //styles={customStyles}
                                                    className={errors.accountA != '' ? 'is-invalid-s': ''}
                                                />
                                                {errors.accountA != '' && <span className='error'>{errors.accountA}</span>}
                                            </div>
                                        }
                                        {articleType !== 3 &&
                                            <>
                                                <div className="col-md-12 col-lg-12 col-xl-12 mb-3 px-0">
                                                    <Select
                                                        menuPortalTarget={ !props.props.fromPurchase ? document.body : ''}  
                                                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                        onChange={(e) => {setItem({ ...item, accountB: e.value })}}
                                                        options={secondAccountsGrup}
                                                        value = {accounts?.filter(account => account.value == item.accountB)}
                                                        placeholder={accountPlaceholder?.second}
                                                        name="accountB"
                                                        //styles={customStyles}
                                                        className={errors.accountB != '' ? 'is-invalid-s': ''}
                                                    />
                                                    {errors.accountB != '' && <span className='error'>{errors.accountB}</span>}
                                                </div>
                                            
                                                <div className="col-md-12 col-lg-12 col-xl-12 mb-3 px-0">
                                                    <Select
                                                        menuPortalTarget={ !props.props.fromPurchase ? document.body : ''}  
                                                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                        onChange={(e) => {setItem({ ...item, accountC: e.value })}}
                                                        options={thirdAccountsGrup}
                                                        value = {accounts?.filter(account => account.value == item.accountC)}
                                                        placeholder={accountPlaceholder?.third}
                                                        name="accountC"
                                                        //styles={customStyles}
                                                        className={errors.accountC != '' ? 'is-invalid-s': ''}
                                                    />
                                                    {errors.accountC != '' && <span className='error'>{errors.accountC}</span>}
                                                </div>
                                            </>
                                        }
                                </div>
                        </div>
                    </div>
                </div>
        </div>
        </div>
    )
}

export default ItemAccounts