import React, { useState, useEffect,useRef, useContext } from "react";
import HeaderTools from "../../PageComponents/Header/HeaderTools";
import apiService from "../../Services/apiServices";
import { useToasts } from "react-toast-notifications";
import Pagination from "../../PageComponents/UI/Pagination/Pagination";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import AccountingPlansNew from "../../PageComponents/UI/Modal/AccountingPlansNew";
import Button from "react-bootstrap/Button";
import { MDBInput } from "mdb-react-ui-kit";
import Search from "../../PageComponents/Search/Search_Old";
import ExcelExport from "../../PageComponents/Excel/ExcelExport";
import Print from "../../PageComponents/Print/Print";
import helper from "../../Services/helper";
import UserPermissionContext from "../Context/UserPermissionContext";

function AccountingPlans() {
  const [accounting, setAccounting] = useState([]);
  const [pageOfItems, setPageOfItems] = useState([]);
  const [find, setFind] = useState([]);
  const [grupiKontabel , setGrupiKontabel] = useState([])
  const [modalShow, setModalShow] = useState(false);
  const [sendItem , setSendItem] = useState([]);
  const [newClick , setNewClick] = useState(false);
  const excelExportRef = useRef();
  const printRef = useRef();
  const searchObj = { CompanyBranch: 0, PurchaseType: -1}
  const {userPermission} = useContext(UserPermissionContext);

  const exportToExcel = () => {
    // let table = document.getElementById('table-to-xls-1').innerHTML;
    let fileName = helper().translate("ACCOUNTING_PLAN");
    // return helper().exportToExcel(table, fileName)
    excelExportRef.current.exportToExcel(fileName)
}

const print = () => {
 
  printRef.current.printPreview()
}

const headers = [
  { key: 'countNumber', columnName: helper().translate("NR") },
  { key: 'accountNumber', columnName: helper().translate("ACCOUNT_NUMBER") },
  { key: 'name', columnName: helper().translate("NAME") },
  { key: 'grupiName', columnName: helper().translate("GROUP") }
];

  const getAccountingPlan = () => {
    apiService()
      .query("getAccountingPlan")
      .then((response) => {
        if (response.success) {
          var count = 1;
          var data = response.data;
          data.forEach(e=> {e.countNumber = count; count++});
          setAccounting(data);
          setFind(data);
        } else {
          alert("Error: getting Plani Kontabel" + response.errorMessage);
        }
      });
  };
  const getGrupiKontabel = () => {
    apiService()
      .query("getGrupiKontabel")
      .then((response) => {
        if (response.success) {
          var count = 1;
          var data = response.data;
          data.forEach(e=> {e.countNumber = count; count++});
          data.forEach(e=> {e.editMode = false});
          setGrupiKontabel(data);
        } else {
          alert("Error: getting grupi kontabel" + response.errorMessage);
        }
      });
  };
  useEffect(() => {
    getAccountingPlan();
    getGrupiKontabel();
  }, []);

  const findChange = (e) => {
    if (e.target.value == "") {
      setFind(accounting);
    }
    var searchAccounting;
    let searchString = e.target.value.trim().toLowerCase();

    if (searchString.length > 0) {
      // We are searching. Filter the results.
      //searchItems = searchItems.filter((e) => e.ItemName.toLowerCase().match(searchString));
      searchAccounting = accounting.filter((item) => {
        return (
          item.name?.toString().toLowerCase().match(searchString) ||
          item.accountNumber?.toString().toLowerCase().match(searchString) ||
          item.grupiName?.toString().toLowerCase().match(searchString) ||
          item.pozicioniName?.toString().toLowerCase().match(searchString)
        );
      });
      setFind(searchAccounting);
    }
  };

  const onChangePage = (pageOfItems) => {
    setPageOfItems(pageOfItems);
  };

  const alertnOnDelete = (id) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <h1>{helper().translate("ARE_YOU_SURE")}</h1>
            <p>{helper().translate("WANT_TO_DELETE_THIS_ACCOUNT")}</p>
            <button
              onClick={() => {
                handleDelete(id);
                onClose();
              }}
            >
             {helper().translate("YES")} , {helper().translate("DELETE")}!
            </button>
            <button onClick={onClose}>{helper().translate("NO")}</button>
          </div>
        );
      },
    });
  };

  const handleDelete = (id) => {
    apiService()
      .query("deletePlaniKontabel", "id=" + id)
      .then((response) => {
        if (response.success) {
          getAccountingPlan();
        } else {
          alert("Error:" + response.errorMessage);
        }
      });
  };





  return (

  <div >

    
    <div id="AccountingPlans" className="container-fluid px-1">
      <div>
         <>
          <AccountingPlansNew
              show={modalShow}
              onHide={() => {setModalShow(false); setNewClick(false)}}
              dataSend ={ modalShow ? sendItem  : ''}
              onNewClick = {newClick}
              getAccountingPlan = {() => getAccountingPlan()}
          />
          </>
      </div>
      <div className="container-fluid px-2 mt-3">
        <Search newHandle={() => {setModalShow(true); setNewClick(true); }} excel={() => exportToExcel()} change={(e) => findChange(e)} back="#/"/>
        <ExcelExport data={find.length ? find : []} headers={headers} type={'other'} ref={excelExportRef} hideContent={true} />
        <Print data={find.length ? find : []} headers={headers}  ref={printRef} query={searchObj} reportName={helper().translate("ACCOUNTING_PLAN")} />
        <div className="card shadow mt-1">
        <div className="card-header py-2"><h6 className="m-0 font-weight-bold text-primary">{helper().translate("ACCOUNTING_PLAN")}</h6></div>
          <div className="card-body table-responsive">
            <table className="table table-hover text-center ">
              <thead>
                <tr>
                  <th>{helper().translate("NR")}</th>
                  <th>{helper().translate("EDIT")}</th>
                  <th>{helper().translate("ACCOUNT_NUMBER")}</th>
                  <th>{helper().translate("NAME")}</th>
                  <th>{helper().translate("GROUP")}</th>
                  {/* <th>{helper().translate("POSITION")}</th> */}
                  <th className="buttons">{helper().translate("DELETE")}</th>
                </tr>
              </thead>
              <tbody>
                {pageOfItems.map((item, key) => (
                  <tr key={key}>
                    <td style={{width: "50px"}}>{item.countNumber}</td>
                    <td style={{width: "50px"}}>
                      <button
                        disabled={userPermission?.find((f)=> f.moduleName === "FINANCE")?.allowEdit === 1 ? false : true}
                        className="btn-edit"
                        onClick={() => {
                          setModalShow(true);
                          setSendItem(item);
                        }}
                      >
                      <span className="icon text-white-40"><i className="fas fa-edit"></i></span>
                    </button>
                    </td>
                    <td>{item.accountNumber}</td>
                    <td className="text-left ps-4 ">{item.name}</td>
                    <td className="text-left ps-4 ">{item.grupiName}</td>
                    {/* <td>{item.pozicioniName}</td> */}
                    <td>
                      <a className={`${userPermission?.find((f)=> f.moduleName === "FINANCE")?.allowDelete === 1 ? "" : "pe-none"}`} onClick={() => alertnOnDelete(item.id)}>
                        <button className="btn-delete">
                          <span className="icon text-white-40">
                            <i className="fas fa-trash"></i>
                          </span>
                        </button>
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <Pagination
            items={
              typeof find == "undefined" || find.length == 0 ? accounting : find
            }
            onChangePage={(pageOfItems) => onChangePage(pageOfItems)}
          />
        </div>
      </div>
    </div>
    </div>
  );
}
export default AccountingPlans;
