
import helper from "./helper";
import globals from "./globals";
export default function currencyServices() {

    var compSettings = JSON.parse(helper().getItem('settings')) ;

    function currencyList(){
      
        var currency_symbols = [
            {label: 'USD', value :'$'}, // US Dollar
            {label: 'EUR', value :'€'},// Euro
            {label: 'CRC', value :'₡'}, // Costa Rican Colón
            {label: 'GBP', value :'£'}, // British Pound Sterling
            {label: 'ILS', value :'₪'}, // Israeli New Sheqel
            {label: 'INR', value :'₹'}, // Indian Rupee
            {label: 'JPY', value :'¥'}, // Japanese Yen
            {label: 'KRW', value :'₩'}, // South Korean Won
            {label: 'NGN', value :'₦'}, // Nigerian Naira
            {label: 'PHP', value :'₱'}, // Philippine Peso
            {label: 'PLN', value :'zł'}, // Polish Zloty
            {label: 'PYG', value :'₲'}, // Paraguayan Guarani
            {label: 'THB', value :'฿'}, // Thai Baht
            {label: 'UAH', value :'₴'}, // Ukrainian Hryvnia
            {label: 'VND', value :'₫'} // Vietnamese Dong
        ];

        return currency_symbols
    }

    function symbol(){
    var currencySym = currencyList();
    let symbolValue = currencySym.find(c => c.label === compSettings?.currency)?.value || ''
    return symbolValue
    }


    return {
        currencyList:currencyList,
        symbol:symbol
    }

}

