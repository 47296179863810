import React, { Component } from 'react';
import helper from '../../Services/helper';

//class HeaderTools extends Component {
  //render() {
    // let newbtnVisible    
    // let newbtnLink
    // switch (location.pathname) {
    //   case '/': {
    //     newbtnVisible = false
    //     break
    //   }
    //   case '/clients': {
    //     newbtnVisible = true
    //     newbtnLink = '/insertClients'
    //     break
    //   }
    //   case '/items': {
    //     newbtnVisible = false
    //     break
    //   }
    //   default:
    //     newbtnVisible = true
    //       break
    // }
    
    const HeaderTools = (props) =>  (
        <nav className="navbar navbar-expand navbar-light bg-white header-tools">
        <div className=" col-lg-12 " id="myHeader">
        {(props.back ) &&
          <a href={props.back ? props.back : ''}  className="btn btn-success btn-icon-split btn-sm mt-2 mb-2 mr-2">
            <span className="icon text-white-40">
              <i className="fas fa-arrow-left " />
            </span>
            <span className="text ">{helper().translate('BACK')}</span>
          </a>
        }
        {(props.new || props.newHandle) &&
          <a href={props.new ? props.new : 'javascript:void(0)'} onClick={props.newHandle} className="btn btn-primary btn-icon-split btn-sm mt-2 mb-2 mr-2">
            <span className="icon text-white-40">
              <i className="fas fa-file" />
            </span>
            <span className="text ">{helper().translate('NEW')}</span>
          </a>
        }
        {props.save &&
          <a onClick={!props.disableSaveButton ? props.save : 0} id="saveOnce"  className={`btn btn-success btn-icon-split btn-sm mt-2 mb-2 mr-2 ${props.disableSaveButton ? 'is-disabled' : ''}`}>
            <span className="icon text-white-40">
              <i className="fas fa-check" />
            </span>
            <span className="text ">{helper().translate('SAVE')}</span>
          </a>
        }
        {props.delete &&
          <a href="#" onClick={props.delete} className="btn btn-danger btn-icon-split btn-sm mt-2 mb-2 mr-2">
            <span className="icon text-white-40">
              <i className="fas fa-trash" />
            </span>
            <span className="text ">{helper().translate('DELETE')}</span>
          </a>
        }
        {(props.print || props.printClick) &&
          <a href={props.print ? props.print : 'javascript:void(0)'} onClick={props.printClick} className="btn btn-dark btn-icon-split btn-sm mt-2 mb-2 mr-2">
            <span className="icon text-white-40">
              <i className="fas fa-print" />
            </span>
            <span className="text ">{helper().translate('PRINT')}</span>
          </a>      
        }  
        {props.table_header &&
          <a href="#" className="btn btn-dark btn-icon-split btn-sm mt-2 mb-2 mr-2">
            <span className="icon text-white-40">
              <i className="fas fa-table" />
            </span>
            <span className="text ">Table Headers</span>
          </a>
        }
        {props.excel &&
          <button onClick={props.excel} className={`btn btn-primary btn-icon-split btn-sm mt-2 mb-2 mr-2 ${props.visibleExcel}`}>
            <span className="icon text-white-40">
              <i className="fas fa-file-excel" />
            </span>
            <span className="text ">{helper().translate('EXCEL')}</span>
          </button>
        }
        
        {props.close && 
        <button onClick={props.close} className="btn btn-danger btn-icon-split btn-sm mt-2 mb-2 mr-2">
            <span className="icon text-white-40">
                <i className="fas fa-times" />
            </span>
        </button>
        }
        {props.upload &&
          <button onClick={props.upload} className={`btn btn-primary btn-icon-split btn-sm mt-2 mb-2 mr-2`}>
            <span className="icon text-white-40">
              <i className="fas fa-file-upload" />
            </span>
            <span className="text ">{helper().translate('UPLOAD')}</span>
          </button>
        }
        {props.importData &&
          <button onClick={props.importData} className={`btn btn-info btn-icon-split btn-sm mt-2 mb-2 mr-2`}>
            <span className="icon text-white-40">
              <i className="fas fa-table" />
            </span>
            <span className="text ">{helper().translate('IMPORT_DATA')}</span>
          </button>
        }
        {props.schema &&
          <button onClick={props.schema} className={`btn btn-success btn-icon-split btn-sm mt-2 mb-2 mr-2`}>
            <span className="icon text-white-40">
              <i className="fas fa-file-download" />
            </span>
            <span className="text ">{helper().translate('SCHEMA')}</span>
          </button>
        }
       </div>
       </nav>
    );
  //}
//}
//const HeaderToolsC = withRouter(HeaderTools);

export default HeaderTools;