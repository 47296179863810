import React, {useEffect, useState, useRef} from 'react'
import {Link} from "react-router-dom";
import apiService from '../../../Services/apiServices'
import DataTable from '../../../PageComponents/UI/Tables/DataTable';
import helper from '../../../Services/helper';
import Select from "react-select"
import ExcelExport from '../../../PageComponents/Excel/ExcelExport';
import Search from '../../../PageComponents/Search/Search_Old';
import AtkPurchaseBookHeader from './AtkPurchaseBookHeader';
import LoaderComponent from '../../../PageComponents/UI/Components/LoaderComponent';

const ReportAtkPurchaseBook = () => {
    const [data, setData] = useState([])
    const d = new Date();
    const searchObj = {CompanyBranch: 0, YEAR: d.getFullYear(), StartMonth: 1, EndMonth: 12}
    const [searchQuery, setSearchQuery] = useState(searchObj);
    const [loader , setLoader] = useState(false);
    const printRef = useRef();

    const selectStyles = {
        control: (provided, state) => ({
            ...provided,
            background: '#fff',
            borderColor: '#9e9e9e',
            minHeight: '28.59px',
            height: '28.59px',
            boxShadow: state.isFocused ? null : null,
        }),
    
        valueContainer: (provided, state) => ({
            ...provided,
            height: '28.59px',
            padding: '0 6px'
        }),
    
        input: (provided, state) => ({
            ...provided,
            margin: '0px',
        }),
        indicatorSeparator: state => ({
            display: 'none',
        }),
        indicatorsContainer: (provided, state) => ({
            ...provided,
            height: '28.59px',
        }),
    };
    
    const t = (key) => {
        return helper().translate(key);
    }

    const months = [
        {value: 1, label: t('JANUARY')},
        {value: 2, label: t('FEBRUARY')},
        {value: 3, label: t('MARCH')},
        {value: 4, label: t('APRILL')},
        {value: 5, label: t('MAY')},
        {value: 6, label: t('JUNE')},
        {value: 7, label: t('JULY')},
        {value: 8, label: t('AUGUST')},
        {value: 9, label: t('SEPTEMBER')},
        {value: 10, label: t('OCTOBER')},
        {value: 11, label: t('NOVEMBER')},
        {value: 12, label: t('DECEMBER')}
    ]
    const [years, setYears] = useState()

    const headers = [
        { key: 'Pos', columnName: 'Pos' , dontShowMenu : true  },
        { key: 'Id', columnName: 'ID' },
        { key: 'Date', columnName: t('DATE') },
        { key: 'InvoiceNo', columnName: t('INVOICE_NO') , type:"text"  },
        { key: 'CompanyName', columnName: t('COMPANY_NAME'), type:"text" },
        { key: 'FiscalNumber', columnName: t('FISCAL_NUMBER') },
        { key: 'VatNumber', columnName: t('VAT_NUMBER') },
        { key: 'K31', columnName: t('K31') , type: "number"},
        { key: 'K32', columnName: t('K32') , type: "number"},
        { key: 'K33', columnName: t('K33') , type: "number"},
        { key: 'K34', columnName: t('K34') , type: "number"},
        { key: 'K35', columnName: t('K35') , type: "number"},
        { key: 'K39', columnName: t('K39') , type: "number"},
        { key: 'K43', columnName: t('K43') , type: "number"},
        { key: 'K47', columnName: t('K47') , type: "number"},
        { key: 'K53', columnName: t('K53') , type: "number"},
        { key: 'K57', columnName: t('K57') , type: "number"},
        { key: 'K61', columnName: t('K61') , type: "number"},
        { key: 'K65', columnName: t('K65') , type: "number"},
        { key: 'K1', columnName: t('K1') , type: "number"},
        { key: 'K37', columnName: t('K37') , type: "number"},
        { key: 'K41', columnName: t('K41') , type: "number"},
        { key: 'K45', columnName: t('K45') , type: "number"},
        { key: 'K49', columnName: t('K49') , type: "number"},
        { key: 'K51', columnName: t('K51') , type: "number"},
        { key: 'K55', columnName: t('K55') , type: "number"},
        { key: 'K59', columnName: t('K59') , type: "number"},
        { key: 'K63', columnName: t('K63') , type: "number"},
        { key: 'K2', columnName: t('K2') , type: "number"},
        { key: 'K67', columnName: t('K67'), type: "number" }
    ];

    const fillYears = () => {
        let s = d.getFullYear() - 10
        let e = d.getFullYear()
        let years = []

        for (let index = s; index <= e; index++) { 
            let obj = {value: index, label: index}
            years.push(obj)
        }
        setYears(years)
    }

    const getData = () => {
        setLoader(true);
        var params = `CompanyBranch=${searchQuery.CompanyBranch}&YEAR=${searchQuery.YEAR}&StartMonth=${searchQuery.StartMonth}&EndMonth=${searchQuery.EndMonth}`;

        apiService().query('RptAtkPurchaseBook', params)
            .then((response) => {
                if(response.success) {
                    setLoader(false);
                    let d = response.data;
                    d.forEach(d => {
                        d.Import ? d.InvoiceNo = d.DudNo : 0
                    })
                    setData(d)
                }
            })
    }

    const print = () => {
        //printRef.current.printPreview()
        window.open(`#/excel-print?CompanyBranch=${searchQuery.CompanyBranch}&YEAR=${searchQuery.YEAR}&StartMonth=${searchQuery.StartMonth}&EndMonth=${searchQuery.EndMonth}`, 'popUpWindow','height=1300,width="100%",left=100,top=100,right=100,bottom=100,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes')
    }

    const exportToExcel = () => {
        let table = document.getElementById('table-to-xls-1').innerHTML;
        let fileName = helper().translate("ATK_PURCHASE_BOOK")
        return helper().exportToExcel(table, fileName)
    }

    useEffect(() => {
        getData()
        fillYears()
    }, [])

    return (
        <div>
            <div className="container-fluid px-2 mt-3">
                {/* <Search search={false} excel={() => exportCsv(data, true)} printClick={print}/> */}
                <Search search={false} excel={() => exportToExcel()} printClick={print} back="#/reports"/>
                <div className="card border-left-primary shadow mt-1 h-100 py-2">
                    <div className="card-body py-0">
                    <div className="row no-gutters align-items-center">
                        <div className="col mr-2 row">
                            <div className="col-lg-3 form-group">
                                <label className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                                {helper().translate("FROM")}
                                </label>
                                <Select styles={selectStyles} value={months?.find(m=> m.value === searchQuery.StartMonth)} options={months} onChange={(e) => { setSearchQuery({...searchQuery, StartMonth: e.value })}} />
                            </div>
                            <div className="col-lg-3 form-group">
                                <label className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                                {helper().translate("TO")}
                                </label>
                            
                                <Select styles={selectStyles} value={months?.find(m=> m.value === searchQuery.EndMonth)} options={months} onChange={(e) => { setSearchQuery({...searchQuery, EndMonth: e.value })}} />
                            </div>
                            <div className="col-lg-3 form-group">
                                <label className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                                {helper().translate("YEAR")}
                                </label>
                            
                                <Select styles={selectStyles} value={years?.find(y=> y.value === searchQuery.YEAR)} options={years} onChange={(e) => { setSearchQuery({...searchQuery, YEAR: e.value })}} />
                            </div>
                        </div>
                        <div className="col-auto">
                        <a className="searchbutton" onClick={getData}>
                            <i className="fas fa-search fa-2x text-dark"  />
                        </a>
                        </div>
                    </div>
                    </div>
                </div>
                <div className="card shadow mt-1">
                    <div className="card-header py-2">
                        <h6 className="m-0 font-weight-bold text-primary text-center">{helper().translate("ATK_PURCHASE_BOOK")}</h6>
                    </div>
                    {loader && <LoaderComponent /> } 
                    {!loader ? <DataTable data={data.length ? data : []} headers={headers} /> : ""}
                    <div className='d-none'>
                        <div id="excelFile">
                            <ExcelExport data={data.length ? data : []} headers={headers.filter(header => header.key !== 'Id')} type={'purchase'} fileName={`Libri_Blerjes_Atk_${searchQuery.StartMonth}_${searchQuery.EndMonth}_${searchQuery.YEAR}`} zeroToNull={true}/>
                        </div>
                    </div> 
                </div>
            </div>
        </div>
    )
}

export default ReportAtkPurchaseBook
