import React, { useState, useEffect , useRef } from "react";
import Select from "react-select";
import { MDBInput } from "mdb-react-ui-kit";
import apiService from "../../Services/apiServices";
import { useToasts } from "react-toast-notifications";
import Search from "../../PageComponents/Search/Search_Old";
import { confirmAlert } from "react-confirm-alert";
import helper from "../../Services/helper";
import ExcelExport from "../../PageComponents/Excel/ExcelExport";
import Print from "../../PageComponents/Print/Print";
import LoaderComponent from "../../PageComponents/UI/Components/LoaderComponent";

function RptWarehouseTransfer() {
    const [item, setItem] = useState([]);
    const [find, setFind] = useState([]);
    const [findByDate , setFindByDate] = useState({warehouseFrom: null ,warehouseFromVal: false,  warehouseTo: null , warehouseToVal: false,  dateStart: null, dateEnd: null , dateStartDisabled: true , dateEndDisabled: true });
    const [warehouse, setWarehouse] = useState([]);
    const [dataShow, setDataShow] = useState(true);
    const { addToast } = useToasts();
    const excelExportRef = useRef();
    const [loader , setLoader] = useState(false);
    const printRef = useRef();
    const searchObj = { CompanyBranch: 0, PurchaseType: -1, StartDate: helper().formatDate(findByDate.dateStart,'MM-dd-yyyy'), EndDate: helper().formatDate(findByDate.dateEnd,'MM-dd-yyyy') , WarehouseId: findByDate.warehouseId}

    const exportToExcel = () => {
        // let table = document.getElementById('table-to-xls-1').innerHTML;
        let fileName = helper().translate("INTERNAL_MOVEMENT_BOOK");
        // return helper().exportToExcel(table, fileName)
        excelExportRef.current.exportToExcel(fileName)
    }

    const print = () => {
        // window.open(`#/excel-print?CompanyBranch=${searchQuery.CompanyBranch}&YEAR=${searchQuery.YEAR}&StartMonth=${searchQuery.StartMonth}&EndMonth=${searchQuery.EndMonth}`, 'popUpWindow', 'height=1300,width="100%",left=100,top=100,right=100,bottom=100,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes')
  //setOpen(!open)
        printRef.current.printPreview()
      }

      const printRow = (item) => {
        if(item) {
            //window.location.href = `#/printPurchase/${item.id}`
            window.open(`#/print-internal_movement/${item.id}`, 'popUpWindow','height=3000,width=32000,left=100,top=100,right=100,bottom=100,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes')
        }
        else {
            console.log("print isnt working right now please try again");
        }
    }

      const headers = [
        { key: 'countNumber', columnName: helper().translate("NR") },
        { key: 'date', columnName: helper().translate("DATE")},
        { key: 'fromWarehouseId', columnName: helper().translate("FROM_WAREHOUSE") ,type:"text"},
        { key: 'toWarehouseId', columnName: helper().translate("TO_WAREHOUSE") ,type:"text" },
        { key: 'totalSum', columnName: helper().translate("TOTAL_SUM") ,type:"number"} 
      ];
     

    const getWarehouses = () => {
      apiService()
        .get("warehouses")
        .then((response) => {
          if (response.success) {
            let data = response.data;
            let firstItem = {id: null , name: helper().translate("ALL_WAREHOUSES")};
            data.unshift(firstItem);
              let options = data.map((item, index) => ({
                value: item.id,
                label: item.name
              }));
            setWarehouse(options);
          } else {
            alert("Error: getting warehouses" + response.errorMessage);
          }
        });
  }
      const GetWarehouseTransers = () => {
        setLoader(true);
        
        let dateTimeNow = new Date();
        let dateTimeStart = new Date(dateTimeNow.getFullYear(),dateTimeNow.getMonth(),2); 
        let strStartDate = helper().formatDate(dateTimeStart,'MM-dd-yyyy');
        let strEndDate = helper().formatDate(dateTimeNow,'MM-dd-yyyy') + " 23:59:59"

        var params = `&dateStart=${strStartDate}&dateEnd=${strEndDate}&warehouseFromId=${findByDate.warehouseFrom}&warehouseToId=${findByDate.warehouseTo}`;
        apiService()
        .query("getInternMovementBook", params)
        .then((response) => {
            if (response.success) {
              setLoader(false);
              var count = 1;
              var data = response.data;
              data.forEach(e=> {
                e.countNumber = count; count++,
                e.date = helper().formatDate(e.date),
                e.createdAt = helper().formatDate(e.createdAt),
                e.fromWarehouseId = warehouse?.find(w => w.value == e.fromWarehouseId)?.label ,
                e.toWarehouseId = warehouse?.find(w => w.value == e.toWarehouseId)?.label ,
                e.changedAt = e.changedAt ? helper().formatDate(e.changedAt) :'' ,
                e.totalSum = e.totalSum.toFixed(2)
              });
              if(data.length == 0){
                setDataShow(false);
              }else{
                setDataShow(true);
              }
            setFind(data);
            setItem(data);
            }
        }
        )

        setFindByDate({...findByDate , dateStart: dateTimeStart , dateEnd: dateTimeNow});
      };


    useEffect(() => {
        getWarehouses();
    },[])
    useEffect(() => {
      GetWarehouseTransers();
    },[warehouse])

    const selectStyles = {
        control: (provided, state) => ({
          ...provided,
          background: "#fff",
          borderColor: "#9e9e9e",
          minHeight: "28.59px",
          height: "28.59px",
          boxShadow: state.isFocused ? null : null,
        }),
    
        valueContainer: (provided, state) => ({
          ...provided,
          height: "28.59px",
          padding: "0 6px",
        }),
    
        input: (provided, state) => ({
          ...provided,
          margin: "0px",
        }),
        indicatorSeparator: (state) => ({
          display: "none",
        }),
        indicatorsContainer: (provided, state) => ({
          ...provided,
          height: "28.59px",
        }),
      };
    
      const fieldChange = (e) => {
          if(e.target.name === "dateStart"){
              setFindByDate({...findByDate , dateStart: e.target.value + " 00:00:00" });
          }else
          if(e.target.name === "dateEnd"){
            setFindByDate({...findByDate , dateEnd: e.target.value + " 23:59:59" }); 
              // if(findByDate?.dateStart){
              //     var dStart = findByDate.dateStart.substring(0,10);
              //     if(dStart < e.target.value || dStart == e.target.value){
              //         setFindByDate({...findByDate , dateEnd: e.target.value + " 23:59:59" });   
              //     }else{
              //       addToast("Data e Mbarimit duhet te jete me e madhe se Data e Fillimit ", {
              //           appearance: "error",
              //           autoDismiss: true,
              //         });
              //       //   setFindByDate({...findByDate , validation: true});
              //     }
              // }else{
              //   addToast("Plotesoni se Pari Daten e Fillimit ", {
              //       appearance: "error",
              //       autoDismiss: true,
              //     });
              // }
        }
      };
      const selectChange = (e,obj) => {
        if (obj.name === "warehouseFrom"){
            setFindByDate({...findByDate, warehouseFrom:e.value , warehouseFromVal: false})
        }else 
        if (obj.name === "warehouseTo"){
          setFindByDate({...findByDate, warehouseTo:e.value , warehouseToVal: false})
      }
      };

      const findChange = (e) => {
        if (e.target.value == "") {
          GetWarehouseTransers();
        }
        var searchPayment;
        let searchString = e.target.value.trim().toLowerCase();
    
        if (searchString.length > 0) {
          // We are searching. Filter the results.
          //searchItems = searchItems.filter((e) => e.ItemName.toLowerCase().match(searchString));
          searchPayment = item.filter((item) => {
            return (
              item?.comment?.toString().toLowerCase().match(searchString) ||
              item?.id?.toString().toLowerCase().match(searchString) ||
              item?.createdBy?.toString().toLowerCase().match(searchString) ||
              item?.totalSum?.toString().toLowerCase().match(searchString) 
            );
          });
          setFind(searchPayment);
        }
      };

      const alertnOnDelete = (id)=> {
        confirmAlert({
            customUI: ({ onClose }) => {
              return (
                <div className="custom-ui">
                  <h1>{helper().translate("ARE_YOU_SURE")}</h1>
                  <p>{helper().translate("WANT_TO_DELETE_THIS_INTERNAL_MOVEMENT")}</p>
                  <button
                    onClick={() => {
                      handleDelete(id);
                      onClose();
                    }}
                  >
                    {helper().translate("YES")} , {helper().translate("DELETE")}!
                  </button>
                  <button onClick={onClose}>{helper().translate("NO")}</button>
                </div>
              );
            },
          });
      }

      const searchBtn = () => {
        setLoader(true);
        let startDate = findByDate.dateStart, endDate = findByDate.dateEnd , warehouseFrom = findByDate.warehouseFrom , warehouseTo = findByDate.warehouseTo;

        let strStartDate = helper().formatDate(startDate,'MM-dd-yyyy');
        let strEndDate = helper().formatDate(endDate,'MM-dd-yyyy') + " 23:59:59"
        
        if(!findByDate.dateStartDisabled) startDate = `01-01-${new Date().getFullYear()}`;
        if(!findByDate.dateEndDisabled) endDate = "2222-01-01";


        var params = `&dateStart=${strStartDate}&dateEnd=${strEndDate}&warehouseFromId=${findByDate.warehouseFrom}&warehouseToId=${findByDate.warehouseTo}`;
        apiService().query('getInternMovementBook', params).then((response) => {
            if(response.success){     
              setLoader(false);      
              var count = 1;
              var data = response.data;
              data.forEach(e=> {
                e.countNumber = count; count++,
                e.date = helper().formatDate(e.date),
                e.createdAt = helper().formatDate(e.createdAt),
                e.changedAt = e.changedAt ? helper().formatDate(e.createdAt) :'' ,
                e.totalSum = e.totalSum.toFixed(2)
              });
              if(data.length == 0){
                setDataShow(false);
              }else{
                setDataShow(true);
              }
              setFind(data);
              setItem(data);
            }else {
              console.log("error" , response.errorMessage)
            }
        })
    };
      
      const handleDelete = (id)=> {
        apiService()
        .query("deleteInternMovementBook", "id=" + id)
        .then((response) => {
          if (response.success) {
            GetWarehouseTransers();
          } else {
            alert("Error:" + response.errorMessage);
          }
        });
      }
    
      return (
        <div className="container-fluid px-2 mt-3">
          <Search change={(e) => findChange(e)} excel={() => exportToExcel()}  printClick={print} back="#/reports"/>
          <ExcelExport data={find.length ? find : []} headers={headers} type={'other'} ref={excelExportRef} hideContent={true} />
          <Print data={find.length ? find : []} headers={headers}  ref={printRef} query={searchObj} reportName={helper().translate("INTERNAL_MOVEMENT_BOOK")} />
          <div className="card border-left-primary shadow h-100 mt-1">
            <div className="card-body pt-3 pb-1">
              <div className="row">
                <div className="col-12">
                  <div className="row no-gutters align-items-center">
                    <div className="col row">
                      <div className="col-lg-3 mb-3 mb-md-0 pe-0 d-flex align-items-center">
                        {/* <label className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                              Data e fillimit
                            </label> */}
                          <div style={{width: '5%'}}>
                            <input type="checkbox" name="includeStartDate" checked={findByDate.dateStartDisabled} onChange={(e) => setFindByDate({...findByDate, dateStartDisabled: e.target.checked})} ></input>
                          </div>
                          <div style={{width: '95%'}}>
                            <MDBInput
                            name="dateStart"
                            disabled={!findByDate.dateStartDisabled}
                            className="form-control-sm active"
                            onChange={(e) =>  setFindByDate({...findByDate , dateStart: new Date(e.target.value)})}
                            type="date"
                            label={helper().translate("START_DATE")}
                            value={helper().isValidDate(findByDate.dateStart)  ?  findByDate.dateStart.toISOString().substring(0,10): ''}
                            />
                          </div>
                      </div>
                      <div className="col-lg-3 mb-3 mb-md-0 pe-0 d-flex align-items-center">
                        {/* <label className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                              Data e Mbarimit
                            </label> */}
                        <div style={{width: '5%'}}>
                            <input type="checkbox" name="includeStartDate" checked={findByDate.dateEndDisabled}  onChange={(e) => setFindByDate({...findByDate, dateEndDisabled: e.target.checked})} ></input>
                        </div>
                        <div style={{width: '95%'}}>
                          <MDBInput
                            name="dateEnd"
                            disabled={!findByDate.dateEndDisabled}
                            className="form-control-sm active"
                            onChange={(e) =>  setFindByDate({...findByDate , dateEnd: new Date(e.target.value)})}
                            type="date"
                            label={helper().translate("END_DATE")}
                            value={helper().isValidDate(findByDate.dateEnd)  ?  findByDate.dateEnd.toISOString().substring(0,10): ''}
                          />
                        </div>
                      </div>
                      <div style={{paddingTop: "2px"}} className="col-lg-2 mb-3 mb-md-0 pe-0 ">
                        {/* <label className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                          Depo
                          </label> */}
                        <Select
                          name="warehouseFrom"
                          placeholder={helper().translate("FROM_WAREHOUSE")}
                          styles={selectStyles}
                          className={findByDate.warehouseFromVal == true ? " is-invalid-s ": '' }
                          options={warehouse}
                          onChange={(e, obj) => selectChange(e, obj)}
                          value={warehouse?.find(b => b.value == findByDate.warehouseFrom)}
                        />
                      </div>
                      <div style={{paddingTop: "2px"}} className="col-lg-2 mb-3 mb-md-0 pe-0 ">
                        {/* <label className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                          Depo
                          </label> */}
                        <Select
                          name="warehouseTo"
                          placeholder={helper().translate("TO_WAREHOUSE")}
                          styles={selectStyles}
                          className={findByDate.warehouseToVal == true ? " is-invalid-s ": '' }
                          options={warehouse}
                          onChange={(e, obj) => selectChange(e, obj)}
                          value={warehouse?.find(b => b.value == findByDate.warehouseTo)}
                        />
                      </div>
                      <div className="col-md-2 col-12 d-flex align-items-center justify-content-center  justify-content-lg-end">
                        <div>
                          <a className="searchbutton" onClick={() => searchBtn()}>
                            <i className="fas fa-search fa-2x text-dark"  />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card shadow mt-1">
        <div className="card-header py-2"><h6 className="m-0 font-weight-bold text-primary text-center">{helper().translate("INTERNAL_MOVEMENT_BOOK")}</h6></div>
        {loader && <LoaderComponent /> } 
        {!loader ? dataShow ? <div className="card-body table-responsive">
            <table className="table table-hover text-center ">
              <thead>
                <tr>
                  <th className="align-middle">{helper().translate("NR")}</th>
                  {/* <th>{helper().translate("EDIT")}</th> */}
                  <th className="align-middle">{helper().translate("DOCUMENT_NR")}</th>
                  <th className="align-middle">{helper().translate("DATE")}</th>
                  <th className="align-middle">{helper().translate("FROM_WAREHOUSE")}</th>
                  <th className="align-middle">{helper().translate("TO_WAREHOUSE")}</th>
                  <th className="align-middle">{helper().translate("TOTAL_SUM")}</th>
                  <th className="align-middle">{helper().translate("COMMENT")}</th>
                  <th className="align-middle">{helper().translate("REGISTRATION_DATE")}</th>
                  <th className="align-middle">{helper().translate("REGISTERED_BY")}</th>
                  <th className="align-middle">{helper().translate("DATE_OF_MODIFICATION")}</th>
                  <th className="align-middle">{helper().translate("MODIFIED_BY")}</th>
                  <th className="align-middle">{helper().translate("PRINT")}</th>
                  {/* <th className="buttons">{helper().translate("DELETE")}</th> */}
                </tr>
              </thead>
              <tbody>
                {find.map((item, key) => (
                  <tr key={key}>
                    <td style={{width: "50px"}}>{item.countNumber}</td>
                    <td >{item.id}</td>
                    {/* <td style={{width: "50px"}}>
                      <a href={"#/features/internal-movement/new/"+item.id}>
                      <button
                        className="btn-edit">
                    <span className="icon text-white-40"><i className="fas fa-edit"></i></span>
                    </button>
                      </a>
                    </td> */}
                    <td>{item.date}</td>
                    <td className="text-left ps-4 ">{item.fromWarehouseId}</td>
                    <td className="text-left ps-5 ">{item.toWarehouseId}</td>
                    <td className="text-left ps-4 ">{item.totalSum}</td>
                    <td>{item.comment}</td>
                    <td className="text-left ps-4 ">{item.createdAt}</td>
                    <td className="text-left ps-4 ">{item.createdBy}</td>
                    <td className="text-left ps-4 ">{item.changedAt}</td>
                    <td className="text-left ps-4 ">{item.changedBy}</td>
                    <td>  
                        <button className="btn-print" onClick={() => printRow(item)}><span className="icon text-white-40"><i className="fas fa-print"></i></span> </button> 
                    </td>
                    {/* <td>
                      <a onClick={() => alertnOnDelete(item.id)}>
                        <button className="btn-delete">
                          <span className="icon text-white-40">
                            <i className="fas fa-trash"></i>
                          </span>
                        </button>
                      </a>
                    </td> */}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          : 
          <div style={{height: '300px'}} className=" d-flex align-items-center justify-content-center  "><div><p>{helper().translate("NO_DATA")}</p></div></div>: ""
          }
        </div>
        </div>
      );
}
export default RptWarehouseTransfer;