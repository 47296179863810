import React, { useState, useEffect, useRef } from 'react';
import DataTable from '../../PageComponents/UI/Tables/DataTable';
import apiService from '../../Services/apiServices';
import { confirmAlert } from 'react-confirm-alert'; 
import { ToastProvider, useToasts } from 'react-toast-notifications';
import helper from '../../Services/helper';
import globals from '../../Services/globals';
import Search from '../../PageComponents/Search/Search_Old';
import LoaderComponent from '../../PageComponents/UI/Components/LoaderComponent';
import { MDBInput } from 'mdb-react-ui-kit';
import Select from 'react-select';
import ExcelExport from '../../PageComponents/Excel/ExcelExport';
import Print from '../../PageComponents/Print/Print';
import ImportData from '../Tools/ImportData';

const PurchasesRegistrationOfGoods = (props) => {
    const { addToast } = useToasts();
    const [defaults, setDefaults] = useState(1)
    const [purchases, setPurchases] = useState()
    const [partners, setPartners] = useState()
    const [purchaseTypes, setPurchaseTypes] = useState()
    const [payMethods, setPayMethods] = useState()
    const [warehouses, setWarehouses] = useState()
    const [search, setSearch] = useState();
    const [loader, setLoader] = useState(true)
    const localPurchases = [1, 2, 3, 7];
    const excelExportRef = useRef();
    const printRef = useRef();
        
    const styles = {
        container: provided => ({
            ...provided,
            Width: '100%'
        }),
        control: (provided, state) => ({
            ...provided,
            background: '#fff',
            borderColor: '#9e9e9e',
            minHeight: '28.59px',
            height: '28.59px',
            boxShadow: state.isFocused ? null : null,
        }),
        menuPortal: base => ({ ...base, zIndex: 9999 }),
        valueContainer: (provided, state) => ({
            ...provided,
            height: '28.59px',
            padding: '0 6px'
        }),

        input: (provided, state) => ({
            ...provided,
            margin: '0px',
        }),
        indicatorSeparator: state => ({
            display: 'none',
        }),
        indicatorsContainer: (provided, state) => ({
            ...provided,
            height: '28.59px',
        }),
    };
    const purchaseObj = {
        id: 0, sId: null, purchaseId: '', no: 1, itemId: 0, projectId: 0, invoiceId: 0, quantity: 1, price: 0, priceWithVat: 0, discount: 0.0, markup: 0, duty: 0.0, dutyPercentage: 0, transport: 0.0, overvalue: 0.0, excise: 0.0, excisePercentage: 0, purchasePrice: 0, vat: '', vatSum: 0.0, createdAt: '', createdBy: '', changedAt: '', changedBy: '', status: 0, price_discount: 0.0, salePrice: 0.0, salePriceWithVat: 0.0, editMode: false, subTotal: 0.0, total: 0.0, transportPercentage: 0, dudNo: 0, dudDate: null
    }

    

    const t = (key) => {
        return helper().translate(key);
    }

    const searchObj = {
        dateStart: '',
        dateEnd: (new Date()).toISOString().substring(0, 10), 
        purchaseTypeId: props.location.search ? parseInt(props.location.search.replace('?type=', '')) : -1,
        includeStartDate: true,
        includeEndDate: true
    }
    var date = new Date();
    date.setDate(date.getDate() - 30);
    searchObj.dateStart = date.toISOString();
    searchObj.dateStart = searchObj.dateStart.substring(0, 10);

    const [searchQuery, setSearchQuery] = useState(searchObj);

    const tables = [
        { name: 'partners', method: setPartners },
        { name: 'purchaseType', method: setPurchaseTypes },
        { name: 'paymentMethod', method: setPayMethods },
        { name: 'warehouses', method: setWarehouses },
    ];

    const headers = [
        { key: 'id', columnName: 'ID' },
        { key: 'date', columnName: t('DATE') },
        // { key: 'partner', columnName: t('COMPANY_NAME') },
        // { key: 'fiscalNo', columnName: t('FISCAL_NUMBER') },
        { key: 'invoiceNo', columnName: t('INVOICE_NO') },
        { key: 'comment', columnName: t('COMMENT') },
        // { key: 'paymentMethod', columnName: t('PAYMENT_METHOD') },
        { key: 'purchaseType', columnName: t('PURCHASE_TYPE') },
        // { key: 'vatSum', columnName: t('VAT_VALUE') },
        { key: 'totalSum', columnName: t('TOTAL_SUM'), calculate: true },
        { key: 'totalWithVat', columnName: t('TOTAL_WITH_VAT'), calculate: true },
    ]

    const getPurchasesFromTo = (type) => {
        
        let startDate = searchQuery.dateStart+" 00:00:00", endDate = searchQuery.dateEnd+" 23:59:59", purchaseTypeId = "0", isImport = props.location.pathname === '/imports' ? 1 : 0; 
        
        if(!searchQuery.includeStartDate) startDate = null;
        if(!searchQuery.includeEndDate) endDate = null;
        setSearchQuery({...searchQuery, purchaseTypeId: 0}) 
        
        var params = `dateStart=${startDate}&dateEnd=${endDate}&purchaseTypeId=${purchaseTypeId}&import=${isImport}`;
        apiService().query('purchasesRegistrationOfGoods', params).then((response) => {
            console.log(response)
            if(response.success){                
                let records = type ? response.data.filter(record => record.purchaseTypeId === type) : response.data
                // let records = response.data
                setDatas(records)
                setPurchases(records)
                searchResult(records)
                setLoader(false)
            }
        })
    }

    const setDatas = (records) => {
        if(!records) records = purchases
        records?.forEach(record => {
            record.date ? record.date = helper().formatDate(record.date, 'yyyy-MM-dd') : '';
            record.withVat ? record.withVat = 'Po' : record.withVat = 'Jo';
            record.partnerId ? record.partner = partners?.find(p => p.id === record.partnerId)?.companyName : record.partnerId = '-';
            record.partnerId ? record.fiscalNo = partners?.find(p => p.id === record.partnerId)?.fiscalNo : record.partnerId = '-';
            record.warehouseId ? record.warehouse = warehouses?.find(w => w.id === record.warehouseId)?.name : record.warehouseId = '-';
            record.purchaseTypeId ? record.purchaseType = purchaseTypes?.find(w => w.value === record.purchaseTypeId)?.label : record.purchaseType = '-';
        });
    }

    const getDefaults = (name, method) => {
        apiService().get(name).then((response) => {
            if(response.success){
                let records = response.data
                name === 'purchaseType' ? records = records.map(record => ({ value: record.id, label: record.name })) : 0
                method(records) 
                setDefaults(defaults + 1)
            }
        })
    }

    const edit = (item) => {
        window.location.href = `#/finances/registration-goods/${item.id}`
    }

    const print = (item) => {
        if(item.id) {
            //window.location.href = `#/printPurchase/${item.id}`
            window.open(`#/print-purchase/${item.id}`, 'popUpWindow','height=1300,width="100%",left=100,top=100,right=100,bottom=100,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes')
        }
        else {
            responseMessage(true, `Dizajni për këtë lloj të faturës së shpejti do të jetë në funksion`, 'info' )
        }
    }

    const exportToExcel = () => {
        //let table = document.getElementById('table-to-xls-1').innerHTML;
        let fileName = helper().translate("REGISTRATION_OF_GOODS")
        //return helper().exportToExcel(table, fileName)
        excelExportRef.current.exportToExcel(fileName)
    }

    const remove = (id) => {
        confirmAlert({
            customUI: ({ onClose }) => {
              return (
                <div className="custom-ui" >
                  <h1>A jeni te sigurt?</h1>
                  <p>Dëshironi të fshini këtë blerje?</p>
                  <button
                    onClick={() => {
                        apiService().query('deletePurchases', `id=${id}`)
                        .then((response) => {
                            if(response.success){
                                responseMessage(response.success, 'Delete Successfully')
                                //getPurchases(parseInt(props.location.search ? props.location.search.replace('?type=', '') : ''))
                                getPurchasesFromTo(parseInt(props.location.search ? props.location.search.replace('?type=', '') : ''))
                            }
                        })
                        onClose();
                    }}
                  >
                    Po , Fshije!
                  </button>
                  <button onClick={onClose}>Jo</button>
                </div>
              );
            }
        })   
    }

    const responseMessage = (success, message, appearance) => {
        if (success) {
            addToast(message, { appearance: appearance ? appearance : 'success', autoDismiss: true, });
        }
        else {
            addToast('Ka ndodhur nje gabim!', { appearance: 'error', autoDismiss: true, });
        }
    }

    const searchResult = (records) => {
        let searchItems = records ? records : purchases,
        searchString = search?.trim().toLowerCase();
        if (searchString?.length > 0) {
            //searchItems = searchItems.filter((e) => e.name.toLowerCase().match(searchString));
            searchItems = searchItems.filter(item => {
                return (
                    // headers.map((h, index) => {
                    //     return item?.[h.key]?.toString().toLowerCase().match(searchString)
                    // }) 
                    item?.partner.toString().toLowerCase().match(searchString) ||
                    item.purchaseType.toString().toLowerCase().match(searchString) ||
                    item.warehouse.toLowerCase().match(searchString) ||
                    item.invoiceNo.toLowerCase().match(searchString) ||
                    item.reference.toLowerCase().match(searchString) 
                )
            });
            return searchItems
        }
        else {
            return searchItems
        }
    }

    useEffect(() => {
        setLoader(true)
        tables.forEach((table, index) => {
            getDefaults(table.name, table.method)
        })
        //getPurchases()
        getPurchasesFromTo()
        console.log('dates: ', purchases)
    }, [])

    useEffect(() => {
        setDatas()
    }, [purchases])

    useEffect(() => {
        setLoader(true)
        //getPurchases(parseInt(props.location.search.replace('?type=', '')))
        getPurchasesFromTo(0)
    }, [props.location])

    return (
        <div className="d-flex">
            <div className="w-100 overflow-auto overflow-auto pr-0">
                <div className="container-fluid px-2 px-0 mt-3">
                    <Search back="#/" new={`#finances/registration-goods`} change={(e) => setSearch(e.target.value)} excel={() => exportToExcel()} />
                    <div className="card border-left-primary shadow h-100 mt-1">
                        <div className="card-body pt-3 pb-1">
                        <div className="row">
                            <div className="col-md-11 col-12">
                            <div className="row no-gutters align-items-center">
                                <div className="col row">
                                <div className="col-lg-3 mb-3 mb-md-0 pe-0 d-flex align-items-center">
                                    <div style={{width: '5%'}}>
                                        <input type="checkbox" name="includeStartDate" checked={searchQuery.includeStartDate ? 'checked': ''} onChange={(e) => setSearchQuery({...searchQuery, includeStartDate: e.target.checked})}></input>
                                    </div>
                                    <div style={{width: '95%'}}>
                                        <MDBInput
                                            name="dateStart"
                                            disabled = {!searchQuery.includeStartDate ? 'disabled': ''}
                                            className="form-control-sm active"
                                            onChange={(e) => setSearchQuery({...searchQuery, dateStart: e.target.value})}
                                            type="date"
                                            label="Data e fillimit"
                                            value={searchQuery.dateStart ? helper().formatDate(searchQuery.dateStart, 'yyyy-MM-dd') : (new Date()).toISOString().substring(0, 10)}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-3 mb-3 mb-md-0 pe-0 d-flex align-items-center">
                                    <div style={{width: '5%'}}>
                                        <input type="checkbox" name="includeEndDate" checked={searchQuery.includeEndDate ? 'checked': ''} onChange={(e) => setSearchQuery({...searchQuery, includeEndDate: e.target.checked})}></input>
                                    </div>
                                    <div style={{width: '95%'}}>
                                        <MDBInput
                                            name="dateEnd"
                                            disabled = {!searchQuery.includeEndDate ? 'disabled': ''}
                                            className="form-control-sm active"
                                            onChange={(e) => setSearchQuery({...searchQuery, dateEnd: e.target.value})}
                                            type="date"
                                            label="Data e Mbarimit"
                                            value={searchQuery.dateEnd ? helper().formatDate(searchQuery.dateEnd, 'yyyy-MM-dd') : (new Date()).toISOString().substring(0, 10)}
                                        />
                                    </div>
                                </div>
                                {/* <div className="col-lg-3 mb-3 mb-md-0 pe-0">
                                    <Select name="purchaseTypeId" placeholder="Tipi i blerjes"  value={purchaseTypes?.find(p => p.value === searchQuery.purchaseTypeId)}  onChange={(e) => setSearchQuery({...searchQuery, purchaseTypeId: e.value})} options={purchaseTypes?.filter(pt => pt.value !== 0)} styles={styles} />
                                </div> */}
                                {/* <div className="col-lg-3 mb-3 mb-md-0 pe-0">
                                    <Select name="salesTypeId" placeholder="Zgjedh Tipin"  value="" onChange="" options="" styles={styles} />
                                </div> */}
                                </div>
                            </div>
                            </div>
                            <div className="col-md-1 col-12 d-flex align-items-center justify-content-center  justify-content-lg-end">
                            <div>
                                <a className="searchbutton" onClick={() => getPurchasesFromTo()}>
                                <i className="fas fa-search fa-2x text-dark"  />
                                </a>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="card shadow mt-1">
                        <div className="card-header py-2">
                            <h6 className="m-0 font-weight-bold text-primary">{t('REGISTRATION_OF_GOODS')}</h6>
                        </div>  
                        {loader && <LoaderComponent  /> }                   
                        {!loader ? purchases?.length ? <DataTable data={searchResult() ? searchResult() : []} headers={headers} print={print} edit={edit} delete={remove} headersFrom={"FINANCE"} formName="RegistrationOfGoods"/> : <div className="p-5 text-center">{helper().translate("NO_DATA")}</div> : ''}                    
                    </div>
                    <ExcelExport data={searchResult() ? searchResult() : []} headers={headers.filter(header => header.key !== 'id')} type={'other'} ref={excelExportRef} hideContent={true}/>

                    <Print  data={searchResult() ? searchResult() : []} headers={headers.filter(header => header.key !== 'id')}  ref={printRef} query={searchObj} reportName={helper().translate("REGISTRATION_OF_GOODS")} />
                </div>
            </div>
        </div>
    )
}

export default PurchasesRegistrationOfGoods
