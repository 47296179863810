import React, { useState, useEffect, useRef } from 'react'
import { Line } from 'react-chartjs-2';
import apiService from '../../../Services/apiServices';
import helper from '../../../Services/helper';

const ChartPayments = () => {
  const [paymentsSales, setPaymentsSales] = useState()
  const [paymentsPurchases, setPaymentsPurchases] = useState()
  const data = {
    labels: [
      helper().translate('JAN'),
      helper().translate('FEB'),
      helper().translate('MAR'),
      helper().translate('APR'),
      helper().translate('MAY'),
      helper().translate('JUN'),
      helper().translate('JUL'),
      helper().translate('AUG'),
      helper().translate('SEP'),
      helper().translate('OCT'),
      helper().translate('NOV'),
      helper().translate('DEC')
    ],
    datasets: [
      {
        data: paymentsPurchases,
        label: helper().translate('PURCHASES'),
        borderColor: "rgba(0,152,219,255)",
        backgroundColor: "rgba(0,152,219,255)",
        lineTension: 0.4,
      },
      {
        data: paymentsSales,
        label: helper().translate('SALES'),
        borderColor: "#1cc88a",//"rgba(145,216,246,255)",
        backgroundColor: "#1cc88a",//"rgba(145,216,246,255)",
        lineTension: 0.4,
      },
    ],
  };

  const options = {
    bezierCurve: false,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
      },
      x: {
        grid: {
          display: false
        }
      }
    },
  };
  // const getPayments = () => {
  //   apiService().get('paymentPurchaseChart')
  //     .then((response) => {
  //       //console.clear();
  //       var data = response.data;
  //       var list = [];

  //       for (let i = 0; i < data?.length; i++) {
  //         var mth = data[i].date.split("-")[1].replace(/^0+/, '');
  //         if (!list[mth]) {
  //           list[mth] = [];
  //         }
  //         list[mth].push(data[i].id);
  //       }

  //       var purchases = []
  //       for (let i = 1; i <= 12; i++) {
  //         purchases.push(list[i]?.length)
  //       }
  //       setPayments(purchases)
  //       console.log(purchases)
  //     })
  // }

  const getPaymentSales = () => {
    apiService().get('paymentSaleChart')
      .then((response) => {
        var paypurchases = []
        response.data?.map((p) => {
          paypurchases.push(p.total);
        })
        setPaymentsSales(paypurchases)
      })
  }
  const getPaymentPurchases = () => {
    apiService().get('paymentPurchaseChart')
      .then((response) => {
        var paysales = []
        response.data?.map((s) => {
          paysales.push(s.total);
        })
        setPaymentsPurchases(paysales)
      })
  }

  useEffect(() => {
    getPaymentSales();
    getPaymentPurchases();
  }, [])
  return (
    <div>
      <Line data={data} options={options} height="450" />
    </div>
  )
}

export default ChartPayments
