import React, { useState, useEffect, useContext } from "react";
import Select from "react-select";
import { MDBInput } from "mdb-react-ui-kit";
import apiService from "../../Services/apiServices";
import { useToasts } from "react-toast-notifications";
import Search from "../../PageComponents/Search/Search_Old";
import { confirmAlert } from "react-confirm-alert";
import helper from "../../Services/helper";
import globals from "../../Services/globals";
import UserPermissionContext from "../Context/UserPermissionContext";

function ReportsDashboardMap(props) {
    useEffect(()=> {
      getReports();
      document.getElementById('navi')?.classList.add("nav-mobile")
      return () => {
        document.getElementById('navi')?.classList.remove("nav-mobile")
      }
    }, [])
  const { addToast } = useToasts();
  const {userPermission} = useContext(UserPermissionContext);
  const [reports , setReports] = useState();


  const handleMouseEnter = (data,index) => {
    setReports((prevItems) =>
    prevItems.map((rp) => {
      if (rp.id === data.id) {
        const updatedNestedArray = rp.items.map((nestedItem, nestedIndex) => {
          if (nestedIndex === index) {
            return { ...nestedItem, activeitem: true };
          }
          return nestedItem;
        });

        return {
          ...rp,
          items: updatedNestedArray,
        };
      }
      return rp;
    })
  );
  };

  const handleMouseLeave = (data,index) => {
    setReports((prevItems) =>
    prevItems.map((rp) => {
      if (rp.id === data.id) {
        const updatedNestedArray = rp.items.map((nestedItem, nestedIndex) => {
          if (nestedIndex === index) {
            return { ...nestedItem, activeitem: false };
          }
          return nestedItem;
        });

        return {
          ...rp,
          items: updatedNestedArray,
        };
      }
      return rp;
    })
  );
  };
  
  const displayMsg = () => {
    addToast( "së shpejti ky raport do të jet aktiv", { appearance: 'error', autoDismiss: true, });
  }

  const getReports = () => {
    apiService().query('getReports')
        .then((response) => {
         if(response.success){
              const groupedItems = Object.values(
                response.data.reduce((acc, currentItem) => {
                  const { id, reportsToken , path , repStyle } = currentItem;
                  if (acc[id]) {
                    acc[id].items.push({ id, reportsToken , path , repStyle , activeitem: false });
                  } else {
                    acc[id] = { id: currentItem.id, title: currentItem.grReportToken , color: currentItem.repStyle , items: [{ id, reportsToken , path,repStyle , activeitem: false }] };
                  }
                  return acc;
                }, {})
              );
            setReports(groupedItems)
         }
        })
  }

    return(
        <div style={{height: "85vh"}}>
        <div className="container-fluid mt-5">
        <div className="row">
          <div className="col-12 mb-4">
            <h4 className="m-0 mt-1 font-weight-bold text-center text-primary">{helper().translate("REPORTS")}</h4>
          </div>
            <hr  style={{width: "97%" , margin: "auto" , height: "2px" , color: "black" , backgroundColor: "black"}}></hr>
        </div>
        <div className="row mt-5">
        {reports?.map((rep, key) =>{
        return(
          
            <div key={key} className={`col-xl-4 col-md-6 mb-4  ${userPermission?.find((f)=> f.moduleName === rep.title)?.allowView ? "" : "pe-none"}`}>
            <div   style={{borderBottom: `4px solid ${rep.color}`}}className={`card  shadow h-100 py-2`}>
              <div className="card-body">
                <div className="row no-gutters align-items-center ">
                <div className="col mr-2">
                    <div  className="row pLeft35">
                      <h5  className="text-xl text-left font-weight-bold text-dark  mb-1">{helper().translate(rep.title)}</h5>
                    </div>
                        {rep.items.length <= 5 ?
                        <div  className="row pLeft35 pt-2">
                        <div className="col text-left">
                          {    rep.items.map((r,k) => {
                          return(
                          
                          <div 
                          key={k}  
                          className="row  reportsMap mt-1  "
                          onMouseEnter={() => handleMouseEnter(r,k)}
                          onMouseLeave={() => handleMouseLeave(r,k)}
                          style={{
                            backgroundColor: r.activeitem === true ? r.repStyle : '#e3e6f0'
                          }}
                           >
                          <a  href={r.path}  >{helper().translate(r.reportsToken)}</a>
                        </div>
                          )
                        })}
                          </div>
                          </div>
                      : 
                        <div
                          className="row row-cols-1 row-cols-md-2 pt-2 poniter  ps-md-4 text-left pr-3  pLeft35">
                          {  rep.items.map((r,k) => {
                          return(
                          <div key={k}   className="col-8 col-sm-12 text-left reportsMap border_white2px " 
                          onMouseEnter={() => handleMouseEnter(r,k)}
                          onMouseLeave={() => handleMouseLeave(r,k)}
                          style={{
                            backgroundColor: r.activeitem === true ? r.repStyle : '#e3e6f0'
                          }}
                          >
                          <a  href={r.path} className="w-100"  >{helper().translate(r.reportsToken)}</a>
                        </div>
                          )
                        })}
                        </div>
                   
                        }
                  </div>
                </div>
              </div>
            </div>
        </div>
          )
         }) }
        </div>
        </div>
        </div>
    )
}


export default ReportsDashboardMap;