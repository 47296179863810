import React, { useEffect, useState } from 'react'
import AdministrationForm from '../UI/AdministrationForm/AdministrationForm'
import helper from '../../Services/helper'

const Branch = (props) => {
    const [modal, setModal] = useState()
    const t = (key) => {
        return helper().translate(key);
    }

    const headers = [
        { key: 'NAME', name: 'name', columnName: t('name'), type: 'input', required: true },
        { key: 'UNIT_NUMBER', name: 'unitNo', columnName: t('unitNo'), type: 'input', required: false },
        { key: 'ADDRESS', name: 'address', columnName: t('address'), type: 'input', required: false },
        { key: 'CONTACT_PERSON', name: 'contactPerson', columnName: t('contactPerson'), type: 'input', required: false },
        { key: 'EMAIL', name: 'email', columnName: t('email'), type: 'input', required: false },
        { key: 'PHONE', name: 'phone', columnName: t('phone'), type: 'input', required: false },
    ]

    const model = {
        id: 0,
        name: "",
        unitNo: "", 
        address: "",
        contactPerson: "",
        email: "",
        phone: "",
        status: 0
    }

    useEffect(() => {
        setModal(props.modal)
    }, [props.modal]);

    return (
        <div>
            <AdministrationForm new={modal} headers={headers} table="Branches" name="braches" model={model} formType="modal" />
        </div>
    )
}

export default Branch
