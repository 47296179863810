import React, { useState, useEffect, useRef } from 'react'
import apiService from '../../../Services/apiServices';
import helper from '../../../Services/helper';
import { Doughnut, Bar } from 'react-chartjs-2';

const ChartSales = () => {
    const [sales, setSales] = useState()
    const [purchases, setPurchases] = useState()
    const data = {
        labels: [
            helper().translate('JAN'),
            helper().translate('FEB'),
            helper().translate('MAR'),
            helper().translate('APR'),
            helper().translate('MAY'),
            helper().translate('JUN'),
            helper().translate('JUL'),
            helper().translate('AUG'),
            helper().translate('SEP'),
            helper().translate('OCT'),
            helper().translate('NOV'),
            helper().translate('DEC')
        ],
        datasets: [
            {
                label: helper().translate('PURCHASES'),
                data: purchases,
                backgroundColor: [
                    'rgba(0,152,219,255)',
                ],
                hoverBackgroundColor: [
                    'rgba(0,152,219,255)',
                ]
            },
            {
                label: helper().translate('SALES'),
                data: sales,
                backgroundColor: [
                    '#1cc88a',

                ],
                hoverBackgroundColor: [
                    '#1cc88a'
                ]
            },
        ],

    };

    const options = {
        maintainAspectRatio: false,

        scales: {
            y: {
                beginAtZero: true,
            },
            x: {
                grid: {
                    display: false
                }
            }
        },
    };

    const getPurchases = () => {
        apiService().get('purchaseChart')
            .then((response) => {

                var purchases = []
                response.data?.map((p) => {
                    purchases.push(p.total);
                })
                setPurchases(purchases)
            })
    }
    const getSales = () => {
        apiService().get('saleChart')
            .then((response) => {
                var sales = []
                response.data?.map((s) => {
                    sales.push(s.total);
                })
                setSales(sales)
            })
    }

    useEffect(() => {
        getSales();
        getPurchases();
    }, [])
    return (
        <div className="container">
            <Bar data={data} options={options} height="450" />
        </div>
    )
}

export default ChartSales
