import React, { useEffect, useState, useRef } from 'react'
import apiService from '../../../Services/apiServices'
import DataTable from '../../../PageComponents/UI/Tables/DataTable';
import helper from '../../../Services/helper';
import ExcelExport from '../../../PageComponents/Excel/ExcelExport';
import Search from '../../../PageComponents/Search/Search_Old';
import { MDBInput } from 'mdb-react-ui-kit';
import Print from '../../../PageComponents/Print/Print';
import globals from '../../../Services/globals';
import LoaderComponent from '../../../PageComponents/UI/Components/LoaderComponent';

const SaleTermsOfConditions = () => {
  const [data, setData] = useState([])
  const [loader , setLoader] = useState(false);
  const excelExportRef = useRef();
  const printRef = useRef();

  const reportName = helper().translate("WAITING_PAYMENT_LIST");

  const headers = [
    { key: 'no', columnName: helper().translate("NR") , dontShowMenu : true  },
    { key: 'documentType', columnName: helper().translate("DOCUMENT_TYPE") },
    { key: 'date', columnName: helper().translate("DATE") },
    { key: 'invoiceNo', columnName: helper().translate("INVOICE_NO") },
    { key: 'companyName', columnName: helper().translate("PARTNER") },
    { key: 'warehouse', columnName: helper().translate("WAREHOUSE") },
    { key: 'comment', columnName: helper().translate("DESCRIPTION")  , type:"text"},
    { key: 'total', columnName: helper().translate("TOTAL") , type:"number" },
    { key: 'days', columnName: helper().translate("DAYS") },
    { key: 'delays', columnName: helper().translate("DELAYS") },
    { key: 'amountPaid', columnName: helper().translate("PAID_AMOUNT")  , type:"number"},
    { key: 'balance', columnName: helper().translate("BALANCE") , type:"number" }
];

  const checkColumn = (header, items) => {
      if(header && header.subHeader){
          //const isTrue = (currentValue) => parseFloat(currentValue[header.key]) != 0;
          const isTrue = (currentValue) => !currentValue[header.key] || currentValue[header.key] == 0;
          if(!items?.every(isTrue)) {
              return header
          }
      }
      else{
          return header
      }
  }

  const listSumByItem = ( list ) => {
    if(list.length < 2) list.push({})
    return list?.reduce(function(previousValue, currentValue, index, array){
        array = array.filter(d => d.days > 0)
        const results = array?.reduce((results, item) => ({
            ...results,
            [item.partnerId]: [...(results[item.partnerId] || []), item]
        }), {});

        let all = [];

        for (var key in results) {
            reOrderItems(results[key])  

            let obj = {
                total_label: helper().translate("TOTAL_SUM")+':',
                total: calculateTotal(results[key], 'total'),
                amountPaid: calculateTotal(results[key], 'amountPaid'),
                balance: calculateTotal(results[key], 'balance'),
                isCalculate: true
            };
            results[key].push(obj);

            results[key].forEach(r => {
                all.push(r);
            })  
        }

        all.forEach(a => {
            a.delays > 0 ? a['delays_color'] = 'red' : 0
        })
        setData(all)
    });
}

const calculateTotal = (arr, prop) => {
    return parseFloat(arr.reduce((accumulator, current) => parseFloat(accumulator) + parseFloat(current[prop]), 0)).toFixed(2)
}

const reOrderItems = (items) => {
    let count = 1;
    items.forEach(i => { 
        i.no = count; 
        count++ 
    });
    return items;
}

const getData = () => {
    //var params = `dateStart=${searchQuery.includeStartDate ? searchQuery.StartDate : "1900-01-01"}&dateEnd=${searchQuery.EndDate+" 23:59:59"}`;
    setLoader(true);
    apiService().get('rptSalesTermsOfPayments')
        .then((response) => {
            if (response.success) {
                if(response.data.length) {
                    var no = 1;
                    let data = response.data;
                    data.forEach(e=> {
                        e.no=no++;
                        e.date=helper().formatDate(e.date);
                    });
                    //setData(data)
                    listSumByItem(data)
                    setLoader(false);
                }
                else {
                    setData([])
                    setLoader(false);
                }
            }
        })
}



const print = () => {
    // window.open(`#/excel-print?CompanyBranch=${searchQuery.CompanyBranch}&YEAR=${searchQuery.YEAR}&StartMonth=${searchQuery.StartMonth}&EndMonth=${searchQuery.EndMonth}`, 'popUpWindow', 'height=1300,width="100%",left=100,top=100,right=100,bottom=100,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes')
    //setOpen(!open)
    printRef.current.printPreview()
}

const exportToExcel = () => {
    // let table = document.getElementById('table-to-xls-1').innerHTML;
    let fileName = helper().translate("WAITING_PAYMENT_LIST")
    // return helper().exportToExcel(table, fileName)
    excelExportRef.current.exportToExcel(fileName)
}

useEffect(() => {  
    setLoader(true);     
    getData()
}, [])

  
  return (
    <>
        {open && <Print data={data.length ? data : []} headers={headers.filter(header => header !== false)} query={'searchQuery'} ref={printRef} reportName={reportName} formName="SaleTermsOfConditions" />}
            <div className="container-fluid px-2 mt-3 pt-1">
                <Search search={false} excel={() => exportToExcel()} printClick={print} back="#/reports"/>
                <div className="card shadow mt-1">
                    <div className="card-header py-2">
                        <h6 className="m-0 font-weight-bold text-primary text-center">{helper().translate("WAITING_PAYMENT_LIST")}</h6>
                    </div>
                    {loader && <LoaderComponent /> } 
                    {!loader ? data.length ? <DataTable data={data.length ? data : []} headers={headers?.filter(header => checkColumn(header, data))} formName="SaleTermsOfConditions" /> : <div className="p-5 text-center">{helper().translate("NO_DATA")}</div> : "" }
                    <ExcelExport data={data.length ? data : []} headers={headers} type={'other'} ref={excelExportRef} hideContent={true} />
                </div>
            </div>
    </>
    
  )
}

export default SaleTermsOfConditions