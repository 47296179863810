import React from 'react';
import Select from 'react-select';

class MapSelectServices extends React.Component {
    constructor(props) {
        super(props);

        this.name = props.name || '';
        this.value = props.value || '';


        this.state = {           
            displayMember: props.displayMember || 'name',
            valueMember: props.valueMember || 'id'
        };
    }
    componentDidMount() {
        
    }

    render() {
        var maps = [];
        if (this.props.data) {
            
            maps = this.props.data.map((item, index) => ({
                value: item[this.state.valueMember],
                label: item[this.state.displayMember],
                target: { name: this.props.name, value: item[this.state.valueMember]}               
            }));

            maps.unshift({ value: 0, label: 'Te gjitha', target: { name: this.props.name, value: 0 } });
        }

        const selectStyles = {
            control: (provided, state) => ({
              ...provided,
              background: '#fff',
              borderColor: '#9e9e9e',
              minHeight: '28.59px',
              height: '28.59px',
              boxShadow: state.isFocused ? null : null,
            }),
        
            valueContainer: (provided, state) => ({
              ...provided,
              height: '28.59px',
              padding: '0 6px'
            }),
        
            input: (provided, state) => ({
              ...provided,
              margin: '0px',
            }),
            indicatorSeparator: state => ({
              display: 'none',
            }),
            indicatorsContainer: (provided, state) => ({
              ...provided,
              height: '28.59px',
            }),
          };

        return (
            <div className={`col-lg-${this.props.width}`}>
                <label className="text-xs font-weight-bold text-primary mb-1">
                    {this.props.label}
                </label>
                <Select styles={selectStyles} placeholder="Zgjedh" value={this.state.selectedId} onChange={this.props.onChange} options={maps} />
            </div>
        );
    }
}

export default MapSelectServices;
