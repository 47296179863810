import React, { useEffect, useState } from 'react'
import AdministrationForm from '../../PageComponents/UI/AdministrationForm/AdministrationForm'
import apiService from '../../Services/apiServices'
import mdlCashBox from '../../Models/CashBox.js'
import helper from '../../Services/helper.js'

const CashBox = () => {
    const [accounts, setAccounts] = useState()
    const [branches, setBranches] = useState()
    const [warehouses, setWarehouses] = useState()

    const getAccounts = () => {       
        apiService().get('accounts')
            .then((response)=> {
                if(response.success) {
                    let records = response.data.map(record => ({ value: record.id, label: record.name }));
                    setAccounts(records)
                }
            })
    } 

    const getBranches = () => {
        apiService().get('branches')
            .then((response)=> {
                if(response.success) {
                    let records = response.data.map(record => ({ value: record.id, label: record.name }));
                    setBranches(records)
                }
            })
    }

    const backData = (e) => {
        getWerhauses(e.value)
    }

    const getWerhauses = (branchId) => {
        apiService().query('warehousesByBranch', `branchId=${branchId}`)
            .then((response)=> {
                if(response.success) {
                    let records = response.data.map(record => ({ value: record.id, label: record.name }));
                    setWarehouses(records)
                }
            })
    }

    const headers = [
        { key: 'NAME', name:"name", columnName: 'name', type: 'input', required: true },
        { key: 'BRANCH', name: "branchId", columnName: 'branchName', type: 'select', options: branches, backData: true, subItem: "warehouseId", required: false },
        { key: 'WAREHOUSE', name: "warehouseId", columnName: 'warehouseName', type: 'select', options: warehouses, required: false },
        { key: 'ACCOUNT', name: 'accountId', columnName: 'accountName', type: 'select', options: accounts, required: false }
    ]

    useEffect(() => {
        getAccounts();
        getBranches();
    }, [])

    return (
        <div>
            <AdministrationForm headers={headers} table="cashBoxes" model={new mdlCashBox()} backData={backData} name={helper().translate('CASHBOX')}/>
        </div>
    )
}

export default CashBox
