import React, { useEffect, useState } from 'react'
import AdministrationForm from '../../PageComponents/UI/AdministrationForm/AdministrationForm'
import apiService from '../../Services/apiServices'

const TermsOfPayments = () => {

  const headers = [
    { key: 'name', columnName: 'Emri', type: 'input', required: true },
    { key: 'duration', columnName: 'Kohëzgjatja', type: 'input', required: true }
  ]

  const obj = {id:0, name: '', duration: 0, type: 'terms_of_payments'}

  const getTermsOfPayments = () => {
    apiService().get('termsOfPayments')
      .then(repsonse => {
        // console.log(repsonse)
      })
  }

  return (
    <div>
      <AdministrationForm headers={headers} selectQuery={'termsOfPayments'} table="referenceData" model={obj} backData={getTermsOfPayments} />
    </div>
  )
}

export default TermsOfPayments