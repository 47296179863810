import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import { MDBInput } from "mdb-react-ui-kit";
import apiService from "../../../Services/apiServices";
import { useToasts } from "react-toast-notifications";
import Search from "../../../PageComponents/Search/Search_Old";
import globals from "../../../Services/globals";
import { confirmAlert } from "react-confirm-alert";
import helper from "../../../Services/helper";
import calculate from "../../../Services/calculate";
import Pagination from "../../../PageComponents/UI/Pagination/Pagination";
import SelectByGroup from './../../../PageComponents/UI/Inputs/SelectByGroup'
import ExcelExport from "../../../PageComponents/Excel/ExcelExport";
import Print from "../../../PageComponents/Print/Print";
import { isEmptyObject, map } from "jquery";
import { main, right } from "@popperjs/core";
import { isDate } from "moment";



function BalanceSheet(props) {

  var sampleData = [
    {
      name: 'PASURIT',
      subClasses: [
        {
          name: 'Pasurit afatshkutra',
          groups: [
            {
              name: 'Paraja dhe ekuivalentet e parase',
              currentYearValue: 10100,
              previousYearValue: 15000,
            },
            {
              name: 'Investimet financiare afatshkurtra',
              currentYearValue: 9000,
              previousYearValue: 10000,
            },
            {
              name: 'Stoqet',
              currentYearValue: 5000,
              previousYearValue: 7000,
            }
          ]
        },
        {
          name: 'Pasurit afatgjata',
          groups: [
            {
              name: 'Pasurit afatgjata 1',
              currentYearValue: 10100,
              previousYearValue: 15000,
            },
            {
              name: 'Pasurit afatgjata 2',
              currentYearValue: 9000,
              previousYearValue: 10000,
            }
          ]
        }
      ]
    },
    {
      name: 'DETYRIMET',
      subClasses: [
        {
          name: 'Detyrimet afatshkurtra :',
          groups: [
            {
              name: 'Mbitërheqja bankare',
              currentYearValue: 10101,
              previousYearValue: 101201,
            },
            {
              name: 'Llogaritë e pagueshme tregtare dhe të tjera',
              currentYearValue: 10101,
              previousYearValue: 101201,
            },
            {
              name: 'Kreditë dhe huatë, pjesa afatshkurtër',
              currentYearValue: 10101,
              previousYearValue: 101201,
            }
          ]
        }
      ]
    },
    {
      name: 'EKUITETI',
      subClasses: [
        {
          name: '',
          groups: [
            {
              name: 'Kapitali aksionar',
              currentYearValue: 10101,
              previousYearValue: 101201,
            },
            {
              name: 'Fitimet e mbajtura',
              currentYearValue: 10101,
              previousYearValue: 101201,
            },
            {
              name: 'Rezervat e tjera',
              currentYearValue: 10101,
              previousYearValue: 101201,
            }
          ]
        }
      ]
    },
  ];

  const { addToast } = useToasts();
  const excelExportRef = useRef();
  const [mainData, setMainData] = useState([]);
  const [dataForPrint, setDataForPrint] = useState([]);
  const [searchParams, setSearchParams] = useState({ projectId : 0, branchId: 0, includeStartDate : true, includeEndDate: true});
  const [projects, setProjects] = useState([]);
  const [branches, setBranches] = useState([]);
  const [showProgress, setShowProgress] = useState(false);
  const searchObj = { CompanyBranch: 0, PurchaseType: -1 , StartDate:searchParams?.startDate?.toISOString()?.substring(0,10) ,  EndDate: searchParams?.endDate?.toISOString()?.substring(0,10)}
  const printRef = useRef();

  

  ///const [startDate, setStartDate] = useState(new Date());

  useEffect(() => {
    getDates();
    getBranches();
    getProjects();

  }, [])

  const exportToExcel = () => {
    // let table = document.getElementById('table-to-xls-1').innerHTML;
    let fileName = helper().translate("BALANCE_SHEET");
    // return helper().exportToExcel(table, fileName)
    excelExportRef.current.exportToExcel(fileName)
    //"Conversion failed when converting date and/or time from character string."
  }

  const print = () => {
    printRef.current.printPreview()
  }

  const headers = [
    { key: 'name', columnName: helper().translate("NAME") , type:"text" },
    { key: 'currentYear', columnName: helper().translate("CURRENT_YEAR") , type:"number" },
    { key: 'previousYear', columnName: helper().translate("LAST_YEAR") , type:"number"  }
  ];


  const data = (mData) =>{
    var dPrint = [];
    mData.forEach(e => {
      dPrint.push({name: e.name  })
      if(e.subClasses){
        e.subClasses.forEach(sub => {
          dPrint.push({name: sub?.name })
          let group = sub?.groups;
            group.forEach(g => {
              dPrint.push({name: g.name , currentYear: g.currentYearValue ,previousYear: g.previousYearValue })
            });
    
          dPrint.push({name: helper().translate("TOTALY") + " " +  sub.name  , currentYear: sub.currentYearValue  ,previousYear: sub.previousYearValue  })
          
        });
      }
      dPrint.push({name: helper().translate("TOTALY") + " " +  e.name  , currentYear: e.currentYearValue ,previousYear: e.previousYearValue })
  
  });
  setDataForPrint(dPrint)
  }


  const formatData = function (response) {

    let formatedData = [];
    // Balance sheet main classes  =  1 ASSETS - Pasurit  2 LIABILITIES - detyrimet and 3 EQUITY - Ekuiteti
    let mainClasses = [
      { id: '1', name: helper().translate('ASSETS') },
      { id: '2', name: helper().translate('LIABILITIES') },
      { id: '3', name: helper().translate('EQUITY') }

    ]

    mainClasses.forEach((m) => {
      let mainClassData = response.filter(i => i.classId.toString().startsWith(m.id));

      let mainClasse = { name: m.name };

      let subClassIds = mainClassData.map(function (ii) { return ii.classId });
      let uniqueSubClassIds = [...new Set(subClassIds)];

      let currentYearValue = mainClassData.map(i => i.debits - i.credits).reduce((partialSum, a) => partialSum + a, 0);
      mainClasse.currentYearValue = helper().formatNumber(Math.abs(currentYearValue));

      let prevYearValue = mainClassData.map(i => i.prevDebits - i.prevCredits).reduce((partialSum, a) => partialSum + a, 0);
      mainClasse.previousYearValue = helper().formatNumber(Math.abs(prevYearValue));

      mainClasse.subClasses = [];

      uniqueSubClassIds.forEach(subId => {
        let subClassObj = mainClassData.find(i => i.classId === subId);

        let subClass = { name: subClassObj.className };
        mainClasse.subClasses.push(subClass);
        let subClassData = mainClassData.filter((ii) => ii.classId === subId);

        let subClassCurrentYearValue = subClassData.map(i => i.debits - i.credits).reduce((partialSum, a) => partialSum + a, 0);
        subClass.currentYearValue = helper().formatNumber(Math.abs(subClassCurrentYearValue));

        let prevYearValue = subClassData.map(i => i.prevDebits - i.prevCredits).reduce((partialSum, a) => partialSum + a, 0);
        subClass.previousYearValue = helper().formatNumber(Math.abs(prevYearValue));

        //groups
        subClass.groups = [];
        let groupsIds = subClassData.map(function (ii) { return ii.groupId });
        let uniqueGroupsIds = [...new Set(groupsIds)];

        uniqueGroupsIds.forEach(groupId => {
          let groupObj = subClassData.find(i => i.groupId === groupId);
          let groupData = subClassData.filter((ii) => ii.groupId === groupId);

          let group = { name: groupObj.groupName };

          let groupCurrentYearValue = groupData.map(i => i.debits - i.credits).reduce((partialSum, a) => partialSum + a, 0);
          group.currentYearValue = helper().formatNumber(Math.abs(groupCurrentYearValue));

          let groupPevYearValue = groupData.map(i => i.prevDebits - i.prevCredits).reduce((partialSum, a) => partialSum + a, 0);
          group.previousYearValue = helper().formatNumber(Math.abs(groupPevYearValue));

          subClass.groups.push(group);
        });


      });


      // let groups = [];
      // subClassesData.forEach(subClass => {
      //   let subClasse = mainClassData.map(p=> {subClassName: p.groupName});
      //groups = subClass.map(g=> {groupName: g.accountName});
      //subClasses.groups = groups;
      //});
      //mainClass.subClass = subClasses;

      formatedData.push(mainClasse);
    });

    return formatedData;
  }

  const getDates = () => {

    let eDate = new Date();
    let sDate = new Date(eDate.getFullYear(),0,1,1); 

    setSearchParams({ ...searchParams, startDate: sDate, endDate: eDate});
    
    getGeneralBookWithDetails(sDate, eDate, 0, 0);

  };


  const getGeneralBookWithDetails = (startDate, endDate, branchId, projectId) => {

    if (!projectId) {
      projectId = 0;
    }

    let strStartDate = helper().formatDate(startDate,'MM-dd-yyyy');
    let strEndDate = helper().formatDate(endDate,'MM-dd-yyyy') + " 23:59:59";

    setShowProgress(true);

    var params = `&startDate=${strStartDate}&endDate=${strEndDate}&branchId=${branchId}&projectId=${projectId}`;
    apiService().query('fnGeneralBookWithDetails', params).then((response) => {
      if (response.success) {
        let formatedData = formatData(response.data);
        
        let pStartDate = new Date(endDate.getFullYear() - 1, 0, 1);
        let month = endDate.getMonth();
        let day = endDate.getDate();
        let pEndDate = new Date(endDate.getFullYear() -1, month, day);

        setSearchParams({ ...searchParams, startDate: startDate, endDate: endDate, prevStartDate: pStartDate, prevEndDate: pEndDate});
   
        setMainData(formatedData);
        data(formatedData);
        setShowProgress(false);

      } else {
        console.log('Error:' + response.errorMessage);
      }
    });
  };

  
  const getProjects = () => {
    
    apiService()
      .query("getProjects").then((response) => {
        if (response.success) {
          let data = response.data;
         
         

          let firstItem = { id: null, name: helper().translate("ALL_PROJECTS") };

          data.unshift(firstItem);

          let options = data.map((item, index) => ({
            value: item.id,
            label: item.name
          }));

          setProjects(options);

        } else {
          alert("Error: getting Projects" + response.errorMessage);
        }
      });
  };

  const getBranches = () => {
   
    apiService()
      .get("Branches").then((response) => {
        if (response.success) {
          
          let data = response.data;

          let firstItem = { id: null, name: helper().translate("ALL_BRANCHES") };
          data.unshift(firstItem);

          let options = data.map((item, index) => ({
            value: item.id,
            label: item.name
          }));

          setBranches(options);

        } else {
          alert("Error: getting branches" + response.errorMessage);
        }
      });
  };

  const selectChange = (e,  obj) =>{

    if(obj.name==='branches'){
      setSearchParams({...searchParams, branchId: e.value});
    }
    else if(obj.name==='projects'){
      setSearchParams({...searchParams, projectId: e.value});
    }

  }

  const onSearchClick = () => {

    let startDate = searchParams.startDate;
    let endDate = searchParams.endDate;

    if (searchParams.includeStartDate === false) {
      let year = new Date(endDate).getFullYear();
      startDate = `${year}-01-01 00:00:00`;
    } else if (searchParams.includeEndDate === false) {
      endDate = "2222-01-01";
    }

    if(searchParams.branchId === null){
      searchParams.branchId = 0;
    }

    getGeneralBookWithDetails(startDate, endDate, searchParams.branchId, searchParams.projectId)
  }

  const selectStyles = {
    control: (provided, state) => ({
      ...provided,
      background: "#fff",
      borderColor: "#9e9e9e",
      minHeight: "28.59px",
      height: "28.59px",
      boxShadow: state.isFocused ? null : null,
    }),

    valueContainer: (provided, state) => ({
      ...provided,
      height: "28.59px",
      padding: "0 6px",
    }),

    input: (provided, state) => ({
      ...provided,
      margin: "0px",
    }),
    indicatorSeparator: (state) => ({
      display: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "28.59px",
    }),
  };

  const dateChange = (e) => {
    let selected = new Date(e.target.value);
    setStartDate(selected);
  }

  return (
    <div className="container-fluid px-2 mt-3">
      <Search back="#/reports" printClick={print} excel={() => exportToExcel()}   />
      <ExcelExport data={dataForPrint.length ? dataForPrint : []} headers={headers} type={'other'} ref={excelExportRef} hideContent={true} />
      <Print data={dataForPrint.length ? dataForPrint : []} headers={headers.filter(header => header !== false)}  ref={printRef} query={searchObj} reportName={helper().translate("BALANCE_SHEET")} />
      <div className="card shadow">
        <div className="card-header border-left-primary py-2"><h6 className="m-0 font-weight-bold text-primary text-center">{helper().translate("BALANCE_SHEET")}</h6></div>
       {showProgress && 
            <div style={{position:'absolute',top:'10px', right:'40px', zIndex:1000, color:'#4E73DF', fontSize:18}}>{helper().translate('LOADING')} ...</div>
       }
      </div>

      <div className="card border-left-primary text-dark  shadow h-100 mt-1 pt-1 pb-3">
        <div className="container mt-1 ">         
          <div className="row">
            <div className="col-12 col-md-10 offset-md-1">
              <div className="row no-gutters align-items-center">
                <div className="col row">
                  <div className="col-lg-3 mb-3 mb-md-0 pe-0 d-flex align-items-center">
                    {/* <label className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                              Data e fillimit
                            </label> */}
                    <div style={{ paddingRight: '3px', border:'1px solid #fff' }}>
                      <input type="checkbox" name="includeStartDate" checked={ searchParams.includeStartDate}  onChange={(e) => setSearchParams({...searchParams, includeStartDate: e.target.checked})} />
                    </div>
                    <div style={{ width: '95%' }}>
                      <MDBInput 
                        name="startDate"
                        disabled={!searchParams.includeStartDate}
                        className="form-control-sm active"
                        onChange={(e) => setSearchParams({ ...searchParams, startDate: new Date(e.target.value)})}
                        type="date"
                        label={helper().translate("START_DATE")}
                        value={helper().isValidDate(searchParams.startDate)  ?  searchParams.startDate.toISOString().substring(0,10): ''}
                      />
                      {/* <input type="date" onChange={pula} value={startDate  ?  startDate.toISOString().substring(0,10): ''}></input> */}
                    </div>
                  </div>
                  <div className="col-lg-3 mb-3 mb-md-0 pe-0 d-flex align-items-center">
                    {/* <label className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                              Data e Mbarimit
                            </label> */}
                    <div style={{ paddingRight: '3px'}}>
                      <input type="checkbox" name="includeStartDate" checked={searchParams.includeEndDate} onChange={(e) => setSearchParams({ ...searchParams, includeEndDate: e.target.checked })} ></input>
                    </div>
                    <div style={{ width: '95%' }}>
                      <MDBInput
                        name="endDate"
                        disabled={!searchParams.includeEndDate}
                        className="form-control-sm active"
                        onChange={(e) => setSearchParams({ ...searchParams, endDate: new Date(e.target.value)})}
                        type="date"
                        label={helper().translate("END_DATE")}
                        value={helper().isValidDate(searchParams.endDate)  ?  searchParams.endDate.toISOString().substring(0,10): ''}
                      />
                    </div>
                  </div>
                  <div style={{ paddingTop: "2px" }} className="col-lg-3 mb-3 mb-md-0 pe-0 ">

                    <Select
                      name="branches"
                      placeholder={helper().translate("BRANCHES")}
                      styles={selectStyles}
                      options={branches}
                      onChange={(e, obj) => selectChange(e, obj)}
                      value={branches?.find(p => p.value === branches.id)}

                    />
                  </div>
                  <div style={{ paddingTop: "2px" }} className="col-lg-3 mb-3 mb-md-0 pe-0 ">

                    <Select
                      name="projects"
                      placeholder={helper().translate("ALL_PROJECTS")}
                      styles={selectStyles}
                      options={projects}
                      onChange={(e, obj) => selectChange(e, obj)}
                      value={projects?.find(p => p.value === projects.projectId)}

                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-1 col-12 d-flex align-items-center justify-content-center  justify-content-lg-end">
              <div>
                <a className="searchbutton" onClick={() => onSearchClick()}>
                  <i className="fas fa-search fa-2x text-dark"  />
                </a>
              </div>
            </div>
          </div>
          <div className="row font-weight-bold my-4" >
            <div className="col-12  d-flex justify-content-center justify-content-md-start text-center ">
              <h6 className="m-0 w-100 font-weight-bold text-primary text-center ">{helper().translate('FINANCIAL_REPORT_BETWEEN_DATES')}:</h6>
            </div>
          </div>
          <div className="row mt-2 font-weight-bold inputMinWitdth" >
            <div className=" col-12 col-md-5 offset-md-1 d-flex justify-content-center justify-content-md-start ">
            </div>
            <div className="col-6 col-md-3  d-flex justify-content-center  ">
              <label><p className="font-weight-bold text-dark"><u>{searchParams.startDate? helper().formatDate(searchParams.startDate) : ''} - {searchParams.endDate? helper().formatDate(searchParams.endDate)  : ''}</u></p></label>
            </div>
            <div className="col-6 col-md-3  d-flex justify-content-center   ">
              <label><p className="font-weight-bold text-dark"><u>{searchParams.prevStartDate? helper().formatDate(searchParams.prevStartDate) : ''} - {searchParams.prevEndDate? helper().formatDate(searchParams.prevEndDate)  : ''}</u></p></label>
            </div>
          </div>
          {
            
            mainData.map(main => {
              return <>
                <div className="row mt-2 " >
                  <div className="col-12 col-md-5 offset-md-1 d-flex justify-content-center justify-content-md-start">
                    <div className="text-left ">
                      <p className="font-weight-bold text-dark m-0">{main.name}</p>
                    </div>
                  </div>
                </div>
                <div className="row"  >
                  <div className="col-12 col-md-11 offset-md-1 text-dark">
                    <hr className="m-0 hrSolid"></hr>
                  </div>
                </div>
                {
                  main.subClasses && main.subClasses.map(sub => {
                    return <>
                      <div className="row mt-2 mt-md-3 font-weight-bold inputMinWitdth" >
                        <div className="col-12 col-md-5 offset-md-1 d-flex justify-content-center justify-content-md-start">
                          <div className="text-left">
                            <p className="font-weight-bold text-dark">{sub.name}</p>
                          </div>
                        </div>
                      </div>

                      {
                        sub.groups.map(group => {
                          return <div className="row mt-1 inputMinWitdth" >
                            <div className=" col-12 col-md-5 offset-md-1 d-flex justify-content-center justify-content-md-start ">
                              <div className="text-left">
                                <p>{group.name}</p>
                              </div>
                            </div>
                            <div className="col-6 col-md-3  d-flex justify-content-center justify-content-md-start ">
                              <input type="text" className="form-control form-control-sm font-weight-bold text-right " disabled value={group.currentYearValue} >
                              </input>
                            </div>
                            <div className="col-6 col-md-3  d-flex justify-content-center justify-content-md-start  ">
                              <input type="text" className="form-control form-control-sm font-weight-bold text-right " disabled value={group.previousYearValue} >
                              </input>
                            </div>
                          </div>
                        })
                      }
                      <div className="row mt-1 font-weight-bold inputMinWitdth" >
                        <div className="col-12 col-md-5 offset-md-1 d-flex justify-content-center justify-content-md-start">
                          <div className="text-left">
                            <p className="font-weight-bold text-dark"><u> {helper().translate("TOTALY") + " "}{sub.name.toLowerCase()} </u> </p>
                          </div>
                        </div>
                        <div className="col-6 col-md-3  d-flex justify-content-center justify-content-md-start ">
                          <input type="text" className="form-control form-control-sm font-weight-bold text-right  border-dark " disabled value={sub.currentYearValue}></input>
                        </div>
                        <div className="col-6 col-md-3  d-flex justify-content-center justify-content-md-start ">
                          <input type="text" className="form-control form-control-sm font-weight-bold text-right border-dark " disabled value={sub.previousYearValue}></input>
                        </div>
                      </div>

                    </>
                  })
                }
                <div className="row mt-1 font-weight-bold inputMinWitdth" >
                  <div className="col-12 col-md-5 offset-md-1 d-flex justify-content-center justify-content-md-start">
                    <div className="text-left">
                      <p className="font-weight-bold text-dark"><u>{helper().translate("TOTALY") + " "}{main.name.toLowerCase()}</u> </p>
                    </div>
                  </div>
                  <div className="col-6 col-md-3  d-flex justify-content-center justify-content-md-start ">
                    <input type="text" className="form-control form-control-sm font-weight-bold text-right  border-dark " disabled value={main.currentYearValue}></input>
                  </div>
                  <div className="col-6 col-md-3  d-flex justify-content-center justify-content-md-start ">
                    <input type="text" className="form-control form-control-sm font-weight-bold text-right border-dark " disabled value={main.previousYearValue}></input>
                  </div>
                </div>
              </>

            })
          }


        </div>
      </div>
    </div>

  )
}

export default BalanceSheet;
