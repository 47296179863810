import React, {useEffect, useState, useRef} from 'react'
import apiService from '../../../Services/apiServices'
import DataTable from '../../../PageComponents/UI/Tables/DataTable';
import helper from '../../../Services/helper';
import Select from "react-select"
import { Switch } from '@material-ui/core';
import ExcelExport from '../../../PageComponents/Excel/ExcelExport';
import Search from '../../../PageComponents/Search/Search_Old';
import LoaderComponent from '../../../PageComponents/UI/Components/LoaderComponent';

const ReportAtkSaleBook = () => {
    const [data, setData] = useState([])
    const d = new Date();
    const searchObj = {CompanyBranch: 0, YEAR: d.getFullYear(), StartMonth: 1, EndMonth: 12}
    const [searchQuery, setSearchQuery] = useState(searchObj)
    const [reference, setReference] = useState(false);
    const [loader , setLoader] = useState(false);

    const selectStyles = {
        control: (provided, state) => ({
            ...provided,
            background: '#fff',
            borderColor: '#9e9e9e',
            minHeight: '28.59px',
            height: '28.59px',
            boxShadow: state.isFocused ? null : null,
        }),
    
        valueContainer: (provided, state) => ({
            ...provided,
            height: '28.59px',
            padding: '0 6px'
        }),
    
        input: (provided, state) => ({
            ...provided,
            margin: '0px',
        }),
        indicatorSeparator: state => ({
            display: 'none',
        }),
        indicatorsContainer: (provided, state) => ({
            ...provided,
            height: '28.59px',
        }),
    };

    const t = (key) => {
        return helper().translate(key);
    }

    const excelExportRef = useRef();

    const months = [
        {value: 1, label: t('JANUARY')},
        {value: 2, label: t('FEBRUARY')},
        {value: 3, label: t('MARCH')},
        {value: 4, label: t('APRILL')},
        {value: 5, label: t('MAY')},
        {value: 6, label: t('JUNE')},
        {value: 7, label: t('JULY')},
        {value: 8, label: t('AUGUST')},
        {value: 9, label: t('SEPTEMBER')},
        {value: 10, label: t('OCTOBER')},
        {value: 11, label: t('NOVEMBER')},
        {value: 12, label: t('DECEMBER')}
    ]
    const [years, setYears] = useState()

    const headers = [
        { key: 'Nr', columnName: t('NU') , dontShowMenu : true   },
        { key: 'Date', columnName: t('DATE') },
        !reference ? { key: 'InvoiceNo', columnName: t('INVOICE_NO') } : { key: 'Reference', columnName: t('REFERENCE') , type:"comment" },
        { key: 'CompanyName', columnName: t('COMPANY_NAME') , type:"text"},
        { key: 'FiscalNumber', columnName: t('FISCAL_NUMBER') , type:"text"},
        { key: 'VatNumber', columnName: t('VAT_NUMBER') },
        { key: 'K9', columnName: t('K9') , type: "number"},
        { key: 'K10a', columnName: t('K10A') , type: "number" },
        { key: 'K10b', columnName: t('K10B') , type: "number"},
        { key: 'K10c', columnName: t('K10C'), type: "number" },
        { key: 'K10', columnName: t('K10'), type: "number" },
        { key: 'K11', columnName: t('K11') , type: "number"},
        { key: 'K12', columnName: t('K12'), type: "number" },
        { key: 'K16', columnName: t('K16') , type: "number"},
        { key: 'K20', columnName: t('K20'), type: "number" },
        { key: 'K24', columnName: t('K24'), type: "number" },
        { key: 'K28', columnName: t('K28') , type: "number"},
        { key: 'K1', columnName: t('K1') , type: "number"},
        { key: 'K14', columnName: t('K14') , type: "number"},
        { key: 'K18', columnName: t('K18') , type: "number"},
        { key: 'K22', columnName: t('K22'), type: "number" },
        { key: 'K26', columnName: t('K26') , type: "number"},
        { key: 'K2', columnName: t('K2') , type: "number"},
        { key: 'K30', columnName: t('K30') , type: "number"}
    ];

    const fillYears = () => {
        let s = d.getFullYear() - 10
        let e = d.getFullYear()
        let years = []

        for (let index = s; index <= e; index++) { 
            let obj = {value: index, label: index}
            years.push(obj)
        }
        setYears(years)
    }


    const getData = () => {
        setLoader(true);
        var params = `CompanyBranch=${searchQuery.CompanyBranch}&YEAR=${searchQuery.YEAR}&StartMonth=${searchQuery.StartMonth}&EndMonth=${searchQuery.EndMonth}`;

        apiService().query('RptAtkSaleBook', params)
            .then((response) => {
                if(response.success) {
                    setLoader(false);
                    setData(response.data)
                }
            })
    }

    const print = () => {
        window.open(`#/excel-print?CompanyBranch=${searchQuery.CompanyBranch}&YEAR=${searchQuery.YEAR}&StartMonth=${searchQuery.StartMonth}&EndMonth=${searchQuery.EndMonth}`, 'popUpWindow','height=1300,width="100%",left=100,top=100,right=100,bottom=100,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes')
    }

    const exportToExcel = () => {
        //let table = document.getElementById('table-to-xls-1').innerHTML;
        let fileName = helper().translate("ATK_SALE_BOOK")
        //return helper().exportToExcel(table, fileName)
        excelExportRef.current.exportToExcel(fileName)
    }

    const exportToExcel1 = () => {
        var table= document.getElementById('table-to-xls-1');
        var html = table.outerHTML;
        window.open('data:application/vnd.ms-excel,' + escape(html));
    }

    useEffect(() => {
        getData()
        fillYears()
    }, [])

    return (
        <div>
            <div className="container-fluid px-2 mt-3">
                <Search search={false} excel={() => exportToExcel()} printClick={print} back="#/reports"/>
                {/* <Search search={false} excel={exportToExcel} printClick={print}/> */}
                <div className="card border-left-primary shadow h-100 py-2">
                    <div className="card-body py-0">
                    <div className="row no-gutters align-items-center">
                        <div className="col mr-2 row">
                            <div className='w-100 d-md-flex d-lg-flex' >
                                <div className="col-md-3 col-lg-3 form-group">
                                    <label className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                                    {helper().translate("FROM")}
                                    </label>
                                    <Select styles={selectStyles} value={months?.find(m=> m.value === searchQuery.StartMonth)} options={months} onChange={(e) => { setSearchQuery({...searchQuery, StartMonth: e.value })}} />
                                </div>
                                <div className="col-md-3 col-lg-3 form-group">
                                    <label className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                                        {helper().translate("TO")}
                                    </label>
                                
                                    <Select styles={selectStyles} value={months?.find(m=> m.value === searchQuery.EndMonth)} options={months} onChange={(e) => { setSearchQuery({...searchQuery, EndMonth: e.value })}} />
                                </div>
                                <div className="col-md-3 col-lg-3 form-group">
                                    <label className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                                    {helper().translate("YEAR")}
                                    </label>
                                
                                    <Select styles={selectStyles} value={years?.find(y=> y.value === searchQuery.YEAR)} options={years} onChange={(e) => { setSearchQuery({...searchQuery, YEAR: e.value })}} />
                                </div>
                                <div className="col-md-3 col-lg-3 form-group">
                                    <label className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                                        {t('REFERENCE')}
                                    </label><br></br>
                                
                                    <Switch className="mt-0" color="primary" onChange={(e)=> setReference(e.target.checked)} checked={reference}/>
                                </div>
                            </div>
                        </div>
                        <div className="col-auto">
                        <a className="searchbutton" onClick={getData}>
                            <i className="fas fa-search fa-2x text-dark" />
                        </a> 
                        </div>
                    </div>
                    </div>
                </div>
                <div className="card shadow mt-1">
                    <div className="card-header py-2">
                        <h6 className="m-0 font-weight-bold text-primary text-center">{helper().translate("ATK_SALE_BOOK")}</h6>
                    </div>
                    {loader && <LoaderComponent /> } 
                    {!loader ? <DataTable data={data.length ? data : []} headers={headers} /> : ""}
                    <div className='d-none'>
                        <div id="excelFile">
                            <ExcelExport data={data.length ? data : []} headers={headers} type={'sale'} ref={excelExportRef} hideContent={true} zeroToNull={true} />  
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ReportAtkSaleBook

