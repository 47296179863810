import React, { useState, useEffect, useRef } from 'react';
import Select from "react-select";
import Switch from "react-switch";
import SaleAddPartner from '../../PageComponents/UI/Modal/SaleAddPartner';
import Search from '../../PageComponents/Search/Search_Old';
import apiService from '../../Services/apiServices';
import ModelPurchases from '../../Models/Purchases';
import ModelPurchaseDetails from '../../Models/PurchaseDetails';
import calculate from '../../Services/calculate';
import helper from '../../Services/helper';
import globals from '../../Services/globals';
import { ToastProvider, useToasts } from 'react-toast-notifications';
import LoaderComponent from '../../PageComponents/UI/Components/LoaderComponent';
import Modal from "react-bootstrap/Modal";
import Item from '../Items/Item';
import { confirmAlert } from 'react-confirm-alert'; 
// import ImportCalculate from './ImportCalculate';
import ModelTransportDetails from '../../Models/TransportDetail';
// import PaymentModel from '../../Models/PaymentModel';
// import PaymentPurchases from './PaymentPurchases';
import ImportData from '../Tools/ImportData';

const RegistrationOfGoods = (params) => {
    const { addToast } = useToasts();
    const selectInputRef = useRef();
    const logedInUser = globals().getLogedInUser();
    const [user, setUser] = useState();
    const [loader, setLoader] = useState(true)
    const [partners, setPartners] = useState()
    const [purchase, setPurchase] = useState(new ModelPurchases())
    const [purchaseTypes, setPurchaseTypes] = useState()
    const [payMethods, setPayMethods] = useState()
    const [warehouses, setWarehouses] = useState()
    const [units, setUnits] = useState()
    const [items, setItems] = useState()
    const [config, setConfig] = useState()
    const [errors, setErrors] = useState({})
    const [showModal, setShowModal] = useState(false)
    const [vatLevels, setVatLevels] = useState()
    const [projects, setProjects] = useState()
    const [isImport, setIsImport] = useState(false)
    const [onTransportDetails, setOnTransportDetails] = useState(true)
    const [transportDetails, setTransportDetails] = useState()
    const [payments, setPayments] = useState()
    const status = {added: 1, deleted: -1, changed: 2, unchanged: 0};
    const [showPayments, setShowPayments] = useState(false)
    const localPurchases = [1, 2, 3, 7];
    const otherPurchases = [4, 5, 6]
    const [pType, setpType] = useState()
    const searchObj = {dateIn: `12/31/${new Date().getFullYear()-1} 23:59:59`, warehouseId: 0}
    const [searchQuery, setSearchQuery] = useState(searchObj)
    const [branches, setBranches] = useState()
    const [showImportData, setShowImportData] = useState(false);

    const purchaseObj = {
        id: 0, sId: null, purchaseId: '', no: 1, itemId: 0, itemName: '', projectId: 0, invoiceId: 0, quantity: 1, price: 0, priceWithVat: 0, discount: 0.0, markup: 0, duty: 0.0, dutyPercentage: 0, transport: 0.0, overvalue: 0.0, excise: 0.0, excisePercentage: 0, purchasePrice: 0, vat: '', vatSum: 0.0, createdAt: '', createdBy: '', changedAt: '', changedBy: '', status: 0, price_discount: 0.0, salePrice: 0.0, salePriceWithVat: 0.0, editMode: false, subTotal: 0.0, total: 0.0, transportPercentage: 0, dudNo: 0, dudDate: null
    }

    const itemsByPurchaseType = {
        1: 'getNoexpenseItems', 2: 'getInventoryItems', 3: 'getExpenseItems'
    }
    
    const [purchaseDetails, setPurchaseDetails] = useState([purchaseObj])

    const tables = [
        { name: 'units', method: setUnits },
        { name: 'purchaseType', method: setPurchaseTypes },
        { name: 'paymentMethod', method: setPayMethods },
        // { name: 'warehouses', method: setWarehouses },
        { name: 'vatLevel', method: setVatLevels },
        { name: 'projects', method: setProjects},
        { name: 'branches', method: setBranches}
    ];

    const modalShow = () => {
       setShowModal(true)
    }

    const modalClose = () => {
        setShowModal(false)
    }

    const styles = {
        container: provided => ({
            ...provided,
            Width: '100%'
        }),
        control: (provided, state) => ({
            ...provided,
            background: '#fff',
            borderColor: '#9e9e9e',
            minHeight: '28.59px',
            height: '28.59px',
            boxShadow: state.isFocused ? null : null,
        }),
        menuPortal: base => ({ ...base, zIndex: 9999 }),
        valueContainer: (provided, state) => ({
            ...provided,
            height: '28.59px',
            padding: '0 6px'
        }),

        input: (provided, state) => ({
            ...provided,
            margin: '0px',
        }),
        indicatorSeparator: state => ({
            display: 'none',
        }),
        indicatorsContainer: (provided, state) => ({
            ...provided,
            height: '28.59px',
        }),
    };

    const getUser = async () => {
        apiService().query('user', 'userName='+logedInUser.data.username)
            .then((response) => {
                if(response.success) {
                    let u = response.data[0];
                    setUser(u)
                }
                else alert('errr')
            })
    }

    const getItems = (name) => {
        apiService().get(name ? name : 'items')
            .then((response) => {
                if (response.success) {
                    let data = response.data
                    setItems(data.filter(d => d.itemGoods === true))
                }
            })
    }

    const getSettings = () => {
        globals().getSettings().then((response) => {
            if (response) {
                setConfig(response)
                setLoader(false)
            }
        })
    }

    const getDefaults = (name, method) => {
        apiService().get(name)
            .then((response) => {
                if (response.success) {
                    let records = response.data
                    records = records.map(record => ({ value: record.id, label: name==='vatLevel' ? record.value : record.name, warehouseId: name === 'projects' ? record.warehouseId : 0 }))
                    if(name === 'units') {
                        records = response.data.map(record => ({ value: record.typeId, label:  record.name  }));
                    }
                    method(records)
                }
            })
    }

    const getWarehousesByBranch = (e) => {
        setSearchQuery({ ...searchQuery, warehouseId: 0 })
        apiService().query('WarehousesByBranch', `branchId=${e?.value}`)
                .then((response) => {
                    if(response.success && response.data.length) {
                        let records = response.data
                        records = records.map(record => ({ value: record.id, label: record.name }))
                        setWarehouses(records)
                    }
                    else {
                        setWarehouses([])
                    }
                })
    }

    const getPurchase = (id) => {
        if(id){
            apiService().query('getPurchase', `id=${id}`)
                .then((response) => {
                    if(response.success && response.data.length) {
                        let purchase = response.data[0]
                        getItems();
                        setPurchase(purchase)
                        getPurchaseDetails(purchase)
                    }
                })
        }
        else {
            let obj = new ModelPurchases()
            obj.purchaseTypeId = 0;//parseInt(params.location.search.replace('?type=', ''))
            obj.paymentMethodId = 0
            obj.warehouseId = user?.warehouseId
            obj.date = helper().formatDate(new Date(new Date().getFullYear(), 0, 1), 'yyyy-MM-dd'); //'01/01/2022' //new Date(new Date().getFullYear(), 0, 1) //(new Date()).toISOString().substring(0, 10)
            obj.import = isImport
            setPurchase(obj)
            setPurchaseDetails([purchaseObj])
            //setLoader(false)
        }
    }

    const getPurchaseDetails = (purchase) => {
        apiService().query('getPurchaseDetails', `purchaseId=${purchase.id}`)
            .then((response) => {
                if(response.success) {
                    let records = response.data
                    records.sort(function (a, b) {
                        return a.no - b.no;
                      });
                    records.forEach(record => {
                        let item = items?.find(i => i.id === record.itemId) 
                        record['unitId'] = item?.unitId
                        calculate().calculatePrice(record, record.price);
                        record['subTotal'] = parseFloat(record['quantity']) * parseFloat(record['price_discount'])
                        record['total'] = parseFloat(record['subTotal']) + parseFloat(record['vatSum'])
                    })
                    calculateTotals(records, purchase)
                    setPurchaseDetails(reOrderItems(records))
                }
            })
    }

    const setUnit = (unitId) => {
        return units?.find(u => u.value === unitId)?.label
    }

    const setProject = (projectId) => {
        return projects?.find(p => p.value === projectId)?.label
    }

    const calculateTotals = (details, _purchase) => {
        let purchaseObj = _purchase ? _purchase : purchase
        let _details = details.filter((row) => row.status !== status.deleted);

        purchaseObj.vatSum = _details.reduce((accumulator, current) => parseFloat(accumulator) +  parseFloat(current.vatSum), 0)
        purchaseObj.subTotal = _details.reduce((accumulator, current) => parseFloat(accumulator) + parseFloat(current.subTotal), 0)
        purchaseObj.totalSum = _details.reduce((accumulator, current) => parseFloat(accumulator) + parseFloat(current.total), 0)
        // purchaseObj.totalSum = _details.reduce((accumulator, current) => parseFloat(accumulator) +  parseFloat(current.subTotal), 0)
        purchaseObj.amount = purchaseObj.totalSum
        setPurchase(purchaseObj)
    }

    const onRowClick = (no) => {
        let rowItem = [...purchaseDetails]
        rowItem.forEach(i => i.editMode = false);
        rowItem[no-1].editMode = !rowItem[no-1].editMode;
        setPurchaseDetails(rowItem);
    }

    const addRow = () => {
        let newItem = purchaseObj
        newItem.no = Math.max(...purchaseDetails.map(o => o.status !== status.deleted ? o.no : ''), 0) + 1
        isImport ? newItem.transportPercentage = parseFloat(purchase.transport) * 100 / parseFloat(purchase.subTotal) : ''
        const newState = [...purchaseDetails, newItem];
        setPurchaseDetails(newState)
    }

    const deleteRow = (rowId) => {
        let arrayCopy = [...purchaseDetails]
        let item = arrayCopy.find(i => i.no === rowId);
        item.status = status.deleted
        item.id !== 0 ?  item.status = status.deleted : arrayCopy = arrayCopy.filter((row) => row.no !== rowId);
        //arrayCopy = arrayCopy.filter((row) => row.no !== rowId);
        let count = 1;
        arrayCopy.forEach(i => { 
            if(i.status !== status.deleted) {
                i.no = count; count++
            }
         });
        //setPurchaseDetails([])
        const newArr = [arrayCopy]
        setPurchaseDetails(arrayCopy)
        calculateTotals(arrayCopy)
    }

    const reOrderItems = (items) => {
        let count = 1;
        items.forEach(i => { 
            if(i.status !== -1) {
                i.no = count; 
                count++ 
            }
            else{
                i.no = 0;
            }
        });

        return items;
    }

    const onFieldChange = (index, itemId) => (e, a) => {
        let newArr = [...purchaseDetails]; 
        newArr[index-1]['status'] = status.changed;
        if(a) {
            let prop = a.name
            newArr[index-1][prop] = e.value; 
            let itemId1 = (prop ==='itemId') ? e.value : itemId
            let item = items?.find(i => i.id === itemId1)
            if(prop === 'vat') item.vat = e.value
            purchase.withVat ? newArr[index-1]['vat'] = item.vat : newArr[index - 1]['vat'] = 0; 
            newArr[index-1]['unitId'] = item.unitId
            calculate().calculateSalePrice(newArr[index-1], newArr[index-1]['salePrice'] ) 
            // calculate().calculateSalePriceWithVat(item, value)  
            newArr[index-1]['subTotal'] = newArr[index-1]['quantity'] * newArr[index-1]['price_discount']
            newArr[index-1]['total'] = newArr[index-1]['subTotal'] + newArr[index-1]['vatSum']  
            setPurchaseDetails(newArr)
            calculateTotals(newArr);
            errors?.['pd_item_'+index] ? setErrors({...errors, ['pd_item_'+index] : ""}) : ''
        }
        else{
            let prop = e.target.name
            let value = e.target.value 
            if(prop === 'quantity' && purchase.purchaseTypeId === 5) {
                if (value <= -1 || value === "")
                    newArr[index - 1][prop] = e.target.value;
            }
            else {
                newArr[index - 1][prop] = e.target.value;
            }
            // newArr[index - 1][prop] = e.target.value;
            errors?.['pd_price_'+index] ? setErrors({...errors, ['pd_price_'+index] : ""}) : ''

            calculate().registrationOfGoods(newArr[index - 1], isImport, prop)
            setPurchaseDetails(newArr); 
            calculateTotals(newArr);
        }  
    }

    const newPurchase = () => {
        if(onTransportDetails) {
            let type = parseInt(params.location.search.replace('?type=', ''))
            purchaseDetails?.some(function(el) {
                el.editMode === true ? 
                    confirmAlert({
                        customUI: ({ onClose }) => {
                        return (
                            <div className="custom-ui" >
                            <h1>A jeni te sigurt?</h1>
                            <p>Dëshironi të hapni blerje të re pa i ruajtur ndryshimet paraprake?</p>
                            <button
                                onClick={() => {
                                    !type || type === purchase.purchaseTypeId ?  getPurchase(0) : window.location.href = `#finances/registration-goods`;
                                    onClose();
                                }}
                            >
                                Vazhdo!
                            </button>
                            <button onClick={onClose}>Jo</button>
                            </div>
                        );
                        }
                    })  
                : 
                location.href = `#finances/registration-goods`
            });
        }
        else {
            responseMessage('error', 'Se pari ruani detajet e transportit!')
        }
        
    }

    const escFunction = (event) => {
        if(event.keyCode === 27) {
            modalClose()
        }
    }

    const responseMessage = (appearance, message) => {
        addToast(message, { appearance: appearance, autoDismiss: true, });
    }

    const afterNewItem = (c) => {
        if(c){
            modalClose();
            getItems()
        }
    }

    const toggleImportData = () => {
        setShowImportData(!showImportData)
    }

    const usePrevious = (value) => {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    const loadStock = () => {
        if(searchQuery.warehouseId !== 0) {
            var params = `dateIn=${searchQuery.dateIn}&warehouseId=${searchQuery.warehouseId}`;
            apiService().query('LoadStock', params)
                .then((response) => {
                    if(response.success) {
                        let arr = []
                        let data = response.data
                        data?.forEach(d => {
                            //let obj = new ModelPurchaseDetails(d)
                            arr.push(toLowerKeys(d))
                        })
                        setPurchase({...purchase, warehouseId: searchQuery.warehouseId})
                        setPurchaseDetails(arr)
                        calculateTotals(arr);
                        setErrors({})
                    }
                    else {
                        console.error(response)
                    }
                })
        }
        else {
            let errorsArr = {}
            errorsArr['warehouseId'] = helper().translate('SELECT_WAREHOUSE')
            if(!purchase.branchId) {
                errorsArr['branchId'] = helper().translate('SELECT_BRANCH')
            }
            setErrors(errorsArr)
            setPurchaseDetails([purchaseObj])
        }
        
    }

    const toLowerKeys = (obj) => {
        return Object.keys(obj).reduce((accumulator, key) => {
            key = 
          accumulator[key.charAt(0).toLowerCase() + key.slice(1, -1) + key.slice(-1).toLocaleLowerCase()] = obj[key];
          //accumulator[key.charAt(0).toLowerCase() + key.slice(-1)] = obj[key];
          return accumulator;
        }, {});
      }

    const toFixed2 = (name, value) =>{
        let elem = document.getElementsByName(name);
        if (elem[0] === document.activeElement) {
            return value;
        } else {
            return parseFloat(value).toFixed(2)
        }
    }

    const handleBlur = (e) => {
        let elem = document.getElementsByName(e.target.name);
        elem[0].value = parseFloat(elem[0].value).toFixed(2);
    }

    const prepareData = (headerKeys, items) => {
        var objs = [];
        let model = purchaseObj;// new ModelPurchaseDetails();

        items?.forEach(item => {
            let obj = {...purchaseObj};
            headerKeys?.forEach(header => {
              Object.keys(model).forEach(function(key) {
                if (header === key) {
                    obj[key] = item[header]  
                }
              })
            })
            
            objs.push(obj)         
        })

        return objs;
    }  

    const getDataFromImport = (headers, data) =>{
        let objs = prepareData(headers, data?.filter(d => d.no !== ''));

        calculateTotals(objs);
        setPurchaseDetails(objs)    
    }

    const save = () => {
        
            let errorsArr = {}, requiredFields = ['warehouseId'];
            purchase.warehouseId = searchQuery.warehouseId;

            requiredFields.forEach(field => 
            {
                field != '' ? !purchase?.[field] ? errorsArr[field] = 'Zgjidhe' : '' : 0
            })

            purchaseDetails?.forEach(detail => {
                if(detail.status !== -1){
                    !detail.itemId ? errorsArr['pd_item_'+detail.no] = 'Zgjidhe' : '';
                    !detail.price || detail.price < 0 ? errorsArr['pd_price_'+detail.no] = 'Zgjidhe' : '';
                }
            });

            const isEmpty = !Object.values(errorsArr).some(x => x !== null && x !== '');
            setErrors(errorsArr)

            if (purchase.id !== 0) {
               if(isEmpty && purchaseDetails.length && purchaseDetails[0].itemId) {
                    apiService().put('purchases', purchase)
                        .then((response) => {
                            if(response.success) {
                                savePurchaseDetails(purchase) 
                            }
                            else{
                                console.error(response)
                                responseMessage('error', response.errorMessage ? response.errorMessage : 'Ka ndodhur nje gabim!')
                            }
                        })
                }
            }
            else {
                purchase.dateRegistered = new Date();
                purchase.warehouseId = searchQuery?.warehouseId
                if(isEmpty && purchaseDetails.length && purchaseDetails[0].itemId) {
                    apiService().post('itemsRegistration', purchase)
                        .then((response) => {
                            if(response.success) {
                                let resp = response.data
                                setPurchase(response.data)
                                savePurchaseDetails(response.data)
                            }
                            else{
                                responseMessage('error', response.errorMessage ? response.errorMessage : 'Ka ndodhur nje gabim!')
                            }
                        })
                }
            }
    }

    const savePurchaseDetails = async (purchase)  => {
        let objs = [], items = reOrderItems([...purchaseDetails]), count = 1;

        items ? await items.forEach(item => {
            item.purchaseId = purchase.id;
            item.purchasePrice = item.price;
                if (item.id === 0) {
                    let obj = {
                        id: 0,
                        entity: new ModelPurchaseDetails(item),
                        status: status.added,
                        type: 'purchaseDetails'
                    }
                    obj.entity.itemId && obj.entity.price && item.no !== 0 && item.status !== -1 ? objs.push(obj) : 0
                }
                else {
                    let obj = {
                        entity: new ModelPurchaseDetails(item),
                        status: item.status ? item.status : status.unchanged,
                        type: 'purchaseDetails'
                    }
                    if (obj.status !== status.unchanged) {
                        obj.entity.itemId && obj.entity.price ? objs.push(obj) : 0
                    }
                }
        }) :
        0

        objs ? apiService().saveChanges(objs)
            .then((response) => {
                if (response.success) {
                    responseMessage(response.success ? 'success' : '', 'U ruajt me sukses!')
                    window.location.href = '#/finances/registration-goods/'+purchase.id;
                }
                else {
                    responseMessage('error', response.errorMessage ? response.errorMessage : 'Ka ndodhur nje gabim!')
                }
            }) : 
        0
    }

    const print = () => {
        window.open(`#/print-purchase/${purchase.id}`, 'popUpWindow','height=1300,width="100%",left=100,top=100,right=100,bottom=100,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes')
    }

    const itemsToCsv = () => {
        // alert('ooooooooooo')
        selectInputRef.current.itemsToCsv()
    }
 
    useEffect(() => {
        document.addEventListener("keydown", escFunction, false);
            return () => {
                document.removeEventListener("keydown", escFunction, false);
            }
    }, [])

    useEffect(() => {
        let id = params.match.params.id
        let type = 0;//params.location.search.replace('?type=', '')
        
        getUser()
        !id ? getItems() : getItems()

        tables.forEach((table, index) => {
            getDefaults(table.name, table.method)
            index === tables.length - 1 ? getSettings() : ""
        })

        !id ? setpType(parseInt(type)) : 0
    }, [])

    useEffect(() => {
        getPurchase(params.match.params.id ? params.match.params.id : 0 )
    }, [loader])

    useEffect(() => {
        getPurchase(params.match.params.id ? params.match.params.id : 0 )
    }, [params.location])

    return (
        <div>
            <div style={{transition: 'all 2s'}} className="container-fluid px-2 mt-3">
                <Search headersFrom={"FINANCE"} back="#/finances/registration-goods-list" search={false} save={save} newHandle={newPurchase} printClick={!otherPurchases.includes(pType) ? print : 0 } upload={loadStock} importData={() => toggleImportData()} />
                <div className="card border-left-primary shadow h-100 mt-1 pt-1 pb-3">
                {loader && <LoaderComponent type="ThreeDots" className="text-center w-100" color="#2BAD60" height="100" width="100" /> }
                {!loader && <> 
                    <div className={`form row px-4`} >
                        <div className="col-12 col-md-8 col-lg-8 col-xl-8 d-md-flex d-lg-flex d-xl-flex">
                            <div className={`col-md-6 col-12`}>
                                <div className="row">
                                    <div className={` col-12 pl-0`}>
                                        <div className={` col-12 pl-0`}>
                                            <label className="text-xs font-weight-bold text-primary mb-0">{helper().translate('REGISTRATION_DATE')}</label>
                                            <input
                                                name="date"
                                                className="form-control form-control-sm"
                                                type="date"
                                                // value={new Date(new Date().getFullYear(), 0, 1)}
                                                value={purchase?.date ? helper().formatDate(purchase.date, 'yyyy-MM-dd') : new Date(new Date().getFullYear(), 0, 1)}
                                                onChange={(e) => setPurchase({ ...purchase, date: e.target.value })}
                                            />
                                        </div>
                                    </div>

                                    {/* <div className={`col-12 l-straight pl-0 d-md-flex`}>
                                        <div className="col-md-6 pl-0 pr-1">
                                            <label className="text-xs font-weight-bold text-primary mb-0">{helper().translate('INVOICE_NO')}</label>
                                            <input className={`form-control form-control-sm ${errors?.invoiceNo ? 'is-invalid': ''}`} name="invoiceNo" value={purchase?.invoiceNo} onChange={(e) => {setPurchase({ ...purchase, invoiceNo: e.target.value }); errors?.purchaseTypeId ? setErrors({...errors, invoiceNo: ''}) :''}}></input>
                                        </div>
                                        <div className="col-md-6 pr-0 pl-1">
                                            <label className="text-xs font-weight-bold text-primary mb-0">{helper().translate('REFERENCE_NO')}</label>
                                            <input className="form-control form-control-sm" name="reference" value={purchase?.reference} onChange={(e) => setPurchase({ ...purchase, reference: e.target.value })}></input>
                                        </div>
                                    </div> */}
                                </div>

                                <div className="row">
                                    <div className={`col-12 pl-0`}>
                                        <div className="col-12 pl-0">
                                            <label className="text-xs font-weight-bold text-primary mb-0">{helper().translate('REGISTRATION_BRANCH')}
                                            </label  >
                                            <Select
                                                name="branchId"
                                                placeholder={helper().translate('SELECT_BRANCH')}
                                                value={branches?.find(v => v.value == purchase?.branchId)}
                                                onChange={(e) => {setPurchase({ ...purchase, branchId: e.value }); errors?.branchId ? setErrors({...errors, branchId: ''}) :''; getWarehousesByBranch(e)}}
                                                options={branches}
                                                styles={styles}
                                                className={errors?.branchId ? 'is-invalid-s': ''}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={`text-box d-sm-none d-none d-md-block col-6 pl-0`}>
                                <label className="text-xs font-weight-bold text-primary mb-0">{helper().translate('COMMENT')}</label>
                                <textarea className="textareas form-control h-81" name="comment" value={purchase.comment} onChange={(e) => setPurchase({ ...purchase, comment: e.target.value })}
                                />
                            </div>
                        </div>
                        {/* LoadStock */}
                        <div className="col-12 col-md-4 col-lg-4 col-xl-4 d-md-flex d-lg-flex d-xl-flex align-items-end">
                            <div className={`col-md-12 col-12`}>
                                <div className="row">
                                    <div className={` col-12 pl-0`}>
                                        <div className={` col-12 pl-0`}>
                                            <label className="text-xs font-weight-bold text-primary mb-0">{helper().translate('STOCK_UPLOAD_DATE')}</label>
                                            <input
                                                name="dateIn"
                                                className="form-control form-control-sm"
                                                type="date"
                                                value={searchQuery?.dateIn ? helper().formatDate(searchQuery.dateIn, 'yyyy-MM-dd') : `12/31/${new Date().getFullYear()-1} 23:59:59`}
                                                onChange={(e) => setSearchQuery({ ...searchQuery, dateIn: e.target.value })}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className={`col-12 pl-0`}>
                                        <div className="col-12 pl-0">
                                            <label className="text-xs font-weight-bold text-primary mb-0">{helper().translate('UPLOAD_WAREHOUSE')}
                                            </label>
                                            <Select
                                                ref={selectInputRef}
                                                name="warehouseId"
                                                placeholder={helper().translate('SELECT_WAREHOUSE')}
                                                value={warehouses?.filter(v => v?.value === searchQuery?.warehouseId)}
                                                onChange={(e) => setSearchQuery({ ...searchQuery, warehouseId: e.value })}
                                                options={warehouses}
                                                styles={styles}
                                                className={errors?.warehouseId ? 'is-invalid-s': ''}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* End LoadStock */}
                    </div>

                    </>}
                </div>
                <Modal
                    show={showModal}
                    size='lg'
                    scrollable={true}
                    onHide={modalClose}
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header closeButton>
                        <Modal.Title className="ms-3" >Shto Artikull</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Item props={params} fromPurchase={true} articleType={purchase.purchaseTypeId === 1 ? 1 : 4 } pc={afterNewItem}></Item>
                    </Modal.Body>
                </Modal >
                <div className="card shadow mt-1">
                    {/* <div className="card-header py-2">
                        <h6 className="m-0 font-weight-bold text-primary">Artikujt</h6>
                    </div> */}
                    {onTransportDetails &&
                    <div className="card-body table-responsive pb-0 pt-1 px-2 mt-1 purchase-tab">
                        {showImportData &&<ImportData ref={selectInputRef} queryName="itemsRegistration" model={purchaseObj} getBackData={(headers, data) => getDataFromImport(headers, data)} />  } 
                    {loader && <LoaderComponent type="ThreeDots" className="text-center" color="#2BAD60" height="100" width="100" /> }
                    {!loader && <table style={{minWidth: '1500px', overflowX: 'scroll', tableLayout: 'fixed'}} className="table table-hover text-center sales-table purchase-table">
                            <thead>
                                <tr>
                                    <th style={{width:'25px'}}>{helper().translate('NU')}.</th>
                                    <th style={{width:'250px'}}>{helper().translate('ITEM')}</th>
                                    <th style={{width:'60px'}}>{helper().translate('UNIT')}</th>
                                    <th style={{width:'75px'}}>{helper().translate('QUANTITY')}</th>
                                    <th style={{width:'75px'}}>{helper().translate('PRICE')}</th>
                                    <th style={{width:'75px'}}>{helper().translate('TOTAL')}</th>
                                    <th style={{width:'25px'}}>+</th>
                                    <th style={{width:'25px'}}>-</th>
                                </tr>
                            </thead>
                            <tbody className='pb-5'>
                                {[...purchaseDetails]?.map((item, key) => (item.status !== status.deleted &&
                                    <tr key={item.no} >
                                        <td>{item.no}</td>
                                        {/* Barcode */}
                                        {
                                            item.editMode ?
                                                <td className="position-relative"><Select className={errors?.['pd_item_'+item.no] ? 'is-invalid-s': ''} styles={styles} menuPortalTarget={document.body} value={items?.map(record => ({ value: record.id, label: record.itemName })).find(i => i.value === parseInt(item.itemId))} onChange={onFieldChange(item.no)} options={items?.map(record => ({ value: record.id, label: record.barcode+ ' - '+record.itemName}))}name="itemId" ></Select>
                                                {(purchase.purchaseTypeId == 1 || purchase.purchaseTypeId == 2) ? <button className="btn-add-item-from-purchase" onClick={modalShow} title="Shto artikull të ri">+</button> : ''}
                                                
                                                </td>
                                                :
                                                <td onClick={function () { onRowClick(item.no) }}><span className={`text-center text-${errors?.['pd_item_'+item.no] ? 'danger' : 'dark'} small`}>{item.itemId > 0 ? items?.map(record => ({ value: record.id, label: record.itemName })).find(i => i.value === parseInt(item.itemId))?.label : helper().translate('CHOOSE_ITEM')}</span></td>
                                        }
                                        {/* unit */}
                                        {
                                            <td onClick={function () { onRowClick(item.no) }}> <span className="text-center text-dark small">{ item.unit  }</span></td>
                                            // setUnit(item.unitId)
                                        }
                                        {/* sasia */}
                                        {
                                            item.editMode && item.itemId ?
                                                <td><input type="number" min={purchase.purchaseTypeId !== 5 ? "1" : ''} max={purchase.purchaseTypeId === 5 ? "-1" : ''} value={toFixed2('quantity', item.quantity)} onBlur={(e) => handleBlur(e)} pattern="[+-]?\d+(?:[.,]\d+)?" onChange={onFieldChange(item.no)} name="quantity" className="form-control form-control-sm text-center"/></td>
                                                :
                                                <td onClick={function () { onRowClick(item.no) }}> <span className="text-center text-dark small">{item.quantity ? toFixed2('quantity', item.quantity) : '0'}</span></td>
                                        }
                                        {/* cmimi pa tvsh */}
                                        {
                                            item.editMode && item.itemId ?
                                                <td><input type="number" min="0" step="any" value={toFixed2('price', item.price)} onBlur={(e) => handleBlur(e)} onChange={onFieldChange(item.no)} name="price" className={`form-control form-control-sm text-center ${errors?.['pd_price_'+item.no] ? 'is-invalid mb-0': ''}`}/></td>
                                                :
                                                <td onClick={function () { onRowClick(item.no) }}> <span className={`text-center text-${errors?.['pd_price_'+item.no] ? 'danger' : 'dark'} small`}>{errors?.['pd_price_'+item.no] ? 'Çmimi nuk mund të jetë 0!' : item.price ? toFixed2('price', item.price) : '0'}</span></td>
                                        }
                                        
                                        {
                                            item.editMode && item.itemId  ? <td><input type="number" min="0" value={toFixed2('total', item.total)} onBlur={(e) => handleBlur(e)} onChange={onFieldChange(item.no)} name="total" className="form-control form-control-sm text-center"/></td>
                                                :
                                                <td onClick={function () { onRowClick(item.no) }}><span className="text-center text-dark small">{parseFloat(item.total).toFixed(2)}</span></td> 
                                        }
                                        {
                                            purchaseDetails.filter(pd => pd.status !== -1).length === key+1 && item.itemId ? <td><button className="btn-edit" onClick={() => addRow()}><span className="icon text-white-40"><i className="fas fa-plus-circle"></i></span></button></td> : <td></td>
                                        }
                                        {
                                            purchaseDetails.filter(pd => pd.status !== -1).length > 1  ? <td><button onClick={() => deleteRow(item.no)} className="btn-delete"><span className="icon text-white-40"><i className="fas fa-minus-circle"></i></span></button></td> : <td></td>
                                        }
                                    </tr>
                                ))}
                                <tr style={{fontSize: '14px'}}> 
                                    <td colspan={5} className="border-bottom-0"></td>
                                    <td style={{background: '#f8f9fc', fontSize: '14px'}} className="font-weight-bold"><small>{helper().translate('TOTAL')} {purchase.withVat ? helper().translate('WITHOUT_VAT') : ''}</small><br></br>{parseFloat(purchase.totalSum).toFixed(2)}</td>
                                    
                                </tr>
                            </tbody>
                        </table>}
                    </div> }
                    </div>
            </div>
        </div>
    )
}

export default RegistrationOfGoods
